define("a24-ember-candidate-profile/services/rest-agency-group-candidate-employment", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _sRestApiBaseUrl: Ember.computed.readOnly("objEnv.sRestApiBaseUrl"),
        sContext: null,

        objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        init: function init() {
            this._super.apply(this, arguments);

            this.set("sContext", this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP"));
        },

        /**
         * Retrieves all work history entries for the candidate
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id to fetch basic info data for
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Ruan Naude <ruan.naude@a24group.com>
         * @since  17 April 2019
         */
        getWorkHistory: function getWorkHistory(objContext, sProperty, sCandidateId, funcSuccess, objFailure) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency-group/" + sAgencyGroupId + "/candidate/" + sCandidateId + "/employment");

            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: {
                    mValue: 1000
                }
            });

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, null, null, true));
        },

        /**
         * Create employment
         *
         * @param objContext - The object on which the property must exist
         * @param sProperty - The property name on which to get and set the promise.
         * @param sCandidateId - The candidate  id
         * @param objData - The employment metadata
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The object containing the callback function to execute when the service call fails
         *
         * @author Ruan Naude <ruan.naude@a24group.com>
         * @since  17 April 2019
         */
        createEmployment: function createEmployment(objContext, sProperty, sCandidateId, objData, funcSuccess, objFailure) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency-group/" + sAgencyGroupId + "/candidate/" + sCandidateId + "/employment");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        },

        /**
         * Update employment
         *
         * @param objContext - The object on which the property must exist
         * @param sProperty - The property name on which to get and set the promise.
         * @param sCandidateId - The candidate id
         * @param sEmploymentId - The employment id
         * @param objData - The employment metadata
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The object containing the callback function to execute when the service call fails
         *
         * @author Ruan Naude <ruan.naude@a24group.com>
         * @since  17 April 2019
         */
        updateEmployment: function updateEmployment(objContext, sProperty, sCandidateId, sEmploymentId, objData, funcSuccess, objFailure) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency-group/" + sAgencyGroupId + "/candidate/" + sCandidateId + "/employment/" + sEmploymentId);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "PUT", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        }
    });
});