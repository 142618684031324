var a24RestUrlConstruct = {
    //Don't add anything in here, this is purely to create the object if the project using
    //this addon does not create and import one.
    //In ember the parent vendor file with the same name and location will take preference over a file with the same
    //name and directory in addons(one in addon will not be imported, only the parent one),
    //If the parent does not have that file but 2 addons have that file then the last addon to be added in the
    //package.json in the parent, will take preference.
};

var a24RestCallHelper = {
    //Don't add anything in here, this is purely to create the object if the project using
    //this addon does not create and import one.
    //In ember the parent vendor file with the same name and location will take preference over a file with the same
    //name and directory in addons(one in addon will not be imported, only the parent one),
    //If the parent does not have that file but 2 addons have that file then the last addon to be added in the
    //package.json in the parent, will take preference.
};

var a24RestResponseHandler = {
    //Don't add anything in here, this is purely to create the object if the project using
    //this addon does not create and import one.
    //In ember the parent vendor file with the same name and location will take preference over a file with the same
    //name and directory in addons(one in addon will not be imported, only the parent one),
    //If the parent does not have that file but 2 addons have that file then the last addon to be added in the
    //package.json in the parent, will take preference.
};
