define("a24-ember-staffshift-candidate-availability/services/shift-email", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        /*
          Services
        */
        _objNavigation: Ember.inject.service("navigation"),
        _objUserSession: Ember.inject.service("user-session"),

        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * Email a timesheet to an email address
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sEmailAddress - The email address
         * @param sShiftId - The shift id
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         * @param objQueryParams - Any extra query params for the get list
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  26 September 2017
         */
        postShiftEmail: function postShiftEmail(objCaller, sProperty, sEmailAddress, sShiftId, funcSuccess, objFailure, objQueryParams) {
            a24RestUrlConstruct.setBaseUrl("/v1/timesheet/email");
            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            var objRequestData = {
                sEmailAddress: sEmailAddress,
                sShiftId: sShiftId
            };

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, Ember.get(this, "_objUserSession"), Ember.get(this, "_objNavigation"), "POST", Ember.get(this, 'sRestApiBaseUrl') + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objRequestData));
        }
    });
});