define("a24-ember-policy-library/services/rest-agency-group-candidate-policy-type", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * This function download pdf for the agency group candidate policy
         *
         * @param sPolicyTypeId - The policy type id
         * @param sCandidateId - The candidate id
         * @param iVersion - The version
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param funcFailure - The callback function to execute when the service call is a failure
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  11 July 2018
         */
        downloadPdfByGET: function downloadPdfByGET(sPolicyTypeId, sCandidateId, iVersion, funcSuccess, funcFailure) {
            var sUrl = this.get("sRestApiBaseUrl") + "/v1" + "/agency-group/" + this.get("_objUserSession").getUserContextReference().mId + "/candidate/" + sCandidateId + "/policy-type/" + sPolicyTypeId + "/version/" + iVersion;

            a24RestCallHelper.downloadPdfByGET(sUrl, "PolicyVersionPreview.pdf", funcSuccess, funcFailure, {
                Authorization: "Bearer " + this.get("_objUserSession.objAuthData.accessToken"),
                Context: "AgencyGroup " + this.get("_objUserSession").getUserContextReference().mId
            });
        }
    });
});