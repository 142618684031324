define("a24-ember-candidate-profile/services/rest-candidate-stated-skills", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _sRestApiBaseUrl: Ember.computed.readOnly("_objEnv.sRestApiBaseUrl"),
        sContext: null,

        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        init: function init() {
            this._super.apply(this, arguments);

            this.set("sContext", this.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE"));
        },

        /**
         * Add a stated skill to the candidate
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id to add skills for
         * @param sSkillsId - The skill id to add
         * @param objPayload - The skill payload
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  01 May 2019
         */
        addStatedSkills: function addStatedSkills(objContext, sProperty, sCandidateId, sSkillsId, objPayload, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/candidate/" + sCandidateId + "/stated-skills/" + sSkillsId);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objPayload));
        },

        /**
         * Update a stated skill for the candidate
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id to update skills for
         * @param sSkillsId - The skill id to update
         * @param objPayload - The skill payload
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  01 May 2019
         */
        updateStatedSkills: function updateStatedSkills(objContext, sProperty, sCandidateId, sSkillsId, objPayload, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/candidate/" + sCandidateId + "/stated-skills/" + sSkillsId);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "PUT", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objPayload));
        },

        /**
         * Delete a stated skill for the candidate
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id to delete skills for
         * @param sSkillsId - The skill id to delete
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  01 May 2019
         */
        deleteStatedSkills: function deleteStatedSkills(objContext, sProperty, sCandidateId, sSkillsId, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/candidate/" + sCandidateId + "/stated-skills/" + sSkillsId);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "DELETE", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});