define("a24-ember-lookup/services/a24-enums", ["exports", "a24-ember-lookup/services/a24-enums-base"], function (exports, _a24EnumsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24EnumsBase.default.extend({
        _objStringsService: Ember.inject.service("a24-strings"),

        init: function init() {
            this._super.apply(this, arguments);

            var objStrings = this.get("_objStringsService");

            this.set("emailTypes", {
                "HOME": {
                    "backend": "home",
                    "frontend": objStrings.getString("home")
                },
                "OTHER": {
                    "backend": "other",
                    "frontend": objStrings.getString("other")
                },
                "WORK": {
                    "backend": "work",
                    "frontend": objStrings.getString("work")
                }
            });

            this.set("gender", {
                "MALE": {
                    "backend": "male",
                    "frontend": objStrings.getString("male")
                },
                "FEMALE": {
                    "backend": "female",
                    "frontend": objStrings.getString("female")
                },
                "OTHER": {
                    "backend": "other",
                    "frontend": objStrings.getString("other")
                }
            });

            this.set("disabled", {
                "YES": {
                    "backend": "yes",
                    "frontend": objStrings.getString("yes")
                },
                "NO": {
                    "backend": "no",
                    "frontend": objStrings.getString("no")
                }
            });

            this.set("yesNo", {
                "YES": {
                    "backend": "Yes",
                    "frontend": objStrings.getString("yes")
                },
                "NO": {
                    "backend": "No",
                    "frontend": objStrings.getString("no")
                }
            });

            this.set("country", {
                "AFGHANISTAN": {
                    "backend": "AF",
                    "frontend": "Afghanistan",
                    "code": "004"
                },
                "ALBANIA": {
                    "backend": "AL",
                    "frontend": "Albania",
                    "code": "008"
                },
                "ALGERIA": {
                    "backend": "DZ",
                    "frontend": "Algeria",
                    "code": "012"
                },
                "AMERICAN_SAMOA": {
                    "backend": "AS",
                    "frontend": "American Samoa",
                    "code": "016"
                },
                "ANDORRA": {
                    "backend": "AD",
                    "frontend": "Andorra",
                    "code": "020"
                },
                "ANGOLA": {
                    "backend": "AO",
                    "frontend": "Angola",
                    "code": "024"
                },
                "ANGUILLA": {
                    "backend": "AI",
                    "frontend": "Anguilla",
                    "code": "660"
                },
                "ANTARCTICA": {
                    "backend": "AQ",
                    "frontend": "Antarctica",
                    "code": "010"
                },
                "ANTIGUA_AND_BARBUDA": {
                    "backend": "AG",
                    "frontend": "Antigua and Barbuda",
                    "code": "028"
                },
                "ARGENTINA": {
                    "backend": "AR",
                    "frontend": "Argentina",
                    "code": "032"
                },
                "ARMENIA": {
                    "backend": "AM",
                    "frontend": "Armenia",
                    "code": "051"
                },
                "ARUBA": {
                    "backend": "AW",
                    "frontend": "Aruba",
                    "code": "533"
                },
                "AUSTRALIA": {
                    "backend": "AU",
                    "frontend": "Australia",
                    "code": "036"
                },
                "AUSTRIA": {
                    "backend": "AT",
                    "frontend": "Austria",
                    "code": "040"
                },
                "AZERBAIJAN": {
                    "backend": "AZ",
                    "frontend": "Azerbaijan",
                    "code": "031"
                },
                "BAHAMAS": {
                    "backend": "BS",
                    "frontend": "Bahamas",
                    "code": "044"
                },
                "BAHRAIN": {
                    "backend": "BH",
                    "frontend": "Bahrain",
                    "code": "048"
                },
                "BANGLADESH": {
                    "backend": "BD",
                    "frontend": "Bangladesh",
                    "code": "050"
                },
                "BARBADOS": {
                    "backend": "BB",
                    "frontend": "Barbados",
                    "code": "052"
                },
                "BELARUS": {
                    "backend": "BY",
                    "frontend": "Belarus",
                    "code": "112"
                },
                "BELGIUM": {
                    "backend": "BE",
                    "frontend": "Belgium",
                    "code": "056"
                },
                "BELIZE": {
                    "backend": "BZ",
                    "frontend": "Belize",
                    "code": "084"
                },
                "BENIN": {
                    "backend": "BJ",
                    "frontend": "Benin",
                    "code": "204"
                },
                "BERMUDA": {
                    "backend": "BM",
                    "frontend": "Bermuda",
                    "code": "060"
                },
                "BHUTAN": {
                    "backend": "BT",
                    "frontend": "Bhutan",
                    "code": "064"
                },
                "BOLIVIA": {
                    "backend": "BO",
                    "frontend": "Bolivia",
                    "code": "068"
                },
                "BOSNIA_AND_HERZEGOVINA": {
                    "backend": "BA",
                    "frontend": "Bosnia and Herzegovina",
                    "code": "070"
                },
                "BOTSWANA": {
                    "backend": "BW",
                    "frontend": "Botswana",
                    "code": "072"
                },
                "BOUVET_ISLAND": {
                    "backend": "BV",
                    "frontend": "Bouvet Island",
                    "code": "074"
                },
                "BRAZIL": {
                    "backend": "BR",
                    "frontend": "Brazil",
                    "code": "076"
                },
                "BRITISH_INDIAN_OCEAN_TERRITORY": {
                    "backend": "IO",
                    "frontend": "British Indian Ocean Territory",
                    "code": "086"
                },
                "BRUNEI_DARUSSALAM": {
                    "backend": "BN",
                    "frontend": "Brunei Darussalam",
                    "code": "096"
                },
                "BULGARIA": {
                    "backend": "BG",
                    "frontend": "Bulgaria",
                    "code": "100"
                },
                "BURKINA_FASO": {
                    "backend": "BF",
                    "frontend": "Burkina Faso",
                    "code": "854"
                },
                "BURUNDI": {
                    "backend": "BI",
                    "frontend": "Burundi",
                    "code": "108"
                },
                "CAMBODIA": {
                    "backend": "KH",
                    "frontend": "Cambodia",
                    "code": "116"
                },
                "CAMEROON": {
                    "backend": "CM",
                    "frontend": "Cameroon",
                    "code": "120"
                },
                "CANADA": {
                    "backend": "CA",
                    "frontend": "Canada",
                    "code": "124"
                },
                "CAPE_VERDE": {
                    "backend": "CV",
                    "frontend": "Cape Verde",
                    "code": "132"
                },
                "CAYMAN_ISLANDS": {
                    "backend": "KY",
                    "frontend": "Cayman Islands",
                    "code": "136"
                },
                "CENTRAL_AFRICAN_REPUBLIC": {
                    "backend": "CF",
                    "frontend": "Central African Republic",
                    "code": "140"
                },
                "CHAD": {
                    "backend": "TD",
                    "frontend": "Chad",
                    "code": "148"
                },
                "CHILE": {
                    "backend": "CL",
                    "frontend": "Chile",
                    "code": "152"
                },
                "CHINA": {
                    "backend": "CN",
                    "frontend": "China",
                    "code": "156"
                },
                "CHRISTMAS_ISLAND": {
                    "backend": "CX",
                    "frontend": "Christmas Island",
                    "code": "162"
                },
                "COCOS_KEELING_ISLANDS": {
                    "backend": "CC",
                    "frontend": "Cocos (Keeling) Islands",
                    "code": "166"
                },
                "COLOMBIA": {
                    "backend": "CO",
                    "frontend": "Colombia",
                    "code": "170"
                },
                "COMOROS": {
                    "backend": "KM",
                    "frontend": "Comoros",
                    "code": "174"
                },
                "CONGO": {
                    "backend": "CG",
                    "frontend": "Congo",
                    "code": "178"
                },
                "CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE": {
                    "backend": "CD",
                    "frontend": "Congo, The Democratic Republic of the",
                    "code": "180"
                },
                "COOK_ISLANDS": {
                    "backend": "CK",
                    "frontend": "Cook Islands",
                    "code": "184"
                },
                "COSTA_RICA": {
                    "backend": "CR",
                    "frontend": "Costa Rica",
                    "code": "188"
                },
                "COTE_D.IVOIRE": {
                    "backend": "CI",
                    "frontend": "Cote D.Ivoire",
                    "code": "384"
                },
                "CROATIA": {
                    "backend": "HR",
                    "frontend": "Croatia",
                    "code": "191"
                },
                "CUBA": {
                    "backend": "CU",
                    "frontend": "Cuba",
                    "code": "192"
                },
                "CYPRUS": {
                    "backend": "CY",
                    "frontend": "Cyprus",
                    "code": "196"
                },
                "CZECH_REPUBLIC": {
                    "backend": "CZ",
                    "frontend": "Czech Republic",
                    "code": "203"
                },
                "DENMARK": {
                    "backend": "DK",
                    "frontend": "Denmark",
                    "code": "208"
                },
                "DJIBOUTI": {
                    "backend": "DJ",
                    "frontend": "Djibouti",
                    "code": "262"
                },
                "DOMINICA": {
                    "backend": "DM",
                    "frontend": "Dominica",
                    "code": "212"
                },
                "DOMINICAN_REPUBLIC": {
                    "backend": "DO",
                    "frontend": "Dominican Republic",
                    "code": "214"
                },
                "EAST_TIMOR": {
                    "backend": "TP",
                    "frontend": "East Timor"
                },
                "ECUADOR": {
                    "backend": "EC",
                    "frontend": "Ecuador",
                    "code": "218"
                },
                "EGYPT": {
                    "backend": "EG",
                    "frontend": "Egypt",
                    "code": "818"
                },
                "EL_SALVADOR": {
                    "backend": "SV",
                    "frontend": "El Salvador",
                    "code": "222"
                },
                "EQUATORIAL_GUINEA": {
                    "backend": "GQ",
                    "frontend": "Equatorial Guinea",
                    "code": "226"
                },
                "ERITREA": {
                    "backend": "ER",
                    "frontend": "Eritrea",
                    "code": "232"
                },
                "ESTONIA": {
                    "backend": "EE",
                    "frontend": "Estonia",
                    "code": "233"
                },
                "ETHIOPIA": {
                    "backend": "ET",
                    "frontend": "Ethiopia",
                    "code": "231"
                },
                "FALKLAND_ISLANDS_MALVINAS": {
                    "backend": "FK",
                    "frontend": "Falkland Islands (Malvinas)",
                    "code": "238"
                },
                "FAROE_ISLANDS": {
                    "backend": "FO",
                    "frontend": "Faroe Islands",
                    "code": "234"
                },
                "FIJI": {
                    "backend": "FJ",
                    "frontend": "Fiji",
                    "code": "242"
                },
                "FINLAND": {
                    "backend": "FI",
                    "frontend": "Finland",
                    "code": "246"
                },
                "FRANCE": {
                    "backend": "FR",
                    "frontend": "France",
                    "code": "250"
                },
                "FRENCH_GUIANA": {
                    "backend": "GF",
                    "frontend": "French Guiana",
                    "code": "254"
                },
                "FRENCH_POLYNESIA": {
                    "backend": "PF",
                    "frontend": "French Polynesia",
                    "code": "258"
                },
                "FRENCH_SOUTHERN_TERRITORIES": {
                    "backend": "TF",
                    "frontend": "French Southern Territories",
                    "code": "260"
                },
                "GABON": {
                    "backend": "GA",
                    "frontend": "Gabon",
                    "code": "266"
                },
                "GAMBIA": {
                    "backend": "GM",
                    "frontend": "Gambia",
                    "code": "270"
                },
                "GEORGIA": {
                    "backend": "GE",
                    "frontend": "Georgia",
                    "code": "268"
                },
                "GERMANY": {
                    "backend": "DE",
                    "frontend": "Germany",
                    "code": "276"
                },
                "GHANA": {
                    "backend": "GH",
                    "frontend": "Ghana",
                    "code": "288"
                },
                "GIBRALTAR": {
                    "backend": "GI",
                    "frontend": "Gibraltar",
                    "code": "292"
                },
                "GREECE": {
                    "backend": "GR",
                    "frontend": "Greece",
                    "code": "300"
                },
                "GREENLAND": {
                    "backend": "GL",
                    "frontend": "Greenland",
                    "code": "304"
                },
                "GRENADA": {
                    "backend": "GD",
                    "frontend": "Grenada",
                    "code": "308"
                },
                "GUADELOUPE": {
                    "backend": "GP",
                    "frontend": "Guadeloupe",
                    "code": "312"
                },
                "GUAM": {
                    "backend": "GU",
                    "frontend": "Guam",
                    "code": "316"
                },
                "GUATEMALA": {
                    "backend": "GT",
                    "frontend": "Guatemala",
                    "code": "320"
                },
                "GUINEA": {
                    "backend": "GN",
                    "frontend": "Guinea",
                    "code": "324"
                },
                "GUINEA_BISSAU": {
                    "backend": "GW",
                    "frontend": "Guinea-Bissau",
                    "code": "624"
                },
                "GUYANA": {
                    "backend": "GY",
                    "frontend": "Guyana",
                    "code": "328"
                },
                "HAITI": {
                    "backend": "HT",
                    "frontend": "Haiti",
                    "code": "332"
                },
                "HEARD_ISLAND_AND_MCDONALD_ISLANDS": {
                    "backend": "HM",
                    "frontend": "Heard Island and McDonald Islands",
                    "code": "334"
                },
                "HOLY_SEE_VATICAN_CITY_STATE": {
                    "backend": "VA",
                    "frontend": "Holy See (Vatican City State)",
                    "code": "336"
                },
                "HONDURAS": {
                    "backend": "HN",
                    "frontend": "Honduras",
                    "code": "340"
                },
                "HONG_KONG": {
                    "backend": "HK",
                    "frontend": "Hong Kong",
                    "code": "344"
                },
                "HUNGARY": {
                    "backend": "HU",
                    "frontend": "Hungary",
                    "code": "348"
                },
                "ICELAND": {
                    "backend": "IS",
                    "frontend": "Iceland",
                    "code": "352"
                },
                "INDIA": {
                    "backend": "IN",
                    "frontend": "India",
                    "code": "356"
                },
                "INDONESIA": {
                    "backend": "ID",
                    "frontend": "Indonesia",
                    "code": "360"
                },
                "IRAN_ISLAMIC_REPUBLIC_OF": {
                    "backend": "IR",
                    "frontend": "Iran (Islamic Republic of)",
                    "code": "364"
                },
                "IRAQ": {
                    "backend": "IQ",
                    "frontend": "Iraq",
                    "code": "368"
                },
                "IRELAND": {
                    "backend": "IE",
                    "frontend": "Ireland",
                    "code": "372"
                },
                "ISRAEL": {
                    "backend": "IL",
                    "frontend": "Israel",
                    "code": "376"
                },
                "ITALY": {
                    "backend": "IT",
                    "frontend": "Italy",
                    "code": "380"
                },
                "JAMAICA": {
                    "backend": "JM",
                    "frontend": "Jamaica",
                    "code": "388"
                },
                "JAPAN": {
                    "backend": "JP",
                    "frontend": "Japan",
                    "code": "392"
                },
                "JORDAN": {
                    "backend": "JO",
                    "frontend": "Jordan",
                    "code": "400"
                },
                "KAZAKHSTAN": {
                    "backend": "KZ",
                    "frontend": "Kazakhstan",
                    "code": "398"
                },
                "KENYA": {
                    "backend": "KE",
                    "frontend": "Kenya",
                    "code": "404"
                },
                "KIRIBATI": {
                    "backend": "KI",
                    "frontend": "Kiribati",
                    "code": "296"
                },
                "KOREA_DEMOCRATIC_PEOPLES_REPUBLIC_OF": {
                    "backend": "KP",
                    "frontend": "Korea, Democratic People`s Republic of",
                    "code": "408"
                },
                "KOREA_REPUBLIC_OF": {
                    "backend": "KR",
                    "frontend": "Korea, Republic of",
                    "code": "410"
                },
                "KUWAIT": {
                    "backend": "KW",
                    "frontend": "Kuwait",
                    "code": "414"
                },
                "KYRGYZSTAN": {
                    "backend": "KG",
                    "frontend": "Kyrgyzstan",
                    "code": "417"
                },
                "LAO_PEOPLES_DEMOCRATIC_REPUBLIC": {
                    "backend": "LA",
                    "frontend": "Lao People`s Democratic Republic",
                    "code": "418"
                },
                "LATVIA": {
                    "backend": "LV",
                    "frontend": "Latvia",
                    "code": "428"
                },
                "LEBANON": {
                    "backend": "LB",
                    "frontend": "Lebanon",
                    "code": "422"
                },
                "LESOTHO": {
                    "backend": "LS",
                    "frontend": "Lesotho",
                    "code": "426"
                },
                "LIBERIA": {
                    "backend": "LR",
                    "frontend": "Liberia",
                    "code": "430"
                },
                "LIBYAN_ARAB_JAMAHIRIYA": {
                    "backend": "LY",
                    "frontend": "Libyan Arab Jamahiriya",
                    "code": "434"
                },
                "LIECHTENSTEIN": {
                    "backend": "LI",
                    "frontend": "Liechtenstein",
                    "code": "438"
                },
                "LITHUANIA": {
                    "backend": "LT",
                    "frontend": "Lithuania",
                    "code": "440"
                },
                "LUXEMBOURG": {
                    "backend": "LU",
                    "frontend": "Luxembourg",
                    "code": "442"
                },
                "MACAU": {
                    "backend": "MO",
                    "frontend": "Macau",
                    "code": "446"
                },
                "MACEDONIA_THE_FORMER_YUGOSLAV_REPUBLIC_OF": {
                    "backend": "MK",
                    "frontend": "Macedonia, The Former Yugoslav Republic of",
                    "code": "807"
                },
                "MADAGASCAR": {
                    "backend": "MG",
                    "frontend": "Madagascar",
                    "code": "450"
                },
                "MALAWI": {
                    "backend": "MW",
                    "frontend": "Malawi",
                    "code": "454"
                },
                "MALAYSIA": {
                    "backend": "MY",
                    "frontend": "Malaysia",
                    "code": "458"
                },
                "MALDIVES": {
                    "backend": "MV",
                    "frontend": "Maldives",
                    "code": "462"
                },
                "MALI": {
                    "backend": "ML",
                    "frontend": "Mali",
                    "code": "466"
                },
                "MALTA": {
                    "backend": "MT",
                    "frontend": "Malta",
                    "code": "470"
                },
                "MARSHALL_ISLANDS": {
                    "backend": "MH",
                    "frontend": "Marshall Islands",
                    "code": "584"
                },
                "MARTINIQUE": {
                    "backend": "MQ",
                    "frontend": "Martinique",
                    "code": "474"
                },
                "MAURITANIA": {
                    "backend": "MR",
                    "frontend": "Mauritania",
                    "code": "478"
                },
                "MAURITIUS": {
                    "backend": "MU",
                    "frontend": "Mauritius",
                    "code": "480"
                },
                "MAYOTTE": {
                    "backend": "YT",
                    "frontend": "Mayotte",
                    "code": "175"
                },
                "MEXICO": {
                    "backend": "MX",
                    "frontend": "Mexico",
                    "code": "484"
                },
                "MICRONESIA_FEDERATED_STATES_OF": {
                    "backend": "FM",
                    "frontend": "Micronesia (Federated States of)",
                    "code": "583"
                },
                "MOLDOVA_REPUBLIC_OF": {
                    "backend": "MD",
                    "frontend": "Moldova, Republic of",
                    "code": "498"
                },
                "MONACO": {
                    "backend": "MC",
                    "frontend": "Monaco",
                    "code": "492"
                },
                "MONGOLIA": {
                    "backend": "MN",
                    "frontend": "Mongolia",
                    "code": "496"
                },
                "MONTSERRAT": {
                    "backend": "MS",
                    "frontend": "Montserrat",
                    "code": "500"
                },
                "MOROCCO": {
                    "backend": "MA",
                    "frontend": "Morocco",
                    "code": "504"
                },
                "MOZAMBIQUE": {
                    "backend": "MZ",
                    "frontend": "Mozambique",
                    "code": "508"
                },
                "MYANMAR": {
                    "backend": "MM",
                    "frontend": "Myanmar",
                    "code": "104"
                },
                "NAMIBIA": {
                    "backend": "NA",
                    "frontend": "Namibia",
                    "code": "516"
                },
                "NAURU": {
                    "backend": "NR",
                    "frontend": "Nauru",
                    "code": "520"
                },
                "NEPAL": {
                    "backend": "NP",
                    "frontend": "Nepal",
                    "code": "524"
                },
                "NETHERLANDS": {
                    "backend": "NL",
                    "frontend": "Netherlands",
                    "code": "528"
                },
                "NETHERLANDS_ANTILLES": {
                    "backend": "AN",
                    "frontend": "Netherlands Antilles"
                },
                "NEW_CALEDONIA": {
                    "backend": "NC",
                    "frontend": "New Caledonia",
                    "code": "540"
                },
                "NEW_ZEALAND": {
                    "backend": "NZ",
                    "frontend": "New Zealand",
                    "code": "554"
                },
                "NICARAGUA": {
                    "backend": "NI",
                    "frontend": "Nicaragua",
                    "code": "558"
                },
                "NIGER": {
                    "backend": "NE",
                    "frontend": "Niger",
                    "code": "562"
                },
                "NIGERIA": {
                    "backend": "NG",
                    "frontend": "Nigeria",
                    "code": "566"
                },
                "NIUE": {
                    "backend": "NU",
                    "frontend": "Niue",
                    "code": "570"
                },
                "NORFOLK_ISLAND": {
                    "backend": "NF",
                    "frontend": "Norfolk Island",
                    "code": "574"
                },
                "NORTHERN_MARIANA_ISLANDS": {
                    "backend": "MP",
                    "frontend": "Northern Mariana Islands",
                    "code": "580"
                },
                "NORWAY": {
                    "backend": "NO",
                    "frontend": "Norway",
                    "code": "578"
                },
                "OBSOLETE_SEE_FR_TERRITORY": {
                    "backend": "FX",
                    "frontend": "Obsolete see FR territory"
                },
                "OMAN": {
                    "backend": "OM",
                    "frontend": "Oman",
                    "code": "512"
                },
                "PAKISTAN": {
                    "backend": "PK",
                    "frontend": "Pakistan",
                    "code": "586"
                },
                "PALAU": {
                    "backend": "PW",
                    "frontend": "Palau",
                    "code": "585"
                },
                "PALESTINIAN_TERRITORY_OCCUPIED": {
                    "backend": "PS",
                    "frontend": "Palestinian Territory,Occupied",
                    "code": "275"
                },
                "PANAMA": {
                    "backend": "PA",
                    "frontend": "Panama",
                    "code": "591"
                },
                "PAPUA_NEW_GUINEA": {
                    "backend": "PG",
                    "frontend": "Papua New Guinea",
                    "code": "598"
                },
                "PARAGUAY": {
                    "backend": "PY",
                    "frontend": "Paraguay",
                    "code": "600"
                },
                "PERU": {
                    "backend": "PE",
                    "frontend": "Peru",
                    "code": "604"
                },
                "PHILIPPINES": {
                    "backend": "PH",
                    "frontend": "Philippines",
                    "code": "608"
                },
                "PITCAIRN": {
                    "backend": "PN",
                    "frontend": "Pitcairn",
                    "code": "612"
                },
                "POLAND": {
                    "backend": "PL",
                    "frontend": "Poland",
                    "code": "616"
                },
                "PORTUGAL": {
                    "backend": "PT",
                    "frontend": "Portugal",
                    "code": "620"
                },
                "PUERTO_RICO": {
                    "backend": "PR",
                    "frontend": "Puerto Rico",
                    "code": "630"
                },
                "QATAR": {
                    "backend": "QA",
                    "frontend": "Qatar",
                    "code": "634"
                },
                "REUNION": {
                    "backend": "RE",
                    "frontend": "Reunion",
                    "code": "638"
                },
                "ROMANIA": {
                    "backend": "RO",
                    "frontend": "Romania",
                    "code": "642"
                },
                "RUSSIAN_FEDERATION": {
                    "backend": "RU",
                    "frontend": "Russian Federation",
                    "code": "643"
                },
                "RWANDA": {
                    "backend": "RW",
                    "frontend": "Rwanda",
                    "code": "646"
                },
                "SAINT_HELENA": {
                    "backend": "SH",
                    "frontend": "Saint Helena",
                    "code": "654"
                },
                "SAINT_KITTS_AND_NEVIS": {
                    "backend": "KN",
                    "frontend": "Saint Kitts and Nevis",
                    "code": "659"
                },
                "SAINT_LUCIA": {
                    "backend": "LC",
                    "frontend": "Saint Lucia",
                    "code": "662"
                },
                "SAINT_PIERRE_AND_MIQUELON": {
                    "backend": "PM",
                    "frontend": "Saint Pierre and Miquelon",
                    "code": "666"
                },
                "SAINT_VINCENT_AND_THE_GRENADINES": {
                    "backend": "VC",
                    "frontend": "Saint Vincent and the Grenadines",
                    "code": "670"
                },
                "SAMOA": {
                    "backend": "WS",
                    "frontend": "Samoa",
                    "code": "882"
                },
                "SAN_MARINO": {
                    "backend": "SM",
                    "frontend": "San Marino",
                    "code": "674"
                },
                "SAO_TOME_AND_PRINCIPE": {
                    "backend": "ST",
                    "frontend": "Sao Tome and Principe",
                    "code": "678"
                },
                "SAUDI_ARABIA": {
                    "backend": "SA",
                    "frontend": "Saudi Arabia",
                    "code": "682"
                },
                "SENEGAL": {
                    "backend": "SN",
                    "frontend": "Senegal",
                    "code": "686"
                },
                "SEYCHELLES": {
                    "backend": "SC",
                    "frontend": "Seychelles",
                    "code": "690"
                },
                "SIERRA_LEONE": {
                    "backend": "SL",
                    "frontend": "Sierra Leone",
                    "code": "694"
                },
                "SINGAPORE": {
                    "backend": "SG",
                    "frontend": "Singapore",
                    "code": "702"
                },
                "SLOVAKIA": {
                    "backend": "SK",
                    "frontend": "Slovakia",
                    "code": "703"
                },
                "SLOVENIA": {
                    "backend": "SI",
                    "frontend": "Slovenia",
                    "code": "705"
                },
                "SOLOMON_ISLANDS": {
                    "backend": "SB",
                    "frontend": "Solomon Islands",
                    "code": "090"
                },
                "SOMALIA": {
                    "backend": "SO",
                    "frontend": "Somalia",
                    "code": "706"
                },
                "SOUTH_AFRICA": {
                    "backend": "ZA",
                    "frontend": "South Africa",
                    "code": "710"
                },
                "SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLAND": {
                    "backend": "GS",
                    "frontend": "South Georgia and the South Sandwich Island",
                    "code": "239"
                },
                "SPAIN": {
                    "backend": "ES",
                    "frontend": "Spain",
                    "code": "724"
                },
                "SRI_LANKA": {
                    "backend": "LK",
                    "frontend": "Sri Lanka",
                    "code": "144"
                },
                "SUDAN": {
                    "backend": "SD",
                    "frontend": "Sudan",
                    "code": "729"
                },
                "SURINAME": {
                    "backend": "SR",
                    "frontend": "Suriname",
                    "code": "740"
                },
                "SVALBARD_AND_JAN_MAYEN_ISLANDS": {
                    "backend": "SJ",
                    "frontend": "Svalbard and Jan Mayen Islands",
                    "code": "744"
                },
                "SWAZILAND": {
                    "backend": "SZ",
                    "frontend": "Swaziland",
                    "code": "748"
                },
                "SWEDEN": {
                    "backend": "SE",
                    "frontend": "Sweden",
                    "code": "752"
                },
                "SWITZERLAND": {
                    "backend": "CH",
                    "frontend": "Switzerland",
                    "code": "756"
                },
                "SYRIAN_ARAB_REPUBLIC": {
                    "backend": "SY",
                    "frontend": "Syrian Arab Republic",
                    "code": "760"
                },
                "TAIWAN": {
                    "backend": "TW",
                    "frontend": "Taiwan",
                    "code": "158"
                },
                "TAJIKISTAN": {
                    "backend": "TJ",
                    "frontend": "Tajikistan",
                    "code": "762"
                },
                "TANZANIA_UNITED_REPUBLIC_OF": {
                    "backend": "TZ",
                    "frontend": "Tanzania, United Republic of",
                    "code": "834"
                },
                "THAILAND": {
                    "backend": "TH",
                    "frontend": "Thailand",
                    "code": "764"
                },
                "TOGO": {
                    "backend": "TG",
                    "frontend": "Togo",
                    "code": "768"
                },
                "TOKELAU": {
                    "backend": "TK",
                    "frontend": "Tokelau",
                    "code": "772"
                },
                "TONGA": {
                    "backend": "TO",
                    "frontend": "Tonga",
                    "code": "776"
                },
                "TRINIDAD_AND_TOBAGO": {
                    "backend": "TT",
                    "frontend": "Trinidad and Tobago",
                    "code": "780"
                },
                "TUNISIA": {
                    "backend": "TN",
                    "frontend": "Tunisia",
                    "code": "788"
                },
                "TURKEY": {
                    "backend": "TR",
                    "frontend": "Turkey",
                    "code": "792"
                },
                "TURKMENISTAN": {
                    "backend": "TM",
                    "frontend": "Turkmenistan",
                    "code": "795"
                },
                "TURKS_AND_CAICOS_ISLANDS": {
                    "backend": "TC",
                    "frontend": "Turks and Caicos Islands",
                    "code": "796"
                },
                "TUVALU": {
                    "backend": "TV",
                    "frontend": "Tuvalu",
                    "code": "798"
                },
                "UGANDA": {
                    "backend": "UG",
                    "frontend": "Uganda",
                    "code": "800"
                },
                "UKRAINE": {
                    "backend": "UA",
                    "frontend": "Ukraine",
                    "code": "804"
                },
                "UNITED_ARAB_EMIRATES": {
                    "backend": "AE",
                    "frontend": "United Arab Emirates",
                    "code": "784"
                },
                "UNITED_KINGDOM": {
                    "backend": "GB",
                    "frontend": "United Kingdom",
                    "code": "826"
                },
                "UNITED_STATES": {
                    "backend": "US",
                    "frontend": "United States",
                    "code": "840"
                },
                "UNITED_STATES_MINOR_OUTLYING_ISLANDS": {
                    "backend": "UM",
                    "frontend": "United States Minor Outlying Islands",
                    "code": "581"
                },
                "UNKNOWN": {
                    "backend": "ZZ",
                    "frontend": "Unknown"
                },
                "URUGUAY": {
                    "backend": "UY",
                    "frontend": "Uruguay",
                    "code": "858"
                },
                "UZBEKISTAN": {
                    "backend": "UZ",
                    "frontend": "Uzbekistan",
                    "code": "860"
                },
                "VANUATU": {
                    "backend": "VU",
                    "frontend": "Vanuatu",
                    "code": "548"
                },
                "VENEZUELA": {
                    "backend": "VE",
                    "frontend": "Venezuela",
                    "code": "862"
                },
                "VIET_NAM": {
                    "backend": "VN",
                    "frontend": "Viet Nam",
                    "code": "704"
                },
                "VIRGIN_ISLANDS_BRITISH": {
                    "backend": "VG",
                    "frontend": "Virgin Islands, British",
                    "code": "092"
                },
                "VIRGIN_ISLANDS_U.S.": {
                    "backend": "VI",
                    "frontend": "Virgin Islands, U.S.",
                    "code": "850"
                },
                "WALLIS_AND_FUTUNA": {
                    "backend": "WF",
                    "frontend": "Wallis and Futuna",
                    "code": "876"
                },
                "WESTERN_SAHARA": {
                    "backend": "EH",
                    "frontend": "Western Sahara",
                    "code": "732"
                },
                "YEMEN": {
                    "backend": "YE",
                    "frontend": "Yemen",
                    "code": "887"
                },
                "YUGOSLAVIA": {
                    "backend": "YU",
                    "frontend": "Yugoslavia"
                },
                "ZAMBIA": {
                    "backend": "ZM",
                    "frontend": "Zambia",
                    "code": "894"
                },
                "ZIMBABWE": {
                    "backend": "ZW",
                    "frontend": "Zimbabwe",
                    "code": "716"
                }
            });
        },
        /**
         * Get the enum entry for the country based on numerical country code
         * Returns null if no matching country is found
         * 
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  05 Feb 2019
         * 
         * @param string sCode - The numerical country code as a string
         */
        getCountryObjectByCode: function getCountryObjectByCode(sCode) {
            // Get the country enum
            var objCountryEnum = this.get("country");
            // Get the key list
            var arrKeys = Object.keys(objCountryEnum);

            // Loop over keys
            for (var i = 0; i < arrKeys.length; i++) {
                // Get and compare 
                var objCountry = objCountryEnum[arrKeys[i]];
                if (objCountry.code === sCode) {
                    // Return country if the code matches
                    return objCountry;
                }
            }
            // In case no country is found by code
            return null;
        }
    });
});