define("a24-ember-staffshift-core/routes/abstractReactRoute", ["exports", "a24-ember-staffshift-core/routes/abstractRoute"], function (exports, _abstractRoute) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _abstractRoute.default.extend({

        activate: function activate() {
            this._super.apply(this, arguments);
            Ember.run.schedule("afterRender", function () {
                $(".reactTabEmberPage").addClass("reactTabEmberPageSpacing");
            });
        },
        deactivate: function deactivate() {
            $(".reactTabEmberPage").removeClass("reactTabEmberPageSpacing");
            this._super.apply(this, arguments);
        }
    });
});