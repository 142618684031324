define("a24-ember-staffshift-candidate-availability/components/day-view", ["exports", "a24-ember-staffshift-candidate-availability/templates/components/day-view", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _dayView, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objAvailabilityService: null,
        objBookedShiftsService: null,
        /*
          Services
        */
        _objUserSession: Ember.inject.service("user-session"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objAvailabilityStrings: Ember.inject.service("a24-strings-staffshift-availability"),
        _objEnums: Ember.inject.service("a24-enums"),
        _objStrings: Ember.inject.service("a24-strings"),
        _objConstantsEmberLibService: Ember.inject.service("a24-constants-ember-lib"),

        /*
          Default values
        */
        layout: _dayView.default,
        classNames: ["day-view"],

        /*
          @property sDate
          The current date.
        */
        sDate: null,

        /*
          @property objBookedShiftData
          A single booked shift data.
          Gets setup on onDateChange().
        */
        objBookedShiftData: null,

        /*
          @property arrBookedData
          Array of all booked shifts data.
          Gets setup on onDateChange().
        */
        arrBookedData: null,

        /*
          @property objAvailData
          Availability data for the current day.
        */
        objAvailData: null,

        /*
          Promises.
          TODO describe better
        */
        objAvailabilityPromise: null,
        objBookingPromise: null,

        /*
          @property bDayIsLoading
          @property bAvailabilityLoading
          @property bBookingLoading
          Set of booleans to handle loading status.
        */
        bDayIsLoading: false,
        bAvailabilityLoading: false,
        bBookingLoading: false,

        /*
          @property _bShowUnsavedData
          TODO describe better
        */
        _bShowUnsavedData: false,

        /*
          @property bEditMode
          true while editing current day shifts.
        */
        bEditMode: false,

        /*
          @property objStoredData
          @property objEditData
          Data before going on edit mode (objEditData) and new data (objEditData).
        */
        objStoredData: null,
        objEditData: null,

        /*
          @property arrAvailabilityActions
          An array of objects each specifying their google material icon name and an action that is invoked when the icon is clicked on.
        */
        arrAvailabilityActions: null,

        objSaveAvailability: null,

        /*
          @property sCandidateEmail
          TODO describe better
        */
        sCandidateEmail: null,

        init: function init() {
            this._super.apply(this, arguments);

            this.onDateChange();
            this.onLoadingChange();
        },
        onAvailabilityActionsChange: Ember.observer("sDate", "bDayIsLoading", function () {
            if (Ember.get(this, "bDayIsLoading")) {
                Ember.set(this, "arrAvailabilityActions", null);
            } else {
                var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
                objDate.setDateFromRest(Ember.get(this, "sDate"), true);
                objDate.setTimeToStartOfDay();

                var objTodayDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
                objTodayDate.setTimeToStartOfDay();

                var objThis = this;

                if (!objDate.isBefore(objTodayDate)) {
                    Ember.set(this, "arrAvailabilityActions", [{
                        sTitle: Ember.get(this, "_objStrings").getString("edit"),
                        sIcon: "mode_edit",
                        executeCardAction: function executeCardAction() {
                            objThis.onEditState();
                        }
                    }]);
                } else {
                    Ember.set(this, "arrAvailabilityActions", null);
                }
            }
        }),
        onEditState: function onEditState() {
            this.set("objStoredData", Ember.copy(Ember.get(this, "objAvailData"), true));
            this.set("objEditData", Ember.copy(Ember.get(this, "objAvailData"), true));

            Ember.set(this, "bEditMode", true);
        },
        onDateChange: Ember.observer("sDate", function () {
            var _this = this;

            var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
            objDate.setDateFromRest(Ember.get(this, "sDate"), true);

            Ember.set(this, "sHeader", objDate.getDateFormat());

            objDate.setTimeToStartOfDay();
            var sTodayStart = objDate.getDateAsISOWithoutMiliFormat();
            objDate.setTimeToEndOfDay();
            var sTodayEnd = objDate.getDateAsISOWithoutMiliFormat();

            var sTodayDateOnly = objDate.getDateStringForRest(true);

            var objYesterday = $.extend(true, objDate);
            objYesterday.subtractTimeFromDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_DAYS, 1);
            objYesterday.setTimeToStartOfDay();
            var sYesterdayStart = objYesterday.getDateAsISOWithoutMiliFormat();

            var sCandidateId = this.get("_objUserSession").getUserContextReference().mId;

            var objAvailabilityService = Ember.get(this, "objAvailabilityService").getAvailability(this, "arrAvailabilityData", sCandidateId, function (objData) {
                if (a24Core.isEmpty(objData) || a24Core.isEmpty(objData[0])) {
                    objData = [{}];
                }

                var sEarly = objData[0].sEarly || Ember.get(_this, "_objAvailabilityStrings").getString("unknown");
                var sLate = objData[0].sLate || Ember.get(_this, "_objAvailabilityStrings").getString("unknown");
                var sNight = objData[0].sNight || Ember.get(_this, "_objAvailabilityStrings").getString("unknown");

                var objParsedData = {
                    objEarly: {
                        sState: sEarly,
                        sLabel: sEarly
                    },
                    objLate: {
                        sState: sLate,
                        sLabel: sLate
                    },
                    objNight: {
                        sState: sNight,
                        sLabel: sNight
                    }
                };

                Ember.set(_this, "objAvailData", objParsedData);

                Ember.set(_this, "bAvailabilityLoading", false);
            }, null, {
                dAvailabilityDate: {
                    mValue: sTodayDateOnly
                }
            });

            /**
             * The singular day query can be done by taking any booking that starts either
             * the current day or the day before and not ending the day before. Due to
             * the max shift length of 24 hours, this is a fine approach. A default order
             * can be placed on dtStart
             */
            var objBookingService = Ember.get(this, "objBookedShiftsService").getBookedShifts(this, "objBookedShiftData", sCandidateId, function (arrData) {
                if (a24Core.isEmpty(arrData)) {
                    arrData = [];
                }
                _this.setBookingDataFromResponse(arrData);
                Ember.set(_this, "bBookingLoading", false);
            }, null, {
                "from-dtStart": {
                    mValue: encodeURIComponent(sYesterdayStart) // start of yesterday
                },
                "to-dtStart": {
                    mValue: encodeURIComponent(sTodayEnd) // end of today
                },
                "from-dtEnd": {
                    mValue: encodeURIComponent(sTodayStart) // start of today
                },
                bExtraData: {
                    mValue: true
                },
                sortBy: {
                    mValue: "dtStart"
                }
            });

            var objPromiseList = {
                objAvailabilityPromise: objAvailabilityService,
                objBookingPromise: objBookingService
            };

            Ember.set(this, "bDayIsLoading", true);
            Ember.set(this, "bAvailabilityLoading", true);
            Ember.set(this, "bBookingLoading", true);

            var objThis = this;

            a24RSVP.configure(this, objPromiseList, [{
                arrRequiredResponses: ["objAvailabilityPromise", "objBookingPromise"],
                funcOnComplete: function funcOnComplete() {
                    Ember.set(objThis, "bDayIsLoading", false);
                }
            }]);
        }),
        onLoadingChange: Ember.observer("bDayIsLoading", function () {
            var _this2 = this;

            Ember.run.next(function () {
                if (!a24Core.isEmpty(_this2.get("onLoadingStateChange"))) {
                    _this2.get("onLoadingStateChange")(Ember.get(_this2, "bDayIsLoading"));
                }
            });
        }),
        setBookingDataFromResponse: function setBookingDataFromResponse(arrData) {
            var arrBookedData = [];

            for (var i = 0; i < arrData.length; i++) {
                arrBookedData.push(this.createBookingDataConfigFromDataItem(arrData[i]));
            }

            Ember.set(this, "arrBookedData", arrBookedData);
        },
        createBookingDataConfigFromDataItem: function createBookingDataConfigFromDataItem(objShiftData) {
            var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
            objDate.setDateFromRest(objShiftData.dtStart);

            var objEndDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
            objEndDate.setDateFromRest(objShiftData.dtEnd);

            var sTime = objDate.getTimeFormat() + " - " + objEndDate.getTimeFormat();

            var arrBookingItem = [{
                sIcon: "access_time",
                sValue: objDate.getDateFormat()
            }, {
                sIcon: "alarm",
                sValue: sTime
            }];

            if (!a24Core.isEmpty(objShiftData.sJobTitleName)) {
                arrBookingItem.push({
                    sIcon: "folder",
                    sValue: objShiftData.sJobTitleName
                });
            }

            if (!a24Core.isEmpty(objShiftData.arrClientAddress)) {
                // At the time of implementation, it was discussed and no one could find any reason
                // to not use just the first item on the array of addresses.
                var objAddress = objShiftData.arrClientAddress[0];

                var arrAddressParts = [objAddress.sAddressLine1, objAddress.sAddressLine2, objAddress.sCityTown, objAddress.sPostalCode, objAddress.sProvince, this.get("_objEnums").getFrontendValue("country", objAddress.sCountryCode)];

                arrAddressParts = arrAddressParts.filter(function (objItem) {
                    return !a24Core.isEmpty(objItem);
                });

                if (!a24Core.isEmpty(arrAddressParts)) {
                    var sAddress = arrAddressParts.join(", ");

                    arrBookingItem.push({
                        sIcon: "location_on",
                        sValue: sAddress
                    });
                }
            }

            if (!a24Core.isEmpty(objShiftData.sBookedAgencyName)) {
                arrBookingItem.push({
                    sLabel: Ember.get(this, "_objAvailabilityStrings").getString("agency"),
                    sValue: objShiftData.sBookedAgencyName
                });
            }

            if (!a24Core.isEmpty(objShiftData.sReportsToName)) {
                arrBookingItem.push({
                    sLabel: Ember.get(this, "_objAvailabilityStrings").getString("reportsTo"),
                    sValue: objShiftData.sReportsToName
                });
            }

            if (!a24Core.isEmpty(objShiftData.sPositionDescription)) {
                arrBookingItem.push({
                    sLabel: Ember.get(this, "_objAvailabilityStrings").getString("descriptionOfPosition"),
                    sValue: objShiftData.sPositionDescription,
                    bWrap: true
                });
            }

            if (!a24Core.isEmpty(objShiftData.sStaffingInstruction)) {
                arrBookingItem.push({
                    sLabel: Ember.get(this, "_objAvailabilityStrings").getString("staffingInstructions"),
                    sValue: objShiftData.sStaffingInstruction,
                    bWrap: true
                });
            }

            var objBookedShiftData = {
                sAvatarUrl: objShiftData.sAgencyAvatarUrl,
                sLetter: objShiftData.sSiteName.substring(0, 1).toUpperCase(),
                sHeader: objShiftData.sSiteName,
                sSubHeader: objShiftData.sWardName,
                sShiftId: objShiftData.id,
                arrData: arrBookingItem
            };

            return objBookedShiftData;
        },

        /**
         * Looks up the next item for the edit state to change to
         *
         * @author Michael Banrard <michael.barnard@a24group.com>
         * @since  13 September 2017
         */
        lookupNextAvailabilityState: function lookupNextAvailabilityState(sState) {
            var arrOrder = [Ember.get(this, "_objAvailabilityStrings").getString("unknown"), Ember.get(this, "_objAvailabilityStrings").getString("available"), Ember.get(this, "_objAvailabilityStrings").getString("unavailable"), Ember.get(this, "_objAvailabilityStrings").getString("unknown")];

            return arrOrder[arrOrder.indexOf(sState) + 1];
        },
        /**
         * Check for unsaved data then present a popup if any is found, else sets back to view mode
         *
         * @author Michael Banrard <michael.barnard@a24group.com>
         * @since  13 September 2017
         */
        cancelChangesWithUnsavedCheck: function cancelChangesWithUnsavedCheck() {
            var objStoredData = Ember.get(this, "objStoredData");
            var objEditData = Ember.get(this, "objEditData");

            var bDataUnchanged = objStoredData.objEarly.sState === objEditData.objEarly.sState && objStoredData.objLate.sState === objEditData.objLate.sState && objStoredData.objNight.sState === objEditData.objNight.sState;

            if (bDataUnchanged) {
                Ember.set(this, "bEditMode", false);
            } else {
                Ember.set(this, "_bShowUnsavedData", true);
            }
        },

        onSave: function onSave() {
            var _this3 = this;

            Ember.set(this, "bEditPopupLoading", true);

            this.get("_objSnackbarService").showSnackbarMessage(Ember.get(this, "_objAvailabilityStrings").getString("updatingAvailability"));

            var sCandidateId = this.get("_objUserSession").getUserContextReference().mId;

            var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
            objDate.setDateFromRest(Ember.get(this, "sDate"), true);

            var sDate = objDate.getDateStringForRest(true);

            var objEdited = Ember.get(this, "objEditData");

            var objPayload = {
                arrAvailability: [{
                    dAvailabilityDate: sDate,
                    sEarly: objEdited.objEarly.sState,
                    sLate: objEdited.objLate.sState,
                    sNight: objEdited.objNight.sState
                }]
            };

            Ember.get(this, "objAvailabilityService").updateAvailability(this, "objSaveAvailability", sCandidateId, function (objData) {
                if (!a24Core.isEmpty(objData.arrWarnings)) {
                    _this3.onDateChange();
                    _this3.get("_objSnackbarService").showSnackbarMessage(Ember.get(_this3, "_objAvailabilityStrings").getString("availabilityPartiallyUpdated"));
                } else {
                    Ember.set(_this3, "objAvailData", Ember.get(_this3, "objEditData"));

                    _this3.get("_objSnackbarService").showSnackbarMessage(Ember.get(_this3, "_objAvailabilityStrings").getString("availabilityUpdated"));
                }
                Ember.set(_this3, "bEditPopupLoading", false);
                Ember.set(_this3, "bEditMode", false);
            }, null, objPayload);
        },

        actions: {
            onShiftClick: function onShiftClick(sPeriod, objHash) {
                var sState = objHash.sState;
                sState = this.lookupNextAvailabilityState(sState);

                Ember.set(this, "objEditData." + sPeriod + ".sState", sState);
                Ember.set(this, "objEditData." + sPeriod + ".sLabel", sState);
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                Ember.set(this, "bEditMode", false);
            },
            onEditPopupConfirm: function onEditPopupConfirm() {
                this.onSave();
            },
            onPopupEditCancel: function onPopupEditCancel() {
                this.cancelChangesWithUnsavedCheck();
            }
        }
    });
});