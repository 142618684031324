define("a24-ember-staffshift-candidate-availability/components/agency-day-view", ["exports", "a24-ember-staffshift-candidate-availability/templates/components/agency-day-view", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _agencyDayView, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _agencyDayView.default,
        classNames: ["day-view"],

        sDate: null,
        sCandidateEmail: null,

        bDayIsLoading: false,
        bAvailabilityLoading: false,
        bBookingLoading: false,
        _bNumbersLoading: true,
        bEditMode: false,

        objBookedShiftData: null,
        objAvailData: null,
        objBookingPromise: null,
        objStoredData: null,
        objEditData: null,

        arrAvailabilityActions: null,

        objAvailabilityService: null,
        objBookedShiftsService: null,
        objEmailRestService: null,
        objSaveAvailability: null,
        arrNumbers: null,
        _bHasMobileNumber: null,
        _bShowUnsavedData: false,
        _arrBookings: null,
        _arrEntityActions: null,

        _objAvailabilityPromise: null,
        _objEntityActionShiftPromise: null,

        _bReactPopupOpen: false,
        _sIFrameUrl: "",
        _bReactPopupLoading: false,
        _objReactPopupPostData: null,

        _ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),
        _sReactGatewayUrl: Ember.computed.readOnly("_ENV.sReactGatewayUrl"),

        //TODO fix hard coded string in hbs for Shifts

        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objAvailabilityStrings: Ember.inject.service("a24-strings-staffshift-availability"),
        _objStrings: Ember.inject.service("a24-strings"),
        _objSSCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objConstantsEmberLibService: Ember.inject.service("a24-constants-ember-lib"),
        _objStaffingShiftDetailsService: Ember.inject.service("rest-staffing-shift-details"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objRestEntityActionShift: Ember.inject.service("rest-entity-action-shift"),
        _bBookingOrNumbersLoading: Ember.computed("bBookingLoading", "_bNumbersLoading", function () {
            return Ember.get(this, "bBookingLoading") || Ember.get(this, "_bNumbersLoading");
        }),
        init: function init() {
            this._super.apply(this, arguments);

            this.set("_sIFrameUrl", this.get("_sReactGatewayUrl"));

            this.onDateChange();
            this.onLoadingChange();
            this.onNumbersChange();
        },
        onAvailabilityActionsChange: Ember.observer("sDate", "bDayIsLoading", function () {
            if (Ember.get(this, "bDayIsLoading")) {
                Ember.set(this, "arrAvailabilityActions", null);
            } else {
                var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
                objDate.setDateFromRest(Ember.get(this, "sDate"), true);
                objDate.setTimeToStartOfDay();

                var objTodayDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
                objTodayDate.setTimeToStartOfDay();

                var objThis = this;

                if (!objDate.isBefore(objTodayDate)) {
                    Ember.set(this, "arrAvailabilityActions", [{
                        sTitle: Ember.get(this, "_objStrings").getString("edit"),
                        sIcon: "mode_edit",
                        executeCardAction: function executeCardAction() {
                            objThis.onEditState();
                        }
                    }]);
                } else {
                    Ember.set(this, "arrAvailabilityActions", null);
                }
            }
        }),
        onEditState: function onEditState() {
            this.set("objStoredData", Ember.copy(Ember.get(this, "objAvailData"), true));
            this.set("objEditData", Ember.copy(Ember.get(this, "objAvailData"), true));

            Ember.set(this, "bEditMode", true);
        },
        onNumbersChange: Ember.observer("arrNumbers", function () {
            this.set("_bHasMobileNumber", false);
            var arrNumbers = this.get("arrNumbers");
            if (arrNumbers !== null) {
                for (var i = 0; i < arrNumbers.length; i++) {
                    if ((arrNumbers[i].type === "mobile" || arrNumbers[i].type === "whatsapp") && !a24Core.isEmpty(arrNumbers[i].number)) {
                        this.set("_bHasMobileNumber", true);
                    }
                }

                this.set("_bNumbersLoading", false);
            }
        }),
        onDateChange: Ember.observer("sDate", function () {
            var _this = this;

            var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
            objDate.setDateFromRest(Ember.get(this, "sDate"), true);

            Ember.set(this, "sHeader", objDate.getDateFormat() + " | " + Ember.get(this, "_objAvailabilityStrings").getString("availability"));

            objDate.setTimeToStartOfDay();
            var sTodayStart = objDate.getDateStringForRest();
            objDate.setTimeToEndOfDay();
            var sTodayEnd = objDate.getDateStringForRest();

            var sTodayDateOnly = objDate.getDateStringForRest(true);

            var objYesterday = $.extend(true, objDate);
            objYesterday.subtractTimeFromDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_DAYS, 1);
            objYesterday.setTimeToStartOfDay();
            var sYesterdayStart = objYesterday.getDateStringForRest();

            var sCandidateId = "";
            if (Ember.get(this, "objAvailabilityService.sContext") === Ember.get(this, "_objStaffshiftCoreConstants.CONTEXT_CANDIDATE")) {
                sCandidateId = this.get("_objUserSession").getUserContextReference().mId;
            } else {
                var objCandidateSearchViewParams = this.get("_objNavigation.objRouteParams")["index.agency-candidate-search-view"];

                sCandidateId = objCandidateSearchViewParams.candidate_id;
            }

            var objAvailabilityService = Ember.get(this, "objAvailabilityService").getAvailability(this, "_objAvailabilityPromise", sCandidateId, function () {}, null, {
                dAvailabilityDate: {
                    mValue: sTodayDateOnly
                }
            });

            /**
             * The singular day query can be done by taking any booking that starts either
             * the current day or the day before and not ending the day before. Due to
             * the max shift length of 24 hours, this is a fine approach. A default order
             * can be placed on dtStart
             */
            var objBookingService = Ember.get(this, "objBookedShiftsService").getShifts(this, "objBookedShiftData", {
                start_date: {
                    bEncode: true,
                    bFromAndTo: true,
                    mValue: {
                        sFrom: sYesterdayStart,
                        sTo: sTodayEnd
                    }
                },
                end_date: {
                    bEncode: true,
                    bFrom: true,
                    mValue: sTodayStart
                },
                candidate_id: {
                    bEncode: true,
                    mValue: sCandidateId
                }
            }, { start_date: { mValue: "asc", bSortBy: true } }, 1, 1000, function () {}, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () {
                Ember.set(_this, "_arrBookings", []);
                Ember.set(_this, "bBookingLoading", false);
            }));

            var objPromiseList = {
                _objAvailabilityProperty: objAvailabilityService,
                _objBookingPromise: objBookingService
            };

            Ember.set(this, "bDayIsLoading", true);
            Ember.set(this, "bAvailabilityLoading", true);
            Ember.set(this, "bBookingLoading", true);

            var objThis = this;

            Ember.copy(a24RSVP).configure(this, objPromiseList, [{
                arrRequiredResponses: ["_objAvailabilityProperty", "_objBookingPromise"],
                funcOnComplete: function funcOnComplete(objResponses) {

                    if (!a24Core.isEmpty(objResponses._objAvailabilityProperty) && !a24Core.isEmpty(objResponses._objAvailabilityProperty.objHeaders) && objResponses._objAvailabilityProperty.objHeaders.statusText === "abort") {
                        return;
                    }
                    if (!a24Core.isEmpty(objResponses._objBookingPromise) && !a24Core.isEmpty(objResponses._objBookingPromise.objHeaders) && objResponses._objBookingPromise.objHeaders.statusText === "abort") {
                        return;
                    }

                    if (!a24Core.isEmpty(objResponses._objAvailabilityProperty)) {
                        var objData = objResponses._objAvailabilityProperty.objResponse;

                        if (a24Core.isEmpty(objData) || a24Core.isEmpty(objData[0])) {
                            objData = [{}];
                        }

                        var sEarly = objData[0].sEarly || Ember.get(_this, "_objAvailabilityStrings").getString("unknown");
                        var sLate = objData[0].sLate || Ember.get(_this, "_objAvailabilityStrings").getString("unknown");
                        var sNight = objData[0].sNight || Ember.get(_this, "_objAvailabilityStrings").getString("unknown");

                        var objParsedData = {
                            objEarly: {
                                sState: sEarly,
                                sLabel: sEarly
                            },
                            objLate: {
                                sState: sLate,
                                sLabel: sLate
                            },
                            objNight: {
                                sState: sNight,
                                sLabel: sNight
                            }
                        };

                        Ember.set(_this, "objAvailData", objParsedData);

                        Ember.set(_this, "bAvailabilityLoading", false);
                    }

                    if (!a24Core.isEmpty(objResponses._objBookingPromise)) {
                        var arrData = objResponses._objBookingPromise.objResponse;
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }
                        Ember.set(_this, "_arrBookings", arrData);
                        Ember.set(_this, "bBookingLoading", false);
                        _this.getBookedShiftsEntityAction(arrData);
                    } else {
                        Ember.set(_this, "_arrBookings", []);
                        Ember.set(_this, "bBookingLoading", false);
                        _this.set("_arrEntityActions", []);
                    }

                    Ember.set(objThis, "bDayIsLoading", false);
                }
            }]);
        }),
        getBookedShiftsEntityAction: function getBookedShiftsEntityAction(arrBookings) {
            var _this2 = this;

            var arrShiftIds = [];
            for (var i = 0; i < arrBookings.length; i++) {
                arrShiftIds.push(arrBookings[i].shift_id);
            }

            var objQuery = {
                shift_ids: { mValue: arrShiftIds.join(",") }
            };

            this.get("_objRestEntityActionShift").getShiftsEntityActions(this, "_objEntityActionShiftPromise", objQuery, function (arrResponse) {
                _this2.set("_arrEntityActions", arrResponse);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                _this2.set("_arrEntityActions", []);
            }));
        },
        onLoadingChange: Ember.observer("bDayIsLoading", function () {
            var _this3 = this;

            Ember.run.next(function () {
                if (!a24Core.isEmpty(_this3.get("onLoadingStateChange"))) {
                    _this3.get("onLoadingStateChange")(Ember.get(_this3, "bDayIsLoading"));
                }
            });
        }),

        lookupNextAvailabilityState: function lookupNextAvailabilityState(sState) {
            var arrOrder = [Ember.get(this, "_objAvailabilityStrings").getString("unknown"), Ember.get(this, "_objAvailabilityStrings").getString("available"), Ember.get(this, "_objAvailabilityStrings").getString("unavailable"), Ember.get(this, "_objAvailabilityStrings").getString("unknown")];

            return arrOrder[arrOrder.indexOf(sState) + 1];
        },

        cancelChangesWithUnsavedCheck: function cancelChangesWithUnsavedCheck() {
            var objStoredData = Ember.get(this, "objStoredData");
            var objEditData = Ember.get(this, "objEditData");

            var bDataUnchanged = objStoredData.objEarly.sState === objEditData.objEarly.sState && objStoredData.objLate.sState === objEditData.objLate.sState && objStoredData.objNight.sState === objEditData.objNight.sState;

            if (bDataUnchanged) {
                Ember.set(this, "bEditMode", false);
            } else {
                Ember.set(this, "_bShowUnsavedData", true);
            }
        },

        onSave: function onSave() {
            var _this4 = this;

            Ember.set(this, "bEditPopupLoading", true);

            this.get("_objSnackbarService").showSnackbarMessage(Ember.get(this, "_objAvailabilityStrings").getString("updatingAvailability"));

            var objCandidateSearchViewParams = this.get("_objNavigation.objRouteParams")["index.agency-candidate-search-view"];

            var sCandidateId = objCandidateSearchViewParams.candidate_id;

            var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
            objDate.setDateFromRest(Ember.get(this, "sDate"), true);

            var sDate = objDate.getDateStringForRest(true);

            var objEdited = Ember.get(this, "objEditData");

            var objPayload = {
                arrAvailability: [{
                    dAvailabilityDate: sDate,
                    sEarly: objEdited.objEarly.sState,
                    sLate: objEdited.objLate.sState,
                    sNight: objEdited.objNight.sState
                }]
            };

            Ember.get(this, "objAvailabilityService").updateAvailability(this, "objSaveAvailability", sCandidateId, function (objData) {
                if (!a24Core.isEmpty(objData.arrWarnings)) {
                    _this4.onDateChange();
                    _this4.get("_objSnackbarService").showSnackbarMessage(Ember.get(_this4, "_objAvailabilityStrings").getString("availabilityPartiallyUpdated"));
                } else {
                    Ember.set(_this4, "objAvailData", Ember.get(_this4, "objEditData"));

                    _this4.get("_objSnackbarService").showSnackbarMessage(Ember.get(_this4, "_objAvailabilityStrings").getString("availabilityUpdated"));
                }
                Ember.set(_this4, "bEditPopupLoading", false);
                Ember.set(_this4, "bEditMode", false);
            }, null, objPayload);
        },

        actions: {
            onShiftClick: function onShiftClick(sPeriod, objHash) {
                var sState = objHash.sState;
                sState = this.lookupNextAvailabilityState(sState);

                Ember.set(this, "objEditData." + sPeriod + ".sState", sState);
                Ember.set(this, "objEditData." + sPeriod + ".sLabel", sState);
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                Ember.set(this, "bEditMode", false);
            },
            onEditPopupConfirm: function onEditPopupConfirm() {
                this.onSave();
            },
            onPopupEditCancel: function onPopupEditCancel() {
                this.cancelChangesWithUnsavedCheck();
            },
            onCancelShiftAction: function onCancelShiftAction(arrShifts) {
                if (!a24Core.isEmpty(arrShifts)) {
                    // open react popup
                    this.set("_bReactPopupOpen", true);
                    this.set("_objReactPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "agency/cancelShift/cancelShiftPopup",
                            data: {
                                shifts: arrShifts
                            }
                        }
                    });
                }
            },
            onRecieveReactData: function onRecieveReactData(objData) {
                if (objData.type === "app_ready" || objData.type === "router_ready") {
                    this.set("_bReactPopupLoading", false);
                } else if (objData.type === "refresh") {
                    this.onDateChange();
                    Ember.get(this, "_objSnackbarService").showSnackbarMessage(Ember.get(this, "_objSSCoreStrings").getHTMLTokenString("updateItemDelay"));
                } else if (objData.type === "close") {
                    this.set("_bReactPopupOpen", false);
                }
            }
        }
    });
});