define("a24-ember-candidate-profile/components/candidate-skills", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-skills", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _candidateSkills, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _candidateSkills.default,
        objSettingsRestService: null,
        sCandidateId: null,
        sCandidateType: null,
        sCandidateTypeName: null,
        objCandidateSkillsRestService: null,
        objCandidateStatedSkillsRestService: null,

        _bAllowEdit: true,
        _bServerError: false,
        _bLoading: true,
        _bNoData: true,
        _bPopupLoading: true,
        _bPopupSkillLoading: false,
        _bPopupDeleteLoading: false,
        _bPopupVerifyLoading: false,
        _bShowManagePopup: false,
        _bChangesMadeToSkills: false,
        _bShowSkillEdit: false,
        _bIsAdd: false,
        _bShowDeleteConfirm: false,
        _bShowVerifyConfirm: false,
        _bShowUnsavedData: false,
        _bShowSkillManagePopup: false,

        _sFilterCurrentSkillsValue: "",
        _sFilterSkillsValue: "",
        _sSkillId: null,
        _sCardHeader: null,
        _sManagePopupHeader: null,
        _sManageSkillPopupHeader: null,
        _sAddEditSkillHeader: null,

        _objCandidateSkillsServicePromise: null,
        _objCandidateStatedSkillsServicePromise: null,
        _objSystemServicePromise: null,
        _objSpecialPopupAction: null,
        _objSpecialAddEditSkillPopupAction: null,
        _objOriginalData: null,

        _arrSkills: null,
        _arrSystemSkills: null,
        _arrHeaderActions: null,
        _arrSkillsSortComponents: null,
        _arrSortComponents: null,
        _arrCandidateSkillsSortComponent: null,
        _arrGroupings: null,
        _arrCandidateSkills: null,
        _arrCandidateSkillManage: null,

        _objUserSession: Ember.inject.service("user-session"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objFabButtonService: Ember.inject.service("fab-button"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objCandidateEnums: Ember.inject.service("a24-enums-candidate-profile"),
        _objConstantsEmberLibService: Ember.inject.service("a24-constants-ember-lib"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),

        _objSystemService: Ember.inject.service("rest-system"),

        _bIsAgencyGroup: Ember.computed("objCandidateSkillsRestService.sContext", function () {
            return this.get("objCandidateSkillsRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP");
        }),

        _bIsAgency: Ember.computed("objCandidateSkillsRestService.sContext", function () {
            return this.get("objCandidateSkillsRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY");
        }),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_objSpecialPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._onPopupManageCancel();
                }
            });

            this.set("_objSpecialManageSkillPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._onPopupManageSkillCancel();
                }
            });

            this.set("_objSpecialAddEditSkillPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._onPopupSkillAddEditCancel();
                }
            });

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this._getCandidateSkills(_this.get("sCandidateType"));
                }
            }]);

            if (a24Core.isEmpty(this.get("_arrHeaderActions"))) {
                this.set("_arrHeaderActions", []);
            }

            if (a24Core.isEmpty(this.get("_arrSkillsSortComponents"))) {
                this.set("_arrSkillsSortComponents", []);
            }

            var objValidationConfig = {};

            objValidationConfig["objChildren.years"] = function (objMixin) {
                objMixin.get("arrValidation").pushObject(function () {
                    if (!a24Core.isEmpty(objMixin.mValue) && objMixin.mValue.trim().match(/[^0-9]/g)) {
                        return _this.get("_objCandidateProfileStringsService").getTokenString("validationOnlyNumbes", objMixin.sFieldName);
                    }
                    if ((a24Core.isEmpty(_this.get("objChildren.years.mValue")) || parseInt(_this.get("objChildren.years.mValue").trim()) === 0) && (a24Core.isEmpty(_this.get("objChildren.months.mValue")) || parseInt(_this.get("objChildren.months.mValue").trim()) === 0)) {
                        return _this.get("_objCandidateProfileStringsService").getString("validationYearsOrMonthsRequired");
                    }
                });
            };
            objValidationConfig["objChildren.months"] = function (objMixin) {
                objMixin.get("arrValidation").pushObject(function () {
                    if (!a24Core.isEmpty(objMixin.mValue) && objMixin.mValue.trim().match(/[^0-9]/g)) {
                        return _this.get("_objCandidateProfileStringsService").getTokenString("validationOnlyNumbes", objMixin.sFieldName);
                    }
                    if (!a24Core.isEmpty(objMixin.mValue) && parseInt(_this.get("objChildren.months.mValue").trim()) > 11) {
                        return _this.get("_objCandidateProfileStringsService").getString("validationMonthSmallerThan12");
                    }
                    if ((a24Core.isEmpty(_this.get("objChildren.years.mValue")) || parseInt(_this.get("objChildren.years.mValue").trim()) === 0) && (a24Core.isEmpty(_this.get("objChildren.months.mValue")) || parseInt(_this.get("objChildren.months.mValue").trim()) === 0)) {
                        return _this.get("_objCandidateProfileStringsService").getString("validationYearsOrMonthsRequired");
                    }
                });
            };

            this.set("objChildConfig", objValidationConfig);

            this._getCandidateSkills(this.get("sCandidateType"));
        },

        onCandidateTypeChange: Ember.observer("sCandidateType", function () {
            // Resets the system skills as tabs changed.
            this.set("_arrSystemSkills", null);
            this._getCandidateSkills(this.get("sCandidateType"));
        }),

        _getCandidateSkills: function _getCandidateSkills(sSelectedType) {
            var _this2 = this;

            if (!this.get("objSettingsRestService.objSettings.employment_skills_editable") || a24Core.isEmpty(this.get("objSettingsRestService.objSettings.agency_groups")) || a24Core.isEmpty(this.get("sCandidateType"))) {
                this.set("_bAllowEdit", false);
                return;
            } else {
                this.set("_bAllowEdit", true);
            }

            this.set("_bLoading", true);
            this.set("_bPopupLoading", true);
            this.set("_bServerError", false);
            if (!a24Core.isEmpty(this.get("objChildren.sSearchCurrentSkills"))) {
                this.get("objChildren.sSearchCurrentSkills").clear();
            }
            this.set("_sCardHeader", this.get("_objCandidateProfileStringsService").getTokenString("skillsHeader", this.get("sCandidateTypeName")));
            this.get("objCandidateSkillsRestService").getSkills(this, "_objCandidateSkillsServicePromise", this.get("sCandidateId"), function (arrResponse) {
                _this2.set("_arrCandidateSkills", null);
                _this2._prepareForDisplay(arrResponse);
                _this2.set("_bShowSkillEdit", false);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                _this2.set("_bServerError", true);
                _this2.set("_bLoading", false);
            }), {
                position_types: {
                    mValue: sSelectedType
                }
            });
        },
        _prepareForDisplay: function _prepareForDisplay(arrResponse) {
            var _this3 = this;

            this.set("_arrSkills", arrResponse);
            if (this.get("_bAllowEdit") && !this.get("_bIsAgency")) {
                this.get("_objFabButtonService").showFabButton(function () {
                    _this3._showManageSkills();
                });
            }

            if (a24Core.isEmpty(arrResponse)) {
                this.set("_bNoData", true);
                this.set("_arrHeaderActions", []);
            } else {
                var arrHeaderActions = [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("refresh"),
                    sIcon: "refresh",
                    executeCardAction: function executeCardAction() {
                        _this3._getCandidateSkills(_this3.get("sCandidateType"));
                    }
                }];
                this.set("_bNoData", false);
                this.set("_arrCandidateSkills", arrResponse);
                if (!this.get("_bIsAgency")) {
                    arrHeaderActions.push({
                        sTitle: this.get("_objStringsService").getString("edit"),
                        sIcon: "edit",
                        executeCardAction: function executeCardAction() {
                            _this3._showManageSkills();
                        }
                    });
                }
                this.set("_arrHeaderActions", arrHeaderActions);

                this.set("_arrSortComponents", this._setupTagComponents(arrResponse, false));

                this.set("_arrGroupings", [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("experience4PlusYears"),
                    funcGroupFilter: function funcGroupFilter(objComponent) {
                        if (objComponent.objData.mValue.computed.duration > 12 * 4) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("experience2To4Years"),
                    funcGroupFilter: function funcGroupFilter(objComponent) {
                        if (objComponent.objData.mValue.computed.duration > 12 * 2 && objComponent.objData.mValue.computed.duration <= 12 * 4) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("experience6MonthsTo2Years"),
                    funcGroupFilter: function funcGroupFilter(objComponent) {
                        if (objComponent.objData.mValue.computed.duration > 6 && objComponent.objData.mValue.computed.duration <= 12 * 2) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("experience0To6Months"),
                    funcGroupFilter: function funcGroupFilter(objComponent) {
                        if (objComponent.objData.mValue.computed.duration <= 6) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }]);
            }
            this.set("_bLoading", false);
        },
        onInputValueChanged: function onInputValueChanged(arrChildRef) {
            this._super.apply(this, arguments);

            // Get the reference of the field that changed
            var sLookup = arrChildRef.join(".");
            if (sLookup === "objChildren.sSearchCurrentSkills") {
                this.set("_sFilterCurrentSkillsValue", this.get(sLookup + ".mValue"));
            } else if (sLookup === "objChildren.sSearchSkills") {
                this.set("_sFilterSkillsValue", this.get(sLookup + ".mValue"));
            }
        },

        _setupTagComponents: function _setupTagComponents(arrCandidateSkills, bManage) {
            var objThis = this;
            var bCanAction = false;
            var sIconStyle = null;
            var arrSortComponents = [];
            var sEditIcon = null;

            // Setup edit skills part.
            if (!a24Core.isEmpty(arrCandidateSkills)) {
                for (var k = 0; k < arrCandidateSkills.length; k++) {
                    sIconStyle = "white-text";
                    sEditIcon = "";
                    if (arrCandidateSkills[k].computed.status === this.get("_objCandidateEnums.skillsStatus.PARTIALLY_VERIFIED.backend")) {
                        sIconStyle = "material-icons-outlined white-text";
                    }
                    bCanAction = arrCandidateSkills[k].computed.status === this.get("_objCandidateEnums.skillsStatus.UNVERIFIED.backend");

                    if (bManage) {
                        sEditIcon = "edit";
                    }
                    arrSortComponents.push({
                        sComponent: "tag-component-shrink",
                        objData: {
                            sTitle: arrCandidateSkills[k].skill_name,
                            mValue: arrCandidateSkills[k],
                            sColor: "a24-primary-color-hue-3-force white-text",
                            sActionIcon: bCanAction ? sEditIcon : "verified_user",
                            sActionIconColor: sIconStyle,
                            sClearColor: "white-text"
                        },
                        objActions: {
                            onTagClicked: function onTagClicked(sTitle, mValue) {
                                objThis.onSkillsTagClicked(mValue);
                            }
                        }
                    });
                }
            }

            if (bManage) {
                arrSortComponents.sort(function (objA, objB) {
                    if (objA.objData.sTitle.toLowerCase() < objB.objData.sTitle.toLowerCase()) {
                        return -1;
                    }
                    if (objA.objData.sTitle.toLowerCase() > objB.objData.sTitle.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
            }

            return arrSortComponents;
        },

        _setEditPopup: function _setEditPopup(objItem) {
            var _this4 = this;

            this.set("_sSkillId", objItem.skill_id);
            this.get("objChildren.years").setValueFromOutside(parseInt(objItem.stated.duration / 12));
            this.get("objChildren.months").setValueFromOutside(parseInt(objItem.stated.duration % 12));
            this.set("_bIsAdd", false);
            this.set("_bPopupSkillLoading", false);
            this.set("_bShowSkillEdit", true);

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this4)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    _this4.set("_objOriginalData", _this4.getPlainFormValueObject());
                });
            });
        },

        _showManageOneSkill: function _showManageOneSkill(objSkill) {
            var objThis = this;
            // var arrEmploymentSkillItems = [];

            var objStatedSkill = objSkill.stated;
            // NOTE: employment details has been comented out for now as the api is not sure how to do this yet.
            // var arrEmploymentSkills = objSkill.employment_details;

            // var sEmploymentLabel = null;
            // var sEmploymentType = null;
            // // We doing a instance of here just in case the API decides to not go the array route just so that the screen
            // // does not break. Also the API not defined yet so this part is just guessing
            // if (!a24Core.isEmpty(arrEmploymentSkills) && arrEmploymentSkills instanceof Array) {
            //     for (var i = 0; i < arrEmploymentSkills.length; i++) {
            //         sEmploymentLabel = parseInt(arrEmploymentSkills[i].duration / 12) +
            //             " " + this.get("_objCandidateProfileStringsService").getString("years") + " " +
            //             parseInt(arrEmploymentSkills[i].duration % 12) +
            //             " " + this.get("_objCandidateProfileStringsService").getString("months");
            //         sEmploymentType = this.get("_objCandidateEnums").getFrontendValue(
            //             "skillsStatus",
            //             arrEmploymentSkills[i].skill_status
            //         );
            //         arrEmploymentSkillItems.push({
            //             sLabel: this.get("_objStringsService").getHTMLTokenString(
            //                 "a24-string-html-dot-value",
            //                 sEmploymentLabel,
            //                 sEmploymentType
            //             )
            //         });
            //     }
            // }

            var sType = this.get("_objCandidateEnums").getFrontendValue("skillsStatus", objStatedSkill.status);
            var sLabel = parseInt(objStatedSkill.duration / 12) + " " + this.get("_objCandidateProfileStringsService").getString("years") + " " + parseInt(objStatedSkill.duration % 12) + " " + this.get("_objCandidateProfileStringsService").getString("months");
            var objStatedSkillItem = {
                sLabel: this.get("_objStringsService").getHTMLTokenString("a24-string-html-dot-value", sLabel, sType),
                arrActions: []
            };

            var funcCardActionVerifyExecute = function funcCardActionVerifyExecute(objItem) {
                return function () {
                    objThis.onVerifyClicked(objItem);
                };
            };

            var funcCardActionEditExecute = function funcCardActionEditExecute(objItem) {
                return function () {
                    objThis._setEditPopup(objItem);
                };
            };

            var funcCardActionRemoveExecute = function funcCardActionRemoveExecute(objItem) {
                return function () {
                    objThis.onSkillsTagRemoveClicked(objItem);
                };
            };

            if (!this.get("_bIsAgency")) {
                if (this.get("_bIsAgencyGroup")) {
                    if (objStatedSkill.status === this.get("_objCandidateEnums.skillsStatus.UNVERIFIED.backend")) {
                        objStatedSkillItem.arrActions.push({
                            sTitle: this.get("_objCandidateProfileStringsService").getString("verify"),
                            sIcon: "verified_user",
                            executeCardAction: funcCardActionVerifyExecute(objSkill)
                        });
                    }
                    objStatedSkillItem.arrActions.push({
                        sTitle: this.get("_objStringsService").getString("remove"),
                        sIcon: "delete",
                        executeCardAction: funcCardActionRemoveExecute(objSkill)
                    });
                    objStatedSkillItem.arrActions.push({
                        sTitle: this.get("_objStringsService").getString("edit"),
                        sIcon: "edit",
                        executeCardAction: funcCardActionEditExecute(objSkill)
                    });
                } else {
                    if (objStatedSkill.status === this.get("_objCandidateEnums.skillsStatus.UNVERIFIED.backend")) {
                        objStatedSkillItem.arrActions.push({
                            sTitle: this.get("_objStringsService").getString("remove"),
                            sIcon: "delete",
                            executeCardAction: funcCardActionRemoveExecute(objSkill)
                        });
                        objStatedSkillItem.arrActions.push({
                            sTitle: this.get("_objStringsService").getString("edit"),
                            sIcon: "edit",
                            executeCardAction: funcCardActionEditExecute(objSkill)
                        });
                    }
                }
            }

            this.set("_objStatedSkill", objStatedSkillItem);
            // this.set("_arrEmploymentSkills", arrEmploymentSkillItems);
            this.set("_bShowSkillManagePopup", true);
        },

        _showManageSkills: function _showManageSkills() {
            this.set("_sManagePopupHeader", this.get("_objCandidateProfileStringsService").getTokenString("headerManageSkills", this.get("sCandidateTypeName")));
            this.set("_bShowManagePopup", true);
            this._getSystemSkills();
        },

        _getSystemSkills: function _getSystemSkills() {
            var _this5 = this;

            this.set("_bPopupLoading", true);
            if (a24Core.isEmpty(this.get("_arrSystemSkills"))) {
                this.get("_objSystemService").getSkills(this, "_objSystemServicePromise", function (arrResponse) {
                    _this5.set("_arrSystemSkills", arrResponse);
                    _this5._setupSkillsForManage();
                    _this5.set("_bPopupLoading", false);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                    _this5.set("_bPopupLoading", false);
                    _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objCandidateProfileStringsService").getString("getSystemSkillsError"));
                }), {
                    position_types: {
                        mValue: this.get("sCandidateType")
                    },
                    sortBy: {
                        mValue: "name"
                    },
                    status: {
                        mValue: this.get("_objCandidateEnums.systemSkillStatus.ACTIVE.backend")
                    },
                    items_per_page: {
                        mValue: 1000
                    }
                });
            } else {
                this._setupSkillsForManage();
                this.set("_bPopupLoading", false);
            }
        },
        _setupSkillsForManage: function _setupSkillsForManage() {
            var arrSystemSkills = this.get("_arrSystemSkills");
            var arrCandidateSkills = this.get("_arrCandidateSkills");
            var arrSystemSkillSortComponents = [];
            var bFound = false;
            var objThis = this;

            // Setup edit skills part

            this.set("_arrCandidateSkillsSortComponent", this._setupTagComponents(arrCandidateSkills, true));

            //Setup System skills for the list
            if (!a24Core.isEmpty(arrSystemSkills)) {
                for (var i = 0; i < arrSystemSkills.length; i++) {
                    bFound = false;
                    if (!a24Core.isEmpty(arrCandidateSkills)) {
                        for (var l = 0; l < arrCandidateSkills.length; l++) {
                            if (arrCandidateSkills[l].skill_name === arrSystemSkills[i].name) {
                                bFound = true;
                                break;
                            }
                        }
                    }
                    if (!bFound) {
                        arrSystemSkillSortComponents.push({
                            sComponent: "tag-component-shrink",
                            objData: {
                                sTitle: arrSystemSkills[i].name,
                                mValue: arrSystemSkills[i]
                            },
                            objActions: {
                                onTagClicked: function onTagClicked(sTitle, mValue) {
                                    objThis.onSkillsTagClicked(mValue);
                                }
                            }
                        });
                    }
                }
            }
            this.set("_arrSkillsSortComponents", arrSystemSkillSortComponents);
        },
        _onPopupManageCancel: function _onPopupManageCancel() {
            if (this.get("_bPopupLoading")) {
                return;
            }
            this.set("_bShowManagePopup", false);
            if (!a24Core.isEmpty(this.get("objChildren.sSearchSkills"))) {
                this.get("objChildren.sSearchSkills").clear();
            }
            if (this.get("_bChangesMadeToSkills")) {
                this.set("_bChangesMadeToSkills", false);
                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objCandidateProfileStringsService").getHTMLTokenString("itemUpdateDelay"));
                this._getCandidateSkills(this.get("sCandidateType"));
                if (!a24Core.isEmpty(this.get("objChildren.sSearchCurrentSkills"))) {
                    this.get("objChildren.sSearchCurrentSkills").clear();
                }
            }
        },
        _onPopupManageSkillCancel: function _onPopupManageSkillCancel() {
            if (this.get("_bPopupSkillManageLoading")) {
                return;
            }
            this.set("_bShowSkillManagePopup", false);
        },
        _onPopupSkillAddEditCancel: function _onPopupSkillAddEditCancel() {
            if (this.get("_bPopupSkillLoading")) {
                return;
            }
            // Check if the form has any unsaved data
            var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

            if (bDataMatches) {
                this.set("_bShowSkillEdit", false);
            } else {
                this.set("_bShowUnsavedData", true);
            }
        },
        onVerifyClicked: function onVerifyClicked(mValue) {
            this.set("_bShowVerifyConfirm", true);
            this.set("_bPopupVerifyLoading", false);

            this.set("_sSkillId", mValue.skill_id);
        },

        onSkillsTagRemoveClicked: function onSkillsTagRemoveClicked(mValue) {
            this.set("_bShowDeleteConfirm", true);
            this.set("_bPopupDeleteLoading", false);

            this.set("_sSkillId", mValue.skill_id);
        },
        onSkillsTagClicked: function onSkillsTagClicked(mValue) {
            var _this6 = this;

            var sFilterCurrentSkillsValue = this.get("_sFilterCurrentSkillsValue");
            var sFilterSkillsValue = this.get("_sFilterSkillsValue");
            this.clearAllErrorMessages();
            this.clearForm();

            //Reset the search values since the clearForm would have cleared them.
            if (!a24Core.isEmpty(this.get("objChildren.sSearchCurrentSkills"))) {
                this.get("objChildren.sSearchCurrentSkills").setValueFromOutside(sFilterCurrentSkillsValue);
            }
            if (!a24Core.isEmpty(this.get("objChildren.sSearchSkills"))) {
                this.get("objChildren.sSearchSkills").setValueFromOutside(sFilterSkillsValue);
            }

            if (a24Core.isEmpty(mValue.skill_id)) {
                // this is when its from the system list
                this.set("_sAddEditSkillHeader", this.get("_objCandidateProfileStringsService").getHTMLTokenString("addSkillsHeader", this.get("sCandidateTypeName"), mValue.name));
                this.set("_sSkillId", mValue._id);
                this.set("_bIsAdd", true);
                this.set("_bPopupSkillLoading", false);
                this.set("_bShowSkillEdit", true);

                Ember.run.next(function () {
                    if (a24Core.isEmpty(_this6)) {
                        return;
                    }
                    Ember.run.schedule("afterRender", function () {
                        _this6.set("_objOriginalData", _this6.getPlainFormValueObject());
                    });
                });
            } else {
                if (!this.get("_bIsAgency")) {
                    this._showManageSkills();
                }

                this.set("_sAddEditSkillHeader", this.get("_objCandidateProfileStringsService").getHTMLTokenString("editSkillsHeader", this.get("sCandidateTypeName"), mValue.skill_name));
                this.set("_sManageSkillPopupHeader", this.get("_objCandidateProfileStringsService").getHTMLTokenString("headerManageEditSkill", this.get("sCandidateTypeName"), mValue.skill_name));

                this._showManageOneSkill(mValue);
            }
        },
        getPayload: function getPayload() {

            var objPayload = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());
            var iDuration = 0;

            if (!a24Core.isEmpty(objPayload.years)) {
                iDuration = parseInt(objPayload.years) * 12;
            }
            if (!a24Core.isEmpty(objPayload.months)) {
                iDuration = iDuration + parseInt(objPayload.months);
            }

            objPayload.duration = iDuration;
            delete objPayload.years;
            delete objPayload.months;

            return objPayload;
        },

        _onPopupSkillAddEditSave: function _onPopupSkillAddEditSave() {
            var _this7 = this;

            this.clearAllErrorMessages();

            if (!this.doFullValidation()) {
                var objPayload = this.getPayload();

                this.set("_bPopupSkillLoading", true);
                if (this.get("_bIsAdd")) {
                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objCandidateProfileStringsService").getString("skill")));

                    this.get("objCandidateStatedSkillsRestService").addStatedSkills(this, "_objCandidateStatedSkillsServicePromise", this.get("sCandidateId"), this.get("_sSkillId"), objPayload, function (objNewSkill) {
                        //Add the new skill to the existing list so that the manage skill popup can remove that item
                        //from the avilible choices
                        objNewSkill.stated = {
                            duration: objNewSkill.duration,
                            status: objNewSkill.status
                        };
                        objNewSkill.computed = {
                            duration: objNewSkill.duration,
                            status: objNewSkill.status
                        };
                        delete objNewSkill.duration;
                        delete objNewSkill.sStatus;

                        if (a24Core.isEmpty(_this7.get("_arrCandidateSkills"))) {
                            _this7.set("_arrCandidateSkills", [objNewSkill]);
                        } else {
                            _this7.get("_arrCandidateSkills").pushObject(objNewSkill);
                        }

                        _this7._doSuccess(_this7.get("_objStringsService").getTokenString("successAddItem", _this7.get("_objCandidateProfileStringsService").getString("skill")));
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                        _this7.set("_bPopupSkillLoading", false);
                        if (sCode === "404" || sCode === "409") {
                            _this7.get("_objSnackbarService").showSnackbarMessage(_this7.get("_objCandidateProfileStringsService").getString("addFailedRefreshPage"));
                        } else {
                            _this7._doError(sCode, objError, _this7.get("_objStringsService").getTokenString("failAddItem", _this7.get("_objCandidateProfileStringsService").getString("skill")));
                        }
                    }));
                } else {
                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objCandidateProfileStringsService").getString("skill")));

                    this.get("objCandidateStatedSkillsRestService").updateStatedSkills(this, "_objCandidateStatedSkillsServicePromise", this.get("sCandidateId"), this.get("_sSkillId"), objPayload, function () {
                        //Update the item from the existing items so that the manage popup wil display the correct
                        //duration when users click on it again.
                        var arrCandidateSkills = _this7.get("_arrCandidateSkills");
                        for (var i = 0; i < arrCandidateSkills.length; i++) {
                            if (_this7.get("_sSkillId") === arrCandidateSkills[i].skill_id) {
                                _this7.set("_arrCandidateSkills." + i + ".stated.duration", objPayload.duration);
                                break;
                            }
                        }

                        _this7._doSuccess(_this7.get("_objStringsService").getTokenString("successUpdateItem", _this7.get("_objCandidateProfileStringsService").getString("skill")));
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                        _this7.set("_bPopupSkillLoading", false);
                        if (sCode === "404" || sCode === "409") {
                            _this7.get("_objSnackbarService").showSnackbarMessage(_this7.get("_objStringsService").getString("updateFailedRefreshPage"));
                        } else {
                            _this7._doError(sCode, objError, _this7.get("_objStringsService").getTokenString("failUpdateItem", _this7.get("_objCandidateProfileStringsService").getString("skill")));
                        }
                    }));
                }
            }
        },

        _onPopupSkillVerifyConfirm: function _onPopupSkillVerifyConfirm() {
            var _this8 = this;

            this.set("_bPopupVerifyLoading", true);
            this.set("_bPopupLoading", true);

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objCandidateProfileStringsService").getTokenString("verifyingItem", this.get("_objCandidateProfileStringsService").getString("skill")));

            this.get("objCandidateStatedSkillsRestService").verifyStatedSkills(this, "_objCandidateStatedSkillsServicePromise", this.get("sCandidateId"), this.get("_sSkillId"), function () {
                // Changing the status of the skill so that it will reflect on the manage popup
                var arrCandidateSkills = _this8.get("_arrCandidateSkills");
                for (var i = 0; i < arrCandidateSkills.length; i++) {
                    if (_this8.get("_sSkillId") === arrCandidateSkills[i].skill_id) {
                        _this8.set("_arrCandidateSkills." + i + ".stated.status", _this8.get("_objCandidateEnums.skillsStatus.VERIFIED.backend"));
                        _this8.set("_arrCandidateSkills." + i + ".computed.status", _this8.get("_objCandidateEnums.skillsStatus.VERIFIED.backend"));
                        break;
                    }
                }

                _this8._doSuccess(_this8.get("_objCandidateProfileStringsService").getTokenString("successVerifyItem", _this8.get("_objCandidateProfileStringsService").getString("skill")));
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                _this8.set("_bPopupVerifyLoading", false);
                if (sCode === "404" || sCode === "409") {
                    _this8.get("_objSnackbarService").showSnackbarMessage(_this8.get("_objCandidateProfileStringsService").getString("verifyFailedRefreshPage"));
                } else {
                    _this8._doError(sCode, objError, _this8.get("_objCandidateProfileStringsService").getTokenString("failVerifyItem", _this8.get("_objCandidateProfileStringsService").getString("skill")));
                }
            }));
        },
        _onPopupSkillDeleteConfirm: function _onPopupSkillDeleteConfirm() {
            var _this9 = this;

            this.set("_bPopupDeleteLoading", true);

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("removingItem", this.get("_objCandidateProfileStringsService").getString("skill")));

            this.get("objCandidateStatedSkillsRestService").deleteStatedSkills(this, "_objCandidateStatedSkillsServicePromise", this.get("sCandidateId"), this.get("_sSkillId"), function () {
                //Remove the item from the existing items so that the manage popup wont display that item and make it
                //availible to be added again
                var arrCandidateSkills = _this9.get("_arrCandidateSkills");
                var iRemoveIndex = -1;
                for (var i = 0; i < arrCandidateSkills.length; i++) {
                    if (_this9.get("_sSkillId") === arrCandidateSkills[i].skill_id) {
                        iRemoveIndex = i;
                        break;
                    }
                }
                if (iRemoveIndex !== -1) {
                    arrCandidateSkills.removeAt(iRemoveIndex);
                }

                _this9._doSuccess(_this9.get("_objStringsService").getTokenString("successRemoveItem", _this9.get("_objCandidateProfileStringsService").getString("skill")));
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                _this9.set("_bPopupDeleteLoading", false);
                if (sCode === "404" || sCode === "409") {
                    _this9.get("_objSnackbarService").showSnackbarMessage(_this9.get("_objStringsService").getString("removeFailedRefreshPage"));
                } else {
                    _this9._doError(sCode, objError, _this9.get("_objStringsService").getTokenString("failRemoveItem", _this9.get("_objCandidateProfileStringsService").getString("skill")));
                }
            }));
        },
        _doSuccess: function _doSuccess(sSnackbarMessage) {
            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            this.set("_bShowSkillEdit", false);
            this.set("_bShowSkillManagePopup", false);
            this.set("_bShowDeleteConfirm", false);
            this.set("_bShowVerifyConfirm", false);

            if (!a24Core.isEmpty(this.get("objChildren.sSearchSkills"))) {
                this.get("objChildren.sSearchSkills").clear();
            }

            this.set("_bChangesMadeToSkills", true);

            this._getSystemSkills();
        },
        _doError: function _doError(sCode, objError, sSnackbarMessage) {
            if (sCode === "MINIMUM") {
                this.setResponseError(objError.arrPath, "failedAddingSkillMin", false, true, this.get("_objCandidateProfileStringsService"));
            }
            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
        },
        actions: {
            onPopupManageCancel: function onPopupManageCancel() {
                this._onPopupManageCancel();
            },
            onPopupManageSkillCancel: function onPopupManageSkillCancel() {
                this._onPopupManageSkillCancel();
            },
            onPopupSkillAddEditCancel: function onPopupSkillAddEditCancel() {
                this._onPopupSkillAddEditCancel();
            },
            onPopupSkillAddEditSave: function onPopupSkillAddEditSave() {
                this._onPopupSkillAddEditSave();
            },
            onPopupDeleteClose: function onPopupDeleteClose() {
                if (this.get("_bPopupDeleteLoading")) {
                    return;
                }

                this.set("_bShowDeleteConfirm", false);
            },
            onPopupVerifyClose: function onPopupVerifyClose() {
                if (this.get("_bPopupVerifyLoading")) {
                    return;
                }

                this.set("_bShowVerifyConfirm", false);
            },
            onPopupVerifyConfirm: function onPopupVerifyConfirm() {
                this._onPopupSkillVerifyConfirm();
            },
            onPopupDeleteConfirm: function onPopupDeleteConfirm() {
                this._onPopupSkillDeleteConfirm();
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowSkillEdit", false);
                this.set("_bShowUnsavedData", false);
            }
        }
    });
});