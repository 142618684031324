define("a24-ember-lib/components/size-limit-component", ["exports", "a24-ember-lib/templates/components/size-limit-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _sizeLimitComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _sizeLimitComponent.default,
        iMaxWidth: a24Constants.SIZE_LIMIT_COMPONENT_MAX_WIDTH, // This was decided by the designers
        sStyleWidth: Ember.computed("iMaxWidth", function () {
            return Ember.String.htmlSafe("max-width: " + this.get("iMaxWidth") + "px");
        })
    });
});