define("a24-ember-lib/components/input-form-html", ["exports", "a24-ember-lib/templates/components/input-form-html", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _inputFormHtml, _inputFormElement, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _inputFormHtml.default,
        classNames: ["md-form", "a24ErrorInputParent"],
        sInputType: "html",
        sValue: null,
        arrAdditionalCssFiles: null,
        _bShowPopup: false,
        _bConfirmationPopup: false,
        _sEmptyPlaceholder: null,
        _sFilledPlaceholder: null,
        _objStringsService: Ember.inject.service("a24-strings"),
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            // If button not empty string set it to default
            if (a24Core.isEmpty(this.get("_sEmptyPlaceholder"))) {
                this.set("_sEmptyPlaceholder", this.get("_objStringsService").getString("clickHereToEdit"));
            }
            // If button not empty string set it to default
            if (a24Core.isEmpty(this.get("_sFilledPlaceholder"))) {
                this.set("_sFilledPlaceholder", this.get("_objStringsService").getString("dataEnteredSuccessfullyClickToPreview"));
            }

            if (!a24Core.isEmpty(this.get("sValue"))) {
                this.setMValueWithInternalFlag(this.get("sValue"), true);
            }

            var onEscHandle = function onEscHandle() {
                _this.triggerAction({
                    action: "onPopupConfirmationCancel",
                    target: _this
                });
            };

            Ember.run.scheduleOnce("afterRender", this, function () {
                // This is jquery for the mdb side
                $(_this.$()).on("keyup", function (objEvent) {
                    if (objEvent.which === 27 && objEvent.keyCode === 27) {
                        if (_this.get("_bShowPopup")) {
                            Ember.run.once(_this, onEscHandle);
                        }
                        objEvent.stopPropagation();
                    }
                });
                // This is the jquery for the ember side
                _this.$().on("keyup", function (objEvent) {
                    if (objEvent.which === 27 && objEvent.keyCode === 27) {
                        if (_this.get("_bShowPopup")) {
                            Ember.run.once(_this, onEscHandle);
                        }
                        objEvent.stopPropagation();
                    }
                });
            });
        },
        clear: function clear() {
            this.setValueFromOutside(null);
        },
        setValueFromOutside: function setValueFromOutside(sValue) {
            this.setMValueWithInternalFlag(sValue, false);
        },
        observeValueChange: Ember.observer("sValue", function () {
            this.setValueFromOutside(this.get("sValue"));
        }),
        /**
         * Computed property based on when the input field has a value or not
         */
        sTextFiller: Ember.computed("mValue", "sFieldName", function () {
            if (!a24Core.isEmpty(this.get("mValue"))) {
                // Return the filled data placeholder
                return this.get("sFieldName") + " " + this.get("_sFilledPlaceholder");
            } else {
                // Return the empty data placeholder
                return this.get("_sEmptyPlaceholder") + " " + this.get("sFieldName");
            }
        }),
        /**
         * Get the config for the tinymce editor
         */
        getConfigForTinyMCE: function getConfigForTinyMCE() {
            var arrContentCss = ["/assets/mce.css"];
            if (!a24Core.isEmpty(this.get("arrAdditionalCssFiles"))) {
                arrContentCss = arrContentCss.concat(this.get("arrAdditionalCssFiles"));
            }
            return {
                selector: "#" + this.get("_sInputId") + "-input",
                height: 250,
                menubar: true,
                theme: "modern",
                plugins: ["advlist autolink lists link image charmap preview hr anchor pagebreak", "searchreplace wordcount visualblocks visualchars code", "insertdatetime nonbreaking save table contextmenu directionality", "template paste textcolor colorpicker textpattern imagetools toc help"],
                toolbar1: "undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                toolbar2: "preview | forecolor backcolor | help",
                image_advtab: true,
                content_css: arrContentCss,
                init_instance_callback: function init_instance_callback(objEditor) {
                    // Get the iframe for the editor
                    var objIFrame = objEditor.iframeElement;
                    // Ensure it exists
                    if (!a24Core.isEmpty(objIFrame)) {
                        // Get a list of all link tags
                        var arrLinks = $(objIFrame).contents().find("link");
                        // If the list is not empty
                        if (!a24Core.isEmpty(arrLinks)) {
                            // JQuery Array will apply "attr" to each item in the array
                            arrLinks.attr("onload", // When the link completes its loading
                            function () {
                                // Redraw the content to ensure css re-applies special counter cases
                                tinyMCE.activeEditor.setContent(tinyMCE.activeEditor.getContent());
                            });
                        }
                    }
                }
            };
        },
        /**
         * Hide the tiny mce editor and optionally save the data to mValue
         */
        hideTinyMCEPopup: function hideTinyMCEPopup(bSaveDataOnClose) {
            if (bSaveDataOnClose) {

                var mValue = tinyMCE.activeEditor.getContent();
                if (a24Core.isEmpty(mValue)) {
                    mValue = null;
                }
                this.setMValueWithInternalFlag(mValue, false);
            }
            tinyMCE.remove();
            this.set("_bShowPopup", false);
        },
        actions: {
            onTextboxClick: function onTextboxClick() {
                var _this2 = this;

                // Show the popup
                this.set("_bShowPopup", true);
                // Wait till the textarea is rendered then run the init for tinyMCE
                Ember.run.schedule("afterRender", function () {
                    // Ensure that the tinyMCE has the correct mValue
                    _this2.$("#" + _this2.get("_sInputId") + "-input").val(_this2.get("mValue"));

                    // Initialise the tinyMCE editor
                    tinyMCE.init(_this2.getConfigForTinyMCE());
                });
            },
            onPopupConfirmationConfirm: function onPopupConfirmationConfirm() {
                // Hide the popup and save the data
                this.hideTinyMCEPopup(true);
            },
            onPopupConfirmationCancel: function onPopupConfirmationCancel() {
                // Get the value that is stored
                var mValue = this.get("mValue");

                // Get the value from the tinyMCE editor
                var mTinyValue = tinyMCE.activeEditor.getContent();

                // If both values are not empty and they differ, we have unsaved data
                var bHasUnsavedData = !(a24Core.isEmpty(mValue) && a24Core.isEmpty(mTinyValue)) && mValue !== mTinyValue;

                if (bHasUnsavedData) {
                    // Propt for confirmation on losing unsaved
                    this.set("_bConfirmationPopup", true);
                } else {
                    // Hide the tinyMCE popup
                    this.hideTinyMCEPopup(false);
                }
            },
            onUnsavedPopupConfirmationConfirm: function onUnsavedPopupConfirmationConfirm() {
                // Hide the confirmation popup
                this.set("_bConfirmationPopup", false);
                // Hide the tinyMCE popup
                this.hideTinyMCEPopup(false);
            }
        }
    });
});