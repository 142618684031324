define("a24-ember-lib/components/react-widget", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/templates/components/react-widget"], function (exports, _didRenderChangesMixin, _reactWidget) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _reactWidget.default,
        classNames: ["react-widget"],

        sIFrameId: null,
        sIFrameUrl: null,
        sIFrameLoader: null,
        objPostIframeData: null,

        _objChatterIframeComService: Ember.inject.service("chatter-iframe-com-base"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objUserSession: Ember.inject.service("user-session"),
        // this relies on the react-ember-navigation having been created in the app
        _objReactEmberNavigation: Ember.inject.service("react-ember-navigation"),

        _funcUnregister: null,
        _sIFrameFullUrl: null,
        _bReadyFired: false,
        _objLayoutInfo: null,
        _bForceLayoutPush: false,
        _bFrameFullscreen: false,
        _sAllowList: "",

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_sAllowList", "clipboard-read; clipboard-write;");
            if (!a24Core.isEmpty(this.get("sIFrameUrl"))) {
                var env = Ember.getOwner(this).resolveRegistration("config:environment");
                if (env.environment === "test") {
                    this.set("sIFrameId", "testFrameIdhere1234");
                } else if (a24Core.isEmpty(this.get("sIFrameId"))) {
                    this.set("sIFrameId", Ember.guidFor({}));
                }
                this.set("_funcUnregister", this.get("_objChatterIframeComService").receiveMessage(function (objMessage) {
                    var objData = {};
                    if (!a24Core.isEmpty(objMessage.data)) {
                        objData = JSON.parse(objMessage.data);
                    }
                    if (a24Core.isEmpty(objData.frameId) || objData.frameId === _this.get("sIFrameId")) {
                        _this._handleMessages(objData);
                    }
                }, this.get("sIFrameUrl")));
            }

            this.set("_objLayoutInfo", {});

            this._setFullUrl();
        },

        willDestroyElement: function willDestroyElement() {
            if (!a24Core.isEmpty(this.get("_funcUnregister"))) {
                this.get("_funcUnregister")();
            }
            this._super.apply(this, arguments);
        },

        _setFullUrl: function _setFullUrl() {
            if (a24Core.isEmpty(this.get("sIFrameUrl"))) {
                this.set("_sIFrameFullUrl", null);
                return;
            }
            var sPre = "?";
            if (this.get("sIFrameUrl").indexOf("?") !== -1) {
                sPre = "&";
            }
            if (a24Core.isEmpty(this.get("sIFrameLoader"))) {
                // This will be the default
                this.set("_sIFrameFullUrl", "" + this.get("sIFrameUrl") + sPre + "reactLoaderType=modeNone&reactFrameId=" + this.get("sIFrameId"));
            } else {
                this.set("_sIFrameFullUrl", "" + this.get("sIFrameUrl") + sPre + "reactLoaderType=" + this.get("sIFrameLoader") + "&reactFrameId=" + this.get("sIFrameId"));
            }
        },

        _funcPostLayoutData: function _funcPostLayoutData() {
            if (a24Core.isEmpty(this) || !this.get("_bReadyFired")) {
                return;
            }
            var objRect = this.element.getBoundingClientRect();

            var iRight = this.get("_objDomService.iPageWidth") - objRect.right;
            var iBottom = this.get("_objDomService.iPageHeight") - objRect.bottom;

            if (this.get("_bForceLayoutPush") || this.get("_objLayoutInfo.layoutLeft") !== objRect.left || this.get("_objLayoutInfo.layoutRight") !== iRight || this.get("_objLayoutInfo.layoutTop") !== objRect.top || this.get("_objLayoutInfo.layoutBottom") !== iBottom || this.get("_objLayoutInfo.layoutHeight") !== objRect.height || this.get("_objLayoutInfo.layoutWidth") !== objRect.width) {
                this.set("_bForceLayoutPush", false);

                if (!this.get("_bFrameFullscreen")) {
                    var objIframeEl = this.$(".a24-iframe");
                    if (!a24Core.isEmpty(objIframeEl)) {
                        objIframeEl.width(objRect.width);
                        objIframeEl.height(objRect.height);
                    }
                }

                var objLayoutInfo = {
                    layoutLeft: objRect.left,
                    layoutRight: iRight,
                    layoutTop: objRect.top,
                    layoutBottom: iBottom,
                    layoutHeight: objRect.height,
                    layoutWidth: objRect.width
                };
                this.set("_objLayoutInfo", objLayoutInfo);
                this._postData({
                    type: "layout_info",
                    data: objLayoutInfo
                });
            }
        },

        _onLayoutChange: Ember.observer("_objDidRenderService._iCountRender", function () {
            Ember.run.scheduleOnce("afterRender", this, this._funcPostLayoutData);
        }),

        _onDataChange: Ember.observer("objPostIframeData", function () {
            Ember.run.once(this, this._postContextData);
        }),

        _postContextData: function _postContextData() {
            if (!a24Core.isEmpty(this.get("objPostIframeData"))) {
                this._postData(this.get("objPostIframeData"));
            }
        },

        _postData: function _postData(objData) {
            if (this.get("_bReadyFired")) {
                var objDataCopy = Ember.copy(objData, true);
                if (objDataCopy.type === "trigger_route") {
                    if (a24Core.isEmpty(objDataCopy.data)) {
                        objDataCopy.data = {};
                    }
                    objDataCopy.data.authResponse = Ember.copy(this.get("_objUserSession.objAuthData"), true);
                    objDataCopy.data.contextResponse = Ember.copy(this.get("_objUserSession.objContextDetails"), true);
                }
                this.get("_objChatterIframeComService").postMessage(JSON.stringify(objDataCopy), this.get("sIFrameUrl"), this.$("iframe")[0].contentWindow);
            }
        },

        _notifyEventListener: function _notifyEventListener(event) {
            if (!a24Core.isEmpty(this.$("iframe"))) {
                this._postData({
                    type: "notify_event_listener",
                    data: {
                        event: event
                    }
                });
            }
        },

        _handleMessages: function _handleMessages(objData) {
            var _this2 = this;

            if (objData.type === "router_ready") {
                this.set("_bReadyFired", true);
                this._onDataChange();
            } else if (objData.type === "app_ready") {
                this.set("_bForceLayoutPush", true);
                this._onLayoutChange();
            } else if (objData.type === "check_parent_has_focus") {
                this._postData({
                    type: objData.type + "_response",
                    data: {
                        hasFocus: window.document.hasFocus() || document.hasFocus()
                    },
                    requestId: objData.requestId
                });
            } else if (objData.type === "add_event_listener") {
                if (objData.data.location === "window") {
                    window.addEventListener(objData.data.event, function (event) {
                        _this2._notifyEventListener(event);
                    });
                } else if (objData.data.location === "document") {
                    document.addEventListener(objData.data.event, function (event) {
                        _this2._notifyEventListener(event);
                    });
                }
            } else if (objData.type === "remove_event_listener") {
                if (objData.data.location === "window") {
                    window.removeEventListener(objData.data.event, function (event) {
                        _this2._notifyEventListener(event);
                    });
                } else if (objData.data.location === "document") {
                    document.removeEventListener(objData.data.event, function (event) {
                        _this2._notifyEventListener(event);
                    });
                }
            } else if (objData.type === "snackbar-message") {
                this.get("_objSnackbarService").showSnackbarMessage(objData.data.message);
            } else if (objData.type === "set_iframe_fullscreen") {
                if (objData.data) {
                    this.set("_bFrameFullscreen", true);
                    var objIframeEl = this.$(".a24-iframe");
                    if (!a24Core.isEmpty(objIframeEl)) {
                        objIframeEl.css({
                            position: "fixed",
                            width: "100%",
                            height: "100%",
                            "z-index": 1503 //to be above left menu on mobile, snackbar is still above this
                        });
                    }
                } else {
                    this.set("_bFrameFullscreen", false);
                    var _objIframeEl = this.$(".a24-iframe");
                    if (!a24Core.isEmpty(_objIframeEl)) {
                        _objIframeEl.css({
                            position: "absolute",
                            width: this.get("_objLayoutInfo.layoutWidth"),
                            height: this.get("_objLayoutInfo.layoutHeight"),
                            "z-index": 0
                        });
                    }
                }
            }

            // handle navigation message
            var objUrlData = this.get("_objReactEmberNavigation").handleNavigationMessage(objData);
            if (!a24Core.isEmpty(objUrlData)) {
                this._postData(objUrlData);
            }

            // This is for if we want to handle custom messages
            if (!a24Core.isEmpty(this.get("onRecieveData"))) {
                this.get("onRecieveData")(objData);
            }
        }
    });
});