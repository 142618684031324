define("a24-ember-lib/services/tooltip", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        /**
         * This is used to add a toolip to an element
         *
         * @param objElement - the element
         * @param sToolTipText - the tooltip text
         * @param sToolTipPosition - the tooltip position
         * @param sToolTipClass - the tooltip class
         * @param funcGetTooltipElementCallback - the tooltip callback function
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  16 June 2016
         */
        addTooltip: function addTooltip(objElement, sToolTipText, sToolTipPosition, sToolTipClass, funcGetTooltipElementCallback) {
            // add a tooltip if tooltip text is set
            if (!a24Core.isEmpty(sToolTipText)) {
                objElement.attr("data-toggle", "tooltip");
                objElement.attr("title", sToolTipText);
                if (a24Core.isEmpty(sToolTipPosition)) {
                    sToolTipPosition = "bottom";
                }
                var objTooltipConfig = {
                    placement: sToolTipPosition,
                    container: "body", // Check Overview point 4 on https://mdbootstrap.com/javascript/tooltips/
                    trigger: "hover",
                    delay: {
                        "show": 350, "hide": 0
                    }
                };

                if (!a24Core.isEmpty(sToolTipClass)) {
                    objTooltipConfig.template = "<div class=\"tooltip " + sToolTipClass + "\" role=\"tooltip\"><div class=\"arrow\"></div><div class=\"tooltip-inner\"></div></div>";
                }

                $(objElement).tooltip(objTooltipConfig);

                if (!a24Core.isEmpty(funcGetTooltipElementCallback)) {
                    funcGetTooltipElementCallback(objElement);
                }
            }
        },

        /**
         * This is used to dispose a toolip of an element
         *
         * @param objElement - the element
         * @param sToolTipText - the tooltip text
         * @param sToolTipPosition - the tooltip position
         * @param sToolTipClass - the tooltip class
         * @param funcGetTooltipElementCallback - the tooltip callback function
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  16 June 2016
         */
        disposeOfTooltip: function disposeOfTooltip(objElement) {
            //Destroy the existing tooltip
            try {
                $(objElement).tooltip("dispose");
            } catch (objErr) {
                //This is temp fix till new bootstrap version fixes the tooltip error when calling dispose
                //while transitioning
            }
        }
    });
});