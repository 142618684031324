define("a24-ember-staffshift-core/components/chatter-right-session-data", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/chatter-right-session-data"], function (exports, _didRenderChangesMixin, _chatterRightSessionData) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _chatterRightSessionData.default,
        objData: null,

        classNames: ["a24-chatter-right-session-data"],

        _ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),
        _objLookupStrings: Ember.inject.service("a24-strings"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objUserSession: Ember.inject.service("user-session"),
        _objSSCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objSSCoreEnums: Ember.inject.service("a24-enums-staffshift-core"),
        _objSSCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _momentHelper: null,

        _objRefreshTimer: null,
        _objRefreshTimerDelay: 30000,

        _arrSessionData: null,
        _arrTechData: null,
        _arrUserData: null,

        init: function init() {
            this._super.apply(this, arguments);
            this._observeData();
        },

        willDestroyElement: function willDestroyElement() {
            Ember.run.cancel(this.get("_objRefreshTimer"));
            this.set("_objRefreshTimer", null);

            this._super.apply(this, arguments);
        },
        _observeData: Ember.observer("objData", function () {
            var _this = this;

            Ember.run.cancel(this.get("_objRefreshTimer"));
            this.set("_objRefreshTimer", null);

            if (a24Core.isEmpty(this.get("objData"))) {
                this.set("_arrSessionData", null);
                this.set("_arrTechData", null);
                this.set("_arrUserData", null);
                return;
            }
            //This is so that we can mock it in test and still have the date helper work normally
            if (a24Core.isEmpty(this.get("_momentHelper"))) {
                this.set("_momentHelper", momentHelper);
            }

            var objDateInstance = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            var sTimeOnPage = null;
            if (this.get("objData.chatInbox.online")) {
                sTimeOnPage = this.get("_momentHelper").getTimeFromNow(this.get("objData.ss.current_page.visited_date"), a24Constants.DATE_FORMAT_REST_FORMAT, true);
            }

            var sTimezoneOffset = "";
            var iOffsetHours = this.get("objData.ss.browser.timezone_offset");
            if (!a24Core.isEmpty(iOffsetHours)) {
                var sPolarity = "+";
                if (iOffsetHours < 0) {
                    iOffsetHours = Math.abs(iOffsetHours);
                    sPolarity = "-";
                }
                var mHours = Math.floor(iOffsetHours / 60);
                var mMinutes = Math.floor(iOffsetHours - mHours * 60);

                if (mHours < 10) {
                    mHours = "0" + mHours;
                }
                if (mMinutes < 10) {
                    mMinutes = "0" + mMinutes;
                }

                sTimezoneOffset = "UTC" + sPolarity + mHours + ":" + mMinutes;
            }

            var iEllapsedSeconds = this.get("_momentHelper").stringDiffInUnits(this.get("_momentHelper").getCurrentDateString(a24Constants.DATE_FORMAT_REST_FORMAT), a24Constants.DATE_FORMAT_REST_FORMAT, this.get("objData.ss.browser.timestamp"), a24Constants.DATE_FORMAT_REST_FORMAT, this.get("_objLibConstants.MOMENT_TIME_SECONDS"));

            var sBrowserLangs = "";
            if (!a24Core.isEmpty(this.get("objData.ss.browser.lang"))) {
                sBrowserLangs = this.get("objData.ss.browser.lang").join(", ");
            }

            //CURRENT PAGE SECTION

            var arrCurrentPage = [];

            arrCurrentPage.push({
                sLabel: this.get("_objSSCoreStrings").getString("timeOnPage"),
                sValue: sTimeOnPage,
                bText: true
            });
            if (a24Core.isEmpty(this.get("objData.ss.source_details")) && this.get("_objUserSession.objGeneralDetails.sResourceType") === this.get("_objSSCoreConstants.CONTEXT_SYSTEM_ADMIN_LOWER_HYPHEN")) {
                arrCurrentPage.push({
                    sLabel: this.get("_objSSCoreStrings").getString("url"),
                    sValue: this.get("_objSSCoreStrings").getHTMLTokenString("tooltipOnString", this.get("objData.ss.current_page.full_url"), a24Core.getSafeStringLink(Ember, this.get("_objSSCoreStrings").getString("clickHereWithIcon"), this.get("objData.ss.current_page.full_url"), "externalLink")),
                    bText: true
                });
            }
            arrCurrentPage.push({
                sLabel: this.get("_objSSCoreStrings").getString("page"),
                sValue: this.get("objData.ss.current_page.page_name"),
                bText: true
            });

            if (this.get("_objUserSession.objGeneralDetails.sResourceType") === this.get("_objSSCoreConstants.CONTEXT_SYSTEM_ADMIN_LOWER_HYPHEN")) {
                var objRouteParams = this.get("objData.ss.current_page.page_route_params");
                for (var sProp in objRouteParams) {
                    if (Object.prototype.hasOwnProperty.call(objRouteParams, sProp)) {
                        var sLabel = sProp.replace(/_/g, " ");
                        sLabel = sLabel.charAt(0).toUpperCase() + sLabel.slice(1);
                        arrCurrentPage.push({
                            sLabel: sLabel,
                            sValue: objRouteParams[sProp],
                            bText: true
                        });
                    }
                }
            }

            //CONTEXT SECTION

            var arrContext = [];

            arrContext.push({
                sLabel: this.get("_objSSCoreStrings").getString("type"),
                sValue: this.get("_objSSCoreEnums").getFrontendValue("context", this.get("objData.ss.context.resource")),
                bText: true
            });
            arrContext.push({
                sLabel: this.get("_objLookupStrings").getString("name"),
                sValue: this.get("objData.ss.context.name"),
                bText: true
            });
            arrContext.push({
                sLabel: this.get("_objSSCoreStrings").getString("timezone"),
                sValue: this.get("objData.ss.context.timezone"),
                bText: true
            });
            arrContext.push({
                sLabel: this.get("_objSSCoreStrings").getString("locale"),
                sValue: this.get("objData.ss.context.locale"),
                bText: true
            });

            //OPERATING SYSTEM SECTION

            var arrOPSystem = [];

            arrOPSystem.push({
                sLabel: this.get("_objSSCoreStrings").getString("os"),
                sValue: this.get("objData.ss.browser.os.name"),
                bText: true
            });
            arrOPSystem.push({
                sLabel: this.get("_objSSCoreStrings").getString("version"),
                sValue: this.get("objData.ss.browser.os.version"),
                bText: true
            });
            var sScreenWidth = "";
            if (!a24Core.isEmpty(this.get("objData.ss.browser.screen.width")) && !a24Core.isEmpty(this.get("objData.ss.browser.screen.height"))) {
                sScreenWidth = this.get("objData.ss.browser.screen.width") + " x " + this.get("objData.ss.browser.screen.height");
            }
            arrOPSystem.push({
                sLabel: this.get("_objSSCoreStrings").getString("screen"),
                sValue: sScreenWidth,
                bText: true
            });

            //BROWSER SECTION

            var arrBrowser = [];

            arrBrowser.push({
                sLabel: this.get("_objLookupStrings").getString("name"),
                sValue: this.get("objData.ss.browser.browser.name"),
                bText: true
            });
            arrBrowser.push({
                sLabel: this.get("_objSSCoreStrings").getString("version"),
                sValue: this.get("objData.ss.browser.browser.version"),
                bText: true
            });
            var sBrowserWidth = "";
            if (!a24Core.isEmpty(this.get("objData.ss.browser.viewport.width")) && !a24Core.isEmpty(this.get("objData.ss.browser.viewport.height"))) {
                sBrowserWidth = this.get("objData.ss.browser.viewport.width") + " x " + this.get("objData.ss.browser.viewport.height");
            }
            arrBrowser.push({
                sLabel: this.get("_objSSCoreStrings").getString("screen"),
                sValue: sBrowserWidth,
                bText: true
            });
            arrBrowser.push({
                sLabel: this.get("_objSSCoreStrings").getString("timezone"),
                sValue: this.get("objData.ss.browser.timezone"),
                bText: true
            });
            arrBrowser.push({
                sLabel: this.get("_objSSCoreStrings").getString("timezoneOffset"),
                sValue: sTimezoneOffset,
                bText: true
            });

            var sCurrentTime = "";
            //Here we want to ignore the timezone on the date so that we display the time as is on the date, hence you
            //would see the time the user is seeing on his PC
            if (!a24Core.isEmpty(this.get("objData.ss.browser.timestamp"))) {
                objDateInstance.setDateFromRest(this.get("objData.ss.browser.timestamp"));
                objDateInstance.addTimeToDate(this.get("_objLibConstants.MOMENT_TIME_SECONDS"), iEllapsedSeconds);
                sCurrentTime = objDateInstance.getDateTimeFormat();
            }

            arrBrowser.push({
                sLabel: this.get("_objSSCoreStrings").getString("currentTime"),
                sValue: sCurrentTime,
                bText: true
            });
            arrBrowser.push({
                sLabel: this.get("_objSSCoreStrings").getString("locale"),
                sValue: sBrowserLangs,
                bText: true
            });

            var sSupportsVideoCalls = "";

            if (!a24Core.isEmpty(this.get("objData.ss.features.supports_video_calls"))) {
                sSupportsVideoCalls = this.get("objData.ss.features.supports_video_calls") ? this.get("_objLookupStrings").getString("yes") : this.get("_objLookupStrings").getString("no");
            }

            arrBrowser.push({
                sLabel: this.get("_objSSCoreStrings").getString("supportsVideoCalls"),
                sValue: sSupportsVideoCalls,
                bText: true
            });

            //USER SECTION

            var arrUser = [];

            arrUser.push({
                sLabel: this.get("_objSSCoreStrings").getString("username"),
                sValue: this.get("objData.ss.user.username"),
                bText: true
            });
            arrUser.push({
                sLabel: this.get("_objSSCoreStrings").getString("timezone"),
                sValue: this.get("objData.ss.user.timezone"),
                bText: true
            });

            var sEmailVerifiedDate = null;
            if (!a24Core.isEmpty(this.get("objData.ss.user.email_verified_date"))) {
                objDateInstance.setDateFromRestAllowTimeAlter(this.get("objData.ss.user.email_verified_date"));
                sEmailVerifiedDate = objDateInstance.getDateTimeFormat();
            }
            arrUser.push({
                sLabel: this.get("_objSSCoreStrings").getString("emailVerified"),
                sValue: sEmailVerifiedDate,
                bText: true
            });

            var sLastEmailVerifySentDate = null;
            if (!a24Core.isEmpty(this.get("objData.ss.user.email_last_verify_email_sent_date"))) {
                objDateInstance.setDateFromRestAllowTimeAlter(this.get("objData.ss.user.email_last_verify_email_sent_date"));
                sLastEmailVerifySentDate = objDateInstance.getDateTimeFormat();
            }
            arrUser.push({
                sLabel: this.get("_objSSCoreStrings").getString("lastEmailVerifySent"),
                sValue: sLastEmailVerifySentDate,
                bText: true
            });
            arrUser.push({
                sLabel: this.get("_objSSCoreStrings").getString("locale"),
                sValue: this.get("objData.ss.user.locale"),
                bText: true
            });

            //Set all the data on the view edit section
            var arrSessionData = [];
            var arrTechData = [];
            var arrUserData = [];
            arrSessionData.push({
                sSubHeader: this.get("_objSSCoreStrings").getHTMLTokenString("iconAndText", "find_in_page", this.get("_objSSCoreStrings").getString("currentPage")),
                sSubHeaderClass: "a24-chatter-right-session-data-sub-header",
                arrItems: arrCurrentPage
            });

            arrSessionData.push({
                sSubHeader: this.get("_objSSCoreStrings").getHTMLTokenString("iconAndText", "account_box", this.get("_objSSCoreStrings").getString("context")),
                sSubHeaderClass: "a24-chatter-right-session-data-sub-header",
                arrItems: arrContext
            });

            arrTechData.push({
                sSubHeader: this.get("_objSSCoreStrings").getHTMLTokenString("iconAndText", "desktop_windows", this.get("_objSSCoreStrings").getString("device")),
                sSubHeaderClass: "a24-chatter-right-session-data-sub-header",
                arrItems: arrOPSystem
            });

            arrTechData.push({
                sSubHeader: this.get("_objSSCoreStrings").getHTMLTokenString("iconAndText", "open_in_browser", this.get("_objSSCoreStrings").getString("browser")),
                sSubHeaderClass: "a24-chatter-right-session-data-sub-header",
                arrItems: arrBrowser
            });

            arrUserData.push({
                sSubHeader: this.get("_objSSCoreStrings").getHTMLTokenString("iconAndText", "account_circle", this.get("_objSSCoreStrings").getString("user")),
                sSubHeaderClass: "a24-chatter-right-session-data-sub-header",
                arrItems: arrUser
            });

            this.set("_arrSessionData", arrSessionData);
            this.set("_arrTechData", arrTechData);
            this.set("_arrUserData", arrUserData);

            this._scheduleRefresh(function () {
                _this._observeData();
            });
        }),

        _scheduleRefresh: function _scheduleRefresh(funcCallback) {
            if (this.get("_ENV").environment === "test") {
                return;
            }
            this.set("_objRefreshTimer", Ember.run.later(this, function () {
                funcCallback();
            }, this.get("_objRefreshTimerDelay")));
        }

    });
});