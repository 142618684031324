define("a24-ember-candidate-profile/services/rest-agency-group-candidate-next-of-kin", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * Retrieves all next of kin entries for the candidate
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  23 Feb 2018
         */
        getNextOfKins: function getNextOfKins(objContext, sProperty, sCandidateId, funcSuccess, objFailure) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency-group/" + sAgencyGroupId + "/candidate/" + sCandidateId + "/next-of-kin");

            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: {
                    mValue: 1000
                }
            });

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Creates a next of kin entry for the candidate
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id
         * @param objData - The candidate next of kin data
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  23 Feb 2018
         */
        createNextOfKin: function createNextOfKin(objContext, sProperty, sCandidateId, objData, funcSuccess, objFailure) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency-group/" + sAgencyGroupId + "/candidate/" + sCandidateId + "/next-of-kin");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", this.get("sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        },

        /**
         * Deletes a next of kin entry for the candidate
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id
         * @param sNextOfKinId - The next of kin id
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  23 Feb 2018
         */
        deleteNextOfKin: function deleteNextOfKin(objContext, sProperty, sCandidateId, sNextOfKinId, funcSuccess, objFailure) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency-group/" + sAgencyGroupId + "/candidate/" + sCandidateId + "/next-of-kin/" + sNextOfKinId);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "DELETE", this.get("sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Updates a next of kin entry for the candidate
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id to fetch basic info data for
         * @param sNextOfKinId - The next of kin id
         * @param objData - The candidate next of kin data
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  21 March 2018
         */
        updateNextOfKin: function updateNextOfKin(objContext, sProperty, sCandidateId, sNextOfKinId, objData, funcSuccess, objFailure) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency-group/" + sAgencyGroupId + "/candidate/" + sCandidateId + "/next-of-kin/" + sNextOfKinId);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "PUT", this.get("sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        }
    });
});