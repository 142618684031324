define("a24-ember-candidate-profile/services/card-datagrid-custom-filter-candidate-professional-registration", ["exports", "a24-ember-lib/services/card-datagrid-filter-custom-base"], function (exports, _cardDatagridFilterCustomBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _cardDatagridFilterCustomBase.default.extend({
        _objStringsService: Ember.inject.service("a24-strings"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objEnumEmberLibService: Ember.inject.service("a24-enums-ember-lib"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objDatagridFitlerService: Ember.inject.service("card-datagrid-filter"),
        _objUserSession: Ember.inject.service("user-session"),
        _arrRegistrationBodies: null,

        funcFilterCustomPrimaryQuery: function funcFilterCustomPrimaryQuery() /*objHeaderItem , objHeaderSecondaries*/{
            //Not being used due to filter being react now

            // var objQuery = {
            //     bEncode: true,
            //     sFilterValue: objHeaderItem._objRegistrationBody.name,
            //     mValue: objHeaderItem._objRegistrationBody._id,
            //     arSec: []
            // };
            //
            // if (!a24Core.isEmpty(objHeaderItem._objRegistrationBodyStatus)) {
            //     objQuery.arSec.push({
            //         "sProp": "status_id",
            //         "bEncode": true,
            //         "mValue": objHeaderItem._objRegistrationBodyStatus._id,
            //         "sFilterValue": objHeaderItem._objRegistrationBodyStatus.name
            //     });
            // }
            // if (!a24Core.isEmpty(objHeaderItem._objRegistrationBodyEntry)) {
            //     objQuery.arSec.push({
            //         "sProp": "registration_body_entry_id",
            //         "bEncode": true,
            //         "mValue": objHeaderItem._objRegistrationBodyEntry._id,
            //         "sFilterValue": objHeaderItem._objRegistrationBodyEntry.name
            //     });
            // }
            // if (!a24Core.isEmpty(objHeaderItem._objRegistrationBodyEntryStatus)) {
            //     objQuery.arSec.push({
            //         "sProp": "registration_body_entry_status_id",
            //         "bEncode": true,
            //         "mValue": objHeaderItem._objRegistrationBodyEntryStatus._id,
            //         "sFilterValue": objHeaderItem._objRegistrationBodyEntryStatus.name
            //     });
            // }
            //
            // return objQuery;
        },

        funcFilterCustomTag: function funcFilterCustomTag(objHeaderItem, objQuery, objHeadersSecondaries, bSkipTitle) {
            var sProfessionalRegistrationTagKey = "";

            if (objHeaderItem.sProperty === "registration_body_details___registration_body_id") {
                sProfessionalRegistrationTagKey = bSkipTitle ? "professionalRegistrationTagNoTitle" : "professionalRegistrationTag";
            } else {
                sProfessionalRegistrationTagKey = bSkipTitle ? "professionalRegistrationEntryTagNoTitle" : "professionalRegistrationEntryTag";
            }

            if (a24Core.isEmpty(objQuery.arSec)) {
                objQuery.arSec = [];
            }

            var objRegistrationBodyStatus = objQuery.arSec.findBy("sProp", "status_id");
            var objRegistrationBodyEntry = objQuery.arSec.findBy("sProp", "registration_body_entry_id");
            var objRegistrationBodyEntryStatus = objQuery.arSec.findBy("sProp", "registration_body_entry_status_id");
            var objObtainedAt = objQuery.arSec.findBy("sProp", "obtained_at");
            var objExpiresAt = objQuery.arSec.findBy("sProp", "expires_at");
            var objValidAt = objQuery.arSec.findBy("sProp", "valid_at");
            var objStrings = this.get("_objStringsService");
            var objCandidateProfileStrings = this.get("_objCandidateProfileStringsService");
            var sIs = objStrings.getString("is").toLowerCase();
            var sTag = this.get("_objCandidateProfileStringsService").getTokenString(sProfessionalRegistrationTagKey, objQuery.sFilterValue);
            var sTagSimple = this.get("_objCandidateProfileStringsService").getTokenString(sProfessionalRegistrationTagKey, objQuery.sFilterValue);
            var bTagSimple = false;

            if (!a24Core.isEmpty(objRegistrationBodyStatus)) {
                var sDropMultiTagValue = null;

                var arrFilterItems = objRegistrationBodyStatus.mValue.split("|");
                if (arrFilterItems.length === 1) {
                    sDropMultiTagValue = objRegistrationBodyStatus.sFilterValue;
                } else {
                    sDropMultiTagValue = this.get("_objStringsService").getString("multiple");

                    bTagSimple = true;
                    //The if is just for backwards compatability with react, once react returns sFilterValueClean we should
                    //only use that for the sTagSimple
                    if (a24Core.isEmpty(objRegistrationBodyStatus.sFilterValueClean)) {
                        sTagSimple += ", " + objStrings.getString("status") + " " + sIs + " " + sDropMultiTagValue;
                    } else {
                        sTagSimple += ", " + objStrings.getString("status") + " " + sIs + " (" + objRegistrationBodyStatus.sFilterValueClean + ")";
                    }
                }
                sTag += ", " + objStrings.getString("status") + " " + sIs + " " + sDropMultiTagValue;
            }

            if (!a24Core.isEmpty(objRegistrationBodyEntry)) {
                sTag += ", " + objCandidateProfileStrings.getString("entry") + " " + objRegistrationBodyEntry.sFilterValue;
                sTagSimple += ", " + objCandidateProfileStrings.getString("entry") + " " + objRegistrationBodyEntry.sFilterValue;
            }

            if (!a24Core.isEmpty(objRegistrationBodyEntryStatus)) {
                sTag += ", " + objCandidateProfileStrings.getString("entryStatus") + " " + sIs + " " + objRegistrationBodyEntryStatus.sFilterValue;
                sTagSimple += ", " + objCandidateProfileStrings.getString("entryStatus") + " " + sIs + " " + objRegistrationBodyEntryStatus.sFilterValue;
            }

            var arrHeaderSecondary = Ember.get(objHeadersSecondaries, "arrFilterSecondary_" + objHeaderItem.sProperty);

            var objObtainedHeader = {};
            var objValidHeader = {};
            var objExpiresHeader = {};

            for (var i = 0; i < arrHeaderSecondary.length; i++) {
                if (arrHeaderSecondary[i].sProperty === "obtained_at") {
                    objObtainedHeader = arrHeaderSecondary[i];
                } else if (arrHeaderSecondary[i].sProperty === "valid_at") {
                    objValidHeader = arrHeaderSecondary[i];
                } else if (arrHeaderSecondary[i].sProperty === "expires_at") {
                    objExpiresHeader = arrHeaderSecondary[i];
                }
            }
            var objFromDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            var objToDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            if (!a24Core.isEmpty(objObtainedAt)) {
                var sObtainFrom = "";
                var sObtainTo = "";
                if (objObtainedAt.bFrom || objObtainedAt.bAfter) {
                    objFromDate.setDateFromRest(objObtainedAt.mValue, true);
                    sObtainFrom = objFromDate.getDateStringForRest();
                } else if (objObtainedAt.bTo) {
                    objToDate.setDateFromRest(objObtainedAt.mValue, true);
                    sObtainTo = objToDate.getDateStringForRest();
                } else if (objObtainedAt.bFromAndTo || objObtainedAt.bNotWithinNext || objObtainedAt.bNotWithinLast) {
                    objFromDate.setDateFromRest(objObtainedAt.mValue.sFrom, true);
                    sObtainFrom = objFromDate.getDateStringForRest();
                    objToDate.setDateFromRest(objObtainedAt.mValue.sTo, true);
                    sObtainTo = objToDate.getDateStringForRest();
                }
                var objObtainedAtTag = this.get("_objDatagridFitlerService").createDateQueryAndTag(objObtainedAt.sFilterValue, sObtainFrom, sObtainTo, objObtainedHeader, true);
                sTag += ", " + objObtainedAtTag.sTag;
                sTagSimple += ", " + objObtainedAtTag.sTag;
            }
            if (!a24Core.isEmpty(objValidAt)) {
                var sValidFrom = "";
                var sValidTo = "";
                if (objValidAt.bFrom || objValidAt.bAfter) {
                    objFromDate.setDateFromRest(objValidAt.mValue, true);
                    sValidFrom = objFromDate.getDateStringForRest();
                } else if (objValidAt.bTo) {
                    objToDate.setDateFromRest(objValidAt.mValue, true);
                    sValidTo = objToDate.getDateStringForRest();
                } else if (objValidAt.bFromAndTo || objValidAt.bNotWithinNext || objValidAt.bNotWithinLast) {
                    objFromDate.setDateFromRest(objValidAt.mValue.sFrom, true);
                    sValidFrom = objFromDate.getDateStringForRest();
                    objToDate.setDateFromRest(objValidAt.mValue.sTo, true);
                    sValidTo = objToDate.getDateStringForRest();
                }
                var objValidAtTag = this.get("_objDatagridFitlerService").createDateQueryAndTag(objValidAt.sFilterValue, sValidFrom, sValidTo, objValidHeader, true);
                sTag += ", " + objValidAtTag.sTag;
                sTagSimple += ", " + objValidAtTag.sTag;
            }
            if (!a24Core.isEmpty(objExpiresAt)) {
                var sExpiresFrom = "";
                var sExpiresTo = "";
                if (objExpiresAt.bFrom || objExpiresAt.bAfter) {
                    objFromDate.setDateFromRest(objExpiresAt.mValue, true);
                    sExpiresFrom = objFromDate.getDateStringForRest();
                } else if (objExpiresAt.bTo) {
                    objToDate.setDateFromRest(objExpiresAt.mValue, true);
                    sExpiresTo = objToDate.getDateStringForRest();
                } else if (objExpiresAt.bFromAndTo || objExpiresAt.bNotWithinNext || objExpiresAt.bNotWithinLast) {
                    objFromDate.setDateFromRest(objExpiresAt.mValue.sFrom, true);
                    sExpiresFrom = objFromDate.getDateStringForRest();
                    objToDate.setDateFromRest(objExpiresAt.mValue.sTo, true);
                    sExpiresTo = objToDate.getDateStringForRest();
                }
                var objExpiresAtTag = this.get("_objDatagridFitlerService").createDateQueryAndTag(objExpiresAt.sFilterValue, sExpiresFrom, sExpiresTo, objExpiresHeader, true);
                if (objExpiresHeader.bTemporal) {

                    if (objExpiresAt.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED.backend") || objExpiresAt.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_AFTER.backend")) {
                        objExpiresAtTag.sTag = objExpiresAtTag.sTag.substring(objExpiresHeader.sTitle.length, objExpiresAtTag.sTag.length);
                    }
                    sTag += ", " + objExpiresAtTag.sTag;
                    sTagSimple += ", " + objExpiresAtTag.sTag;
                } else {
                    sTag += ", " + objExpiresAtTag.sTag;
                    sTagSimple += ", " + objExpiresAtTag.sTag;
                }
            }

            var objReturn = {
                sTag: sTag
            };
            if (bTagSimple) {
                objReturn.sTagSimple = sTagSimple;
            }

            return objReturn;
        },

        funcFilterCustomPrimaryReset: function funcFilterCustomPrimaryReset() /*objHeaderItem*/{
            //Not being used due to filter being react now

            // //Reset the state of the custom filter input
            // set(objHeaderItem, "_objRegistrationBody", null);
            // set(objHeaderItem, "_objRegistrationBodyStatus", null);
            // set(objHeaderItem, "_objRegistrationBodyEntry", null);
            // set(objHeaderItem, "_objRegistrationBodyEntryStatus", null);
        },

        funcFilterCustomFixupQuery: function funcFilterCustomFixupQuery(objHeader, objHeaderSecondaries) {

            for (var i = 0; i < objHeader.mFilterValue.length; i++) {
                var arrSeconderies = objHeader.mFilterValue[i].arSec;
                //Loop in reverse so that we can remove items from the array without index out of bounds
                for (var j = arrSeconderies.length - 1; j >= 0; j--) {
                    var objSecondaryHeader = null;
                    var arrHeaderSecondary = Ember.get(objHeaderSecondaries, "arrFilterSecondary_" + objHeader.sProperty);
                    for (var k = 0; k < arrHeaderSecondary.length; k++) {
                        if (arrHeaderSecondary[k].sProperty === arrSeconderies[j].sProp) {
                            objSecondaryHeader = arrHeaderSecondary[k];
                            break;
                        }
                    }
                    if (a24Core.isEmpty(objSecondaryHeader)) {
                        Ember.get(objHeader, "mFilterValue." + i + ".arSec").removeAt(j);
                    } else {
                        if (arrSeconderies[j].sProp === "expires_at" || arrSeconderies[j].sProp === "obtained_at" || arrSeconderies[j].sProp === "valid_at") {
                            if (!a24Core.isEmpty(arrSeconderies[j].sFilterValue) && arrSeconderies[j].sFilterValue !== this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_CUSTOM_RANGE.backend")) {
                                var objFromDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                                var objToDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                                var sFrom = "";
                                var sTo = "";

                                if (objSecondaryHeader.bTemporal) {
                                    if (arrSeconderies[j].bFromAndTo) {
                                        objFromDate.setDateFromRest(arrSeconderies[j].mValue.sFrom, true);
                                        objToDate.setDateFromRest(arrSeconderies[j].mValue.sTo, true);
                                        sFrom = objFromDate.getDateStringForRest();
                                        sTo = objToDate.getDateStringForRest();
                                    } else if (arrSeconderies[j].bFrom || arrSeconderies[j].bAfter) {
                                        objFromDate.setDateFromRest(arrSeconderies[j].mValue, true);
                                        sFrom = objFromDate.getDateStringForRest();
                                    } else if (arrSeconderies[j].bTo) {
                                        objToDate.setDateFromRest(arrSeconderies[j].mValue, true);
                                        sTo = objToDate.getDateStringForRest();
                                    }
                                } else {
                                    objFromDate.setDateFromRest(arrSeconderies[j].mValue.sFrom, true);
                                    objToDate.setDateFromRest(arrSeconderies[j].mValue.sTo, true);
                                    sFrom = objFromDate.getDateStringForRest();
                                    sTo = objToDate.getDateStringForRest();
                                }

                                var objQueryAndTag = this.get("_objDatagridFitlerService").createDateQueryAndTag(arrSeconderies[j].sFilterValue, sFrom, sTo, objSecondaryHeader, true);

                                if (a24Core.isEmpty(objQueryAndTag.objQuery)) {
                                    Ember.get(objHeader, "mFilterValue." + i + ".arSec").removeAt(j);
                                } else {
                                    if (objQueryAndTag.objQuery.bFrom || objQueryAndTag.objQuery.bAfter) {
                                        objFromDate.setDateFromBrowser(objQueryAndTag.objQuery.mValue, this.get("_objLibConstants.DATE_FORMAT"));
                                        objQueryAndTag.objQuery.mValue = objFromDate.getDateStringForRest(true);
                                    } else if (objQueryAndTag.objQuery.bTo) {
                                        objFromDate.setDateFromBrowser(objQueryAndTag.objQuery.mValue, this.get("_objLibConstants.DATE_FORMAT"));
                                        objQueryAndTag.objQuery.mValue = objToDate.getDateStringForRest(true);
                                    } else if (objQueryAndTag.objQuery.bFromAndTo || objQueryAndTag.objQuery.bNotWithinNext || objQueryAndTag.objQuery.bNotWithinLast) {
                                        objFromDate.setDateFromBrowser(objQueryAndTag.objQuery.mValue.sFrom, this.get("_objLibConstants.DATE_FORMAT"));
                                        objToDate.setDateFromBrowser(objQueryAndTag.objQuery.mValue.sTo, this.get("_objLibConstants.DATE_FORMAT"));

                                        objQueryAndTag.objQuery.mValue = {
                                            sFrom: objFromDate.getDateStringForRest(true),
                                            sTo: objToDate.getDateStringForRest(true)
                                        };
                                    }
                                    Ember.set(objHeader, "mFilterValue." + i + ".arSec." + j + ".mValue", objQueryAndTag.objQuery.mValue);
                                }
                            }
                        }
                    }
                }
            }
        },

        funcFilterCustomSetEditState: function funcFilterCustomSetEditState() /*objHeaderItem, iIndex*/{
            //Not being used due to filter being react now

            // var objTag = objHeaderItem.mFilterValue[iIndex];
            //
            // set(objHeaderItem, "_objRegistrationBody", {
            //     _id: objTag.mValue,
            //     name: objTag.sFilterValue
            // });
            //
            // for (var i = 0; i < objTag.arSec.length; i++) {
            //     var objSecondary = objTag.arSec[i];
            //
            //     if (objSecondary.sProp === "status_id") {
            //         set(objHeaderItem, "_objRegistrationBodyStatus", {
            //             _id: objSecondary.mValue,
            //             name: objSecondary.sFilterValue
            //         });
            //     } else if (objSecondary.sProp === "registration_body_entry_id") {
            //         set(objHeaderItem, "_objRegistrationBodyEntry", {
            //             _id: objSecondary.mValue,
            //             name: objSecondary.sFilterValue
            //         });
            //     } else if (objSecondary.sProp === "registration_body_entry_status_id") {
            //         set(objHeaderItem, "_objRegistrationBodyEntryStatus", {
            //             _id: objSecondary.mValue,
            //             name: objSecondary.sFilterValue
            //         });
            //     }
            // }
        }
    });
});