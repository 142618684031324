enifed('ember-extension-support/container_debug_adapter', ['exports', 'ember-runtime'], function (exports, _emberRuntime) {
  'use strict';

  exports.default = _emberRuntime.Object.extend({
    /**
      The resolver instance of the application
      being debugged. This property will be injected
      on creation.
       @property resolver
      @default null
      @public
    */
    resolver: null,

    /**
      Returns true if it is possible to catalog a list of available
      classes in the resolver for a given type.
       @method canCatalogEntriesByType
      @param {String} type The type. e.g. "model", "controller", "route".
      @return {boolean} whether a list is available for this type.
      @public
    */
    canCatalogEntriesByType: function (type) {
      if (type === 'model' || type === 'template') {
        return false;
      }

      return true;
    },


    /**
      Returns the available classes a given type.
       @method catalogEntriesByType
      @param {String} type The type. e.g. "model", "controller", "route".
      @return {Array} An array of strings.
      @public
    */
    catalogEntriesByType: function (type) {
      var namespaces = (0, _emberRuntime.A)(_emberRuntime.Namespace.NAMESPACES);
      var types = (0, _emberRuntime.A)();
      var typeSuffixRegex = new RegExp(_emberRuntime.String.classify(type) + '$');

      namespaces.forEach(function (namespace) {
        for (var key in namespace) {
          if (!namespace.hasOwnProperty(key)) {
            continue;
          }
          if (typeSuffixRegex.test(key)) {
            var klass = namespace[key];
            if ((0, _emberRuntime.typeOf)(klass) === 'class') {
              types.push(_emberRuntime.String.dasherize(key.replace(typeSuffixRegex, '')));
            }
          }
        }
      });
      return types;
    }
  });
});