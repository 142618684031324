define("a24-ember-staffshift-core/components/context-switcher-component", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/context-switcher-component"], function (exports, _didRenderChangesMixin, _contextSwitcherComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _contextSwitcherComponent.default,

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        arrContexts: null,
        objSelectedContext: null, // This will store the context that the user is currently logged in
        arrSwitchableContexts: null, // This will store all the other contexts that the user has assigned
        _sAppContext: Ember.computed.readOnly("env.sAppContext"),
        _objUserContextService: Ember.inject.service("rest-user-context"),
        _objUserSessionService: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objConstantsSSCore: Ember.inject.service("a24-constants-staffshift-core"),
        _objConstantsEmberLib: Ember.inject.service("a24-constants-ember-lib"),
        _sSelectedResourceType: null,
        _bLoading: false,
        init: function init() {
            this._super.apply(this, arguments);
            // If array properties are null, we set them as an empty array
            if (this.get("arrContexts") == null) {
                this.set("arrContexts", []);
            }
            if (this.get("arrSwitchableContexts") == null) {
                this.set("arrSwitchableContexts", []);
            }
            this.set("_sSelectedResourceType", this.get("_objUserSessionService.objGeneralDetails.sResourceType"));
            this.retrieveUserContexts();
            this.set("_bLoading", this.get("_objNavigation.bPageLoading"));
        },
        retrieveUserContexts: function retrieveUserContexts() {
            var _this = this;

            var sUserId = this.get("_objUserSessionService").objAuthData.context.sUserId;

            this.get("_objUserContextService").getList(this, sUserId, function (arrData) {
                // At least one context will be set
                _this.set("arrContexts", arrData);
                _this.convertContextForDisplay();
            });
        },
        onPageLoadingChange: Ember.observer("_objNavigation.bPageLoading", function () {
            this.set("_bLoading", this.get("_objNavigation.bPageLoading"));
        }),
        onResourceTypeChange: Ember.observer("_objUserSessionService.objGeneralDetails", function () {

            if (!a24Core.isEmpty(this.get("_objUserSessionService.objGeneralDetails"))) {
                // When switching between 2 of the same contexts, the init does not re-fire, so we do it manually
                if (this.get("_sSelectedResourceType") === this.get("_objUserSessionService.objGeneralDetails.sResourceType")) {
                    this.retrieveUserContexts();
                }
            }
        }),
        convertContextForDisplay: function convertContextForDisplay() {
            // Get a list of contexts
            var arrContexts = this.get("arrContexts");

            // Hold a temporary list of contexts that the user can switch to
            var arrSwitchableContexts = [];

            // Hold a temporary default context
            var objDefaultContext = {};

            // Iterate over the contexts that were retrieved
            for (var i = 0; i < arrContexts.length; i++) {

                // Get the current context
                var objContext = arrContexts[i];

                // Get the avatar url
                var sAvatarUrl = a24.getUserContextAvatarUrl(this.get("env.sTriageBaseUrl"), this.get("_objConstantsSSCore"), objContext.sAvatarUrl);

                // Build the new context object for display
                var objRebuiltContext = {
                    sDisplayLetter: objContext.sName.charAt(0).toUpperCase(),
                    sDisplayName: objContext.sName,
                    sAvatarUrl: sAvatarUrl,
                    sType: objContext.objReferenceId.sResource,
                    sId: objContext.id,
                    sRefId: objContext.objReferenceId.mId
                };

                // Set this context as the default context if it is marked as default
                if (objContext.bDefaultContext) {
                    objDefaultContext = objRebuiltContext;
                }

                // If this context is the selected one
                if (objContext.bSelectedContext) {
                    // Set as the selected context
                    this.set("objSelectedContext", objRebuiltContext);
                } else {
                    // Else, add to the array of switchable contexts
                    arrSwitchableContexts.pushObject(objRebuiltContext);
                }
            }

            if (a24Core.isEmpty(this.get("objSelectedContext"))) {
                // If no selected context could be found
                // Loop over the switchable contexts
                var arrNewSwitchableContexts = [];
                for (var j = 0; j < arrSwitchableContexts.length; j++) {
                    // If you do not match the selected context
                    if (objDefaultContext.sRefId !== arrSwitchableContexts[j].sRefId) {
                        // add to new list
                        arrNewSwitchableContexts.pushObject(arrSwitchableContexts[j]);
                    }
                }
                // Set the new list as the switchable list
                arrSwitchableContexts = arrNewSwitchableContexts;
                // Set the default context as the selected context
                this.set("objSelectedContext", objDefaultContext);
            }

            // Sort the array alphabetically
            if (!a24Core.isEmpty(arrSwitchableContexts)) {
                arrSwitchableContexts.sort(function (objA, objB) {
                    if (objA.sDisplayName.toLowerCase() < objB.sDisplayName.toLowerCase()) {
                        return -1;
                    } else if (objA.sDisplayName.toLowerCase() > objB.sDisplayName.toLowerCase()) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            }

            this.set("arrSwitchableContexts", arrSwitchableContexts);
        },
        actions: {
            contextSwitch: function contextSwitch(objContext) {
                var _this2 = this;

                if (!a24Core.isEmpty(this.get("closeDropdown"))) {
                    this.get("closeDropdown")();
                }

                if (objContext.sType === this.get("_objConstantsSSCore").CONTEXT_AGENCY_GROUP) {
                    // AGENCY GROUP
                    var objParams = {};
                    objParams["agency-group"] = [objContext.sRefId];

                    this.get("_objNavigation").navigateOutsideContext("agency-group.welcome", objParams, {}, false, !a24Core.isEmpty(this.get("_sAppContext")));
                } else if (objContext.sType === this.get("_objConstantsSSCore").CONTEXT_CANDIDATE) {
                    // CANDIDATE
                    this.get("_objNavigation").navigateOutsideContext("candidate.welcome", {}, {}, false, !a24Core.isEmpty(this.get("_sAppContext")));
                } else {

                    //Seems there is a loading order problem on gwt side when switching to gwt context from ember context
                    //Gwt then loads correct context but think you are still in ember context. Thus context switcher on gwt
                    //screen is wrong and further service calls fail. So for this reason we first switch/set the new
                    //context on ember before going to gwt
                    this.get("_objUserContextService").setSelectedContext(this, objContext.sId, function () {
                        // Navigate to another context in gwt using navigation service
                        _this2.get("_objNavigation").navigateToGWTContext(objContext.sRefId, true);
                    });
                }
            }
        }
    });
});