define("a24-ember-staffshift-users/components/page-agency-group-user-list", ["exports", "a24-ember-staffshift-users/templates/components/page-agency-group-user-list", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _pageAgencyGroupUserList, _inputFormElement, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _pageAgencyGroupUserList.default,

        // Services
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigation: Ember.inject.service("navigation"),
        _objFabButtonService: Ember.inject.service("fab-button"),
        _objAgencyGroupService: Ember.inject.service("rest-agency-group"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objStaffshiftUsersEnumsService: Ember.inject.service("a24-enums-staffshift-users"),
        _objStaffshiftUsersStringsService: Ember.inject.service("a24-strings-staffshift-users"),
        _objStringsService: Ember.inject.service("a24-strings"),
        // Default Properties
        sUrlBase: "",
        objQueryParams: null,
        objDatagridConfig: null,
        // whether the page is in loading
        _bPageLoading: true,
        // the agency group id
        _sAgencyGroupId: null,
        // variables for add user popup
        _bShowAddUser: false,
        _bShowUnsavedData: false,
        _bAddUserPopupLoading: false,

        // add popup close action
        _objSpecialAddPopupAction: null,
        // original data
        _objOriginalData: null,
        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),
        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objStaffshiftUsersStringsService").getString("listUsers"));

            var sUrl = Ember.get(this, "sRestApiBaseUrl") + "/v1/agency-group/" + Ember.get(this, "_objNavigation.sContextId") + "/user";

            // Build up the url with context id
            Ember.set(this, "sUrlBase", sUrl);

            var arrStatusMapping = this.get("_objStaffshiftUsersEnumsService").getList("statuses").map(function (objItem) {
                return {
                    sValue: objItem.sValue,
                    sTitle: objItem.sLabel
                };
            });

            var sAgencyGroupId = Ember.get(this, "_objNavigation.sContextId");
            this.set("_sAgencyGroupId", sAgencyGroupId);

            // set up datagrid
            Ember.set(this, "objDatagridConfig", {
                sDatagridHeader: Ember.get(this, "_objStaffshiftUsersStringsService").getString("listUsers"),
                iMaxHeight: 40 * 27,
                bSelectColumn: false,
                bEmitSelectRowEvent: false,
                arrSingleItemSelectActions: null,
                arrHeaderConfig: [{
                    iUniqueColNum: 1,
                    sTitle: Ember.get(this, "_objStringsService").getString("username"),
                    sProperty: "sUsername",
                    sFilterType: "text",
                    bText: true,
                    iWidth: 27
                }, {
                    iUniqueColNum: 2,
                    sTitle: Ember.get(this, "_objStringsService").getString("firstName"),
                    sProperty: "sFirstName",
                    sFilterType: "text",
                    bText: true,
                    iWidth: 27
                }, {
                    iUniqueColNum: 3,
                    sTitle: Ember.get(this, "_objStringsService").getString("lastName"),
                    sProperty: "sLastName",
                    sFilterType: "text",
                    bText: true,
                    iWidth: 27
                }, {
                    iUniqueColNum: 4,
                    sTitle: Ember.get(this, "_objStringsService").getString("status"),
                    sProperty: "sAgencyGroupUserStatus",
                    sFilterType: "dropdown",
                    bMapping: true,
                    arrMappingData: arrStatusMapping,
                    bStopColumnSort: true,
                    iWidth: 19
                }, {
                    iUniqueColNum: 5,
                    sTitle: Ember.get(this, "_objStringsService").getString("email"),
                    sProperty: "sEmailAddress",
                    sFilterType: "text",
                    bText: true,
                    iWidth: 0,
                    bSilent: true
                }]
            });

            // setup close button on popup
            this.set("_objSpecialAddPopupAction", {
                sTitle: Ember.get(this, "_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onPopupAddUserClose");
                }
            });

            // Set the validation on the form
            Ember.set(this, "objChildConfig", {
                "objChildren.sFirstName": function objChildrenSFirstName(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 2);
                    a24Validation.addMaxLength(objMixin, 100);
                },
                "objChildren.sLastName": function objChildrenSLastName(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 2);
                    a24Validation.addMaxLength(objMixin, 100);
                },
                "objChildren.sEmailAddress": function objChildrenSEmailAddress(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 2);
                    a24Validation.addMaxLength(objMixin, 100);
                }
            });

            this.get("_objFabButtonService").showFabButton(function () {
                _this.addUserFabButton();
            });

            this.set("_bPageLoading", false);
        },

        refreshUserList: function refreshUserList() {
            var _this2 = this;

            // Put the page in loading
            this.set("_bPageLoading", true);

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    // Take page out of loading
                    _this2.set("_bPageLoading", false);
                });
            });
        },
        addUserFabButton: function addUserFabButton() {
            var _this3 = this;

            this.clearAllErrorMessages();
            this.clearForm();

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    Ember.set(_this3, "_objOriginalData", _this3.getPlainFormValueObject());
                });
            });

            this.set("_bShowAddUser", true);
        },
        addUser: function addUser(sAgencyGroupId) {
            var _this4 = this;

            var objPayload = this.getPlainFormValueObject();

            // Set the card into loading
            Ember.set(this, "_bAddUserPopupLoading", true);

            Ember.get(this, "_objSnackbarService").showSnackbarMessage(Ember.get(this, "_objStaffshiftUsersStringsService").getString("addingUser"));

            // Do the add service call
            Ember.get(this, "_objAgencyGroupService").addUser(this, "_objAddUser", sAgencyGroupId, function () {

                // Hide the add user popup
                Ember.set(_this4, "_bShowAddUser", false);
                Ember.set(_this4, "_bAddUserPopupLoading", false);

                Ember.get(_this4, "_objSnackbarService").showSnackbarMessage(Ember.get(_this4, "_objStaffshiftUsersStringsService").getString("userAddSuccess"));

                // GET user list again
                _this4.refreshUserList();
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                //take popup out of loading state
                Ember.set(_this4, "_bAddUserPopupLoading", false);
                if (sCode === "PATTERN") {
                    _this4.setResponseError(objError.arrPath, "validationRestInvalidFormat");
                } else if (sCode === "REGEX_NAME_NO_MATCH") {
                    _this4.setResponseError(objError.arrPath, "validationRestInvalidChar");
                } else {
                    Ember.get(_this4, "_objSnackbarService").showSnackbarMessage(Ember.get(_this4, "_objStaffshiftUsersStringsService").getString("userAddFailure"));
                }
            }), objPayload);
        },
        cancelClosePopup: function cancelClosePopup() {
            if (!Ember.get(this, "_bAddUserPopupLoading")) {

                // Check if the form has any unsaved data
                var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), Ember.get(this, "_objOriginalData"));

                if (!bDataMatches) {
                    Ember.set(this, "_bShowUnsavedData", true);
                } else {
                    this.clearForm();
                    this.clearAllErrorMessages();
                    Ember.set(this, "_bShowAddUser", false);
                }
            }
        },
        actions: {
            // Swallowing the action, no need for it currently
            cardDatagridTableCell: function cardDatagridTableCell() {},
            onPopupAddConfirm: function onPopupAddConfirm() {
                // Do the validation on the form
                if (!this.doFullValidation()) {
                    // If the validation passed

                    // Get the agency group id
                    var sAgencyGroupId = Ember.get(this, "_sAgencyGroupId");

                    // Add the user
                    this.addUser(sAgencyGroupId);
                }
            },
            onPopupAddCancel: function onPopupAddCancel() {
                this.cancelClosePopup();
            },

            // handle cancel button pressed
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.clearForm();
                this.clearAllErrorMessages();
                Ember.set(this, "_bShowAddUser", false);
            },
            onPopupAddUserClose: function onPopupAddUserClose() {
                this.cancelClosePopup();
            }
        }
    });
});