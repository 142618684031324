var a24ConstantsExtendSSEL = {
    // VERY SPECIFIC USE CASE
    DATE_FORMAT_ISO_NO_MILLI_FORMAT: "YYYY-MM-DDTHH:mm:ssZ",

    //Date formats
    DATE_FORMAT_REST_FORMAT_MONTH_YEAR_ONLY: "YYYY-MM",
    DATE_FORMAT_REST_FORMAT_DATE_ONLY: "YYYY-MM-DD",
    DATE_FORMAT_REST_FORMAT_WITHOUT_TZ: "YYYY-MM-DDTHH:mm:ss.sss",
    DATE_FORMAT_REST_FORMAT: "YYYY-MM-DDTHH:mm:ss.sssZ"
};

if (typeof a24Constants === "undefined") {
    var a24Constants = {};
}

Object.keys(a24ConstantsExtendSSEL).forEach(function(sKey) {
    a24Constants[sKey] = a24ConstantsExtendSSEL[sKey];
});
