enifed('ember-runtime/system/core_object', ['exports', 'ember-babel', 'container', 'ember-utils', 'ember-metal', 'ember-runtime/mixins/action_handler', 'ember-runtime/inject', 'ember-debug', 'ember-environment', 'ember/features'], function (exports, _emberBabel, _container, _emberUtils, _emberMetal, _action_handler, _inject, _emberDebug, _emberEnvironment, _features) {
  'use strict';

  exports.POST_INIT = undefined;

  var _Mixin$create, _ClassMixinProps;

  var schedule = _emberMetal.run.schedule;
  var applyMixin = _emberMetal.Mixin._apply;
  var reopen = _emberMetal.Mixin.prototype.reopen;

  var POST_INIT = exports.POST_INIT = (0, _emberUtils.symbol)('POST_INIT');

  function makeCtor() {
    // Note: avoid accessing any properties on the object since it makes the
    // method a lot faster. This is glue code so we want it to be as fast as
    // possible.

    var wasApplied = false;
    var initFactory = void 0;

    var Class = function () {
      function Class(properties) {
        var _self;

        (0, _emberBabel.classCallCheck)(this, Class);

        var self = this;

        if (!wasApplied) {
          Class.proto(); // prepare prototype...
        }

        var beforeInitCalled = void 0; // only used in debug builds to enable the proxy trap

        // using DEBUG here to avoid the extraneous variable when not needed
        if (true) {
          beforeInitCalled = true;
        }

        if (true && _features.MANDATORY_GETTER && _features.EMBER_METAL_ES5_GETTERS && _emberUtils.HAS_NATIVE_PROXY && typeof self.unknownProperty === 'function') {
          var messageFor = function (obj, property) {
            return 'You attempted to access the `' + String(property) + '` property (of ' + obj + ').\n' + 'Since Ember 3.1, this is usually fine as you no longer need to use `.get()`\n' + 'to access computed properties. However, in this case, the object in question\n' + 'is a special kind of Ember object (a proxy). Therefore, it is still necessary\n' + ('to use `.get(\'' + String(property) + '\')` in this case.\n\n') + 'If you encountered this error because of third-party code that you don\'t control,\n' + 'there is more information at https://github.com/emberjs/ember.js/issues/16148, and\n' + 'you can help us improve this error message by telling us more about what happened in\n' + 'this situation.';
          };

          /* globals Proxy Reflect */
          self = new Proxy(this, {
            get: function (target, property, receiver) {
              if (property === _emberMetal.PROXY_CONTENT) {
                return target;
              } else if (beforeInitCalled || typeof property === 'symbol' || (0, _emberUtils.isInternalSymbol)(property) || property === _emberUtils.GUID_KEY_PROPERTY || property === 'toJSON' || property === 'toString' || property === 'toStringExtension' || property === 'didDefineProperty' || property === 'willWatchProperty' || property === 'didUnwatchProperty' || property === 'didAddListener' || property === 'didRemoveListener' || property === '__DESCRIPTOR__' || property === 'isDescriptor' || property in target) {
                return Reflect.get(target, property, receiver);
              }

              var value = target.unknownProperty.call(receiver, property);

              if (typeof value !== 'function') {
                (true && !(value === undefined || value === null) && (0, _emberDebug.assert)(messageFor(receiver, property), value === undefined || value === null));
              }
            }
          });
        }

        self.__defineNonEnumerable(_emberUtils.GUID_KEY_PROPERTY);
        var m = (0, _emberMetal.meta)(self);
        var proto = m.proto;
        m.proto = self;

        if (initFactory) {
          _container.FACTORY_FOR.set(this, initFactory);
          if (true && self !== this) {
            _container.FACTORY_FOR.set(self, initFactory);
          }
          initFactory = null;
        }

        if (properties !== undefined) {
          (true && !(typeof properties === 'object' && properties !== null) && (0, _emberDebug.assert)('EmberObject.create only accepts objects.', typeof properties === 'object' && properties !== null));
          (true && !(!(properties instanceof _emberMetal.Mixin)) && (0, _emberDebug.assert)('EmberObject.create no longer supports mixing in other ' + 'definitions, use .extend & .create separately instead.', !(properties instanceof _emberMetal.Mixin)));


          var concatenatedProperties = self.concatenatedProperties;
          var mergedProperties = self.mergedProperties;
          var hasConcatenatedProps = concatenatedProperties !== undefined && concatenatedProperties.length > 0;
          var hasMergedProps = mergedProperties !== undefined && mergedProperties.length > 0;

          var keyNames = Object.keys(properties);

          for (var i = 0; i < keyNames.length; i++) {
            var keyName = keyNames[i];
            var value = properties[keyName];

            if (_emberEnvironment.ENV._ENABLE_BINDING_SUPPORT && _emberMetal.Mixin.detectBinding(keyName)) {
              m.writeBindings(keyName, value);
            }

            (true && !(!(value instanceof _emberMetal.ComputedProperty)) && (0, _emberDebug.assert)('EmberObject.create no longer supports defining computed ' + 'properties. Define computed properties using extend() or reopen() ' + 'before calling create().', !(value instanceof _emberMetal.ComputedProperty)));
            (true && !(!(typeof value === 'function' && value.toString().indexOf('._super') !== -1)) && (0, _emberDebug.assert)('EmberObject.create no longer supports defining methods that call _super.', !(typeof value === 'function' && value.toString().indexOf('._super') !== -1)));
            (true && !(!(keyName === 'actions' && _action_handler.default.detect(this))) && (0, _emberDebug.assert)('`actions` must be provided at extend time, not at create time, ' + 'when Ember.ActionHandler is used (i.e. views, controllers & routes).', !(keyName === 'actions' && _action_handler.default.detect(this))));


            var possibleDesc = (0, _emberMetal.descriptorFor)(self, keyName, m);
            var isDescriptor = possibleDesc !== undefined;

            if (!isDescriptor) {
              var baseValue = self[keyName];

              if (hasConcatenatedProps && concatenatedProperties.indexOf(keyName) > -1) {
                if (baseValue) {
                  value = (0, _emberUtils.makeArray)(baseValue).concat(value);
                } else {
                  value = (0, _emberUtils.makeArray)(value);
                }
              }

              if (hasMergedProps && mergedProperties.indexOf(keyName) > -1) {
                value = (0, _emberUtils.assign)({}, baseValue, value);
              }
            }

            if (isDescriptor) {
              possibleDesc.set(self, keyName, value);
            } else if (typeof self.setUnknownProperty === 'function' && !(keyName in self)) {
              self.setUnknownProperty(keyName, value);
            } else {
              if (_features.MANDATORY_SETTER) {
                (0, _emberMetal.defineProperty)(self, keyName, null, value); // setup mandatory setter
              } else {
                self[keyName] = value;
              }
            }
          }
        }

        if (_emberEnvironment.ENV._ENABLE_BINDING_SUPPORT) {
          _emberMetal.Mixin.finishPartial(self, m);
        }

        // using DEBUG here to avoid the extraneous variable when not needed
        if (true) {
          beforeInitCalled = false;
        }
        (_self = self).init.apply(_self, arguments);

        self[POST_INIT]();

        m.proto = proto;
        (0, _emberMetal.finishChains)(m);
        (0, _emberMetal.sendEvent)(self, 'init', undefined, undefined, undefined, m);

        // only return when in debug builds and `self` is the proxy created above
        if (true && self !== this) {
          return self;
        }
      }

      Class.willReopen = function willReopen() {
        if (wasApplied) {
          Class.PrototypeMixin = _emberMetal.Mixin.create(Class.PrototypeMixin);
        }

        wasApplied = false;
      };

      Class._initFactory = function _initFactory(factory) {
        initFactory = factory;
      };

      Class.proto = function proto() {
        var superclass = Class.superclass;
        if (superclass) {
          superclass.proto();
        }

        if (!wasApplied) {
          wasApplied = true;
          Class.PrototypeMixin.applyPartial(Class.prototype);
        }

        return this.prototype;
      };

      return Class;
    }();

    Class.toString = _emberMetal.Mixin.prototype.toString;

    return Class;
  }

  var IS_DESTROYED = (0, _emberMetal.descriptor)({
    configurable: true,
    enumerable: false,

    get: function () {
      return (0, _emberMetal.peekMeta)(this).isSourceDestroyed();
    },
    set: function (value) {
      (true && !(value === IS_DESTROYED) && (0, _emberDebug.assert)('You cannot set `' + this + '.isDestroyed` directly, please use `.destroy()`.', value === IS_DESTROYED));
    }
  });

  var IS_DESTROYING = (0, _emberMetal.descriptor)({
    configurable: true,
    enumerable: false,

    get: function () {
      return (0, _emberMetal.peekMeta)(this).isSourceDestroying();
    },
    set: function (value) {
      (true && !(value === IS_DESTROYING) && (0, _emberDebug.assert)('You cannot set `' + this + '.isDestroying` directly, please use `.destroy()`.', value === IS_DESTROYING));
    }
  });

  /**
    @class CoreObject
    @public
  */
  var CoreObject = makeCtor();
  CoreObject.toString = function () {
    return 'Ember.CoreObject';
  };
  CoreObject.PrototypeMixin = _emberMetal.Mixin.create((_Mixin$create = {
    reopen: function () {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      applyMixin(this, args, true);
      return this;
    },
    init: function () {}
  }, _Mixin$create[POST_INIT] = function () {}, _Mixin$create.__defineNonEnumerable = function (property) {
    Object.defineProperty(this, property.name, property.descriptor);
    //this[property.name] = property.descriptor.value;
  }, _Mixin$create.concatenatedProperties = null, _Mixin$create.mergedProperties = null, _Mixin$create.isDestroyed = IS_DESTROYED, _Mixin$create.isDestroying = IS_DESTROYING, _Mixin$create.destroy = function () {
    var m = (0, _emberMetal.peekMeta)(this);
    if (m.isSourceDestroying()) {
      return;
    }

    m.setSourceDestroying();

    schedule('actions', this, this.willDestroy);
    schedule('destroy', this, this._scheduledDestroy, m);

    return this;
  }, _Mixin$create.willDestroy = function () {}, _Mixin$create._scheduledDestroy = function (m) {
    if (m.isSourceDestroyed()) {
      return;
    }
    (0, _emberMetal.deleteMeta)(this);
    m.setSourceDestroyed();
  }, _Mixin$create.toString = function () {
    var hasToStringExtension = typeof this.toStringExtension === 'function';
    var extension = hasToStringExtension ? ':' + this.toStringExtension() : '';

    var ret = '<' + (this[_emberUtils.NAME_KEY] || _container.FACTORY_FOR.get(this) || this.constructor.toString()) + ':' + (0, _emberUtils.guidFor)(this) + extension + '>';

    return ret;
  }, _Mixin$create));

  CoreObject.PrototypeMixin.ownerConstructor = CoreObject;

  CoreObject.__super__ = null;

  var ClassMixinProps = (_ClassMixinProps = {

    isClass: true,

    isMethod: false
  }, _ClassMixinProps[_emberUtils.NAME_KEY] = null, _ClassMixinProps[_emberUtils.GUID_KEY] = null, _ClassMixinProps.extend = function () {
    var Class = makeCtor();
    var proto = void 0;
    Class.ClassMixin = _emberMetal.Mixin.create(this.ClassMixin);
    Class.PrototypeMixin = _emberMetal.Mixin.create(this.PrototypeMixin);

    Class.ClassMixin.ownerConstructor = Class;
    Class.PrototypeMixin.ownerConstructor = Class;

    reopen.apply(Class.PrototypeMixin, arguments);

    Class.superclass = this;
    Class.__super__ = this.prototype;

    proto = Class.prototype = Object.create(this.prototype);
    proto.constructor = Class;
    (0, _emberUtils.generateGuid)(proto);
    (0, _emberMetal.meta)(proto).proto = proto; // this will disable observers on prototype

    Class.ClassMixin.apply(Class);
    return Class;
  }, _ClassMixinProps.create = function (props, extra) {
    var C = this;

    if (extra === undefined) {
      return new C(props);
    } else {
      return new C(flattenProps.apply(this, arguments));
    }
  }, _ClassMixinProps.reopen = function () {
    this.willReopen();
    reopen.apply(this.PrototypeMixin, arguments);
    return this;
  }, _ClassMixinProps.reopenClass = function () {
    reopen.apply(this.ClassMixin, arguments);
    applyMixin(this, arguments, false);
    return this;
  }, _ClassMixinProps.detect = function (obj) {
    if ('function' !== typeof obj) {
      return false;
    }
    while (obj) {
      if (obj === this) {
        return true;
      }
      obj = obj.superclass;
    }
    return false;
  }, _ClassMixinProps.detectInstance = function (obj) {
    return obj instanceof this;
  }, _ClassMixinProps.metaForProperty = function (key) {
    var proto = this.proto();
    var possibleDesc = (0, _emberMetal.descriptorFor)(proto, key);

    (true && !(possibleDesc !== undefined) && (0, _emberDebug.assert)('metaForProperty() could not find a computed property with key \'' + key + '\'.', possibleDesc !== undefined));

    return possibleDesc._meta || {};
  }, _ClassMixinProps._computedProperties = (0, _emberMetal.computed)(function () {
    (0, _emberMetal._hasCachedComputedProperties)();
    var proto = this.proto();
    var possibleDesc = void 0;
    var properties = [];

    for (var name in proto) {
      possibleDesc = (0, _emberMetal.descriptorFor)(proto, name);

      if (possibleDesc !== undefined) {
        properties.push({
          name: name,
          meta: possibleDesc._meta
        });
      }
    }
    return properties;
  }).readOnly(), _ClassMixinProps.eachComputedProperty = function (callback, binding) {
    var property = void 0;
    var empty = {};

    var properties = (0, _emberMetal.get)(this, '_computedProperties');

    for (var i = 0; i < properties.length; i++) {
      property = properties[i];
      callback.call(binding || this, property.name, property.meta || empty);
    }
  }, _ClassMixinProps);

  if (_emberEnvironment.ENV._ENABLE_PROPERTY_REQUIRED_SUPPORT) {
    ClassMixinProps.ClassMixin = _emberMetal.REQUIRED;
    ClassMixinProps.PrototypeMixin = _emberMetal.REQUIRED;
  }

  function injectedPropertyAssertion() {
    (true && !((0, _inject.validatePropertyInjections)(this)) && (0, _emberDebug.assert)('Injected properties are invalid', (0, _inject.validatePropertyInjections)(this)));
  }

  function flattenProps() {
    var concatenatedProperties = this.concatenatedProperties,
        mergedProperties = this.mergedProperties;

    var hasConcatenatedProps = concatenatedProperties !== undefined && concatenatedProperties.length > 0;
    var hasMergedProps = mergedProperties !== undefined && mergedProperties.length > 0;

    var initProperties = {};

    for (var _len2 = arguments.length, props = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      props[_key2] = arguments[_key2];
    }

    for (var i = 0; i < props.length; i++) {
      var properties = props[i];

      (true && !(!(properties instanceof _emberMetal.Mixin)) && (0, _emberDebug.assert)('EmberObject.create no longer supports mixing in other ' + 'definitions, use .extend & .create separately instead.', !(properties instanceof _emberMetal.Mixin)));


      var keyNames = Object.keys(properties);

      for (var j = 0, k = keyNames.length; j < k; j++) {
        var keyName = keyNames[j];
        var value = properties[keyName];

        if (hasConcatenatedProps && concatenatedProperties.indexOf(keyName) > -1) {
          var baseValue = initProperties[keyName];

          if (baseValue) {
            value = (0, _emberUtils.makeArray)(baseValue).concat(value);
          } else {
            value = (0, _emberUtils.makeArray)(value);
          }
        }

        if (hasMergedProps && mergedProperties.indexOf(keyName) > -1) {
          var _baseValue = initProperties[keyName];

          value = (0, _emberUtils.assign)({}, _baseValue, value);
        }

        initProperties[keyName] = value;
      }
    }

    return initProperties;
  }

  if (true) {
    /**
      Provides lookup-time type validation for injected properties.
       @private
      @method _onLookup
    */
    ClassMixinProps._onLookup = injectedPropertyAssertion;
    /**
      Returns a hash of property names and container names that injected
      properties will lookup on the container lazily.
       @method _lazyInjections
      @return {Object} Hash of all lazy injected property keys to container names
      @private
    */
    ClassMixinProps._lazyInjections = function () {
      var injections = {};
      var proto = this.proto();
      var key = void 0;
      var desc = void 0;

      for (key in proto) {
        desc = (0, _emberMetal.descriptorFor)(proto, key);
        if (desc instanceof _emberMetal.InjectedProperty) {
          injections[key] = desc.type + ':' + (desc.name || key);
        }
      }

      return injections;
    };
  }

  var ClassMixin = _emberMetal.Mixin.create(ClassMixinProps);

  ClassMixin.ownerConstructor = CoreObject;

  CoreObject.ClassMixin = ClassMixin;

  ClassMixin.apply(CoreObject);
  exports.default = CoreObject;
});