define("a24-ember-staffshift-candidate-availability/services/availability", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        sContext: null,

        /*
          Services
        */
        _objNavigation: Ember.inject.service("navigation"),
        _objUserSession: Ember.inject.service("user-session"),
        _objAvailabilityStrings: Ember.inject.service("a24-strings-staffshift-availability"),
        _objConstantsEmberLibService: Ember.inject.service("a24-constants-ember-lib"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),

        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),
        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        init: function init() {
            this._super.apply(this, arguments);

            this.set("sContext", this.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE"));
        },

        /*
          Functions
        */
        /**
         * Performs a single availability ajax call and formats the availability data
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id to fetch availability data for
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         * @param objQueryParams - Any extra query params for the get list
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  30 August 2017
         */
        getFormattedAvailability: function getFormattedAvailability(objCaller, sProperty, sCandidateId, iCurrentMonth, iCurrentYear, funcSuccess, objFailure, objQueryParams) {

            var objThis = this;
            var funcSuccessFormatData = function funcSuccessFormatData(arrData) {
                funcSuccess(objThis.formatAvailabilityData(arrData, iCurrentMonth, iCurrentYear));
            };

            return this.getAvailability(objCaller, sProperty, sCandidateId, funcSuccessFormatData, objFailure, objQueryParams);
        },

        /**
         * Performs a single availability ajax call
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id to fetch availability data for
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         * @param objQueryParams - Any extra query params for the get list
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  30 August 2017
         */
        getAvailability: function getAvailability(objCaller, sProperty, sCandidateId, funcSuccess, objFailure, objQueryParams) {

            a24RestUrlConstruct.setBaseUrl("/v1/candidate/" + sCandidateId + "/availability");
            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, Ember.get(this, "_objUserSession"), Ember.get(this, "_objNavigation"), "GET", Ember.get(this, 'sRestApiBaseUrl') + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Formats an array of availability data
         *
         * @param arrData - An array of availability data objects
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  30 August 2017
         */
        formatAvailabilityData: function formatAvailabilityData(arrData, iCurrentMonth, iCurrentYear) {
            var _this = this;

            var arrParsedData = [{}, {}, {}];
            $.each(arrData, function (sKey, objValue) {
                var objDate = a24DateManager.createDate(Ember.get(_this, "_objUserSession.objContextDetails.sTimezone"));
                objDate.setDateFromRest(objValue.dAvailabilityDate, true);

                var iDay = objDate.getTimeOnDate(_this.get("_objConstantsEmberLibService").MOMENT_TIME_DATE);
                var iMonth = objDate.getTimeOnDate(_this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS);
                var iYear = objDate.getTimeOnDate(_this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS);

                // 0 - prev
                // 1 - current
                // 2 - future
                var iIndex = iYear < iCurrentYear ? 0 : // prev
                iYear > iCurrentYear ? 2 : // future
                iMonth === iCurrentMonth ? 1 : // same
                iMonth < iCurrentMonth ? 0 : 2;

                arrParsedData[iIndex][iDay] = {
                    arrData: {
                        objEarly: {
                            sState: objValue.sEarly,
                            sLabel: Ember.get(_this, "_objAvailabilityStrings").getString(objValue.sEarly.toLowerCase())
                        },
                        objLate: {
                            sState: objValue.sLate,
                            sLabel: Ember.get(_this, "_objAvailabilityStrings").getString(objValue.sLate.toLowerCase())
                        },
                        objNight: {
                            sState: objValue.sNight,
                            sLabel: Ember.get(_this, "_objAvailabilityStrings").getString(objValue.sNight.toLowerCase())
                        }
                    },
                    dAvailabilityDate: objValue.dAvailabilityDate,
                    bEdit: false
                };
            });

            var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
            var iMonth;
            for (var a = 0; a < arrParsedData.length; a++) {
                if (a === 0) {
                    iMonth = iCurrentMonth - 1;
                } else if (a === 1) {
                    iMonth = iCurrentMonth;
                } else {
                    //Its okey for it to go to 13 since with moment setting month to 13 will increase the year which is
                    //what we want.
                    iMonth = iCurrentMonth + 1;
                }
                for (var i = 0; i < 31; i++) {
                    if (a24Core.isEmpty(arrParsedData[a][i + 1])) {
                        objDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS, iCurrentYear);
                        objDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS, iMonth);
                        objDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_DATE, i + 1);

                        var sDate = objDate.getDateStringForRest(true);
                        arrParsedData[a][i + 1] = {
                            arrData: {
                                objEarly: {
                                    sState: Ember.get(this, "_objAvailabilityStrings").getString("unknown"),
                                    sLabel: Ember.get(this, "_objAvailabilityStrings").getString("unknown")
                                },
                                objLate: {
                                    sState: Ember.get(this, "_objAvailabilityStrings").getString("unknown"),
                                    sLabel: Ember.get(this, "_objAvailabilityStrings").getString("unknown")
                                },
                                objNight: {
                                    sState: Ember.get(this, "_objAvailabilityStrings").getString("unknown"),
                                    sLabel: Ember.get(this, "_objAvailabilityStrings").getString("unknown")
                                }
                            },
                            dAvailabilityDate: sDate,
                            bEdit: false
                        };
                    }
                }
            }

            var objFinalParsedData = {
                objPrevMonth: arrParsedData[0],
                objMonth: arrParsedData[1],
                objNextMonth: arrParsedData[2]
            };

            return objFinalParsedData;
        },

        updateAvailabilityForMonth: function updateAvailabilityForMonth(objMonthDays, objBulkUpdateConfig) {
            var objStartDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
            objStartDate.setDateFromBrowser(objBulkUpdateConfig.dStart, a24Constants.DATE_FORMAT);
            objStartDate.setTimeToStartOfDay(); //So that time does not affect the isAfter
            var objEndDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
            objEndDate.setDateFromBrowser(objBulkUpdateConfig.dEnd, a24Constants.DATE_FORMAT);
            objEndDate.setTimeToEndOfDay(); //So that time does not affect the isBefore on same dates
            var objNewMonthDays = Ember.copy(objMonthDays, true);
            for (var i = 1; i <= 31; i++) {
                if (!a24Core.isEmpty(objMonthDays[i])) {
                    var objAvailabilityDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
                    objAvailabilityDate.setDateFromRest(objMonthDays[i].dAvailabilityDate, true);
                    //currently there is a bug in the setDateFromRest when using the date or month year only flag where
                    //it will keep the timezone of the browser. Using the setTimeToStartOfDay corrects the timezone.
                    objAvailabilityDate.setTimeToStartOfDay();
                    //We add one min so that when this date matches the start range that it is after it e.g
                    //18 Jan 2020 00:00 is not after 18 Jan 2020 00:00
                    objAvailabilityDate.addTimeToDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MINUTES, 1);
                    // check if day in range
                    if (objAvailabilityDate.isAfter(objStartDate) && objEndDate.isAfter(objAvailabilityDate)) {
                        // check if week day selected for update
                        var bUpdateDay = false;
                        for (var k = 0; k < objBulkUpdateConfig.weekItems.length; k++) {
                            if (objBulkUpdateConfig.weekItems[k].sValue === objAvailabilityDate.getDayOfWeek()) {
                                bUpdateDay = true;
                                break;
                            }
                        }
                        if (bUpdateDay) {
                            if (objNewMonthDays[i].arrData.objEarly.sState !== Ember.get(this, "_objAvailabilityStrings").getString("booked")) {
                                objNewMonthDays[i].arrData.objEarly = Ember.copy(objBulkUpdateConfig.objAvailability.objEarly, true);
                            }
                            if (objNewMonthDays[i].arrData.objLate.sState !== Ember.get(this, "_objAvailabilityStrings").getString("booked")) {
                                objNewMonthDays[i].arrData.objLate = Ember.copy(objBulkUpdateConfig.objAvailability.objLate, true);
                            }
                            if (objNewMonthDays[i].arrData.objNight.sState !== Ember.get(this, "_objAvailabilityStrings").getString("booked")) {
                                objNewMonthDays[i].arrData.objNight = Ember.copy(objBulkUpdateConfig.objAvailability.objNight, true);
                            }
                        }
                    }
                }
            }
            return objNewMonthDays;
        },
        formatBulkAvailabilityData: function formatBulkAvailabilityData(objMonthDays, objNextMonthDays, objNonCurrentMonth, objBulkUpdateConfig, objMonthTwoDays, objMonthThreeDays) {
            // update current month
            var objNewMonthDays = this.updateAvailabilityForMonth(objMonthDays, objBulkUpdateConfig);
            var objNewNextMonthDays = this.updateAvailabilityForMonth(objNextMonthDays, objBulkUpdateConfig);
            var objNewMonthTwoDays = this.updateAvailabilityForMonth(objMonthTwoDays, objBulkUpdateConfig);
            var objNewMonthThreeDays = this.updateAvailabilityForMonth(objMonthThreeDays, objBulkUpdateConfig);

            if (a24Core.isEmpty(objNonCurrentMonth)) {
                objNonCurrentMonth = {};
            }
            if (!a24Core.isEmpty(objNextMonthDays)) {
                // build objNonCurrentMonth
                // eg.
                // 2020: { //<--year number
                //     12: { //<--month number
                //         6: { //<--day number
                //             arrData: {objEarly, objLate, objNight, dAvailabilityDate}
                //         },
                //         7: {
                //             arrData: {objEarly, objLate, objNight, dAvailabilityDate}
                //         }
                //         ...
                //     }
                //     ...
                // },

                // start from next month
                var objNextAvailabilityDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
                objNextAvailabilityDate.setDateFromRest(objMonthThreeDays[1].dAvailabilityDate, true);
                objNextAvailabilityDate.addTimeToDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS, 1);
                //currently there is a bug in the setDateFromRest when using the date or month year only flag where
                //it will keep the timezone of the browser. Using the setTimeToStartOfDay corrects the timezone.
                objNextAvailabilityDate.setTimeToStartOfDay();
                //We add one min so that when this date matches the start range that it is after it e.g
                //18 Jan 2020 00:00 is not after 18 Jan 2020 00:00
                objNextAvailabilityDate.addTimeToDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MINUTES, 1);

                var objStartDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
                objStartDate.setDateFromBrowser(objBulkUpdateConfig.dStart, a24Constants.DATE_FORMAT);
                objStartDate.setTimeToStartOfDay(); //So that time does not affect the isAfter

                var objEndDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
                objEndDate.setDateFromBrowser(objBulkUpdateConfig.dEnd, a24Constants.DATE_FORMAT);
                objEndDate.setTimeToEndOfDay(); //So that time does not affect the isBefore on same dates

                var n = 0;
                while (objNextAvailabilityDate.isBefore(objEndDate)) {
                    if (objNextAvailabilityDate.isAfter(objStartDate)) {

                        var iDay = objNextAvailabilityDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_DATE);
                        var iMonth = objNextAvailabilityDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS);
                        var iYear = objNextAvailabilityDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS);

                        // check if week day selected for update
                        var bUpdateMonthDay = false;
                        for (var m = 0; m < objBulkUpdateConfig.weekItems.length; m++) {
                            if (objBulkUpdateConfig.weekItems[m].sValue === objNextAvailabilityDate.getDayOfWeek()) {
                                bUpdateMonthDay = true;
                                break;
                            }
                        }
                        if (bUpdateMonthDay) {
                            if (!objNonCurrentMonth.hasOwnProperty(iYear)) {
                                objNonCurrentMonth[iYear] = {};
                            }
                            if (!objNonCurrentMonth[iYear].hasOwnProperty(iMonth)) {
                                objNonCurrentMonth[iYear][iMonth] = {};
                            }

                            objNonCurrentMonth[iYear][iMonth][iDay] = {
                                arrData: {
                                    objEarly: Ember.copy(objBulkUpdateConfig.objAvailability.objEarly, true),
                                    objLate: Ember.copy(objBulkUpdateConfig.objAvailability.objLate, true),
                                    objNight: Ember.copy(objBulkUpdateConfig.objAvailability.objNight, true)
                                },
                                dAvailabilityDate: objNextAvailabilityDate.getDateStringForRest(true)
                            };
                        }
                    }

                    objNextAvailabilityDate.addTimeToDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_DAYS, 1);

                    n++;
                    if (n > 31 * 6) {
                        // prevent infinate loop
                        break;
                    }
                }
            }

            var objFinalParsedData = {
                objMonth: objNewMonthDays,
                objNextMonth: objNewNextMonthDays,
                objMonthTwoDays: objNewMonthTwoDays,
                objMonthThreeDays: objNewMonthThreeDays,
                objNonCurrentMonth: objNonCurrentMonth
            };

            return objFinalParsedData;
        },
        updateAvailability: function updateAvailability(objCaller, sProperty, sCandidateId, funcSuccess, objFailure, objPayload) {
            a24RestUrlConstruct.setBaseUrl("/v1/candidate/" + sCandidateId + "/availability");

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, Ember.get(this, "_objUserSession"), Ember.get(this, "_objNavigation"), "POST", Ember.get(this, 'sRestApiBaseUrl') + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objPayload));
        }
    });
});