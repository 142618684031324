define("a24-ember-policy-library/services/a24-enums-policy-library", ["exports", "a24-ember-lookup/services/a24-enums-base"], function (exports, _a24EnumsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24EnumsBase.default.extend({
        _objPolicyStringsService: Ember.inject.service("a24-strings-policy-library"),

        init: function init() {
            this._super.apply(this, arguments);

            this.set("policies", {
                "ACKNOWLEDGED": {
                    "backend": "acknowledged",
                    "frontend": this.get("_objPolicyStringsService").getString("accepted")
                },
                "NOT_ACKNOWLEDGED": {
                    "backend": "not_acknowledged",
                    "frontend": this.get("_objPolicyStringsService").getString("notAccepted")
                },
                "READ_ONLY": {
                    "backend": "read_only",
                    "frontend": this.get("_objPolicyStringsService").getString("readOnly")
                }
            });

            this.set("classification", {
                "VIEWABLE": {
                    "backend": "viewable",
                    "frontend": this.get("_objPolicyStringsService").getString("viewable")
                },
                "ACKNOWLEDGEABLE": {
                    "backend": "acknowledgeable",
                    "frontend": this.get("_objPolicyStringsService").getString("acknowledgeable")
                }
            });
        }
    });
});