define("a24-ember-staffshift-users/services/rest-acl-group-resource-tree", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        sTriageBaseRest: Ember.computed.readOnly("env.sTriageBaseRest"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),
        /**
         * Get a list of all available resources
         * 
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  22 October 2018
         */
        getAclGroupResourceTree: function getAclGroupResourceTree(objCaller, sProperty, funcSuccess, objFailure) {

            a24RestUrlConstruct.setBaseUrl("/rest/acl/group/resource-tree");

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, Ember.get(this, "_objUserSession"), Ember.get(this, "_objNavigation"), "GET", Ember.get(this, "sTriageBaseRest") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Get a list of all available resources for a specific group
         * 
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sGroupId - The acl group id that you wish to retrieve the resources for
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  22 October 2018
         */
        getAclGroupResourceTreeByGroupId: function getAclGroupResourceTreeByGroupId(objCaller, sProperty, sGroupId, funcSuccess, objFailure) {

            a24RestUrlConstruct.setBaseUrl("/rest/acl/group/" + sGroupId + "/resource-tree");

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, Ember.get(this, "_objUserSession"), Ember.get(this, "_objNavigation"), "GET", Ember.get(this, "sTriageBaseRest") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});