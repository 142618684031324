define("a24-ember-staffshift-users/services/rest-agency-group-user-whitelist", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        /*
          Services
        */
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        _sRestApiBaseUrl: Ember.computed.readOnly("_objEnv.sRestApiBaseUrl"),

        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /*
          Functions
        */
        /**
         * Gets all user whitelistings
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  25 Sept 2019
         */
        getUserWhitelistings: function getUserWhitelistings(objContext, sProperty, funcSuccess, objFailure) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency-group/" + sAgencyGroupId + "/ip-whitelist-all-access-user");

            a24RestUrlConstruct.addQueryParamObject({
                sortBy: {
                    mValue: "user_id"
                }
            });

            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: {
                    mValue: 1000
                }
            });

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Add an user whitelisting
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param objData - Post body for adding an user whitelisting
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  25 Sept 2019
         */
        addUserToWhitelist: function addUserToWhitelist(objContext, sProperty, objData, funcSuccess, objFailure) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency-group/" + sAgencyGroupId + "/ip-whitelist-all-access-user");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        },

        /**
         * Delete an user whitelisting
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sUserId - User Id to delete
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  25 Sept 2019
         */
        deleteUserWhitelist: function deleteUserWhitelist(objContext, sProperty, sUserId, funcSuccess, objFailure) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency-group/" + sAgencyGroupId + "/ip-whitelist-all-access-user/" + sUserId);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "DELETE", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});