enifed('ember-views/system/jquery', ['exports', 'ember-environment'], function (exports, _emberEnvironment) {
  'use strict';

  exports.jQueryDisabled = undefined;


  var jQuery = void 0;
  var jQueryDisabled = exports.jQueryDisabled = false;

  if (_emberEnvironment.environment.hasDOM) {
    jQuery = _emberEnvironment.context.imports.jQuery;

    if (jQuery) {
      if (jQuery.event.addProp) {
        jQuery.event.addProp('dataTransfer');
      } else {
        // http://www.whatwg.org/specs/web-apps/current-work/multipage/dnd.html#dndevents
        ['dragstart', 'drag', 'dragenter', 'dragleave', 'dragover', 'drop', 'dragend'].forEach(function (eventName) {
          jQuery.event.fixHooks[eventName] = {
            props: ['dataTransfer']
          };
        });
      }
    } else {
      exports.jQueryDisabled = jQueryDisabled = true;
    }
  }

  exports.default = jQuery;
});