define("a24-ember-lib/components/loading-component", ["exports", "a24-ember-lib/templates/components/loading-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _loadingComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _loadingComponent.default,
        classNames: ["center-text-horizontal"]
    });
});