define("a24-ember-staffshift-users/services/rest-agency-users", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * This service method will make a GET to the agency group to look up available users
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sAgencyId - The id of the agency to get users for
         * @param sQueryString - The query string for the query params
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objQuery - Extra params to add to the query
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  03 October 2019
         */
        getAvailableUserByQuery: function getAvailableUserByQuery(objCaller, sProperty, sAgencyId, sQueryString, funcSuccess, objFailure, objQuery) {

            var sUrl = Ember.get(this, "sRestApiBaseUrl") + "/v1/agency/" + sAgencyId + "/user";

            a24RestUrlConstruct.setBaseUrlSwagger(sUrl);
            if (a24Core.isEmpty(objQuery)) {
                a24RestUrlConstruct.addQueryParamObject({
                    items_per_page: {
                        mValue: 10
                    },
                    q: {
                        mValue: sQueryString,
                        bLike: true,
                        bEncode: true
                    },
                    sStatus: {
                        mValue: "active"
                    }
                });
            } else {
                a24RestUrlConstruct.addQueryParamObject(objQuery);
            }

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, Ember.get(this, "_objUserSession"), Ember.get(this, "_objNavigation"), "GET", a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },


        /**
         * This service method will make a GET to the agency to look up available users for suggest
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sAgencyId - The id of the agency to get users for
         * @param sQueryString - The query string for the query params
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         *
         * @author Ruan Naude <ruan.naude@a24group.com>
         * @since  24 Nov 2020
         */
        suggestUsers: function suggestUsers(objCaller, sProperty, sAgencyId, sQueryString, funcSuccess, objFailure) {
            this.getAvailableUserByQuery(objCaller, sProperty, sAgencyId, sQueryString, funcSuccess, objFailure, {
                items_per_page: {
                    mValue: 10
                },
                q: {
                    mValue: sQueryString,
                    bLike: true,
                    bEncode: true
                }
            });
        }
    });
});