define("a24-ember-lib/components/snackbar-component", ["exports", "a24-ember-lib/templates/components/snackbar-component", "a24-ember-window/mixins/a24responsive", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _snackbarComponent, _a24responsive, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_a24responsive.default, _didRenderChangesMixin.default, {
        layout: _snackbarComponent.default,
        classNames: ["a24-snackbar"],
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objDomService: Ember.inject.service("dom-event"),
        _objWindowConstants: Ember.inject.service("a24-constants-window"),
        _objSnackbarTimer: null,
        _iTimerStartTime: 0,
        _iSlideTime: a24Constants.SNACKBAR_SLIDE_TIME,
        _iShowTime: 8000,
        _iShowTimeQuick: 1000,
        _sMessage: "",
        _funcActionCallback: null,
        _sAction: "",
        _sActionTextClass: "",
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.get("_objSnackbarService");
            this.get("_objDomService");
            Ember.run.scheduleOnce("afterRender", this, function () {
                var objSnackbar = $(_this.$(".a24-snackbar-inner"));
                objSnackbar.hammer().on("swipeleft", function () {
                    _this.dismissMessage();
                });
                objSnackbar.hammer().on("swiperight", function () {
                    _this.dismissMessage();
                });
            });
        },
        willDestroyElement: function willDestroyElement() {
            var objSnackbar = $(this.$(".a24-snackbar-inner"));
            objSnackbar.hammer().off("swipeleft");
            objSnackbar.hammer().off("swiperight");
        },
        showSnackbarMessage: Ember.observer("_objSnackbarService._bHasNewMessage", function () {
            var _this2 = this;

            if (this.get("_objSnackbarService._bHasNewMessage")) {
                this.set("_objSnackbarService._bHasNewMessage", false);

                if (this.get("_objSnackbarService.sSnackbarState") === "hideEnd") {
                    //If no messages are showing then just show the message
                    this.handleCleanMessage();
                } else if (this.get("_objSnackbarService.sSnackbarState") === "showEnd") {
                    //If one is already showing then cut down the time it should show
                    Ember.run.cancel(this.get("_objSnackbarTimer"));
                    this.set("_objSnackbarTimer", null);

                    var iTimeElapsed = momentHelper.getTimezoneInMilli() - this.get("_iTimerStartTime");
                    if (iTimeElapsed > this.get("_iShowTimeQuick")) {
                        //Has already shown past the quick show time so just hide immediately
                        this.funcHideStart();
                    } else {
                        //Has not shown past the quick show time yet, so update time with remainder of quick show time
                        this.set("_objSnackbarTimer", Ember.run.later(function () {
                            _this2.funcHideStart();
                        }, this.get("_iShowTimeQuick") - iTimeElapsed));
                    }
                }
            }
        }),
        handleCleanMessage: function handleCleanMessage() {
            var _this3 = this;

            var objMessage = this.get("_objSnackbarService._arrSnackbarMessages").objectAt(0);
            this.get("_objSnackbarService._arrSnackbarMessages").removeAt(0);

            this.set("_sMessage", objMessage.sMessage);
            this.set("_funcActionCallback", objMessage.funcActionCallback);
            this.set("_sAction", objMessage.sAction);
            this.set("_sActionTextClass", objMessage.sActionTextClass);

            //Need to run this in next to get the correct height since the message might or might not wrap depending on
            //device and max width of snackbar. Also need to have a pre showStart status so that when 2 message get set
            //in the same process that the que and not override one another.
            this.set("_objSnackbarService.sSnackbarState", "beforeShowStart");
            Ember.run.next(function () {
                Ember.run.scheduleOnce("afterRender", _this3, _this3.funcShowStart);
            });
        },
        funcShowStart: function funcShowStart() {
            this.set("_objSnackbarService.sSnackbarState", "showStart");

            this.setSnackbarStyle();
            this.animateIn();
        },
        funcShowEnd: function funcShowEnd() {
            var _this4 = this;

            this.set("_objSnackbarService.sSnackbarState", "showEnd");

            var iShowTime = this.get("_iShowTime");
            if (this.get("_objSnackbarService._arrSnackbarMessages").length > 0) {
                iShowTime = this.get("_iShowTimeQuick");
            }

            this.set("_objSnackbarTimer", Ember.run.later(function () {
                _this4.funcHideStart();
            }, iShowTime));

            this.set("_iTimerStartTime", momentHelper.getTimezoneInMilli());
        },
        funcHideStart: function funcHideStart() {
            var _this5 = this;

            this.set("_objSnackbarService.sSnackbarState", "hideStart");
            this.set("_objSnackbarTimer", null);
            this.set("_iTimerStartTime", 0);

            this.$().animate({
                height: 0
            }, this.get("_iSlideTime"), function () {
                Ember.run(function () {
                    _this5.funcHideEnd();
                });
            });
        },
        funcHideEnd: function funcHideEnd() {
            this.set("_objSnackbarService.sSnackbarState", "hideEnd");

            //Check if there are more messages
            if (this.get("_objSnackbarService._arrSnackbarMessages").length > 0) {
                this.handleCleanMessage();
            }
        },
        animateIn: function animateIn() {
            var _this6 = this;

            //Stop the current animation if there is one and create new one with the correct height
            this.$().stop();
            this.$().animate({
                height: this.get("_objSnackbarService.iSnackbarHeight")
            }, this.get("_iSlideTime"), function () {
                Ember.run(function () {
                    _this6.funcShowEnd();
                });
            });
        },
        setSnackbarStyle: function setSnackbarStyle() {
            var sSize = this.get("sScreenSize");
            if (sSize === this.get("_objWindowConstants.MEDIA_SIZE_TABLET") || sSize === this.get("_objWindowConstants.MEDIA_SIZE_DESKTOP") || sSize === this.get("_objWindowConstants.MEDIA_SIZE_JUMBO")) {
                this.setTabletDesktopStyle();
            } else {
                this.setMobileStyle();
            }
            this.set("_objSnackbarService.iSnackbarHeight", this.$(".a24-snackbar-inner").outerHeight());
        },
        setTabletDesktopStyle: function setTabletDesktopStyle() {
            //Have to apply the left css here so that the first time around the full width of the page is available
            //for the snackbar to use to get the correct width for the margin minus calc, otherwise only half the screen
            //is available and that could be less than the max width
            this.$().css({
                marginLeft: -(this.$(".a24-snackbar-inner").outerWidth() / 2),
                left: "50%"
            });
        },
        setMobileStyle: function setMobileStyle() {
            this.$().css({
                marginLeft: 0,
                left: "0%"
            });
        },
        screenWidthObserve: Ember.observer("_objDomService.iPageWidth", function () {
            this.setSnackbarStyle();
        }),
        onHeightChange: Ember.observer("_objSnackbarService.iSnackbarHeight", function () {
            if (this.get("_objSnackbarService.sSnackbarState") === "showStart") {
                this.animateIn();
            } else if (this.get("_objSnackbarService.sSnackbarState") === "showEnd") {
                //Just set the snackbar to the correct height
                this.$().css({
                    height: this.get("_objSnackbarService.iSnackbarHeight")
                });
            }
        }),
        dismissMessage: function dismissMessage() {
            if (this.get("_objSnackbarService.sSnackbarState") === "showEnd") {
                Ember.run.cancel(this.get("_objSnackbarTimer"));
                this.set("_objSnackbarTimer", null);
                this.funcHideStart();
            }
        },
        actions: {
            onActionClicked: function onActionClicked() {
                this.get("_funcActionCallback")();
                this.dismissMessage();
            },
            onDismissMessage: function onDismissMessage() {
                this.dismissMessage();
            }
        }
    });
});