define("a24-ember-staffshift-users/services/ipify-ip-address-check", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objAjax: $.ajax,
        getUserIpAddress: function getUserIpAddress(funcProcessResponse) {
            this.get("_objAjax")({
                url: "https://api.ipify.org?format=json",
                cache: true,
                dataType: "json"
            }).always(function (sResponse) {
                funcProcessResponse(sResponse);
            });
        }
    });
});