define("a24-ember-staffshift-candidate-availability/components/icon-label", ["exports", "a24-ember-staffshift-candidate-availability/templates/components/icon-label", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _iconLabel, _didRenderChangesMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
    /*
      Default values
    */
    layout: _iconLabel.default,
    classNames: ["icon-label", "text-ellipse"],
    classNameBindings: ["bIconOnly:text-center", "bClickable:cursor-clickable"],

    /*
      @property sIcon
      The google material icon name.
    */
    sIcon: null,

    /*
      @property sText
      The text to display next to the icon.
    */
    sText: null,

    /*
      @property bIconOnly
      True if you only want to show the icon.
    */
    bIconOnly: false,

    /*
      @property bClickable
      True if an "onClick" action should be dispatched when a click event is triggered.
    */
    bClickable: false,

    /*
      Events
    */
    click: function click(event) {
      if (Ember.get(this, "bClickable")) {
        if (!a24Core.isEmpty(this.get("onClick"))) {
          this.get("onClick")(event);
        }
      }
    }
  });
});