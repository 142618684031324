define("a24-ember-staffshift-users/components/whitelisted-users", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/mixins/input-form-element", "a24-ember-staffshift-users/templates/components/whitelisted-users"], function (exports, _didRenderChangesMixin, _inputFormElement, _whitelistedUsers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _whitelistedUsers.default,
        arrUsers: null,
        funcGetSuggestions: null,
        objWhitelistUserService: null,
        objUserService: null,

        // Services
        _objNavigationService: Ember.inject.service("navigation"),
        _objUserSession: Ember.inject.service("user-session"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objUsersStringsService: Ember.inject.service("a24-strings-staffshift-users"),
        _objStringsService: Ember.inject.service("a24-strings"),

        // Promises
        _objSpecialAddEditPopupAction: null,
        _objGetUserSuggestions: null,
        _objGetLinkedUser: null,
        _objRemoveUser: null,
        _objAddUser: null,
        _objOriginalData: null,

        // Stored Lists
        _arrCustomActions: null,
        _arrFilterConfig: null,
        _arrHeaderActions: null,

        // Default properties
        _bShowAddUserPopup: false,
        _bShowConfirmDelete: false,
        _bAddUserPopupLoading: false,
        _bShowDeleteWarning: false,
        _bPopupDeleteLoading: false,

        _sUserToRemove: null,
        _sUserDetailsToRemove: "",

        // Init function
        init: function init() {
            var _this = this;

            // Call super
            this._super.apply(this, arguments);

            this.set("_arrHeaderActions", [{
                sTitle: this.get("_objStringsService").getString("add"),
                sIcon: "add",
                executeCardAction: function executeCardAction() {
                    _this.addActionClicked();
                }
            }]);

            this.set("_objSpecialAddEditPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onPopupAddUserClose");
                }
            });

            // Set the custom actions
            this.set("_arrCustomActions", [{
                sTooltipText: this.get("_objStringsService").getString("add"),
                sIcon: "add"
            }]);

            // Set the validation on the form
            this.set("objChildConfig", {
                "objChildren.user": function objChildrenUser(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                }
            });

            // Set the suggestions function for the popup
            this.setGetSuggestionFunction();

            // Do the service calls
            this.doServiceCalls();
        },
        setGetSuggestionFunction: function setGetSuggestionFunction() {
            var _this2 = this;

            // Agency Group Id
            var sAgencyGroup = this.get("_objUserSession").getUserContextReference().mId;

            this.set("funcGetSuggestions", function (sQueryString, funcOnComplete) {
                // sQueryString - The query string search that was made
                // funcOnComplete - The function to call on completion

                // Make a service call to retrieve the data for the items
                _this2.get("objUserService").getAvailableUserByQuery(_this2, "_objGetUserSuggestions", sAgencyGroup, sQueryString, // Query string passed to function
                function (arrData) {
                    // When service call succeeds

                    // If the data is empty, ensure it's an empty array
                    if (a24Core.isEmpty(arrData)) {
                        arrData = [];
                    }

                    // Define the array that will contain formatted data
                    var arrFormatted = [];

                    // Loop over the results
                    for (var i = 0; i < arrData.length; i++) {

                        // Get the item at index i
                        var objDataItem = arrData[i];

                        // Build the label to show
                        var sLabel = objDataItem.sFirstName + " " + objDataItem.sLastName + " - " + objDataItem.sUsername;

                        // Push label with id as an object to the search box
                        arrFormatted.push({
                            sLabel: sLabel,
                            id: objDataItem.id
                        });
                    }

                    // Call the callback with the formatted data
                    funcOnComplete(arrFormatted);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    // Call the callback with empty
                    funcOnComplete([]);
                }));
            });
        },

        // Make the service calls required for the card to display correctlty
        doServiceCalls: function doServiceCalls() {
            var _this3 = this;

            // Set the loading to true
            this.set("bLoading", true);

            // Get the list of whitelisted users
            this.get("objWhitelistUserService").getUserWhitelistings(this, "_objGetUserWhitelistings", function (arrData) {
                // Prep the data then take the form out of loading
                _this3.prepareForDisplay(arrData);
                _this3.set("bLoading", false);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                // Prep empty data then take the form out of loading
                _this3.prepareForDisplay([]);
                _this3.set("bLoading", false);
            }));
        },

        // Prepare user list for display
        prepareForDisplay: function prepareForDisplay(arrData) {

            // Reset the data if it is empty
            if (a24Core.isEmpty(arrData)) {
                arrData = [];
            }

            arrData.sort(function (objA, objB) {
                if (objA.first_name < objB.first_name) {
                    return -1;
                } else if (objA.first_name > objB.first_name) {
                    return 1;
                }
                return 0;
            });

            // Store a this instance
            var objThis = this;

            // Function used to generate a custom function per item
            var funcCardActionRemoveExecute = function funcCardActionRemoveExecute(objUser) {
                // Return the function to remove the user with id = sId
                return function () {
                    // Set the current user id as the id
                    objThis.set("_sUserToRemove", objUser.id);
                    objThis.set("_sUserDetailsToRemove", objUser.name);
                    // Show the confirm delete
                    objThis.set("_bShowConfirmDelete", true);
                };
            };

            // Define the array of users
            var arrDisplayUsers = [];

            // Loop over the data that is present
            for (var i = 0; i < arrData.length; i++) {

                // Concat the first and last name
                var sFullName = arrData[i].first_name + " " + arrData[i].last_name;

                // set a user object
                var objUser = {
                    id: arrData[i].user_id,
                    name: sFullName
                };

                // Push the item in to the array
                arrDisplayUsers.push({
                    sLabel: this.get("_objStringsService").getHTMLTokenString("a24-string-html-dot-value", sFullName, arrData[i].username),
                    sIcon: "person",
                    arrActions: [{
                        sTitle: this.get("_objStringsService").getString("remove"),
                        sIcon: "delete",
                        executeCardAction: funcCardActionRemoveExecute(objUser)
                    }]
                });
            }

            // Set the array of users
            this.set("arrUsers", arrDisplayUsers);
        },

        // Remove the user
        removeUser: function removeUser(sUserId) {
            var _this4 = this;

            this.set("_bPopupDeleteLoading", true);

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("removingItem", this.get("_objUsersStringsService").getString("user")));

            // Do the remove service call
            this.get("objWhitelistUserService").deleteUserWhitelist(this, "_objRemoveUser", sUserId, function () {
                // Hide the confirm delete popup
                _this4.set("_bShowConfirmDelete", false);

                _this4.get("_objSnackbarService").showSnackbarMessage(_this4.get("_objStringsService").getTokenString("successRemoveItem", _this4.get("_objUsersStringsService").getString("user")));
                _this4.set("_bPopupDeleteLoading", false);
                // GET user list again
                _this4.doServiceCalls();
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this4.set("_bShowConfirmDelete", false);

                _this4.get("_objSnackbarService").showSnackbarMessage(_this4.get("_objStringsService").getTokenString("failRemoveItem", _this4.get("_objUsersStringsService").getString("user")));
                _this4.set("_bPopupDeleteLoading", false);
            }));
        },
        addUser: function addUser(sUserId) {
            var _this5 = this;

            // Set the card in to loading
            this.set("_bAddUserPopupLoading", true);

            var objData = {
                "user_id": sUserId
            };

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objUsersStringsService").getString("user")));

            // Do the add service call
            this.get("objWhitelistUserService").addUserToWhitelist(this, "_objAddUser", objData, function () {

                // Hide the add user popup
                _this5.set("_bShowAddUserPopup", false);

                _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("successAddItem", _this5.get("_objUsersStringsService").getString("user")));

                // GET user list again
                _this5.doServiceCalls();
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {

                if (sCode === "409") {
                    _this5.setResponseError(["user"], "validationUserAlreadyExist", false, false, _this5.get("_objUsersStringsService"));
                } else {
                    _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("failAddItem", _this5.get("_objUsersStringsService").getString("user")));
                }

                _this5.set("_bAddUserPopupLoading", false);
            }));
        },
        addActionClicked: function addActionClicked() {
            var _this6 = this;

            this.clearForm();
            this.clearAllErrorMessages();
            this.set("_bAddUserPopupLoading", false);
            this.set("_bShowAddUserPopup", true);

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    _this6.set("_objOriginalData", _this6.getPlainFormValueObject());
                });
            });
        },

        // ACTIONS
        actions: {
            onPopupDeleteClose: function onPopupDeleteClose() {
                if (this.get("_bPopupDeleteLoading")) {
                    return;
                }
                this.set("_bShowConfirmDelete", false);
            },
            // Once confirmation is given on removing a user
            onRemoveUserPopupConfirm: function onRemoveUserPopupConfirm() {
                if (this.get("_objUserSession.objAuthData.context.sUserId") !== this.get("_sUserToRemove")) {
                    // Remove the user with the stored id
                    this.removeUser(this.get("_sUserToRemove"));
                } else {
                    this.set("_bShowDeleteWarning", true);
                }
            },
            onRemoveUserAddressWarningPopupConfirm: function onRemoveUserAddressWarningPopupConfirm() {
                this.set("_bShowDeleteWarning", false);
                this.removeUser(this.get("_sUserToRemove"));
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.clearForm();
                this.clearAllErrorMessages();
                this.set("_bShowAddUserPopup", false);
            },
            onPopupAddUserClose: function onPopupAddUserClose() {
                if (!this.get("_bAddUserPopupLoading")) {
                    var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

                    if (bDataMatches) {
                        this.clearForm();
                        this.clearAllErrorMessages();
                        this.set("_bShowAddUserPopup", false);
                    } else {
                        this.set("_bShowUnsavedData", true);
                    }
                }
            },
            onPopupAddUserConfirm: function onPopupAddUserConfirm() {

                // Do the validation on the form
                if (!this.doFullValidation()) {
                    // If the validation passed

                    // Get the user id of the user that is being added
                    var sUserId = this.getPlainFormValueObject().user.id;

                    // Add the user with the correct id
                    this.addUser(sUserId);
                }
            }
        }
    });
});