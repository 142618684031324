define("a24-ember-candidate-profile/components/candidate-compliance-exemptions", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-compliance-exemptions"], function (exports, _didRenderChangesMixin, _candidateComplianceExemptions) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _candidateComplianceExemptions.default,
        bDatagridLoading: false,
        sCandidateId: null,
        sCandidateName: null,
        sUrlBase: null,
        objQueryParams: null,
        sContext: null,

        _bPageLoading: false,
        _bServerError: false,
        _bReactPopupLoading: true,
        _bReactPopupOpen: false,

        _sIFrameUrl: "",

        _objSelectedItem: null,
        _objReactPopupPostData: null,

        _arrSelectedItems: null,

        _env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        _sReactGatewayUrl: Ember.computed.readOnly("_env.sReactGatewayUrl"),
        _sRestApiBaseUrl: Ember.computed.readOnly("_env.sRestApiBaseUrl"),

        _objNavigation: Ember.inject.service("navigation"),
        _objUserSession: Ember.inject.service("user-session"),
        _objStringsCandidateProfile: Ember.inject.service("a24-strings-candidate-profile"),
        _objSSCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),

        _objReactEmberUtils: Ember.inject.service("react-ember-utils"),
        _objQueryParamBuilderService: Ember.inject.service("query-param-object-builder"),
        _objRestEntityActionSection: Ember.inject.service("rest-entity-action-section"),
        _objFabButtonService: Ember.inject.service("fab-button"),

        _getDefaultNowDateFilter: function _getDefaultNowDateFilter() {
            var objNowDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            objNowDate.setDateFromRestAllowTimeAlter(momentHelper.getCurrentDateString(a24Constants.DATE_FORMAT_REST_FORMAT));

            return objNowDate.getDateTimeFormat();
        },

        init: function init() {
            this._super.apply(this, arguments);

            this.set("_sIFrameUrl", this.get("_sReactGatewayUrl"));

            var sDefaultNowDate = this._getDefaultNowDateFilter();
            if (a24Core.isEmpty(this.get("objQueryParams.effective_to.sValue"))) {
                this.set("objQueryParams.effective_toFrom.sValue", sDefaultNowDate);
            }

            this._doServiceCalls();
        },

        _doServiceCalls: function _doServiceCalls() {
            this.set("_bPageLoading", true);

            var sContextId = this.get("_objNavigation.sContextId");

            a24RestUrlConstruct.setBaseUrl("/v1/" + this.get("sContext") + "/" + sContextId + "/candidate-type");
            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: { mValue: 1000 },
                name: {
                    bSortBy: true,
                    mValue: "asc"
                }
            });

            var sCandidateTypesEndpoint = this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl();

            var objPromiseCandidateTypes = this.get("_objReactEmberUtils").makeReactApiRequest({
                sEndpoint: sCandidateTypesEndpoint,
                objOptions: {
                    noAbortError: true,
                    method: 'GET',
                    fetchAll: true,
                    useCache: 24,
                    cacheTimeUnit: 'hours'
                },
                objParams: {}
            });

            var objPromiseEntityAction = this.get("_objRestEntityActionSection").getEntityActionsForSection(this, "_objPromiseEntityAction", "compliance-rule-exemption", function () {}, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{}));

            var objPromiseList = {
                _objCandidateTypesPromise: objPromiseCandidateTypes,
                _objPromiseEntityAction: objPromiseEntityAction
            };

            var objThis = this;

            var arrRequiredResponses = ["_objCandidateTypesPromise", "_objPromiseEntityAction"];

            if (this.get("sContext") === "agency-group") {
                a24RestUrlConstruct.setBaseUrl("/v1/agency-group/" + sContextId + "/agency");
                a24RestUrlConstruct.addQueryParamObject({
                    items_per_page: { mValue: 1000 }
                });

                var sAgenciesEndpoint = this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl();

                var objAgenciesPromise = this.get("_objReactEmberUtils").makeReactApiRequest({
                    sEndpoint: sAgenciesEndpoint,
                    objOptions: {
                        noAbortError: true,
                        method: 'GET',
                        fetchAll: true,
                        useCache: 24,
                        cacheTimeUnit: 'hours'
                    },
                    objParams: {}
                });

                objPromiseList._objAgenciesPromise = objAgenciesPromise;
                arrRequiredResponses.push("_objAgenciesPromise");
            }

            Ember.copy(a24RSVP).configure(this, objPromiseList, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    var arrCandidateTypes = [];
                    var arrAgencies = [];
                    if (!a24Core.isEmpty(objResponses._objPromiseEntityAction) && !a24Core.isEmpty(objResponses._objPromiseEntityAction.objResponse)) {
                        objThis._setFabAction(objResponses._objPromiseEntityAction.objResponse);
                    }

                    if (objThis.get("sContext") === "agency-group" && !a24Core.isEmpty(objResponses._objAgenciesPromise)) {
                        arrAgencies = objResponses._objAgenciesPromise.objResponse;

                        if (a24Core.isEmpty(arrAgencies)) {
                            arrAgencies = [];
                        }
                    }

                    if (!a24Core.isEmpty(objResponses._objCandidateTypesPromise)) {
                        arrCandidateTypes = objResponses._objCandidateTypesPromise.objResponse;

                        if (a24Core.isEmpty(arrCandidateTypes)) {
                            arrCandidateTypes = [];
                        }
                    }
                    if (!a24Core.isEmpty(objResponses._objCandidateTypesPromise)) {
                        objThis._setupPageConfig(arrCandidateTypes, arrAgencies);
                    }
                }
            }]);
        },

        _setFabAction: function _setFabAction(arrSectionActions) {
            var _this = this;

            var bAllowAction = false;
            if (!a24Core.isEmpty(arrSectionActions)) {
                var objSectionAction = arrSectionActions.find(function (objActionSection) {
                    return objActionSection.entity_type === "compliance-rule-exemption";
                });
                var arrAllowedActions = [];
                if (!a24Core.isEmpty(objSectionAction.actions)) {
                    objSectionAction.actions.forEach(function (objItem) {
                        if (objItem.allowed) {
                            arrAllowedActions.push(objItem.action);
                        }
                    });
                }
                bAllowAction = arrAllowedActions.includes("create");
            }
            if (bAllowAction) {
                this.get("_objFabButtonService").showFabButton(function () {
                    _this.set("_bReactPopupOpen", true);
                    _this.set("_objReactPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "agency/compliance/ruleExemptions/addPopup",
                            data: {
                                subjectId: _this.get("sCandidateId"),
                                subjectType: "candidate",
                                headerName: _this.get("sCandidateName")
                            }
                        }
                    });
                });
            }
        },

        _setupPageConfig: function _setupPageConfig(arrCandidateTypes, arrAgencies) {
            var objThis = this;

            var arrAppliesTo = this.get("_objCandidateProfileEnumsService").getList("appliesTo");
            var arrAppliesToOptions = [];
            for (var i = 0; i < arrAppliesTo.length; i++) {
                arrAppliesToOptions.push({
                    sTitle: arrAppliesTo[i].sLabel,
                    sValue: arrAppliesTo[i].sValue
                });
            }

            var arrAgenciesOptions = [];
            if (!a24Core.isEmpty(arrAgencies)) {
                for (var l = 0; l < arrAgencies.length; l++) {
                    arrAgenciesOptions.push({
                        sTitle: arrAgencies[l].agency_details.display_name,
                        sValue: arrAgencies[l].agency_id
                    });
                }
            }

            // This array contain the datagrid columns
            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objStringsService").getString("organisation"),
                sProperty: "organisation_id",
                bStopColumnSort: true,
                iWidth: 10,
                bSilent: true,
                sFilterType: "suggest",
                bFilterOr: true,
                sReactFilterRoute: "datagrid/filters/client",
                arrReactFilterExtraDataProps: ["index_types"]
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objStringsService").getString("organisation"),
                sProperty: "organisation_name",
                bText: true,
                iWidth: 10,
                bStopFiltering: true,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objStringsCandidateProfile").getString("site"),
                sProperty: "site_id",
                bStopColumnSort: true,
                iWidth: 10,
                bSilent: true,
                sFilterType: "suggest",
                bFilterOr: true,
                sReactFilterRoute: "datagrid/filters/client"
            }, {
                iUniqueColNum: 4,
                sTitle: this.get("_objStringsCandidateProfile").getString("site"),
                sProperty: "site_name",
                bText: true,
                iWidth: 15,
                bStopFiltering: true
            }, {
                iUniqueColNum: 5,
                sTitle: this.get("_objStringsCandidateProfile").getString("wardName"),
                sProperty: "ward_name",
                bText: true,
                iWidth: 15,
                bStopFiltering: true,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 6,
                sTitle: "At Client",
                sProperty: "at_client",
                bStopColumnSort: true,
                iWidth: 10,
                bSilent: true,
                sFilterType: "suggest",
                bFilterOr: true,
                sReactFilterRoute: "datagrid/filters/atClient"
            }, {
                iUniqueColNum: 7,
                sTitle: this.get("_objStringsCandidateProfile").getString("appliesTo"),
                sProperty: "applies_to",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrAppliesToOptions,
                iWidth: 10
            }, {
                iUniqueColNum: 8,
                sTitle: this.get("_objStringsCandidateProfile").getString("complianceRule"),
                sProperty: "compliance_rule_id",
                sFilterType: "suggest",
                sReactFilterRoute: "datagrid/filterEditor/complianceRule",
                arrReactFilterExtraDataProps: ["candidate_types"],
                iWidth: 10,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true
            }, {
                iUniqueColNum: 9,
                sTitle: this.get("_objStringsCandidateProfile").getString("complianceRule"),
                sProperty: "compliance_rule_name",
                bText: true,
                iWidth: 10,
                bStopFiltering: true
            }, {
                iUniqueColNum: 10,
                sTitle: this.get("_objStringsCandidateProfile").getString("effectiveFrom"),
                sProperty: "effective_from",
                sFilterType: "date",
                bDate: true,
                bFilterAllowTime: true,
                iWidth: 15
            }, {
                iUniqueColNum: 11,
                sTitle: this.get("_objStringsCandidateProfile").getString("effectiveTo"),
                sProperty: "effective_to",
                sFilterType: "date",
                bDate: true,
                bFilterAllowTime: true,
                iWidth: 15
            }].concat(_toConsumableArray(this.get("sContext") === "agency-group" ? [{
                iUniqueColNum: 12,
                sTitle: this.get("_objSSCoreStrings").getString("agency"),
                sProperty: "agency_id",
                sFilterType: "dropdown",
                bMapping: true,
                arrMappingData: arrAgenciesOptions,
                iWidth: 10,
                bSilent: true,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 13,
                sTitle: this.get("_objSSCoreStrings").getString("agency"),
                sProperty: "agency_name",
                sFilterType: "dropdown",
                bText: true,
                bStopFiltering: true,
                iWidth: 10
            }] : [])).filter(Boolean);

            var objPermanentQuery = {
                candidate_id: {
                    mValue: this.get("sCandidateId"),
                    sFitlerValue: this.get("sCandidateName")
                }
            };

            var arrSingleItemActions = [{
                sTitle: this.get("_objStringsCandidateProfile").getString("viewExemption"),
                sIcon: "visibility",
                executeCardAction: function executeCardAction() {
                    var objExemption = objThis.get("_arrSelectedItems")[0];
                    objThis._viewExemption(objExemption);
                }
            }];

            this.set("_objDatagridConfig", {
                sDatagridHeader: this.get("_objStringsCandidateProfile").getString("complianceExemptions"),
                iMaxHeight: 40 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bHasReloadButton: true,
                bEmitWillDoServiceCall: true,
                index_types: ["client_organisation"],
                candidate_types: arrCandidateTypes,
                sReactIFrameUrl: this.get("_sReactGatewayUrl"),
                arrSingleItemSelectActions: arrSingleItemActions,
                arrHeaderConfig: arrDataGridHeaderConfig,
                objPermanentQuery: objPermanentQuery
            });

            this.set("_bPageLoading", false);
        },

        _viewExemption: function _viewExemption(objExemption) {
            this.set("_bReactPopupOpen", true);
            this.set("_objReactPopupPostData", {
                type: "trigger_route",
                data: {
                    route: "agency/compliance/ruleExemptions/viewPopup",
                    data: {
                        complianceRuleExemptionId: objExemption.compliance_rule_exemption_id,
                        rangeId: objExemption.range_id,
                        agencyId: this.get("sContext") === "agency-group" ? objExemption.agency_id : undefined
                    }
                }
            });
        },

        _onItemsSelect: function _onItemsSelect(arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedItem", arrSelectedItems[0]);
            }
            // Set the selected items
            this.set("_arrSelectedItems", arrSelectedItems);
        },
        _refresh: function _refresh() {
            var _this2 = this;

            this.set("_bReactPopupOpen", false);
            // Put the page in loading
            this.set("_bPageLoading", true);

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this2)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    // Take page out of loading
                    _this2.set("_bPageLoading", false);
                });
            });
        },
        actions: {
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this._onItemsSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableCell: function cardDatagridTableCell() /*sColumnName, objRowData*/{},
            onPageReload: function onPageReload() {
                this._doServiceCalls();
            },
            onRecieveReactData: function onRecieveReactData(objData) {
                if (objData.type === "app_ready") {
                    this.set("_bReactPopupLoading", false);
                } else if (objData.type === "refresh") {
                    this._refresh();
                } else if (objData.type === "close") {
                    this.set("_bReactPopupOpen", false);
                }
            },
            onWillDoServiceCall: function onWillDoServiceCall()
            /*arrTableData,
            objUrlConstantPart,
            objFilterParamObject,
            objTableParamObject,
            iPage,
            iPerPage,
            objSuccessCallback,
            objFailure,
            objProject*/
            {
                if (!a24Core.isEmpty(this.get("onWillDoServiceCall"))) {
                    this.get("onWillDoServiceCall").apply(undefined, arguments);
                }
            }
        }
    });
});