define("a24-ember-candidate-profile/components/page-compliance-rule-view", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/page-compliance-rule-view"], function (exports, _didRenderChangesMixin, _pageComplianceRuleView) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _pageComplianceRuleView.default,
        sContext: null,
        classNames: ["page-compliance-rule-view"],

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),
        _sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),
        _sIFrameUrl: null,
        _objEntityActionPromise: null,
        _objProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),

        _objRestEntityAction: Ember.inject.service("rest-entity-action-compliance-rule"),
        _objNavigation: Ember.inject.service("navigation"),
        _objSnackbarService: Ember.inject.service("snackbar"),

        _sComplianceRuleId: null,
        _objReactViewPostData: null,

        _arrConvert: null,
        _arrEntityAction: null,
        // query builder params
        _bShowConditionBuilder: false,
        _sSimpleTagsUrl: null,
        _sSearchUrl: null,
        _bForceReset: null,
        _sWarningMessage: null,
        _bBusy: false,

        init: function init() {
            this._super.apply(this, arguments);

            this.set("_arrConvert", []);
            var objEnv = Ember.getOwner(this).resolveRegistration("config:environment");

            this.set("_sIFrameUrl", objEnv.sReactGatewayUrl);

            this.get("_objTitleBarService").setTitleBar(this.get("_objProfileStringsService").getString("viewComplianceRule"));

            // get the complaince rule id
            var objRouteParams = this.get("_objNavigation.objRouteParams");
            this._loadComplianceRule(objRouteParams);
        },
        _loadComplianceRule: function _loadComplianceRule(objRouteParams) {
            var sPrevComplianceId = this.get("_sComplianceRuleId");
            var sRoute = "index.agency-compliance-rule-view";

            if (this.get("sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP")) {
                sRoute = "agency-group.compliance-rule-view";
            }
            if (!a24Core.isEmpty(objRouteParams) && !a24Core.isEmpty(objRouteParams[sRoute])) {
                var objRouteParam = objRouteParams[sRoute];
                this.set("_sComplianceRuleId", objRouteParam["compliance_rule_id"]);
            }

            if (sPrevComplianceId !== this.get("_sComplianceRuleId")) {
                if (this.get("sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP")) {
                    this.set("_sSearchUrl", this.get("_sRestApiBaseUrl") + "/v1/agency-group/" + this.get("_objNavigation.sContextId") + "/advanced-search-v2/candidate");
                }

                this.set("_objReactViewPostData", {
                    type: "trigger_route",
                    data: {
                        route: "agency/complianceRules/viewPage",
                        data: {
                            complianceRuleId: this.get("_sComplianceRuleId")
                        }
                    }
                });
            }
        },

        _onRouteParamChange: Ember.observer("_objNavigation.objRouteParams", function () {
            var objRouteParams = this.get("_objNavigation.objRouteParams");
            this._loadComplianceRule(objRouteParams);
        }),

        _triggerQueue: Ember.observer("_arrConvert.@each", function () {
            var _this = this;

            if (!this.get("_bBusy") && !a24Core.isEmpty(this.get("_arrConvert"))) {
                this.set("_bBusy", true);
                var objConvert = this.get("_arrConvert").objectAt(0);
                this.set("_sSimpleTagsUrl", null); // This is to reset the observe as the below set didnt want to fire the observer
                if (a24Core.isEmpty(objConvert.sUrl)) {
                    this.set("_bForceReset", true);
                }
                Ember.run.next(function () {
                    if (a24Core.isEmpty(_this)) {
                        return;
                    }
                    _this.set("_sSimpleTagsUrl", objConvert.sUrl);
                });
                this.set("_iRequestId", objConvert.sRequestId);
            }
        }),
        actions: {
            onRecieveDataViewReact: function onRecieveDataViewReact(objData) {
                var _this2 = this;

                if (objData.type === "complianceRuleConditionEdit") {
                    this.set("_sSimpleTagsUrl", null); // This is to reset the observe as the below set didnt want to fire the observer
                    this.set("_bShowConditionBuilder", true);
                    this.set("_bPostSimpleTagsOnly", false);
                    Ember.run.next(function () {
                        if (a24Core.isEmpty(_this2)) {
                            return;
                        }
                        if (!a24Core.isEmpty(objData.data.url)) {
                            _this2.set("_sSimpleTagsUrl", objData.data.url);
                        } else {
                            _this2.set("_sSimpleTagsUrl", "");
                        }
                    });
                    if (!a24Core.isEmpty(objData.data.requestId)) {
                        this.set("_iRequestId", objData.data.requestId);
                    }
                    if (objData.data.index > 0) {
                        this.set("_sWarningMessage", this.get("_objProfileStringsService").getString("complianceRulesWarningMessage"));
                    } else {
                        this.set("_sWarningMessage", "");
                    }
                } else if (objData.type === "function") {
                    if (objData.data.type === "convertFilterUrl") {
                        this.set("_bPostSimpleTagsOnly", true);

                        this.get("_arrConvert").pushObject({
                            sUrl: objData.data.data,
                            sRequestId: objData.data.requestId
                        });
                    }
                } else if (objData.type === "refresh") {

                    this.set("_objReactViewPostData", {
                        type: "trigger_route",
                        data: {
                            route: "agency/complianceRules/viewPage",
                            data: {
                                complianceRuleId: this.get("_sComplianceRuleId")
                            }
                        }
                    });
                } else if (objData.type === "navigate_within_context") {
                    Ember.run.later(this, function () {
                        _this2.get("_objNavigation").navigateWithinContext("compliance-rules");
                    }, 600);
                }
            },

            // query builder events
            onWillDoServiceCallConditions: function onWillDoServiceCallConditions(objSuccessCallback) {
                if (!this.get("_bShowConditionBuilder")) {
                    objSuccessCallback([], null, {
                        getResponseHeader: function getResponseHeader() {
                            return "0";
                        }
                    });
                    return true;
                }
            },

            onSimpleTagsAction: function onSimpleTagsAction(arrTags) {
                if (this.get("_bPostSimpleTagsOnly")) {
                    var objPostbody = {
                        type: "functionResponse",
                        data: {
                            requestId: this.get("_iRequestId"),
                            data: arrTags
                        }
                    };

                    this.set("_objReactViewPostData", objPostbody);

                    this.set("_bBusy", false);
                    if (!a24Core.isEmpty(this.get("_arrConvert"))) {
                        this.get("_arrConvert").removeAt(0);
                    }
                }
            },
            onPopupCloseAction: function onPopupCloseAction() {
                var objPostbody = {
                    type: "complianceRuleConditionFiltersData"
                };
                this.set("_objReactViewPostData", objPostbody);
                this.set("_bShowConditionBuilder", false);
            },
            onPopupDoneAction: function onPopupDoneAction(arrTags, sUrl, objQuery) {
                var objJsonQuery = JSON.parse(objQuery);
                var objPostbody = {
                    type: "complianceRuleConditionFiltersData",
                    data: {
                        requestId: this.get("_iRequestId"),
                        url: sUrl,
                        data: objJsonQuery.query,
                        tags: arrTags
                    }
                };

                this.set("_objReactViewPostData", objPostbody);

                this.set("_bShowConditionBuilder", false);
            },
            onForceResetTriggered: function onForceResetTriggered() {
                this.set("_bForceReset", false);
            }
        }
    });
});