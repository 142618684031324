define("a24-ember-candidate-profile/components/right-panel-agency-candidate-calendar", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/right-panel-agency-candidate-calendar", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _rightPanelAgencyCandidateCalendar, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _rightPanelAgencyCandidateCalendar.default,
        mData: null,
        _objData: null,
        _objBasicInfo: null,
        _bCalendarLoaded: false,
        _bActivitiesLoaded: false,
        _sCandidateId: "",
        _sUserId: "",
        _sAvatar: "",
        _bAvatarLoaded: false,
        _bUserLoaded: false,
        _bNumbersLoadedDone: false,
        _bNumbersLoaded: false,
        _bNumbersLibLoaded: false,
        _bShowExtraNumbers: false,
        _sCurrentStatus: "...",
        _objMainNumber: null,
        _arrNumbers: null,
        _bNumbersError: false,
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objUserAvatarService: Ember.inject.service("rest-agency-user-avatar"),
        _objMetaRestService: Ember.inject.service("rest-agency-candidate-meta"),
        _objContactNumbersService: Ember.inject.service("rest-agency-candidate-contact-numbers"),
        _objActivityRestService: Ember.inject.service("rest-agency-candidate-activity"),
        _objActivityTypeRestService: Ember.inject.service("rest-advanced-search-activity-type"),
        _objRestAgencyCandidateActivityEmailComm: Ember.inject.service("rest-agency-activity-email-communication-message"),
        _objRestAgencyCandidateActivityConversation: Ember.inject.service("rest-agency-activity-conversation"),
        _objRestAgencyCandidateActivityNotification: Ember.inject.service("rest-agency-activity-notification"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objBasicInfoService: Ember.inject.service("rest-agency-candidate-basic-info"),
        _objCandidateStatusService: Ember.inject.service("rest-agency-candidate-status"),
        _sActivityContext: null,
        _objCandidateStatusPromise: null,
        _objMetaGetPromise: null,
        _objNumbersGetPromise: null,
        init: function init() {
            this._super.apply(this, arguments);

            this.set("_objData", { "data_loading": true });

            this.set("_sActivityContext", this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_LOWER_HYPHEN").toLowerCase());
        },
        _onDataChange: Ember.observer("mData", "mData.sCandidateId", "mData.bCalendarLoaded", "mData.objBasicInfo", "mData.arrNumbers", function () {
            if (!a24Core.isEmpty(this.get("mData.sCandidateId"))) {
                this.set("_sCandidateId", this.get("mData.sCandidateId"));
            }
            if (!a24Core.isEmpty(this.get("mData.bCalendarLoaded"))) {
                this.set("_bCalendarLoaded", this.get("mData.bCalendarLoaded"));
            }
            if (!a24Core.isEmpty(this.get("mData.objBasicInfo"))) {
                this.set("_objBasicInfo", this.get("mData.objBasicInfo"));
            }
            if (!a24Core.isEmpty(this.get("mData.bNumbersLoaded")) && this.get("mData.bNumbersLoaded")) {
                if (a24Core.isEmpty(this.get("mData.bNumbersError"))) {
                    this.set("_bNumbersLoaded", true);
                    this.set("_arrNumbers", this.get("mData.arrNumbers"));
                } else {
                    this.set("_bNumbersLoaded", true);
                    this.set("_arrNumbers", []);
                    this.set("_bNumbersError", this.get("mData.bNumbersError"));
                }
            }
        }),
        _onDataLoaded: Ember.observer("_objBasicInfo", "_sUserId", "_bUserLoaded", "_sAvatar", "_bAvatarLoaded", "_sCurrentStatus", function () {
            if (!a24Core.isEmpty(this.get("_objBasicInfo")) && (!a24Core.isEmpty(this.get("_sUserId")) || this.get("_bUserLoaded")) && (!a24Core.isEmpty(this.get("_sAvatar")) || this.get("_bAvatarLoaded")) && !a24Core.isEmpty(this.get("_sCurrentStatus"))) {
                var objData = {
                    "ss": {
                        "user": {
                            "first_name": this.get("_objBasicInfo.first_name"),
                            "last_name": this.get("_objBasicInfo.last_name"),
                            "avatar": this.get("_sAvatar")
                        }
                    },
                    "ember_candidate": {
                        "current_status": {
                            "status": this.get("_sCurrentStatus")
                        }
                    }
                };

                this.set("_objData", objData);
            }
        }),
        _getRestAvatar: function _getRestAvatar(funcSuccess, funcFailure) {
            this.get("_objUserAvatarService").getAvatar(this, "_objAvatarGetPromise", this.get("_sUserId"), funcSuccess, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
            funcFailure));
        },
        _getAvatar: function _getAvatar() {
            var _this = this;

            var funcAvatarSuccess = function funcAvatarSuccess(objResponse) {
                if (!a24Core.isEmpty(objResponse) && !a24Core.isEmpty(objResponse.avatar_url)) {
                    _this.set("_sAvatar", objResponse.avatar_url);
                } else {
                    _this.set("_sAvatar", "");
                }
                _this.set("_bAvatarLoaded", true);
            };

            var funcFailure = function funcFailure() /*sCode*/{
                _this.set("_sAvatar", "");
                _this.set("_bAvatarLoaded", true);
            };

            this._getRestAvatar(funcAvatarSuccess, funcFailure);
        },

        _setNumbers: Ember.observer("_arrNumbers", "_bNumbersLibLoaded", function () {
            if (this.get("_bNumbersError")) {
                this.set("_objMainNumber", {
                    sNumber: "--- --- ---"
                });

                this.set("_bNumbersLoadedDone", true);
            } else if (this.get("_bNumbersLibLoaded") && this.get("_bNumbersLoaded")) {
                var arrNumbers = this.get("_arrNumbers");

                var objMobileNumber = null;
                var objHomeNumber = null;
                var objWorkNumber = null;
                var objAltNumber = null;
                var arrFormattedNumbers = [];
                for (var i = 0; i < arrNumbers.length; i++) {
                    var sFormattedNumber = this.get("objChildren.number").formatNumberForDisplay(arrNumbers[i].number);
                    var sLabel = a24Core.getSafeStringLink(Ember, sFormattedNumber, arrNumbers[i].number, "phone");

                    var objItem = {
                        sNumber: sLabel,
                        sIcon: null,
                        sIconFa: null,
                        sIconSvg: null
                    };

                    var objCallTypeEnum = this.get("_objCandidateProfileEnumsService").getFrontendValue("callTypes", arrNumbers[i].type, null, true);
                    if (!a24Core.isEmpty(objCallTypeEnum)) {
                        objItem.sIcon = objCallTypeEnum.icon;
                        objItem.sIconFa = objCallTypeEnum.icon_fa;
                        objItem.sIconSvg = objCallTypeEnum.icon_svg;

                        //override with each loop since we want the last value of each
                        if (arrNumbers[i].type === this.get("_objCandidateProfileEnumsService.callTypes.MOBILE.backend")) {
                            objMobileNumber = objItem;
                        } else if (arrNumbers[i].type === this.get("_objCandidateProfileEnumsService.callTypes.HOME.backend")) {
                            objHomeNumber = objItem;
                        } else if (arrNumbers[i].type === this.get("_objCandidateProfileEnumsService.callTypes.WORK.backend")) {
                            objWorkNumber = objItem;
                        } else if (arrNumbers[i].type === this.get("_objCandidateProfileEnumsService.callTypes.ADDITIONAL.backend")) {
                            objAltNumber = objItem;
                        }

                        arrFormattedNumbers.pushObject(objItem);
                    }
                }
                if (!a24Core.isEmpty(objMobileNumber)) {
                    this.set("_objMainNumber", objMobileNumber);
                } else if (!a24Core.isEmpty(objHomeNumber)) {
                    this.set("_objMainNumber", objHomeNumber);
                } else if (!a24Core.isEmpty(objWorkNumber)) {
                    this.set("_objMainNumber", objWorkNumber);
                } else if (!a24Core.isEmpty(objAltNumber)) {
                    this.set("_objMainNumber", objAltNumber);
                } else {
                    this.set("_objMainNumber", null);
                }

                if (!a24Core.isEmpty(this.get("_objMainNumber"))) {
                    arrFormattedNumbers.removeObject(this.get("_objMainNumber"));
                }

                this.set("_arrFormattedNumbers", arrFormattedNumbers);
                this.set("_bNumbersLoadedDone", true);
            }
        }),

        actions: {
            openExtraNumberDropdown: function openExtraNumberDropdown() {
                this.set("_bShowExtraNumbers", true);
            },
            closeExtraNumberDropdown: function closeExtraNumberDropdown() {
                this.set("_bShowExtraNumbers", false);
            },
            onInputFormTelNumberInitDone: function onInputFormTelNumberInitDone() {
                this.set("_bNumbersLibLoaded", true);
            },
            onActivitiesLoaded: function onActivitiesLoaded() {
                var _this2 = this;

                if (!this.get("_bActivitiesLoaded")) {
                    this.set("_sCurrentStatus", "...");
                    var objPromiseCandidateStatusSummary = this.get("_objCandidateStatusService").getCandidateStatusSummary(this, "_objCandidateStatusPromise", this.get("_sCandidateId"), function () {}, // handled in rsvp
                    a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode*/{
                        _this2.set("_sCurrentStatus", "---");
                    }));

                    var objPromiseCandidateMeta = this.get("_objMetaRestService").getCandidateMeta(this, "_objMetaGetPromise", this.get("_sCandidateId"), function () {}, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
                    function () /*sCode*/{
                        _this2.set("_sUserId", "");
                        _this2.set("_sAvatar", "");
                        _this2.set("_bUserLoaded", true);
                        _this2.set("_bAvatarLoaded", true);
                    }));

                    var objServiceCalls = {
                        _objRestCandidateStatusSummaryProperty: objPromiseCandidateStatusSummary,
                        _objRestCandidateMetaProperty: objPromiseCandidateMeta
                    };

                    var arrRequiredResponses = ["_objRestCandidateStatusSummaryProperty", "_objRestCandidateMetaProperty"];

                    Ember.copy(a24RSVP).configure(this, objServiceCalls, [{
                        arrRequiredResponses: arrRequiredResponses,
                        funcOnComplete: function funcOnComplete(objResponses) {
                            if (!a24Core.isEmpty(objResponses._objRestCandidateStatusSummaryProperty) && !a24Core.isEmpty(objResponses._objRestCandidateStatusSummaryProperty.objHeaders) && objResponses._objRestCandidateStatusSummaryProperty.objHeaders.statusText === "abort" || !a24Core.isEmpty(objResponses._objRestCandidateMetaProperty) && !a24Core.isEmpty(objResponses._objRestCandidateMetaProperty.objHeaders) && objResponses._objRestCandidateMetaProperty.objHeaders.statusText === "abort") {
                                return;
                            }

                            if (!a24Core.isEmpty(objResponses._objRestCandidateStatusSummaryProperty) && !a24Core.isEmpty(objResponses._objRestCandidateStatusSummaryProperty.objResponse)) {
                                var objResponse = objResponses._objRestCandidateStatusSummaryProperty.objResponse;
                                if (!a24Core.isEmpty(objResponse) && !a24Core.isEmpty(objResponse.current_status)) {
                                    _this2.set("_sCurrentStatus", objResponse.current_status.status);
                                } else {
                                    _this2.set("_sCurrentStatus", "---");
                                }
                            }

                            if (!a24Core.isEmpty(objResponses._objRestCandidateMetaProperty) && !a24Core.isEmpty(objResponses._objRestCandidateMetaProperty.objResponse)) {
                                var _objResponse = objResponses._objRestCandidateMetaProperty.objResponse;
                                if (!a24Core.isEmpty(_objResponse) && !a24Core.isEmpty(_objResponse.user_id)) {
                                    _this2.set("_sUserId", _objResponse.user_id);
                                    _this2._getAvatar();
                                } else {
                                    _this2.set("_sUserId", "");
                                    _this2.set("_sAvatar", "");
                                    _this2.set("_bUserLoaded", true);
                                    _this2.set("_bAvatarLoaded", true);
                                }
                            }
                        }
                    }]);
                }
                this.set("_bActivitiesLoaded", true);
            }
        }
    });
});