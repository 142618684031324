define("a24-ember-menu/components/sticky-header-component", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-menu/templates/components/sticky-header-component"], function (exports, _didRenderChangesMixin, _stickyHeaderComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _stickyHeaderComponent.default,
        classNames: ["sticky-header-component"],
        sStickyHeaderScrollElementSelector: null,
        iTopOffset: 0,

        _objStickyHeaderService: Ember.inject.service("sticky-header-service"),
        _bShowStickyHeader: false,
        _funcOnScroll: null,

        didInsertElement: function didInsertElement() {
            var _this = this;

            this._super.apply(this, arguments);
            var funcOnScroll = function funcOnScroll() {
                Ember.run(function () {
                    Ember.run.once(_this, _this._funcOnScrollOnce);
                });
            };

            this.set("_funcOnScroll", funcOnScroll);
            if (!a24Core.isEmpty(this.get("sStickyHeaderScrollElementSelector"))) {
                var objScrollElement = Ember.$(this.get("sStickyHeaderScrollElementSelector"));
                if (!a24Core.isEmpty(objScrollElement)) {
                    objScrollElement.on("scroll wheel DOMMouseScroll mousewheel touchmove", funcOnScroll);
                }
            }
        },

        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);
            this.set("_bShowStickyHeader", false);
            if (!a24Core.isEmpty(this.get("sStickyHeaderScrollElementSelector"))) {
                var objScrollElement = Ember.$(this.get("sStickyHeaderScrollElementSelector"));
                if (!a24Core.isEmpty(objScrollElement)) {
                    objScrollElement.off("scroll wheel DOMMouseScroll mousewheel touchmove", this.get("_funcOnScroll"));
                }
            }
        },

        _funcOnScrollOnce: function _funcOnScrollOnce() {
            var _this2 = this;

            if (a24Core.isEmpty(this.get("sStickyHeaderScrollElementSelector"))) {
                this.set("_bShowStickyHeader", false);
                return;
            }

            var sStickyHeaderItemSelector = this.get("sStickyHeaderScrollElementSelector") + " .sticky-header-item";
            var objStickyHeaderItems = Ember.$(sStickyHeaderItemSelector);

            if (!a24Core.isEmpty(objStickyHeaderItems)) {
                var objContainer = Ember.$(this.get("sStickyHeaderScrollElementSelector"));
                if (a24Core.isEmpty(objContainer)) {
                    this.set("_bShowStickyHeader", false);
                    return;
                }

                if (objContainer.offset().top > objStickyHeaderItems.eq(0).offset().top) {
                    this.set("_bShowStickyHeader", true);

                    Ember.run.schedule("afterRender", function () {
                        var objStickyHeader = _this2.$(".sticky-header-component-float");
                        Ember.$(sStickyHeaderItemSelector).each(function (iIndex, objItem) {
                            var iOffset = objStickyHeader.offset().top + objStickyHeader.height();

                            if (Ember.$(objItem).offset().top < iOffset) {
                                //In future we can update this to instead of pulling the text from the sticky item manually
                                //we trigger an event on that header e.g EmberJquery(objItem).trigger("sticky-item-show")
                                //and then the component that has that item can handle it and set things onto the sticky
                                //service that can then show on the sticky header. Such a thing would allow us to easily
                                //put round icon header component in the sticky header hbs and then we can put actions etc
                                //onto the sticky header

                                Ember.run.once(_this2, _this2._setHeader, Ember.$(objItem).text().trim());
                            }
                        });
                    });
                } else {
                    this.set("_bShowStickyHeader", false);
                }
            } else {
                this.set("_bShowStickyHeader", false);
            }
        },

        _setHeader: function _setHeader(sValue) {
            this.set("_objStickyHeaderService.sHeader", sValue);
        },

        _setPositionStickyHeader: function _setPositionStickyHeader() {
            this._funcOnScrollOnce();

            var objStickyHeaderFloat = this.$(".sticky-header-component-float");
            var objScrollElement = Ember.$(this.get("sStickyHeaderScrollElementSelector"));

            if (!a24Core.isEmpty(objStickyHeaderFloat) && !a24Core.isEmpty(objScrollElement)) {
                objStickyHeaderFloat.css("top", objScrollElement.offset().top + this.get("iTopOffset") + "px");
                objStickyHeaderFloat.width(this.$().outerWidth(true) + "px");
            }
        },

        _observeRenderCount: Ember.observer("_objDidRenderService._iCountRender", function () {
            Ember.run.once(this, this._setPositionStickyHeader);
        })
    });
});