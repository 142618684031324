define("a24-ember-staffshift-users/components/agency-group-view-add-user-card", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/mixins/input-form-element", "a24-ember-staffshift-users/templates/components/agency-group-view-add-user-card"], function (exports, _didRenderChangesMixin, _inputFormElement, _agencyGroupViewAddUserCard) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _agencyGroupViewAddUserCard.default,
        // Services
        _objNavigationService: Ember.inject.service("navigation"),
        _objACLGroupUsers: Ember.inject.service("rest-acl-group-users"),
        _objAgencyGroupUsers: Ember.inject.service("rest-agency-group-users"),
        _objUserSession: Ember.inject.service("user-session"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objStaffshiftUsersStringsService: Ember.inject.service("a24-strings-staffshift-users"),
        _objStringsService: Ember.inject.service("a24-strings"),

        // Promises
        _objGetUserSuggestions: null,
        _objGetLinkedUser: null,
        _objRemoveUser: null,
        _objAddUser: null,

        // Stored Lists
        _arrCustomActions: null,
        _arrFilterConfig: null,

        // Default properties
        _bShowAddUserPopup: false,
        _bShowConfirmDelete: false,
        _bAddUserPopupLoading: false,
        arrUsers: null,
        funcGetSuggestions: null,

        _objSpecialAddEditPopupAction: null,

        // Init function
        init: function init() {
            var _this = this;

            // Call super
            this._super.apply(this, arguments);

            this.set("_objSpecialAddEditPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onPopupAddUserClose");
                }
            });

            // Set the filter config for the card
            Ember.set(this, "_arrFilterConfig", [{
                sTitle: this.get("_objStringsService").getString("firstName"),
                sProperty: "sFirstName",
                sFilterType: "text",
                bText: true
            }, {
                sTitle: this.get("_objStringsService").getString("lastName"),
                sProperty: "sLastName",
                sFilterType: "text",
                bText: true
            }, {
                sTitle: this.get("_objStringsService").getString("username"),
                sProperty: "sUsername",
                sFilterType: "text",
                bText: true
            }]);

            // Set the custom actions
            Ember.set(this, "_arrCustomActions", [{
                sTooltipText: this.get("_objStringsService").getString("add"),
                sIcon: "add"
            }]);

            // Set the validation on the form
            Ember.set(this, "objChildConfig", {
                "objChildren.user": function objChildrenUser(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                }
            });

            // Set the suggestions function for the popup
            this.setGetSuggestionFunction();

            // Do the service calls
            this.doServiceCalls();
        },
        setGetSuggestionFunction: function setGetSuggestionFunction() {
            var _this2 = this;

            // Agency Group Id
            var sAgencyGroup = Ember.get(this, "_objUserSession").getUserContextReference().mId;

            Ember.set(this, "funcGetSuggestions", function (sQueryString, funcOnComplete) {
                // sQueryString - The query string search that was made
                // funcOnComplete - The function to call on completion

                // Make a service call to retrieve the data for the items
                Ember.get(_this2, "_objAgencyGroupUsers").getAvailableUserByQuery(_this2, "_objGetUserSuggestions", sAgencyGroup, sQueryString, // Query string passed to function
                function (arrData) {
                    // When service call succeeds

                    // If the data is empty, ensure it's an empty array
                    if (a24Core.isEmpty(arrData)) {
                        arrData = [];
                    }

                    // Define the array that will contain formatted data
                    var arrFormatted = [];

                    // Loop over the results
                    for (var i = 0; i < arrData.length; i++) {

                        // Get the item at index i
                        var objDataItem = arrData[i];

                        // Build the label to show
                        var sLabel = objDataItem.sFirstName + " " + objDataItem.sLastName + " - " + objDataItem.sUsername;

                        // Push label with id as an object to the search box
                        arrFormatted.push({
                            sLabel: sLabel,
                            id: objDataItem.id
                        });
                    }

                    // Call the callback with the formatted data
                    funcOnComplete(arrFormatted);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    // Call the callback with empty
                    funcOnComplete([]);
                }));
            });
        },

        // Make the service calls required for the card to display correctlty
        // Optional objQueryParams to query the get on
        doServiceCalls: function doServiceCalls(objQueryParams) {
            var _this3 = this;

            // If the query is empty
            if (a24Core.isEmpty(objQueryParams)) {
                objQueryParams = {};
            }

            // Set the loading to true
            Ember.set(this, "bLoading", true);

            // Get the route params
            var objRouteParams = Ember.get(this, "_objNavigationService.objRouteParams");
            // Get the ACL group id
            var sAclGroupId = objRouteParams["agency-group.acl-group-view"].group_type_id;

            // Get the list of users linked to the acl group
            Ember.get(this, "_objACLGroupUsers").getUserList(this, "_objGetLinkedUser", sAclGroupId, function (arrData) {
                // Prep the data then take the form out of loading
                _this3.prepareForDisplay(arrData);
                Ember.set(_this3, "bLoading", false);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                // Prep empty data then take the form out of loading
                _this3.prepareForDisplay([]);
                Ember.set(_this3, "bLoading", false);
            }), objQueryParams);
        },

        // Prepare user list for display
        prepareForDisplay: function prepareForDisplay(arrData) {

            // Reset the data if it is empty
            if (a24Core.isEmpty(arrData)) {
                arrData = [];
            }

            // Store a this instance
            var objThis = this;

            // Function used to generate a custom function per item
            var funcCardActionRemoveExecute = function funcCardActionRemoveExecute(sId) {
                // Return the function to remove the user with id = sId
                return function () {
                    // Set the current user id as the id
                    Ember.set(objThis, "_sUserToRemove", sId);
                    // Show the confirm delete
                    Ember.set(objThis, "_bShowConfirmDelete", true);
                };
            };

            // Define the array of users
            var arrDisplayUsers = [];

            // Loop over the data that is present
            for (var i = 0; i < arrData.length; i++) {

                // Concat the first and last name
                var sFullName = arrData[i].sFirstName + " " + arrData[i].sLastName;

                // Get the user id
                var sUserId = arrData[i].id;

                // Push the item in to the
                arrDisplayUsers.push({
                    sLabel: this.get("_objStringsService").getHTMLTokenString("a24-string-html-dot-value", sFullName, arrData[i].sUsername),
                    sIcon: "person",
                    arrActions: [{
                        sTitle: this.get("_objStringsService").getString("remove"),
                        sIcon: "delete",
                        executeCardAction: funcCardActionRemoveExecute(sUserId)
                    }]
                });
            }

            // Set the array of users
            Ember.set(this, "arrUsers", arrDisplayUsers);
        },

        // Remove the user
        removeUser: function removeUser(sUserId) {
            var _this4 = this;

            // Get the route params
            var objRouteParams = Ember.get(this, "_objNavigationService.objRouteParams");
            // Get the ACL group id
            var sAclGroupId = objRouteParams["agency-group.acl-group-view"].group_type_id;

            // Set the card in to loading
            Ember.set(this, "bLoading", true);

            // Do the remove service call
            Ember.get(this, "_objACLGroupUsers").removeUser(this, "_objRemoveUser", sAclGroupId, sUserId, function () {
                // Hide the confirm delete popup
                Ember.set(_this4, "_bShowConfirmDelete", false);

                Ember.get(_this4, "_objSnackbarService").showSnackbarMessage(_this4.get("_objStaffshiftUsersStringsService").getString("userRemovedSuccess"));

                // GET user list again
                _this4.doServiceCalls();
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                Ember.set(_this4, "_bShowConfirmDelete", false);

                Ember.get(_this4, "_objSnackbarService").showSnackbarMessage(_this4.get("_objStaffshiftUsersStringsService").getString("userRemovedFailure"));
                // GET user list again
                _this4.doServiceCalls();
            }));
        },
        // Remove the user
        addUser: function addUser(sUserId) {
            var _this5 = this;

            // Get the route params
            var objRouteParams = Ember.get(this, "_objNavigationService.objRouteParams");
            // Get the ACL group id
            var sAclGroupId = objRouteParams["agency-group.acl-group-view"].group_type_id;

            // Set the card in to loading
            Ember.set(this, "bLoading", true);
            Ember.set(this, "_bAddUserPopupLoading", true);

            // Do the add service call
            Ember.get(this, "_objACLGroupUsers").addUser(this, "_objAddUser", sAclGroupId, sUserId, function () {

                // Hide the add user popup
                Ember.set(_this5, "_bShowAddUserPopup", false);
                Ember.set(_this5, "_bAddUserPopupLoading", false);

                Ember.get(_this5, "_objSnackbarService").showSnackbarMessage(_this5.get("_objStaffshiftUsersStringsService").getString("userAddSuccess"));

                // GET user list again
                _this5.doServiceCalls();
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                // Hide the add user popup
                Ember.set(_this5, "_bShowAddUserPopup", false);
                Ember.set(_this5, "_bAddUserPopupLoading", false);

                Ember.get(_this5, "_objSnackbarService").showSnackbarMessage(_this5.get("_objStaffshiftUsersStringsService").getString("userAddFailure"));

                // GET user list again
                _this5.doServiceCalls();
            }));
        },

        // ACTIONS
        actions: {
            // If the filter changes, we wish to reload the card
            cardDatagridFilterChangeEvent: function cardDatagridFilterChangeEvent(sProperty, objValue) {
                this.doServiceCalls(objValue);
            },

            // If the custom action is clicked
            customActionClicked: function customActionClicked() {
                this.clearForm();
                this.clearAllErrorMessages();
                Ember.set(this, "_bShowAddUserPopup", true);
            },

            // Once confirmation is given on removing a user
            onRemoveUserPopupConfirm: function onRemoveUserPopupConfirm() {
                // Remove the user with the stored id
                this.removeUser(Ember.get(this, "_sUserToRemove"));
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.clearForm();
                this.clearAllErrorMessages();
                Ember.set(this, "_bShowAddUserPopup", false);
            },
            onPopupAddUserClose: function onPopupAddUserClose() {
                if (!Ember.get(this, "_bAddUserPopupLoading")) {
                    var bHasUnsavedData = !a24Core.isEmpty(this.getPlainFormValueObject()) && !a24Core.isEmpty(this.getPlainFormValueObject().user) && !a24Core.isEmpty(this.getPlainFormValueObject().user.id);

                    if (bHasUnsavedData) {
                        Ember.set(this, "_bShowUnsavedData", true);
                    } else {
                        this.clearForm();
                        this.clearAllErrorMessages();
                        Ember.set(this, "_bShowAddUserPopup", false);
                    }
                }
            },
            onPopupAddUserConfirm: function onPopupAddUserConfirm() {

                // Do the validation on the form
                if (!this.doFullValidation()) {
                    // If the validation passed

                    // Get the user id of the user that is being added
                    var sUserId = this.getPlainFormValueObject().user.id;

                    // Add the user with the correct id
                    this.addUser(sUserId);
                }
            }
        }
    });
});