define("a24-ember-staffshift-core/components/react-main-content-component", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/react-main-content-component"], function (exports, _didRenderChangesMixin, _reactMainContentComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _reactMainContentComponent.default,

        sRoute: null,
        objReactTriggerRoute: null,

        _sIFrameUrl: "",
        _sRouteIds: "",

        _bRouterReady: false,

        _objReactIframeData: null,

        _objTitleBarService: Ember.inject.service("title-bar"),
        _objTriageMenu: Ember.inject.service("triage-menu"),
        _objNavigationWithWillDestroyService: Ember.inject.service("navigation"),

        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            Ember.run.schedule("afterRender", function () {
                $(".main-container").addClass("tab-react-iframe");
            });

            var objEnv = Ember.getOwner(this).resolveRegistration("config:environment");

            if (a24Core.isEmpty(this.get("_sIFrameUrl"))) {
                this.set("_sIFrameUrl", objEnv.sReactGatewayUrl);
            }

            this.get("_objTitleBarService").setTitleBar();

            Ember.run.schedule("afterRender", function () {
                _this.set("_objTriageMenu.sTopBarComponent", null);
                Ember.$(".a24-bread-crumb-bar-component").hide();
            });

            var objRouteParams = this.get("_objNavigationWithWillDestroyService.objRouteParams");
            if (!a24Core.isEmpty(objRouteParams) && !a24Core.isEmpty(objRouteParams[this.get("sRoute")])) {
                var objRouteParam = objRouteParams[this.get("sRoute")];
                var sRouteIds = Object.values(objRouteParam).join(",");

                this.set("_sRouteIds", sRouteIds);

                var objTriggerRouteData = this.get("objReactTriggerRoute.data.data") || {};
                var objTriggerRoute = this.get("objReactTriggerRoute");

                Object.assign(objTriggerRouteData, Ember.copy(objRouteParam, true));
                objTriggerRoute.data.data = objTriggerRouteData;
                this.set("_objReactIframeData", Ember.copy(objTriggerRoute, true));
            }
        },

        getA24WillDestroyRoute: function getA24WillDestroyRoute() {
            return this.get("sRoute");
        },

        _onRouteParamChange: Ember.observer("_objNavigationWithWillDestroyService.objRouteParams", function () {
            if (!this.get("_bRouterReady")) {
                return;
            }

            var objRouteParams = this.get("_objNavigationWithWillDestroyService.objRouteParams");
            if (!a24Core.isEmpty(objRouteParams)) {
                var objRouteParam = objRouteParams[this.get("sRoute")];
                if (!a24Core.isEmpty(objRouteParam)) {
                    var sRouteIds = Object.values(objRouteParam).join(",");

                    if (sRouteIds === this.get("_sRouteIds")) {
                        var objTriggerData = {
                            type: "tab_route_info",
                            data: Ember.copy(objRouteParams, true)
                        };

                        this.set("_objReactIframeData", objTriggerData);
                    }
                }
            }
        }),

        _onNewRouteChange: Ember.observer("_objNavigationWithWillDestroyService.objNewRouteParams", function () {
            if (!this.get("_bRouterReady")) {
                return;
            }

            var objNewRouteParams = this.get("_objNavigationWithWillDestroyService.objNewRouteParams");

            if (!a24Core.isEmpty(objNewRouteParams)) {
                if (!a24Core.isEmpty(objNewRouteParams) && !a24Core.isEmpty(objNewRouteParams[this.get("sRoute")])) {
                    var objRouteParam = objNewRouteParams[this.get("sRoute")];
                    var sRouteIds = Object.values(objRouteParam).join(",");

                    if (sRouteIds === this.get("_sRouteIds")) {
                        var objTriggerData = {
                            type: "tab_route_new_info",
                            data: Ember.copy(objNewRouteParams, true)
                        };

                        this.set("_objReactIframeData", objTriggerData);
                    }
                }
            }
        }),
        _onCurrentRouteChange: Ember.observer("_objNavigationWithWillDestroyService.objCurrentRouteParams", function () {
            if (!this.get("_bRouterReady")) {
                return;
            }

            var objCurrentRouteParams = this.get("_objNavigationWithWillDestroyService.objCurrentRouteParams");

            if (!a24Core.isEmpty(objCurrentRouteParams)) {
                if (!a24Core.isEmpty(objCurrentRouteParams) && !a24Core.isEmpty(objCurrentRouteParams[this.get("sRoute")])) {
                    var objRouteParam = objCurrentRouteParams[this.get("sRoute")];
                    var sRouteIds = Object.values(objRouteParam).join(",");

                    if (sRouteIds === this.get("_sRouteIds")) {
                        var objTriggerData = {
                            type: "tab_route_current_info",
                            data: Ember.copy(objCurrentRouteParams, true)
                        };

                        this.set("_objReactIframeData", objTriggerData);
                    } else {
                        this.set("_sRouteIds", sRouteIds);

                        var objTriggerRouteData = this.get("objReactTriggerRoute.data.data") || {};
                        var objTriggerRoute = this.get("objReactTriggerRoute");

                        Object.assign(objTriggerRouteData, Ember.copy(objRouteParam, true));
                        objTriggerRoute.data.data = objTriggerRouteData;

                        this.set("_objReactIframeData", Ember.copy(objTriggerRoute, true));
                    }
                }
            }
        }),

        a24WillDestroy: function a24WillDestroy() {
            if (a24Core.isEmpty(Ember.$(".a24-bread-crumb-bar-component"))) {
                this.set("_objTriageMenu.sTopBarComponent", "title-bar");
            }
            $(".main-container").removeClass("tab-react-iframe");
        },

        actions: {
            onRecieveData: function onRecieveData(objData) {
                var _this2 = this;

                if (objData.type === "app_ready") {
                    this.set("_bRouterReady", true);
                } else if (objData.type === "component_ready") {
                    var objCurrentRouteParams = this.get("_objNavigationWithWillDestroyService.objCurrentRouteParams");

                    var objRouteParam = objCurrentRouteParams[this.get("sRoute")];
                    var sRouteIds = Object.values(objRouteParam).join(",");

                    if (sRouteIds === this.get("_sRouteIds")) {
                        var objTriggerData = {
                            type: "tab_route_info",
                            data: Ember.copy(objCurrentRouteParams, true)
                        };

                        this.set("_objReactIframeData", objTriggerData);
                        Ember.run.next(function () {
                            if (a24Core.isEmpty(_this2)) {
                                return;
                            }
                            objTriggerData = {
                                type: "tab_route_new_info",
                                data: Ember.copy(objCurrentRouteParams, true)
                            };

                            _this2.set("_objReactIframeData", objTriggerData);
                            Ember.run.next(function () {
                                if (a24Core.isEmpty(_this2)) {
                                    return;
                                }
                                objTriggerData = {
                                    type: "tab_route_current_info",
                                    data: Ember.copy(objCurrentRouteParams, true)
                                };

                                _this2.set("_objReactIframeData", objTriggerData);
                            });
                        });
                    }
                }

                if (!a24Core.isEmpty(this.get("onRecieveData"))) {
                    this.get("onRecieveData")(objData);
                }
            }
        }
    });
});