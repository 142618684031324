define("a24-ember-staffshift-core/components/logout-component", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/logout-component"], function (exports, _didRenderChangesMixin, _logoutComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _logoutComponent.default,
        _objNavigation: Ember.inject.service("navigation"),
        _objIntercom: Ember.inject.service("intercom"),
        _objLogoutService: Ember.inject.service("rest-logout"),
        _objLogoutServiceKey: null,
        actions: {
            doLogout: function doLogout() {
                var _this = this;

                this.get("_objLogoutService").doLogout(this, "_objLogoutServiceKey", function (objResponse) {
                    _this.get("_objIntercom").shutdown();
                    if (objResponse.success === true) {
                        _this.get("_objNavigation").navigateToGWTLogin(false);
                    } else {
                        _this.get("_objNavigation").navigateOutsideContext("404");
                    }
                });
            }
        }
    });
});