define("a24-ember-policy-library/services/rest-agency-group-candidate-policy-group", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        sContext: null,
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),

        _sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        init: function init() {
            this._super.apply(this, arguments);

            this.set("sContext", this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP"));
        },

        /**
         * This service method will be used to retrieve all policy
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objQueryParams - Any extra query params for the get list
         *
         * @author Ahmed Onawale<ahmedonawale@gmail.com>
         * @since  28 May 2018
         */
        getPolicyGroupDetails: function getPolicyGroupDetails(objCaller, sProperty, sCandidateId, funcSuccess, objFailure, objQueryParams) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            var sUrl = this.get("_sRestApiBaseUrl") + "/v1/agency-group/" + sAgencyGroupId + "/" + "candidate" + "/" + sCandidateId + "/policy";

            a24RestUrlConstruct.setBaseUrlSwagger(sUrl);
            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});