define("a24-ember-staffshift-candidate-availability/components/day-availability", ["exports", "a24-ember-staffshift-candidate-availability/templates/components/day-availability", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _dayAvailability, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        /*
          Default values
        */
        layout: _dayAvailability.default,
        classNames: ["day-availability"],
        classNameBindings: ["objDay.bIsToday:today", "objDay.bIsFuture:future", "objDay.bIsNextMonthDay:next", "objDay.bIsPast:past", "objDay.bIsPrevMonthDay:previous", "bIsDesktop:desktop", "bIsMobileOnly:mobile", "bClickable:cursor-clickable"],

        /*
          @property objDay
          The day object this component represents.
        */
        objDay: null,

        /*
          Inline computed properties
        */
        bNotDesktop: Ember.computed.not("bIsDesktop"),
        bIsDesktop: Ember.computed.or("media.isDesktop", "media.isJumbo"),
        bIsMobile: Ember.computed.or("media.isMobile", "media.isTiny", "media.isTablet"),
        bIsMobileOnly: Ember.computed.and("bNotDesktop", "bIsMobile"),

        bEdit: Ember.computed("objDay", "objDay.{bEdit,bIsPast,bIsPrevMonthDay,bIsNextMonthDay}", "bIsMobileOnly", function () {

            return Ember.get(this, "objDay.bEdit") && !Ember.get(this, "bIsMobileOnly") && !Ember.get(this, "objDay.bIsPast") && !Ember.get(this, "objDay.bIsPrevMonthDay") && !Ember.get(this, "objDay.bIsNextMonthDay");
        }),

        bClickable: Ember.computed.not("objDay.bEdit"),

        click: function click() {
            if (Ember.get(this, "bClickable")) {
                if (!a24Core.isEmpty(this.get("onDayAction"))) {
                    this.get("onDayAction")("onDaySelect", Ember.get(this, "objDay"));
                }
            }
        },
        actions: {
            selectAvailability: function selectAvailability(sPeriod, objAvailability, objEvent) {
                if (!a24Core.isEmpty(this.get("onDayAction"))) {
                    this.get("onDayAction")("selectAvailability", objAvailability, Ember.get(this, "objDay"), sPeriod, objEvent);
                }
            }
        }
    });
});