define("a24-ember-candidate-profile/components/candidate-external-days-to-expiry", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-external-days-to-expiry"], function (exports, _didRenderChangesMixin, _candidateExternalDaysToExpiry) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _candidateExternalDaysToExpiry.default,
        bLoading: true,
        arrExternalDaysToExpiryData: null,
        _arrExternalDaysToExpiryDataView: null,
        _objStringsService: Ember.inject.service("a24-strings"),
        _objUserSession: Ember.inject.service("user-session"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        init: function init() {
            this._super.apply(this, arguments);
            this.prepareExternalDataForDisplay();
        },
        prepareExternalDataForDisplay: Ember.observer("arrExternalDaysToExpiryData", function () {
            var arrExternalDaysToExpiryData = this.get("arrExternalDaysToExpiryData");
            if (!a24Core.isEmpty(arrExternalDaysToExpiryData)) {
                arrExternalDaysToExpiryData.sort(function (objA, objB) {
                    if (objA.name < objB.name) {
                        return -1;
                    } else if (objA.name > objB.name) {
                        return 1;
                    }
                    return 0;
                });
                this.set("_arrExternalDaysToExpiryDataView", this._prepareForDisplay(arrExternalDaysToExpiryData));
            }
        }),
        _prepareForDisplay: function _prepareForDisplay(arrExternalDaysToExpiry) {
            var _this = this;

            return arrExternalDaysToExpiry.map(function (objItem) {
                var objDate = a24DateManager.createDate(_this.get("_objUserSession.objContextDetails.sTimezone"));
                var sDate = null;

                if (!a24Core.isEmpty(objItem.value)) {
                    var iDays = momentHelper.stringDiffInUnits(objItem.value, _this.get("_objLibConstants.DATE_FORMAT_ISO_FORMAT"), objDate.getDateFormat(), _this.get("_objLibConstants.DATE_FORMAT"), _this.get("_objLibConstants.MOMENT_TIME_DAYS"));
                    sDate = iDays + " " + _this.get("_objCandidateProfileStringsService").getString("days").toLowerCase();
                } else {
                    sDate = _this.get("_objCandidateProfileStringsService").getString("never");
                }

                return {
                    sIcon: "insert_drive_file",
                    sLabel: objItem.name,
                    sExtra: _this.get("_objStringsService").getHTMLTokenString("a24-string-html-dot-value", _this.get("_objCandidateProfileStringsService").getString("expiresIn"), sDate)
                };
            });
        }
    });
});