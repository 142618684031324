define("a24-ember-window/services/a24-constants-window", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        // This is the only exception to the service not extending the base constant
        // because these constants is hard coded in the js and will be used every where.

        MEDIA_SIZE_TINY: "tiny",
        MEDIA_SIZE_MOBILE: "mobile",
        MEDIA_SIZE_TABLET: "tablet",
        MEDIA_SIZE_JUMBO: "jumbo",
        MEDIA_SIZE_DESKTOP: "desktop",

        // Widths for screen sizes
        SCREEN_SIZE_TINY_PX: 300,
        SCREEN_SIZE_MOBILE_PX: 768,
        SCREEN_SIZE_TABLET_PX: 992,
        SCREEN_SIZE_DESKTOP_PX: 1200,
        SCREEN_SIZE_JUMBO_PX: 1440,
        SCREEN_REM_BASE: 16

    });
});