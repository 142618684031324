define("a24-ember-staffshift-core/services/chat-boxes-service-base", ["exports", "a24-ember-window/mixins/a24responsive"], function (exports, _a24responsive) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend(_a24responsive.default, {
        media: Ember.inject.service("media"),
        _objNavigation: Ember.inject.service("navigation"),
        _objTriageMenu: Ember.inject.service("triage-menu"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objBreadCrumbService: Ember.inject.service("bread-crumb"),
        _objConstantsWindowService: Ember.inject.service("a24-constants-window"),

        _bHideChat: true,
        _objPostData: null,

        bShowNotificationIcon: false,
        iNotificationCount: 0,

        observeNotificationCount: Ember.observer("iNotificationCount", function () {
            this.set("bShowNotificationIcon", this.get("iNotificationCount") ? true : false);
        }),

        _isMobile: Ember.computed("sScreenSize", function () {
            var isMobile = this.get("sScreenSize") === this.get("_objConstantsWindowService").MEDIA_SIZE_TINY || this.get("sScreenSize") === this.get("_objConstantsWindowService").MEDIA_SIZE_MOBILE;
            return isMobile;
        }),

        setNotificationCount: function setNotificationCount(iCount) {
            this.set("iNotificationCount", iCount);
        },

        addChatBoxClasses: function addChatBoxClasses() {
            if (this.get("_isMobile")) {
                if (!$(".navigation-bar").hasClass("mobile-chat-box-nav-bar")) {
                    $(".navigation-bar").addClass("mobile-chat-box-nav-bar");
                }
                if (!$(".main-page").hasClass("mobile-chat-box-main-page")) {
                    $(".main-page").addClass("mobile-chat-box-main-page");
                }
                if (!$(".main-container").hasClass("mobile-chat-box-main-container")) {
                    $(".main-container").addClass("mobile-chat-box-main-container");
                }
                if (!$(".a24-chat-box-container").hasClass("mobile-a24-chat-box-container")) {
                    $(".a24-chat-box-container").addClass("mobile-a24-chat-box-container");
                }
                if (!$(".right-panel-container").hasClass("mobile-chat-box-right-panel")) {
                    $(".right-panel-container").addClass("mobile-chat-box-right-panel");
                }
            }
        },

        removeChatBoxClasses: function removeChatBoxClasses() {
            $(".navigation-bar").removeClass("mobile-chat-box-nav-bar");
            $(".main-page").removeClass("mobile-chat-box-main-page");
            $(".main-container").removeClass("mobile-chat-box-main-container");
            $(".a24-chat-box-container").removeClass("mobile-a24-chat-box-container");
            $(".right-panel-container").removeClass("mobile-chat-box-right-panel");
        },

        showChatBox: function showChatBox() {
            this.set("_bHideChat", false);
            this.addChatBoxClasses();
        },

        hideChatBox: function hideChatBox() {
            this.set("_bHideChat", true);
            this.removeChatBoxClasses();
        },

        toggleChatBox: function toggleChatBox() {
            var hideChat = !this.get("_bHideChat");
            this.set("_bHideChat", hideChat);
            if (this.get("_isMobile")) {
                if (!hideChat) {
                    this.addChatBoxClasses();
                } else {
                    this.removeChatBoxClasses();
                }
            }
        },

        postMessage: function postMessage(objData) {
            this.set("_objPostData", objData);
        },

        generateFriendlyPageName: function generateFriendlyPageName() {
            var sCurrentRoute = this.get("_objNavigation.sCurrentRouteName");
            if (a24Core.isEmpty(sCurrentRoute)) {
                //this will be on init load of app
                return;
            }
            var arrRouteParts = sCurrentRoute.split(".");

            if (arrRouteParts.length > 2) {
                //If 3 parts we assume it is a tab item at end so remove that
                arrRouteParts = arrRouteParts.slice(0, 2);
            }

            var sCurrentRouteName = arrRouteParts[arrRouteParts.length - 1];

            var sPluginId = null;
            if (arrRouteParts[arrRouteParts.length - 1] === "plugin" && !a24Core.isEmpty(this.get("_objNavigation.objRouteParams")[sCurrentRoute])) {
                sPluginId = this.get("_objNavigation.objRouteParams")[sCurrentRoute].plugin_id;
            }

            var sTopLabel = "";
            var sSubLabel = "";
            var sExtraLabel = "";

            var arrMenuItems = this.get("_objTriageMenu.arrMenuResponse");
            if (a24Core.isEmpty(arrMenuItems)) {
                return;
            }
            for (var i = 0; i < arrMenuItems.length; i++) {
                if (a24Core.isEmpty(sPluginId)) {
                    for (var j = 0; j < arrMenuItems[i].arrSubmenus.length; j++) {
                        if (arrMenuItems[i].arrSubmenus[j].sReferencedNamePlaces.indexOf(sCurrentRouteName) !== -1) {
                            if (arrMenuItems[i].arrSubmenus.length === 1) {
                                sTopLabel = arrMenuItems[i].arrSubmenus[j].sLabel;
                                if (arrMenuItems[i].arrSubmenus[j].sPlaceName !== sCurrentRouteName) {
                                    sSubLabel = sCurrentRouteName;
                                }
                            } else {
                                sTopLabel = arrMenuItems[i].sLabel;
                                sSubLabel = arrMenuItems[i].arrSubmenus[j].sLabel;
                                if (arrMenuItems[i].arrSubmenus[j].sPlaceName !== sCurrentRouteName) {
                                    sExtraLabel = sCurrentRouteName;
                                }
                            }
                        }
                    }
                } else {
                    if (arrMenuItems[i].sPluginId === sPluginId) {
                        sTopLabel = arrMenuItems[i].sLabel;
                    }
                }
            }

            var objRouteParams = this.get("_objNavigation.objRouteParams");
            var objRouteParamsFlat = {};
            for (var sProp in objRouteParams) {
                if (Object.prototype.hasOwnProperty.call(objRouteParams, sProp)) {
                    Object.assign(objRouteParamsFlat, objRouteParams[sProp]);
                }
            }
            delete objRouteParamsFlat.context_id;

            var arrBreadTitles = [];
            var sTitleBar = null;
            if (a24Core.isEmpty(this.get("_objTriageMenu.sTopBarComponent"))) {
                var arrBreads = this.get("_objBreadCrumbService.arrBreadCrumbItems");
                if (!a24Core.isEmpty(arrBreads)) {
                    for (var k = 0; k < arrBreads.length; k++) {
                        arrBreadTitles.push(arrBreads[k].sTitle);
                    }
                }
            } else {
                sTitleBar = this.get("_objTitleBarService.sTitle");
            }

            var sSelectedTab = null;
            var arrTabs = this.get("_objTitleBarService.arrTabs");
            if (!a24Core.isEmpty(arrTabs)) {
                for (var l = 0; l < arrTabs.length; l++) {
                    if (arrTabs[l].bActive) {
                        sSelectedTab = arrTabs[l].sText;
                        break;
                    }
                }
            }

            var arrFinalStrings = [];
            if (!a24Core.isEmpty(sTopLabel)) {
                arrFinalStrings.push(sTopLabel);
            }
            if (!a24Core.isEmpty(sSubLabel)) {
                arrFinalStrings.push(sSubLabel);
            }
            if (!a24Core.isEmpty(sExtraLabel)) {
                arrFinalStrings.push(sExtraLabel);
            }
            if (!a24Core.isEmpty(sTitleBar)) {
                arrFinalStrings[arrFinalStrings.length - 1] = sTitleBar;

                if (!a24Core.isEmpty(sSelectedTab)) {
                    arrFinalStrings.push(sSelectedTab);
                }
            }
            if (!a24Core.isEmpty(arrBreadTitles)) {
                if (arrBreadTitles.length > 1) {
                    if (arrBreadTitles[0] !== sTopLabel) {
                        arrBreadTitles[0] = sTopLabel;
                    }
                    arrFinalStrings = arrBreadTitles;
                } else {
                    if (arrBreadTitles[0] !== sTopLabel) {
                        arrBreadTitles.push(arrBreadTitles[0]);
                        arrBreadTitles[0] = sTopLabel;
                    }
                    arrFinalStrings = arrBreadTitles;
                }
            }

            return {
                sFinalPageName: arrFinalStrings.join(" / "),
                objRouteParams: objRouteParamsFlat
            };
        }

    });
});