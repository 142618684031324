define("a24-ember-lib/components/input-form-time-picker", ["exports", "a24-ember-lib/templates/components/input-form-time-picker", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _inputFormTimePicker, _inputFormElement, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _inputFormTimePicker.default,
        classNames: ["md-form", "a24ErrorInputParent"],
        sSelectedTime: "",
        sInputType: "time",
        _objStringsService: Ember.inject.service("a24-strings"),
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            Ember.run.scheduleOnce("afterRender", this, function () {
                var objTimeInput = $("#" + _this.get("_sInputId"));

                //set the initial value before adding the time picker to the field
                objTimeInput.pickatime({
                    twelvehour: false,
                    darktheme: false,
                    clear: _this.get("_objStringsService").getString("clear"),
                    donetext: _this.get("_objStringsService").getString("ok")
                    //min: [7,30], will ignore this for now
                    //max: [14,0], will ignore this for now
                });
                if (!a24Core.isEmpty(_this.get("sSelectedTime"))) {

                    _this.setMValueWithInternalFlag(_this.get("sSelectedTime"), true);

                    //Set the init time if we have one
                    objTimeInput.val(_this.get("sSelectedTime"));
                    objTimeInput.change();
                }
                objTimeInput.on("change", function () {
                    Ember.run(function () {
                        //Set mValue when the value changes and also reset floater if value is empty
                        _this.setMValueWithInternalFlag(objTimeInput.val(), false);

                        _this.resetFloatingLabel(objTimeInput);
                    });
                });
                objTimeInput.on("blur", function () {
                    Ember.run(function () {
                        _this.resetFloatingLabel(objTimeInput);
                    });
                });

                // This is added so we can add the esc event on the div
                _this.$().attr("tabindex", -1);
                // This is stopped as the picker has a default handling of focusin that closes the popover
                $(_this.$()).on("focusin", function (objEvent) {
                    Ember.run(function () {
                        objEvent.stopPropagation();
                    });
                });
                // This is to focus the popover so the key up events can be handled
                objTimeInput.on("focus", function () {
                    Ember.run(function () {
                        //First we need to hide the time picker that tried to show
                        //before focusing the input that will bring up the picker
                        $("#" + _this.get("_sInputId")).data("clockpicker").hide();
                        _this.$().focus();
                    });
                });

                var onEscHandler = function onEscHandler() {
                    // hides the clock picker when esc is clicked
                    $("#" + _this.get("_sInputId")).data("clockpicker").hide();
                };

                // This is jquery for the mdb side
                $(_this.$()).on("keyup", function (objEvent) {
                    Ember.run(function () {
                        if (objEvent.which === 27 && objEvent.keyCode === 27) {
                            // This is to stop the event from falling through
                            objEvent.stopPropagation();
                            Ember.run.once(_this, onEscHandler);
                        }
                    });
                });
                // This is the jquery for the ember side
                _this.$().on("keyup", function (objEvent) {
                    Ember.run(function () {
                        if (objEvent.which === 27 && objEvent.keyCode === 27) {
                            // This is to stop the event from falling through
                            objEvent.stopPropagation();
                            Ember.run.once(_this, onEscHandler);
                        }
                    });
                });

                objTimeInput.prop("readonly", true);

                _this.resetFloatingLabel(objTimeInput);
            });
        },
        resetFloatingLabel: function resetFloatingLabel(objTimeInput) {
            //If the input field has no value then the label should NOT have the active class and
            //float at placeholder position
            if (a24Core.isEmpty(objTimeInput.val())) {

                this.$("label").removeClass("active");
            }
        },
        clear: function clear() {
            this.setValueFromOutside(null);
        },
        setValueFromOutside: function setValueFromOutside(sValue) {
            var objTimeInput = $("#" + this.get("_sInputId"));

            if (a24Core.isEmpty(sValue)) {
                objTimeInput.val("");
            } else if (objTimeInput.val() !== sValue) {
                objTimeInput.val(sValue);
            }

            this.resetFloatingLabel(objTimeInput);

            this.setMValueWithInternalFlag(sValue, false);
        },
        setInputValue: Ember.observer("sSelectedTime", function () {
            this.setValueFromOutside(this.get("sSelectedTime"));
        })
    });
});