define("a24-ember-candidate-profile/components/candidate-additional-note", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-additional-note", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _candidateAdditionalNote, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _candidateAdditionalNote.default,
        objSettingsRestService: null,
        sCandidateId: null,
        objAdditionalNoteRestService: null,
        _bLoading: false,
        _bAllowEdit: true,
        _bShowUnsavedData: false,
        _bShowEditPopup: false,
        _bPopupLoading: false,
        _sPopupHeader: null,
        _objAdditionalNoteData: null,
        _objSpecialAddEditPopupAction: null,
        _arrHeaderActions: null,
        _objAdditionalNoteUpdatePromise: null,
        _objAdditionalNoteGetPromise: null,
        _objStringsService: Ember.inject.service("a24-strings"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _arrReloadActions: null,

        _bServerError: false,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.getCandidateAdditionalNote();
                }
            }]);

            if (!this.get("objSettingsRestService.objSettings.profile_editable")) {
                this.set("_bAllowEdit", false);
            }

            var sPopupHeader = this.get("_objStringsService").getTokenString("editItem", this.get("_objCandidateProfileStringsService").getString("profileNote"));

            this.set("_sPopupHeader", sPopupHeader);

            this.set("_objSpecialAddEditPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.popupClose();
                }
            });

            var objValidationConfig = {};

            objValidationConfig["objChildren.note"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 3);
                a24Validation.addRequired(objMixin, true);
            };

            this.set("objChildConfig", objValidationConfig);

            this.getCandidateAdditionalNote();
        },
        getCandidateAdditionalNote: function getCandidateAdditionalNote() {
            var _this2 = this;

            this.set("_bLoading", true);
            this.set("_bServerError", false);
            this.set("_arrHeaderActions", []);

            this.get("objAdditionalNoteRestService").getAdditionalNote(this, "_objAdditionalNoteGetPromise", this.get("sCandidateId"), function (objData) {
                if (!a24Core.isEmpty(objData) && !a24Core.isEmpty(objData.note)) {
                    objData.note = objData.note.replace(/\r\n|\r/g, "\n");
                }
                _this2.set("_objAdditionalNoteData", objData);

                _this2.set("_bLoading", false);

                if (_this2.get("_bAllowEdit")) {
                    _this2.set("_arrHeaderActions", [{
                        sTitle: _this2.get("_objStringsService").getString("edit"),
                        sIcon: "edit",
                        executeCardAction: function executeCardAction() {
                            _this2.openEditPopup();
                        }
                    }]);
                }
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
            function (sCode) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this2.set("_bServerError", true);
                    return;
                }

                _this2.set("_objAdditionalNoteData", null);
                _this2.set("_bLoading", false);
                if (sCode === "412") {
                    _this2.set("_arrHeaderActions", null);
                } else {
                    if (_this2.get("_bAllowEdit")) {
                        _this2.set("_arrHeaderActions", [{
                            sTitle: _this2.get("_objStringsService").getString("edit"),
                            sIcon: "edit",
                            executeCardAction: function executeCardAction() {
                                _this2.openEditPopup();
                            }
                        }]);
                    }
                }
            }));
        },
        openEditPopup: function openEditPopup() {
            var _this3 = this;

            this.clearAllErrorMessages();
            this.clearForm();

            this.set("_bShowEditPopup", true);
            if (!a24Core.isEmpty(this.get("setBreadCrumbAction"))) {
                this.get("setBreadCrumbAction")();
            }

            var objAdditionalNoteData = this.get("_objAdditionalNoteData");

            if (!a24Core.isEmpty(objAdditionalNoteData)) {
                this.populateInputFields(objAdditionalNoteData);
            }

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this3)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    _this3.set("_objOriginalData", _this3.getPlainFormValueObject());
                });
            });
        },
        populateInputFields: function populateInputFields(objData) {
            if (!a24Core.isEmpty(objData)) {
                this.get("objChildren.note").setValueFromOutside(objData.note);
            }
        },
        getPayload: function getPayload() {
            var objData = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());

            return objData;
        },
        setSuccessResponse: function setSuccessResponse(sSnackbarMessage) {
            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            this.set("_bPopupLoading", false);
            this.set("_bShowEditPopup", false);
            this.getCandidateAdditionalNote();
        },
        setErrorResponse: function setErrorResponse(sCode, objError, sSnackbarMessage) {
            if (sCode === "MIN_LENGTH") {
                this.setResponseError(objError.arrPath, "validationRestMinLength");
            } else {
                this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            }

            this.set("_bPopupLoading", false);
        },
        popupClose: function popupClose() {
            if (!this.get("_bPopupLoading")) {
                var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));
                if (bDataMatches) {
                    this.set("_bShowEditPopup", false);
                } else {
                    this.set("_bShowUnsavedData", true);
                }
            }
        },
        actions: {
            onPopupEditConfirm: function onPopupEditConfirm() {
                var _this4 = this;

                if (!this.doFullValidation()) {
                    this.set("_bPopupLoading", true);

                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objCandidateProfileStringsService").getString("profileNote")));

                    this.get("objAdditionalNoteRestService").updateAdditionalNote(this, "_objAdditionalNoteUpdatePromise", this.get("sCandidateId"), this.getPayload(), function () {
                        _this4.setSuccessResponse(_this4.get("_objStringsService").getTokenString("successUpdateItem", _this4.get("_objCandidateProfileStringsService").getString("profileNote")));
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                        _this4.setErrorResponse(sCode, objError, _this4.get("_objStringsService").getTokenString("failUpdateItem", _this4.get("_objCandidateProfileStringsService").getString("profileNote")));
                    }));
                }
            },
            onPopupClose: function onPopupClose() {
                this.popupClose();
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowEditPopup", false);
                this.set("_bShowUnsavedData", false);
            }
        }
    });
});