define("a24-ember-staffshift-core/services/rest-user-context", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objUserContextProperty: null,
        _sContextUrl: "/rest/application/usercontext",

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        _sAppContext: Ember.computed.readOnly("env.sAppContext"),
        _sTriageBaseRest: Ember.computed.readOnly("env.sTriageBaseRest"),

        /**
         * This service method will be used to retrieve all the contexts that the user has available
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sUserId - The id of the user getting the contexts
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object to execute when the service call is not successful
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  23 June 2016
         */
        getList: function getList(objCaller, sUserId, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrl(this.get("_sTriageBaseRest") + this.get("_sContextUrl"));
            a24RestUrlConstruct.addQueryParamObject({
                sUserId: {
                    mValue: sUserId
                }
            });
            a24RestUrlConstruct.addQueryParamObject({
                page: {
                    mValue: "1"
                }
            });
            a24RestUrlConstruct.addQueryParamObject({
                per_page: {
                    mValue: 1000
                }
            });

            return a24RestCallHelper.doRestServiceCall(objCaller, "_objUserContextProperty", a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, null, "", true));
        },
        /**
         * This service method will be used to attempt to set a users current context
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sContextId - The id of the context that needs to be set as the current
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object to execute when the service call is not successful
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  02 August 2016
         */
        setSelectedContext: function setSelectedContext(objCaller, sContextId, funcSuccess, objFailure) {

            var sFinalUrl = this.get("_sTriageBaseRest") + this.get("_sContextUrl") + "/" + sContextId;

            return a24RestCallHelper.doRestServiceCall(objCaller, "_objUserContextProperty", a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "POST", sFinalUrl, funcSuccess, objFailure, {
                bSelectedContext: true
            }));
        },

        /**
         * This service method will be used to retrieve the current context details
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sResource - The resource used for the context call
         * @param sResourceId - The id of the resource for the context call
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object to execute when the service call is not successful
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  05 March 2018
         */
        getUserContextDetails: function getUserContextDetails(objCaller, sResource, sResourceId, funcSuccess, objFailure) {

            var sUserContextUrl = "";

            if (sResource === "system-admin") {
                sUserContextUrl = "/rest/user/account-settings/timezone/" + this.get("_objUserSession").objAuthData.context.sUserId;
            } else if (sResource === "agency") {
                sUserContextUrl = "/rest/agency/" + sResourceId + "/context";
            } else if (sResource === "agency-group") {
                sUserContextUrl = "/rest/agency-group/" + sResourceId + "/context";
            } else if (sResource === "organisation") {
                sUserContextUrl = "/rest/organisation/" + sResourceId + "/context";
            } else if (sResource === "candidate") {
                sUserContextUrl = "/rest/user/account-settings/timezone/" + this.get("_objUserSession").objAuthData.context.sUserId;
            }

            return a24RestCallHelper.doRestServiceCall(objCaller, "_objContextCallProperty", a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sTriageBaseRest") + sUserContextUrl, funcSuccess, objFailure));
        }
    });
});