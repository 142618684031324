define("a24-ember-staffshift-users/components/agency-group-view-page", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-users/templates/components/agency-group-view-page"], function (exports, _didRenderChangesMixin, _agencyGroupViewPage) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _agencyGroupViewPage.default,

        // Services
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objStaffshiftUsersStringsService: Ember.inject.service("a24-strings-staffshift-users"),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.get("_objTitleBarService").setTitleBar(this.get("_objStaffshiftUsersStringsService").getString("viewGroup"), function () {
                Ember.get(_this, "_objNavigationService").navigateWithinContext("acl-group-list");
            });
        },

        actions: {
            onTitleBarUpdate: function onTitleBarUpdate(sGroupName) {
                var _this2 = this;

                this.get("_objTitleBarService").setTitleBar(this.get("_objStaffshiftUsersStringsService").getString("viewGroup") + " - " + sGroupName, function () {
                    Ember.get(_this2, "_objNavigationService").navigateWithinContext("acl-group-list");
                });
            }
        }
    });
});