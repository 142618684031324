define("a24-ember-candidate-profile/services/activity-type-lookup", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        objActivityTypes: null,
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),

        init: function init() {
            this._super.apply(this, arguments);
            this.set("objActivityTypes", {
                REGISTRATION_CALL_MADE: {
                    sLabel: this.get("_objCandidateProfileEnumsService").getFrontendValue("activityTypes", "REGISTRATION_CALL_MADE"),
                    sIcon: "call"
                },
                REGISTRATION_CALL_RECEIVED: {
                    sLabel: this.get("_objCandidateProfileEnumsService").getFrontendValue("activityTypes", "REGISTRATION_CALL_RECEIVED"),
                    sIcon: "call"
                },
                REGISTRATION_EMAIL_SENT: {
                    sLabel: this.get("_objCandidateProfileEnumsService").getFrontendValue("activityTypes", "REGISTRATION_EMAIL_SENT"),
                    sIcon: "email"
                },
                REGISTRATION_EMAIL_RECEIVED: {
                    sLabel: this.get("_objCandidateProfileEnumsService").getFrontendValue("activityTypes", "REGISTRATION_EMAIL_RECEIVED"),
                    sIcon: "email"
                },
                LEFT_VOICEMAIL: {
                    sLabel: this.get("_objCandidateProfileEnumsService").getFrontendValue("activityTypes", "LEFT_VOICEMAIL"),
                    sIcon: "voicemail"
                },
                CALL_MADE_NO_ANSWER: {
                    sLabel: this.get("_objCandidateProfileEnumsService").getFrontendValue("activityTypes", "CALL_MADE_NO_ANSWER"),
                    sIcon: "phone_missed"
                },
                INTERVIEW_BOOKED: {
                    sLabel: this.get("_objCandidateProfileEnumsService").getFrontendValue("activityTypes", "INTERVIEW_BOOKED"),
                    sIcon: "event_note"
                },
                REGISTRATION_TEXT_SENT: {
                    sLabel: this.get("_objCandidateProfileEnumsService").getFrontendValue("activityTypes", "REGISTRATION_TEXT_SENT"),
                    sIcon: "textsms"
                },
                INITIAL_SCREENING_COMPLETED: {
                    sLabel: this.get("_objCandidateProfileEnumsService").getFrontendValue("activityTypes", "INITIAL_SCREENING_COMPLETED"),
                    sIcon: "verified_user"
                },
                INITIAL_SCREENING_PAUSED: {
                    sLabel: this.get("_objCandidateProfileEnumsService").getFrontendValue("activityTypes", "INITIAL_SCREENING_PAUSED"),
                    sIcon: "watch_later"
                },
                APPLICATION_PACK_SENT: {
                    sLabel: this.get("_objCandidateProfileEnumsService").getFrontendValue("activityTypes", "APPLICATION_PACK_SENT"),
                    sIcon: "card_giftcard"
                },
                INTERVIEW_HELD: {
                    sLabel: this.get("_objCandidateProfileEnumsService").getFrontendValue("activityTypes", "INTERVIEW_HELD"),
                    sIcon: "assignment_ind"
                },
                DBS_ONLINE_CHECK_DONE: {
                    sLabel: this.get("_objCandidateProfileEnumsService").getFrontendValue("activityTypes", "DBS_ONLINE_CHECK_DONE"),
                    sIcon: "playlist_add_check"
                },
                INTERVIEW_FAILED: {
                    sLabel: this.get("_objCandidateProfileEnumsService").getFrontendValue("activityTypes", "INTERVIEW_FAILED"),
                    sIcon: "assignment_late"
                },
                INTERVIEW_PASSED: {
                    sLabel: this.get("_objCandidateProfileEnumsService").getFrontendValue("activityTypes", "INTERVIEW_PASSED"),
                    sIcon: "assignment_turned_in"
                }
            });
        },
        getActivityTypeLookup: function getActivityTypeLookup(sKey) {
            if (a24Core.isEmpty(sKey) || !this.get("objActivityTypes").hasOwnProperty(sKey)) {
                return null;
            }
            return this.get("objActivityTypes")[sKey];
        }
    });
});