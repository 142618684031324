define("a24-ember-candidate-profile/components/candidate-plugins-overview", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-plugins-overview"], function (exports, _didRenderChangesMixin, _candidatePluginsOverview) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _candidatePluginsOverview.default,
        objPluginRestService: null,
        sCandidateId: null,
        _sIFrameUrl: null,
        _sIFrameHeader: null,
        _bShowManagePopup: false,
        _bPluginLoading: true,
        _arrPluginData: null,
        _objUrlCall: null,
        _objNavigation: Ember.inject.service("navigation"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objSpecialEditPopupAction: null,
        _bIsCandidateContext: Ember.computed("objPluginRestService.sContext", function () {
            return this.get("objPluginRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE");
        }),
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            // This is to init the rest service
            this.get("objPluginRestService");

            if (a24Core.isEmpty(this.get("arrPluginData"))) {
                this.set("_arrPluginData", []);
            }

            this.set("_objSpecialEditPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onPopupClose");
                }
            });

            this.getPluginData();
        },
        _candidateDataSuccess: function _candidateDataSuccess(arrResponse) {
            var _this2 = this;

            if (a24Core.isEmpty(arrResponse)) {
                this.set("_bPluginLoading", false);
            } else {
                var arrPluginData = [];
                // Loop over items in the response and build up array of objects containing the properties
                // required to display plug-ins
                arrResponse.forEach(function (objItem) {

                    var sPluginUrl = "";
                    var sOverviewUrl = "";
                    var sPluginName = "";

                    // Ensure that required object and properties exist
                    if (!a24Core.isEmpty(objItem.objSetting) && !a24Core.isEmpty(objItem.objSetting.sOverviewUrl)) {
                        // If sFormUrl is not empty we build up the ember navigation route
                        sOverviewUrl = "/plugin-loader?plugin_id=" + objItem.id + "&plugin_state=overview_url";
                        sPluginName = objItem.objSetting.sPluginName;
                        if (!a24Core.isEmpty(objItem.objSetting.sFormUrl)) {
                            sPluginUrl = _this2.get("_objNavigation").navigateWithinContext("plugin", { plugin: [objItem.id] }, null, true);
                        }

                        // Build up array of plugin data
                        arrPluginData.push({
                            sPluginUrl: sPluginUrl,
                            sIFrameUrl: sOverviewUrl,
                            sHeader: sPluginName
                        });
                    }
                });

                this.set("_arrPluginData", arrPluginData);
                this.set("_bPluginLoading", false);
            }
        },
        getPluginData: function getPluginData() {
            var _this3 = this;

            var sCandidateId = this.get("sCandidateId");

            var funcSuccessCallback = Ember.run.bind(this, this._candidateDataSuccess);
            var funcFailureCallback = {
                funcCallback: function funcCallback() {
                    _this3.set("_bPluginLoading", false);
                },
                arrErrorCodes: ["*", "*CODE", "*MALFORMED"]
            };

            this.get("objPluginRestService").getPlugins(this, "_objUrlCall", sCandidateId, funcSuccessCallback, funcFailureCallback);
        },


        actions: {
            onAgencyGroupManageClick: function onAgencyGroupManageClick(sPluginUrl, sPluginName) {
                var arrPlugin = sPluginUrl.split("/");
                var sPluginId = arrPlugin[arrPlugin.length - 1];

                this.set("_sIFrameUrl", "/plugin-loader?plugin_id=" + sPluginId + "&plugin_state=form_url");

                this.set("_sIFrameHeader", sPluginName);

                this.set("_bShowManagePopup", true);
            },
            onPopupClose: function onPopupClose() {
                if (this.get("_bPluginLoading")) {
                    return;
                }

                this.set("_bShowManagePopup", false);
            }
        }
    });
});