define("a24-ember-staffshift-users/components/agency-group-add-edit-popup", ["exports", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-users/templates/components/agency-group-add-edit-popup"], function (exports, _inputFormElement, _didRenderChangesMixin, _agencyGroupAddEditPopup) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _agencyGroupAddEditPopup.default,

        // Services
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objRestAclGroup: Ember.inject.service("rest-acl-group"),
        _objUserSession: Ember.inject.service("user-session"),
        _objStaffshiftUsersStringsService: Ember.inject.service("a24-strings-staffshift-users"),
        _objStaffshiftUsersEnumsService: Ember.inject.service("a24-enums-staffshift-users"),
        _objStringsService: Ember.inject.service("a24-strings"),

        // Default properties
        bShowAddPopup: false,
        bIsEditing: false,
        objAclGroupData: null,
        _bAddPopupLoading: false,
        _objOriginalData: null,
        _bShowUnsavedData: false,
        _objSpecialAddEditPopupAction: null,

        arrStatuses: null,

        _arrResources: null,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this._setupForm();
            if (Ember.get(this, "bShowAddPopup")) {
                Ember.run.debounce(this, this._resetFormState, 100);
            }
            this.set("_objSpecialAddEditPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onPopupAddCancel");
                }
            });
        },


        onTogglePopup: Ember.observer("bShowAddPopup", function () {
            if (Ember.get(this, "bShowAddPopup")) {
                Ember.run.debounce(this, this._resetFormState, 100);
            }
        }),

        _resetFormState: function _resetFormState() {
            var _this2 = this;

            this.clearForm();
            this.clearAllErrorMessages();

            if (Ember.get(this, "bIsEditing") && Ember.get(this, "objAclGroupData")) {
                Ember.get(this, "objChildren.groupName").setValueFromOutside(Ember.get(this, "objAclGroupData.sGroupName"));

                Ember.set(this, "_objGroupRole", {
                    sLabel: this.get("_objStaffshiftUsersEnumsService").getFrontendValue("aclRoles", Ember.get(this, "objAclGroupData.sRoleId")),
                    sValue: Ember.get(this, "objAclGroupData.sRoleId")
                });

                var arrStatuses = this.get("objAclGroupData.arrStatuses");
                arrStatuses = arrStatuses.map(function (objItem) {
                    return {
                        sValue: objItem
                    };
                });

                Ember.set(this, "_arrResources", arrStatuses);
            }

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    Ember.set(_this2, "_objOriginalData", _this2.getPlainFormValueObject());
                });
            });
        },
        _setupForm: function _setupForm() {
            Ember.set(this, "_arrRoles", this.get("_objStaffshiftUsersEnumsService").getList("aclRoles"));
            Ember.set(this, "objChildConfig", {
                "objChildren.groupName": function objChildrenGroupName(objMixin) {
                    a24Validation.addMinLength(objMixin, 3);
                    a24Validation.addMaxLength(objMixin, 50);
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.role": function objChildrenRole(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                }
            });
        },


        actions: {
            onPopupAddConfirm: function onPopupAddConfirm() {
                var _this3 = this;

                if (!this.doFullValidation()) {
                    Ember.set(this, "_bAddPopupLoading", true);

                    if (Ember.get(this, "bIsEditing")) {
                        Ember.get(this, "_objSnackbarService").showSnackbarMessage(this.get("_objStaffshiftUsersStringsService").getString("editingAclGroup"));
                    } else {
                        Ember.get(this, "_objSnackbarService").showSnackbarMessage(this.get("_objStaffshiftUsersStringsService").getString("creatingAclGroup"));
                    }

                    var objFormData = this.getPlainFormValueObject();

                    var sAgencyGroupId = Ember.get(this, "_objUserSession").getUserContextReference().mId;

                    var objPayload = {
                        sName: objFormData.groupName,
                        sRoleId: objFormData.role,
                        arrResources: [{
                            mId: sAgencyGroupId,
                            sResource: "AgencyGroup"
                        }]
                    };
                    if (!a24Core.isEmpty(objFormData.status)) {
                        for (var i = 0; i < objFormData.status.length; i++) {
                            objPayload.arrResources.push({
                                mId: objFormData.status[i],
                                sResource: "AgencyGroupStatus"
                            });
                        }
                    }

                    var funcSuccess = function funcSuccess() {
                        Ember.set(_this3, "_bAddPopupLoading", false);
                        if (!a24Core.isEmpty(_this3.get("onPopupAddSuccess"))) {
                            _this3.get("onPopupAddSuccess")();
                        }

                        if (Ember.get(_this3, "bIsEditing")) {
                            Ember.get(_this3, "_objSnackbarService").showSnackbarMessage(_this3.get("_objStaffshiftUsersStringsService").getString("editAclGroupSuccess"));
                        } else {
                            Ember.get(_this3, "_objSnackbarService").showSnackbarMessage(_this3.get("_objStaffshiftUsersStringsService").getString("createAclGroupSuccess"));
                        }
                    };

                    var objFailureObject = a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {

                        if (sCode === "MIN_LENGTH") {
                            _this3.setResponseError(objError.arrPath, "validationRestMinLength");
                        } else if (sCode === "MAX_LENGTH") {
                            _this3.setResponseError(objError.arrPath, "validationRestMaxLength");
                        } else if (sCode === "PATTERN") {
                            _this3.setResponseError(objError.arrPath, "validationRestInvalidChar");
                        } else if (sCode === "DUPLICATE_FOUND") {
                            _this3.setResponseError(["groupName"], _this3.get("_objStaffshiftUsersStringsService").getString("aclConflictError"), true, true);
                        } else if (sCode === "REQUIRED_FIELD_NOT_SET") {
                            _this3.setResponseError(objError.arrPath, "validationRequired");
                        } else {
                            if (Ember.get(_this3, "bIsEditing")) {
                                Ember.get(_this3, "_objSnackbarService").showSnackbarMessage(_this3.get("_objStaffshiftUsersStringsService").getString("editAclGroupFailed"));
                            } else {
                                Ember.get(_this3, "_objSnackbarService").showSnackbarMessage(_this3.get("_objStaffshiftUsersStringsService").getString("createAclGroupFailed"));
                            }
                        }
                        Ember.set(_this3, "_bAddPopupLoading", false);
                    });

                    if (Ember.get(this, "bIsEditing")) {
                        Ember.get(this, "_objRestAclGroup").editAclGroup(this, "_objRestAclGroupProperty", Ember.get(this, "objAclGroupData.id"), funcSuccess, objFailureObject, objPayload);
                    } else {
                        Ember.get(this, "_objRestAclGroup").addAclGroup(this, "_objRestAclGroupProperty", funcSuccess, objFailureObject, objPayload);
                    }
                }
            },
            onPopupAddCancel: function onPopupAddCancel() {
                if (!Ember.get(this, "_bAddPopupLoading")) {

                    // Check if the form has any unsaved data
                    var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), Ember.get(this, "_objOriginalData"));

                    if (bDataMatches) {
                        if (!a24Core.isEmpty(this.get("onPopupAddCancel"))) {
                            this.get("onPopupAddCancel")();
                        }
                    } else {
                        Ember.set(this, "_bShowUnsavedData", true);
                    }
                }
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                Ember.set(this, "_bShowUnsavedData", false);
                if (!a24Core.isEmpty(this.get("onPopupAddCancel"))) {
                    this.get("onPopupAddCancel")();
                }
            }
        }
    });
});