var a24ConstantsPolicyLibrary = {
    //======== A24 ADDON OVERRIDES START ========

    DATE_FORMAT_REST_FORMAT_MONTH_YEAR_ONLY: "YYYY-MM",
    DATE_FORMAT_REST_FORMAT_DATE_ONLY: "YYYY-MM-DD",
    DATE_FORMAT_REST_FORMAT_WITHOUT_TZ: "YYYY-MM-DDTHH:mm:ss.sss",
    DATE_FORMAT_REST_FORMAT: "YYYY-MM-DDTHH:mm:ss.sssZ"

    //======== A24 ADDON OVERRIDES END   ========
};

if (typeof a24Constants === "undefined") {
    var a24Constants = {};
}

Object.keys(a24ConstantsPolicyLibrary).forEach(function(sKey) {
    a24Constants[sKey] = a24ConstantsPolicyLibrary[sKey];
});
