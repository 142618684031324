define("a24-ember-candidate-profile/services/rest-user-avatar", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _sRestApiBaseUrl: Ember.computed.readOnly("_objEnv.sRestApiBaseUrl"),

        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * Get avatar for the user
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sUserId - The user id to fetch avatar data for
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  30 October 2019
         */
        getAvatar: function getAvatar(objContext, sProperty, sUserId, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/user/" + sUserId + "/avatar");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", Ember.get(this, "_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Update avatar for the user
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The user id
         * @param objData - The contact number data
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  30 October 2019
         */
        updateAvatar: function updateAvatar(objContext, sProperty, sUserId, objData, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/user/" + sUserId + "/avatar");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "PUT", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        },

        /**
         * Delete avatar for the user
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sUserId - The user id to fetch avatar data for
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  30 October 2019
         */
        deleteAvatar: function deleteAvatar(objContext, sProperty, sUserId, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/user/" + sUserId + "/avatar");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "DELETE", Ember.get(this, "_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});