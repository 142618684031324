define("a24-ember-candidate-profile/components/email-preview", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/email-preview"], function (exports, _didRenderChangesMixin, _emailPreview) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _emailPreview.default,
        classNames: ["email-preview"],
        objEmail: null,

        _objAjax: $.ajax,
        _sPreviewString: null,
        _objEmailPreviewGeneratorService: Ember.inject.service("email-preview-generator"),
        _bInitDone: false,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            if ("srcdoc" in document.createElement("iframe")) {
                this.set("_bInitDone", true);

                // Render the initial value
                this.onValueChange();
            } else {
                this.get("_objAjax")({
                    url: this.get("_objEmailPreviewGeneratorService._sSrcDocPolyfilJs"),
                    dataType: "script",
                    cache: true
                }).always(function () {
                    _this.set("_bInitDone", true);
                    // Render the initial value
                    _this.onValueChange();
                });
            }
        },
        onValueChange: Ember.observer("objEmail", function () {
            if (this.get("_bInitDone")) {
                // Re-render the value when it changes
                this.set("_sPreviewString", this.get("_objEmailPreviewGeneratorService").generateFullHtmlFromEmailObject(this.get("objEmail")));
            }
        })
    });
});