define("a24-ember-candidate-profile/components/page-compliance-rules", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/page-compliance-rules", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _pageComplianceRules, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _pageComplianceRules.default,
        classNames: ["page-compliance-rules"],
        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),
        _sReactGatewayUrl: Ember.computed.readOnly("env.sReactGatewayUrl"),
        _sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        objQueryParams: null,
        objRestCandidateType: null,
        objSavedSearchService: null,
        sUrlBase: null,
        sSavedSearchRef: null,
        sDatagridRoute: null,
        sContext: null,
        sContractId: null,
        sContractName: null,
        sContractStatus: null,

        bLinkedRulesView: false,
        bRuleLoading: false,
        arrLinkedRules: null,
        arrComplianceContractEntityActions: null,
        iVisibleActions: 0,

        _bPageLoading: true,
        _bAgencyGroupContext: false,
        _bAllowEdit: false,
        _bShowConditionBuilder: false,
        _bBusy: false,
        _bDatagridReady: false,
        _bPostSimpleTagsOnly: false,
        _bForceReset: false,
        _bServiceCallsDone: false,
        _bShowLoadFailedPopup: false,
        _bServerError: false,
        _bShowUnlinkError: false,
        _bEntityActionPending: true,

        _sSimpleTagsUrl: null,
        _sSearchUrl: null,

        _iRequestId: null,

        _arrSelectedItems: null,
        _arrConvert: null,
        _arrEntityActions: null,
        _arrCandidateTypes: null,

        _objSelectedItem: null,
        _objCandidateTypesResponse: null,
        _objEntityActionPromise: null,
        _objEntityActionSectionPromise: null,
        _objQueuedPostData: null,

        _objUserSession: Ember.inject.service("user-session"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objNavigation: Ember.inject.service("navigation"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objFabButtonService: Ember.inject.service("fab-button"),
        _objProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objEnumsStaffshiftCore: Ember.inject.service("a24-enums-staffshift-core"),
        _objChatterIframeComService: Ember.inject.service("chatter-iframe-com"),

        _objRestEntityAction: Ember.inject.service("rest-entity-action-compliance-rule"),

        // These are for the react popups
        _sIFrameUrl: null,
        _bPopupLoading: true,
        _bShowManageComplianceRules: false,
        _objReactPopupPostData: null,

        _sIFrameViewUrl: null,
        _bPopupViewLoading: true,
        _bShowViewComplianceRules: false,
        _objReactViewPopupPostData: null,

        init: function init() {
            this._super.apply(this, arguments);

            this.set("_arrConvert", []);

            this.set("_sIFrameUrl", this.get("_sReactGatewayUrl"));
            this.set("_sIFrameViewUrl", this.get("_sReactGatewayUrl"));

            this.get("_objTitleBarService").setTitleBar(this.get("_objProfileStringsService").getString("complianceRules"));

            if (this.get("sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP")) {
                this.set("_bAgencyGroupContext", true);
                this.set("_sSearchUrl", this.get("_sRestApiBaseUrl") + "/v1/agency-group/" + this.get("_objNavigation.sContextId") + "/advanced-search-v2/candidate");
            } else {
                this.set("_bAgencyGroupContext", false);
                this.set("_sSearchUrl", this.get("_sRestApiBaseUrl") + "/v1/agency/" + this.get("_objNavigation.sContextId") + "/advanced-search/candidate");
            }

            this.doServiceCalls();
        },
        _onLinkedRules: Ember.observer("_bServiceCallsDone", "arrLinkedRules", "arrComplianceContractEntityActions", function () {
            Ember.run.once(this, "linkedRulesActions");
        }),

        linkedRulesActions: function linkedRulesActions() {
            var _this = this;

            if (this.get("bLinkedRulesView") && this.get("_bServiceCallsDone")) {
                if (this.get("arrLinkedRules") !== null && this.get("arrComplianceContractEntityActions") !== null) {
                    this._setDatagridActions();
                    this.set("_bPageLoading", true);
                    Ember.run.next(function () {
                        if (a24Core.isEmpty(_this)) {
                            return;
                        }
                        Ember.run.schedule("afterRender", function () {
                            _this.set("_bPageLoading", false);
                        });
                    });
                }
            }
        },
        _doEntityActionCall: function _doEntityActionCall(arrRuleIds) {
            // Sets the loading state on the actions
            this.set("_objDatagridConfig.arrSingleItemSelectActions", Ember.copy(this.get("_arrLoadingActions"), true));

            if (!a24Core.isEmpty(arrRuleIds)) {
                var objQuery = {
                    entity_id: { mValue: arrRuleIds.join(",") }
                };

                var objPromiseEntityActions = this.get("_objRestEntityAction").getComplianceRulesEntityActions(this, "_objEntityActionPromise", objQuery, function () {
                    // Handled by RSVP
                }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{}));

                var objPromiseList = {
                    _objEntityActions: objPromiseEntityActions
                };

                var arrRequiredResponses = ["_objEntityActions"];

                var objThis = this;

                Ember.copy(a24RSVP).configure(this, objPromiseList, [{
                    arrRequiredResponses: arrRequiredResponses,
                    funcOnComplete: function funcOnComplete(objResponses) {
                        if (!a24Core.isEmpty(objResponses._objEntityActions) && !a24Core.isEmpty(objResponses._objEntityActions.objResponse)) {
                            objThis.set("_bEntityActionPending", false);
                            objThis.set("_arrEntityActions", objResponses._objEntityActions.objResponse);
                        }
                        objThis._setDatagridActions();
                    }
                }]);
            }
        },
        _isLinked: function _isLinked(arrLinkedRules, sCurrentRuleId) {
            var objRuleLinked = null;
            if (!a24Core.isEmpty(arrLinkedRules)) {
                objRuleLinked = arrLinkedRules.find(function (sLinkedRuleId) {
                    return sLinkedRuleId === sCurrentRuleId;
                });
            }

            var bLinkedRule = false;
            if (!a24Core.isEmpty(objRuleLinked)) {
                bLinkedRule = true;
            }
            return bLinkedRule;
        },
        pendingEntityActions: Ember.observer("_arrEntityActions", function () {
            var _this2 = this;

            if (!a24Core.isEmpty(this.get("_arrEntityActions")) && !a24Core.isEmpty(this.get("_objQueuedPostData"))) {
                var arrEntityActions = this.get("_arrEntityActions");
                var objPostData = this.get("_objQueuedPostData");
                var arrEntityAction = [arrEntityActions.find(function (objItem) {
                    return objItem.entity_id === objPostData.complianceRuleId;
                })];
                objPostData.pendingData = { entityActionsData: false };
                objPostData.entityActionsData = arrEntityAction;
                Ember.run.next(function () {
                    if (a24Core.isEmpty(_this2)) {
                        return;
                    }
                    Ember.run.schedule("afterRender", function () {
                        if (!_this2.get("_bEntityActionPending") && _this2.get("_bShowViewComplianceRules")) {
                            _this2.set("_objReactViewPopupPostData", {
                                type: "update_trigger_route",
                                data: {
                                    route: "agency/complianceRules/viewPopup",
                                    data: objPostData
                                }
                            });
                        }
                    });
                });
            }
        }),
        _viewComplianceRule: function _viewComplianceRule(objSelectedItem) {
            var bDelete = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            this.set("_bShowViewComplianceRules", true);
            var arrEntityActions = this.get("_arrEntityActions");
            var arrEntityAction = null;
            var arrRowConditions = objSelectedItem.condition;
            var arrRuleApplicableTypes = [];
            for (var i = 0; i < arrRowConditions.length; i++) {
                arrRuleApplicableTypes = arrRuleApplicableTypes.concat(arrRowConditions[i].applicable_position_types);
            }
            if (!a24Core.isEmpty(arrEntityActions)) {
                arrEntityAction = [arrEntityActions.find(function (objItem) {
                    return objItem.entity_id === objSelectedItem._id;
                })];
            }
            var objPostData = {
                complianceRuleId: objSelectedItem._id,
                complianceRuleName: objSelectedItem.name,
                complianceContractId: this.get("sContractId"),
                complianceContractName: this.get("sContractName"),
                ruleApplicableTypes: arrRuleApplicableTypes,
                agencyCandidateTypes: this.get("_arrCandidateTypes"),
                complianceContractStatus: this.get("sContractStatus"),
                isLinked: this._isLinked(this.get("arrLinkedRules"), objSelectedItem._id),
                openDelete: bDelete,
                complianceRuleData: objSelectedItem,
                entityActionsData: [],
                candidateTypesData: this.get("_arrCandidateTypes"),
                contractEntityActionsData: this.get("arrComplianceContractEntityActions")
            };

            if (this.get("_bAgencyGroupContext")) {
                if (this.get("_bEntityActionPending")) {
                    objPostData.pendingData = { entityActionsData: true };
                } else {
                    objPostData.entityActionsData = arrEntityAction;
                }
            }
            this.set("_objQueuedPostData", Ember.copy(objPostData, true));

            this.set("_objReactViewPopupPostData", {
                type: "trigger_route",
                data: {
                    route: "agency/complianceRules/viewPopup",
                    data: objPostData
                }
            });
        },
        _setUnsetDefaultRule: function _setUnsetDefaultRule(objSelectedItem, bIsSet) {
            this.set("_bShowViewComplianceRules", true);

            var sRoute = bIsSet ? "setAsDefaultPopup" : "unSetAsDefaultPopup";

            this.set("_objReactViewPopupPostData", {
                type: "trigger_route",
                data: {
                    route: "agency/complianceRules/" + sRoute,
                    data: {
                        complianceRuleId: objSelectedItem._id,
                        complianceRuleName: objSelectedItem.name
                    }
                }
            });
        },
        _setDatagridActions: function _setDatagridActions() {
            var _this3 = this;

            var objThis = this;

            var arrSingleActions = [{
                sTitle: this.get("_objProfileStringsService").getString("view"),
                sIcon: "visibility",
                executeCardAction: function executeCardAction() {
                    objThis._viewComplianceRule(objThis.get("_arrSelectedItems")[0]);
                }
            }];

            if (!a24Core.isEmpty(this.get("_arrEntityActions"))) {
                arrSingleActions.push({
                    sTitle: this.get("_objStringsService").getString("edit"),
                    sIcon: "edit",
                    executeCardAction: function executeCardAction() {
                        objThis.set("_bShowManageComplianceRules", true);
                        objThis.set("_objReactPopupPostData", {
                            type: "trigger_route",
                            data: {
                                route: "agency/complianceRules/managePopup",
                                data: {
                                    complianceRuleId: objThis.get("_arrSelectedItems")[0]._id,
                                    complianceRuleData: objThis.get("_arrSelectedItems")[0]
                                }
                            }
                        });
                    },
                    funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                        var arrEntityActions = _this3.get("_arrEntityActions");
                        for (var i = 0; i < arrEntityActions.length; i++) {
                            if (arrEntityActions[i].entity_id === arrSelectedItems[0]._id) {
                                for (var l = 0; l < arrEntityActions[i].actions.length; l++) {
                                    if (arrEntityActions[i].actions[l].action === "update" && arrEntityActions[i].actions[l].allowed) {
                                        return true;
                                    }
                                }
                            }
                        }
                        return false;
                    }
                });
                arrSingleActions.push({
                    sTitle: this.get("_objStringsService").getString("remove"),
                    sIcon: "delete",
                    executeCardAction: function executeCardAction() {
                        objThis._viewComplianceRule(objThis.get("_arrSelectedItems")[0], true);
                    },
                    funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                        var arrEntityActions = _this3.get("_arrEntityActions");
                        for (var i = 0; i < arrEntityActions.length; i++) {
                            if (arrEntityActions[i].entity_id === arrSelectedItems[0]._id) {
                                for (var l = 0; l < arrEntityActions[i].actions.length; l++) {
                                    if (arrEntityActions[i].actions[l].action === "delete" && (arrEntityActions[i].actions[l].allowed || arrEntityActions[i].actions[l].reason_code === "COMPLIANCE_RULE_LINKED_TO_CONTRACT")) {
                                        return true;
                                    }
                                }
                            }
                        }
                        return false;
                    }
                });
                arrSingleActions.push({
                    sTitle: this.get("_objProfileStringsService").getString("setAsDefault"),
                    sIcon: "label",
                    executeCardAction: function executeCardAction() {
                        objThis._setUnsetDefaultRule(objThis.get("_arrSelectedItems")[0], true);
                    },
                    funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                        var arrEntityActions = _this3.get("_arrEntityActions");
                        if (!arrSelectedItems[0].is_default) {
                            for (var i = 0; i < arrEntityActions.length; i++) {
                                if (arrEntityActions[i].entity_id === arrSelectedItems[0]._id) {
                                    for (var l = 0; l < arrEntityActions[i].actions.length; l++) {
                                        if (arrEntityActions[i].actions[l].action === "default" && arrEntityActions[i].actions[l].allowed) {
                                            return true;
                                        }
                                    }
                                }
                            }
                        }
                        return false;
                    }
                });
                arrSingleActions.push({
                    sTitle: this.get("_objProfileStringsService").getString("unSetAsDefault"),
                    sIconSvg: Ember.String.htmlSafe(this.get("_objEnumsStaffshiftCore").getMaterialSvgIcon("label_off")),
                    executeCardAction: function executeCardAction() {
                        objThis._setUnsetDefaultRule(objThis.get("_arrSelectedItems")[0], false);
                    },
                    funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                        var arrEntityActions = _this3.get("_arrEntityActions");
                        if (arrSelectedItems[0].is_default) {
                            for (var i = 0; i < arrEntityActions.length; i++) {
                                if (arrEntityActions[i].entity_id === arrSelectedItems[0]._id) {
                                    for (var l = 0; l < arrEntityActions[i].actions.length; l++) {
                                        if (arrEntityActions[i].actions[l].action === "undefault" && arrEntityActions[i].actions[l].allowed) {
                                            return true;
                                        }
                                    }
                                }
                            }
                        }
                        return false;
                    }
                });
            }

            if (this.get("bLinkedRulesView") && !a24Core.isEmpty(this.get("arrComplianceContractEntityActions"))) {

                var arrExtraActions = [{
                    "sIcon": "edit",
                    "sIconClass": "a24-grey-text-darken-1",
                    "sTitle": objThis.get("_objProfileStringsService").getString("linkUnlinkRule"),
                    executeCardAction: function executeCardAction() {
                        var objQeuryUpdate = Ember.copy(_this3.get("objQueryParams"), true);
                        objQeuryUpdate._id.sValue = "";
                        _this3.set("objQueryParams", objQeuryUpdate);
                    }
                }];

                this.set("_arrExtraActions", arrExtraActions);

                arrSingleActions.push({
                    sTitle: objThis.get("_objProfileStringsService").getString("linkRule"),
                    sIconFa: "fa-link",
                    executeCardAction: function executeCardAction() {
                        objThis.set("_bShowViewComplianceRules", true);
                        var arrRowConditions = objThis.get("_arrSelectedItems")[0].condition;
                        var arrRuleApplicableTypes = [];
                        for (var i = 0; i < arrRowConditions.length; i++) {
                            arrRuleApplicableTypes = arrRuleApplicableTypes.concat(arrRowConditions[i].applicable_position_types);
                        }
                        objThis.set("_objReactViewPopupPostData", {
                            type: "trigger_route",
                            data: {
                                route: "agency/complianceContracts/linkComplianceRulePopup",
                                data: {
                                    ruleName: objThis.get("_arrSelectedItems")[0].name,
                                    ruleId: objThis.get("_arrSelectedItems")[0]._id,
                                    contractId: objThis.get("sContractId"),
                                    contractName: objThis.get("sContractName"),
                                    ruleApplicableTypes: arrRuleApplicableTypes,
                                    agencyCandidateTypes: objThis.get("_arrCandidateTypes"),
                                    status: objThis.get("sContractStatus")
                                }
                            }
                        });
                    },
                    funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                        var arrEntityActions = objThis.get("arrComplianceContractEntityActions");
                        var sRuleLinkedId = objThis.get("arrLinkedRules").find(function (sLinkedRuleId) {
                            return arrSelectedItems[0]._id === sLinkedRuleId;
                        });
                        if (a24Core.isEmpty(sRuleLinkedId)) {
                            for (var l = 0; l < arrEntityActions[0].actions.length; l++) {
                                if (arrEntityActions[0].actions[l].action === "add_rule" && arrEntityActions[0].actions[l].allowed) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    }
                });
                arrSingleActions.push({
                    sTitle: objThis.get("_objProfileStringsService").getString("unlinkRule"),
                    sIconFa: "fa-chain-broken",
                    executeCardAction: function executeCardAction() {
                        var objItem = objThis.get("_arrSelectedItems")[0];
                        if (objItem.is_default) {
                            objThis.set("_bShowUnlinkError", true);
                        } else {
                            objThis.set("_bShowViewComplianceRules", true);
                            objThis.set("_objReactViewPopupPostData", {
                                type: "trigger_route",
                                data: {
                                    route: "agency/complianceContracts/unlinkComplianceRulePopup",
                                    data: {
                                        complianceRuleName: objItem.name,
                                        complianceRuleId: objItem._id,
                                        complianceContractId: objThis.get("sContractId"),
                                        complianceContractName: objThis.get("sContractName"),
                                        complianceContractStatus: objThis.get("sContractStatus")
                                    }
                                }
                            });
                        }
                    },
                    funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                        var arrEntityActions = objThis.get("arrComplianceContractEntityActions");
                        var sRuleLinkedId = objThis.get("arrLinkedRules").find(function (sLinkedRuleId) {
                            return arrSelectedItems[0]._id === sLinkedRuleId;
                        });
                        if (!a24Core.isEmpty(sRuleLinkedId)) {
                            for (var l = 0; l < arrEntityActions[0].actions.length; l++) {
                                if (arrEntityActions[0].actions[l].action === "remove_rule" && arrEntityActions[0].actions[l].allowed) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    }
                });
            }

            this.set("_objDatagridConfig.arrSingleItemSelectActions", arrSingleActions);
        },

        _handleServerErrors: function _handleServerErrors() {
            this.set("_bServerError", true);
            this.set("_bShowLoadFailedPopup", true);
        },

        doServiceCalls: function doServiceCalls() {
            var _this4 = this;

            this.set("_bPageLoading", true);

            var objPromiseCandidateTypes = this.get("objRestCandidateType").getCandidateTypes(this, "_objCandidateTypesResponse", this.get("_objUserSession").getUserContextReference().mId, function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this4._handleServerErrors();
            }));

            var objPromiseEntityActionSection = this.get("_objRestEntityAction").getComplianceRulesEntityActionSection(this, "_objEntityActionSectionPromise", {}, function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{}));

            var objPromiseList = {
                _objCandidateTypes: objPromiseCandidateTypes,
                _objEntityActionSection: objPromiseEntityActionSection
            };

            var arrRequiredResponses = ["_objCandidateTypes", "_objEntityActionSection"];

            var objThis = this;

            Ember.copy(a24RSVP).configure(this, objPromiseList, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    objThis.set("_arrCandidateTypes", []);
                    if (!a24Core.isEmpty(objResponses._objCandidateTypes) && !a24Core.isEmpty(objResponses._objCandidateTypes.objResponse)) {
                        objThis.set("_arrCandidateTypes", objResponses._objCandidateTypes.objResponse);
                    }

                    if (!a24Core.isEmpty(objResponses._objEntityActionSection) && !a24Core.isEmpty(objResponses._objEntityActionSection.objResponse)) {
                        var arrSectionResponse = objResponses._objEntityActionSection.objResponse;
                        for (var i = 0; i < arrSectionResponse.length; i++) {
                            if (arrSectionResponse[i].entity_type === "compliance-rule") {
                                for (var l = 0; l < arrSectionResponse[i].actions.length; l++) {
                                    if (arrSectionResponse[i].actions[l].action === "create" && arrSectionResponse[i].actions[l].allowed) {

                                        objThis.get("_objFabButtonService").showFabButton(function () {
                                            objThis.set("_bShowManageComplianceRules", true);
                                            objThis.set("_objReactPopupPostData", {
                                                type: "trigger_route",
                                                data: {
                                                    route: "agency/complianceRules/managePopup"
                                                }
                                            });
                                        });
                                    }
                                }
                            }
                        }
                    }

                    objThis._setupPageConfig(objThis.get("_arrCandidateTypes"));
                    if (!objThis.get("_bServerError")) {
                        objThis.set("_bServiceCallsDone", true);
                    } else {
                        objThis.set("_bServerError", false);
                    }
                }
            }]);
        },

        _setupPageConfig: function _setupPageConfig(arrCandidateTypes) {
            var _this5 = this;

            var arrYes = [{
                sTitle: this.get("_objStringsService").getString("yes"),
                sValue: this.get("_objStringsService").getString("yes")
            }];

            var arrCandidateTypesDropdown = [];
            for (var i = 0; i < arrCandidateTypes.length; i++) {
                arrCandidateTypesDropdown.push({
                    sTitle: arrCandidateTypes[i].name,
                    sValue: arrCandidateTypes[i]._id
                });
            }
            this.set("_arrCandidateTypesDropdown", arrCandidateTypesDropdown);

            var objThis = this;

            var arrSingleActions = [{
                sTitle: this.get("_objProfileStringsService").getString("view"),
                sIcon: "visibility",
                executeCardAction: function executeCardAction() {
                    objThis._viewComplianceRule(objThis.get("_arrSelectedItems")[0]);
                }
            }];

            if (this.get("_bAgencyGroupContext")) {
                arrSingleActions.push({
                    bLoader: true
                });
            }
            this.set("_arrLoadingActions", arrSingleActions);

            var arrHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objStringsService").getString("name"),
                sProperty: "name",
                sFilterType: "text",
                bText: true,
                bHideEmptyCheck: true,
                iWidth: 20,
                bIsClickable: true
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objStringsService").getString("description"),
                sProperty: "description",
                sFilterType: "text",
                bText: true,
                iWidth: this.get("bLinkedRulesView") ? 30 : 40,
                bStopColumnSort: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objProfileStringsService").getString("candidateType"),
                sProperty: "position_type",
                sFilterType: "dropdownMulti",
                bMapping: true,
                bHideEmptyCheck: true,
                arrMappingData: arrCandidateTypesDropdown,
                bSilent: true,
                iWidth: 10
            }, {
                bCustom: true,
                iUniqueColNum: 4,
                sTitle: this.get("_objProfileStringsService").getString("candidateType"),
                sProperty: "condition",
                sFilterType: "text",
                bStopColumnSort: true,
                bStopFiltering: true,
                iWidth: 30,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    var sCandidateTypes = "";
                    var arrCandidateTypesRow = [];
                    var arrCandidateTypeDisplay = [];
                    if (!a24Core.isEmpty(objRowData.condition)) {
                        var arrRowConditions = objRowData.condition;
                        for (var i = 0; i < arrRowConditions.length; i++) {
                            arrCandidateTypesRow = arrCandidateTypesRow.concat(arrRowConditions[i].applicable_position_types);
                        }

                        var arrCandidateTypesFiltered = arrCandidateTypesRow.filter(function (value, index, self) {
                            return self.indexOf(value) === index;
                        });
                        var bUnsupported = false;
                        for (var l = 0; l < arrCandidateTypesFiltered.length; l++) {
                            var objCandidateType = arrCandidateTypes.find(function (objItem) {
                                return objItem._id === arrCandidateTypesFiltered[l];
                            });
                            if (!a24Core.isEmpty(objCandidateType)) {
                                arrCandidateTypeDisplay.push(objCandidateType.name);
                            } else {
                                bUnsupported = true;
                            }
                        }

                        if (bUnsupported) {
                            arrCandidateTypeDisplay.push("Others");
                        }
                        sCandidateTypes = arrCandidateTypeDisplay.join(", ");
                    }
                    return Ember.String.htmlSafe("<span title=\"" + sCandidateTypes + "\">" + sCandidateTypes + "</span>");
                }
            }, {
                iUniqueColNum: 6,
                sTitle: "Default",
                sProperty: "is_default",
                sFilterType: "bool",
                bHideEmptyCheck: true,
                bStopColumnSort: true,
                bBool: true,
                arrMappingData: [{
                    sValue: "true",
                    sTitle: this.get("_objStringsService").getString("yes")
                }, {
                    sValue: "false",
                    sTitle: this.get("_objStringsService").getString("no")
                }],
                iWidth: 10,
                iMinWidth: 100
            }];

            if (this.get("bLinkedRulesView")) {
                arrHeaderConfig.push({
                    bCustom: true,
                    iUniqueColNum: 5,
                    sTitle: "Linked",
                    sProperty: "_id",
                    bStopColumnSort: true,
                    sFilterType: "dropdown",
                    bMapping: true,
                    bHideEmptyCheck: true,
                    arrMappingData: arrYes,
                    funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                        var objRuleLinked = _this5.get("arrLinkedRules").find(function (sLinkedRuleId) {
                            return sLinkedRuleId === objRowData._id;
                        });
                        if (!a24Core.isEmpty(objRuleLinked)) {
                            return Ember.String.htmlSafe("<i class=\"material-icons\">check</i>");
                        }
                        return Ember.String.htmlSafe("&nbsp;");
                    },
                    funcFilterParseQueryValue: function funcFilterParseQueryValue(objQueryObjectParam) {
                        if (objQueryObjectParam.mValue === "Yes") {
                            if (!a24Core.isEmpty(_this5.get("arrLinkedRules"))) {
                                objQueryObjectParam.mValue = _this5.get("arrLinkedRules").join("|");
                            } else {
                                objQueryObjectParam.mValue = "";
                            }
                            objQueryObjectParam.bEncode = true;
                            objQueryObjectParam.bDelimited = true;
                            return objQueryObjectParam;
                        }
                    },
                    iWidth: 10,
                    iMinWidth: 100
                });
            }

            this.set("_objDatagridConfig", {
                sDatagridHeader: objThis.get("_objProfileStringsService").getString("complianceRules"),
                iMaxHeight: 40 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bHasReloadButton: true,
                bEmitDidDoServiceCall: true,
                bEmitWillDoServiceCall: true,
                arrSingleItemSelectActions: arrSingleActions,
                arrMultiItemSelectActions: [],
                objSavedSearchService: !this.get("bLinkedRulesView") ? this.get("objSavedSearchService") : null,
                sSavedSearchRef: !this.get("bLinkedRulesView") ? this.get("sSavedSearchRef") : null,
                sDatagridRoute: !this.get("bLinkedRulesView") ? this.get("sDatagridRoute") : null,
                arrHeaderConfig: arrHeaderConfig
            });

            if (!objThis.get("bLinkedRulesView")) {
                this.set("_bPageLoading", false);
            }
        },
        _onItemsSelect: function _onItemsSelect(arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedItem", arrSelectedItems[0]);
            } else {
                this.set("_objSelectedItem", null);
            }
            // Set the selected items
            this.set("_arrSelectedItems", arrSelectedItems);
        },

        _triggerQueue: Ember.observer("_arrConvert.@each", function () {
            var _this6 = this;

            if (this.get("_bDatagridReady") && !this.get("_bBusy") && !a24Core.isEmpty(this.get("_arrConvert"))) {
                this.set("_bBusy", true);
                var objConvert = this.get("_arrConvert").objectAt(0);
                this.set("_sSimpleTagsUrl", null); // This is to reset the observe as the below set didnt want to fire the observer
                if (a24Core.isEmpty(objConvert.sUrl)) {
                    this.set("_bForceReset", true);
                }
                Ember.run.next(function () {
                    if (a24Core.isEmpty(_this6)) {
                        return;
                    }
                    _this6.set("_sSimpleTagsUrl", objConvert.sUrl);
                });
                this.set("_iRequestId", objConvert.sRequestId);
            }
        }),

        actions: {
            onForceResetTriggered: function onForceResetTriggered() {
                this.set("_bForceReset", false);
            },
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this._onItemsSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableCell: function cardDatagridTableCell(sColumnName, objRowData) {
                if (sColumnName === "name") {
                    this._viewComplianceRule(objRowData);
                }
            },
            onDidDoServiceCall: function onDidDoServiceCall(arrTableData /*, jqXHR, objPOSTBody*/) {
                var arrRuleIds = [];
                for (var i = 0; i < arrTableData.length; i++) {
                    arrRuleIds.push(arrTableData[i]._id);
                }
                if (this.get("_bAgencyGroupContext")) {
                    this._doEntityActionCall(arrRuleIds);
                }
            },
            onWillDoServiceCall: function onWillDoServiceCall(arrTableData, objUrlConstantPart, objFilterParamObject, objTableParamObject, iPage, iPerPage, objSuccessCallback
            // objFailure
            ) {
                this.set("_bEntityActionPending", true);
                if (this.get("bLinkedRulesView")) {
                    if (!a24Core.isEmpty(objFilterParamObject._id) && a24Core.isEmpty(this.get("arrLinkedRules"))) {
                        // We are just calling the success with empty results since it not worth quering the backend if we know
                        // that result set to be empty. Also we are doing this since the query will be mValue="" which will
                        // remove the query from the api call so it will return everything regardless
                        objSuccessCallback([], null, {
                            getResponseHeader: function getResponseHeader() {
                                return "0";
                            }
                        });
                        return true;
                    }
                }
            },
            onRecieveDataReactPopup: function onRecieveDataReactPopup(objData) {
                var _this7 = this;

                if (objData.type === "app_ready") {
                    this.set("_bPopupLoading", false);
                } else if (objData.type === "close") {
                    this.set("_bShowManageComplianceRules", false);
                } else if (objData.type === "complianceRuleConditionEdit") {
                    this.set("_sSimpleTagsUrl", null); // This is to reset the observe as the below set didnt want to fire the observer
                    this.set("_bShowConditionBuilder", true);
                    this.set("_bPostSimpleTagsOnly", false);
                    Ember.run.next(function () {
                        if (a24Core.isEmpty(_this7)) {
                            return;
                        }
                        if (!a24Core.isEmpty(objData.data.url)) {
                            _this7.set("_sSimpleTagsUrl", objData.data.url);
                        } else {
                            _this7.set("_sSimpleTagsUrl", "");
                        }
                    });
                    if (!a24Core.isEmpty(objData.data.requestId)) {
                        this.set("_iRequestId", objData.data.requestId);
                    }
                    if (objData.data.index > 0) {
                        this.set("_sWarningMessage", this.get("_objProfileStringsService").getString("complianceRulesWarningMessage"));
                    } else {
                        this.set("_sWarningMessage", "");
                    }
                } else if (objData.type === "function") {
                    if (objData.data.type === "convertFilterUrl") {
                        this.set("_bPostSimpleTagsOnly", true);
                        this.get("_arrConvert").pushObject({
                            sUrl: objData.data.data,
                            sRequestId: objData.data.requestId
                        });
                    }
                } else if (objData.type === "refresh") {
                    this.set("_bPageLoading", true);
                    Ember.run.next(function () {
                        if (a24Core.isEmpty(_this7)) {
                            return;
                        }
                        Ember.run.schedule("afterRender", function () {
                            _this7.set("_bPageLoading", false);
                        });
                    });
                }
            },
            onRecieveDataViewReactPopup: function onRecieveDataViewReactPopup(objData) {
                var _this8 = this;

                if (objData.type === "app_ready") {
                    this.set("_bPopupViewLoading", false);
                } else if (objData.type === "close") {
                    this.set("_bShowViewComplianceRules", false);
                    this.set("_objQueuedPostData", null);
                } else if (objData.type === "complianceRuleConditionEdit") {
                    this.set("_sSimpleTagsUrl", null); // This is to reset the observe as the below set didnt want to fire the observer
                    this.set("_bShowConditionBuilder", true);
                    this.set("_bPostSimpleTagsOnly", false);
                    Ember.run.next(function () {
                        if (a24Core.isEmpty(_this8)) {
                            return;
                        }
                        if (!a24Core.isEmpty(objData.data.url)) {
                            _this8.set("_sSimpleTagsUrl", objData.data.url);
                        } else {
                            _this8.set("_sSimpleTagsUrl", "");
                        }
                    });
                    if (!a24Core.isEmpty(objData.data.requestId)) {
                        this.set("_iRequestId", objData.data.requestId);
                    }
                    if (objData.data.index > 0) {
                        this.set("_sWarningMessage", this.get("_objProfileStringsService").getString("complianceRulesWarningMessage"));
                    } else {
                        this.set("_sWarningMessage", "");
                    }
                } else if (objData.type === "function") {
                    if (objData.data.type === "convertFilterUrl") {
                        this.set("_bPostSimpleTagsOnly", true);
                        this.get("_arrConvert").pushObject({
                            sUrl: objData.data.data,
                            sRequestId: objData.data.requestId
                        });
                    }
                } else if (objData.type === "refresh") {
                    if (this.get("bLinkedRulesView")) {
                        // Manually calling the event here since the parent needs to justify what happens here if
                        // we are using this on the compliance contract links page instead of the normal reload
                        if (!a24Core.isEmpty(this.get("onLinkUnlinkComplianceRule"))) {
                            this.get("onLinkUnlinkComplianceRule")();
                        }
                    } else {
                        this.set("_bPageLoading", true);
                        Ember.run.next(function () {
                            if (a24Core.isEmpty(_this8)) {
                                return;
                            }
                            Ember.run.schedule("afterRender", function () {
                                _this8.set("_bPageLoading", false);
                            });
                        });
                    }
                }
            },
            onWillDoServiceCallConditions: function onWillDoServiceCallConditions(objSuccessCallback) {
                if (this.get("_bDatagridReady")) {
                    if (!this.get("_bShowConditionBuilder")) {
                        objSuccessCallback([], null, {
                            getResponseHeader: function getResponseHeader() {
                                return "0";
                            }
                        });
                        return true;
                    }
                } else {
                    this.set("_bDatagridReady", true);
                    this._triggerQueue();
                    if (!this.get("_bAgencyGroupContext")) {
                        return true;
                    }
                }
            },
            onSimpleTagsAction: function onSimpleTagsAction(arrTags) {
                if (this.get("_bPostSimpleTagsOnly")) {
                    var objPostbody = {
                        type: "functionResponse",
                        data: {
                            requestId: this.get("_iRequestId"),
                            data: arrTags
                        }
                    };
                    if (this.get("_bShowViewComplianceRules")) {
                        this.set("_objReactViewPopupPostData", objPostbody);
                    } else if (this.get("_bShowManageComplianceRules")) {
                        this.set("_objReactPopupPostData", objPostbody);
                    }

                    this.set("_bBusy", false);
                    if (!a24Core.isEmpty(this.get("_arrConvert"))) {
                        this.get("_arrConvert").removeAt(0);
                    }
                }
            },
            onPopupCloseAction: function onPopupCloseAction() {
                var objPostbody = {
                    type: "complianceRuleConditionFiltersData"
                };
                if (this.get("_bShowViewComplianceRules")) {
                    this.set("_objReactViewPopupPostData", objPostbody);
                } else if (this.get("_bShowManageComplianceRules")) {
                    this.set("_objReactPopupPostData", objPostbody);
                }

                this.set("_bShowConditionBuilder", false);
            },
            onPopupDoneAction: function onPopupDoneAction(arrTags, sUrl, objQuery) {
                var objJsonQuery = JSON.parse(objQuery);
                var objPostbody = {
                    type: "complianceRuleConditionFiltersData",
                    data: {
                        requestId: this.get("_iRequestId"),
                        url: sUrl,
                        data: objJsonQuery.query,
                        tags: arrTags
                    }
                };
                if (this.get("_bShowViewComplianceRules")) {
                    this.set("_objReactViewPopupPostData", objPostbody);
                } else if (this.get("_bShowManageComplianceRules")) {
                    this.set("_objReactPopupPostData", objPostbody);
                }

                this.set("_bShowConditionBuilder", false);
            },
            onQueryPageReload: function onQueryPageReload(bLoading) {
                this.set("_bPageLoading", bLoading);
            },
            onPageReload: function onPageReload() {
                this.set("_bShowLoadFailedPopup", false);
                this.doServiceCalls();
            },
            onUnlinkErrorClose: function onUnlinkErrorClose() {
                this.set("_bShowUnlinkError", false);
            }
        }
    });
});