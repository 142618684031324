define("a24-ember-staffshift-candidate-availability/components/shift-confirmation-popup", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-candidate-availability/templates/components/shift-confirmation-popup", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _shiftConfirmationPopup, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _shiftConfirmationPopup.default,
        classNames: ["message-preview"],
        bShowPopup: false,
        sShiftDetails: null,
        sCandidateId: null,
        sOrderId: null,
        sShiftId: null,
        sAgencyId: null,
        sAssignmentId: null,
        _bPopupLoading: false,
        _bShowUnsavedData: false,
        _bShowSentMessagePopup: false,
        _objStringsService: Ember.inject.service("a24-strings"),
        _objAvailabilityStrings: Ember.inject.service("a24-strings-staffshift-availability"),
        _objAgencyCandidateNotificationService: Ember.inject.service("rest-agency-candidate-notification"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objSpecialPopupAction: null,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("objChildConfig", {
                "objChildren.content": function objChildrenContent(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 2);
                }
            });

            this.set("_objSpecialPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._popupCancel();
                }
            });

            this.set("_sHeaderPopup", this.get("_objAvailabilityStrings").getString("shiftConfirmation"));

            var objThis = this;
            Ember.run.next(function () {
                Ember.run.scheduleOnce("afterRender", _this, function () {
                    var objTextInput = $("#" + objThis.get("objChildren.content")._sInputId);
                    objTextInput.on("click keyup input paste", function () {
                        objThis.set("sShiftDetails", objTextInput[0].value.trim());
                    });
                });
            });
        },
        _onShowPopup: Ember.observer("bShowPopup", function () {
            var _this2 = this;

            if (this.get("bShowPopup")) {
                this.clearAllErrorMessages();
                this.clearForm();

                this.set("_bPopupLoading", false);
                this.get("objChildren.content").setValueFromOutside(this.get("sShiftDetails"));

                Ember.run.next(function () {
                    Ember.run.schedule("afterRender", function () {
                        _this2.set("_objOriginalData", _this2.getPlainFormValueObject());
                    });
                });
            }
        }),
        _popupCancel: function _popupCancel() {
            if (!this.get("_bPopupLoading")) {
                // Check if the form has any unsaved data
                var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));
                if (bDataMatches) {
                    this.get("onPopupCloseAction")();
                } else {
                    this.set("_bShowUnsavedData", true);
                }
            }
        },
        _getPayload: function _getPayload() {
            var objData = {
                "type": "assignment_confirmation",
                "data": {
                    "content": this.get("objChildren.content.mValue").trim(),
                    "order_id": this.get("sOrderId"),
                    "shift_id": this.get("sShiftId"),
                    "assignment_id": this.get("sAssignmentId")
                }
            };

            return objData;
        },
        actions: {
            onPopupConfirm: function onPopupConfirm() {
                var _this3 = this;

                this.clearAllErrorMessages();
                if (!this.doFullValidation()) {
                    var objData = this._getPayload();
                    this.set("_bPopupLoading", true);
                    this.get("_objAgencyCandidateNotificationService").sendShiftConfirmation(this, "_objAgencyCandidateNotificationPromise", this.get("sAgencyId"), this.get("sCandidateId"), objData, function (objResponse) {
                        if (!a24Core.isEmpty(objResponse)) {
                            _this3.get("onPopupCloseAction")();
                            _this3.set("_bShowSentMessagePopup", true);
                        }
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        _this3.set("_bPopupLoading", false);
                        _this3.get("_objSnackbarService").showSnackbarMessage(_this3.get("_objAvailabilityStrings").getString("notificationSendFailed"));
                    }));
                }
            },
            onPopupCancel: function onPopupCancel() {
                this._popupCancel();
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowUnsavedData", false);
                this.get("onPopupCloseAction")();
            }
        }
    });
});