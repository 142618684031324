define("a24-ember-lib/components/line-item-component", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/templates/components/line-item-component"], function (exports, _didRenderChangesMixin, _lineItemComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _lineItemComponent.default,
        tagName: "li",
        classNameBindings: ["sCustomClassName"],
        sCustomClassName: null,
        sOptionsLabel: null,
        sOptionsLink: null,
        arrActions: null,
        // the tooltip text
        sToolTipText: null,
        // the tooltip position
        sToolTipPosition: "bottom",
        sToolTipClass: "a24-tooltip",
        funcGetTooltipElementCallback: null,
        _objToolTipService: Ember.inject.service("tooltip"),
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            Ember.run.schedule("afterRender", function () {
                var objElement = _this.$();
                var sToolTipText = _this.get("sToolTipText");
                var sToolTipPosition = _this.get("sToolTipPosition");
                var sToolTipClass = _this.get("sToolTipClass");
                var funcGetTooltipElementCallback = _this.get("funcGetTooltipElementCallback");
                _this.get("_objToolTipService").addTooltip(objElement, sToolTipText, sToolTipPosition, sToolTipClass, funcGetTooltipElementCallback);

                _this.$().on("mousedown", function () {
                    if (!a24Core.isEmpty(_this.get("setSelectedItemAction"))) {
                        return _this.get("setSelectedItemAction")();
                    }
                });
            });
        },
        observeTooltip: Ember.observer("sToolTipText", function () {
            var objElement = this.$();
            this.get("_objToolTipService").disposeOfTooltip(objElement);

            var sToolTipText = this.get("sToolTipText");
            var sToolTipPosition = this.get("sToolTipPosition");
            var sToolTipClass = this.get("sToolTipClass");
            var funcGetTooltipElementCallback = this.get("funcGetTooltipElementCallback");
            this.get("_objToolTipService").addTooltip(objElement, sToolTipText, sToolTipPosition, sToolTipClass, funcGetTooltipElementCallback);
        }),
        willDestroyElement: function willDestroyElement() {
            var objElement = this.$();
            this.get("_objToolTipService").disposeOfTooltip(objElement);
        },
        actions: {
            onActionClick: function onActionClick() {
                if (!a24Core.isEmpty(this.get("onActionClick"))) {
                    return this.get("onActionClick")();
                }
            },
            onLinkAction: function onLinkAction(objEvent) {
                if (!a24Core.isEmpty(this.get("onLinkAction"))) {
                    return this.get("onLinkAction")(this.get("sOptionsLink"), objEvent);
                }
            }
        }
    });
});