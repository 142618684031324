define("a24-ember-lib/services/component-sort", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        filterAndSortComponents: function filterAndSortComponents(arrComponents, arrGroupings, sFilterProperty, sFilterValue, sInnerSortProperty) {
            var arrComponentsFiltered = null;

            if (!a24Core.isEmpty(sFilterProperty) && !a24Core.isEmpty(sFilterValue)) {
                arrComponentsFiltered = arrComponents.filter(function (objComponent) {
                    return Ember.get(objComponent, sFilterProperty).toLowerCase().indexOf(sFilterValue.toLowerCase()) !== -1;
                });
            } else {
                arrComponentsFiltered = arrComponents;
            }

            var arrGroups = [];
            var iGroupingsLength = arrGroupings.length;

            for (var i = 0; i < iGroupingsLength; i++) {
                var objGroup = arrGroupings[i];

                var arrItems = arrComponentsFiltered.filter(function (objComponent) {
                    return objGroup.funcGroupFilter(objComponent);
                });

                if (!a24Core.isEmpty(arrItems)) {
                    arrGroups.push({
                        sTitle: objGroup.sTitle,
                        arrItems: arrItems.sortBy(sInnerSortProperty)
                    });
                }
            }

            return arrGroups;
        }
    });
});