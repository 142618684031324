define("a24-ember-lib/components/input-form-tag", ["exports", "a24-ember-lib/templates/components/input-form-tag", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _inputFormTag, _inputFormElement, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _inputFormTag.default,
        classNames: ["a24ErrorInputParent"],
        sInputType: "tag",
        arrTags: null,
        sValueKey: "sValue",
        sIdKey: "sId",
        sPlaceholderSecondary: null,
        _sChipStyle: null,
        _bRendered: false,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            Ember.run.scheduleOnce("afterRender", function () {
                //Set default values
                if (a24Core.isEmpty(_this.get("arrTags"))) {
                    _this.set("arrTags", []);
                } else {
                    _this._updateInputValuesFromTags(false);
                }

                _this.set("_sChipStyle", "chips");
                var objChips = $("#" + _this.get("_sInputId"));
                var arrData = _this._updateMaterialChipValues(true);

                objChips.material_chip({
                    data: arrData,
                    placeholder: _this.get("sPlaceholderSecondary"),
                    secondaryPlaceholder: _this.get("sPlaceholder") //This seems to be the primary placeholder
                });
                objChips.on("chip.add", function () {
                    _this._updateInputValuesFromMDBTags(objChips);
                });
                objChips.on("chip.delete", function () {
                    _this._updateInputValuesFromMDBTags(objChips);
                });

                objChips.find("input").on("keyup", function (objEvent) {
                    if (objEvent.which === 27) {
                        // Escape key
                        objChips.find("input").blur();
                    }
                });

                _this.set("_bRendered", true);
            });
        },
        _updateInputValuesFromMDBTags: function _updateInputValuesFromMDBTags(objChips) {
            var arrTagData = objChips.material_chip("data");

            var arrData = [];
            for (var i = 0; i < arrTagData.length; i++) {
                var objTag = {};
                objTag[this.get("sValueKey")] = arrTagData[i].tag;
                if (!a24Core.isEmpty(arrTagData[i].id)) {
                    objTag[this.get("sIdKey")] = arrTagData[i].id;
                }
                arrData.push(objTag);
            }

            this.setMValueWithInternalFlag(arrData, false);
        },
        _updateInputValuesFromTags: function _updateInputValuesFromTags(bBuild) {
            var arrData = [];
            for (var i = 0; i < this.get("arrTags").length; i++) {
                var objTag = {};
                objTag[this.get("sValueKey")] = this.get("arrTags." + i + "." + this.get("sValueKey"));
                if (!a24Core.isEmpty(this.get("arrTags." + i + "." + this.get("sIdKey")))) {
                    objTag[this.get("sIdKey")] = this.get("arrTags." + i + "." + this.get("sIdKey"));
                }
                arrData.push(objTag);
            }

            this.setMValueWithInternalFlag(arrData, !bBuild);
        },
        _updateMaterialChipValues: function _updateMaterialChipValues(bReturnData) {
            var arrData = [];
            if (!a24Core.isEmpty(this.get("arrTags"))) {
                for (var i = 0; i < this.get("arrTags").length; i++) {

                    var objTag = {
                        tag: this.get("arrTags." + i + "." + this.get("sValueKey"))
                    };

                    if (!a24Core.isEmpty(this.get("arrTags." + i + "." + this.get("sIdKey")))) {
                        objTag.id = this.get("arrTags." + i + "." + this.get("sIdKey"));
                    }
                    arrData.push(objTag);
                }
            }

            if (bReturnData) {
                return arrData;
            } else {
                //Reload the new data into the tag input
                $("#" + this.get("_sInputId")).material_chip(arrData);
                this._updateInputValuesFromTags(true);
            }
        },
        clear: function clear() {
            var arrData = [];
            this.setMValueWithInternalFlag(null, false);
            $("#" + this.get("_sInputId")).material_chip(arrData);
        },
        observeArrTags: Ember.observer("arrTags.@each", function () {
            if (this.get("_bRendered")) {
                Ember.run.once(this, this._updateMaterialChipValues, false);
            }
        })
    });
});