define("a24-ember-staffshift-candidate-availability/components/availability-card", ["exports", "a24-ember-staffshift-candidate-availability/templates/components/availability-card", "a24-ember-lib/components/card-style-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _availabilityCard, _cardStyleComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _cardStyleComponent.default.extend(_didRenderChangesMixin.default, {
        /*
          Services.
        */
        objShiftEmail: Ember.inject.service("shift-email"),
        objRetrieveShiftPdf: Ember.inject.service("retrieve-shift-pdf"),
        _objUserSession: Ember.inject.service("user-session"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objAvailabilityStrings: Ember.inject.service("a24-strings-staffshift-availability"),
        _objConstantsEmberLibService: Ember.inject.service("a24-constants-ember-lib"),
        objEmailServicePromise: null,

        /*
          Default values
        */
        layout: _availabilityCard.default,
        classNames: ["availability-card", "p-3"],

        /*
          Store the default icon size for the header.
        */
        sIconSizeClass: null,

        /*
          @property arrData
          An array of objects each specifying the information to display on the card.
        */
        arrData: null,

        /*
          @property sImageUrl
          The header left image url.
        */
        sImageUrl: null,

        /*
          @property sHeader
          The header displayed.
        */
        sHeader: null,

        /*
          @property sSubHeader
          The sSubHeader displayed.
        */
        sSubHeader: null,

        /*
          @property sEmail
          The email to send shift information to.
        */
        sEmail: null,

        /*
          @property sShiftId
          The shift ID.
        */
        sShiftId: null,

        /*
          @property bShowEmailPopup
          Used to determine if the email popup should be shown.
        */
        bShowEmailPopup: false,

        /*
          @property bSendingEmail
          True if shift information is currently being sent to the provided `sEmail` value.
        */
        bSendingEmail: false,

        /*
          Inline computed properties
        */
        bHasImage: Ember.computed.bool("sImageUrl").readOnly(),
        bHasSubHeader: Ember.computed.bool("sSubHeader").readOnly(),

        /*
          Multiline computed properties
        */
        /*
          @computed arrActions
          An array of objects each specifying their google material icon name and an action that is invoked when the icon is clicked on.
        */
        arrActions: Ember.computed(function () {
            var objThis = this;

            return [{
                sIcon: "email",
                executeCardAction: function executeCardAction() {
                    objThis._showEmailPopup();
                }
            }, {
                sIcon: "print",
                executeCardAction: function executeCardAction() {
                    objThis._downloadPDF();
                }
            }];
        }).readOnly(),

        init: function init() {
            this._super.apply(this, arguments);

            if (a24Core.isEmpty(this.get("sIconSizeClass"))) {
                this.set("sIconSizeClass", this.get("_objConstantsEmberLibService").ROUND_ICON_CONTENT_SIZE_SMALL);
            }
        },

        /*
          Private functions
        */
        _showEmailPopup: function _showEmailPopup() {
            // Set the candidate email
            Ember.set(this, "sEmail", Ember.get(this, "_objUserSession").getUserEmail());

            Ember.set(this, "bShowEmailPopup", true);
        },


        /*
          Sends request to download shift pdf
        */
        _downloadPDF: function _downloadPDF() {
            var _this = this;

            var sShiftId = Ember.get(this, "sShiftId");
            Ember.get(this, "_objSnackbarService").showSnackbarMessage(Ember.get(this, "_objAvailabilityStrings").getString("downloadingTimesheet"));
            Ember.get(this, "objRetrieveShiftPdf").downloadCandidateShift(sShiftId, sShiftId + ".pdf", function () {
                Ember.get(_this, "_objSnackbarService").showSnackbarMessage(Ember.get(_this, "_objAvailabilityStrings").getString("successfullyDownloadedTimesheet"));
            }, function () {
                Ember.get(_this, "_objSnackbarService").showSnackbarMessage(Ember.get(_this, "_objAvailabilityStrings").getString("failedDownloadedTimesheet"));
            }, null, {
                Authorization: "Bearer " + this.get("_objUserSession.objAuthData.accessToken"),
                Context: "Candidate"
            });
        },


        actions: {
            /*
              Gets called after the user clicks SEND on popup shift email request
            */
            emailShiftConfirm: function emailShiftConfirm() {
                var _this2 = this;

                var sShiftId = Ember.get(this, "sShiftId");
                var sEmailAddress = Ember.get(this, "sEmail");

                Ember.set(this, "bSendingEmail", true);

                Ember.get(this, "objShiftEmail").postShiftEmail(this, "objEmailServicePromise", sEmailAddress, sShiftId, function () {
                    Ember.set(_this2, "bShowEmailPopup", false);
                    Ember.set(_this2, "bSendingEmail", false);
                    Ember.get(_this2, "_objSnackbarService").showSnackbarMessage(Ember.get(_this2, "_objAvailabilityStrings").getString("emailSentSuccess"));
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*CODE"], function () {
                    Ember.set(_this2, "bShowEmailPopup", false);
                    Ember.set(_this2, "bSendingEmail", false);
                    Ember.get(_this2, "_objSnackbarService").showSnackbarMessage(Ember.get(_this2, "_objAvailabilityStrings").getString("emailSentFailure"));
                }));
            },
            /*
              Gets called after the user clicks CANCEL on popup shift email request
            */
            emailShiftCancel: function emailShiftCancel() {
                Ember.set(this, "bShowEmailPopup", false);
            }
        }
    });
});