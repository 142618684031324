define("a24-ember-staffshift-core/components/page-access-denied", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/page-access-denied"], function (exports, _didRenderChangesMixin, _pageAccessDenied) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _pageAccessDenied.default,
        classNames: ["page-access-denied"],
        _objStatusErrorPageService: Ember.inject.service("status-error-page"),
        _objSSCoreStringsService: Ember.inject.service("a24-strings-staffshift-core"),
        _objUserSession: Ember.inject.service("user-session"),

        _bHasSession: false,

        init: function init() {
            this._super.apply(this, arguments);
            this.set("_bHasSession", !a24Core.isEmpty(this.get("_objUserSession.objAuthData")));
            this.set("_objStatusErrorPageService.sIcon", "warning");
            this.set("_objStatusErrorPageService.sMessage", this.get("_objSSCoreStringsService").getString("contextWrongIp"));
        }
    });
});