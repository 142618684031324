define("a24-ember-staffshift-core/components/notification-sent-popup", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/notification-sent-popup"], function (exports, _didRenderChangesMixin, _notificationSentPopup) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _notificationSentPopup.default,
        bShowPopup: null,
        bPopupLoading: null,
        onPopupCloseAction: null,
        objActivityData: null,
        objRestActivityNotification: null,

        _bShowNotifcationPreviewPopup: null,
        _bNotificationPreviewPopupLoading: null,
        _objNotificationPreviewSpecialPopupAction: null,
        _objSpecialPopupAction: null,
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSSCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objStaffshiftCoreEnums: Ember.inject.service("a24-enums-staffshift-core"),
        _objCurrentNotificationSentCache: null,
        _bIsEmail: null,
        _objNotificationPreview: null,
        _arrNotificationPreviewPopupActions: null,
        _objEmailPreviewGeneratorService: Ember.inject.service("email-preview-generator"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _arrNotificationsSentResponse: null,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            // Create an action that will open the conversation preview in a new tab
            this.set("_objSpecialPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.get("onPopupCloseAction")();
                }
            });

            // Create an action that will close the preview popup
            this.set("_objNotificationPreviewSpecialPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.set("_bShowNotifcationPreviewPopup", false);
                }
            });
        },
        _onActivityDataChange: Ember.observer("objActivityData", function () {
            var objActivityData = this.get("objActivityData");
            this.set("sSubjectId", objActivityData.sSubjectId);
            this.set("sSubjectType", objActivityData.sSubjectType);
            this.notifcationFromActivity(objActivityData.sSubjectId, objActivityData.sActivityId, objActivityData.sLinkedEntityId, objActivityData.sSubjectType);
        }),
        notifcationFromActivity: function notifcationFromActivity(sSubjectId, sActivityId, sLinkedEntityId, sSubjectType) {
            var _this2 = this;

            var objThis = this;

            // show notifications sent and delivery status
            this.set("bPopupLoading", true);
            this.set("_bOptOut", false);
            this.get("objRestActivityNotification").getActivityNotification(this, "_objActivityNotificationPreviewPromise", sSubjectId, sSubjectType, sActivityId, sLinkedEntityId, function (objResponse) {
                if (!a24Core.isEmpty(objResponse) && !a24Core.isEmpty(objResponse.notifications_sent)) {
                    // reset cache
                    _this2.set("_objCurrentNotificationSentCache", {});
                    // save notifications sent and delivery status
                    var arrNotificationsSent = [];

                    var _loop = function _loop(i) {
                        var objData = {};
                        var sMedium = objResponse.notifications_sent[i].medium;
                        var sMediumType = sMedium;
                        var objMediumTypeEnum = _this2.get("_objStaffshiftCoreEnums").getFrontendValue("mediumType", sMedium, null, true);
                        var sStatus = _this2.get("_objStaffshiftCoreEnums").getFrontendValue("notificationStatus", objResponse.notifications_sent[i].status);
                        var sIcon = "texture";
                        var sIconFa = null;
                        var sIconSvg = null;
                        if (!a24Core.isEmpty(objMediumTypeEnum)) {
                            sMediumType = objMediumTypeEnum.sLabel;
                            sIcon = objMediumTypeEnum.icon;
                            sIconFa = objMediumTypeEnum.icon_fa;
                            sIconSvg = objMediumTypeEnum.icon_svg;
                        }
                        if (a24Core.isEmpty(sStatus)) {
                            sStatus = objResponse.notifications_sent[i].status;
                        }

                        var sNotificationSentId = objResponse.notifications_sent[i].id;

                        objData.activity_id = sActivityId;
                        objData.medium = sMediumType;
                        objData.backend_medium = sMedium;
                        objData.status = sStatus;
                        objData.icon = sIcon;
                        objData.icon_fa = sIconFa;
                        objData.icon_svg = sIconSvg;
                        objData.entity_id = sLinkedEntityId;
                        objData.notifications_sent_id = sNotificationSentId;
                        objData.arrActions = [];
                        objData.arrActions.push({
                            sTitle: objThis.get("_objSSCoreStrings").getString("viewNotificationPreview"),
                            sIcon: "visibility",
                            executeCardAction: function executeCardAction() {
                                objThis.activityNotificationFromMedium(sActivityId, sLinkedEntityId, sMedium, sNotificationSentId);
                            }
                        });
                        arrNotificationsSent.push(objData);
                    };

                    for (var i = 0; i < objResponse.notifications_sent.length; i++) {
                        _loop(i);
                    }

                    _this2.set("_arrNotificationsSentResponse", arrNotificationsSent);

                    _this2.set("bPopupLoading", false);
                } else {
                    if (objResponse.status === _this2.get("_objStaffshiftCoreEnums.notificationStatus.OPT_OUT.backend")) {
                        _this2.set("_bOptOut", true);
                        _this2.set("bPopupLoading", false);
                        _this2.set("_arrNotificationsSentResponse", []);
                    } else {
                        if (!a24Core.isEmpty(_this2.get("onPopupCloseAction"))) {
                            _this2.get("onPopupCloseAction")();
                        }
                        _this2.set("bPopupLoading", false);

                        _this2.get("_objSnackbarService").showSnackbarMessage(_this2.get("_objSSCoreStrings").getString("couldNotLoadNotificationSentInformation"));
                    }
                }
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                if (!a24Core.isEmpty(_this2.get("onPopupCloseAction"))) {
                    _this2.get("onPopupCloseAction")();
                }
                _this2.set("bPopupLoading", false);

                _this2.get("_objSnackbarService").showSnackbarMessage(_this2.get("_objSSCoreStrings").getString("couldNotLoadNotificationSentInformation"));
            }));
        },

        activityNotificationFromMedium: function activityNotificationFromMedium(sActivityId, sLinkedEntityId, sMedium, sNotificationSentId) {
            var _this3 = this;

            var funcSuccess = function funcSuccess(objData) {
                _this3.set("_objCurrentNotificationSentCache." + sNotificationSentId, objData);
                // On success
                if (sMedium === _this3.get("_objStaffshiftCoreEnums").getBackendValue("mediumType", _this3.get("_objStringsService").getString("email"))) {
                    _this3.set("_bIsEmail", true);

                    var sSubject = "";
                    var objSender = null;
                    var objRecipient = null;
                    var objReplyTo = null;
                    var sBody = "";
                    var sStatus = "";
                    var sStatusReason = "";
                    if (!a24Core.isEmpty(objData.data)) {
                        if (!a24Core.isEmpty(objData.data.subject)) {
                            sSubject = objData.data.subject;
                        }
                        if (!a24Core.isEmpty(objData.data.sender)) {
                            objSender = objData.data.sender;
                        }
                        if (!a24Core.isEmpty(objData.data.to_recipient)) {
                            objRecipient = objData.data.to_recipient;
                        }
                        if (!a24Core.isEmpty(objData.data.reply_to)) {
                            objReplyTo = objData.data.reply_to;
                        }
                        if (!a24Core.isEmpty(objData.data.body_html)) {
                            sBody = objData.data.body_html;
                        }
                        if (!a24Core.isEmpty(objData.status_reason)) {
                            sStatusReason = objData.status_reason;
                        }
                    }
                    if (!a24Core.isEmpty(objData.status)) {
                        if (objData.status === _this3.get("_objStaffshiftCoreEnums.notificationStatus.OPT_OUT.backend")) {
                            sBody = _this3.get("_objSSCoreStrings").getString("failedToGenerateNotificationPreviewOptOut");
                        }
                        sStatus = _this3.get("_objStaffshiftCoreEnums").getFrontendValue("notificationStatus", objData.status);
                    }

                    _this3.set("_objNotificationPreview", {
                        sSubject: sSubject,
                        objFrom: objSender,
                        objTo: objRecipient,
                        objReplyTo: objReplyTo,
                        sHTML: sBody,
                        sDeliveryStatus: sStatus
                    });

                    if (!a24Core.isEmpty(sStatusReason)) {
                        _this3.set("_objNotificationPreview.sDeliveryFailureReason", sStatusReason);
                    }

                    _this3.set("_arrNotificationPreviewPopupActions", [{
                        sTitle: _this3.get("_objSSCoreStrings").getString("openInNewTab"),
                        sIcon: "open_in_new",
                        executeCardAction: function executeCardAction() {
                            var objNotificationPreview = _this3.get("_objNotificationPreview");
                            // Generate the email html from details
                            var sHtml = _this3.get("_objEmailPreviewGeneratorService").generateFullHtmlWithHTMLWrappingFromEmailObject(objNotificationPreview);
                            _this3.openHtmlInNewWindow(sHtml);
                        }
                    }]);
                } else {
                    _this3.set("_bIsEmail", false);
                    _this3.set("_arrNotificationPreviewPopupActions", null);
                    _this3.set("_objNotificationPreview", objData);
                }

                // Take the popup out of loading
                _this3.set("_bNotificationPreviewPopupLoading", false);
            };

            this.set("_bShowNotifcationPreviewPopup", true);
            this.set("_bNotificationPreviewPopupLoading", true);

            // Use the stored response if it is set
            var objStoredNotifciationResponse = this.get("_objCurrentNotificationSentCache." + sNotificationSentId);

            if (!a24Core.isEmpty(objStoredNotifciationResponse)) {
                funcSuccess(objStoredNotifciationResponse);
                return;
            }

            this._getActivityNotificationFromMedium(sActivityId, sLinkedEntityId, sMedium, sNotificationSentId, funcSuccess);
        },

        _getActivityNotificationFromMedium: function _getActivityNotificationFromMedium(sActivityId, sLinkedEntityId, sMedium, sNotificationSentId, funcSuccess) {
            var _this4 = this;

            this.get("objRestActivityNotification").getActivityNotificationFromMedium(this, "_objActivityNotificationMediumPreviewPromise", this.get("sSubjectId"), this.get("sSubjectType"), sActivityId, sLinkedEntityId, sMedium, sNotificationSentId, funcSuccess, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this4.set("_bShowNotifcationPreviewPopup", false);
                _this4.set("_bNotificationPreviewPopupLoading", false);

                _this4.get("_objSnackbarService").showSnackbarMessage(_this4.get("_objSSCoreStrings").getString("couldNotLoadNotificationPreview"));
            }));
        },
        openHtmlInNewWindow: function openHtmlInNewWindow(sHtml) {
            // Open the html in a new window
            var objNewWindow = window.open();

            if (objNewWindow !== null) {
                objNewWindow.document.write(sHtml);
                objNewWindow.document.close();
                objNewWindow.focus();
            }
        },
        actions: {
            onPopupClose: function onPopupClose() {
                if (!a24Core.isEmpty(this.get("onPopupCloseAction"))) {
                    this.get("onPopupCloseAction")();
                }
            },
            onNotificationPreviewPopupClose: function onNotificationPreviewPopupClose() {
                this.set("_bShowNotifcationPreviewPopup", false);
            },
            onNotificationSentClick: function onNotificationSentClick(objData) {
                var sActivityId = objData.activity_id;
                var sLinkedEntityId = objData.entity_id;
                var sMedium = objData.backend_medium;
                var sNotificationSentId = objData.notifications_sent_id;

                this.activityNotificationFromMedium(sActivityId, sLinkedEntityId, sMedium, sNotificationSentId);
            }
        }
    });
});