define("a24-ember-staffshift-users/services/a24-strings-staffshift-users", ["exports", "a24-ember-lookup/services/a24-strings-base"], function (exports, _a24StringsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24StringsBase.default.extend({
        _objStrings: null,
        init: function init() {
            this._super.apply(this, arguments);
            this.set("_objStrings", {
                //===== STRINGS START =====

                admin: "Admin",
                reporter: "Reporter",
                booker: "Booker",
                owner: "Owner",
                manager: "Manager",
                candidate: "Candidate",
                account: "Account",
                vacancyManager: "Vacancy Manager",
                compliance: "Compliance",
                dataProcessor: "Data Processor",
                enabled: "Enabled",
                addUser: "Add User",
                listGroups: "List Groups",
                listUsers: "List Users",
                viewGroup: "View Group",
                assignedUsers: "Assigned Users",
                mainGroupDetails: "Main Group Details",
                groupName: "Group Name",
                role: "Role",
                selectRole: "Select Role",
                createAclGroup: "Add group",
                editAclGroup: "Edit group",
                creatingAclGroup: "Creating group...",
                editingAclGroup: "Editing group...",
                createAclGroupSuccess: "Successfully created group",
                editAclGroupSuccess: "Successfully edited group",
                createAclGroupFailed: "Failed to create group",
                aclConflictError: "The Group name you are trying to use already belongs to another group",
                editAclGroupFailed: "Failed to edit group",

                confirmDeleteUser: "Are you sure you want to remove this user?",

                userRemovedSuccess: "User successfully removed",
                userRemovedFailure: "An error occurred removing the user",
                userAddSuccess: "User added successfully",
                userAddFailure: "An error occurred adding the user",
                addingUser: "Adding user...",

                statuses: "Statuses",
                selectStatus: "Select Status",

                userIpwhitelists: "IP and User Whitelist",
                addIpAddress: "Add IP Address",
                ipAddress: "IP Address",
                confirmDeleteIpAddress: "Are you sure you want to remove this IP Address?",
                editIpAddress: "Edit IP Address",
                ipWhitelist: "IP Whitelist",
                user: "User",
                userWhitelist: "User Whitelist",
                validationIpAlreadyExist: "IP already exists",
                validationUserAlreadyExist: "User already exists",

                deleteUserWhitelistWarning: "Logged in as: {0}.<br/><br/>You are currently logged in as the user you are trying to remove, are you sure you want to remove this user?<br/><br/><b>Note by doing this you might not be able to access this context anymore.</b>",
                deleteIpWhitelistWarning: "IP address is: {0}.<br/><br/>You are currently connected from the IP address you are trying to remove, are you sure you want to remove this entry?<br/><br/><b>Note by doing this you might not be able to access this context anymore.</b>"

                //===== STRINGS END   =====
            });
        }
    });
});