define("a24-ember-lib/mixins/react-datagrid-query-param-builder", ["exports", "a24-ember-lib/mixins/query-param-object-builder"], function (exports, _queryParamObjectBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        queryParams: ["datagrid_params"],
        datagrid_params: ""
    }, _queryParamObjectBuilder.default);
});