define("a24-ember-staffshift-core/components/coming-soon-card", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/coming-soon-card"], function (exports, _didRenderChangesMixin, _comingSoonCard) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _comingSoonCard.default,
        bLoading: false
    });
});