define("a24-ember-candidate-profile/services/rest-agency-group-candidate-notification", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),
        sRestApiBaseUrl: Ember.computed.readOnly("_objEnv.sRestApiBaseUrl"),

        /**
         * Route for previewing an agency group notification of a candidate
         *
         * @param objContext - The caller of this function
         * @param sProperty - The property name to get the promise object
         * @param sCandidateId - The candidate id to fetch preview for
         * @param sNotificationId - The notification id
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  7 March 2019
         */
        previewNotification: function previewNotification(objContext, sProperty, sCandidateId, sNotificationId, funcSuccess, objFailure) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency-group/" + sAgencyGroupId + "/candidate/" + sCandidateId + "/notification/" + sNotificationId + "/preview");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", this.get("sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Route for sending an agency group notification to a candidate
         *
         * @param objContext - The caller of this function
         * @param sProperty - The property name to get the promise object
         * @param sCandidateId - The candidate id of candidate to send to
         * @param objData - The data for the notification
         * @param funcSuccess - The success function
         * @param objFailure - The object that will deal with failed service calls
          * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  7 March 2019
         */
        sendNotification: function sendNotification(objContext, sProperty, sCandidateId, objData, funcSuccess, objFailure) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency-group/" + sAgencyGroupId + "/candidate/" + sCandidateId + "/notifications");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", this.get("sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        }
    });
});