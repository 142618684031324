define("a24-ember-policy-library/components/candidate-policy", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-policy-library/templates/components/candidate-policy"], function (exports, _didRenderChangesMixin, _candidatePolicy) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _candidatePolicy.default,
        classNames: ["candidate-policy"],
        sCandidateId: null,
        objPolicyGroupService: null,
        _bLoading: false,
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objBreadcrumb: Ember.inject.service("bread-crumb"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objPolicyStringsService: Ember.inject.service("a24-strings-policy-library"),
        _objPolicyEnumsService: Ember.inject.service("a24-enums-policy-library"),
        _objPolicyRestService: Ember.inject.service("rest-agency-group-policy"),
        _objAgencyGroupCandidatePolicyRestService: Ember.inject.service("rest-agency-group-candidate-policy-type"),
        _objConstantsEmberLibService: Ember.inject.service("a24-constants-ember-lib"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objRestCandidatePolicyGroupServiceProperty: null,
        _arrResponseData: null,
        _arrItems: null,
        _sEmptyPoliciesMessage: null,
        _arrFilterConfig: null,
        _sHeaderPostfix: "",
        _objQueryParams: null,
        init: function init() {
            this._super.apply(this, arguments);

            // Call the breadcrumb item change
            this.onBreadCrumbItemChange();
            this.observeObjPolicyGroupService();
        },
        _bIsAgencyGroup: Ember.computed("objPolicyGroupService.sContext", function () {
            return this.get("objPolicyGroupService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP");
        }),
        onBreadCrumbItemChange: Ember.observer("_objBreadcrumb.arrBreadCrumbItems", function () {
            var arrBreadCrumbItems = this.get("_objBreadcrumb.arrBreadCrumbItems");
            if (!a24Core.isEmpty(arrBreadCrumbItems) && !this.get("_bIsAgencyGroup")) {
                this.set("_sHeaderPostfix", " - " + arrBreadCrumbItems[arrBreadCrumbItems.length - 1].sTitle);
            }
        }),
        observeObjPolicyGroupService: Ember.observer("objPolicyGroupService", function () {
            //Only do the service call if the current instance is not busy destroying
            if (!a24Core.isEmpty(this)) {
                this.setupPage();
            }
        }),
        setupPage: function setupPage() {
            this.set("_arrFilterConfig", [{
                sTitle: this.get("_objPolicyStringsService").getString("policies"),
                sProperty: "action",
                sFilterType: "dropdown",
                arrMappingData: this.get("_objPolicyEnumsService").getList("policies").map(function (objEnum) {
                    return {
                        sTitle: objEnum.sLabel,
                        sValue: objEnum.sValue
                    };
                }),
                bHideEmptyCheck: true,
                bMapping: true,
                sFilterValue: this.get("_objPolicyEnumsService.policies.NOT_ACKNOWLEDGED.backend")
            }]);

            this.set("_objQueryParams", {
                action: {
                    bEncode: true,
                    mValue: this.get("_objPolicyEnumsService.policies.NOT_ACKNOWLEDGED.backend")
                }
            });

            this.doServiceCall();
        },
        setEmptyPoliciesMessage: function setEmptyPoliciesMessage() {
            var objQueryParams = this.get("_objQueryParams");
            if (!a24Core.isEmpty(objQueryParams.action)) {
                this.set("_sEmptyPoliciesMessage", this.get("_objPolicyStringsService").getString("noPoliciesMatchFilterCriteria"));
            } else {
                this.set("_sEmptyPoliciesMessage", this.get("_objPolicyStringsService").getString("noPoliciesAvailable"));
            }
        },
        doServiceCall: function doServiceCall() {
            var _this = this;

            var objSort = {
                policy_type_title: {
                    mValue: "asc",
                    bSortBy: true
                },
                items_per_page: {
                    mValue: 1000
                }
            };

            // Set loading true
            this.set("_bLoading", true);

            if (this.get("objPolicyGroupService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE")) {
                this.get("objPolicyGroupService").getPolicyGroupDetails(this, "_objRestCandidatePolicyGroupServiceProperty", this.get("_objNavigationService").get("objRouteParams")["candidate.policy-group-list"].policy_group_type, this.get("_objNavigationService").get("objRouteParams")["candidate.policy-group-list"].policy_group_id, function (arrData) {
                    _this.set("_arrResponseData", arrData);
                    _this.parseResponseData();
                }, null, objSort);
            } else {
                this.get("objPolicyGroupService").getPolicyGroupDetails(this, "_objRestCandidatePolicyGroupServiceProperty", this.get("sCandidateId"), function (arrData) {
                    _this.set("_arrResponseData", arrData);
                    _this.parseResponseData();
                }, null, objSort);
            }
        },
        parseResponseData: function parseResponseData() {
            var arrData = this.get("_arrResponseData");
            var objQuery = this.get("_objQueryParams");

            var arrFinalData = [];
            if (!a24Core.isEmpty(arrData)) {
                for (var i = 0; i < arrData.length; i++) {
                    var objItem = arrData[i];
                    if (a24Core.isEmpty(objQuery.action) || objQuery.action.mValue === this.get("_objPolicyEnumsService.policies.READ_ONLY.backend") && objItem.policy_type_classification === "viewable" || objQuery.action.mValue === this.get("_objPolicyEnumsService.policies.ACKNOWLEDGED.backend") && objItem.policy_type_classification === "acknowledgeable" && !a24Core.isEmpty(objItem.acknowledged_policy) || objQuery.action.mValue === this.get("_objPolicyEnumsService.policies.NOT_ACKNOWLEDGED.backend") && objItem.policy_type_classification === "acknowledgeable" && !a24Core.isEmpty(objItem.latest_policy)) {
                        //These are all fine and allowed to be shown
                    } else {
                        //This means the item is filtered out.
                        continue;
                    }

                    var objFormattedItem = {};

                    if (!a24Core.isEmpty(objItem.latest_policy)) {
                        // Latest Policy is present
                        objFormattedItem.sTitle = objItem.policy_type_title;
                        objFormattedItem.sDescription = objItem.policy_type_description;
                        objFormattedItem.sIcon = "priority_high";
                        objFormattedItem.sColor = "warning-color";
                        objFormattedItem.sPolicyTypeId = objItem.policy_type_id;
                        objFormattedItem.sVersion = objItem.latest_policy.version;
                        if (!a24Core.isEmpty(objItem.acknowledged_policy)) {
                            objFormattedItem.bHasSignedOutdated = true;
                            objFormattedItem.sVersion = objItem.acknowledged_policy.version;
                        }
                    } else {
                        // Latest Policy is not present
                        objFormattedItem.sTitle = objItem.policy_type_title;
                        objFormattedItem.sDescription = objItem.policy_type_description;
                        objFormattedItem.sIcon = "check";
                        objFormattedItem.sColor = "success-color";
                        objFormattedItem.bAccepted = true;
                        objFormattedItem.sPolicyTypeId = objItem.policy_type_id;
                        objFormattedItem.sVersion = objItem.acknowledged_policy.version;
                    }

                    objFormattedItem.funcHeaderClickCallback = this.onHistoricItemClicked(objItem);

                    if (objItem.policy_type_classification === "viewable") {
                        objFormattedItem.sIcon = "check";
                        objFormattedItem.sColor = "grey";
                        objFormattedItem.bAccepted = false;
                        objFormattedItem.bHasSignedOutdated = false;
                        objFormattedItem.bReadOnly = true;
                    }

                    arrFinalData.push(objFormattedItem);
                }
            }

            this.set("_arrItems", arrFinalData);

            // Set loading false
            this.set("_bLoading", false);

            // Set empty message
            if (a24Core.isEmpty(arrFinalData)) {
                this.setEmptyPoliciesMessage();
            }
        },
        onHistoricItemClicked: function onHistoricItemClicked(objItem) {
            var objThis = this;
            return function () {
                var sVersion = "";
                if (!a24Core.isEmpty(objItem.latest_policy)) {
                    if (objThis.get("objPolicyGroupService.sContext") === objThis.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP")) {
                        sVersion = objItem.latest_policy.version;
                    } else {
                        sVersion = "latest";
                    }
                } else {
                    sVersion = objItem.acknowledged_policy.version;
                }

                if (objThis.get("objPolicyGroupService.sContext") === objThis.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE")) {
                    var objParams = {
                        "policy-view": [objThis.get("_objNavigationService").get("objRouteParams")["candidate.policy-group-list"].policy_group_type, objThis.get("_objNavigationService").get("objRouteParams")["candidate.policy-group-list"].policy_group_id, objItem.policy_type_id, sVersion]
                    };
                    objThis.get("_objNavigationService").navigateWithinContext("policy-view", objParams);
                } else if (objThis.get("objPolicyGroupService.sContext") === objThis.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP")) {
                    objThis.get("_objSnackbarService").showSnackbarMessage(objThis.get("_objStringsService").getString("downloadPdf"));

                    if (!a24Core.isEmpty(objItem.acknowledged_policy)) {
                        // This is to download the signed policy. We dont care about the latest policy in this case
                        objThis.get("_objAgencyGroupCandidatePolicyRestService").downloadPdfByGET(objItem.policy_type_id, objThis.get("sCandidateId"), objItem.acknowledged_policy.version, function () {
                            objThis.get("_objSnackbarService").showSnackbarMessage(objThis.get("_objStringsService").getString("downloadPdfFinish"));
                        }, function () {
                            objThis.get("_objSnackbarService").showSnackbarMessage(objThis.get("_objStringsService").getString("downloadPdfFailed"));
                        });
                    } else {
                        // This is to download just the latest version
                        objThis.get("_objPolicyRestService").downloadPdfByGET(objItem.policy_type_id, sVersion, function () {
                            objThis.get("_objSnackbarService").showSnackbarMessage(objThis.get("_objStringsService").getString("downloadPdfFinish"));
                        }, function () {
                            objThis.get("_objSnackbarService").showSnackbarMessage(objThis.get("_objStringsService").getString("downloadPdfFailed"));
                        });
                    }
                }
            };
        },
        buildFilterParams: function buildFilterParams(objFilterParams) {
            if (a24Core.isEmpty(objFilterParams.action)) {
                return objFilterParams;
            }

            if (objFilterParams.action.mValue === "read_only") {
                delete objFilterParams.action;
                objFilterParams.policy_type_classification = {
                    mValue: "viewable"
                };
            } else {
                objFilterParams.policy_type_classification = {
                    mValue: "acknowledgeable"
                };
            }

            return objFilterParams;
        },
        actions: {
            cardDatagridFilterChangeEvent: function cardDatagridFilterChangeEvent(sProperty, objValue) {
                this.set("_objQueryParams", Ember.copy(objValue, true));
                this.parseResponseData();
            },
            onOutdatedClick: function onOutdatedClick(objClickedItem) {
                var _this2 = this;

                if (this.get("objPolicyGroupService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE")) {
                    var objParams = {
                        "policy-view": [this.get("_objNavigationService").get("objRouteParams")["candidate.policy-group-list"].policy_group_type, this.get("_objNavigationService").get("objRouteParams")["candidate.policy-group-list"].policy_group_id, objClickedItem.sPolicyTypeId, objClickedItem.sVersion]
                    };
                    this.get("_objNavigationService").navigateWithinContext("policy-view", objParams);
                } else if (this.get("objPolicyGroupService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP")) {
                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getString("downloadPdf"));

                    // This is to download the signed policy. We dont care about the latest policy in this case
                    this.get("_objAgencyGroupCandidatePolicyRestService").downloadPdfByGET(objClickedItem.sPolicyTypeId, this.get("sCandidateId"), objClickedItem.sVersion, function () {
                        _this2.get("_objSnackbarService").showSnackbarMessage(_this2.get("_objStringsService").getString("downloadPdfFinish"));
                    }, function () {
                        _this2.get("_objSnackbarService").showSnackbarMessage(_this2.get("_objStringsService").getString("downloadPdfFailed"));
                    });
                }
            }
        }
    });
});