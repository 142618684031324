define("a24-ember-candidate-profile/components/candidate-contact-numbers", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-contact-numbers", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _candidateContactNumbers, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _candidateContactNumbers.default,
        objSettingsRestService: null,
        objContactNumbersRestService: null,
        sCandidateId: null,

        _arrContactNumbers: null,
        _arrDisplayContactNumbers: null,
        _sCountryCode: null,

        _bLoading: true,
        _bPopupLoading: false,
        _bPopupDeleteLoading: false,
        _bShowUnsavedData: false,
        _bIsAdd: false,
        _bAllowAddEdit: true,
        _bShowDeleteConfirm: false,
        _bServiceCallsDone: false,
        _bInputsReady: false,

        _sHeaderPopup: null,
        _objOriginalData: null,
        _bShowAddEditPopup: false,
        _arrHeaderActions: null,
        _objContactNumberEditData: null,
        _objSpecialAddEditPopupAction: null,
        _objCandidateProfileContactNumbersPromise: null,
        _objContactNumbersUpdatePromise: null,
        _objContactNumbersCreatePromise: null,
        _objContactNumbersDeletePromise: null,
        _objRestCountryPromise: null,
        _objSelectedContactType: null,
        _objRestCountryProperty: null,
        _objNavigationService: Ember.inject.service("navigation"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _arrContactTypeDropdown: null,
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objUserSession: Ember.inject.service("user-session"),
        _objRestCountry: Ember.inject.service("rest-country"),

        _bServerError: false,
        _arrReloadActions: null,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.getContactNumbers(true);
                }
            }]);

            if (!this.get("objSettingsRestService.objSettings.profile_editable")) {
                this.set("_bAllowAddEdit", false);
            }

            if (a24Core.isEmpty(this.get("_arrContactNumbers"))) {
                this.set("_arrContactNumbers", []);
            }

            this.set("_arrHeaderActions", []);

            if (this.get("_bAllowAddEdit")) {
                this.set("_arrHeaderActions", [{
                    sTitle: this.get("_objStringsService").getString("add"),
                    sIcon: "add",
                    executeCardAction: function executeCardAction() {
                        _this.setAddEditPopup();
                    }
                }]);
            }

            this.set("_objSpecialAddEditPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onPopupAddEditCancel");
                }
            });

            var objValidationConfig = {};

            objValidationConfig["objChildren.type"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.number"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 1);
                a24Validation.addRequired(objMixin, true);
            };

            this.set("objChildConfig", objValidationConfig);

            this.set("_arrContactTypeDropdown", this.get("_objCandidateProfileEnumsService").getList("phoneTypes"));

            this.getContactNumbers(true);
        },
        prepareForDisplay: function prepareForDisplay(arrData) {
            var objThis = this;

            var funcCardActionEditExecute = function funcCardActionEditExecute(objContactNumber) {
                return function () {
                    objThis.setAddEditPopup(objContactNumber);
                };
            };
            var funcCardActionRemoveExecute = function funcCardActionRemoveExecute(objContactNumber) {
                return function () {
                    objThis.removeContactNumber(objContactNumber);
                };
            };

            var arrDisplay = [];

            for (var i = 0; i < arrData.length; i++) {
                var objContactNumber = arrData[i];

                var sFormattedNumber = this.get("objChildren.number").formatNumberForDisplay(arrData[i].number);

                var objPhoneTypeEnum = this.get("_objCandidateProfileEnumsService").getFrontendValue("phoneTypes", arrData[i].type, "additional", true);

                var sLabel = a24Core.getSafeStringLink(Ember, sFormattedNumber, arrData[i].number, "phone");

                var arrActions = [];

                if (this.get("_bAllowAddEdit")) {
                    arrActions.push({
                        sTitle: this.get("_objStringsService").getString("edit"),
                        sIcon: "edit",
                        executeCardAction: funcCardActionEditExecute(objContactNumber)
                    });

                    arrActions.push({
                        sTitle: this.get("_objStringsService").getString("remove"),
                        sIcon: "delete",
                        executeCardAction: funcCardActionRemoveExecute(objContactNumber)
                    });
                }

                arrDisplay.push({
                    sLabel: sLabel,
                    sExtra: objPhoneTypeEnum.sLabel,
                    sIcon: objPhoneTypeEnum.icon,
                    sIconFa: objPhoneTypeEnum.icon_fa,
                    sIconSvg: objPhoneTypeEnum.icon_svg,
                    arrActions: arrActions
                });
            }

            return arrDisplay;
        },
        setAddEditPopup: function setAddEditPopup(objContactNumber) {
            var _this2 = this;

            this.clearAllErrorMessages();
            this.clearForm();

            this.set("_sHeaderPopup", this.get("_objStringsService").getTokenString("addItem", this.get("_objCandidateProfileStringsService").getString("contactNumber")));

            var objMobileNumberField = this.get("objChildren.number");
            if (!a24Core.isEmpty(this.get("_sCountryCode"))) {
                var sCountryCode = this.get("_sCountryCode");
                if (sCountryCode === "UK") {
                    sCountryCode = "GB";
                }
                objMobileNumberField.setCountryCode(sCountryCode);
            }

            this.set("_bIsAdd", true);

            if (!a24Core.isEmpty(objContactNumber)) {
                // set inputs based on id
                this.set("_sHeaderPopup", this.get("_objStringsService").getTokenString("editItem", this.get("_objCandidateProfileStringsService").getString("contactNumber")));
                this.set("_bIsAdd", false);
                this.set("_objContactNumberEditData", objContactNumber);

                this.populateInputFields(objContactNumber);
            }

            this.set("_bShowAddEditPopup", true);
            if (!a24Core.isEmpty(this.get("setBreadCrumbAction"))) {
                this.get("setBreadCrumbAction")();
            }

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this2)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    _this2.set("_objOriginalData", _this2.getPlainFormValueObject());
                });
            });
        },
        populateInputFields: function populateInputFields(objContactNumber) {
            var objSelectedContactType = this.get("_objCandidateProfileEnumsService").getFrontendValue("phoneTypes", objContactNumber.type, null, true);
            if (!a24Core.isEmpty(objSelectedContactType)) {
                this.set("_objSelectedContactType", Ember.copy(objSelectedContactType, true));
            }

            this.get("objChildren.number").setValueFromOutside(objContactNumber.number);
        },
        getContactNumbers: function getContactNumbers(bInitial) {
            var _this3 = this;

            this.set("_bLoading", true);
            this.set("_bServerError", false);

            var objContextDetails = this.get("_objUserSession").objContextDetails;

            var objPromiseContactNumbers = this.get("objContactNumbersRestService").getContactNumbers(this, "_objCandidateProfileContactNumbersPromise", this.get("sCandidateId"), function () {}, // handled in rsvp
            a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this3.set("_bServerError", true);
                    return;
                }
                if (sCode === "404" || sCode === "403") {
                    if (!a24Core.isEmpty(_this3.get("onGetContactNumbersFailure"))) {
                        _this3.get("onGetContactNumbersFailure")(sCode);
                        return;
                    }
                }
                // handled in rsvp
            }));

            var objServiceCalls = {
                _objRestContactNumberProperty: objPromiseContactNumbers
            };
            var arrRequiredResponses = ["_objRestContactNumberProperty"];

            if (!a24Core.isEmpty(objContextDetails.sCountryId) && bInitial) {
                var objPromiseCountry = this.get("_objRestCountry").getCountryById(this, "_objRestCountryPromise", objContextDetails.sCountryId, function () {}, // handled in rsvp
                a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode) {
                    if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                        _this3.set("_bServerError", true);
                        return;
                    }
                    // handled in rsvp
                }));

                objServiceCalls._objRestCountryProperty = objPromiseCountry;
                arrRequiredResponses.push("_objRestCountryProperty");
            }

            Ember.copy(a24RSVP).configure(this, objServiceCalls, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    if (!a24Core.isEmpty(objResponses._objRestContactNumberProperty) && !a24Core.isEmpty(objResponses._objRestContactNumberProperty.objHeaders) && objResponses._objRestContactNumberProperty.objHeaders.statusText === "abort" || !a24Core.isEmpty(objResponses._objRestCountryProperty) && !a24Core.isEmpty(objResponses._objRestCountryProperty.objHeaders) && objResponses._objRestCountryProperty.objHeaders.statusText === "abort") {
                        return;
                    }

                    if (!a24Core.isEmpty(objResponses._objRestContactNumberProperty) && !a24Core.isEmpty(objResponses._objRestContactNumberProperty.objResponse)) {
                        _this3.set("_arrContactNumbers", objResponses._objRestContactNumberProperty.objResponse);
                    } else {
                        _this3.set("_arrContactNumbers", []);
                    }

                    if (!a24Core.isEmpty(objResponses._objRestCountryProperty) && !a24Core.isEmpty(objResponses._objRestCountryProperty.objResponse)) {
                        _this3.set("_sCountryCode", objResponses._objRestCountryProperty.objResponse.sCountryCode);
                    } else {
                        _this3.set("_sCountryCode", "");
                    }

                    _this3.set("_bServiceCallsDone", true);
                    _this3._setupForm();
                }
            }]);
        },
        removeContactNumber: function removeContactNumber(objContactNumber) {
            this.set("_bShowDeleteConfirm", true);
            this.set("_objContactNumberEditData", objContactNumber);
        },
        setErrorResponse: function setErrorResponse(sCode, objError, sSnackbarMessage) {
            if (sCode === "PATTERN") {
                this.setResponseError(objError.arrPath, "validationRestInvalidFormat");
            } else {
                this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            }

            this.set("_bPopupLoading", false);
            this.set("_bPopupDeleteLoading", false);
        },
        setSuccessResponse: function setSuccessResponse(sSnackbarMessage) {
            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            this.set("_bPopupLoading", false);
            this.set("_bPopupDeleteLoading", false);
            this.set("_bShowAddEditPopup", false);
            this.set("_bShowDeleteConfirm", false);

            this.getContactNumbers(false);
        },
        getPayload: function getPayload() {
            return a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());
        },

        _setupForm: function _setupForm() {
            if (this.get("_bServiceCallsDone") && this.get("_bInputsReady")) {

                this.set("_arrDisplayContactNumbers", this.prepareForDisplay(this.get("_arrContactNumbers")));

                var objMobileNumberField = this.get("objChildren.number");
                if (!a24Core.isEmpty(this.get("_sCountryCode"))) {
                    var sCountryCode = this.get("_sCountryCode");
                    if (sCountryCode === "UK") {
                        sCountryCode = "GB";
                    }
                    objMobileNumberField.setCountryCode(sCountryCode);
                }

                this.set("_bLoading", false);
            }
        },
        actions: {
            onPopupDeleteConfirm: function onPopupDeleteConfirm() {
                var _this4 = this;

                this.set("_bPopupDeleteLoading", true);
                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("removingItem", this.get("_objCandidateProfileStringsService").getString("contactNumber")));
                this.get("objContactNumbersRestService").deleteContactNumber(this, "_objContactNumbersDeletePromise", this.get("sCandidateId"), this.get("_objContactNumberEditData._id"), function () {
                    _this4.setSuccessResponse(_this4.get("_objStringsService").getTokenString("successRemoveItem", _this4.get("_objCandidateProfileStringsService").getString("contactNumber")));
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    if (sCode === "404") {
                        _this4.get("_objSnackbarService").showSnackbarMessage(_this4.get("_objStringsService").getString("removeFailedRefreshPage"));
                    } else {
                        _this4.get("_objSnackbarService").showSnackbarMessage(_this4.get("_objStringsService").getTokenString("failRemoveItem", _this4.get("_objCandidateProfileStringsService").getString("contactNumber")));
                    }

                    _this4.set("_bPopupDeleteLoading", false);
                }));
            },
            onPopupClose: function onPopupClose() {
                if (this.get("_bPopupDeleteLoading")) {
                    return;
                }

                this.set("_bShowDeleteConfirm", false);
            },
            onPopupAddEditCancel: function onPopupAddEditCancel() {
                if (this.get("_bPopupLoading")) {
                    return;
                }
                // Check if the form has any unsaved data
                var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

                if (bDataMatches) {
                    this.set("_bShowAddEditPopup", false);
                } else {
                    this.set("_bShowUnsavedData", true);
                }
            },
            onPopupAddEditConfirm: function onPopupAddEditConfirm() {
                var _this5 = this;

                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    var objPayload = this.getPayload();

                    this.set("_bPopupLoading", true);
                    if (this.get("_bIsAdd")) {
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objCandidateProfileStringsService").getString("contactNumber")));
                        this.get("objContactNumbersRestService").createContactNumber(this, "_objContactNumbersCreatePromise", this.get("sCandidateId"), objPayload, function () {
                            _this5.setSuccessResponse(_this5.get("_objStringsService").getTokenString("successAddItem", _this5.get("_objCandidateProfileStringsService").getString("contactNumber")));
                        }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                            _this5.setErrorResponse(sCode, objError, _this5.get("_objStringsService").getTokenString("failAddItem", _this5.get("_objCandidateProfileStringsService").getString("contactNumber")));
                        }));
                    } else {
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objCandidateProfileStringsService").getString("contactNumber")));

                        this.get("objContactNumbersRestService").updateContactNumber(this, "_objContactNumbersUpdatePromise", this.get("sCandidateId"), this.get("_objContactNumberEditData._id"), objPayload, function () {
                            _this5.setSuccessResponse(_this5.get("_objStringsService").getTokenString("successUpdateItem", _this5.get("_objCandidateProfileStringsService").getString("contactNumber")));
                        }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                            if (sCode === "404") {
                                _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getString("updateFailedRefreshPage"));
                                _this5.set("_bPopupLoading", false);
                            } else {
                                _this5.setErrorResponse(sCode, objError, _this5.get("_objStringsService").getTokenString("failUpdateItem", _this5.get("_objCandidateProfileStringsService").getString("contactNumber")));
                            }
                        }));
                    }
                }
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowAddEditPopup", false);
                this.set("_bShowUnsavedData", false);
            },
            onInputFormTelNumberInitDone: function onInputFormTelNumberInitDone() {
                this.set("_bInputsReady", true);
                this._setupForm();
            }
        }
    });
});