define("a24-ember-candidate-profile/components/candidate-external-general", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-external-general"], function (exports, _didRenderChangesMixin, _candidateExternalGeneral) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _candidateExternalGeneral.default,
        arrExternalData: null,
        bLoading: false,
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _arrExternalDataView: null,
        init: function init() {
            this._super.apply(this, arguments);

            this.prepareExternalDataForDisplay();
        },
        prepareExternalDataForDisplay: Ember.observer("arrExternalData", function () {
            this.set("_bLoading", false);
            var arrExternalData = this.get("arrExternalData");
            this.set("_arrExternalDataView", [{
                arrItems: arrExternalData
            }]);
        })
    });
});