define("a24-ember-candidate-profile/services/a24-constants-candidate-profile", ["exports", "a24-ember-lookup/services/a24-constants-base"], function (exports, _a24ConstantsBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _a24ConstantsBase.default.extend({
    CONVERT_MILES_TO_METERS: 1609.344,
    CONVERT_KILOMETERS_TO_METERS: 1000
  });
});