define("a24-ember-candidate-profile/services/rest-agency-activity-notification", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * Route for agency candidate activity notification
         *
         * @param objContext - The object on which the property must exist
         * @param sProperty - The Property name to get the promise object
         * @param sSubjectId - The id of the subject
         * @param sSubjectType - The type of the subject
         * @param sActivityId - The activity id
         * @param sNotificationId - The notification id
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  29 May 2020
         */
        getActivityNotification: function getActivityNotification(objContext, sProperty, sSubjectId, sSubjectType, sActivityId, sNotificationId, funcSuccess, objFailure) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency/" + sAgencyId + "/" + sSubjectType + "/" + sSubjectId + "/activity/" + sActivityId + "/candidate-notification/" + sNotificationId);
            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Route for agency candidate activity notification
         *
         * @param objContext - The object on which the property must exist
         * @param sProperty - The Property name to get the promise object
         * @param sSubjectId - The candidate id
         * @param sSubjectType - The candidate id
         * @param sActivityId - The activity id
         * @param sNotificationId - The notification id
         * @param sMedium - The medium
         * @param sNotificationSentId - The notification sent id
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  29 May 2020
         */
        getActivityNotificationFromMedium: function getActivityNotificationFromMedium(objContext, sProperty, sSubjectId, sSubjectType, sActivityId, sNotificationId, sMedium, sNotificationSentId, funcSuccess, objFailure) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency/" + sAgencyId + "/" + sSubjectType + "/" + sSubjectId + "/activity/" + sActivityId + "/candidate-notification/" + sNotificationId + "/" + sMedium + "/" + sNotificationSentId);
            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});