define("a24-ember-candidate-profile/components/compliance-rules-condition-query-builder", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/compliance-rules-condition-query-builder"], function (exports, _didRenderChangesMixin, _complianceRulesConditionQueryBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _complianceRulesConditionQueryBuilder.default,
        classNames: ["compliance-rules-condition-query-builder"],

        bShow: false,
        bForceReset: false,
        sWarningMessage: null,
        sSimpleTagsUrl: null,
        sSearchUrl: null,
        sContext: null,

        _env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),
        _sReactGatewayUrl: Ember.computed.readOnly("_env.sReactGatewayUrl"),
        _sRestApiBaseUrl: Ember.computed.readOnly("_env.sRestApiBaseUrl"),

        _objQueryParams: null,
        _objDatagridConfig: null,
        _sUrlBase: null,
        _sQuery: null,
        _bLoading: true,
        _bDoneDisabled: true,
        _bShowPopup: false,
        _bServiceCallsDone: false,
        _bShowLoadFailedPopup: false,
        _bAgencyGroupContext: true,
        _bHandleServerError: false,
        _bResetUrl: false,

        _arrCandidateStatus: null,
        _arrCandidateTypes: null,

        _objCandidateTypesResponse: null,
        _objCandidateStatusResponse: null,
        _objAckPoliciesResponse: null,
        _objExternalDocumentTypesResponse: null,
        _objDocumentTypesResponse: null,
        _objExternalVerificationResponse: null,
        _objActivityTypesResponse: null,
        _objConsultantTypeResponse: null,
        // _objGetExternalDaysToExpiry: null,
        _objConsultantRolePromise: null,

        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objQueryParamBuilderService: Ember.inject.service("query-param-object-builder"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objSSCoreStringsService: Ember.inject.service("a24-strings-staffshift-core"),
        _objEmberLibStringService: Ember.inject.service("a24-strings-ember-lib"),
        _objA24EnumsPolicyLib: Ember.inject.service("a24-enums-policy-library"),
        _objConstantsLib: Ember.inject.service("a24-constants-ember-lib"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objEnumEmberLibService: Ember.inject.service("a24-enums-ember-lib"),
        _objSSCoreEnumsService: Ember.inject.service("a24-enums-staffshift-core"),
        _objDatagridFilterService: Ember.inject.service("card-datagrid-filter"),

        _objRestActivityTypes: Ember.inject.service("rest-advanced-search-activity-type"),

        _objTemporalVariableService: Ember.inject.service("card-datagrid-temporal-variable"),

        _objRestCandidateType: null,
        _objRestStatus: null,
        _objRestLookup: Ember.computed(function () {
            return Ember.getOwner(this).lookup("service:rest-agency-group-lookup");
        }),
        _objRestAgencyLookup: Ember.computed(function () {
            return Ember.getOwner(this).lookup("service:rest-agency-lookup");
        }),
        _objConsultantRoleService: Ember.computed(function () {
            return Ember.getOwner(this).lookup("service:rest-agency-group-consultant-role");
        }),
        _objRestPolicyType: Ember.computed(function () {
            return Ember.getOwner(this).lookup("service:rest-agency-group-policy-type");
        }),
        _objRestDocumentTypes: Ember.computed(function () {
            return Ember.getOwner(this).lookup("service:rest-agency-group-document-types");
        }),

        _objRestAgencyGroupCandidateType: Ember.computed(function () {
            return Ember.getOwner(this).lookup("service:rest-agency-group-candidate-type");
        }),
        _objRestAgencyGroupStatus: Ember.computed(function () {
            return Ember.getOwner(this).lookup("service:rest-agency-group-status-details");
        }),

        _objRestAgencyCandidateType: Ember.computed(function () {
            return Ember.getOwner(this).lookup("service:rest-agency-candidate-type");
        }),
        _objRestAgencyStatus: Ember.computed(function () {
            return Ember.getOwner(this).lookup("service:rest-agency-status-details");
        }),

        _objFilterCustomCandidatePositionHeldService: Ember.inject.service("card-datagrid-custom-filter-candidate-position-held"),
        _objFilterCustomCandidateExternalPortfoliosService: Ember.inject.service("card-datagrid-custom-filter-candidate-external-portfolios"),
        // _objFilterCustomCandidateExternalDaysToExpiryService: inject("card-datagrid-custom-filter-candidate-external-days-to-expiry"),
        _objFilterCustomCandidateProfessionalRegistrationService: Ember.inject.service("card-datagrid-custom-filter-candidate-professional-registration"),
        _objFilterCustomCandidateDistanceService: Ember.inject.service("card-datagrid-custom-filter-candidate-distance"),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_sUrlBase", this.get("_sRestApiBaseUrl") + "/v1/agency-group/" + this.get("_objNavigation.sContextId") + "/advanced-search-v2/candidate");

            if (this.get("sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP")) {
                this.set("_bAgencyGroupContext", true);
                this.set("_objRestCandidateType", this.get("_objRestAgencyGroupCandidateType"));
                this.set("_objRestStatus", this.get("_objRestAgencyGroupStatus"));
            } else {
                this.set("_bAgencyGroupContext", false);
                this.set("_objRestCandidateType", this.get("_objRestAgencyCandidateType"));
                this.set("_objRestStatus", this.get("_objRestAgencyStatus"));
            }

            this.set("_objSpecialQueryBuilderPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._onQueryBuilderPopupCancel();
                }
            });

            var objQueryParamSetup = Ember.Object.create({
                queryParams: ["first_name", "last_name", "full_name", "gender", "city", "province", "post_code", "physical_address_geo_location", "username", "current_status__status", "current_status__date", "responsible_consultant", "consultants___consultant_role", "current_status__dateTo", "current_status__dateFrom", "first_status__status", "first_status__date", "first_status__dateTo", "first_status__dateFrom", "linked_to_agency_group_at", "linked_to_agency_group_atTo", "linked_to_agency_group_atFrom", "candidate_type_id", "emails___email", "contact_numbers___number", "languages___name", "current_employment_reference", "employment_references___reference", "dbs_status", "last_contacted_at", "last_contacted_atTo", "last_contacted_atFrom", "policies___type_id", "last_shift_worked_at", "last_shift_worked_atTo", "last_shift_worked_atFrom", "next_shift_at", "next_shift_atTo", "next_shift_atFrom", "employment_position_held___job_family_id", "activities___activity_type", "first_status_date___status", "last_status_date___status", "external_documents___type_id", "documents___system_document_type_id", "external_portfolios___portfolio_id", "user_status", "last_login_at", "last_login_atTo", "last_login_atFrom", "tags_links___tag_id", "skill_details___skill_id", "qualification_details___qualification_id", "external_verifications___name", "registration_body_details___registration_body_id", "registration_body_details___registration_body_entry_id", "external_days_to_expiry___type_id", "keywords___name", "chat_token_last_issued_at", "chat_token_last_issued_atFrom", "chat_token_last_issued_atTo", "notification_dnc", "medium_dnc___type", "transport_type", "allocated_agency_group_verified_documents___document_type_id", "external_workforce", "reference_compliance___reference_compliance_standard_id", "has_next_of_kin", "page", "sortBy", "vis_cols", "savedSearchKey"],
                first_name: "",
                last_name: "",
                full_name: "",
                gender: "",
                city: "",
                province: "",
                post_code: "",
                physical_address_geo_location: "",
                username: "",
                current_status__status: "",
                current_status__date: "",
                responsible_consultant: "",
                consultants___consultant_role: "",
                current_status__dateTo: "",
                current_status__dateFrom: "",
                first_status__status: "",
                first_status__date: "",
                first_status__dateTo: "",
                first_status__dateFrom: "",
                linked_to_agency_group_at: "",
                linked_to_agency_group_atTo: "",
                linked_to_agency_group_atFrom: "",
                candidate_type_id: "",
                emails___email: "",
                contact_numbers___number: "",
                languages___name: "",
                current_employment_reference: "",
                employment_references___reference: "",
                dbs_status: "",
                last_contacted_at: "",
                last_contacted_atTo: "",
                last_contacted_atFrom: "",
                policies___type_id: "",
                last_shift_worked_at: "",
                last_shift_worked_atTo: "",
                last_shift_worked_atFrom: "",
                next_shift_at: "",
                next_shift_atTo: "",
                next_shift_atFrom: "",
                employment_position_held___job_family_id: "",
                activities___activity_type: "",
                first_status_date___status: "",
                last_status_date___status: "",
                external_documents___type_id: "",
                documents___system_document_type_id: "",
                external_portfolios___portfolio_id: "",
                user_status: "",
                last_login_at: "",
                last_login_atTo: "",
                last_login_atFrom: "",
                tags_links___tag_id: "",
                skill_details___skill_id: "",
                qualification_details___qualification_id: "",
                external_verifications___name: "",
                registration_body_details___registration_body_id: "",
                registration_body_details___registration_body_entry_id: "",
                external_days_to_expiry___type_id: "",
                keywords___name: "",
                chat_token_last_issued_at: "",
                chat_token_last_issued_atFrom: "",
                chat_token_last_issued_atTo: "",
                notification_dnc: "",
                medium_dnc___type: "",
                transport_type: "",
                allocated_agency_group_verified_documents___document_type_id: "",
                external_workforce: "",
                reference_compliance___reference_compliance_standard_id: "",
                has_next_of_kin: "",
                page: "1",
                sortBy: "full_name::asc",
                vis_cols: "1,5,10,6,7,8",
                savedSearchKey: ""
            });

            this.set("_objQueryParams", this.get("_objQueryParamBuilderService").createQueryParamObject(objQueryParamSetup.queryParams, objQueryParamSetup, null, true, true));

            this.doServiceCalls();
        },
        doServiceCalls: function doServiceCalls() {
            var _this2 = this;

            this.set("_bLoading", true);

            var objPromiseCandidateTypes = this.get("_objRestCandidateType").getCandidateTypes(this, "_objCandidateTypesResponse", this.get("_objUserSession").getUserContextReference().mId, function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2._handleServerErrors();
            }));

            var objPromiseCandidateStatus = this.get("_objRestStatus").getStatusList(this, "_objCandidateStatusResponse", function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["412", "500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2._handleServerErrors();
            }));

            var objActivityTypesQueryParams = {
                disabled: { mValue: false },
                subject_type: { mValue: this.get("_objSSCoreEnumsService.activitySubjectTypes.CANDIDATE.backend") },
                name: { mValue: "asc", bSortBy: true },
                page: { mValue: 1 },
                items_per_page: { mValue: 1000 }
            };

            var objActivityTypes = this.get("_objRestActivityTypes").getActivityTypes(this, "_objActivityTypesResponse", function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2._handleServerErrors();
            }), objActivityTypesQueryParams);

            var objPromiseList = {
                _objRSVPCandidateTypes: objPromiseCandidateTypes,
                _objRSVPCandidateStatus: objPromiseCandidateStatus,
                _objRSVPActivityTypes: objActivityTypes
            };

            var arrRequiredResponses = ["_objRSVPCandidateTypes", "_objRSVPCandidateStatus", "_objRSVPActivityTypes"];

            var objExternalDocumentTypes = null;
            var objExternalVerificationTypes = null;
            // var objPromiseExternalDaysTypes = null;
            if (this.get("_bAgencyGroupContext")) {
                objExternalDocumentTypes = this.get("_objRestLookup").getExternalDocuments(this, "_objExternalDocumentTypesResponse", function () {
                    // Handled by RSVP
                }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    _this2._handleServerErrors();
                }), {
                    items_per_page: {
                        mValue: 1000
                    },
                    sortBy: {
                        mValue: "name"
                    }
                });

                var objPromiseConsultantRole = this.get("_objConsultantRoleService").getConsultantRoles(this, "_objConsultantRolePromise", function () {}, // handled in rsvp
                a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    _this2._handleServerErrors();
                }));

                var objAckPolicies = this.get("_objRestPolicyType").getPolicyTypes(this, "_objAckPoliciesResponse", this.get("_objUserSession").getUserContextReference().mId, function () {
                    // Handled by RSVP
                }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    _this2._handleServerErrors();
                }), {
                    items_per_page: {
                        mValue: 1000
                    },
                    classification: {
                        mValue: this.get("_objA24EnumsPolicyLib.classification.ACKNOWLEDGEABLE.backend")
                    }
                });

                var objDocumentTypes = this.get("_objRestDocumentTypes").getDocumentTypes(this, "_objDocumentTypesResponse", function () {
                    // Handled by RSVP
                }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    _this2._handleServerErrors();
                }));

                objExternalVerificationTypes = this.get("_objRestLookup").getExternalVerification(this, "_objExternalVerificationResponse", function () {
                    // Handled by RSVP
                }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    _this2._handleServerErrors();
                }), {
                    items_per_page: {
                        mValue: 1000
                    }
                });

                var objPromiseConsultantTypes = this.get("_objRestLookup").getPortfolioConsultantTypes(this, "_objConsultantTypeResponse", function () {
                    // Handled by RSVP
                }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    _this2._handleServerErrors();
                }), {
                    items_per_page: {
                        mValue: 1000
                    }
                });

                // Commented out for this ticket https://github.com/A24Group/react-core/issues/2994
                // objPromiseExternalDaysTypes = this.get("_objRestLookup").getExternalDaysToExpiry(
                //     this,
                //     "_objGetExternalDaysToExpiry",
                //     () => {
                //         // Handled by RSVP
                //     },
                //     a24RestCallHelper.createJsonAjaxFailureObject(
                //         ["500", "502", "503", "504"],
                //         (/*sCode, sStatus, objErrorThrown, objError*/) => {
                //             this._handleServerErrors();
                //         }
                //     ),
                //     {
                //         sortBy: {
                //             mValue: "name"
                //         },
                //         items_per_page: {
                //             mValue: 1000
                //         }
                //     }
                // );

                objPromiseList._objConsultantRolePromise = objPromiseConsultantRole;
                objPromiseList._objRSVPExternalDocumentTypes = objExternalDocumentTypes;
                objPromiseList._objRSVPAckPolicies = objAckPolicies;
                objPromiseList._objRSVPDocumentTypes = objDocumentTypes;
                objPromiseList._objRSVPConsultantTypes = objPromiseConsultantTypes;
                objPromiseList._objRSVPExternalVerificationTypes = objExternalVerificationTypes;
                // objPromiseList._objRSVPExternalDaysTypes = objPromiseExternalDaysTypes;

                arrRequiredResponses.push("_objRSVPExternalDocumentTypes");
                arrRequiredResponses.push("_objConsultantRolePromise");
                arrRequiredResponses.push("_objRSVPAckPolicies");
                arrRequiredResponses.push("_objRSVPDocumentTypes");
                arrRequiredResponses.push("_objRSVPConsultantTypes");
                arrRequiredResponses.push("_objRSVPExternalVerificationTypes");
                // arrRequiredResponses.push("_objRSVPExternalDaysTypes");
            } else {
                objExternalDocumentTypes = this.get("_objRestAgencyLookup").getExternalDocuments(this, "_objExternalDocumentTypesResponse", function () {
                    // Handled by RSVP
                }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    _this2._handleServerErrors();
                }), {
                    items_per_page: {
                        mValue: 1000
                    },
                    sortBy: {
                        mValue: "name"
                    }
                });

                objExternalVerificationTypes = this.get("_objRestAgencyLookup").getExternalVerification(this, "_objExternalVerificationResponse", function () {
                    // Handled by RSVP
                }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    _this2._handleServerErrors();
                }), {
                    items_per_page: {
                        mValue: 1000
                    }
                });
                // Commented out for this ticket https://github.com/A24Group/react-core/issues/2994
                // objPromiseExternalDaysTypes = this.get("_objRestAgencyLookup").getExternalDaysToExpiry(
                //     this,
                //     "_objGetExternalDaysToExpiry",
                //     () => {
                //         // Handled by RSVP
                //     },
                //     a24RestCallHelper.createJsonAjaxFailureObject(
                //         ["500", "502", "503", "504"],
                //         (/*sCode, sStatus, objErrorThrown, objError*/) => {
                //             this._handleServerErrors();
                //         }
                //     ),
                //     {
                //         sortBy: {
                //             mValue: "name"
                //         },
                //         items_per_page: {
                //             mValue: 1000
                //         }
                //     }
                // );
                objPromiseList._objRSVPExternalDocumentTypes = objExternalDocumentTypes;
                objPromiseList._objRSVPExternalVerificationTypes = objExternalVerificationTypes;
                // objPromiseList._objRSVPExternalDaysTypes = objPromiseExternalDaysTypes;

                arrRequiredResponses.push("_objRSVPExternalDocumentTypes");
                arrRequiredResponses.push("_objRSVPExternalVerificationTypes");
                // arrRequiredResponses.push("_objRSVPExternalDaysTypes");
            }

            var objThis = this;

            a24RSVP.configure(this, objPromiseList, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    var arrCandidateTypes = objResponses._objRSVPCandidateTypes.objResponse;
                    var arrCandidateStatus = objResponses._objRSVPCandidateStatus.objResponse;
                    var arrActivityTypes = objResponses._objRSVPActivityTypes.objResponse;

                    var arrAckPolicies = objThis.get("_bAgencyGroupContext") ? objResponses._objRSVPAckPolicies.objResponse : [];
                    var arrDocumentTypes = objThis.get("_bAgencyGroupContext") ? objResponses._objRSVPDocumentTypes.objResponse : [];
                    var arrConsultantTypes = objThis.get("_bAgencyGroupContext") ? objResponses._objRSVPConsultantTypes.objResponse : [];
                    var arrExternalVerificationTypes = objResponses._objRSVPExternalVerificationTypes.objResponse;
                    // var arrExternalDaysTypes = objResponses._objRSVPExternalDaysTypes.objResponse;
                    var arrExternalDocsTypes = objResponses._objRSVPExternalDocumentTypes.objResponse;
                    var arrConsultantRoles = objThis.get("_bAgencyGroupContext") ? objResponses._objConsultantRolePromise.objResponse : [];

                    if (a24Core.isEmpty(arrCandidateTypes)) {
                        arrCandidateTypes = [];
                    }
                    if (a24Core.isEmpty(arrCandidateStatus)) {
                        arrCandidateStatus = [];
                    }
                    if (a24Core.isEmpty(arrAckPolicies)) {
                        arrAckPolicies = [];
                    }
                    if (a24Core.isEmpty(arrExternalDocsTypes)) {
                        arrExternalDocsTypes = [];
                    }
                    if (a24Core.isEmpty(arrDocumentTypes)) {
                        arrDocumentTypes = [];
                    }
                    if (a24Core.isEmpty(arrActivityTypes)) {
                        arrActivityTypes = [];
                    }
                    if (a24Core.isEmpty(arrConsultantTypes)) {
                        arrConsultantTypes = [];
                    }
                    if (a24Core.isEmpty(arrExternalVerificationTypes)) {
                        arrExternalVerificationTypes = [];
                    }
                    // if (a24Core.isEmpty(arrExternalDaysTypes)) {
                    //     arrExternalDaysTypes = [];
                    // }
                    if (a24Core.isEmpty(arrConsultantRoles)) {
                        arrConsultantRoles = [];
                    }
                    objThis.set("_arrConsultantRoles", arrConsultantRoles);

                    objThis.setupSearchConfig(arrCandidateTypes, arrCandidateStatus, arrAckPolicies, arrExternalDocsTypes, arrDocumentTypes, arrActivityTypes, arrConsultantTypes, arrExternalVerificationTypes, [], arrConsultantRoles);
                }
            }]);
        },
        setupSearchConfig: function setupSearchConfig(arrCandidateTypes, arrCandidateStatus, arrAckPolicies, arrExternalDocsTypes, arrDocumentTypes, arrActivityTypes, arrConsultantTypes, arrExternalVerificationTypes, arrExternalDaysTypes, arrConsultantRoles) {
            var _this3 = this;

            var objThis = this;
            this.set("_arrCandidateTypes", arrCandidateTypes);
            this.set("_arrCandidateStatus", arrCandidateStatus);
            var arrLanguageOptions = objThis.get("_objCandidateProfileEnumsService").getList("language");
            var arrDBSStatusOptions = objThis.get("_objCandidateProfileEnumsService").getList("dbsStatuses");
            var arrMediumTypeOptions = objThis.get("_objCandidateProfileEnumsService").getList("mediumTypeDnc");
            var arrDBSWorkforceOptions = objThis.get("_objCandidateProfileEnumsService").getList("dbsWorforce");
            var arrTransportOptions = objThis.get("_objCandidateProfileEnumsService").getList("transportType");

            var arrUserStatusDropdown = objThis.get("_objCandidateProfileEnumsService").getList("userStatuses").map(function (objStatus) {
                return {
                    sTitle: objStatus.sLabel,
                    sValue: objStatus.sValue
                };
            });

            var arrCandidateTypeIds = [];

            var arrCandidateTypesDropdown = [];
            for (var i = 0; i < arrCandidateTypes.length; i++) {
                arrCandidateTypeIds.push(arrCandidateTypes[i]._id);
                arrCandidateTypesDropdown.push({
                    sTitle: arrCandidateTypes[i].name,
                    sValue: arrCandidateTypes[i]._id
                });
            }

            var arrStatusesDropdown = [];
            for (var j = 0; j < arrCandidateStatus.length; j++) {
                arrStatusesDropdown.push({
                    sTitle: arrCandidateStatus[j].name,
                    sValue: arrCandidateStatus[j].name // This is done to comply with api
                });
            }

            var arrCandidateStatusesDropdown = [];
            arrCandidateStatusesDropdown.push({
                sTitle: objThis.get("_objEmberLibStringService").getString("empty"),
                sValue: "null"
            });
            for (var r = 0; r < arrCandidateStatus.length; r++) {
                arrCandidateStatusesDropdown.push({
                    sTitle: arrCandidateStatus[r].name,
                    sValue: arrCandidateStatus[r].name // This is done to comply with api
                });
            }

            var arrAckPoliciesDropdown = [];
            for (var m = 0; m < arrAckPolicies.length; m++) {
                arrAckPoliciesDropdown.push({
                    sTitle: arrAckPolicies[m].title,
                    sValue: arrAckPolicies[m]._id
                });
            }

            var arrDocumentTypesDropdown = [];
            arrDocumentTypesDropdown.push({
                sTitle: this.get("_objStringsService").getString("any"),
                sValue: "*"
            });
            for (var p = 0; p < arrDocumentTypes.length; p++) {
                arrDocumentTypesDropdown.push({
                    sTitle: arrDocumentTypes[p].name,
                    sValue: arrDocumentTypes[p].system_document_type_id
                });
            }

            var arrLanguagesDropdown = [];
            for (var k = 0; k < arrLanguageOptions.length; k++) {
                arrLanguagesDropdown.push({
                    sTitle: arrLanguageOptions[k].sLabel,
                    sValue: arrLanguageOptions[k].sLabel // This is done to comply with api
                });
            }

            var arrDBSStatusDropdown = [];
            for (var l = 0; l < arrDBSStatusOptions.length; l++) {
                arrDBSStatusDropdown.push({
                    sTitle: arrDBSStatusOptions[l].sLabel,
                    sValue: arrDBSStatusOptions[l].sValue
                });
            }

            var arrExternalVerificationDropdown = [];
            for (var t = 0; t < arrExternalVerificationTypes.length; t++) {
                arrExternalVerificationDropdown.push({
                    sTitle: arrExternalVerificationTypes[t].name,
                    sValue: arrExternalVerificationTypes[t].name
                });
            }

            var arrActivityTypeDropdown = [];
            for (var n = 0; n < arrActivityTypes.length; n++) {
                arrActivityTypeDropdown.push({
                    sTitle: arrActivityTypes[n].name,
                    sValue: arrActivityTypes[n].code
                });
            }

            var arrConsultantRolesDropdown = [];
            arrConsultantRolesDropdown.push({
                sTitle: objThis.get("_objStringsService").getString("any"),
                sValue: "*"
            });

            for (var x = 0; x < arrConsultantRoles.length; x++) {
                //For now we will equal on the name since advance search does not have access to id
                arrConsultantRolesDropdown.push({
                    sTitle: arrConsultantRoles[x].name,
                    sValue: arrConsultantRoles[x].name
                });
            }

            var arrMediaTypesDropdown = [];
            if (!a24Core.isEmpty(arrMediumTypeOptions)) {
                for (var s = 0; s < arrMediumTypeOptions.length; s++) {
                    arrMediaTypesDropdown.push({
                        sTitle: arrMediumTypeOptions[s].sLabel,
                        sValue: arrMediumTypeOptions[s].sValue
                    });
                }
            }

            var arrTransportOptionsDropdown = [];
            for (var q = 0; q < arrTransportOptions.length; q++) {
                arrTransportOptionsDropdown.push({
                    sTitle: arrTransportOptions[q].sLabel,
                    sValue: arrTransportOptions[q].sValue
                });
            }

            var arrDBSWorkforceDropdown = [];
            for (var v = 0; v < arrDBSWorkforceOptions.length; v++) {
                arrDBSWorkforceDropdown.push({
                    sTitle: arrDBSWorkforceOptions[v].sLabel,
                    sValue: arrDBSWorkforceOptions[v].sValue
                });
            }

            var arrQueryType = this.get("_objEnumEmberLibService").getList("filterOptionsTextType");
            var objConstants = this.get("_objConstantsLib");
            arrQueryType.push({
                "sValue": objConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_CONTAINS,
                "sLabel": this.get("_objStringsService").getString("contains")
            });

            var sCountryId = this.get("_objUserSession.objContextDetails.sCountryId");
            var sCountryCode = sCountryId === "710" ? "ZA" : sCountryId === "840" ? "US" : "GB";

            var arrDataGridHeaderConfig = [];

            arrDataGridHeaderConfig = [// This array contain the datagrid columns
            {
                iUniqueColNum: 1,
                sTitle: this.get("_objSSCoreStringsService").getString("fullName"),
                sProperty: "full_name",
                sFilterType: "text",
                bText: true,
                iWidth: 15,
                bIsClickable: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objStringsService").getString("firstName"),
                sProperty: "first_name",
                sFilterType: "text",
                bText: true,
                iWidth: 8,
                bIsClickable: true,
                bStopFiltering: true,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objStringsService").getString("lastName"),
                sProperty: "last_name",
                sFilterType: "text",
                bText: true,
                iWidth: 8,
                bIsClickable: true,
                bStopFiltering: true,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 4,
                sTitle: this.get("_objStringsService").getString("gender"),
                sProperty: "gender",
                sFilterType: "dropdown",
                bMapping: true,
                arrMappingData: [{
                    sValue: "male",
                    sTitle: this.get("_objStringsService").getString("male")
                }, {
                    sValue: "female",
                    sTitle: this.get("_objStringsService").getString("female")
                }],
                iWidth: 8,
                bStopColumnSort: true,
                bHideEmptyCheck: true
            }, {
                iUniqueColNum: 5,
                sTitle: this.get("_objSSCoreStringsService").getString("type"),
                sProperty: "candidate_type_id",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrCandidateTypesDropdown,
                iWidth: 15,
                bStopFiltering: true
            }, {
                iUniqueColNum: 10,
                sTitle: this.get("_objCandidateProfileStringsService").getString("currentStatus"),
                sProperty: "current_status__status",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrCandidateStatusesDropdown,
                iWidth: 15
            }, {
                iUniqueColNum: 18,
                sTitle: this.get("_objCandidateProfileStringsService").getString("firstStatus"),
                sProperty: "first_status__status",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrStatusesDropdown,
                iWidth: 15,
                bStopFiltering: true
            }, {
                iUniqueColNum: 11,
                sTitle: this.get("_objCandidateProfileStringsService").getString("currentStatusSince"),
                sProperty: "current_status__date",
                sFilterType: "date",
                bDate: true,
                iWidth: 15,
                bStopFiltering: true
            }, {
                iUniqueColNum: 19,
                sTitle: this.get("_objCandidateProfileStringsService").getString("firstStatusSince"),
                sProperty: "first_status__date",
                sFilterType: "date",
                bDate: true,
                iWidth: 15,
                bStopFiltering: true
            }, {
                iUniqueColNum: 20,
                sTitle: this.get("_objCandidateProfileStringsService").getString("linkedToGroupSince"),
                sProperty: "linked_to_agency_group_at",
                sFilterType: "date",
                bDate: true,
                iWidth: 15,
                bStopFiltering: true
            }, {
                iUniqueColNum: 38,
                sTitle: this.get("_objCandidateProfileStringsService").getString("consultantRole"),
                sProperty: "consultants___consultant_role",
                bMapping: true,
                sFilterType: "dropdown",
                arrMappingData: arrConsultantRolesDropdown,
                iWidth: 15,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true,
                bStopFiltering: true,
                funcFilterParseQueryValue: function funcFilterParseQueryValue(objQueryObjectParam) {
                    var objQueryObject = Ember.copy(objQueryObjectParam, true);
                    for (var _i = 0; _i < objQueryObject.length; _i++) {
                        if (objQueryObject[_i].mValue === "*") {
                            objQueryObject[_i].bAny = true;
                        }
                    }

                    return objQueryObject;
                }
            }, {
                iUniqueColNum: 12,
                sTitle: this.get("_objCandidateProfileStringsService").getString("responsibleConsultant"),
                sProperty: "responsible_consultant",
                sFilterType: "text",
                bText: true,
                iWidth: 15,
                bFilterOr: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 6,
                sTitle: this.get("_objCandidateProfileStringsService").getString("city"),
                sProperty: "city",
                sFilterType: "text",
                bText: true,
                iWidth: 15,
                bFilterOr: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 7,
                sTitle: this.get("_objStringsService").getLocalisedString("province", sCountryCode),
                sProperty: "province",
                sFilterType: "text",
                bText: true,
                iWidth: 15,
                bFilterOr: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 8,
                sTitle: this.get("_objStringsService").getLocalisedString("postCode", sCountryCode),
                sProperty: "post_code",
                sFilterType: "text",
                bText: true,
                iWidth: 8,
                bFilterOr: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 41,
                sTitle: this.get("_objCandidateProfileStringsService").getString("distance"),
                sProperty: "physical_address_geo_location",
                bText: true,
                sFilterType: "custom",
                bSilent: true,
                iWidth: 15,
                bHideEmptyCheck: true,
                sContext: this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP"),
                sFilterCustomComponentName: "card-datagrid-custom-filter-candidate-distance",
                objFilterCustomService: this.get("_objFilterCustomCandidateDistanceService"),
                bStopFiltering: true
            }, {
                iUniqueColNum: 9,
                sTitle: this.get("_objStringsService").getString("username"),
                sProperty: "username",
                sFilterType: "text",
                bText: true,
                iWidth: 8,
                bStopFiltering: true
            }, {
                iUniqueColNum: 47,
                sTitle: this.get("_objCandidateProfileStringsService").getString("currentPayrollNumber"),
                sProperty: "current_employment_reference",
                sFilterType: "text",
                bText: true,
                iWidth: 8,
                bFilterOr: true,
                bStopFiltering: true,
                arrQueryType: Ember.copy(arrQueryType, true),
                bStopColumnSort: true
            }, {
                iUniqueColNum: 48,
                sTitle: this.get("_objCandidateProfileStringsService").getString("historicalPayrollNumber"),
                sProperty: "employment_references___reference",
                sFilterType: "text",
                bSilent: true,
                bText: true,
                iWidth: 8,
                bFilterOr: true,
                bStopFiltering: true,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 13,
                sTitle: this.get("_objStringsService").getString("email"),
                sProperty: "emails___email",
                sFilterType: "text",
                bText: true,
                bSilent: true,
                iWidth: 8,
                bStopFiltering: true
            }, {
                iUniqueColNum: 14,
                sTitle: this.get("_objStringsService").getString("contactNumber"),
                sProperty: "contact_numbers___number",
                sFilterType: "text",
                bText: true,
                bSilent: true,
                iWidth: 8,
                funcFilterParseQueryValue: function funcFilterParseQueryValue(objQueryObject) {
                    var arrValids = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
                    var sSearchFilter = "";
                    var bFirstChar = true;

                    // Doing a null string check because for some reason the mValue is a string
                    if (!a24Core.isEmpty(objQueryObject.mValue) && objQueryObject.mValue !== "null") {

                        // Loops over the mValue to build up a string without invalid chars for the filter
                        // Also replaces the invalid chars with a *
                        for (var i = 0; i < objQueryObject.mValue.length; i++) {
                            if (arrValids.indexOf(objQueryObject.mValue.charAt(i)) !== -1) {
                                if (bFirstChar) {
                                    // If the first char is a 0 then it will strip it off
                                    if (objQueryObject.mValue.charAt(i) !== "0") {
                                        sSearchFilter += objQueryObject.mValue.charAt(i);
                                    }
                                    bFirstChar = false;
                                } else {
                                    sSearchFilter += objQueryObject.mValue.charAt(i);
                                }
                            } else {
                                sSearchFilter += "*";
                            }
                        }

                        objQueryObject.mValue = sSearchFilter;
                    }
                    return objQueryObject;
                },
                bStopFiltering: true
            }, {
                iUniqueColNum: 15,
                sTitle: this.get("_objStringsService").getString("language"),
                sProperty: "languages___name",
                sFilterType: "dropdown",
                bSilent: true,
                bMapping: true,
                arrMappingData: arrLanguagesDropdown,
                iWidth: 8,
                bFilterSecondary: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 21,
                sTitle: this.get("_objCandidateProfileStringsService").getString("acknowledgedPolicies"),
                sProperty: "policies___type_id",
                sFilterType: "dropdown",
                bSilent: true,
                bMapping: true,
                arrMappingData: arrAckPoliciesDropdown,
                iWidth: 8,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true
            }, {
                iUniqueColNum: 22,
                sTitle: this.get("_objCandidateProfileStringsService").getString("positionHeld"),
                sProperty: "employment_position_held___job_family_id",
                bText: true,
                sFilterType: "custom",
                bSilent: true,
                iWidth: 15,
                bFilterAnd: true,
                bFilterOr: true,

                sFilterCustomComponentName: "card-datagrid-custom-filter-candidate-position-held",
                objFilterCustomService: this.get("_objFilterCustomCandidatePositionHeldService"),
                bStopFiltering: true
            }, {
                iUniqueColNum: 23,
                sTitle: this.get("_objSSCoreStringsService").getString("lastShiftDate"),
                sProperty: "last_shift_worked_at",
                sFilterType: "date",
                bDate: true,
                iWidth: 15,
                bStopFiltering: true
            }, {
                iUniqueColNum: 24,
                sTitle: this.get("_objSSCoreStringsService").getString("nextShiftDate"),
                sProperty: "next_shift_at",
                sFilterType: "date",
                bDate: true,
                iWidth: 15,
                bStopFiltering: true
            }, {
                iUniqueColNum: 25,
                sTitle: this.get("_objCandidateProfileStringsService").getString("activities"),
                sProperty: "activities___activity_type",
                sFilterType: "dropdown",
                bSilent: true,
                bMapping: true,
                arrMappingData: arrActivityTypeDropdown,
                iWidth: 8,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 26,
                sTitle: this.get("_objCandidateProfileStringsService").getString("firstTimeToStatus"),
                sProperty: "first_status_date___status",
                sFilterType: "dropdown",
                bSilent: true,
                bMapping: true,
                arrMappingData: arrStatusesDropdown,
                iWidth: 8,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 27,
                sTitle: this.get("_objCandidateProfileStringsService").getString("lastTimeToStatus"),
                sProperty: "last_status_date___status",
                sFilterType: "dropdown",
                bSilent: true,
                bMapping: true,
                arrMappingData: arrStatusesDropdown,
                iWidth: 8,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 28,
                sTitle: this.get("_objCandidateProfileStringsService").getString("externalDocs"),
                sProperty: "external_documents___type_id",
                sFilterType: "suggest",
                sReactFilterRoute: "datagrid/filterEditor/externalDocs",
                bSilent: true,
                iWidth: 8,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true
            }, {
                iUniqueColNum: 29,
                sTitle: this.get("_objStringsService").getString("documents"),
                sProperty: "documents___system_document_type_id",
                sFilterType: "dropdown",
                bSilent: true,
                bMapping: true,
                arrMappingData: arrDocumentTypesDropdown,
                iWidth: 8,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true,
                funcFilterParseQueryValue: function funcFilterParseQueryValue(arrQuery) {
                    var arrNewQuery = [];
                    for (var i = 0; i < arrQuery.length; i++) {
                        arrNewQuery.push(arrQuery[i]);
                        if (arrNewQuery[i].mValue === "*") {
                            arrNewQuery[i].bLike = true;
                        }
                    }

                    return arrNewQuery;
                },
                bStopFiltering: true
            }, {
                iUniqueColNum: 30,
                sTitle: this.get("_objCandidateProfileStringsService").getString("userStatus"),
                sProperty: "user_status",
                sFilterType: "dropdown",
                bMapping: true,
                arrMappingData: arrUserStatusDropdown,
                iWidth: 15,
                bFilterOr: true,
                bStopFiltering: true,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 31,
                sTitle: this.get("_objSSCoreStringsService").getString("lastLoggedIn"),
                sProperty: "last_login_at",
                sFilterType: "date",
                bDate: true,
                iWidth: 8,
                bStopFiltering: true
            }, {
                iUniqueColNum: 32,
                sTitle: this.get("_objCandidateProfileStringsService").getString("externalPortfolio"),
                sProperty: "external_portfolios___portfolio_id",
                bText: true,
                sFilterType: "custom",
                bSilent: true,
                iWidth: 15,
                bFilterAnd: true,
                bFilterOr: true,
                arrConsultantTypes: arrConsultantTypes,

                sFilterCustomComponentName: "card-datagrid-custom-filter-candidate-external-portfolios",
                objFilterCustomService: this.get("_objFilterCustomCandidateExternalPortfoliosService"),
                bStopFiltering: true
            }, {
                iUniqueColNum: 46,
                sTitle: this.get("_objCandidateProfileStringsService").getString("tags"),
                sProperty: "tags_links___tag_id",
                sFilterType: "suggest",
                sReactFilterRoute: "datagrid/filterEditor/candidate-tags",
                iWidth: 15,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 33,
                sTitle: this.get("_objCandidateProfileStringsService").getString("skill"),
                sProperty: "skill_details___skill_id",
                sFilterType: "suggest",
                sReactFilterRoute: "datagrid/filterEditor/skills",
                //Whatever property is listed in the arrReactFilterExtraDataPropswill be pulled from
                //_objDatagridConfig config and send on to react popup
                arrReactFilterExtraDataProps: ["candidate_types"],
                iWidth: 15,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true,
                funcFilterParseQueryValue: function funcFilterParseQueryValue(objQueryObjectParam) {
                    var objQueryObject = Ember.copy(objQueryObjectParam, true);
                    for (var _i2 = 0; _i2 < objQueryObject.length; _i2++) {
                        if (objQueryObject[_i2].mValue === "*") {
                            objQueryObject[_i2].bAny = true;
                        }
                        if (!a24Core.isEmpty(objQueryObject[_i2].arSec)) {
                            for (var _j = 0; _j < objQueryObject[_i2].arSec.length; _j++) {
                                if (objQueryObject[_i2].arSec[_j].sProp === "duration") {
                                    objQueryObject[_i2].arSec[_j].mValue = Math.ceil(objQueryObject[_i2].arSec[_j].mValue * 12);
                                }
                            }
                        }
                    }

                    return objQueryObject;
                }
            }, {
                iUniqueColNum: 34,
                sTitle: this.get("_objCandidateProfileStringsService").getString("qualification"),
                sProperty: "qualification_details___qualification_id",
                sFilterType: "suggest",
                sReactFilterRoute: "datagrid/filterEditor/qualifications_v2",
                //Whatever property is listed in the arrReactFilterExtraDataProps will be pulled from
                //_objDatagridConfig config and send on to react popup
                arrReactFilterExtraDataProps: ["candidate_types"],
                iWidth: 15,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true,
                funcFilterParseQueryValue: function funcFilterParseQueryValue(objQueryObjectParam) {
                    var objQueryObject = Ember.copy(objQueryObjectParam, true);
                    for (var _i3 = 0; _i3 < objQueryObject.length; _i3++) {
                        if (objQueryObject[_i3].mValue === "*") {
                            objQueryObject[_i3].bAny = true;
                        }
                    }

                    return objQueryObject;
                }
            }, {
                iUniqueColNum: 35,
                sTitle: this.get("_objCandidateProfileStringsService").getString("professionalRegistration"),
                sProperty: "registration_body_details___registration_body_id",
                sFilterType: "custom",
                sReactFilterRoute: "datagrid/filterEditor/professional-registration_v2",
                //Whatever property is listed in the arrReactFilterExtraDataProps will be pulled from
                //_objDatagridConfig config and send on to react popup
                arrReactFilterExtraDataProps: ["candidate_types"],
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                iWidth: 15,
                bFilterSecondary: true,
                sFilterCustomComponentName: "card-datagrid-custom-filter-candidate-professional-registration",
                objFilterCustomService: this.get("_objFilterCustomCandidateProfessionalRegistrationService")
            }, {
                iUniqueColNum: 44,
                sTitle: this.get("_objCandidateProfileStringsService").getString("profRegEntries"),
                sProperty: "registration_body_details___registration_body_entry_id",
                bText: true,
                sFilterType: "custom",
                sReactFilterRoute: "datagrid/filterEditor/professional-registration-entry",
                //Whatever property is listed in the arrReactFilterExtraDataProps will be pulled from
                //_objDatagridConfig config and send on to react popup
                arrReactFilterExtraDataProps: ["candidate_types"],
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                iWidth: 15,
                bHideEmptyCheck: true,
                sFilterCustomComponentName: "card-datagrid-custom-filter-candidate-professional-registration",
                objFilterCustomService: this.get("_objFilterCustomCandidateProfessionalRegistrationService"),
                bStopFiltering: true
            }, {
                iUniqueColNum: 36,
                sTitle: this.get("_objCandidateProfileStringsService").getString("externalVerification"),
                sProperty: "external_verifications___name",
                sFilterType: "dropdown",
                bSilent: true,
                bMapping: true,
                arrMappingData: arrExternalVerificationDropdown,
                iWidth: 8,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true
            },
            // Commented out for this ticket https://github.com/A24Group/react-core/issues/2994
            // {
            //     iUniqueColNum: 37,
            //     sTitle: this.get("_objCandidateProfileStringsService").getString("externalDaysToExpiry"),
            //     sProperty: "external_days_to_expiry___type_id",
            //     bText: true,
            //     sFilterType: "custom",
            //     sReactFilterRoute: "datagrid/filterEditor/externalDaysToExpiry",
            //     bSilent: true,
            //     iWidth: 15,
            //     bFilterAnd: true,
            //     bFilterOr: true,
            //     bFilterSecondary: true,
            //     arrExternalDaysTypes: arrExternalDaysTypes,

            //     sFilterCustomComponentName: "card-datagrid-custom-filter-candidate-external-days-to-expiry",
            //     objFilterCustomService: this.get("_objFilterCustomCandidateExternalDaysToExpiryService"),
            // },
            {
                iUniqueColNum: 39,
                sTitle: this.get("_objSSCoreStringsService").getString("keywords"),
                sProperty: "keywords___name",
                sFilterType: "text",
                iWidth: 15,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 40,
                sTitle: this.get("_objCandidateProfileStringsService").getString("lastSeenInApp"),
                sProperty: "chat_token_last_issued_at",
                sFilterType: "date",
                bDate: true,
                iWidth: 15,
                bStopFiltering: true
            }, {
                iUniqueColNum: 42,
                sTitle: this.get("_objCandidateProfileStringsService").getString("doNotContact"),
                sProperty: "notification_dnc",
                sFilterType: "bool",
                bHideEmptyCheck: true,
                bBool: true,
                bMapping: true,
                arrMappingData: [{
                    sValue: "true",
                    sTitle: this.get("_objStringsService").getString("yes")
                }, {
                    sValue: "false",
                    sTitle: this.get("_objStringsService").getString("no")
                }],
                iWidth: 8,
                bStopFiltering: true
            }, {
                iUniqueColNum: 43,
                sTitle: this.get("_objCandidateProfileStringsService").getString("notificationPreference"),
                sProperty: "medium_dnc___type",
                sFilterType: "dropdown",
                bMapping: false,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true,
                arrMappingData: arrMediaTypesDropdown,
                arrQueryType: [{
                    sLabel: this.get("_objStringsService").getString("is"),
                    sValue: this.get("_objConstantsLib").CARD_DATAGRID_FILTER_QUERY_TYPE_IS
                }],
                bCustom: true,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    var sMediumTypes = "";
                    if (!a24Core.isEmpty(objRowData.medium_dnc)) {
                        var arrRowMediumDncData = objRowData.medium_dnc;
                        for (var _i4 = 0; _i4 < arrRowMediumDncData.length; _i4++) {
                            if (!arrRowMediumDncData[_i4].value) {
                                var sMediumType = _this3.get("_objCandidateProfileEnumsService").getFrontendValue("mediumTypeDnc", arrRowMediumDncData[_i4].type);
                                if (a24Core.isEmpty(sMediumType)) {
                                    sMediumType = arrRowMediumDncData[_i4].type;
                                }
                                if (!a24Core.isEmpty(sMediumTypes)) {
                                    sMediumTypes += ", ";
                                }
                                sMediumTypes += sMediumType;
                            }
                        }
                    }
                    return Ember.String.htmlSafe("<span title=\"" + sMediumTypes + "\">" + sMediumTypes + "</span>");
                },
                iWidth: 15,
                bStopFiltering: true,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 45,
                sTitle: this.get("_objCandidateProfileStringsService").getString("transport"),
                sProperty: "transport_type",
                sFilterType: "dropdown",
                bHideEmptyCheck: false,
                bSilent: true,
                bMapping: false,
                arrMappingData: arrTransportOptionsDropdown,
                iWidth: 8,
                bStopFiltering: true
            }, {
                iUniqueColNum: 49,
                sTitle: this.get("_objCandidateProfileStringsService").getString("verifiedDocuments"),
                sProperty: "allocated_agency_group_verified_documents___document_type_id",
                sFilterType: "suggest",
                sReactFilterRoute: "datagrid/filterEditor/verifiedDocumentsCompliance",
                bSilent: true,
                iWidth: 8,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true
            }, {
                iUniqueColNum: 51,
                sTitle: this.get("_objCandidateProfileStringsService").getString("referenceCompliance"),
                sProperty: "reference_compliance___reference_compliance_standard_id",
                sFilterType: "suggest",
                sReactFilterRoute: "datagrid/filterEditor/referenceCompliance",
                bSilent: true,
                iWidth: 8,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true,
                // Once backend is done test that this works as expected
                funcFilterParseQueryValue: function funcFilterParseQueryValue(arrQuery) {
                    var arrNewQuery = Ember.copy(arrQuery, true);
                    for (var _i5 = 0; _i5 < arrNewQuery.length; _i5++) {
                        arrNewQuery[_i5].arSec.push({
                            sProp: 'candidate_match_enabled_position_type',
                            bEncode: true,
                            mValue: true
                        });
                    }

                    return arrNewQuery;
                }
            }, {
                iUniqueColNum: 52,
                sTitle: this.get("_objCandidateProfileStringsService").getString("hasNextOfKin"),
                sProperty: "has_next_of_kin",
                sFilterType: "bool",
                bSilent: true,
                bHideEmptyCheck: true,
                bBool: true,
                bMapping: true,
                arrMappingData: [{
                    sValue: "true",
                    sTitle: this.get("_objStringsService").getString("yes")
                }, {
                    sValue: "false",
                    sTitle: this.get("_objStringsService").getString("no")
                }],
                iWidth: 8
            }];
            if (this.get("_objUserSession.objContextDetails.sCountryId") === "826") {
                arrDataGridHeaderConfig.push({
                    iUniqueColNum: 16,
                    sTitle: this.get("_objCandidateProfileStringsService").getString("dbsStatus"),
                    sProperty: "dbs_status",
                    sFilterType: "dropdownMulti",
                    bMapping: true,
                    arrMappingData: arrDBSStatusDropdown,
                    iWidth: 15
                });
                arrDataGridHeaderConfig.push({
                    iUniqueColNum: 50,
                    sTitle: this.get("_objCandidateProfileStringsService").getString("externalWorkforce"),
                    sProperty: "external_workforce",
                    sFilterType: "dropdownMulti",
                    bMapping: true,
                    arrMappingData: arrDBSWorkforceDropdown,
                    iWidth: 15,
                    bStopColumnSort: true,
                    bHideEmptyCheck: true
                });
            }

            arrDataGridHeaderConfig.push({
                iUniqueColNum: 17,
                sTitle: this.get("_objCandidateProfileStringsService").getString("lastContactedAt"),
                sProperty: "last_contacted_at",
                sFilterType: "date",
                bDate: true,
                iWidth: 15,
                bStopFiltering: true
            });

            var arrSingleItemActions = [{
                sTitle: this.get("_objSSCoreStringsService").getString("openInNewTab"),
                sIcon: "open_in_new",
                executeCardAction: function executeCardAction() {
                    objThis.viewAllCandidatesInNewWindows(objThis.get("_arrSelectedItems"));
                }
            }];

            var arrMultiItemActions = [{
                sTitle: this.get("_objSSCoreStringsService").getString("openInNewTab"),
                sIcon: "open_in_new",
                executeCardAction: function executeCardAction() {
                    objThis.viewAllCandidatesInNewWindows(objThis.get("_arrSelectedItems"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    // Only show when selected items is not more than 10
                    return !a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length <= 10;
                }
            }];

            var arrProficiencyDropdown = this.get("_objCandidateProfileEnumsService").getList("languageProficiency").map(function (objProficiency) {
                return {
                    sTitle: objProficiency.sLabel,
                    sValue: objProficiency.sValue
                };
            });

            var arrDocumentStatusesDropdown = this.get("_objCandidateProfileEnumsService").getList("documentStatuses").map(function (objDocumentStatus) {
                return {
                    sTitle: objDocumentStatus.sLabel,
                    sValue: objDocumentStatus.sValue
                };
            });

            var arrSkillsStatusesDropdown = this.get("_objCandidateProfileEnumsService").getList("skillsStatusSearch").map(function (objSkillStatus) {
                return {
                    sTitle: objSkillStatus.sLabel,
                    sValue: objSkillStatus.sValue
                };
            });

            var arrQualificationsStatusesDropdown = this.get("_objCandidateProfileEnumsService").getList("qualificationsStatus").map(function (objQualificationsStatus) {
                return {
                    sTitle: objQualificationsStatus.sLabel,
                    sValue: objQualificationsStatus.sValue
                };
            });

            var iPerPage = 15;

            // Set up datagrid config
            this.set("_objDatagridConfig", {
                sDatagridHeader: this.get("_objSSCoreStringsService").getString("candidateSearch"),
                iMaxHeight: 40 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bHasReloadButton: true,
                bEmitDidDoServiceCall: true,
                bEmitWillDoServiceCall: true,
                iResultCountLimit: 251,
                arrSingleItemSelectActions: arrSingleItemActions,
                arrMultiItemSelectActions: arrMultiItemActions,
                arrHeaderConfig: arrDataGridHeaderConfig, // This array contain the datagrid columns
                sReactIFrameUrl: this.get("_sReactGatewayUrl"),
                candidate_types: arrCandidateTypes,
                iPerPage: iPerPage,
                arrFilterSecondary_consultants___consultant_role: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("consultant"),
                    sProperty: "user_name",
                    sFilterType: "text",
                    bHideEmptyCheck: true,
                    funcFilterPrimaryChange: function funcFilterPrimaryChange(sPrimaryValue, objPrimary, objSecondary) {
                        Ember.set(objSecondary, "bFilterRequired", sPrimaryValue === "*");
                    }
                }],
                arrFilterSecondary_policies___type_id: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("version"),
                    sProperty: "version",
                    bHideEmptyCheck: true,
                    sFilterType: "int"
                }],
                arrFilterSecondary_languages___name: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("languageProficiency"),
                    sProperty: "proficiency",
                    sFilterType: "dropdown",
                    bHideEmptyCheck: true,
                    arrMappingData: arrProficiencyDropdown
                }],
                arrFilterSecondary_activities___activity_type: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("lastOccurredAt"),
                    sProperty: "last_activity_at",
                    bHideEmptyCheck: true,
                    sFilterType: "date"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("numberOfOccurrences"),
                    sProperty: "total",
                    bHideEmptyCheck: true,
                    sFilterType: "int"
                }],
                arrFilterSecondary_first_status_date___status: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("withinDateRange"),
                    sProperty: "date",
                    sFilterType: "date",
                    bHideEmptyCheck: true,
                    bFilterRequired: true
                }],
                arrFilterSecondary_last_status_date___status: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("withinDateRange"),
                    sProperty: "date",
                    sFilterType: "date",
                    bHideEmptyCheck: true,
                    bFilterRequired: true
                }],
                arrFilterSecondary_external_days_to_expiry___type_id: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("expiresOn"),
                    sProperty: "value",
                    sFilterType: "date",
                    bTemporal: true,
                    bTimeless: true
                }],
                arrFilterSecondary_external_documents___type_id: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("uploaded"),
                    sProperty: "date",
                    bHideEmptyCheck: true,
                    sFilterType: "date",
                    bTemporal: true
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("expiry"),
                    sProperty: "expiry_date",
                    bHideEmptyCheck: true,
                    sFilterType: "date",
                    bTemporal: true
                }],
                arrFilterSecondary_documents___system_document_type_id: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("uploaded"),
                    sProperty: "uploaded_at",
                    bHideEmptyCheck: true,
                    sFilterType: "date"
                }, {
                    sTitle: this.get("_objStringsService").getString("status"),
                    sProperty: "status",
                    bHideEmptyCheck: true,
                    sFilterType: "dropdown",
                    arrMappingData: arrDocumentStatusesDropdown
                }],
                arrFilterSecondary_skill_details___skill_id: [{
                    sTitle: this.get("_objStringsService").getString("status"),
                    sProperty: "status",
                    bHideEmptyCheck: true,
                    sFilterType: "dropdown",
                    arrMappingData: arrSkillsStatusesDropdown,
                    bFilterRequired: false,
                    funcFilterPrimaryChange: function funcFilterPrimaryChange(sPrimaryValue, objPrimary, objSecondary) {
                        Ember.set(objSecondary, "bFilterRequired", sPrimaryValue === "*||" + objThis.get("_objStringsService").getString("any"));
                    }
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("yearsOfExperience"),
                    sProperty: "duration",
                    bHideEmptyCheck: true,
                    sFilterType: "int",
                    bFilterAllowFloat: true,
                    iFilterFloatPlaces: 1
                }],
                arrFilterSecondary_qualification_details___qualification_id: [{
                    sTitle: this.get("_objStringsService").getString("status"),
                    sProperty: "status",
                    bHideEmptyCheck: true,
                    sFilterType: "dropdown",
                    arrMappingData: arrQualificationsStatusesDropdown,
                    funcFilterPrimaryChange: function funcFilterPrimaryChange(sPrimaryValue, objPrimary, objSecondary) {
                        Ember.set(objSecondary, "bFilterRequired", sPrimaryValue === "*||" + objThis.get("_objStringsService").getString("any"));
                    }
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("obtainedAt"),
                    sProperty: "obtained_at",
                    bHideEmptyCheck: true,
                    sFilterType: "date",
                    bTemporal: true
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("expiresOn"),
                    sProperty: "expires_at",
                    bHideEmptyCheck: true,
                    sFilterType: "date",
                    bTemporal: true
                }],
                arrFilterSecondary_registration_body_details___registration_body_id: [{
                    sTitle: this.get("_objStringsService").getString("status"),
                    sProperty: "status_id",
                    sFilterType: "suggest"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("entry"),
                    sProperty: "registration_body_entry_id",
                    sFilterType: "suggest"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("entryStatus"),
                    sProperty: "registration_body_entry_status_id",
                    sFilterType: "suggest"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("expiresOn"),
                    sProperty: "expires_at",
                    sFilterType: "date",
                    bTemporal: true,
                    bTimeless: true
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("obtainedAt"),
                    sProperty: "obtained_at",
                    sFilterType: "date",
                    bTemporal: true,
                    bTimeless: true
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("validFrom"),
                    sProperty: "valid_at",
                    sFilterType: "date",
                    bTemporal: true,
                    bTimeless: true
                }],
                arrFilterSecondary_registration_body_details___registration_body_entry_id: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("professionalRegistration"),
                    sProperty: "registration_body_id",
                    sFilterType: "suggest",
                    bFilterHideTag: true
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("entryStatus"),
                    sProperty: "registration_body_entry_status_id",
                    sFilterType: "suggest"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("expiresOn"),
                    sProperty: "expires_at",
                    sFilterType: "date"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("obtainedAt"),
                    sProperty: "obtained_at",
                    sFilterType: "date"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("validFrom"),
                    sProperty: "valid_at",
                    sFilterType: "date"
                }],
                arrFilterSecondary_external_verifications___name: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("verified"),
                    sProperty: "value",
                    bHideEmptyCheck: true,
                    bFilterRequired: true,
                    sFilterType: "dropdown",
                    arrMappingData: [{
                        sValue: this.get("_objStringsService").getString("no"),
                        sTitle: this.get("_objStringsService").getString("no")
                    }, {
                        sValue: this.get("_objStringsService").getString("yes"),
                        sTitle: this.get("_objStringsService").getString("yes")
                    }]
                }],
                arrFilterSecondary_medium_dnc___type: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("enabled"),
                    sProperty: "value",
                    bHideEmptyCheck: true,
                    sFilterType: "bool",
                    bFilterRequired: true,
                    bBool: true,
                    arrMappingData: [{
                        sValue: "false",
                        sTitle: this.get("_objStringsService").getString("yes")
                    }, {
                        sValue: "true",
                        sTitle: this.get("_objStringsService").getString("no")
                    }]
                }],
                arrFilterSecondary_allocated_agency_group_verified_documents___document_type_id: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("verifiedAt"),
                    sProperty: "verified_at",
                    bHideEmptyCheck: true,
                    sFilterType: "date",
                    bTemporal: true
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("issuedAt"),
                    sProperty: "issued_at",
                    bHideEmptyCheck: true,
                    sFilterType: "date",
                    bTemporal: true
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("expireAt"),
                    sProperty: "expire_at",
                    bHideEmptyCheck: true,
                    sFilterType: "date",
                    bTemporal: true
                }],
                arrFilterSecondary_reference_compliance___reference_compliance_standard_id: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("isCompliant"),
                    sProperty: "is_compliant",
                    bHideEmptyCheck: true,
                    sFilterType: "suggest"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("expiry"),
                    sProperty: "compliance_expiry_date",
                    bHideEmptyCheck: true,
                    sFilterType: "date",
                    bTemporal: true
                }],
                objPermanentQuery: {
                    // Required fields, either by api or UI components
                    agency_group_id: {
                        mValue: 1,
                        bProject: true
                    },
                    candidate_id: {
                        mValue: 1,
                        bProject: true
                    },
                    full_name: {
                        mValue: 1,
                        bProject: true
                    },
                    first_name: {
                        mValue: 1,
                        bProject: true
                    },
                    last_name: {
                        mValue: 1,
                        bProject: true
                    }

                    // Optional based on column visibility
                    // gender
                    // candidate_type_id
                    // current_status
                    // first_status
                    // first_status_date
                    // status_since
                    // linked_to_agency_group_at
                    // city
                    // province
                    // post_code
                    // username
                    // last_shift_worked_at
                    // next_shift_at
                    // user_status
                    // last_login_at
                    // chat_token_last_issued_at
                    // notification_dnc
                    // medium_dnc
                    // last_contacted_at
                }
            });
            this.set("_bLoading", false);
            this.set("_bServiceCallsDone", true);
            if (this.get("_bHandleServerError") && !a24Core.isEmpty(this.get("onQueryPageReload"))) {
                this.set("_bHandleServerError", false);
                this.get("onQueryPageReload")(false);
            }
        },
        _handleServerErrors: function _handleServerErrors() {
            this.set("_bShowLoadFailedPopup", true);
        },
        viewAllCandidatesInNewWindows: function viewAllCandidatesInNewWindows(arrSelectedItems) {
            var _this4 = this;

            $.each(arrSelectedItems, function (iKey, objCandidate) {
                _this4.viewCandidateInNewWindow(objCandidate.candidate_id);
            });
        },
        viewCandidateInNewWindow: function viewCandidateInNewWindow(sCandidateId) {
            var sLink = this.get("_objNavigation").navigateWithinContext("candidate-search-view", {
                "candidate-search-view": [sCandidateId]
            }, null, true);
            // Open the candidate link
            this.openLink(sLink);
        },
        openLink: function openLink(sLink) {
            window.open(sLink);
        },

        _onForceReset: Ember.observer("bForceReset", function () {
            if (this.get("bForceReset")) {
                this.set("_objQueryParams", {});
                this.get("onForceResetTriggered")();
            }
        }),
        _runTagsUrlChange: function _runTagsUrlChange() {
            var _this5 = this;

            if (this.get("sSimpleTagsUrl") !== null) {
                var objSimpleTagsQueryParams = this.get("_objDatagridFilterService").transplantUrlValuesToDatagrid(this.get("_objQueryParams"), this.get("sSimpleTagsUrl"), ["page", "sortBy", "vis_cols"]);
                this.set("_objQueryParams", objSimpleTagsQueryParams);

                if (this.get("_bResetUrl")) {
                    this.set("_bResetUrl", false);
                } else {
                    Ember.run.next(function () {
                        if (a24Core.isEmpty(_this5)) {
                            return;
                        }
                        var arrHeaders = _this5.get("_objDatagridConfig.arrHeaderConfig");
                        var arrSimpleTags = _this5.get("_objDatagridFilterService").getSimpleTags(arrHeaders);

                        for (var i = 0; i < arrSimpleTags.length; i++) {
                            // We are specificly checking for ack policies here since the lookup could not be done for agency
                            if (!_this5.get("_bAgencyGroupContext") && arrSimpleTags[i].property === "policies___type_id") {
                                arrSimpleTags[i].title = arrSimpleTags[i].header + ": ";
                                arrSimpleTags[i].error = true;
                            } else if (a24Core.isEmpty(arrSimpleTags[i].value)) {
                                arrSimpleTags[i].title = arrSimpleTags[i].header + ": ";
                                arrSimpleTags[i].error = true;
                            }
                        }

                        _this5.get("onSimpleTagsAction")(arrSimpleTags);
                        if (!_this5.get("bShow")) {
                            _this5.set("_bResetUrl", true);
                            _this5.set("sSimpleTagsUrl", "");
                        }
                    });
                }
            }
        },
        _onTagsUrlChange: Ember.observer("sSimpleTagsUrl", function () {
            Ember.run.once(this, "_runTagsUrlChange");
        }),
        _onShowPopup: Ember.observer("bShow", function () {
            var _this6 = this;

            this.set("_bShowPopup", this.get("bShow"));
            if (this.get("bShow")) {
                if (!a24Core.isEmpty(this.get("_sQuery"))) {
                    this.set("_bDoneDisabled", false);
                } else {
                    this.set("_bDoneDisabled", true);
                }
            }
            Ember.run.next(function () {
                if (a24Core.isEmpty(_this6)) {
                    return;
                }
                $(window).trigger("resize");
            });
        }),
        onItemsSelect: function onItemsSelect(arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedItem", arrSelectedItems[0]);
            }
            // Set the selected items
            this.set("_arrSelectedItems", arrSelectedItems);
        },
        doCandidateView: function doCandidateView(sCandidateId) {
            var _this7 = this;

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this7)) {
                    return;
                }
                _this7.get("_objNavigation").navigateWithinContext("candidate-search-view", {
                    "candidate-search-view": [sCandidateId]
                });
            });
        },
        _onQueryBuilderPopupCancel: function _onQueryBuilderPopupCancel() {
            this.get("onPopupCloseAction")();
        },
        actions: {
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this.onItemsSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this.onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrSelectedItems) {
                this.onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableCell: function cardDatagridTableCell(sColumnName, objRowData) {
                if (sColumnName === "first_name" || sColumnName === "last_name" || sColumnName === "full_name") {

                    var sCandidateId = objRowData.candidate_id;

                    this.viewCandidateInNewWindow(sCandidateId);
                }
            },
            onQueryBuilderPopupCancel: function onQueryBuilderPopupCancel() {
                this._onQueryBuilderPopupCancel();
            },
            onPageReload: function onPageReload() {
                this.set("_bShowLoadFailedPopup", false);
                this.set("_bHandleServerError", true);
                if (a24Core.isEmpty(this.get("onQueryPageReload"))) {
                    this.get("onQueryPageReload")(true);
                }
                this.doServiceCalls();
            },
            onQueryBuilderPopupConfirm: function onQueryBuilderPopupConfirm() {
                if (!this.get("_bDoneDisabled")) {
                    this.set("_bDoneDisabled", true);
                    var sDatagridUrl = this.get("_objDatagridFilterService").getDatagridUrlQueryString(this.get("_objQueryParams"));

                    var arrHeaders = this.get("_objDatagridConfig.arrHeaderConfig");
                    var arrSimpleTags = this.get("_objDatagridFilterService").getSimpleTags(arrHeaders);
                    this.get("onPopupDoneAction")(arrSimpleTags, sDatagridUrl, this.get("_sQuery"));
                }
            },
            onDidDoServiceCall: function onDidDoServiceCall(arrTableData, jqXHR, objPOSTBody) {
                if (!a24Core.isEmpty(objPOSTBody)) {
                    if (!a24Core.isEmpty(objPOSTBody.query)) {
                        objPOSTBody.query = this.get("_objTemporalVariableService").convertQueryToTemporalVariables(Ember.copy(objPOSTBody.query, true), this.get("_objDatagridConfig"));
                    }
                    delete objPOSTBody.project;
                    delete objPOSTBody.items_per_page;
                    delete objPOSTBody.page;
                    delete objPOSTBody.sort;
                    if (!a24Core.isEmpty(objPOSTBody) && Object.keys(objPOSTBody).length !== 0) {
                        this.set("_bDoneDisabled", false);
                        this.set("_sQuery", JSON.stringify(objPOSTBody));
                    } else {
                        this.set("_bDoneDisabled", true);
                    }
                } else {
                    this.set("_bDoneDisabled", true);
                }
            },
            onWillDoServiceCall: function onWillDoServiceCall() {
                this.set("_bDoneDisabled", true);
                this.set("_sQuery", null);
                if (!a24Core.isEmpty(this.get("onWillDoServiceCall"))) {
                    return this.get("onWillDoServiceCall")(arguments[6]); // this is the success callback
                }
            }
        }
    });
});