define("a24-ember-candidate-profile/services/card-datagrid-custom-filter-consultant-role", ["exports", "a24-ember-lib/services/card-datagrid-filter-custom-base"], function (exports, _cardDatagridFilterCustomBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _cardDatagridFilterCustomBase.default.extend({

        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objEmberLibStringService: Ember.inject.service("a24-strings-ember-lib"),
        _objStringsService: Ember.inject.service("a24-strings"),

        funcFilterCustomPrimaryQuery: function funcFilterCustomPrimaryQuery(objHeaderItem /*, objHeaderSecondaries*/) {
            var objQuery = {};
            if (a24Core.isEmpty(objHeaderItem._objSelectedQueryType)) {
                objQuery = {
                    bEncode: true,
                    sFilterValue: objHeaderItem._objConsultantRole.sTitle,
                    mValue: objHeaderItem._objConsultantRole.sValue
                };
                if (!a24Core.isEmpty(objHeaderItem._objConsultant)) {
                    var sSecondaryProperty = "user_name";
                    var sSecondaryValueProperty = "name";
                    if (!a24Core.isEmpty(objHeaderItem.sSecondaryProperty)) {
                        sSecondaryProperty = objHeaderItem.sSecondaryProperty;
                    }
                    if (!a24Core.isEmpty(objHeaderItem.sSecondaryValueProperty)) {
                        sSecondaryValueProperty = objHeaderItem.sSecondaryValueProperty;
                    }

                    objQuery.arSec = [{
                        sProp: sSecondaryProperty,
                        sFilterValue: objHeaderItem._objConsultant.name,
                        bEncode: true,
                        mValue: objHeaderItem._objConsultant[sSecondaryValueProperty]
                    }];
                }
            } else {
                objQuery = {
                    bEncode: true,
                    sFilterValue: objHeaderItem._objConsultantRole.sTitle,
                    mValue: objHeaderItem._objConsultantRole.sValue,
                    bDelimitedNot: true
                };
            }

            if (objHeaderItem._objConsultantRole.sValue === "*") {
                objQuery.bAny = true;
            }

            return objQuery;
        },
        funcFilterCustomTag: function funcFilterCustomTag(objHeaderItem, objQuery, objHeadersSecondaries, bSkipTitle) {
            var sConsultantRoleTagKey = "consultantRoleTag";
            var sTag = "";
            var bIs = true;

            if (bSkipTitle) {
                sConsultantRoleTagKey = "consultantRoleTagNoTitle";
            }

            if (!a24Core.isEmpty(objQuery.bDelimitedNot)) {
                bIs = false;
            }

            sTag += this.get("_objCandidateProfileStringsService").getTokenString(sConsultantRoleTagKey, bIs ? this.get("_objStringsService").getString("is") : this.get("_objStringsService").getString("isNot"), objQuery.sFilterValue);
            if (!a24Core.isEmpty(objQuery.arSec)) {
                sTag += this.get("_objCandidateProfileStringsService").getTokenString("consultantTag", objQuery.arSec[0].sFilterValue);
            }
            return {
                sTag: sTag
            };
        },
        funcFilterCustomPrimaryReset: function funcFilterCustomPrimaryReset(objHeaderItem) {
            Ember.set(objHeaderItem, "_objConsultant", null);
            Ember.set(objHeaderItem, "_objConsultantRole", null);
        },

        funcFilterCustomFixupQuery: function funcFilterCustomFixupQuery() /*objHeader, objHeaderSecondaries*/{},

        funcFilterCustomSetEditState: function funcFilterCustomSetEditState(objHeaderItem, iIndex) {
            var objHeaderTag = objHeaderItem.mFilterValue[iIndex];

            Ember.set(objHeaderItem, "_objConsultantRole", {
                sValue: objHeaderTag.mValue,
                sTitle: objHeaderTag.sFilterValue
            });

            if (!a24Core.isEmpty(objHeaderTag.arSec)) {
                var objConsultant = {
                    name: objHeaderTag.arSec[0].sFilterValue
                };

                if (!a24Core.isEmpty(objHeaderItem.sSecondaryValueProperty)) {
                    var sSecondaryValueProperty = objHeaderItem.sSecondaryValueProperty;
                    objConsultant[sSecondaryValueProperty] = objHeaderTag.arSec[0].mValue;
                }
                Ember.set(objHeaderItem, "_objConsultant", objConsultant);
            }

            if (objHeaderTag.bDelimitedNot) {
                Ember.set(objHeaderItem, "_objSelectedQueryType", {
                    sValue: "is_not"
                });
            } else {
                Ember.set(objHeaderItem, "_objSelectedQueryType", null);
            }
        }
    });
});