define("a24-ember-staffshift-users/services/rest-agency-ip-whitelist", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        _sRestApiBaseUrl: Ember.computed.readOnly("_objEnv.sRestApiBaseUrl"),

        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * Gets all IP whitelistings
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  25 Sept 2019
         */
        getIpWhitelistings: function getIpWhitelistings(objContext, sProperty, funcSuccess, objFailure) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/ip-whitelist");

            a24RestUrlConstruct.addQueryParamObject({
                sortBy: {
                    mValue: "ip"
                }
            });

            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: {
                    mValue: 1000
                }
            });

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Add an IP whitelisting
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param objData - Post body for adding an IP whitelisting
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  25 Sept 2019
         */
        addIpToWhitelist: function addIpToWhitelist(objContext, sProperty, objData, funcSuccess, objFailure) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/ip-whitelist");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        },

        /**
         * Edit an IP whitelisting
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sIpAddress - The IP address
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         * @param objData - Post body for adding an IP whitelisting
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  25 Sept 2019
         */
        editIpWhitelist: function editIpWhitelist(objContext, sProperty, sIpAddress, funcSuccess, objFailure, objData) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/ip-whitelist/" + sIpAddress);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "PUT", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        },

        /**
         * Delete an IP whitelisting
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sIp - IP address to delete
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  25 Sept 2019
         */
        deleteIpWhitelist: function deleteIpWhitelist(objContext, sProperty, sIp, funcSuccess, objFailure) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/ip-whitelist/" + sIp);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "DELETE", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});