define("a24-ember-lib/components/input-form-suggest", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/templates/components/input-form-suggest", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _inputFormSuggest, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _inputFormSuggest.default,
        classNames: ["a24ErrorInputParent", "md-form"],
        sInputType: "suggest",
        sPlaceHolder: "",
        // Need to set this when using this component as this will be the display key for the dropdown
        sOptionsLabelKey: null,
        //This is the value that will display in the input after selecting suggestion, because sometimes you might want to
        //display more info on the suggested item that is not part of the search string.
        sOptionsLabelInputKey: null,
        // This is optional if you want a icon to display next to the entry in the dropdown
        sOptionsLinkKey: null,
        // This is for if you want a permanent item showed and the bottom of the dropdown
        objPermanentItem: null,
        // This is the display key from the above object to be sent in
        sPermanentLabelKey: null,
        /*
        When setting the function for the component you should set to variables for it.
        Variables are as follows
        - sFilter - This is the string to search for
        - funcCallback - Call this function at the end when setting up the function. The param of this funcCallback
          will be the array of results from the filter search
         */
        funcGetSuggestions: null,
        iSuggestAt: 3,
        bAlwaysShowOnFocus: false,
        iSuggestDelay: 500,
        bShowLoaderDuringSuggest: true,
        sToolTipKey: null,
        iMaxSuggestHeight: 192, // this is the total height of 5 items plus the top and bottom padding 36 * 5 + 6 + 6
        sCustomTabIndex: null,
        sCustomDropdownClass: null,
        _iPageWidth: null,
        _iPageHeight: null,
        _arrSuggestions: null,
        _bDropdownShow: false,
        _bPreventBlur: false,
        _bLoading: true,
        _iSelected: null,
        _sPreviousValue: null,
        _sDropdownClass: null,
        _bActionOnMouseDown: false,
        _objDropdownContainer: null,
        _objDomService: Ember.inject.service("dom-event"),
        _objEmberLibStringService: Ember.inject.service("a24-strings-ember-lib"),
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.get("_objDomService");
            this.set("_iPageWidth", this.get("_objDomService.iPageWidth"));
            this.set("_iPageHeight", this.get("_objDomService.iPageHeight"));

            this.set("_bLoading", this.get("bShowLoaderDuringSuggest"));

            var sDropdownClass = "input-form-suggest-dropdown";
            if (!a24Core.isEmpty(this.get("sCustomDropdownClass"))) {
                sDropdownClass += " " + this.get("sCustomDropdownClass");
            }
            this.set("_sDropdownClass", sDropdownClass);

            Ember.run.scheduleOnce("afterRender", function () {
                var objTextInput = $("#" + _this.get("_sInputId"));
                var iSelected = null;

                if (a24Core.isEmpty(_this.get("sOptionsLabelInputKey"))) {
                    //If you did not supply a sOptionsLabelInputKey we default it to the same as sOptionsLabelKey, thus
                    //this component will function exatly as before when no sOptionsLabelInputKey is supplied.
                    _this.set("sOptionsLabelInputKey", _this.get("sOptionsLabelKey"));
                }

                _this.set("_sPreviousValue", objTextInput.val());

                var objRunLater = null;

                objTextInput.bind("keydown", function (objEvent) {
                    if (!a24Core.isEmpty(objEvent.which) && (objEvent.which === 33 || //page up
                    objEvent.which === 34 || //page down
                    objEvent.which === 35 || //end
                    objEvent.which === 36 //home
                    )) {
                        objEvent.stopPropagation();
                        objEvent.preventDefault();
                    } else if (!a24Core.isEmpty(objEvent.which) && objEvent.which === 38) {
                        // Up Arrow
                        objEvent.stopPropagation();
                        objEvent.preventDefault();

                        Ember.run(function () {
                            if (a24Core.isEmpty(_this.get("_arrSuggestions"))) {
                                return;
                            }

                            iSelected = _this.get("_iSelected");
                            if (iSelected === null) {
                                iSelected = 0;
                            } else {
                                if (iSelected !== 0) {
                                    iSelected = iSelected - 1;
                                }
                            }
                            _this.get("_arrSuggestions").setEach("_bSuggestionSelected", false);
                            _this.set("_arrSuggestions." + iSelected + "._bSuggestionSelected", true);

                            _this.set("_iSelected", iSelected);

                            _this._scrollItemIntoView();
                        });
                    } else if (!a24Core.isEmpty(objEvent.which) && objEvent.which === 40) {
                        // Down Arrow
                        objEvent.stopPropagation();
                        objEvent.preventDefault();

                        Ember.run(function () {
                            if (a24Core.isEmpty(_this.get("_arrSuggestions"))) {
                                return;
                            }

                            iSelected = _this.get("_iSelected");
                            if (iSelected === null) {
                                iSelected = 0;
                            } else {
                                if (iSelected !== _this.get("_objDropdownContainer").find(".input-form-suggest-content-list li").length - 1) {
                                    iSelected = iSelected + 1;
                                }
                            }
                            _this.get("_arrSuggestions").setEach("_bSuggestionSelected", false);
                            _this.set("_arrSuggestions." + iSelected + "._bSuggestionSelected", true);

                            _this.set("_iSelected", iSelected);

                            _this._scrollItemIntoView();
                        });
                    }
                });

                // Binds the below events for when typing into the text box. This will set the suggestions and show dropdown
                objTextInput.bind("click keyup input paste", function (objEvent) {
                    if (!a24Core.isEmpty(_this.get("onErrorMessageSetAction"))) {
                        _this.get("onErrorMessageSetAction")(_this, parseInt(_this.$().css("margin-top")));
                    }

                    //We want to ignore some keys that dont have anything to do with input value changing, like arrow keys
                    if (!a24Core.isEmpty(objEvent.which) && (objEvent.which === 13 || //enter
                    objEvent.which === 16 || //shift
                    objEvent.which === 17 || //ctrl
                    objEvent.which === 18 || //alt
                    objEvent.which === 20 || //caps lock
                    objEvent.which === 37 || //left
                    objEvent.which === 38 || //up
                    objEvent.which === 39 || //right
                    objEvent.which === 40 //down
                    )) {
                        return;
                    }

                    // sets the mValue to null when typing
                    if (!a24Core.isEmpty(_this.get("mValue")) && _this.get("_sPreviousValue") !== objTextInput.val()) {
                        _this.setMValueWithInternalFlag(null, false);
                        //We refocus the input here since we know in some cases input will loase focus if other fields hide/display when
                        //this field goes empty, the DOM render will defocus this field.
                        objTextInput.focus();
                    }

                    if (_this.get("_sPreviousValue") !== objTextInput.val() || objTextInput.val().length === 0 && _this.get("iSuggestAt") === 0 || _this.get("bAlwaysShowOnFocus")) {
                        //We do not clear the _arrSuggestions when you set bShowLoaderDuringSuggest to false since we assume
                        //that in that scenario you either will supply results immediatly or want results showing while user
                        //types
                        if (_this.get("bShowLoaderDuringSuggest")) {
                            _this.set("_bLoading", true);
                            _this.set("_arrSuggestions", []);
                        }
                        _this.set("_sPreviousValue", objTextInput.val());

                        if (objTextInput.val().length >= _this.get("iSuggestAt")) {

                            if (!a24Core.isEmpty(objRunLater)) {
                                Ember.run.cancel(objRunLater);
                            }
                            var funcCallback = function funcCallback(arrSuggestions) {
                                if (!a24Core.isEmpty(arrSuggestions)) {
                                    for (var i = 0; i < arrSuggestions.length; i++) {
                                        if (!a24Core.isEmpty(arrSuggestions[i].arrActions)) {
                                            for (var j = 0; j < arrSuggestions[i].arrActions.length; j++) {
                                                if (!a24Core.isEmpty(arrSuggestions[i].arrActions[j].executeCardAction)) {
                                                    (function () {
                                                        var funcAction = arrSuggestions[i].arrActions[j].executeCardAction;
                                                        arrSuggestions[i].arrActions[j].executeCardAction = function () {
                                                            _this.set("_bDropdownShow", false);
                                                            funcAction();
                                                        };
                                                    })();
                                                }
                                            }
                                        }
                                    }
                                }

                                _this.set("_arrSuggestions", arrSuggestions);

                                _this.set("_bLoading", false);
                            };

                            var funcRunLater = function funcRunLater() {
                                _this.get("funcGetSuggestions")(objTextInput.val(), funcCallback);
                            };

                            objRunLater = Ember.run.later(funcRunLater, _this.get("iSuggestDelay"));

                            _this.set("_bDropdownShow", true);
                            _this.set("_iSelected", null);
                        } else {
                            _this.set("_bDropdownShow", false);
                        }
                    }
                });
                // This is key up events for the arrow keys and the enter when selecting a input
                objTextInput.on("keyup", function (objEvent) {
                    if (objEvent.which === 13) {
                        // Enter
                        var iSelect = _this.get("_iSelected");
                        if (iSelect === null && !a24Core.isEmpty(_this.get("_arrSuggestions"))) {
                            _this.setSelectedItem(_this.get("_arrSuggestions.0"));
                        } else if (iSelect !== null && !a24Core.isEmpty(_this.get("_arrSuggestions"))) {
                            _this.setSelectedItem(_this.get("_arrSuggestions." + iSelect));
                        }

                        _this.set("_bDropdownShow", false);
                    } else if (objEvent.which === 27) {
                        // esc
                        objEvent.stopPropagation();
                        _this.set("_bDropdownShow", false);
                    }
                });

                _this.$("#" + _this.get("_sInputId")).on("keyup", function (objEvent) {
                    if (objEvent.which === 27) {
                        // esc
                        objEvent.stopPropagation();
                        _this.set("_bDropdownShow", false);
                    }
                });

                if (!a24Core.isEmpty(_this.get("mValue"))) {
                    // firing a change here will cause the mvalue to update to
                    // the same value, hence not causing a duplicate event to be fired
                    objTextInput.val(_this.get("mValue")[_this.get("sOptionsLabelInputKey")]);
                    objTextInput.change();

                    _this.resetFloatingLabel(objTextInput);
                }

                objTextInput.on("blur", function () {
                    // This is to prevent the blur to happen when dropdown is clicked when clicking the link
                    if (_this.get("_bPreventBlur")) {
                        _this.set("_bPreventBlur", false);
                    } else {
                        _this.set("_bDropdownShow", false);
                        _this.resetFloatingLabel(objTextInput);
                    }
                });

                var objDropdownContainer = _this.$(".input-form-suggest-dropdown-container");
                _this.set("_objDropdownContainer", objDropdownContainer);
                objDropdownContainer.appendTo("body");

                // We are blocking scrolling on the blocker div. This will help when the dropdown shows
                // that the whole page does not scroll that will place the dropdown on the wrong position.
                objDropdownContainer.on("scroll wheel DOMMouseScroll mousewheel touchmove", function (objEvent) {
                    objEvent.stopPropagation();
                });

                if (!a24Core.isEmpty(_this.get("sFieldName")) && !a24Core.isEmpty(_this.get("sPlaceHolder"))) {
                    _this.$("label").addClass("active");
                }
            });
        },
        sizeChanges: Ember.observer("_objDomService.iPageWidth", "_objDomService.iPageHeight", function () {
            if (this.get("_bDropdownShow") && (this.get("_objDomService.iPageWidth") !== this.get("_iPageWidth") || this.get("_objDomService.iPageHeight") !== this.get("_iPageHeight"))) {
                this.set("_bDropdownShow", false);
            }
            this.set("_iPageWidth", this.get("_objDomService.iPageWidth"));
            this.set("_iPageHeight", this.get("_objDomService.iPageHeight"));
        }),
        _onDropdownShow: Ember.observer("_bDropdownShow", function () {
            var _this2 = this;

            if (this.get("_bDropdownShow")) {
                this.positionDropdown();

                //This is to cater for shdow scroller that does a 100 scroll animation, we can fix this better in future.
                Ember.run.later(this, this.positionDropdown, 150);

                Ember.$("body").addClass("stop-scrolling-suggest-input");
            } else {
                Ember.run.scheduleOnce("afterRender", function () {
                    _this2.get("_objDropdownContainer").css({
                        top: "0px",
                        left: "0px",
                        width: "0px",
                        height: "0px"
                    });

                    //This style needs to be made pre component else tabbing from one to another makes that the closing of the
                    //one removes the stop-scrolling style added by the new one opened.
                    //I would suggest adding this components ember id as a class to the body aswell and then only removing
                    //stop-scrolling-suggest-input when its id is also there
                    Ember.$("body").removeClass("stop-scrolling-suggest-input");
                });
            }
        }),
        positionDropdown: function positionDropdown() {
            var _this3 = this;

            if (this.get("_bDropdownShow")) {
                Ember.run.scheduleOnce("afterRender", function () {
                    var objOffset = _this3.$(".input-form-suggest-dropdown-placeholder").offset();
                    var iHeight = _this3.get("iMaxSuggestHeight");

                    //Calc the height the popup should be based on the viewport and where the popup shows relative to the
                    //viewport
                    var iTopPos = _this3.$(".input-form-suggest-dropdown-placeholder")[0].getBoundingClientRect().top;
                    var iBodyHeight = Ember.$(window).height();
                    if (iBodyHeight - iTopPos < iHeight + 5) {
                        iHeight = iBodyHeight - iTopPos - 5; // minus 5 to allow the bottom shadow visibility
                    }

                    _this3.get("_objDropdownContainer").css({
                        top: objOffset.top,
                        left: objOffset.left,
                        width: _this3.$(".input-form-suggest-dropdown-placeholder").width(),
                        height: iHeight
                    });
                });
            }
        },
        clear: function clear() {
            this.setValueFromOutside(null);
        },
        willDestroyElement: function willDestroyElement() {
            this._super();
            if (!a24Core.isEmpty(this.get("_objDropdownContainer"))) {
                this.get("_objDropdownContainer").remove();
            }
        },
        setValueFromOutside: function setValueFromOutside(mValue) {
            this.setMValueWithInternalFlag(mValue, false);

            //Set the new value onto the input since it does not bind anymore
            var objTextInput = $("#" + this.get("_sInputId"));
            if (a24Core.isEmpty(mValue)) {
                objTextInput.val("");
            } else {
                objTextInput.val(mValue[this.get("sOptionsLabelInputKey")]);
            }
            objTextInput.change();
            this.set("_sPreviousValue", objTextInput.val());

            this.resetFloatingLabel(objTextInput);
        },
        resetFloatingLabel: function resetFloatingLabel(objTextInput) {
            if (!a24Core.isEmpty(this.get("sFieldName")) && !a24Core.isEmpty(this.get("sPlaceHolder"))) {
                return;
            }

            //If the input field has no value then the label should NOT have the active class and
            //float at placeholder position
            if (a24Core.isEmpty(objTextInput.val())) {
                this.$("label").removeClass("active");
            }
        },
        setSelectedItem: function setSelectedItem(objItem) {
            if (!a24Core.isEmpty(objItem) && !a24Core.isEmpty(objItem._bSuggestionSelected)) {
                delete objItem._bSuggestionSelected;
            }

            this.setMValueWithInternalFlag(objItem, false);

            var objTextInput = $("#" + this.get("_sInputId"));
            // Set input value after click
            objTextInput.val(objItem[this.get("sOptionsLabelInputKey")]);
            objTextInput.change();
            objTextInput.blur();

            this.set("_sPreviousValue", objTextInput.val());

            this.set("_iSelected", null);
        },
        _scrollItemIntoView: function _scrollItemIntoView() {
            var _this4 = this;

            Ember.run.scheduleOnce("afterRender", function () {
                var objList = _this4.get("_objDropdownContainer").find(".a24-dropdown-content-box");
                var objItem = _this4.get("_objDropdownContainer").find(".input-form-suggest-content-list li.hover");
                if (a24Core.isEmpty(objList) || a24Core.isEmpty(objItem)) {
                    return;
                }
                if (objItem.offset().top - objItem.height() < objList.offset().top) {
                    var iScrollTo = objItem.offset().top - objList.offset().top + objList.scrollTop() - objItem.height();
                    objList.scrollTop(iScrollTo);
                } else if (objItem.offset().top + objItem.height() * 2 > objList.offset().top + objList.height()) {
                    var _iScrollTo = objItem.offset().top - objList.offset().top + objList.scrollTop() - objList.height() + objItem.height() * 2;
                    objList.scrollTop(_iScrollTo);
                }
            });
        },
        // might need to move this to emberlib some time
        _funcOpenWindow: function _funcOpenWindow(sLink) {
            window.open(sLink);
        },
        actions: {
            onActionClick: function onActionClick() {
                var objTextInput = $("#" + this.get("_sInputId"));
                this.resetFloatingLabel(objTextInput);
                this.set("_bPreventBlur", true);
                this.set("_bActionOnMouseDown", true);
            },
            closeSuggestDropdown: function closeSuggestDropdown() {
                this.set("_bDropdownShow", false);
                this.set("_iSelected", null);
            },
            setSelectedItemAction: function setSelectedItemAction(objItem) {
                if (this.get("_bActionOnMouseDown")) {
                    this.set("_bActionOnMouseDown", false);
                } else {
                    this.set("_bPreventBlur", false);
                    this.setSelectedItem(objItem);
                }
            },
            onPermanentItemAction: function onPermanentItemAction(objItem) {
                this.get("onPermanentItemAction")(objItem);
                this.set("_bDropdownShow", false);
            },
            onLinkAction: function onLinkAction(sOptionsLink, objEvent) {
                this.set("_bPreventBlur", true);
                objEvent.stopPropagation();
                this._funcOpenWindow(sOptionsLink);
            }
        }
    });
});