define("a24-ember-form-inputs/components/input-form-time-slot", ["exports", "a24-ember-form-inputs/templates/components/input-form-time-slot", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/mixins/input-form-element"], function (exports, _inputFormTimeSlot, _didRenderChangesMixin, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _inputFormTimeSlot.default,
        classNames: ["input-form-time-slot", "md-form", "a24ErrorInputParent"],
        sInputType: "timeslot",
        bIsOutline: false,
        bDisabled: false,
        bSpinHourValue: true,
        bSpinMinuteValue: true,
        bMinuteUpdatesHour: false,
        bForceMinuteStep: false,
        iHourMinValue: 0,
        iHourMaxValue: 23,
        iMinuteMinValue: 0,
        iMinuteMaxValue: 59,
        iHourStepValue: 1,
        iMinuteStepValue: 15,

        _iMinuteMinValue: null,
        _iMinuteMaxValue: null,

        init: function init() {
            this._super.apply(this, arguments);
            this.set("_iMinuteMinValue", this.get("iMinuteMinValue"));
            this.set("_iMinuteMaxValue", this.get("iMinuteMaxValue"));
        },
        onInputValueChanged: function onInputValueChanged(arrChildRef) {
            if (a24Core.isEmpty(arrChildRef)) {
                return;
            }
            if (arrChildRef.join(".") === "objChildren.iHour") {
                Ember.run.once(this, this._setMinMaxMinute);
            }
            Ember.run.once(this, this._setMValue);
        },
        _setMValue: function _setMValue() {
            this.setMValueWithInternalFlag({
                iHour: this.get("objChildren.iHour.mValue"),
                iMinute: this.get("objChildren.iMinute.mValue")
            }, false);
        },
        _setMinMaxMinute: function _setMinMaxMinute() {
            var iHour = this.get("objChildren.iHour.mValue");
            if (!a24Core.isEmpty(iHour) && iHour > this.get("iHourMinValue")) {
                this.set("_iMinuteMinValue", 0);
            } else {
                this.set("_iMinuteMinValue", this.get("iMinuteMinValue"));
            }

            if (!a24Core.isEmpty(iHour) && iHour < this.get("iHourMaxValue")) {
                this.set("_iMinuteMaxValue", 59);
            } else {
                this.set("_iMinuteMaxValue", this.get("iMinuteMaxValue"));
            }
        },
        clear: function clear() {
            this.get("objChildren.iHour").clear();
            this.get("objChildren.iMinute").clear();
        },
        setValueFromOutside: function setValueFromOutside(objValue) {
            if (!a24Core.isEmpty(objValue.iHour)) {
                this.get("objChildren.iHour").setValueFromOutside(objValue.iHour);
            }
            if (!a24Core.isEmpty(objValue.iMinute)) {
                this.get("objChildren.iMinute").setValueFromOutside(objValue.iMinute);
            }
        },
        checkExtraTypes: function functionName() {
            return {
                bHandled: true,
                mReturned: this.get("mValue")
            };
        },
        // This is here to prevent the default implementation which
        // appends a div to both number inputs when there is an error
        showErrorMessage: function showErrorMessage() {},
        actions: {
            onSpinMaxMinute: function onSpinMaxMinute() {
                if (this.get("bMinuteUpdatesHour")) {
                    var iHourStep = this.get("iHourStepValue");
                    var iHour = this.get("objChildren.iHour.mValue");

                    if (iHour > this.get("iHourMinValue")) {
                        this.get("objChildren.iHour").setValueFromOutside(iHour - iHourStep);
                    } else {
                        this.get("objChildren.iHour").setValueFromOutside(this.get("iHourMaxValue"));
                    }
                }
            },

            onSpinMinMinute: function onSpinMinMinute() {
                if (this.get("bMinuteUpdatesHour")) {
                    var iHour = this.get("objChildren.iHour.mValue");
                    var iHourStep = this.get("iHourStepValue");

                    if (iHour < this.get("iHourMaxValue")) {
                        this.get("objChildren.iHour").setValueFromOutside(iHour + iHourStep);
                    } else {
                        this.get("objChildren.iHour").setValueFromOutside(this.get("iHourMinValue"));
                    }
                }
            }
        }
    });
});