define("a24-ember-candidate-profile/services/rest-agency-global-search", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _sRestApiBaseUrl: Ember.computed.readOnly("_objEnv.sRestApiBaseUrl"),

        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * Makes a service call to do a global search using the query object provided
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param objData - The query object
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         * @param objQueryParams (Optional) - The query params to filter on 
         *
         * @author Deon De Wet<deon.dewet@a24group.com>
         * @since  14 Jan 2022
         */
        search: function search(objContext, sProperty, objData, funcSuccess, objFailure, objQueryParams) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/global-search-suggestions");

            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: {
                    mValue: 10
                }
            });

            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        }
    });
});