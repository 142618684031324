define("a24-ember-candidate-profile/components/candidate-misc-details", ["exports", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-misc-details"], function (exports, _inputFormElement, _didRenderChangesMixin, _candidateMiscDetails) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _candidateMiscDetails.default,
        objSettingsRestService: null,
        sCandidateId: null,
        objMiscDetailsRestService: null,
        _bLoading: false,
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _sPopupHeader: null,
        _arrMiscData: null,
        _objMiscDetailsData: null,
        _objOriginalData: null,
        _objSpecialEditPopupAction: null,
        _objMiscDetailsGetPromise: null,
        _objMiscDetailsUpdatePromise: null,
        _arrActionItems: null,
        _bAllowEdit: true,
        _bShowUnsavedData: false,
        _arrTransportTypeDropdownItems: null,
        _objSelectedTransportType: null,
        _bShowEditPopup: false,
        _bPopupLoading: false,

        _bServerError: false,
        _arrReloadActions: null,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.getCandidateMiscDetails();
                }
            }]);

            if (!this.get("objSettingsRestService.objSettings.profile_editable")) {
                this.set("_bAllowEdit", false);
            }

            this.set("_objSpecialEditPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onPopupClose");
                }
            });

            this.set("_arrActionItems", []);

            var sPopupHeader = this.get("_objStringsService").getTokenString("editItem", this.get("_objCandidateProfileStringsService").getString("miscDetails"));

            this.set("_sPopupHeader", sPopupHeader);

            this.set("_arrTransportTypeDropdownItems", this.get("_objCandidateProfileEnumsService").getList("transportType"));

            this.getCandidateMiscDetails();
        },
        getCandidateMiscDetails: function getCandidateMiscDetails() {
            var _this2 = this;

            this.set("_bLoading", true);
            this.set("_bServerError", false);

            var sCandidateId = this.get("sCandidateId");

            this.get("objMiscDetailsRestService").getMiscDetails(this, "_objMiscDetailsGetPromise", sCandidateId, function (objData) {
                _this2.getMiscDetailsDone();
                _this2.prepareForDisplay(objData);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
            function (sCode) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this2.set("_bServerError", true);
                    return;
                }
                _this2.getMiscDetailsDone();
            }));
        },
        getMiscDetailsDone: function getMiscDetailsDone() {
            var _this3 = this;

            if (this.get("_bAllowEdit")) {
                this.set("_arrActionItems", [{
                    sTitle: this.get("_objStringsService").getString("edit"),
                    sIcon: "edit",
                    executeCardAction: function executeCardAction() {
                        _this3.openEditPopup();
                    }
                }]);
            }
            this.set("_bLoading", false);
        },
        prepareForDisplay: function prepareForDisplay(objData) {
            var sTransportType = null;

            if (!a24Core.isEmpty(objData) && !a24Core.isEmpty(objData.transport_type)) {
                sTransportType = this.get("_objCandidateProfileEnumsService").getFrontendValue("transportType", objData.transport_type);
            }

            this.set("_objMiscDetailsData", objData);

            this.set("_arrMiscData", [{
                arrItems: [{
                    sLabel: this.get("_objCandidateProfileStringsService").getString("transportType"),
                    sValue: sTransportType,
                    bText: true
                }]
            }]);
        },
        openEditPopup: function openEditPopup() {
            var _this4 = this;

            this.clearAllErrorMessages();
            this.clearForm();

            this.set("_bShowEditPopup", true);
            if (!a24Core.isEmpty(this.get("setBreadCrumbAction"))) {
                this.get("setBreadCrumbAction")();
            }

            var objMiscDetailsData = this.get("_objMiscDetailsData");

            if (!a24Core.isEmpty(objMiscDetailsData)) {
                this.setSelectedPopupValues(objMiscDetailsData);
            }

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this4)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    _this4.set("_objOriginalData", _this4.getPlainFormValueObject());
                });
            });
        },
        setSelectedPopupValues: function setSelectedPopupValues(objData) {
            if (!a24Core.isEmpty(objData) && !a24Core.isEmpty(objData.transport_type)) {
                this.set("_objSelectedTransportType", {
                    sLabel: this.get("_objCandidateProfileEnumsService").getFrontendValue("transportType", objData.transport_type),
                    sValue: objData.transport_type
                });
            }
        },
        getPayload: function getPayload() {
            return a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());
        },
        updateMiscDetails: function updateMiscDetails() {
            var _this5 = this;

            this.set("_bPopupLoading", true);

            var sCandidateId = this.get("sCandidateId");

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objCandidateProfileStringsService").getString("miscDetails")));

            this.get("objMiscDetailsRestService").updateMiscDetails(this, "_objMiscDetailsUpdatePromise", sCandidateId, this.getPayload(), function () {
                _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("successUpdateItem", _this5.get("_objCandidateProfileStringsService").getString("miscDetails")));
                _this5.set("_bPopupLoading", false);
                _this5.set("_bShowEditPopup", false);
                _this5.getCandidateMiscDetails();
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("failUpdateItem", _this5.get("_objCandidateProfileStringsService").getString("miscDetails")));

                _this5.set("_bPopupLoading", false);
            }));
        },
        actions: {
            onPopupClose: function onPopupClose() {
                if (!this.get("_bPopupLoading")) {
                    var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));
                    if (bDataMatches) {
                        this.set("_bShowEditPopup", false);
                    } else {
                        this.set("_bShowUnsavedData", true);
                    }
                }
            },

            onPopupEditConfirm: function onPopupEditConfirm() {
                this.updateMiscDetails();
            },

            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowEditPopup", false);
                this.set("_bShowUnsavedData", false);
            }
        }
    });
});