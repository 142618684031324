define("a24-ember-lib/helpers/array-filter", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.arrayFilter = arrayFilter;


    /**
     * This function will return a filtered array
     *
     * @param arrParams - The array of params sent to the helper
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     * @since  03 September 2018
     */
    function arrayFilter(arrParams) {
        var arrItems = arrParams[0];

        if (a24Core.isEmpty(arrItems)) {
            return arrItems;
        }
        var funcFilterFunc = arrParams[1];

        if (arrItems.filter && funcFilterFunc) {
            arrItems = arrItems.filter(funcFilterFunc);
        }

        var funcSortFunc = arrParams[2];
        if (funcSortFunc) {
            arrItems = funcSortFunc(arrItems);
        }

        return arrItems;
    }

    exports.default = Ember.Helper.helper(arrayFilter);
});