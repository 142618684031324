define("a24-ember-candidate-profile/components/candidate-add-popup", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/mixins/input-form-element", "a24-ember-candidate-profile/templates/components/candidate-add-popup"], function (exports, _didRenderChangesMixin, _inputFormElement, _candidateAddPopup) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _candidateAddPopup.default,
        bShowAddEditPopup: false,
        objCandidateDetails: null,
        objCandidateTypes: null,
        bLoading: true,

        bIsAgencyContext: false,

        _bPopupLoading: false,
        _bLinkPopupLoading: false,
        _bShowAddEditPopup: false,
        _bShowUnsavedData: false,
        _bShowLinkedPopup: false,
        _bShowUnlinkedPopup: false,
        _bShowUnverifiedPopup: false,
        _bServiceCallsDone: false,
        _bInputsReady: false,

        _sDateOfBirth: null,
        _sHeaderPopup: null,
        _sUnlinkedMessage: null,
        _sLinkedMessage: null,
        _sCandidateId: null,
        _sCountryCode: null,

        _arrCandidateTypeDropdown: null,

        _objSpecialPopupAction: null,
        _objOriginalData: null,

        // Services
        _objUserSession: Ember.inject.service("user-session"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSSCoreStringsService: Ember.inject.service("a24-strings-staffshift-core"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objFormInputsConstants: Ember.inject.service("a24-constants-form-inputs"),
        _objFormInputsStringsService: Ember.inject.service("a24-strings-form-inputs"),

        // Rest Services
        objRestCandidateType: null,
        objRestCandidate: null,
        objRestCandidateLink: null,
        _objRestCountry: Ember.inject.service("rest-country"),

        // Promise properties
        _objRestCandidateTypeProperty: null,
        _objRestCandidateProperty: null,
        _objRestCandidateLinkProperty: null,
        _objRestCountryProperty: null,

        // Promise objects
        _objRestCountryPromise: null,
        _objRestCandidateTypePromise: null,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this._setupValidations();

            this.set("_objSpecialPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._popupAddEditCancel();
                }
            });
        },

        onLoad: Ember.observer("bLoading", function () {
            if (!this.get("bLoading")) {
                this._doServiceCalls();
            }
        }).on("init"),
        _doServiceCalls: function _doServiceCalls() {
            var _this2 = this;

            this.set("_bPopupLoading", true);

            var objContextDetails = this.get("_objUserSession").objContextDetails;

            var objPromiseCountry = this.get("_objRestCountry").getCountryById(this, "_objRestCountryPromise", objContextDetails.sCountryId, function () {} // handled in rsvp
            );

            var objServiceCalls = { _objRestCountryProperty: objPromiseCountry };
            var arrRequiredResponses = ["_objRestCountryProperty"];

            var arrCandidateTypes = this.get("objCandidateTypes");
            if (!a24Core.isEmpty(arrCandidateTypes)) {
                this._setCandidateTypes(arrCandidateTypes);
            } else {
                var objPromiseCandidateType = this.get("objRestCandidateType").getCandidateTypes(this, "_objRestCandidateTypePromise", this.get("_objUserSession").getUserContextReference().mId, function () {} // handled in rsvp
                );

                objServiceCalls._objRestCandidateTypeProperty = objPromiseCandidateType;
                arrRequiredResponses.push("_objRestCandidateTypeProperty");
            }

            a24RSVP.configure(this, objServiceCalls, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    var objCountry = objResponses._objRestCountryProperty.objResponse;

                    if (!a24Core.isEmpty(objResponses._objRestCandidateTypeProperty) && !a24Core.isEmpty(objResponses._objRestCandidateTypeProperty.objResponse)) {

                        _this2._setCandidateTypes(objResponses._objRestCandidateTypeProperty.objResponse);
                    }

                    _this2.set("_sCountryCode", objCountry.sCountryCode);

                    _this2.set("_bServiceCallsDone", true);
                    _this2._setupForm();
                }
            }]);
        },
        _setupForm: function _setupForm() {
            if (this.get("_bServiceCallsDone") && this.get("_bInputsReady")) {

                var objMobileNumberField = this.get("objChildren.sNumber");
                var sCountryCode = this.get("_sCountryCode");
                if (sCountryCode === "UK") {
                    sCountryCode = "GB";
                }
                objMobileNumberField.setCountryCode(sCountryCode);

                this.set("_bPopupLoading", false);

                if (!a24Core.isEmpty(this.get("onInputLoaded"))) {
                    this.get("onInputLoaded")();
                }
            }
        },
        _setCandidateTypes: function _setCandidateTypes(arrCandidateTypes) {
            // Map the dropdown list with candidate types
            var arrCandidateTypeDropdown = [];
            if (!a24Core.isEmpty(arrCandidateTypes)) {
                for (var i = 0; i < arrCandidateTypes.length; i++) {
                    var objCandidateTypeDropDownItem = {
                        sValue: arrCandidateTypes[i]._id + "",
                        sLabel: arrCandidateTypes[i].name
                    };
                    arrCandidateTypeDropdown.push(objCandidateTypeDropDownItem);
                }
            }
            this.set("_arrCandidateTypeDropdown", arrCandidateTypeDropdown);
        },
        _setAddEditPopup: Ember.observer("bShowAddEditPopup", function () {
            var _this3 = this;

            if (this.get("bShowAddEditPopup")) {
                this.clearAllErrorMessages();
                this.clearForm();

                this.set("_sHeaderPopup", this.get("_objStringsService").getTokenString("addItem", this.get("_objCandidateProfileStringsService").getString("candidate")));

                this.set("_bShowAddEditPopup", true);
                if (!a24Core.isEmpty(this.get("setBreadCrumbAction"))) {
                    this.get("setBreadCrumbAction")();
                }

                Ember.run.next(function () {
                    if (a24Core.isEmpty(_this3)) {
                        return;
                    }
                    Ember.run.schedule("afterRender", function () {
                        _this3.set("_objOriginalData", _this3.getPlainFormValueObject());
                    });
                });
            } else {
                this.set("_bShowAddEditPopup", false);
            }
        }),
        _populateInputFields: function _populateInputFields() {
            // This is left empty since there is no edit needed for this yet
        },
        _setupValidations: function _setupValidations() {
            var _this4 = this;

            var objThis = this;
            this.set("objChildConfig", {
                "objChildren.sFirstName": function objChildrenSFirstName(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 2);
                    a24Validation.addMaxLength(objMixin, 100);
                },
                "objChildren.sLastName": function objChildrenSLastName(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 2);
                    a24Validation.addMaxLength(objMixin, 100);
                },
                "objChildren.sEmailAddress": function objChildrenSEmailAddress(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                    objMixin.get("arrValidation").pushObject(function () {
                        var sEmail = objMixin.mValue.trim();
                        var sEmailRegex = objThis.get("_objFormInputsConstants.EMAIL_REGEX");
                        var objRegEmail = new RegExp(sEmailRegex);
                        if (!objRegEmail.test(sEmail)) {
                            return objThis.get("_objFormInputsStringsService").getTokenString("validationEmail", objMixin.get("sFieldName"));
                        }
                    });
                },
                "objChildren.sNumber": function objChildrenSNumber(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 3);
                    a24Validation.addMaxLength(objMixin, 30);
                },
                "objChildren.dDateOfBirth": function objChildrenDDateOfBirth(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addAgeMinMax(objMixin, 16, 85, _this4.get("_objLibConstants.MOMENT_TIMEZONE_UTC"), _this4.get("_objLibConstants.DATE_FORMAT"));
                },
                "objChildren.sCandidateType": function objChildrenSCandidateType(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                }
            });
        },
        _getPayload: function _getPayload() {

            var objData = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());

            if (!a24Core.isEmpty(objData.dDateOfBirth)) {
                var objDateOfBirth = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                objDateOfBirth.setDateFromBrowser(objData.dDateOfBirth, a24Constants.DATE_FORMAT);
                objData.dDateOfBirth = objDateOfBirth.getDateStringForRest(true);
            }

            if (this.get("bIsAgencyContext")) {
                objData.sMobileNumber = objData.sNumber;
                delete objData.sNumber;
            }

            return objData;
        },
        _setUnverifiedPopup: function _setUnverifiedPopup() {
            this.set("_bShowUnverifiedPopup", true);
        },
        _setUnlinkedPopup: function _setUnlinkedPopup(objMetaData, objPayload) {
            this.set("_sCandidateId", objMetaData.sCandidateId);

            if (a24Core.isEmpty(objMetaData.sFirstName) && a24Core.isEmpty(objMetaData.sLastName)) {
                this.set("_sUnlinkedMessage", this.get("_objCandidateProfileStringsService").getHTMLTokenString("unlinkedCandidateNoName", objPayload.sEmailAddress));
            } else {
                this.set("_sUnlinkedMessage", this.get("_objCandidateProfileStringsService").getHTMLTokenString("unlinkedCandidate", objMetaData.sFirstName + " " + objMetaData.sLastName, objPayload.sEmailAddress));
            }

            this.set("_bShowUnlinkedPopup", true);
        },
        _setLinkedPopup: function _setLinkedPopup(objMetaData, objPayload) {
            var sWithName = "linkedCandidate";
            var sWithoutName = "linkedCandidateNoName";
            if (this.get("bIsAgencyContext")) {
                sWithName = "linkedCandidateAgency";
                sWithoutName = "linkedCandidateNoNameAgency";
            }

            this.set("_sCandidateId", objMetaData.sCandidateId);

            if (a24Core.isEmpty(objMetaData.sFirstName) && a24Core.isEmpty(objMetaData.sLastName)) {
                this.set("_sLinkedMessage", this.get("_objCandidateProfileStringsService").getHTMLTokenString(sWithoutName, objPayload.sEmailAddress));
            } else {
                this.set("_sLinkedMessage", this.get("_objCandidateProfileStringsService").getHTMLTokenString(sWithName, objMetaData.sFirstName + " " + objMetaData.sLastName, objPayload.sEmailAddress));
            }

            this.set("_bShowLinkedPopup", true);
        },
        _popupAddEditCancel: function _popupAddEditCancel() {
            if (!this.get("_bPopupLoading")) {

                // Check if the form has any unsaved data
                var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));
                if (bDataMatches) {
                    if (!a24Core.isEmpty(this.get("onPopupCloseAction"))) {
                        this.get("onPopupCloseAction")();
                    }
                } else {
                    this.set("_bShowUnsavedData", true);
                }
            }
        },

        actions: {
            onPopupAddEditConfirm: function onPopupAddEditConfirm() {
                var _this5 = this;

                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    this.set("_bPopupLoading", true);
                    var objPayload = this._getPayload();

                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objCandidateProfileStringsService").getString("candidate")));

                    this.get("objRestCandidate").createCandidate(this, "_objRestCandidateProperty", objPayload, function (objResponse) {
                        _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("successAddItem", _this5.get("_objCandidateProfileStringsService").getString("candidate")));
                        if (!a24Core.isEmpty(_this5.get("onCandidateAddedAction"))) {
                            _this5.get("onCandidateAddedAction")(objResponse);
                        }
                        _this5.set("_bPopupLoading", false);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError, sFieldName) {
                        var objErrorResponse = null;
                        if (!a24Core.isEmpty(_this5.get("_objRestCandidateProperty.responseText"))) {
                            objErrorResponse = JSON.parse(_this5.get("_objRestCandidateProperty.responseText"));
                        }
                        if (sCode === "UNVERIFIED_EMAIL_FOUND") {
                            _this5._setUnverifiedPopup(objErrorResponse.arrMetaData, objPayload);
                        } else if (sCode === "CANDIDATE_EXISTS_BUT_NOT_LINKED") {
                            _this5._setUnlinkedPopup(objErrorResponse.arrMetaData, objPayload);
                        } else if (sCode === "CANDIDATE_ALREADY_LINKED_TO_AGENCY_GROUP" || sCode === "CANDIDATE_ALREADY_LINKED_TO_AGENCY") {
                            _this5._setLinkedPopup(objErrorResponse.arrMetaData, objPayload);
                        } else if (sCode === "INVALID_CANDIDATE_TYPE") {
                            _this5.setResponseError(["sCandidateType"], "validationRestNotSupportedOption");
                        } else if (sCode === "REGEX_NAME_NO_MATCH" || sCode === "INVALID_FORMAT") {
                            _this5.setResponseError([sFieldName], "validationRestInvalidFormat");
                        } else {
                            _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("failAddItem", _this5.get("_objCandidateProfileStringsService").getString("candidate")));
                        }

                        _this5.set("_bPopupLoading", false);
                    }));
                }
            },
            onPopupAddEditCancel: function onPopupAddEditCancel() {
                this._popupAddEditCancel();
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                if (!a24Core.isEmpty(this.get("onPopupCloseAction"))) {
                    this.get("onPopupCloseAction")();
                }
                this.set("_bShowUnsavedData", false);
            },
            onPopupUnverifiedConfirm: function onPopupUnverifiedConfirm() {
                this.set("_bShowUnverifiedPopup", false);
                if (!a24Core.isEmpty(this.get("onPopupCloseAction"))) {
                    this.get("onPopupCloseAction")();
                }
            },
            onPopupUnlinkedConfirm: function onPopupUnlinkedConfirm() {
                var _this6 = this;

                this.set("_bLinkPopupLoading", true);
                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objCandidateProfileStringsService").getString("candidateLink")));

                var objPayload = this._getPayload();

                this.get("objRestCandidateLink").linkCandidate(this, "_objRestCandidateLinkProperty", this.get("_sCandidateId"), objPayload.sCandidateType, function (objResponse) {
                    _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objStringsService").getTokenString("successAddItem", _this6.get("_objCandidateProfileStringsService").getString("candidateLink")));
                    if (!a24Core.isEmpty(_this6.get("onCandidateAddedAction"))) {
                        _this6.get("onCandidateAddedAction")(objResponse);
                    }
                    _this6.set("_bShowUnlinkedPopup", false);
                    _this6.set("_bLinkPopupLoading", false);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    var objErrorResponse = JSON.parse(_this6.get("_objRestCandidateLinkProperty.responseText"));
                    if (sCode === "CANDIDATE_ALREADY_LINKED_TO_AGENCY_GROUP") {
                        _this6.set("_bShowUnlinkedPopup", false);
                        _this6._setLinkedPopup(objErrorResponse.arrMetaData, objPayload);
                    } else if (sCode === "INVALID_CANDIDATE_TYPE") {
                        _this6.setResponseError(["sCandidateType"], "validationRestNotSupportedOption");
                    } else {
                        _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objStringsService").getTokenString("failAddItem", _this6.get("_objCandidateProfileStringsService").getString("candidateLink")));
                    }

                    _this6.set("_bLinkPopupLoading", false);
                }));
            },
            onPopupLinkedConfirm: function onPopupLinkedConfirm() {
                // navigate to candidate with a run later because of redirect issue
                this.set("_bShowLinkedPopup", false);
                if (!a24Core.isEmpty(this.get("onNavigateToCandidateAction"))) {
                    this.get("onNavigateToCandidateAction")(this.get("_sCandidateId"));
                }
            },
            onPopupUnverifiedCancel: function onPopupUnverifiedCancel() {
                this.set("_bShowUnverifiedPopup", false);
            },
            onPopupUnlinkedCancel: function onPopupUnlinkedCancel() {
                this.set("_bShowUnlinkedPopup", false);
            },
            onPopupLinkedCancel: function onPopupLinkedCancel() {
                this.set("_bShowLinkedPopup", false);
            },
            onInputFormTelNumberInitDone: function onInputFormTelNumberInitDone() {
                this.set("_bInputsReady", true);
                this._setupForm();
            }
        }
    });
});