define("a24-ember-candidate-profile/components/timeline-card-no-data", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/timeline-card-no-data"], function (exports, _didRenderChangesMixin, _timelineCardNoData) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _timelineCardNoData.default,
        classNames: ["timeline-card-no-data"],
        objActions: null,
        objData: null,
        _sDateLabel: null,
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),

        init: function init() {
            this._super.apply(this, arguments);

            var objData = this.get("objData");

            var sStartDate = null;
            var sEndDate = null;

            if (!a24Core.isEmpty(objData)) {
                if (!a24Core.isEmpty(objData.start_date)) {
                    var objStartDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    objStartDate.setDateFromRest(objData.start_date, true);
                    sStartDate = objStartDate.getDateFormat();
                }

                if (objData.current || a24Core.isEmpty(objData.end_date)) {
                    sEndDate = this.get("_objCandidateProfileStringsService").getString("current");
                } else {
                    var objEndDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    objEndDate.setDateFromRest(objData.end_date, true);
                    sEndDate = objEndDate.getDateFormat();
                }

                this.set("_sDateLabel", sStartDate + " - " + sEndDate);
            }
        },

        actions: {
            funcAddAction: function funcAddAction() {
                if (!a24Core.isEmpty(this.get("objActions.funcAddAction"))) {
                    this.get("objActions.funcAddAction")();
                }
            }
        }
    });
});