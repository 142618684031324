define("a24-ember-staffshift-candidate-availability/helpers/availability-icon", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.availabilityIcon = availabilityIcon;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    /**
     * Computes the availability icon name for an availability type
     *
     * @param sAvailabilityType - The availability type { objLate, objEarly, objNight }
    
     * @return sIconName - The icon name for the given availability type
     *
     * @author Ahmed Onawale <ahmedonawale@gmail.com>
     * @since  06 September 2017
     */
    function availabilityIcon(_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
            sAvailabilityType = _ref2[0];

        switch (sAvailabilityType) {
            case "objLate":
                return "brightness_6";
            case "objNight":
                return "brightness_1";
            case "objEarly":
                return "brightness_7";
            default:
                return "";
        }
    }

    exports.default = Ember.Helper.helper(availabilityIcon);
});