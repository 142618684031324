define("a24-ember-lib/services/input-form-file-service", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        /**
         * Get file for an input
         *
         * @param objFileInput - The input element
         * @param bReturnName - True if only the file name should be returned
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  1 November 2019
         */
        getFileObject: function getFileObject(objFileInput, bReturnName) {
            if (a24Core.isEmpty(objFileInput.get(0).files) || a24Core.isEmpty(objFileInput.get(0).files[0])) {
                return "";
            } else {
                if (bReturnName) {
                    return objFileInput.get(0).files[0].name;
                } else {
                    return objFileInput.get(0).files[0];
                }
            }
        },


        /**
         * Get file input id
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  1 November 2019
         */
        getFileInputId: function getFileInputId() {
            return Ember.guidFor({});
        }
    });
});