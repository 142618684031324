define("a24-ember-staffshift-candidate-availability/components/time-range-selector", ["exports", "a24-ember-staffshift-candidate-availability/templates/components/time-range-selector", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _timeRangeSelector, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        /*
          Default values
        */
        layout: _timeRangeSelector.default,
        classNames: ["time-range-selector"],

        sDefaultView: "Month",
        sView: Ember.computed.or("objData.sView", "sDefaultView"),
        bShowDropdown: Ember.computed.readOnly("objData.bShowDropdown"),
        bIsViewDropDownDisabled: Ember.computed.readOnly("objData.bIsViewDropDownDisabled"),
        bShowBulkUpdateButton: Ember.computed.readOnly("objData.bShowBulkUpdateButton"),
        _objAvailabilityStrings: Ember.inject.service("a24-strings-staffshift-availability"),
        /*
          Actions
        */
        actions: {
            changeView: function changeView(view) {
                if (Ember.get(this, "sView") !== view) {
                    Ember.set(this, "sDefaultView", view);
                    if (!a24Core.isEmpty(this.get("onAction"))) {
                        this.get("onAction")("view", view);
                    }
                }
            },
            onToday: function onToday() {
                if (!a24Core.isEmpty(this.get("onAction"))) {
                    this.get("onAction")("today");
                }
            },
            onBackAction: function onBackAction() {
                if (!a24Core.isEmpty(this.get("onAction"))) {
                    this.get("onAction")("back");
                }
            },
            onNextAction: function onNextAction() {
                if (!a24Core.isEmpty(this.get("onAction"))) {
                    this.get("onAction")("next");
                }
            },
            onBulkUpdateAction: function onBulkUpdateAction() {
                if (!a24Core.isEmpty(this.get("onAction"))) {
                    this.get("onAction")("bulkUpdate");
                }
            }
        }
    });
});