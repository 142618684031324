define("a24-ember-candidate-profile/services/rest-agency-group-candidate-type", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objNavigation: Ember.inject.service("navigation"),
        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),
        _objAgencyGroupCandidateTypesResponse: null,
        _sLastAgencyGroupId: null,

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        init: function init() {
            this._super.apply(this, arguments);
        },

        /**
         * Retrieves the agency group candidate type list
         *
         * @param objContext - The caller of this function
         * @param sProperty - The Property name to get the promise object
         * @param sAgencyGroupId - The agency group
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         */
        getCandidateTypes: function getCandidateTypes(objCaller, sProperty, sAgencyGroupId, funcSuccess, objFailure) {

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency-group/" + sAgencyGroupId + "/candidate-type");

            a24RestUrlConstruct.addQueryParamObject({
                name: {
                    bSortBy: true,
                    mValue: "asc"
                }
            });

            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: {
                    mValue: 1000
                }
            });

            var objThis = this;
            var funcSuccessWrapper = function funcSuccessWrapper(objData) {
                objThis.set("_objAgencyGroupCandidateTypesResponse", objData);
                funcSuccess.apply(undefined, arguments);
            };

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, null, //This is a public route
            this.get("_objNavigation"), "GET", this.get("sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccessWrapper, objFailure, null, null, false, false, true));
        },

        /**
         * Retrieves the agency group candidate type list with page cache
         *
         * @param objContext - The caller of this function
         * @param sProperty - The Property name to get the promise object
         * @param sAgencyGroupId - The agency group id
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  10 January 2022
         */
        getCandidateTypesCached: function getCandidateTypesCached(objContext, sProperty, sAgencyGroupId, funcSuccess, objFailure) {
            var _this = this;

            if (this.get("_sLastAgencyGroupId") === sAgencyGroupId && !a24Core.isEmpty(this.get("_objAgencyGroupCandidateTypesResponse"))) {
                return new Ember.RSVP.Promise(function (funcResolve) {
                    funcSuccess(Ember.copy(_this.get("_objAgencyGroupCandidateTypesResponse"), true));
                    funcResolve(Ember.copy(_this.get("_objAgencyGroupCandidateTypesResponse"), true));
                });
            } else {
                this.set("_sLastAgencyGroupId", sAgencyGroupId);
                return this.getCandidateTypes(objContext, sProperty, sAgencyGroupId, funcSuccess, objFailure);
            }
        }
    });
});