define("a24-ember-lib/components/popup-responsive-modal", ["exports", "a24-ember-lib/templates/components/popup-responsive-modal", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _popupResponsiveModal, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _popupResponsiveModal.default,
        classNames: ["popup-responsive-modal"],
        // id of the component
        sModalId: null,
        // used to determine what was clicked
        mValue: null,
        // used to determine if clicking outside the popup will close it.
        bDismissible: true,
        // used to open/close the popup
        bShowPopup: false,
        bLoading: false,
        iMobileWidth: 800,
        iMobileHeight: 500,
        bMobileView: false, //This is not meant to be send in but rather observed by components wrapped inside this one
        sModalSize: "", //modal-sm | modal-lg | modal-fluid | It is medium by default, so no style for that
        _bPopupAnimating: false, // used to prevent popup from getting hidden or shown while animating
        _objDomEventService: Ember.inject.service("dom-event"), // Dom event service
        _objUrlTrickery: Ember.inject.service("url-trickery"), // Url manipulation service
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _sPopupId: "",
        _sPopupUrlQuery: "",
        _funcKeyUp: null,
        _bDoneInit: false,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.get("_objDomEventService");
            this.get("_objUrlTrickery");

            if (a24Core.isEmpty(this.get("_sPopupId"))) {
                this.set("_sPopupId", Ember.guidFor({}));
            }
            this.set("_sPopupUrlQuery", this.get("_sPopupId") + "=" + encodeURI(this.get("_objLibConstants.URL_VALUE_POPUPS")));

            var objThis = this;
            var funcKeyUp = function funcKeyUp(objEvent) {
                Ember.run(function () {
                    if (objEvent.keyCode === 27) {

                        if (a24Core.endsWith(objThis.get("_objUrlTrickery.sCurrentHash"), objThis.get("_sPopupUrlQuery"))) {
                            if (objThis.get("bDismissible")) {
                                //The below goBack will cause the onPopupResponsiveDismiss to fire
                                objThis.get("_objUrlTrickery").goBack();
                            } else {
                                objThis.sendAction("onPopupResponsiveDismiss", objThis.get("mValue"));
                            }
                        }
                    }
                });
            };
            this.set("_funcKeyUp", funcKeyUp);

            Ember.run.scheduleOnce("afterRender", function () {
                _this.$().attr("id", _this.get("sModalId"));
                _this.$().attr("role", "dialog");
                // this is to preserve the order in which the attributes get added
                _this.$().attr("style", "display: none;");
                _this.$().addClass("modal");
                _this.$().addClass("fade");

                if (a24Core.isEmpty(_this.get("bShowPopup"))) {
                    _this.set("bShowPopup", false);
                }
                if (_this.get("bShowPopup")) {
                    _this.get("_objUrlTrickery").addUrlParam(_this.get("_sPopupId"), _this.get("_objLibConstants.URL_VALUE_POPUPS"));
                    $(document).on("keyup", _this.get("_funcKeyUp"));
                }

                var objComponentElement = $(_this.$());
                objComponentElement.modal({
                    backdrop: false,
                    show: _this.get("bShowPopup"),
                    keyboard: false
                });

                var objThis = _this;
                objComponentElement.on("hidden.bs.modal", function () /*objEvent*/{
                    Ember.run(function () {
                        if (!a24Core.isEmpty(objThis)) {
                            objThis.set("_bPopupAnimating", false);
                            if (objThis.get("bShowPopup")) {
                                objThis.funcShow();
                            }
                        }
                    });
                });
                objComponentElement.on("shown.bs.modal", function () /*objEvent*/{
                    Ember.run(function () {
                        if (!a24Core.isEmpty(objThis)) {
                            objThis.set("_bPopupAnimating", false);
                            if (!objThis.get("bShowPopup")) {
                                objThis.funcHide();
                            }
                        }
                    });
                });

                _this.set("_bDoneInit", true);

                //Set the correct state
                _this.onWindowResize();
            });
        },
        funcShow: function funcShow() {
            var _this2 = this;

            Ember.run(function () {
                if (!a24Core.isEmpty(_this2)) {
                    if (!_this2.get("_objUrlTrickery.sCurrentHash").includes(_this2.get("_sPopupId") + "=" + _this2.get("_objLibConstants.URL_VALUE_POPUPS"))) {
                        $(_this2.$()).modal("show");
                        _this2.set("_bPopupAnimating", true);
                        _this2.set("bShowPopup", true);
                        _this2.get("_objUrlTrickery").addUrlParam(_this2.get("_sPopupId"), _this2.get("_objLibConstants.URL_VALUE_POPUPS"));

                        $(document).on("keyup", _this2.get("_funcKeyUp"));
                    }
                }
            });
        },
        funcHide: function funcHide() {
            var _this3 = this;

            Ember.run(function () {
                if (!a24Core.isEmpty(_this3)) {
                    $(_this3.$()).modal("hide");
                    _this3.set("_bPopupAnimating", true);
                    _this3.set("bShowPopup", false);

                    if (_this3.get("_objUrlTrickery.sCurrentHash").indexOf(_this3.get("_sPopupUrlQuery")) !== -1) {
                        _this3.get("_objUrlTrickery").goBack();
                    }
                    $(document).off("keyup", _this3.get("_funcKeyUp"));
                }
            });
        },
        willDestroy: function willDestroy() {
            this._super.apply(this, arguments);
            //Remove only the callback added by this component since there might be other popups still
            $(document).off("keyup", this.get("_funcKeyUp"));
        },
        dismissPopup: function dismissPopup() {
            if (!a24Core.isEmpty(this.get("onPopupResponsiveDismiss"))) {
                this.get("onPopupResponsiveDismiss")(this.get("mValue"));
            }
            if (this.get("bDismissible")) {
                this.set("bShowPopup", false);
            }
        },
        observeShowHideChanges: Ember.observer("bShowPopup", function () {
            if (this.get("_bDoneInit")) {
                if (this.get("bShowPopup") && !this.get("_bPopupAnimating")) {
                    this.funcShow();
                    a24ComponentEvent.onPopupResponsiveModalOpen();
                } else if (!this.get("bShowPopup") && !this.get("_bPopupAnimating")) {
                    this.funcHide();
                    a24ComponentEvent.onPopupResponsiveModalClose();
                }
            }
        }),
        observeHashChanges: Ember.observer("_objUrlTrickery.sCurrentHash", function () {
            if (!a24Core.isEmpty(this)) {
                if (this.get("_bDoneInit")) {
                    if (this.get("bShowPopup") && this.get("_objUrlTrickery.sCurrentHash").indexOf(this.get("_sPopupUrlQuery")) === -1) {
                        if (!this.get("bDismissible")) {
                            this.get("_objUrlTrickery").addUrlParam(this.get("_sPopupId"), this.get("_objLibConstants.URL_VALUE_POPUPS"));
                        }
                        this.dismissPopup();
                    } else if (!this.get("bShowPopup") && a24Core.endsWith(this.get("_objUrlTrickery.sCurrentHash"), this.get("_sPopupUrlQuery"))) {
                        this.get("_objUrlTrickery").goBack();
                    }
                }
            }
        }),
        onWindowResize: Ember.observer("_objDomEventService.iPageWidth", "_objDomEventService.iPageHeight", function () {
            if (this.get("_bDoneInit")) {
                var iPageWidth = this.get("_objDomEventService.iPageWidth");
                var iPageHeight = this.get("_objDomEventService.iPageHeight");

                if (iPageWidth < this.get("iMobileWidth") || iPageHeight < this.get("iMobileHeight")) {
                    this.set("bMobileView", true);
                } else {
                    this.set("bMobileView", false);
                }
            }
        }),
        actions: {
            onPopupBackdropClick: function onPopupBackdropClick(objEvent) {
                if (objEvent.target === this.$(".a24-modal-backdrop").get(0) || objEvent.target === this.$(".modal-dialog").get(0)) {
                    objEvent.stopPropagation();
                    this.dismissPopup();
                }
            }
        }
    });
});