define("a24-ember-lib/components/collapse-trigger-component", ["exports", "a24-ember-lib/templates/components/collapse-trigger-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _collapseTriggerComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _collapseTriggerComponent.default,
        // the id of the component that will collapse
        sCollapseSectionId: "",
        bDisplayArrow: true,
        bDisplayWaves: true,
        bShouldShowCollapsed: false,
        calcShouldShowCollapsed: function calcShouldShowCollapsed() {
            var _this = this;

            Ember.run(function () {
                if (!a24Core.isEmpty(_this) && !a24Core.isEmpty(_this.get("sCollapseSectionId"))) {
                    _this.set("bShouldShowCollapsed", !$("#" + _this.get("sCollapseSectionId")).eq(0).hasClass("show"));
                }
            });
        },
        didInsertElement: function didInsertElement() {
            var _this2 = this;

            this._super.apply(this, arguments);

            Ember.run.next(function () {
                Ember.run.scheduleOnce("afterRender", function () {
                    if (!a24Core.isEmpty(_this2.get("sCollapseSectionId"))) {
                        $("#" + _this2.get("sCollapseSectionId")).eq(0).on("shown.bs.collapse", function () {
                            _this2.calcShouldShowCollapsed();
                        }).on("hidden.bs.collapse", function () {
                            _this2.calcShouldShowCollapsed();
                        });
                        _this2.calcShouldShowCollapsed();
                    }
                });
            });
        },
        mouseEnter: function mouseEnter() {
            if (!a24Core.isEmpty(this.get("onAccordionMouseEnter"))) {
                this.get("onAccordionMouseEnter")(this.get("sCollapseSectionId"));
            }
        },
        mouseLeave: function mouseLeave() {
            if (!a24Core.isEmpty(this.get("onAccordionMouseLeave"))) {
                this.get("onAccordionMouseLeave")(this.get("sCollapseSectionId"));
            }
        },
        actions: {
            onTriggerClicked: function onTriggerClicked(objEvent) {
                var objTarget = $(objEvent.target);
                // If the item IS NOT <a> or class waves-effect perform the click
                // If the item IS <a> or class waves-effect ensure that only this accordian <a> allows expanding
                if (!(objTarget.hasClass("waves-effect") || objTarget.is("a")) || objTarget[0] === this.$("a")[0]) {
                    if (!a24Core.isEmpty(this.get("sCollapseSectionId"))) {
                        if (!a24Core.isEmpty($("#" + this.get("sCollapseSectionId")))) {
                            $("#" + this.get("sCollapseSectionId")).collapse("toggle");
                        }
                    }
                    if (!a24Core.isEmpty(this.get("onAccordionMouseClick"))) {
                        this.get("onAccordionMouseClick")(this.get("sCollapseSectionId"));
                    }
                }
            }
        }
    });
});