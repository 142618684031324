var a24ComponentEventExtend = {
    onPopupConfirmationModalOpen: function() {
        // This is left blank for overriding purpose
    },
    onPopupConfirmationModalClose: function() {
        // This is left blank for overriding purpose
    },
    onPopupResponsiveModalOpen: function() {
        // This is left blank for overriding purpose
    },
    onPopupResponsiveModalClose: function() {
        // This is left blank for overriding purpose
    }
};

//Only add the extended values to the a24ComponentEvent if they do not exist on it already
Object.keys(a24ComponentEventExtend).forEach(function(sKey) {
    if (!a24ComponentEvent.hasOwnProperty(sKey)) {
        a24ComponentEvent[sKey] = a24ComponentEventExtend[sKey];
    }
});
