define("a24-ember-lib/services/url-trickery", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        sCurrentHash: "",
        _funcHashChange: null,
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        init: function init() {
            this._super.apply(this, arguments);

            //Trim any existing ember ids from the url before adding the listeners
            var sTrimmed = a24Core.stripEmberIdsFromUrl(this.get("_objLibConstants.URL_VALUE_POPUPS"));
            if (sTrimmed !== window.location.href) {
                this.replaceBrowserUrl(sTrimmed);
            }

            var objThis = this;
            var funcHashChange = function funcHashChange() {
                objThis.set("sCurrentHash", window.location.hash);
            };
            this.set("_funcHashChange", funcHashChange);
            $(window).on("hashchange", funcHashChange);
        },
        willDestroy: function willDestroy() {
            this._super.apply(this, arguments);
            $(window).off("hashchange", this.get("_funcHashChange"));
        },
        /**
         * This function will add a param to the url
         *
         * @param sParam - The param name
         * @param sValue - The value of the param
         */
        addUrlParam: function addUrlParam(sParam, sValue) {
            var sNewUrl = window.location.href;
            if (window.location.hash.indexOf("?") !== -1) {
                sNewUrl += "&" + sParam + "=" + encodeURI(sValue);
            } else {
                sNewUrl += "?" + sParam + "=" + encodeURI(sValue);
            }
            this.changeBrowserUrl(sNewUrl);
        },
        /**
         * This function will go back on the history
         */
        goBack: function goBack() {
            window.history.back();
        },
        /**
         * This function will change the browser url to the given one
         *
         * @param sNewUrl - The new url the browser should have
         */
        changeBrowserUrl: function changeBrowserUrl(sNewUrl) {
            window.location = sNewUrl;
        },
        /**
         * This function will change the browser url to the given one without adding a history item
         *
         * @param sNewUrl - The new url the browser should have
         */
        replaceBrowserUrl: function replaceBrowserUrl(sNewUrl) {
            window.location.replace(sNewUrl);
        }
    });
});