define("a24-ember-staffshift-candidate-availability/services/rest-agency-staffing-order", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        /*
          Services
        */
        _objNavigation: Ember.inject.service("navigation"),
        _objUserSession: Ember.inject.service("user-session"),

        _sRestTriageApiBaseUrl: Ember.computed.readOnly("_ENV.sTriageBaseRest"),

        _ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * Used to fetch order data from the server for an agency
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param iOrderId - The order id
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  14 August 2020
         */
        getOrder: function getOrder(objCaller, sProperty, iOrderId, funcSuccess, objFailure) {

            a24RestUrlConstruct.setBaseUrl("/rest/staffing/order/" + iOrderId);

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, Ember.get(this, "_objUserSession"), Ember.get(this, "_objNavigation"), "GET", Ember.get(this, "_sRestTriageApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, null, null, false, true));
        }
    });
});