define("a24-ember-staffshift-core/components/reset-password-popup-component", ["exports", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/reset-password-popup-component"], function (exports, _inputFormElement, _didRenderChangesMixin, _resetPasswordPopupComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _resetPasswordPopupComponent.default,
        sUsernameEmail: "",
        bShowPopup: false,
        _bLoading: false,
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objUserService: Ember.inject.service("rest-user"),
        _objStaffshiftCoreStringsService: Ember.inject.service("a24-strings-staffshift-core"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSpecialForgotPasswordPopupAction: null,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.set("objChildConfig", {
                "objChildren._sUsernameEmail": function objChildren_sUsernameEmail(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 3);
                    a24Validation.addMaxLength(objMixin, 100);
                }
            });

            this.set("_objSpecialForgotPasswordPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    if (!a24Core.isEmpty(_this.get("onForgotPasswordClose"))) {
                        _this.get("onForgotPasswordClose")();
                    }
                }
            });

            this.set("_bLoading", false);
        },
        _onShowPopup: Ember.observer("bShowPopup", function () {
            this.get("objChildren._sUsernameEmail").setValueFromOutside(this.get("sUsernameEmail"));
        }),
        isValidEmail: function isValidEmail(sEmail) {
            var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            return regex.test(sEmail);
        },
        actions: {
            onPopupForgotPasswordCancel: function onPopupForgotPasswordCancel() {
                if (!this.get("_bLoading")) {
                    if (!a24Core.isEmpty(this.get("onForgotPasswordClose"))) {
                        this.get("onForgotPasswordClose")();
                    }
                }
            },

            onPopupForgotPasswordConfirm: function onPopupForgotPasswordConfirm() {
                var _this2 = this;

                // validate
                if (!this.doFullValidation()) {
                    // determine if username or email
                    var objData = this.getPlainFormValueObject();
                    var sUsernameEmail = objData._sUsernameEmail.trim();
                    var sUsername = null;
                    var sEmail = null;
                    if (this.isValidEmail(sUsernameEmail)) {
                        sEmail = sUsernameEmail;
                    } else {
                        sUsername = sUsernameEmail;
                    }

                    this.set("_bLoading", true);

                    // call service to reset password
                    this.get("_objUserService").resetPassword(this, sUsername, sEmail, function () {
                        // reset password was a success
                        _this2.get("_objSnackbarService").showSnackbarMessage(_this2.get("_objStaffshiftCoreStringsService").getString("resetPasswordEmailSent"));
                        _this2.set("_bLoading", false);
                        if (!a24Core.isEmpty(_this2.get("onForgotPasswordClose"))) {
                            _this2.get("onForgotPasswordClose")();
                        }
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE"], function (sCode /*,sStatus, objErrorThrown, objError*/) {
                        window.dataLayer = window.dataLayer || [];

                        // Show failure message
                        if (sCode === "DOES_NOT_EXIST") {
                            window.dataLayer.push({
                                "event": "forgot-password-user-does-not-exist"
                            });

                            _this2.setResponseError(["_sUsernameEmail"], "emailNotInDatabase", false, false, _this2.get("_objStaffshiftCoreStringsService"));
                        } else if (sCode === "REGEX_NAME_NO_MATCH") {
                            window.dataLayer.push({
                                "event": "forgot-password-username-invalid-chars"
                            });

                            _this2.setResponseError(["_sUsernameEmail"], "validationRestInvalidChar");
                        } else if (sCode === "STRING_TOO_LONG") {
                            window.dataLayer.push({
                                "event": "forgot-password-username-too-long"
                            });

                            _this2.setResponseError(["_sUsernameEmail"], "validationRestMaxLength");
                        } else if (sCode === "USER_NOT_ACTIVE") {
                            window.dataLayer.push({
                                "event": "forgot-password-inactive-user"
                            });

                            _this2.setResponseError(["_sUsernameEmail"], _this2.get("_objStaffshiftCoreStringsService").getString("userNotActive"), true, true);
                        } else {
                            _this2.setResponseError(["_sUsernameEmail"], _this2.get("_objStringsService").getString("serverError"), true, true);
                        }
                        _this2.set("_bLoading", false);
                    }));
                }
            }
        }
    });
});