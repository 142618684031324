var a24ValidationExtend = {
    addMaxLength: function(objMixin, iMaxLength) {
        if (
            objMixin.get("sInputType") === "text" ||
            objMixin.get("sInputType") === "text-area" ||
            objMixin.get("sInputType") === "tel"
        ) {
            //Only set the iMaxCharacters for inputs that support the character counter
            //This is in separate if to allow adding more types in the next if later
            objMixin.set("iMaxCharacters", iMaxLength);
        }

        if (
            objMixin.get("sInputType") === "text" ||
            objMixin.get("sInputType") === "text-area" ||
            objMixin.get("sInputType") === "tel"
        ) {
            objMixin.get("arrValidation").pushObject(
                function() {
                    if (!a24Core.isEmpty(objMixin.get("mValue")) && objMixin.get("mValue").trim().length > iMaxLength) {
                        return objMixin.get("_objStringsService").getTokenString(
                            "validationMaxLength",
                            objMixin.get("sFieldName"),
                            iMaxLength
                        );
                    }
                }
            );
        } else if (objMixin.get("sInputType") === "password") {
            objMixin.get("arrValidation").pushObject(
                function() {
                    //passwords can not be trimmed thus custom empty checks
                    if (objMixin.get("mValue").length > 0 && objMixin.get("mValue").length > iMaxLength) {
                        return objMixin.get("_objStringsService").getTokenString(
                            "validationMaxLength",
                            objMixin.get("sFieldName"),
                            iMaxLength
                        );
                    }
                }
            );
        } else if (objMixin.get("sInputType") === "tag") {
            objMixin.get("arrValidation").pushObject(
                function() {
                    if (a24Core.isEmpty(objMixin.get("mValue"))) {
                        return;
                    }
                    for (var i = 0; i < objMixin.get("mValue").length; i++) {
                        if (
                            !a24Core.isEmpty(objMixin.get("mValue." + i)[objMixin.get("sValueKey")]) &&
                            objMixin.get("mValue." + i)[objMixin.get("sValueKey")].trim().length > iMaxLength
                        ) {
                            return objMixin.get("_objStringsService").getTokenString(
                                "validationMaxLengthMulti",
                                objMixin.get("sFieldName"),
                                iMaxLength
                            );
                        }
                    }
                }
            );
        } else if (objMixin.get("sInputType") === "week") {
            objMixin.get("arrValidation").pushObject(
                function() {
                    if (a24Core.isEmpty(objMixin.get("mValue"))) {
                        return;
                    }
                    if (objMixin.get("mValue").length > iMaxLength) {
                        //Yes validationMaxFileSize string works here, those keys should have been more generic
                        return objMixin.get("_objStringsService").getTokenString(
                            "validationMaxFileSize",
                            objMixin.get("sFieldName"),
                            iMaxLength
                        );
                    }
                }
            );
        }
    },
    addMinLength: function(objMixin, iMinLength) {
        if (
            objMixin.get("sInputType") === "text" ||
            objMixin.get("sInputType") === "text-area" ||
            objMixin.get("sInputType") === "tel"
        ) {
            objMixin.get("arrValidation").pushObject(
                function() {
                    if (!a24Core.isEmpty(objMixin.get("mValue")) && objMixin.get("mValue").trim().length < iMinLength) {
                        return objMixin.get("_objStringsService").getTokenString(
                            "validationMinLength",
                            objMixin.get("sFieldName"),
                            iMinLength
                        );
                    }
                }
            );
        } else if (objMixin.get("sInputType") === "password") {
            objMixin.get("arrValidation").pushObject(
                function() {
                    //passwords can not be trimmed thus custom empty checks
                    if (objMixin.get("mValue").length > 0 && objMixin.get("mValue").length < iMinLength) {
                        return objMixin.get("_objStringsService").getTokenString(
                            "validationMinLength",
                            objMixin.get("sFieldName"),
                            iMinLength
                        );
                    }
                }
            );
        } else if (objMixin.get("sInputType") === "tag") {
            objMixin.get("arrValidation").pushObject(
                function() {
                    if (a24Core.isEmpty(objMixin.get("mValue"))) {
                        return;
                    }
                    for (var i = 0; i < objMixin.get("mValue").length; i++) {
                        if (
                            !a24Core.isEmpty(objMixin.get("mValue." + i)[objMixin.get("sValueKey")]) &&
                            objMixin.get("mValue." + i)[objMixin.get("sValueKey")].trim().length < iMinLength
                        ) {
                            return objMixin.get("_objStringsService").getTokenString(
                                "validationMinLengthMulti",
                                objMixin.get("sFieldName"),
                                iMinLength
                            );
                        }
                    }
                }
            );
        } else if (objMixin.get("sInputType") === "week") {
            objMixin.get("arrValidation").pushObject(
                function() {
                    if (a24Core.isEmpty(objMixin.get("mValue"))) {
                        return;
                    }
                    if (objMixin.get("mValue").length < iMinLength) {
                        //Yes validationMinFileSize string works here, those keys should have been more generic
                        return objMixin.get("_objStringsService").getTokenString(
                            "validationMinFileSize",
                            objMixin.get("sFieldName"),
                            iMinLength
                        );
                    }
                }
            );
        }
    },
    addExtraRequired: function(/*objMixin*/) {
        // This function can be overridden if any other extra types are added for the required validation
    },
    addAllowedMimeTypes: function(objMixin, arrMimeTypes, arrPrettyMimeTypes) {
        if (objMixin.get("sInputType") === "file") {
            objMixin.get("arrValidation").pushObject(
                function() {
                    var objFile = objMixin.get("mValue");
                    var bFound = false;
                    if (a24Core.isEmpty(objFile)) {
                        return;
                    }

                    var sMimeTypes = "";

                    if (!a24Core.isEmpty(arrPrettyMimeTypes)) {
                        sMimeTypes = arrPrettyMimeTypes.join(", ");
                    } else {
                        sMimeTypes = arrMimeTypes.join(", ");
                    }

                    for (var i = 0; i < arrMimeTypes.length; i++) {
                        if (!a24Core.isEmpty(objFile.type)) {
                            if (
                                arrMimeTypes[i] === objFile.type ||
                                arrMimeTypes[i] === "*"
                            ) {
                                bFound = true;
                            }
                        } else {
                            return objMixin.get("_objStringsService").getTokenString(
                                "validationFileAllowedAllMimeTypes",
                                objMixin.get("sFieldName")
                            );
                        }
                    }

                    if (!bFound) {
                        return objMixin.get("_objStringsService").getTokenString(
                            "validationFileAllowedMimeTypes",
                            objMixin.get("sFieldName"),
                            sMimeTypes
                        );
                    }
                }
            );
        }
    },
    // Max file size in MB
    addMaxFileSize: function(objMixin, iMaxFileSize) {
        if (objMixin.get("sInputType") === "file") {
            objMixin.get("arrValidation").pushObject(
                function() {
                    var objFile = objMixin.get("mValue");
                    if (a24Core.isEmpty(objFile)) {
                        return;
                    }
                    // this is to convert to MB
                    var iFileSize = objFile.size / 1024 / 1024;
                    if (iFileSize > iMaxFileSize) {
                        if (iMaxFileSize < 1) {
                            var iMaxFileSizeInKB = iMaxFileSize * 1024;
                            return objMixin.get("_objStringsService").getTokenString(
                                "validationMaxFileSize",
                                objMixin.get("sFieldName"),
                                iMaxFileSizeInKB + "KB"
                            );
                        } else {
                            return objMixin.get("_objStringsService").getTokenString(
                                "validationMaxFileSize",
                                objMixin.get("sFieldName"),
                                iMaxFileSize + "MB"
                            );
                        }
                    }
                }
            );
        }
    },
    // Min file size in MB
    addMinFileSize: function(objMixin, iMinFileSize) {
        if (objMixin.get("sInputType") === "file") {
            objMixin.get("arrValidation").pushObject(
                function() {
                    var objFile = objMixin.get("mValue");
                    if (a24Core.isEmpty(objFile)) {
                        return;
                    }
                    // this is to convert to MB
                    var iFileSize = objFile.size / 1024 / 1024;
                    if (iFileSize < iMinFileSize) {
                        if (iMinFileSize < 1) {
                            var iMinFileSizeInKB = iMinFileSize * 1024;
                            return objMixin.get("_objStringsService").getTokenString(
                                "validationMinFileSize",
                                objMixin.get("sFieldName"),
                                iMinFileSizeInKB + "KB"
                            );
                        } else {
                            return objMixin.get("_objStringsService").getTokenString(
                                "validationMinFileSize",
                                objMixin.get("sFieldName"),
                                iMinFileSize + "MB"
                            );
                        }
                    }
                }
            );
        }
    },
    addRequired: function(objMixin, bRequired) {
        if (bRequired) {
            if (a24Core.isEmpty(objMixin.get("funcValidationRequired"))) {
                var funcValidation = this.addExtraRequired(objMixin);
                if (a24Core.isEmpty(funcValidation)) {
                    if (
                        objMixin.get("sInputType") === "text" ||
                        objMixin.get("sInputType") === "text-area" ||
                        objMixin.get("sInputType") === "tag" ||
                        objMixin.get("sInputType") === "dropdown-single" ||
                        objMixin.get("sInputType") === "dropdown-multi" ||
                        objMixin.get("sInputType") === "radio" ||
                        objMixin.get("sInputType") === "date" ||
                        objMixin.get("sInputType") === "html" ||
                        objMixin.get("sInputType") === "time" ||
                        objMixin.get("sInputType") === "file" ||
                        objMixin.get("sInputType") === "tel" ||
                        objMixin.get("sInputType") === "email" ||
                        objMixin.get("sInputType") === "number" ||
                        objMixin.get("sInputType") === "week"
                    ) {
                        funcValidation = function() {
                            if (a24Core.isEmpty(objMixin.get("mValue"))) {
                                return objMixin.get("_objStringsService").getTokenString(
                                    "validationRequired",
                                    objMixin.get("sFieldName")
                                );
                            }
                        };
                    } else if (objMixin.get("sInputType") === "password") {
                        funcValidation = function() {
                            //passwords can not be trimmed thus custom empty checks
                            if (objMixin.get("mValue") === "" || objMixin.get("mValue") === null) {
                                return objMixin.get("_objStringsService").getTokenString(
                                    "validationRequired",
                                    objMixin.get("sFieldName")
                                );
                            }
                        };
                    } else if (
                        objMixin.get("sInputType") === "suggest" ||
                        objMixin.get("sInputType") === "address-suggest"
                    ) {
                        funcValidation = function() {
                            if (a24Core.isEmpty(objMixin.get("mValue"))) {
                                return objMixin.get("_objStringsService").getTokenString(
                                    "validationSuggestionRequired",
                                    objMixin.get("sFieldName")
                                );
                            }
                        };
                    } else if (objMixin.get("sInputType") === "checkbox") {
                        funcValidation = function() {
                            if (
                                a24Core.isEmpty(objMixin.get("mValue")) ||
                                a24Core.isEmpty(objMixin.get("mValue.bValue")) ||
                                !objMixin.get("mValue.bValue")
                            ) {
                                return objMixin.get("_objStringsService").getTokenString(
                                    "validationRequired",
                                    objMixin.get("sFieldName")
                                );
                            }
                        };
                    }
                }

                if (!a24Core.isEmpty(funcValidation)) {
                    objMixin.get("arrValidation").pushObject(
                        funcValidation
                    );
                    objMixin.set("funcValidationRequired", funcValidation);
                }
            }
        } else {
            if (!a24Core.isEmpty(objMixin.get("funcValidationRequired"))) {
                objMixin.get("arrValidation").removeObject(
                    objMixin.get("funcValidationRequired")
                );
                objMixin.set("funcValidationRequired", null);
            }
        }

    },
    addAgeMinMax: function(objMixin, iMinAge, iMaxAge, sTimeZone, sInputDateFormat) {
        if (
            objMixin.get("sInputType") === "date"
        ) {
            objMixin.get("arrValidation").pushObject(function() {
                var objCurrentDate = a24DateManager.createDate(sTimeZone);
                objCurrentDate.setTimeToStartOfDay();

                var objDateOfBirth = a24DateManager.createDate(sTimeZone);
                objDateOfBirth.setDateFromBrowser(objMixin.get("mValue"), sInputDateFormat);

                var iDifferenceInYears = objCurrentDate.difference(objDateOfBirth, a24Constants.MOMENT_TIME_YEARS);

                if (iDifferenceInYears < iMinAge) {
                    return objMixin.get("_objStringsService").getTokenString(
                        "validationDateOfBirthTooYoung",
                        objMixin.get("sFieldName"),
                        iMinAge.toString()
                    );
                }
                if (iDifferenceInYears > iMaxAge) {
                    return objMixin.get("_objStringsService").getTokenString(
                        "validationDateOfBirthTooOld",
                        objMixin.get("sFieldName"),
                        iMaxAge.toString()
                    );
                }
            });
        }
    },
    addWholeNumber: function(objMixin) {
        if (objMixin.get("sInputType") === "text") {
            objMixin.get("arrValidation").pushObject(
                function() {
                    if (
                        !a24Core.isEmpty(objMixin.get("mValue")) &&
                        (
                            !$.isNumeric(objMixin.get("mValue")) ||
                            (objMixin.get("mValue") < 0) ||
                            (objMixin.get("mValue") % 1 !== 0)
                        )
                    ) {
                        return objMixin.get("_objStringsService").getTokenString(
                            "validationWholeNumber",
                            objMixin.get("sFieldName")
                        );
                    }
                }
            );
        }
    },
    addPostCode: function(objMixin, sCountryCode) {
        if (a24Core.isEmpty(sCountryCode)) {
            sCountryCode = "GB";
        }
        if (objMixin.get("sInputType") === "text") {
            objMixin.get("arrValidation").pushObject(
                function() {
                    if (!a24Core.isEmpty(objMixin.get("mValue"))) {
                        var mResult = objMixin.get("_objPostCodeValidatorService").validatePostCode(objMixin.get("mValue"), sCountryCode.toLowerCase());
                        if (mResult !== true) {
                            return mResult;
                        }
                    }
                }
            );
        }
    }
};

//Only add the extended values to the a24Validation if they do not exist on it already
Object.keys(a24ValidationExtend).forEach(function(sKey) {
    if (!a24Validation.hasOwnProperty(sKey)) {
        a24Validation[sKey] = a24ValidationExtend[sKey];
    }
});
