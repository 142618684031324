define("a24-ember-candidate-profile/components/tab-agency-group-candidate-profile-equality", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/tab-agency-group-candidate-profile-equality"], function (exports, _didRenderChangesMixin, _tabAgencyGroupCandidateProfileEquality) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _tabAgencyGroupCandidateProfileEquality.default,
        _sCandidateId: null,
        _objBasicInfoService: Ember.inject.service("rest-agency-group-candidate-basic-info"),
        _objEqualityMonitoringService: Ember.inject.service("rest-agency-group-equality-monitoring"),
        _objStringsCandidateProfile: Ember.inject.service("a24-strings-candidate-profile"),
        _objStatusErrorPageService: Ember.inject.service("status-error-page"),

        _objNavigationService: Ember.inject.service("navigation"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _bDisplayPermissionError: false,
        init: function init() {
            this._super.apply(this, arguments);

            var objCandidateViewParams = this.get("_objNavigationService.objRouteParams")["agency-group.candidate-view"];
            var objCandidateSearchViewParams = this.get("_objNavigationService.objRouteParams")["agency-group.candidate-search-view"];

            if (!a24Core.isEmpty(objCandidateViewParams)) {
                this.set("_sCandidateId", objCandidateViewParams.candidate_id);
            }
            if (!a24Core.isEmpty(objCandidateSearchViewParams)) {
                this.set("_sCandidateId", objCandidateSearchViewParams.candidate_id);
            }

            this.getBasicInfo();
        },

        _setTitleBar: function _setTitleBar() {
            this.get("_objTitleBarService").setTitleBarPartially(this.get("_objStringsCandidateProfile").getString("candidateProfile") + " - " + this.get("_objBasicInfoService.objLastCandidateDetails.first_name") + " " + this.get("_objBasicInfoService.objLastCandidateDetails.last_name"));
        },

        getBasicInfo: function getBasicInfo() {
            var _this = this;

            if (!a24Core.isEmpty(this.get("_objBasicInfoService.objLastCandidateDetails"))) {
                this._setTitleBar();
            } else {
                this.get("_objBasicInfoService").getBasicInfo(this, "_objBasicInfoGetPromise", this.get("_sCandidateId"), function () {
                    _this._setTitleBar();
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
                function (sCode) {
                    if (sCode === "403") {
                        _this.set("_objStatusErrorPageService.sIcon", "warning");
                        _this.set("_objStatusErrorPageService.sMessage", _this.get("_objStringsCandidateProfile").getString("permissionsError"));
                        _this.set("_bDisplayPermissionError", true);
                    }
                }));
            }
        }
    });
});