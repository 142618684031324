define("a24-ember-candidate-profile/components/candidate-addresses", ["exports", "a24-ember-candidate-profile/templates/components/candidate-addresses", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _candidateAddresses, _inputFormElement, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _candidateAddresses.default,
        objSettingsRestService: null,
        sCandidateId: null,
        objAddressDetailsRestService: null,
        funcGetCountriesSuggestions: null,
        funcGetCountiesSuggestions: null,

        _bLoading: true,
        _bPopupLoading: false,
        _bShowUnsavedData: false,
        _bIsAdd: false,
        _bShowAddressDetailsErrorMessagePopup: false,
        _bAllowAddEdit: true,
        _bShowDeleteConfirm: false,
        _bShowGeoEdit: false,
        _bGeoPopupLoading: false,

        _objGeoEditData: null,

        _sGeoLocationEditText: "",
        _sSelectedCountryCode: "",

        _bIsUkSaCountry: false,
        _sCountryCode: null,
        _sDefaultCountry: "GB",
        _objAjax: $.ajax,
        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),
        _objCountries: null,

        _sHeaderPopup: null,
        _objOriginalData: null,
        _bShowAddEditPopup: false,
        _arrHeaderActions: null,
        _arrAddressTypeDropdown: null,
        _objAddressDetailsEditData: null,
        _objSpecialAddEditPopupAction: null,
        _objCandidateProfileAddressDetailsPromise: null,
        _objSelectedAddressType: null,
        _objSelectedCountry: null,
        _arrCandidateData: null,
        _arrCountyDropdown: null,
        _objNavigationService: Ember.inject.service("navigation"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objEnumsService: Ember.inject.service("a24-enums"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objUserSession: Ember.inject.service("user-session"),
        _objRestCountry: Ember.inject.service("rest-country"),

        _bServerError: false,
        _arrReloadActions: null,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            var sCountryId = this.get("_objUserSession.objContextDetails.sCountryId");
            var sCountryCode = sCountryId === "710" ? "ZA" : sCountryId === "840" ? "US" : "GB";
            // default country code is that of the context details
            this.set("_sSelectedCountryCode", sCountryCode);

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.getAddressDetails();
                }
            }]);

            // This is to init the rest service
            this.get("objAddressDetailsRestService");

            if (!this.get("objSettingsRestService.objSettings.profile_editable")) {
                this.set("_bAllowAddEdit", false);
            }

            this.set("_objSpecialAddEditPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onPopupAddEditCancel");
                }
            });

            this.set("_objSpecialGeoPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onPopupGeoCancel");
                }
            });

            var objValidationConfig = {};

            objValidationConfig["objChildren.type"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.address_line1"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 1);
                a24Validation.addMaxLength(objMixin, 50);
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.address_line2"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 1);
                a24Validation.addMaxLength(objMixin, 50);
            };
            objValidationConfig["objChildren.city"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
                a24Validation.addMinLength(objMixin, 1);
                a24Validation.addMaxLength(objMixin, 50);
            };
            objValidationConfig["objChildren.province_uksa"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.province_other"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
                a24Validation.addMinLength(objMixin, 2);
                a24Validation.addMaxLength(objMixin, 50);
            };
            objValidationConfig["objChildren.country_code"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.post_code"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 1);
                a24Validation.addMaxLength(objMixin, 50);
                a24Validation.addRequired(objMixin, true);
            };

            objValidationConfig["objChildren.longitude"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.latitude"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };

            this.set("objChildConfig", objValidationConfig);

            this.set("_arrAddressTypeDropdown", this.get("_objCandidateProfileEnumsService").getList("addressType"));
            this.set("_arrCountryDropdown", this.get("_objEnumsService").getList("country"));

            var objThis = this;
            this.set("_objCountries", this.get("_objEnumsService").getList("country"));
            this.set("funcGetCountriesSuggestions", function (sQueryString, funcOnComplete) {

                var arrCountries = objThis.get("_objCountries");
                var arrFilteredCountries = [];

                if (!a24Core.isEmpty(sQueryString)) {
                    for (var i = 0; i < arrCountries.length; i++) {
                        if (arrCountries[i].sLabel.toLowerCase().indexOf(sQueryString.toLowerCase()) !== -1) {
                            arrFilteredCountries.push(arrCountries[i]);
                        }
                    }
                } else {
                    arrFilteredCountries = arrCountries;
                }

                funcOnComplete(arrFilteredCountries);
            });

            this.getAddressDetails();
        },

        _bIsCandidateContext: Ember.computed("objAddressDetailsRestService.sContext", function () {
            return this.get("objAddressDetailsRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE");
        }),

        onInputValueChanged: function onInputValueChanged(arrChildRef) {
            this._super.apply(this, arguments);

            // Get the reference of the field that changed
            var sLookup = arrChildRef.join(".");

            if (sLookup === "objChildren.country_code") {
                if (!a24Core.isEmpty(this.get(sLookup + ".mValue"))) {
                    this.set("_sSelectedCountryCode", this.get(sLookup + ".mValue.sValue"));
                    if (this.get(sLookup + ".mValue.sValue") === "ZA" || this.get(sLookup + ".mValue.sValue") === "UK" || this.get(sLookup + ".mValue.sValue") === "GB" || this.get(sLookup + ".mValue.sValue") === "US") {
                        this.set("_bIsUkSaCountry", true);

                        this.get("objChildren.province_uksa").clear();
                        this.setCountyDropdown(this.get(sLookup + ".mValue.sValue"));
                    } else {
                        this.set("_bIsUkSaCountry", false);
                    }

                    this.get("objChildren.post_code").get("arrValidation").clear();
                    this.get("objChildren.post_code").set("funcValidationRequired", null);

                    if (this.get(sLookup + ".mValue.sValue") === "UK" || this.get(sLookup + ".mValue.sValue") === "GB" || this.get(sLookup + ".mValue.sValue") === "US") {
                        a24Validation.addRequired(this.get("objChildren.post_code"), true);
                        a24Validation.addPostCode(this.get("objChildren.post_code"), this.get(sLookup + ".mValue.sValue"));
                    } else {
                        a24Validation.addRequired(this.get("objChildren.post_code"), true);
                        a24Validation.addMinLength(this.get("objChildren.post_code"), 1);
                        a24Validation.addMaxLength(this.get("objChildren.post_code"), 100);
                    }

                    this._formatPostCode();
                }
            } else if (sLookup === "objChildren.post_code") {
                this._formatPostCode();
            }
        },
        _formatPostCode: function _formatPostCode() {
            var objCountryCode = this.get("objChildren")["country_code"];
            var objPostCode = this.get("objChildren")["post_code"];

            if (!a24Core.isEmpty(objCountryCode) && !a24Core.isEmpty(objCountryCode.mValue) && (objCountryCode.mValue.sValue === "UK" || objCountryCode.mValue.sValue === "GB")) {
                if (!a24Core.isEmpty(objPostCode.mValue)) {
                    var sInputValue = objPostCode.mValue.trim();
                    if (!/\s/.test(sInputValue)) {
                        var sValue1 = sInputValue.slice(0, sInputValue.length - 3);
                        var sValue2 = sInputValue.slice(sInputValue.length - 3, sInputValue.length);
                        objPostCode.setValueFromOutside(sValue1.toUpperCase() + " " + sValue2.toUpperCase());
                    } else if (sInputValue.split(" ").length > 1) {
                        objPostCode.setValueFromOutside(sInputValue.replace(/  +/g, " ").toUpperCase());
                    }
                }
            }
        },
        setCountyDropdown: function setCountyDropdown(sCountryCode) {
            var arrCounties = null;
            if (sCountryCode === "ZA") {
                arrCounties = this.get("_objCandidateProfileEnumsService").getList("saProvinces");
            } else if (sCountryCode === "UK" || sCountryCode === "GB") {
                arrCounties = this.get("_objCandidateProfileEnumsService").getList("ukProvinces");
            } else if (sCountryCode === "US") {
                arrCounties = this.get("_objCandidateProfileEnumsService").getList("usProvinces");
            }

            this.set("funcGetCountiesSuggestions", function (sQueryString, funcOnComplete) {

                var arrFilteredCounties = [];

                if (!a24Core.isEmpty(sQueryString)) {
                    for (var i = 0; i < arrCounties.length; i++) {
                        if (arrCounties[i].sLabel.toLowerCase().indexOf(sQueryString.toLowerCase()) !== -1) {
                            arrFilteredCounties.push(arrCounties[i]);
                        }
                    }
                } else {
                    arrFilteredCounties = arrCounties;
                }

                funcOnComplete(arrFilteredCounties);
            });
            this.set("_arrCountyDropdown", arrCounties);
        },

        _onLoadingChange: Ember.observer("_bLoading", function () {
            var _this2 = this;

            if (this.get("_bLoading")) {
                this.set("_arrHeaderActions", []);
            } else if (this.get("_bAllowAddEdit")) {
                this.set("_arrHeaderActions", [{
                    sTitle: this.get("_objStringsService").getString("add"),
                    sIcon: "add",
                    executeCardAction: function executeCardAction() {
                        _this2.setAddEditPopup();
                    }
                }]);
            }
        }).on("init"),

        prepareForDisplay: function prepareForDisplay(arrData) {
            var arrDisplay = [];

            arrData.sort(function (objAddressDetailsA, objAddressDetailsB) {
                if (objAddressDetailsA.type === "physical_address") {
                    return -1;
                }
                if (objAddressDetailsB.type === "physical_address") {
                    return 1;
                }
                if (objAddressDetailsA.type === "alternative_address") {
                    return 1;
                }
                if (objAddressDetailsB.type === "alternative_address") {
                    return -1;
                }
                return 0;
            });

            var objThis = this;

            var funcCardActionEditExecute = function funcCardActionEditExecute(objAddressDetails) {
                return function () {
                    objThis.setAddEditPopup(objAddressDetails);
                };
            };
            var funcCardActionRemoveExecute = function funcCardActionRemoveExecute(objAddressDetails) {
                return function () {
                    objThis.removeAddressDetails(objAddressDetails);
                };
            };
            var funcCardActionGeoEditExecute = function funcCardActionGeoEditExecute(objAddressDetails, sAddressLink) {
                return function () {
                    objThis.geoUpdateShow(objAddressDetails, sAddressLink);
                };
            };

            for (var i = 0; i < arrData.length; i++) {

                var objAddressDetails = arrData[i];

                /**
                 * THIS IS A TEMP FIX!
                 * Remove this once the api solves the UK country code issue.
                 *
                 * We set the invalid iso country code of UK to the valid iso country code of GB
                 */
                if (objAddressDetails.country_code === "UK") {
                    objAddressDetails.country_code = "GB";
                }

                var sAddressLink = "";
                var sAddressLinkCoords = "";
                var sHtmlLabel = "";
                var objCountry = this.get("_objEnumsService").getList("country").findBy("sValue", objAddressDetails.country_code);

                if (!a24Core.isEmpty(objAddressDetails.co_ords)) {
                    sAddressLinkCoords = objAddressDetails.co_ords.latitude + "," + objAddressDetails.co_ords.longitude;
                    sHtmlLabel = a24Core.getSafeStringLink(Ember, objAddressDetails.address_line1, sAddressLinkCoords, "address");
                }
                if (!a24Core.isEmpty(objAddressDetails.address_line1)) {
                    sAddressLink = objAddressDetails.address_line1;
                }
                if (!a24Core.isEmpty(objAddressDetails.address_line2)) {
                    sAddressLink = sAddressLink + " " + objAddressDetails.address_line2;
                }
                if (!a24Core.isEmpty(objAddressDetails.city)) {
                    sAddressLink = sAddressLink + " " + objAddressDetails.city;
                }
                if (!a24Core.isEmpty(objAddressDetails.province)) {
                    sAddressLink = sAddressLink + " " + objAddressDetails.province;
                }
                if (!a24Core.isEmpty(objAddressDetails.country_code)) {
                    sAddressLink = sAddressLink + " " + objCountry.sLabel;
                }
                if (!a24Core.isEmpty(objAddressDetails.post_code)) {
                    sAddressLink = sAddressLink + " " + objAddressDetails.post_code;
                }

                if (a24Core.isEmpty(sHtmlLabel)) {
                    sHtmlLabel = a24Core.getSafeStringLink(Ember, objAddressDetails.address_line1, sAddressLink, "address");
                }
                var sType = this.get("_objCandidateProfileEnumsService").getFrontendValue("addressType", objAddressDetails.type);
                var sIcon = a24.getIconForAddress(objAddressDetails.type);

                var arrActions = [];

                if (this.get("_bAllowAddEdit")) {
                    if (!this.get("_bIsCandidateContext") || this.get("_bIsCandidateContext") && objAddressDetails.type !== "physical_address") {
                        arrActions.push({
                            sTitle: this.get("_objStringsService").getString("edit"),
                            sIcon: "edit",
                            executeCardAction: funcCardActionEditExecute(objAddressDetails)
                        });
                    }
                    if (objAddressDetails.type !== "physical_address") {
                        arrActions.push({
                            sTitle: this.get("_objStringsService").getString("remove"),
                            sIcon: "delete",
                            executeCardAction: funcCardActionRemoveExecute(objAddressDetails)
                        });
                    }
                    if (!this.get("_bIsCandidateContext") && !a24Core.isEmpty(objAddressDetails.geo_location_details) && objAddressDetails.geo_location_details.type !== "lookup") {
                        arrActions.push({
                            sTitle: this.get("_objCandidateProfileStringsService").getString("updateGeoLocation"),
                            sIcon: "location_on",
                            executeCardAction: funcCardActionGeoEditExecute(objAddressDetails, sAddressLink)
                        });
                    }
                }

                var arrItems = [{
                    sLabel: this.get("_objCandidateProfileStringsService").getString("addressType"),
                    sValue: sType,
                    bText: true
                }, {
                    sLabel: this.get("_objStringsService").getString("addressLine1"),
                    sValue: objAddressDetails.address_line1,
                    bText: true
                }];

                arrItems.push({
                    sLabel: this.get("_objStringsService").getString("addressLine2"),
                    sValue: objAddressDetails.address_line2,
                    bText: true,
                    bHideEmpty: true
                });

                arrItems.push({
                    sLabel: this.get("_objStringsService").getString("cityTown"),
                    sValue: objAddressDetails.city,
                    bText: true,
                    bHideEmpty: true
                });

                arrItems.push({
                    sLabel: this.get("_objStringsService").getLocalisedString("province", objAddressDetails.country_code),
                    sValue: objAddressDetails.province,
                    bText: true,
                    bHideEmpty: true
                });

                var sCountry = null;
                if (!a24Core.isEmpty(objCountry)) {
                    sCountry = objCountry.sLabel;
                }

                arrItems.push({
                    sLabel: this.get("_objStringsService").getString("country"),
                    sValue: sCountry,
                    bText: true
                });

                arrItems.push({
                    sLabel: this.get("_objStringsService").getLocalisedString("postCode", objAddressDetails.country_code),
                    sValue: objAddressDetails.post_code,
                    bText: true
                });

                var sCoords = null;
                if (!a24Core.isEmpty(objAddressDetails.co_ords)) {
                    sCoords = a24Core.getSafeStringLink(Ember, sAddressLinkCoords, sAddressLinkCoords, "address");
                }

                arrItems.push({
                    sLabel: this.get("_objCandidateProfileStringsService").getString("coordinates"),
                    sValue: sCoords,
                    bText: true,
                    bHideEmpty: true
                });

                arrDisplay.push({
                    sLabel: sHtmlLabel,
                    sExtra: sType,
                    sIcon: sIcon,
                    arrActions: arrActions,
                    arrItems: [{
                        arrItems: arrItems
                    }]
                });
            }
            return arrDisplay;
        },
        setAddEditPopup: function setAddEditPopup(objAddressDetails) {
            this.clearAllErrorMessages();
            this.clearForm();

            this.set("_sHeaderPopup", this.get("_objStringsService").getTokenString("addItem", this.get("_objCandidateProfileStringsService").getString("addressDetails")));

            this.set("_bIsAdd", true);

            if (!a24Core.isEmpty(objAddressDetails)) {
                // set inputs based on id
                this.set("_sHeaderPopup", this.get("_objStringsService").getTokenString("editItem", this.get("_objCandidateProfileStringsService").getString("addressDetails")));
                this.set("_bIsAdd", false);
                this.set("_objAddressDetailsEditData", objAddressDetails);

                this.populateInputFields(objAddressDetails);

                this.showPopup();
            } else {
                // This is an add popup, so lets set the defaults
                var objSelectedAddressType = this.get("_arrAddressTypeDropdown").findBy("sValue", this.get("_objCandidateProfileEnumsService").get("addressType.PHYSICAL_ADDRESS.backend"));
                this.set("_objSelectedAddressType", Ember.copy(objSelectedAddressType, true));

                var objThis = this;
                if (!a24Core.isEmpty(objThis.get("_sCountryCode"))) {
                    objThis._setSelectedCountry(objThis.get("_sCountryCode"));
                    objThis.showPopup();
                } else {
                    if (objThis.get("_objEnv").environment === "test") {
                        objThis._setSelectedCountry(objThis.get("_sDefaultCountry"));
                        objThis.showPopup();
                    } else {
                        objThis.get("_objAjax")({
                            url: "//ipinfo.io",
                            dataType: "jsonp"
                        }).always(function (objResponse) {
                            if (!a24Core.isEmpty(objThis)) {
                                Ember.run(function () {
                                    var sCountryCode = null;
                                    if (!a24Core.isEmpty(objResponse) && !a24Core.isEmpty(objResponse.country)) {
                                        sCountryCode = objResponse.country;
                                    } else {
                                        //Could not determine country so just default to gb
                                        sCountryCode = objThis.get("_sDefaultCountry");
                                    }
                                    objThis._setSelectedCountry(sCountryCode);
                                    objThis.showPopup();
                                });
                            }
                        });
                    }
                }
            }
        },
        _setSelectedCountry: function _setSelectedCountry(sCountryCode) {
            var objSelectedCountry = this.get("_arrCountryDropdown").findBy("sValue", sCountryCode);

            if (!a24Core.isEmpty(objSelectedCountry)) {
                this.get("objChildren.country_code").setValueFromOutside(objSelectedCountry);
            }
        },
        showPopup: function showPopup() {
            var _this3 = this;

            this.set("_bShowAddEditPopup", true);
            if (!a24Core.isEmpty(this.get("setBreadCrumbAction"))) {
                this.get("setBreadCrumbAction")();
            }

            // This is in a double next since this needs to be run after the input value changed on county
            Ember.run.next(function () {
                if (a24Core.isEmpty(_this3)) {
                    return;
                }
                Ember.run.next(function () {
                    if (a24Core.isEmpty(_this3)) {
                        return;
                    }
                    Ember.run.schedule("afterRender", function () {
                        _this3.set("_objOriginalData", _this3.getPlainFormValueObject());
                    });
                });
            });
        },
        populateInputFields: function populateInputFields(objAddressDetails) {
            var _this4 = this;

            var objSelectedAddressType = this.get("_arrAddressTypeDropdown").findBy("sValue", objAddressDetails.type);
            if (!a24Core.isEmpty(objSelectedAddressType)) {
                this.set("_objSelectedAddressType", Ember.copy(objSelectedAddressType, true));
            }

            this.get("objChildren.address_line1").setValueFromOutside(objAddressDetails.address_line1);
            if (!a24Core.isEmpty(objAddressDetails.address_line2)) {
                this.get("objChildren.address_line2").setValueFromOutside(objAddressDetails.address_line2);
            }
            if (!a24Core.isEmpty(objAddressDetails.city)) {
                this.get("objChildren.city").setValueFromOutside(objAddressDetails.city);
            }

            this._setSelectedCountry(objAddressDetails.country_code);

            // This next is here so that the inputs for county has time to swap around
            Ember.run.next(function () {
                if (a24Core.isEmpty(_this4)) {
                    return;
                }
                if (!a24Core.isEmpty(objAddressDetails.province)) {
                    if (objAddressDetails.country_code === "ZA" || objAddressDetails.country_code === "GB" || objAddressDetails.country_code === "US") {
                        _this4.setCountyDropdown(objAddressDetails.country_code);
                        var objSelectedCounty = _this4.get("_arrCountyDropdown").findBy("sValue", objAddressDetails.province);
                        if (!a24Core.isEmpty(objSelectedCounty)) {
                            _this4.get("objChildren.province_uksa").setValueFromOutside(objSelectedCounty);
                        }
                    } else {
                        _this4.get("objChildren.province_other").setValueFromOutside(objAddressDetails.province);
                    }
                }
            });

            this.get("objChildren.post_code").setValueFromOutside(objAddressDetails.post_code);
        },
        getAddressDetails: function getAddressDetails() {
            var _this5 = this;

            this.set("_bLoading", true);
            this.set("_bServerError", false);

            var objContextDetails = this.get("_objUserSession").objContextDetails;

            var objPromiseAddressDetails = this.get("objAddressDetailsRestService").getAddressDetails(this, "_objCandidateProfileAddressDetailsPromise", this.get("sCandidateId"), function () {}, // handled in rsvp
            a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this5.set("_bServerError", true);
                    return;
                }
                // We swallow all 404 errors as it means that the candidate does not have
                // any address details info
                _this5.set("_arrCandidateData", []);
                _this5.set("_bLoading", false);
            }));

            var objServiceCalls = {
                _objRestAddressProperty: objPromiseAddressDetails
            };
            var arrRequiredResponses = ["_objRestAddressProperty"];

            if (!a24Core.isEmpty(objContextDetails.sCountryId)) {
                var objPromiseCountry = this.get("_objRestCountry").getCountryById(this, "_objRestCountryPromise", objContextDetails.sCountryId, function () {}, // handled in rsvp
                a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode) {
                    if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                        _this5.set("_bServerError", true);
                        return;
                    }
                    // handled in rsvp
                }));

                objServiceCalls._objRestCountryProperty = objPromiseCountry;
                arrRequiredResponses.push("_objRestCountryProperty");
            }

            Ember.copy(a24RSVP).configure(this, objServiceCalls, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    if (!a24Core.isEmpty(objResponses._objRestAddressProperty) && !a24Core.isEmpty(objResponses._objRestAddressProperty.objHeaders) && objResponses._objRestAddressProperty.objHeaders.statusText === "abort" || !a24Core.isEmpty(objResponses._objRestCountryProperty) && !a24Core.isEmpty(objResponses._objRestCountryProperty.objHeaders) && objResponses._objRestCountryProperty.objHeaders.statusText === "abort") {
                        return;
                    }

                    if (!a24Core.isEmpty(objResponses._objRestAddressProperty) && !a24Core.isEmpty(objResponses._objRestAddressProperty.objResponse)) {
                        _this5.set("_arrCandidateData", _this5.prepareForDisplay(objResponses._objRestAddressProperty.objResponse));
                    } else {
                        _this5.set("_arrCandidateData", []);
                    }

                    if (!a24Core.isEmpty(objResponses._objRestCountryProperty) && !a24Core.isEmpty(objResponses._objRestCountryProperty.objResponse)) {
                        _this5.set("_sCountryCode", objResponses._objRestCountryProperty.objResponse.sCountryCode);
                    } else {
                        _this5.set("_sCountryCode", "");
                    }

                    _this5.set("_bLoading", false);
                }
            }]);
        },
        removeAddressDetails: function removeAddressDetails(objAddressDetailsData) {
            this.set("_bShowDeleteConfirm", true);
            this.set("_objAddressDetailsEditData", objAddressDetailsData);
        },
        geoUpdateShow: function geoUpdateShow(objAddressDetails, sAddressLink) {
            var _this6 = this;

            this.clearAllErrorMessages();
            this.clearForm();
            this.set("_bGeoPopupLoading", false);

            var sHtmlLabel = a24Core.getSafeStringLink(Ember, this.get("_objCandidateProfileStringsService").getString("googleMaps"), sAddressLink, "address");

            this.set("_sGeoLocationEditText", Ember.String.htmlSafe(this.get("_objCandidateProfileStringsService").getTokenString("geoLocationUpdateText", sHtmlLabel)));
            if (!a24Core.isEmpty(objAddressDetails.co_ords) && !a24Core.isEmpty(objAddressDetails.co_ords.longitude) && !a24Core.isEmpty(objAddressDetails.co_ords.latitude)) {
                this.get("objChildren.longitude").setValueFromOutside(objAddressDetails.co_ords.longitude);
                this.get("objChildren.latitude").setValueFromOutside(objAddressDetails.co_ords.latitude);
            }

            this.set("_bShowGeoEdit", true);

            this.set("_objGeoEditData", objAddressDetails);

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this6)) {
                    return;
                }
                Ember.run.next(function () {
                    if (a24Core.isEmpty(_this6)) {
                        return;
                    }
                    Ember.run.schedule("afterRender", function () {
                        _this6.set("_objOriginalData", _this6.getPlainFormValueObject());
                    });
                });
            });
        },
        setErrorResponse: function setErrorResponse(sCode, objError, sSnackbarMessage) {
            if (sCode === "MAX_LENGTH") {
                this.setResponseError(objError.arrPath, "validationRestMaxLength");
            } else if (sCode === "MAXIMUM") {
                this.setResponseError(objError.arrPath, "validationRestMax");
            } else if (sCode === "MINIMUM") {
                this.setResponseError(objError.arrPath, "validationRestMin");
            } else if (sCode === "MIN_LENGTH") {
                this.setResponseError(objError.arrPath, "validationRestMaxLength");
            } else if (sCode === "PATTERN") {
                this.setResponseError(objError.arrPath, "validationRestInvalidFormat");
            } else if (sCode === "ENUM_MISMATCH") {
                this.setResponseError(objError.arrPath, "validationRestUnsupportedValue");
            } else if (sCode === "MAX_ITEMS_ALLOWED") {
                this.setResponseError(objError.arrPath, "failedAddAddressMaxItems", false, true, this.get("_objCandidateProfileStringsService"));
            } else if (sCode === "INVALID_COUNTRY_CODE") {
                this.setResponseError(objError.arrPath, "validationRestUnsupportedValue");
            } else if (sCode === "TYPE_UPDATE_FORBIDDEN") {
                this.setResponseError(objError.arrPath, "addressTypeUpdateForbidden", false, true, this.get("_objCandidateProfileStringsService"));
            } else {
                this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            }

            this.set("_bPopupLoading", false);
            this.set("_bGeoPopupLoading", false);
        },
        setSuccessResponse: function setSuccessResponse(sSnackbarMessage) {
            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            this.set("_bPopupLoading", false);
            this.set("_bShowAddEditPopup", false);
            this.set("_bShowGeoEdit", false);
            this.set("_bShowDeleteConfirm", false);

            this.getAddressDetails();
        },
        getPayload: function getPayload() {

            var objPayload = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());

            // Had to make two properties for the province/county. When trying to get the value it might pull the other
            // input since they both have the same property name. This is so that there is no confusion
            if (!a24Core.isEmpty(objPayload.province_uksa)) {
                objPayload.province = objPayload.province_uksa.sValue;
                delete objPayload.province_uksa;
            } else if (!a24Core.isEmpty(objPayload.province_other)) {
                objPayload.province = objPayload.province_other;
                delete objPayload.province_other;
            }

            objPayload.country_code = objPayload.country_code.sValue;

            return objPayload;
        },

        actions: {
            onPopupDeleteConfirm: function onPopupDeleteConfirm() {
                var _this7 = this;

                this.set("_bPopupLoading", true);
                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("removingItem", this.get("_objCandidateProfileStringsService").getString("addressDetails")));
                this.get("objAddressDetailsRestService").deleteAddressDetails(this, "_objCandidateProfileAddressDetailsPromise", this.get("sCandidateId"), this.get("_objAddressDetailsEditData._id"), function () {
                    _this7.setSuccessResponse(_this7.get("_objStringsService").getTokenString("successRemoveItem", _this7.get("_objCandidateProfileStringsService").getString("addressDetails")));
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    if (sCode === "404") {
                        _this7.get("_objSnackbarService").showSnackbarMessage(_this7.get("_objStringsService").getString("removeFailedRefreshPage"));
                    } else {
                        _this7.get("_objSnackbarService").showSnackbarMessage(_this7.get("_objStringsService").getTokenString("failRemoveItem", _this7.get("_objCandidateProfileStringsService").getString("addressDetails")));
                    }

                    _this7.set("_bPopupLoading", false);
                }));
            },
            onPopupClose: function onPopupClose() {
                if (this.get("_bPopupLoading")) {
                    return;
                }

                this.set("_bShowDeleteConfirm", false);
            },
            onPopupAddEditCancel: function onPopupAddEditCancel() {
                if (this.get("_bPopupLoading")) {
                    return;
                }
                // Check if the form has any unsaved data
                var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

                if (bDataMatches) {
                    this.set("_bShowAddEditPopup", false);
                } else {
                    this.set("_bShowUnsavedData", true);
                }
            },
            onPopupGeoCancel: function onPopupGeoCancel() {
                if (this.get("_bGeoPopupLoading")) {
                    return;
                }
                // Check if the form has any unsaved data
                var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

                if (bDataMatches) {
                    this.set("_bShowGeoEdit", false);
                } else {
                    this.set("_bShowUnsavedData", true);
                }
            },
            onPopupGeoConfirm: function onPopupGeoConfirm() {
                var _this8 = this;

                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    var objPayload = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());

                    this.set("_bGeoPopupLoading", true);

                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objCandidateProfileStringsService").getString("geoLocation")));

                    this.get("objAddressDetailsRestService").updateGeoLocation(this, "_objCandidateProfileAddressDetailsPromise", this.get("sCandidateId"), this.get("_objGeoEditData._id"), objPayload, function () {
                        _this8.setSuccessResponse(_this8.get("_objStringsService").getTokenString("successUpdateItem", _this8.get("_objCandidateProfileStringsService").getString("geoLocation")));
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                        if (sCode === "404") {
                            _this8.get("_objSnackbarService").showSnackbarMessage(_this8.get("_objStringsService").getString("updateFailedRefreshPage"));
                            _this8.set("_bGeoPopupLoading", false);
                        } else {
                            _this8.setErrorResponse(sCode, objError, _this8.get("_objStringsService").getTokenString("failUpdateItem", _this8.get("_objCandidateProfileStringsService").getString("geoLocation")));
                        }
                    }));
                }
            },
            onPopupAddEditConfirm: function onPopupAddEditConfirm() {
                var _this9 = this;

                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    var objPayload = this.getPayload();

                    this.set("_bPopupLoading", true);
                    if (this.get("_bIsAdd")) {
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objCandidateProfileStringsService").getString("addressDetails")));
                        this.get("objAddressDetailsRestService").createAddressDetails(this, "_objCandidateProfileAddressDetailsPromise", this.get("sCandidateId"), objPayload, function () {
                            _this9.setSuccessResponse(_this9.get("_objStringsService").getTokenString("successAddItem", _this9.get("_objCandidateProfileStringsService").getString("addressDetails")));
                        }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                            _this9.setErrorResponse(sCode, objError, _this9.get("_objStringsService").getTokenString("failAddItem", _this9.get("_objCandidateProfileStringsService").getString("addressDetails")));
                        }));
                    } else {
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objCandidateProfileStringsService").getString("addressDetails")));
                        this.get("objAddressDetailsRestService").updateAddressDetails(this, "_objCandidateProfileAddressDetailsPromise", this.get("sCandidateId"), this.get("_objAddressDetailsEditData._id"), objPayload, function () {
                            _this9.setSuccessResponse(_this9.get("_objStringsService").getTokenString("successUpdateItem", _this9.get("_objCandidateProfileStringsService").getString("addressDetails")));
                        }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                            if (sCode === "404") {
                                _this9.get("_objSnackbarService").showSnackbarMessage(_this9.get("_objStringsService").getString("updateFailedRefreshPage"));
                                _this9.set("_bPopupLoading", false);
                            } else {
                                _this9.setErrorResponse(sCode, objError, _this9.get("_objStringsService").getTokenString("failUpdateItem", _this9.get("_objCandidateProfileStringsService").getString("addressDetails")));
                            }
                        }));
                    }
                }
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowAddEditPopup", false);
                this.set("_bShowUnsavedData", false);
                this.set("_bShowGeoEdit", false);
            }
        }
    });
});