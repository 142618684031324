enifed('ember-routing/index', ['exports', 'ember-routing/location/api', 'ember-routing/location/none_location', 'ember-routing/location/hash_location', 'ember-routing/location/history_location', 'ember-routing/location/auto_location', 'ember-routing/system/generate_controller', 'ember-routing/system/controller_for', 'ember-routing/system/dsl', 'ember-routing/system/router', 'ember-routing/system/route', 'ember-routing/system/query_params', 'ember-routing/services/routing', 'ember-routing/services/router', 'ember-routing/system/cache', 'ember-routing/ext/controller'], function (exports, _api, _none_location, _hash_location, _history_location, _auto_location, _generate_controller, _controller_for, _dsl, _router, _route, _query_params, _routing, _router2, _cache) {
  'use strict';

  exports.BucketCache = exports.RouterService = exports.RoutingService = exports.QueryParams = exports.Route = exports.Router = exports.RouterDSL = exports.controllerFor = exports.generateControllerFactory = exports.generateController = exports.AutoLocation = exports.HistoryLocation = exports.HashLocation = exports.NoneLocation = exports.Location = undefined;
  Object.defineProperty(exports, 'Location', {
    enumerable: true,
    get: function () {
      return _api.default;
    }
  });
  Object.defineProperty(exports, 'NoneLocation', {
    enumerable: true,
    get: function () {
      return _none_location.default;
    }
  });
  Object.defineProperty(exports, 'HashLocation', {
    enumerable: true,
    get: function () {
      return _hash_location.default;
    }
  });
  Object.defineProperty(exports, 'HistoryLocation', {
    enumerable: true,
    get: function () {
      return _history_location.default;
    }
  });
  Object.defineProperty(exports, 'AutoLocation', {
    enumerable: true,
    get: function () {
      return _auto_location.default;
    }
  });
  Object.defineProperty(exports, 'generateController', {
    enumerable: true,
    get: function () {
      return _generate_controller.default;
    }
  });
  Object.defineProperty(exports, 'generateControllerFactory', {
    enumerable: true,
    get: function () {
      return _generate_controller.generateControllerFactory;
    }
  });
  Object.defineProperty(exports, 'controllerFor', {
    enumerable: true,
    get: function () {
      return _controller_for.default;
    }
  });
  Object.defineProperty(exports, 'RouterDSL', {
    enumerable: true,
    get: function () {
      return _dsl.default;
    }
  });
  Object.defineProperty(exports, 'Router', {
    enumerable: true,
    get: function () {
      return _router.default;
    }
  });
  Object.defineProperty(exports, 'Route', {
    enumerable: true,
    get: function () {
      return _route.default;
    }
  });
  Object.defineProperty(exports, 'QueryParams', {
    enumerable: true,
    get: function () {
      return _query_params.default;
    }
  });
  Object.defineProperty(exports, 'RoutingService', {
    enumerable: true,
    get: function () {
      return _routing.default;
    }
  });
  Object.defineProperty(exports, 'RouterService', {
    enumerable: true,
    get: function () {
      return _router2.default;
    }
  });
  Object.defineProperty(exports, 'BucketCache', {
    enumerable: true,
    get: function () {
      return _cache.default;
    }
  });
});