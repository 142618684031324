define("a24-ember-staffshift-users/services/rest-acl-group", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * This service method will make a PUT to the acl group route
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objPayload - The new policy type details
         *
         * @author Ahmed Onawale<ahmedonawale@gmail.com>
         * @since  27 November 2017
         */
        addAclGroup: function addAclGroup(objCaller, sProperty, funcSuccess, objFailure, objPayload) {
            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, Ember.get(this, "_objUserSession"), Ember.get(this, "_objNavigation"), "POST", Ember.get(this, "sRestApiBaseUrl") + "/v1/acl/group", funcSuccess, objFailure, objPayload));
        },

        /**
         * This service method will make a PUT to the acl group route
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objPayload - The new policy type details
         *
         * @author Ahmed Onawale<ahmedonawale@gmail.com>
         * @since  27 November 2017
         */
        editAclGroup: function editAclGroup(objCaller, sProperty, sCandidateId, funcSuccess, objFailure, objPayload) {
            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, Ember.get(this, "_objUserSession"), Ember.get(this, "_objNavigation"), "POST", Ember.get(this, "sRestApiBaseUrl") + "/v1/acl/group/" + sCandidateId, funcSuccess, objFailure, objPayload));
        },

        /**
         * This service method will make a GET to the acl group route with a specific
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sAclGroupId - The id of the acl group that you wish to recieve
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  29 November 2017
         */
        getAclGroupById: function getAclGroupById(objCaller, sProperty, sAclGroupId, funcSuccess, objFailure) {
            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, Ember.get(this, "_objUserSession"), Ember.get(this, "_objNavigation"), "GET", Ember.get(this, "sRestApiBaseUrl") + "/v1/acl/group/" + sAclGroupId, funcSuccess, objFailure));
        }
    });
});