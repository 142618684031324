define("a24-ember-lib/services/chatter-iframe-com-base", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objLastNotification: null,

        objIntervalTimer: null,
        sLastHash: null,
        iCacheBust: 0,

        postMessage: function postMessage(sMessage, sTargetUrl, objTarget) {

            if (!sTargetUrl) {
                return;
            }

            sTargetUrl = window.location.protocol + sTargetUrl;

            objTarget = objTarget || parent; // default to parent

            if (window["postMessage"]) {
                // the browser supports window.postMessage, so call it with a targetOrigin
                // set appropriately, based on the target_url parameter.
                objTarget["postMessage"](sMessage, sTargetUrl.replace(/([^:]+:\/\/[^/]+).*/, "$1"));
            } else if (sTargetUrl) {
                // the browser does not support window.postMessage, so set the location
                // of the objTarget to sTargetUrl#sMessage. A bit ugly, but it works! A cache
                // bust parameter is added to ensure that repeat messages trigger the callback.
                this.set("iCacheBust", this.get("iCacheBust") + 1);
                objTarget.location = sTargetUrl.replace(/#.*$/, "") + "#" + +new Date() + this.get("iCacheBust") + "&" + sMessage;
            }
        },

        receiveMessage: function receiveMessage(funcCallback, mSourceOrigin) {
            var _this = this;

            // browser supports window.postMessage
            if (window["postMessage"]) {
                var funcUnregister = null;
                var objCallbackWrapper = function objCallbackWrapper(objEvent) {
                    if (typeof mSourceOrigin === "string" && objEvent.origin !== window.location.protocol + mSourceOrigin || Object.prototype.toString.call(mSourceOrigin) === "[object Function]" && mSourceOrigin(objEvent.origin) === !1) {
                        return !1;
                    }
                    funcCallback(objEvent);
                };

                if (window["addEventListener"]) {
                    window["addEventListener"]("message", objCallbackWrapper, !1);
                    funcUnregister = function funcUnregister() {
                        window["removeEventListener"]("message", objCallbackWrapper, !1);
                    };
                    return funcUnregister;
                } else {
                    window["attachEvent"]("onmessage", objCallbackWrapper);
                    funcUnregister = function funcUnregister() {
                        window["detachEvent"]("onmessage", objCallbackWrapper);
                    };
                    return funcUnregister;
                }
            } else {
                // a polling loop is started & callback is called whenever the location.hash changes
                var funcUnregisterPoller = function funcUnregisterPoller() {
                    if (!a24Core.isEmpty(_this.get("objIntervalTimer"))) {
                        Ember.run.cancel(_this.get("objIntervalTimer"));
                        _this.set("objIntervalTimer", null);
                    }
                };
                funcUnregisterPoller();

                if (!a24Core.isEmpty(funcCallback)) {
                    this._funcPoll(funcCallback);
                }

                return funcUnregisterPoller;
            }
        },

        openDesktopNotification: function openDesktopNotification(sIframeUrl, objTarget, objNotificationData) {
            var objThis = this;
            if ("Notification" in window) {
                if (Notification.permission !== "granted") {
                    Notification.requestPermission();
                } else {
                    if (!objNotificationData.hasOwnProperty("forceSingleton")) {
                        objNotificationData.forceSingleton = true;
                    }
                    if (objNotificationData.forceSingleton && !a24Core.isEmpty(this.get("_objLastNotification"))) {
                        this.get("_objLastNotification").close();
                    }
                    var objNotification = new Notification(objNotificationData.title, {
                        body: objNotificationData.body,
                        icon: objNotificationData.icon,
                        badge: objNotificationData.badge
                    });

                    if (!a24Core.isEmpty(objNotificationData.callbackId)) {
                        objNotification.onclick = function () {
                            var objData = {
                                type: "callback_by_id",
                                data: {
                                    callbackId: objNotificationData.callbackId
                                }
                            };

                            objThis.postMessage(JSON.stringify(objData), sIframeUrl, objTarget);

                            window.focus();
                        };
                    }

                    this.set("_objLastNotification", objNotification);
                }
            }
        },

        _funcPoll: function _funcPoll(funcCallback) {
            var _this2 = this;

            this.set("objIntervalTimer", Ember.run.later(this, function () {
                if (!a24Core.isEmpty(_this2.get("objIntervalTimer"))) {
                    Ember.run.cancel(_this2.get("objIntervalTimer"));
                }
                var sHash = document.location.hash;
                var sRegex = /^#?\d+&/;
                if (sHash !== _this2.get("sLastHash") && sRegex.test(sHash)) {
                    _this2.set("sLastHash", sHash);
                    funcCallback({ data: sHash.replace(sRegex, "") });
                }
                _this2._funcPoll();
            }, 100));
        }
    });
});