define("a24-ember-candidate-profile/services/rest-candidate-plugin", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),

        sContext: null,
        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        init: function init() {
            this._super.apply(this, arguments);

            this.set("sContext", this.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE"));
        },

        /**
         * This service method will be used to retrieve the plugins for a candidate
         *
         * @param objContext - The ember object on which the property must exist, component / controller
         * @param sProperty The property name on which to get and set the promise. Can be send in as null for service calls
         *                  that specifically do no need to be canceled when making the same call again
         * @param sCandidateId - The candidate id the plugin needs to be returned for
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The object containing the callback function to execute when the service call is not
         *                     successful including the array of error codes that you want to handle
         * @param objQueryParams - The object containing the optional query params
         *
         * @author Jacques Slabber
         * @since  23 September 2016
         */
        getPlugins: function getPlugins(objContext, sProperty, sCandidateId, funcSuccess, objFailure, objQueryParams) {
            a24RestUrlConstruct.setBaseUrl("/v1/candidate/" + sCandidateId + "/plugin");
            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", Ember.get(this, "sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },


        /**
         * This service method will be used to retrieve a specific plugin for a candidate
         *
         * @param objContext - The ember object on which the property must exist, component / controller
         * @param sProperty The property name on which to get and set the promise. Can be send in as null for service calls
         *                  that specifically do no need to be canceled when making the same call again
         * @param sCandidateId - The candidate id the plugin needs to be returned for
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The object containing the callback function to execute when the service call is not
         *                     successful including the array of error codes that you want to handle
         * @param sPluginId - The id of the plugin to load
         *
         * @author Deon de Wet
         * @since  17 October 2016
         */
        getPlugin: function getPlugin(objContext, sProperty, sCandidateId, funcSuccess, objFailure, sPluginId) {
            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", Ember.get(this, "sRestApiBaseUrl") + "/v1/candidate/" + sCandidateId + "/plugin/" + sPluginId, funcSuccess, objFailure));
        }
    });
});