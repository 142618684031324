define("a24-ember-candidate-profile/components/candidate-activities", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-activities", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _candidateActivities, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _candidateActivities.default,
        sCandidateId: null,
        sCandidateName: null,
        objActivityRestService: null,
        objActivityTypeRestService: null,
        objRestCandidateActivityEmailComm: null,
        objRestCandidateActivityConversation: null,
        objRestCandidateActivityNotification: null,
        sActivityContext: null,
        _bLoading: false,
        _bPopupLoading: false,
        _arrHeaderActions: null,
        _sPopupHeader: null,
        _arrActivities: null,
        _objSpecialAddPopupAction: null,
        _bShowUnsavedData: false,
        _bShowAddPopup: false,
        _objActivityEditData: null,
        _objActivitiesGetPromise: null,
        _objActivityPostPromise: null,
        _arrActivityTypes: null,
        _arrActivityTypeAddEditDropdown: null,
        _arrReloadActions: null,

        _sActivityTypeSelected: null,
        _mapActivityTypeDescription: null,

        _env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),
        _sTriageBaseUrl: Ember.computed.readOnly("_env.sTriageBaseUrl"),
        _sReactGatewayUrl: Ember.computed.readOnly("_env.sReactGatewayUrl"),

        _objNavigation: Ember.inject.service("navigation"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objUserSession: Ember.inject.service("user-session"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objSSCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objSSCoreEnumsService: Ember.inject.service("a24-enums-staffshift-core"),

        _bServerError: false,
        _iResultCount: 0,
        _iPage: 1,
        _iPerPage: 10,
        _arrFilterConfig: null,
        _objQueryParams: null,

        _bShowEmailMessagePopup: false,
        _objActivityTypesGetPromise: null,
        _bShowNotifcationsSentPopup: false,
        _objCurrentNotificationSentCache: null,
        _objEmailActivity: null,
        _objNotificationActivity: null,

        _sIFrameUrl: null,
        _objReactPopupPostData: null,
        _bShowReactPopup: false,
        _bReactPopupLoading: true,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            if (a24Core.isEmpty(this.get("_sIFrameUrl"))) {
                this.set("_sIFrameUrl", this.get("_sReactGatewayUrl"));
            }

            this.set("_sActivityTypeSelected", this.get("_objCandidateProfileStringsService").getTokenString("selectedActivityTypes", "-"));

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.doServiceCalls();
                }
            }]);

            this.set("_sPopupHeader", this.get("_objStringsService").getTokenString("addItem", this.get("_objCandidateProfileStringsService").getString("activity")));

            this.set("_arrHeaderActions", [{
                sTooltipText: this.get("_objStringsService").getString("add"),
                sIcon: "add",
                executeCardAction: function executeCardAction() {
                    _this.setAddPopup();
                }
            }, {
                sTooltipText: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.doServiceCalls(_this.get("_objQueryParams"));
                }
            }]);

            this.set("_objSpecialAddPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.hidePopup();
                }
            });

            // Set up the form children
            this.set("objChildConfig", {
                // activity type drop down
                "objChildren.activity_type": function objChildrenActivity_type(objMixin) {
                    // This field is required
                    a24Validation.addRequired(objMixin, true);
                },
                // description
                "objChildren.description": function objChildrenDescription(objMixin) {
                    // This fields has a min Length of 1
                    a24Validation.addMinLength(objMixin, 3);
                },
                // comment
                "objChildren.comment": function objChildrenComment(objMixin) {
                    // This fields has a min Length of 1
                    a24Validation.addMinLength(objMixin, 1);
                }
            });
            this.set("_objCurrentNotificationSentCache", {});

            this.doServiceCalls();
        },
        doServiceCalls: function doServiceCalls(objQueryParams) {
            var _this2 = this;

            this.set("_bLoading", true);
            this.set("_bServerError", false);
            this.set("_iResultCount", 0);

            var sCandidateId = this.get("sCandidateId");

            if (a24Core.isEmpty(objQueryParams)) {
                objQueryParams = {
                    page: { mValue: this.get("_iPage") },
                    items_per_page: { mValue: this.get("_iPerPage") }
                };
            }

            if (a24Core.isEmpty(objQueryParams.activity_date)) {
                objQueryParams.activity_date = {
                    mValue: "desc",
                    bSortBy: true
                };
            }
            // Save the current query params for later use.
            this.set("_objQueryParams", Ember.copy(objQueryParams, true));

            var objPromiseActivities = this.get("objActivityRestService").getActivities(this, "_objActivitiesGetPromise", sCandidateId, function () {}, // handled in RSVP
            a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this2.set("_bServerError", true);
                    return;
                }
                // Clear data
                _this2.set("_arrActivities", []);
            }), objQueryParams);

            var objServiceCalls = {
                _objRestActivitiesProperty: objPromiseActivities
            };

            var arrRequiredResponses = ["_objRestActivitiesProperty"];

            // load activity types if not loaded
            if (a24Core.isEmpty(this.get("_arrActivityTypesContext"))) {
                var objActivityTypesContextQueryParams = {
                    disabled: { mValue: false },
                    context: { mValue: this.get("sActivityContext") },
                    subject_type: { mValue: this.get("_objSSCoreEnumsService.activitySubjectTypes.CANDIDATE.backend") },
                    name: { mValue: "asc", bSortBy: true },
                    page: { mValue: 1 },
                    items_per_page: { mValue: 1000 }
                };

                var objPromiseActivityTypesContext = this.get("objActivityTypeRestService").getActivityTypes(this, "_objActivityTypesContextGetPromise", function () {}, // handled in RSVP
                a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                        _this2.set("_bServerError", true);
                        return;
                    }
                    // Clear data
                    _this2.set("_arrActivityTypesContext", []);
                }), objActivityTypesContextQueryParams);

                objServiceCalls._objRestActivityTypesContextProperty = objPromiseActivityTypesContext;
                arrRequiredResponses.push("_objRestActivityTypesContextProperty");
            }

            if (a24Core.isEmpty(this.get("_arrActivityTypes"))) {
                var objActivityTypesQueryParams = {
                    subject_type: { mValue: this.get("_objSSCoreEnumsService.activitySubjectTypes.CANDIDATE.backend") },
                    name: { mValue: "asc", bSortBy: true },
                    page: { mValue: 1 },
                    items_per_page: { mValue: 1000 }
                };

                var objPromiseActivityTypes = this.get("objActivityTypeRestService").getActivityTypes(this, "_objActivityTypesGetPromise", function () {}, // handled in RSVP
                a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                        _this2.set("_bServerError", true);
                        return;
                    }
                    // Clear data
                    _this2.set("_arrActivityTypes", []);
                }), objActivityTypesQueryParams);

                objServiceCalls._objRestActivityTypesProperty = objPromiseActivityTypes;
                arrRequiredResponses.push("_objRestActivityTypesProperty");
            }

            Ember.copy(a24RSVP).configure(this, objServiceCalls, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    if (!a24Core.isEmpty(objResponses._objRestActivityTypesContextProperty) && !a24Core.isEmpty(objResponses._objRestActivityTypesContextProperty.objHeaders) && objResponses._objRestActivityTypesContextProperty.objHeaders.statusText === "abort" || !a24Core.isEmpty(objResponses._objRestActivityTypesProperty) && !a24Core.isEmpty(objResponses._objRestActivityTypesProperty.objHeaders) && objResponses._objRestActivityTypesProperty.objHeaders.statusText === "abort" || !a24Core.isEmpty(objResponses._objRestActivitiesProperty) && !a24Core.isEmpty(objResponses._objRestActivitiesProperty.objHeaders) && objResponses._objRestActivitiesProperty.objHeaders.statusText === "abort") {
                        return;
                    }

                    if (!a24Core.isEmpty(objResponses._objRestActivityTypesProperty) && !a24Core.isEmpty(objResponses._objRestActivityTypesProperty.objResponse)) {
                        var arrActivityTypes = objResponses._objRestActivityTypesProperty.objResponse;
                        _this2.set("_arrActivityTypes", arrActivityTypes);

                        var arrActivityTypeDropdown = [];
                        for (var k = 0; k < arrActivityTypes.length; k++) {
                            arrActivityTypeDropdown.push({
                                sTitle: arrActivityTypes[k].name,
                                sValue: arrActivityTypes[k].code
                            });
                        }

                        _this2.set("_arrFilterConfig", [{
                            sTitle: _this2.get("_objStringsService").getString("createdBy"),
                            sProperty: "created_by_user_name",
                            sFilterType: "text",
                            bHideEmptyCheck: true,
                            bText: true
                        }, {
                            sTitle: _this2.get("_objCandidateProfileStringsService").getString("activityType"),
                            sProperty: "activity_type",
                            sFilterType: "dropdownMulti",
                            arrMappingData: arrActivityTypeDropdown,
                            bHideEmptyCheck: true,
                            bMapping: true
                        }, {
                            sTitle: _this2.get("_objCandidateProfileStringsService").getString("activityDate"),
                            sProperty: "activity_date_at",
                            sFilterType: "date",
                            bHideEmptyCheck: true,
                            bDate: true
                        }]);
                    }

                    if (!a24Core.isEmpty(objResponses._objRestActivityTypesContextProperty) && !a24Core.isEmpty(objResponses._objRestActivityTypesContextProperty.objResponse)) {
                        _this2.set("_arrActivityTypesContext", objResponses._objRestActivityTypesContextProperty.objResponse);

                        var arrActivityTypeContextDropdown = [];
                        var mapActivityTypeDescription = new Map();
                        var arrActivityTypesContext = objResponses._objRestActivityTypesContextProperty.objResponse;
                        for (var l = 0; l < arrActivityTypesContext.length; l++) {
                            mapActivityTypeDescription.set(arrActivityTypesContext[l].code, arrActivityTypesContext[l].description);
                            arrActivityTypeContextDropdown.push({
                                sTitle: arrActivityTypesContext[l].name,
                                sValue: arrActivityTypesContext[l].code
                            });
                        }
                        _this2.set("_mapActivityTypeDescription", mapActivityTypeDescription);
                        _this2.set("_arrActivityTypeAddEditDropdown", arrActivityTypeContextDropdown);
                    }

                    if (!a24Core.isEmpty(objResponses._objRestActivitiesProperty) && !a24Core.isEmpty(objResponses._objRestActivitiesProperty.objResponse) && !a24Core.isEmpty(_this2.get("_arrActivityTypes"))) {
                        _this2.set("_arrActivities", _this2.prepareForDisplay(objResponses._objRestActivitiesProperty.objResponse));

                        _this2.set("_iResultCount", a24RestCallHelper.getXResultCount(objResponses._objRestActivitiesProperty.objHeaders));

                        _this2.set("_bLoading", false);
                        if (!a24Core.isEmpty(_this2.get("onDoneLoading"))) {
                            _this2.get("onDoneLoading")();
                        }
                    } else {
                        _this2.set("_arrActivities", []);
                        _this2.set("_bLoading", false);
                        if (!a24Core.isEmpty(_this2.get("onDoneLoading"))) {
                            _this2.get("onDoneLoading")();
                        }
                    }
                }
            }]);
        },
        prepareForDisplay: function prepareForDisplay(arrData) {
            var _this3 = this;

            var arrActivities = [];
            var objThis = this;

            arrData.forEach(function (objActivity) {
                // lookup icon and label for activity type
                var objLookupData = null;
                var arrActivityTypes = _this3.get("_arrActivityTypes");
                for (var l = 0; l < arrActivityTypes.length; l++) {
                    if (arrActivityTypes[l].code === objActivity.activity_type) {
                        objLookupData = arrActivityTypes[l];
                        break;
                    }
                }

                var sSeenDateFormatted = momentHelper.getTimeFromNow(objActivity.activity_date, a24Constants.DATE_FORMAT_REST_FORMAT);
                var objActivityDateTime = a24DateManager.createDate(objThis.get("_objUserSession").get("objContextDetails.sTimezone"));
                objActivityDateTime.setDateFromRest(objActivity.activity_date);

                var arrData = [{
                    arrItems: [{
                        sLabel: _this3.get("_objStringsService").getString("description"),
                        sValue: objActivity.description,
                        bText: true
                    }, {
                        sLabel: _this3.get("_objStringsService").getString("comment"),
                        sValue: objActivity.comment,
                        bText: true
                    }, {
                        sLabel: _this3.get("_objStringsService").getString("createdBy"),
                        sValue: objActivity.created_by_user_name,
                        bText: true
                    }, {
                        sLabel: _this3.get("_objCandidateProfileStringsService").getString("activityDate"),
                        sValue: objActivityDateTime.getDateTimeFormat(),
                        bText: true
                    }]
                }];

                var sIcon = null;
                var sName = _this3.get("_objCandidateProfileStringsService").getString("unknown");

                if (!a24Core.isEmpty(objLookupData)) {
                    sIcon = objLookupData.icon;
                    sName = objLookupData.name;
                }

                // Add an actvity entry
                var objExpandingActivity = {
                    sIcon: sIcon,
                    sLabel: _this3.get("_objStringsService").getHTMLTokenString("a24-string-html-dot-value", sName, objActivity.created_by_user_name),
                    sDate: sSeenDateFormatted,
                    arrData: arrData
                };

                if (!a24Core.isEmpty(objThis.get("objActivityRestService.sContext")) && (objThis.get("objActivityRestService.sContext") === objThis.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP") || objThis.get("objActivityRestService.sContext") === objThis.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY"))) {
                    // Check the linked entities for an EmailCommunicationMessage
                    if (!a24Core.isEmpty(objActivity.linked_entities)) {
                        var _loop = function _loop(i) {
                            var objLinkedEntity = objActivity.linked_entities[i];
                            objExpandingActivity.arrActions = [];
                            if (objLinkedEntity.entity_type === "EmailCommunicationMessage") {
                                // EmailCommunicationMessage found, adding action
                                objExpandingActivity.arrActions.push({
                                    sTitle: _this3.get("_objCandidateProfileStringsService").getString("emailPreview"),
                                    sIcon: "attachment",
                                    executeCardAction: function executeCardAction() {
                                        _this3.set("_objEmailActivity", {
                                            sSubjectId: _this3.get("sCandidateId"),
                                            sLinkedEntityId: objLinkedEntity.entity_id,
                                            sActivityId: objActivity._id,
                                            sSubjectType: "candidate"
                                        });
                                        _this3.set("_bShowEmailMessagePopup", true);
                                    }
                                });
                                return "break";
                            } else if (objLinkedEntity.entity_type === "ChatConversation") {
                                objExpandingActivity.arrActions.push({
                                    sTitle: _this3.get("_objCoreStrings").getString("conversationPreview"),
                                    sIcon: "open_in_browser",
                                    executeCardAction: function executeCardAction() {
                                        _this3.set("_bShowReactPopup", true);
                                        _this3.set("_objReactPopupPostData", {
                                            type: "trigger_route",
                                            data: {
                                                route: "agency/conversation/previewPopup",
                                                data: { conversationId: objLinkedEntity.entity_id }
                                            }
                                        });
                                    }
                                });
                                return "break";
                            } else if (objLinkedEntity.entity_type === "CandidateNotifications") {
                                objExpandingActivity.arrActions.push({
                                    sTitle: _this3.get("_objCoreStrings").getString("notificationPreview"),
                                    sIcon: "open_in_browser",
                                    executeCardAction: function executeCardAction() {
                                        _this3.set("_objNotificationActivity", {
                                            sSubjectId: _this3.get("sCandidateId"),
                                            sLinkedEntityId: objLinkedEntity.entity_id,
                                            sActivityId: objActivity._id,
                                            sSubjectType: "candidate"
                                        });
                                        _this3.set("_bShowNotifcationsSentPopup", true);
                                    }
                                });
                                return "break";
                            } else if (objLinkedEntity.entity_type === "Order" && !a24Core.isEmpty(objLinkedEntity.entity_id) && objThis.get("objActivityRestService.sContext") === objThis.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY")) {
                                var iOrderId = objLinkedEntity.entity_id;
                                objExpandingActivity.arrActions.push({
                                    sTitle: _this3.get("_objCandidateProfileStringsService").getString("openOrderInNewTab"),
                                    sIcon: "open_in_new",
                                    executeCardAction: function executeCardAction() {
                                        objThis._viewOrderInNewWindow(iOrderId);
                                    }
                                });
                                return "break";
                            }
                        };

                        for (var i = 0; i < objActivity.linked_entities.length; i++) {
                            var _ret = _loop(i);

                            if (_ret === "break") break;
                        }
                    }
                }

                arrActivities.push(objExpandingActivity);
            });
            return arrActivities;
        },
        _viewOrderInNewWindow: function _viewOrderInNewWindow(sOrderId) {
            var sUrl = this.get("_sTriageBaseUrl") + "/views#agency-staffing-order-details;orderId=" + sOrderId + ";cId=" + this.get("_objNavigation").get("sContextId");

            this._openLink(sUrl);
        },
        _openLink: function _openLink(sLink) {
            window.open(sLink);
        },
        setAddPopup: function setAddPopup() {
            var _this4 = this;

            this.clearAllErrorMessages();
            this.clearForm();
            this.set("_bShowAddPopup", true);

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this4)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    _this4.set("_objOriginalData", _this4.getPlainFormValueObject());
                });
            });
        },
        hidePopup: function hidePopup() {
            if (this.get("_bPopupLoading")) {
                return;
            }
            // Check if the form has any unsaved data
            var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

            if (bDataMatches) {
                this.set("_bShowAddPopup", false);
            } else {
                this.set("_bShowUnsavedData", true);
            }
        },
        getPayload: function getPayload() {
            // Get the form data
            var objFormData = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());

            return objFormData;
        },
        setErrorResponse: function setErrorResponse(sCode, objError, sSnackbarMessage) {
            if (sCode === "MIN_LENGTH") {
                this.setResponseError(objError.arrPath, "validationRestMinLength");
            } else if (sCode === "ENUM_MISMATCH") {
                this.setResponseError(objError.arrPath, "validationRestUnsupportedValue");
            } else {
                this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            }

            this.set("_bPopupLoading", false);
        },
        setSuccessResponse: function setSuccessResponse(sSnackbarMessage) {
            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            this.set("_bPopupLoading", false);
            this.set("_bShowAddPopup", false);

            this.doServiceCalls();
        },
        onInputValueChanged: function onInputValueChanged(arrChildRef) {
            var sLookup = arrChildRef.join(".");
            if (sLookup === "objChildren.activity_type") {
                if (!a24Core.isEmpty(this.get(sLookup + ".mValue.sValue"))) {
                    var mapActivityTypesDescription = this.get("_mapActivityTypeDescription");
                    var sActivityTypeDescription = mapActivityTypesDescription.get(this.get(sLookup + ".mValue.sValue"));
                    if (!a24Core.isEmpty(sActivityTypeDescription)) {
                        this.set("_sActivityTypeSelected", this.get("_objCandidateProfileStringsService").getTokenString("selectedActivityTypes", sActivityTypeDescription));
                    } else {
                        this.set("_sActivityTypeSelected", this.get("_objCandidateProfileStringsService").getTokenString("selectedActivityTypes", "-"));
                    }
                } else {
                    this.set("_sActivityTypeSelected", this.get("_objCandidateProfileStringsService").getTokenString("selectedActivityTypes", "-"));
                }
            }
        },
        actions: {
            // If the filter changes, we wish to reload the card
            cardDatagridFilterChangeEvent: function cardDatagridFilterChangeEvent(sProperty, objValue) {
                objValue = Ember.copy(objValue, true);
                var objParams = objValue;
                this.set("_iPage", 1);
                objParams.page = { mValue: 1 };
                objParams.items_per_page = { mValue: this.get("_iPerPage") };
                if (!a24Core.isEmpty(objParams.activity_date_at)) {
                    if (objParams.activity_date_at.bNotWithinLast === true) {
                        objParams.activity_date_at_to = {
                            mValue: objParams.activity_date_at.mValue.sFrom,
                            bEncode: true
                        };
                    } else if (objParams.activity_date_at.bNotWithinNext === true) {
                        objParams.activity_date_at_from = {
                            mValue: objParams.activity_date_at.mValue.sTo,
                            bEncode: true
                        };
                    } else {
                        objParams.activity_date_at_from = {
                            mValue: objParams.activity_date_at.mValue.sFrom,
                            bEncode: true
                        };
                        objParams.activity_date_at_to = {
                            mValue: objParams.activity_date_at.mValue.sTo,
                            bEncode: true
                        };
                    }
                    delete objParams.activity_date_at;
                }
                this.doServiceCalls(objParams);
            },
            cardDatagridPagerEvent: function cardDatagridPagerEvent(objPageQuery) {
                objPageQuery = Ember.copy(objPageQuery, true);

                var objQueryParams = this.get("_objQueryParams");
                objQueryParams.page = objPageQuery.page;
                objQueryParams.items_per_page = objPageQuery.per_page;

                this.doServiceCalls(objQueryParams);
            },
            onPopupConfirmationCancel: function onPopupConfirmationCancel() {
                this.hidePopup();
            },
            onPopupConfirmationConfirm: function onPopupConfirmationConfirm() {
                var _this5 = this;

                // When the save button is clicked
                var bHasError = this.doFullValidation();
                if (!bHasError) {
                    this.set("_bPopupLoading", true);

                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objCandidateProfileStringsService").getString("activity")));

                    var objPayload = this.getPayload();
                    // call to save the person activity
                    this.get("objActivityRestService").createActivity(this, "_objActivityPostPromise", this.get("sCandidateId"), objPayload, function () {
                        _this5.setSuccessResponse(_this5.get("_objStringsService").getTokenString("successAddItem", _this5.get("_objCandidateProfileStringsService").getString("activity")));
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                        _this5.setErrorResponse(sCode, objError, _this5.get("_objStringsService").getTokenString("failAddItem", _this5.get("_objCandidateProfileStringsService").getString("activity")));
                    }));
                }
            },
            onInputValueChangedAction: function onInputValueChangedAction(arrPath) {
                this.onInputValueChanged(arrPath);
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowUnsavedData", false);
                this.set("_bShowAddPopup", false);
            },
            // If the custom action is clicked
            customActionClicked: function customActionClicked(objItem) {
                objItem.executeCardAction();
            },

            onEmailMessagePopupClose: function onEmailMessagePopupClose() {
                this.set("_bShowEmailMessagePopup", false);
            },
            onNotificationSentPopupClose: function onNotificationSentPopupClose() {
                this.set("_bShowNotifcationsSentPopup", false);
            },
            onRecievePopupData: function onRecievePopupData(objData) {
                if (objData.type === "app_ready") {
                    this.set("_bReactPopupLoading", false);
                } else if (objData.type === "refresh") {
                    // for if we need to do refresh
                } else if (objData.type === "close") {
                    this.set("_bShowReactPopup", false);
                }
            }
        }
    });
});