define("a24-calendar/components/calendar-day", ["exports", "a24-calendar/templates/components/calendar-day", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _calendarDay, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        /*
          Default values.
        */
        layout: _calendarDay.default,
        classNames: ["calendar-day"],
        classNameBindings: ["objDay.bIsToday:today", "objDay.bIsNextMonthDay:next", "objDay.bIsThisMonthDay:current", "objDay.bIsPrevMonthDay:previous"],

        /*
          @property objDay
          The day object this component represents
        */
        objDay: null,

        /*
          Events
        */
        click: function click() {
            if (!a24Core.isEmpty(this.get("onDayAction"))) {
                this.get("onDayAction")("click", Ember.get(this, "objDay"));
            }
        }
    });
});