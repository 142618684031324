define("a24-ember-staffshift-candidate-availability/components/booked-shift-card-agency", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-candidate-availability/templates/components/booked-shift-card-agency", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _bookedShiftCardAgency, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _bookedShiftCardAgency.default,

        objData: null,
        arrEntityActions: null,
        objEmailRestService: null,

        _ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),
        bHasMobileNumber: false,
        objStaffingOrderData: null,
        _objShiftTimesheetEmail: Ember.inject.service("shift-email"),
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objConstantsSSCore: Ember.inject.service("a24-constants-staffshift-core"),
        _objEnumsService: Ember.inject.service("a24-enums"),
        _objSSCoreEnums: Ember.inject.service("a24-enums-staffshift-core"),
        _objSSCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objStrings: Ember.inject.service("a24-strings"),
        _objAvailabilityStrings: Ember.inject.service("a24-strings-staffshift-availability"),
        _objFormInputStrings: Ember.inject.service("a24-strings-form-inputs"),
        _objStaffingOrderService: Ember.inject.service("rest-agency-staffing-order"),
        _arrHeaderActions: null,
        _sShiftDetails: null,
        _bShiftConfirmation: false,
        _sCandidateId: null,
        _sShiftId: null,
        _sOrderId: null,
        _sAgencyId: null,
        _bOrderDataLoading: true,
        _arrOrderCache: null,
        _sReportTo: null,
        _bShowSendTimesheet: false,
        _bSendTimesheetLoading: false,
        _objEmailGetPromise: null,
        _objEmailTimesheetServicePromise: null,
        _objEmail: null,
        _bEmailGetDone: false,
        _sSpacer: "| ",
        _sHeader: Ember.computed("objData.{start_date,end_date}", function () {
            if (a24Core.isEmpty(this.get("objData.start_date")) || a24Core.isEmpty(this.get("objData.end_date"))) {
                return "-";
            }
            var objDateInstance = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            objDateInstance.setDateFromRest(this.get("objData.end_date"));
            var sEndTime = objDateInstance.getTimeFormat();
            objDateInstance.setDateFromRest(this.get("objData.start_date"));
            var sStartTime = objDateInstance.getTimeFormat();

            //TODO cater for overlap to next day

            return new Ember.String.htmlSafe("<div class= 'booked-shift-card-agency-float-text'>" + "<b>" + sStartTime + " - " + sEndTime + "&nbsp;</b>" + "</div>" + "<div class='booked-shift-card-agency-float-text'>" + "| " + objDateInstance.getCustomFormat(this.get("_objLibConstants.DAY_DATE_FORMAT")) + "</div>");
        }).readOnly(),

        _sFirstLetter: Ember.computed("objData.site_name", function () {
            return this.get("objData.site_name") ? this.get("objData.site_name")[0].toUpperCase() : "?";
        }).readOnly(),
        _sAvatar: Ember.computed("objData.organisation_avatar", function () {
            return a24.getUserContextAvatarUrl(this.get("_ENV.sTriageBaseUrl"), this.get("_objConstantsSSCore"), this.get("objData.organisation_avatar"));
        }).readOnly(),
        _sSiteName: Ember.computed("objData.site_name", function () {
            return this._checkNull(this.get("objData.site_name"));
        }).readOnly(),
        _sSiteLink: Ember.computed("objData.site_id", function () {
            var sLink = this.get("_objNavigation").navigateWithinContext("agency-client-site-view", {
                "agency-client-site-view": [this.get("objData.site_id")]
            }, null, true);

            return sLink;
        }).readOnly(),
        _sWardName: Ember.computed("objData.ward_name", function () {
            if (a24Core.isEmpty(this.get("objData.ward_name"))) {
                return "";
            } else {
                return this.get("objData.ward_name");
            }
        }).readOnly(),
        _sWardLink: Ember.computed("objData.ward_id", function () {
            var sLink = this.get("_objNavigation").navigateWithinContext("agency-client-ward-view", {
                "agency-client-ward-view": [this.get("objData.ward_id")]
            }, null, true);
            return sLink;
        }).readOnly(),
        _sOrgName: Ember.computed("objData.organisation_name", function () {
            return this._checkNull(this.get("objData.organisation_name"));
        }).readOnly(),
        _sOrderRef: Ember.computed("objData.order_reference", function () {
            if (a24Core.isEmpty(this.get("objData.order_reference"))) {
                return "####";
            } else {
                return "#" + this.get("objData.order_reference");
            }
        }).readOnly(),
        _sOrderRefLink: Ember.computed("objData.order_id", function () {
            return this.get("_ENV.sTriageBaseUrl") + "/views#agency-staffing-order-details;cId=" + this.get("objData.agency_id") + ";orderId=" + this.get("objData.order_id");
        }).readOnly(),
        _sJobFamily: Ember.computed("objData.job_family_name", function () {
            return this._checkNull(this.get("objData.job_family_name"));
        }).readOnly(),
        _sJobTitle: Ember.computed("objData.job_title_name", function () {
            return this._checkNull(this.get("objData.job_title_name"));
        }).readOnly(),
        _sPayCurrency: Ember.computed("objData.contract_currency", function () {
            return this._checkNull(this.get("objData.contract_currency"));
        }).readOnly(),
        _sPay: Ember.computed("objData.contract_currency", function () {
            var sPayRate = "";
            var arrContract = this.get("objData.contract_breakdown");
            var arrPayRates = [];
            if (!a24Core.isEmpty(arrContract)) {
                for (var i = 0; i < arrContract.length; i++) {
                    arrPayRates.push(arrContract[i].pay_rate);
                }
                arrPayRates.sort(function (a, b) {
                    return a - b;
                });

                var sFirstRate = arrPayRates[0].toFixed(2);
                var sLastRate = arrPayRates[arrPayRates.length - 1].toFixed(2);
                var sUnit = this.get("objData.contract_unit") === "hourly" ? this.get("_objAvailabilityStrings").getString("perhour").toLowerCase() : "";

                sPayRate += sFirstRate;

                if (sFirstRate !== sLastRate) {
                    sPayRate += " - " + sLastRate;
                }
                sPayRate += " " + sUnit;

                return sPayRate;
            }

            return "-";
        }).readOnly(),
        _sDirectionsText: Ember.computed("objData.{candidate_geo_location,candidate_address_line1,site_geo_location,address_line1}", function () {
            if (a24Core.isEmpty(this.get("objData.candidate_geo_location")) && a24Core.isEmpty(this.get("objData.candidate_address_line1")) || a24Core.isEmpty(this.get("objData.site_geo_location")) && a24Core.isEmpty(this.get("objData.address_line1"))) {
                return "-";
            } else {
                return this.get("_objAvailabilityStrings").getString("directions");
            }
        }).readOnly(),
        _sDirectionsLink: Ember.computed("objData.{candidate_geo_location,candidate_address_line1,site_geo_location,address_line1}", function () {
            if (a24Core.isEmpty(this.get("objData.candidate_geo_location")) && a24Core.isEmpty(this.get("objData.candidate_address_line1")) || a24Core.isEmpty(this.get("objData.site_geo_location")) && a24Core.isEmpty(this.get("objData.address_line1"))) {
                return null;
            } else {
                return "https://www.google.com/maps/dir/?api=1" + "&origin=" + encodeURIComponent(this._getCandidateAddressString()) + "&destination=" + encodeURIComponent(this._getSiteAddressString());
            }
        }).readOnly(),
        _sDirectionsPostCode: Ember.computed("objData.{city,post_code}", function () {
            if (!a24Core.isEmpty(this.get("objData.city")) && !a24Core.isEmpty(this.get("objData.post_code"))) {
                return this.get("objData.city") + ", " + this.get("objData.post_code");
            } else if (!a24Core.isEmpty(this.get("objData.city"))) {
                return this.get("objData.city");
            } else if (!a24Core.isEmpty(this.get("objData.post_code"))) {
                return this.get("objData.post_code");
            } else {
                return "-";
            }
        }).readOnly(),
        _sDescription: Ember.computed("objData.position_description", function () {
            return this._checkNull(this.get("objData.position_description"));
        }).readOnly(),
        _sInstructions: Ember.computed("objData.staffing_instructions", function () {
            return this._checkNull(this.get("objData.staffing_instructions"));
        }).readOnly(),
        _sSiteAddress: Ember.computed("objData.{site_geo_location,address_line1}", function () {
            if (a24Core.isEmpty(this.get("objData.address_line1"))) {
                return "-";
            } else {
                return a24Core.getSafeStringLink(Ember, this._getSiteAddressString(true), this._getSiteAddressString(), "address");
            }
        }).readOnly(),
        _sShiftRef: Ember.computed("objData.shift_reference_number", function () {
            return this._checkNull(this.get("objData.shift_reference_number"));
        }).readOnly(),
        _bHasContract: Ember.computed("objData.contract_breakdown", function () {
            return !a24Core.isEmpty(this.get("objData.contract_breakdown"));
        }).readOnly(),
        _sCurrency: Ember.computed("objData.contract_currency", function () {
            if (!a24Core.isEmpty(this.get("objData.contract_currency"))) {
                return this.get("objData.contract_currency");
            }
            return "";
        }).readOnly(),
        _arrContractBreakdown: Ember.computed("objData.contract_breakdown", function () {
            var arrContract = this.get("objData.contract_breakdown");
            var arrContractBreakdown = [];
            if (!a24Core.isEmpty(arrContract)) {
                // loop over contact data
                for (var i = 0; i < arrContract.length; i++) {
                    var objContractBreakdownEntry = {};
                    var objDateInstance = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    objDateInstance.setDateFromRest(arrContract[i].start_date);
                    objContractBreakdownEntry.sStartDate = objDateInstance.getDateTimeFormat();
                    var objEndDateInstance = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    objEndDateInstance.setDateFromRest(arrContract[i].end_date);
                    objContractBreakdownEntry.sEndDate = objEndDateInstance.getDateTimeFormat();
                    var sTime = momentHelper.formatTimeDuration(arrContract[i].duration_minutes);
                    var sTimeHtml = Ember.String.htmlSafe("<span title=\"" + sTime + "\">" + sTime + "</span>");
                    objContractBreakdownEntry.sTotal = sTimeHtml;
                    objContractBreakdownEntry.sPay = arrContract[i].pay_rate;
                    objContractBreakdownEntry.sTotalPay = arrContract[i].total_line_pay;
                    arrContractBreakdown.push(objContractBreakdownEntry);
                }
            }
            return arrContractBreakdown;
        }).readOnly(),
        _sContractPay: Ember.computed("objData.contract_pay", function () {
            return this._checkNull(this.get("objData.contract_pay"));
        }).readOnly(),
        _sContractTotalPayLabel: Ember.computed("objData.contract_pay", function () {
            return this.get("_objAvailabilityStrings").getString("totalTotalPayAmount").toUpperCase();
        }).readOnly(),
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            var objValidationConfig = {};

            objValidationConfig["objChildren.email"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };

            this.set("_objSpecialSendTimesheetPopupAction", {
                sTitle: this.get("_objStrings").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onPopupSendTimesheetCancel");
                }
            });

            this.set("objChildConfig", objValidationConfig);

            // this.set("objData", {
            //     "_id": "5ebd4c6c37a3f159770aa674",
            //     "county": "Greater Manchester",
            //     "city": "Manchester",
            //     "site_geo_location": {
            //         "coordinates": [
            //             -2.18212,
            //             53.4877
            //         ],
            //         "type": "Point"
            //     },
            //     "address_line1": "4 Rushbrooke Avenue",
            //     "country_code": "GB",
            //     "post_code": "M11 4JW",
            //     "agency_id": "5b16b824e8a73a752c42d848",
            //     "shift_id": "5ebd4c5fe8a73a71d96caf72",
            //     "shift_reference_number": "34534ffgfgxcw43",
            //     "order_id": "5ebd4c60e8a73a71d96caf74",
            //     "contract_id": "5df0be18e8a73a04940e0372",
            //     "contract_name": "Black Contract New New",
            //     "contract_version": 8,
            //     "contract_link": "5df0bfcae8a73a0845603aa2",
            //     "contract_vat_applicable": true,
            //     "contract_unit": "hourly",
            //     "contract_initial_start_date": "1969-12-31T22:00:00.000Z",
            //     "contract_final_end_date": "2020-05-30T17:52:00.000Z",
            //     "contract_currency": "$",
            //     "start_date": "2020-05-22T08:00:00+02:00",
            //     "end_date": "2020-05-22T14:00:00+02:00",
            //     "status_code": "Accepted",
            //     "date_created": "2020-05-14T15:49:20+02:00",
            //     "job_title_id": "51fbba14cebdc3176a000001",
            //     "job_title_name": "Band 2 Care Assistant - Community",
            //     "job_family_id": "51fbba14cebdc3176a000000",
            //     "job_family_name": "Adults & Community - Care",
            //     "order_reference": "HE14",
            //     "po_number": "PO 1",
            //     "organisation_id": "5b16b6b1e8a73a176211c1b2",
            //     "organisation_name": "Black Org",
            //     "site_id": "5b16b719e8a73a63f0031ff2",
            //     "site_name": "Black Site 1",
            //     "ward_name": "Black Ward Ex",
            //     "staffing_instructions": "Please arrive in your work clothes, bring your own lunch. \n Please arrive in your work clothes, bring your own lunch.",
            //     "position_description": "Need to assist with all the assistant tasks.",
            //     "candidate_id": "5ebd414be8a73a2af26f2bb2",
            //     "contract_breakdown": [
            //         {
            //             "start_date": "2020-05-22T06:00:00.000Z",
            //             "end_date": "2020-05-22T12:00:00.000Z",
            //             "duration_minutes": 360,
            //             "contract_job_code": "ILHA",
            //             "pay_rate": 31.95,
            //             "charge_rate": 46.04,
            //             "total_line_pay": 191.7,
            //             "total_line_charge": 276.24,
            //             "total_line_vat": 41.44
            //         }
            //     ],
            //     "contract_charge": 276.24,
            //     "contract_pay": 191.7,
            //     "contract_vat": 41.44,
            //     "contract_charge_incl_vat": 317.68,
            //     "candidate_full_name": "fandi man",
            //     "created_at": "2020-05-14T13:49:32.168Z",
            //     "updated_at": "2020-05-14T13:49:58.790Z",
            //     "__v": 2,
            //     "candidate_address_line1" : "12A Lowman Rd",
            //     "candidate_address_line2" : "Holloway",
            //     "candidate_city" : "london",
            //     "candidate_country_code" : "GB",
            //     "candidate_post_code" : "N7 6DD",
            //     "candidate_post_code_low" : "n7 6dd",
            //     "candidate_province" : "Greater London",
            //     "candidate_geo_location" : {
            //         // "coordinates" : [
            //         //     -0.113922,
            //         //     51.555467
            //         // ],
            //         "type" : "Point"
            //     }
            // });
            this.onDataChanged();
        },

        _openSendTimesheetPopup: function _openSendTimesheetPopup() {
            var _this2 = this;

            this.clearAllErrorMessages();
            this.clearForm();

            this.set("_sShiftId", this.get("objData.shift_id"));
            if (this.get("_bEmailGetDone")) {
                if (!a24Core.isEmpty(this.get("_objEmail"))) {
                    this.get("objChildren.email").setValueFromOutside(this.get("_objEmail").email);
                }
                this.set("_bSendTimesheetLoading", false);
            } else {
                this.set("_bSendTimesheetLoading", true);

                this.get("objEmailRestService").getEmails(this, "_objEmailGetPromise", this.get("objData.candidate_id"), function (arrData) {
                    _this2.set("_bEmailGetDone", true);
                    if (!a24Core.isEmpty(arrData)) {
                        var objEmail = arrData.find(function (objEmail) {
                            if (objEmail.status === "primary") {
                                return true;
                            } else {
                                return false;
                            }
                        });

                        if (!a24Core.isEmpty(objEmail)) {
                            _this2.get("objChildren.email").setValueFromOutside(objEmail.email);
                            _this2.set("_objEmail", objEmail);
                        }
                    } else {
                        _this2.set("_objEmail", null);
                    }

                    _this2.set("_bSendTimesheetLoading", false);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    // Just setting the input to empty as it is no use throwing a error just dont populate the input
                    _this2.set("_bEmailGetDone", true);
                    _this2.set("_objEmail", null);
                    _this2.set("_bSendTimesheetLoading", false);
                }));
            }

            this.set("_bShowSendTimesheet", true);
        },

        openLink: function openLink(sLink) {
            window.open(sLink);
        },

        onDataChanged: Ember.observer("objData.order_id", function () {
            var _this3 = this;

            if (!a24Core.isEmpty(this.get("objData"))) {
                var arrHeaderActions = [{
                    sTitle: this.get("_objAvailabilityStrings").getString("openShiftInNewTab"),
                    sIcon: "open_in_new",
                    bLightWaves: true,
                    executeCardAction: function executeCardAction() {
                        var sLink = _this3.get("_objNavigation").navigateWithinContext("agency-search-shift", {}, {
                            shift_id: _this3.get("objData.shift_id"),
                            order_id: _this3.get("objData.order_id")
                        }, true);

                        _this3.openLink(sLink);
                    }
                }];
                if (this.get("bHasMobileNumber")) {
                    var objDateCurrent = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                    var objEndDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    objEndDate.setDateFromRest(this.get("objData.end_date"));

                    if (objEndDate.difference(objDateCurrent, a24Constants.MOMENT_TIME_SECONDS) > 0 && this.get("objData.status_code") !== this.get("_objSSCoreEnums.assignmentStatus.CLOSED.backend")) {
                        arrHeaderActions.push({
                            sTitle: this.get("_objAvailabilityStrings").getString("sendShiftConfirmation"),
                            sIcon: "chat_bubble",
                            bLightWaves: true,
                            executeCardAction: function executeCardAction() {
                                var objDateInstance = a24DateManager.createDate(_this3.get("_objUserSession.objContextDetails.sTimezone"));
                                objDateInstance.setDateFromRest(_this3.get("objData.end_date"));
                                var sEndTime = objDateInstance.getTimeFormat();
                                objDateInstance.setDateFromRest(_this3.get("objData.start_date"));
                                var sStartTime = objDateInstance.getTimeFormat();

                                //TODO cater for overlap to next day
                                var sShiftDateTime = objDateInstance.getDateFormat() + ", " + sStartTime + "-" + sEndTime + ", ";
                                var sSiteWard = _this3.get("objData.site_name");
                                if (_this3.get("objData.ward_name")) {
                                    sSiteWard += " - " + _this3.get("objData.ward_name");
                                }
                                sSiteWard += ", ";

                                var sAddress = "";
                                if (!a24Core.isEmpty(_this3.get("objData.address_line1"))) {
                                    sAddress += _this3.get("objData.address_line1");
                                }
                                if (!a24Core.isEmpty(_this3.get("objData.city"))) {
                                    sAddress += ", " + _this3.get("objData.city");
                                }
                                if (!a24Core.isEmpty(_this3.get("objData.post_code"))) {
                                    sAddress += ", " + _this3.get("objData.post_code");
                                }

                                _this3.set("_sShiftDetails", sShiftDateTime + sSiteWard + sAddress);
                                _this3.set("_bShiftConfirmation", true);
                                _this3.set("_sCandidateId", _this3.get("objData.candidate_id"));
                                _this3.set("_sShiftId", _this3.get("objData.shift_id"));
                                _this3.set("_sOrderId", _this3.get("objData.order_id"));
                                _this3.set("_sAgencyId", _this3.get("objData.agency_id"));
                                _this3.set("_sAssignmentId", _this3.get("objData.assignment_id"));
                            }
                        });
                    }
                }
                arrHeaderActions.push({
                    sTitle: this.get("_objAvailabilityStrings").getString("sendTimesheet"),
                    sIcon: "next_week",
                    bOutlinedIcon: true,
                    bLightWaves: true,
                    executeCardAction: function executeCardAction() {
                        _this3._openSendTimesheetPopup();
                    }
                });

                arrHeaderActions.push({
                    bLoader: true,
                    sTitle: this.get("_objAvailabilityStrings").getString("cancelShift"),
                    sIcon: "not_interested",
                    bLightWaves: true,
                    executeCardAction: function executeCardAction() {
                        _this3._cancelShifts([_this3.get("objData")]);
                    }
                });
                this.set("_arrHeaderActions", arrHeaderActions);
            }
        }),
        onEntityActions: Ember.observer("arrEntityActions", function () {
            var arrHeaderActions = Ember.copy(this.get("_arrHeaderActions"), true);
            var arrEntityActions = this.get("arrEntityActions");
            // get the header action for the cancel shift to switch loader out or remove
            var objHeaderAction = arrHeaderActions.find(function (objHeaderAction) {
                return objHeaderAction.sIcon === "not_interested";
            });
            var objBooking = this.get("objData");

            if (!a24Core.isEmpty(arrEntityActions)) {
                var sAssignmentAccepted = this.get("_objSSCoreEnums").getBackendValue("assignmentStatus", this.get("_objSSCoreStrings").getString("accepted"));

                var objEntityAction = arrEntityActions.find(function (objEntityAction) {
                    var objCancelAction = objEntityAction.actions.find(function (objAction) {
                        return objAction.action === "Cancel";
                    });

                    if (!a24Core.isEmpty(objCancelAction) && objEntityAction.entity_id === objBooking.shift_id && objBooking.status_code === sAssignmentAccepted && objCancelAction.reason_code === "STATUS_DISALLOWS_ACTION" && !objCancelAction.allowed) {
                        return true;
                    }
                    return false;
                });

                if (!a24Core.isEmpty(objHeaderAction) && !a24Core.isEmpty(objEntityAction)) {
                    objHeaderAction.bLoader = false;
                } else {
                    arrHeaderActions.removeObject(objHeaderAction);
                }
            } else {
                arrHeaderActions.removeObject(objHeaderAction);
            }

            this.set("_arrHeaderActions", arrHeaderActions);
        }),
        _cancelShifts: function _cancelShifts(arrShifts) {
            this.get("onCancelShiftAction")(arrShifts);
        },
        _checkNull: function _checkNull(mValue, bReturnNull) {
            if (a24Core.isEmpty(mValue)) {
                if (bReturnNull) {
                    return null;
                } else {
                    return "-";
                }
            } else {
                return mValue;
            }
        },
        _getCandidateAddressString: function _getCandidateAddressString() {
            var sAddressLink = "";
            var sCountryCode = "";

            if (this.get("objData.candidate_country_code") === "UK") {
                sCountryCode = "GB";
            } else {
                sCountryCode = this.get("objData.candidate_country_code");
            }
            var objCountry = this.get("_objEnumsService").getList("country").findBy("sValue", sCountryCode);

            if (!a24Core.isEmpty(this.get("objData.candidate_geo_location")) && !a24Core.isEmpty(this.get("objData.candidate_geo_location.coordinates"))) {
                sAddressLink = this.get("objData.candidate_geo_location.coordinates").slice().reverse().join(",");
            } else {
                if (!a24Core.isEmpty(this.get("objData.candidate_address_line1"))) {
                    sAddressLink = this.get("objData.candidate_address_line1");
                }
                if (!a24Core.isEmpty(this.get("objData.candidate_address_line2"))) {
                    sAddressLink = sAddressLink + ", " + this.get("objData.candidate_address_line2");
                }
                if (!a24Core.isEmpty(this.get("objData.candidate_city"))) {
                    sAddressLink = sAddressLink + ", " + this.get("objData.candidate_city");
                }
                if (!a24Core.isEmpty(this.get("objData.candidate_province"))) {
                    sAddressLink = sAddressLink + ", " + this.get("objData.candidate_province");
                }
                if (!a24Core.isEmpty(this.get("objData.candidate_country_code"))) {
                    sAddressLink = sAddressLink + ", " + objCountry.sLabel;
                }
                if (!a24Core.isEmpty(this.get("objData.candidate_post_code"))) {
                    sAddressLink = sAddressLink + ", " + this.get("objData.candidate_post_code");
                }
            }

            return sAddressLink;
        },
        _getSiteAddressString: function _getSiteAddressString(bNoCoords) {
            var sAddressLink = "";
            var sCountryCode = "";

            if (this.get("objData.country_code") === "UK") {
                sCountryCode = "GB";
            } else {
                sCountryCode = this.get("objData.country_code");
            }
            var objCountry = this.get("_objEnumsService").getList("country").findBy("sValue", sCountryCode);

            if (!bNoCoords && !a24Core.isEmpty(this.get("objData.site_geo_location")) && !a24Core.isEmpty(this.get("objData.site_geo_location.coordinates"))) {
                sAddressLink = this.get("objData.site_geo_location.coordinates").slice().reverse().join(",");
            } else {
                if (!a24Core.isEmpty(this.get("objData.address_line1"))) {
                    sAddressLink = this.get("objData.address_line1");
                }
                if (!a24Core.isEmpty(this.get("objData.address_line2"))) {
                    sAddressLink = sAddressLink + ", " + this.get("objData.address_line2");
                }
                if (!a24Core.isEmpty(this.get("objData.city"))) {
                    sAddressLink = sAddressLink + ", " + this.get("objData.city");
                }
                if (!a24Core.isEmpty(this.get("objData.county"))) {
                    sAddressLink = sAddressLink + ", " + this.get("objData.county");
                }
                if (!a24Core.isEmpty(this.get("objData.country_code"))) {
                    sAddressLink = sAddressLink + ", " + objCountry.sLabel;
                }
                if (!a24Core.isEmpty(this.get("objData.post_code"))) {
                    sAddressLink = sAddressLink + ", " + this.get("objData.post_code");
                }
            }

            return sAddressLink;
        },
        actions: {
            onPopupCancel: function onPopupCancel() {
                this.set("_bShiftConfirmation", false);
            },
            onCollapseExpandStart: function onCollapseExpandStart() {
                var _this4 = this;

                if (a24Core.isEmpty(this.get("_sReportTo"))) {
                    this.get("_objStaffingOrderService").getOrder(this, "objStaffingOrderData", this.get("objData.order_id"), function (arrData) {
                        if (!a24Core.isEmpty(arrData.sReportsToName)) {
                            // set report to for order
                            _this4.set("_sReportTo", arrData.sReportsToName);
                        } else {
                            _this4.set("_sReportTo", "-");
                        }

                        _this4.set("_bOrderDataLoading", false);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () {
                        _this4.set("_sReportTo", "-");

                        _this4.set("_bOrderDataLoading", false);
                    }));
                }
            },
            onPopupSendTimesheetCancel: function onPopupSendTimesheetCancel() {
                if (this.get("_bSendTimesheetLoading")) {
                    return;
                }
                this.set("_bShowSendTimesheet", false);
            },
            onPopupSendTimesheetConfirm: function onPopupSendTimesheetConfirm() {
                var _this5 = this;

                if (!this.doFullValidation()) {
                    var objPayload = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());
                    this.set("_bSendTimesheetLoading", true);
                    this.get("_objShiftTimesheetEmail").postShiftEmail(this, "_objEmailTimesheetServicePromise", objPayload.email, this.get("_sShiftId"), function () {
                        _this5.set("_bShowSendTimesheet", false);
                        _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objAvailabilityStrings").getString("emailSentSuccess"));
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () {
                        _this5.set("_bSendTimesheetLoading", false);
                        _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objAvailabilityStrings").getString("emailSentFailure"));
                    }));
                }
            }
        }
    });
});