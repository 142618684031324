define("a24-ember-candidate-profile/services/rest-candidate-documents", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        sContext: null,
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _sRestApiBaseUrl: Ember.computed.readOnly("_objEnv.sRestApiBaseUrl"),

        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        init: function init() {
            this._super.apply(this, arguments);

            this.set("sContext", this.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE"));
        },

        /**
         * Get all documents
         *
         * @param objContext - The object on which the property must exist
         * @param sProperty - The property name on which to get and set the promise.
         * @param sCandidateId - The candidate  id
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The object containing the callback function to execute when the service call fails
         * @param objQueryParams - Any extra query params for the get list
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  22 Febuary 2019
         */
        getDocuments: function getDocuments(objContext, sProperty, sCandidateId, funcSuccess, objFailure, objQueryParams) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/candidate/" + sCandidateId + "/documents");

            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Get a document
         *
         * @param objContext - The object on which the property must exist
         * @param sProperty - The property name on which to get and set the promise.
         * @param sCandidateId - The candidate  id
         * @param sDocId - The document id
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The object containing the callback function to execute when the service call fails
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  22 Febuary 2019
         */
        getDocument: function getDocument(objContext, sProperty, sCandidateId, sDocId, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/candidate/" + sCandidateId + "/documents/" + sDocId);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Create a document
         *
         * @param objContext - The object on which the property must exist
         * @param sProperty - The property name on which to get and set the promise.
         * @param sCandidateId - The candidate  id
         * @param objData - The document metadata
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The object containing the callback function to execute when the service call fails
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  22 Febuary 2019
         */
        createDocument: function createDocument(objContext, sProperty, sCandidateId, objData, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/candidate/" + sCandidateId + "/documents");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        }
    });
});