define("a24-ember-lib/helpers/equals", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.equals = equals;


  /**
   * This function will return whether passed in params are equal
   *
   * @param arrParams - The array of params sent to the helper
   *
   * @author Ruan Naude <ruan.naude@a24group.com>
   * @since 09 Aug 2016
   */
  function equals(arrParams) {
    return arrParams[0] === arrParams[1];
  }

  exports.default = Ember.Helper.helper(equals);
});