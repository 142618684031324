define("a24-ember-lib/mixins/template-left-column-resize", ["exports", "a24-ember-window/mixins/a24responsive"], function (exports, _a24responsive) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_a24responsive.default, {
        iRightColumnWidth: null,
        bRightColumnVisible: false,
        _bColumnVisible: false,
        _bShowRightColumn: false,
        _iMaxTemplateWidth: null,
        _bSmallDevice: false,
        _objWindowConstants: Ember.inject.service("a24-constants-window"),
        didInsertElement: function didInsertElement() {
            var _this = this;

            Ember.run.scheduleOnce("afterRender", function () {
                _this.set("_iMaxTemplateWidth", _this.get("_objWindowConstants.SCREEN_SIZE_TABLET_PX"));

                if (a24Core.isEmpty(_this.get("iRightColumnWidth"))) {
                    _this.set("iRightColumnWidth", _this.get("_objWindowConstants.SCREEN_SIZE_TABLET_PX") - _this.get("_objWindowConstants.SCREEN_SIZE_MOBILE_PX"));
                }

                _this.setLeftColumn();
            });
        },
        setLeftColumn: Ember.observer("_bColumnVisible", "_bSmallDevice", "bRightColumnVisible", function () {
            var _this2 = this;

            if (!this.get("_bSmallDevice") && this.get("bRightColumnVisible")) {
                this.set("_bShowRightColumn", true);
            } else {
                this.set("_bShowRightColumn", false);
            }
            Ember.run.next(function () {
                Ember.run.scheduleOnce("afterRender", _this2, function () {

                    if (_this2.get("_bShowRightColumn")) {
                        _this2.$(".left-Column").css("margin-right", _this2.get("iRightColumnWidth") - _this2.get("_objWindowConstants.SCREEN_REM_BASE") * 0.75 + 2 + "px");
                        _this2.$(".a24-right-column-style").css("width", _this2.get("iRightColumnWidth") + "px");
                    } else {
                        _this2.$(".left-Column").css("margin-right", "0px");
                    }
                });
            });
        }),
        setSmallDevices: Ember.observer("sScreenSize", function () {
            if (this.get("sScreenSize") === this.get("_objWindowConstants.MEDIA_SIZE_TINY") || this.get("sScreenSize") === this.get("_objWindowConstants.MEDIA_SIZE_MOBILE")) {
                this.setRightHandSmallDevice();
            } else {
                this.set("_bColumnVisible", true);
                this.set("_bSmallDevice", false);
            }
        }),
        sRightColumnStyle: Ember.computed("sRightStyleWidth", function () {
            return "a24-right-column-style";
        }),
        setRightHandSmallDevice: function setRightHandSmallDevice() {
            // We are disabling the right side on mobile until it is decided on what is going to happen with the right column on mobile
            this.set("_bSmallDevice", true);
            this.set("_bColumnVisible", false);
        }
    });
});