define("a24-ember-staffshift-candidate-availability/components/availability-shift-editor", ["exports", "a24-ember-staffshift-candidate-availability/templates/components/availability-shift-editor", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _availabilityShiftEditor, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _availabilityShiftEditor.default,

        /*
          @property objDay
          The data object this component represents.
        */
        objData: null,

        classNames: ["availability-shift-editor"],

        actions: {
            selectAvailability: function selectAvailability(sPeriod, objHash) {
                if (!a24Core.isEmpty(this.get("onShiftClick"))) {
                    this.get("onShiftClick")(sPeriod, objHash);
                }
            }
        }
    });
});