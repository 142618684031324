var a24Extend = {
    // TODO Might need to move to the component
    getCardActionsMarginStyle: function(arrActions, objSpecialAction, iVisibleItems) {
        var iActionCount = 0;
        if (!a24Core.isEmpty(arrActions)) {
            iActionCount = arrActions.length;
        }

        //If the special item is there we need to decrease the visible items by one
        if (!a24Core.isEmpty(objSpecialAction)) {
            iVisibleItems = iVisibleItems - 1;
        }

        //When there are more items that allowed to be visible another item will show up for the overflow
        //So the amount of visible items will then be the allowed visible count plus one for overflow
        if (iActionCount > iVisibleItems) {
            iActionCount = iVisibleItems + 1;
        }

        //We need to recheck the special item since we now need to increase the action count since the special item
        //also takes space
        if (!a24Core.isEmpty(objSpecialAction)) {
            iActionCount = iActionCount + 1;
        }

        //Return the correct style for the needed margin
        if (iActionCount == 0) {
            return "";
        } else {
            return "action-margin-" + iActionCount;
        }
    },
    /**
     * This function will return a formatted array of timezones, which can be
     * used in a drop down component
     *
     * @author Deon De Wet <deon.dewet@a24group.com>
     * @since  18 Dec 2017
     */
    // TODO will have to move to the date helper
    getTimezones: function () {
        var arrTimezoneFormated = [];
        var arrTimezones = momentHelper.getTimezones();
        $.each(arrTimezones, function(iKey, sTimezone) {
            arrTimezoneFormated.push(
                {
                    sLabel: sTimezone,
                    sValue: sTimezone
                }
            );

        });

        return arrTimezoneFormated;
    }
};

//Only add the extended values to the a24 if they do not exist on it already
Object.keys(a24Extend).forEach(function(sKey) {
    if (!a24.hasOwnProperty(sKey)) {
        a24[sKey] = a24Extend[sKey];
    }
});
