define("a24-ember-lib/components/input-form-text-area", ["exports", "a24-ember-lib/templates/components/input-form-text-area", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _inputFormTextArea, _inputFormElement, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _inputFormTextArea.default,
        classNames: ["md-form", "a24ErrorInputParent"],
        sValue: "",
        sInputType: "text-area",
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            Ember.run.scheduleOnce("afterRender", function () {
                var objTextInput = $("#" + _this.get("_sInputId"));
                if (!a24Core.isEmpty(_this.get("sValue"))) {
                    // Set the mvalue first
                    _this.setMValueWithInternalFlag(_this.get("sValue"), true);

                    // firing a change here will cause the mvalue to update to
                    // the same value, hence not causing a duplicate event to be fired
                    objTextInput.val(_this.get("sValue"));
                    objTextInput.change();
                }

                objTextInput.on("keyup", function (objEvent) {
                    if (objEvent.which === 27) {
                        // Escape key
                        objTextInput.change();
                        objTextInput.blur();
                    }
                });

                if (!a24Core.isEmpty(_this.get("iMaxCharacters"))) {
                    objTextInput.attr("length", _this.get("iMaxCharacters"));
                    objTextInput.characterCounter();
                }

                _this.resetFloatingLabel(objTextInput);

                objTextInput.on("blur", function () {
                    _this.resetFloatingLabel(objTextInput);
                });
            });
        },
        resetFloatingLabel: function resetFloatingLabel(objTextInput) {
            //If the input field has no value then the label should NOT have the active class and
            //float at placeholder position
            if (!a24Core.isEmpty(this.get("sValue")) || !a24Core.isEmpty(this.get("sPlaceholder"))) {
                this.$("label").addClass("active");
            } else if (a24Core.isEmpty(objTextInput.val())) {
                this.$("label").removeClass("active");
            }
        },
        clear: function clear() {
            this.setValueFromOutside("");
        },
        setValueFromOutside: function setValueFromOutside(sValue) {
            this.setMValueWithInternalFlag(sValue, false);

            //Set the new value onto the input since it does not bind anymore
            var objTextInput = $("#" + this.get("_sInputId"));
            objTextInput.val(sValue);
            objTextInput.change();

            this.resetFloatingLabel(objTextInput);
        },
        observeValueChange: Ember.observer("sValue", function () {
            this.setValueFromOutside(this.get("sValue"));
        }),
        actions: {
            onInputChange: function onInputChange(sValue) {
                // any internal changes that fire this function will have updated
                // mvalue already, meaning the duplicate update will not fire any
                // additional events
                this.setMValueWithInternalFlag(sValue, false);
            }
        }
    });
});