define("a24-ember-lib/helpers/card-datagrid-table-get-key", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.cardDatagridTableGetKey = cardDatagridTableGetKey;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    /**
     * This function will return the key path for value of a table cell
     *
     * @param arrParams - The array of params sent to the helper
     *
     * @author Ahmed Onawale <ahmedonawale@gmail.com>
     * @since 22 October 2018
     */
    function cardDatagridTableGetKey(_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
            objHeader = _ref2[0];

        var sProperty = objHeader.sProperty;

        sProperty = sProperty.replace("___", ".0.").replace("__", ".");

        if (objHeader.bHTML) {
            sProperty = sProperty + "HTML";
        }

        return sProperty;
    }

    exports.default = Ember.Helper.helper(cardDatagridTableGetKey);
});