define("a24-ember-staffshift-candidate-availability/components/day-overview", ["exports", "a24-ember-staffshift-candidate-availability/templates/components/day-overview", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _dayOverview, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        /*
            Default values
        */
        layout: _dayOverview.default,
        classNames: ["day-overview", "py-1"],

        /*
         * Default values
        */
        classNameBindings: ["bIsDesktop:desktop", "bIsMobileOnly:mobile"],

        /*
          Inline computed properties
        */
        bNotDesktop: Ember.computed.not("bIsDesktop"),
        bIsDesktop: Ember.computed.or("media.isDesktop", "media.isJumbo"),
        bIsMobile: Ember.computed.or("media.isMobile", "media.isTiny", "media.isTablet"),
        bIsMobileOnly: Ember.computed.and("bNotDesktop", "bIsMobile"),

        /*
          Stores the loading state of the card
         */
        bLoading: false,

        /*
            @property arrActions
            An array of objects each specifying their google material icon name and an action that is invoked when the icon is clicked.
        */
        arrActions: null,

        /*
            @property arrData
            The day availability object this component represents.
        */
        arrData: null,

        /*
            @property sDayMonthYear
            Current selected day.
            @TODO Maybe need to add computed here
        */
        sDayMonthYear: null,

        // @TODO Move underlying code to mixin

        actions: {
            selectOverviewAvailability: function selectOverviewAvailability(sPeriod, objAvailability, objEvent) {
                if (!a24Core.isEmpty(this.get("onDayOverviewAction"))) {
                    this.get("onDayOverviewAction")("selectAvailability", objAvailability, sPeriod, objEvent);
                }
            }
        }
    });
});