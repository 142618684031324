define("a24-ember-candidate-profile/services/rest-agency-group-lookup", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * Returns a list of external documents
         *
         * @param objCaller - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The success function
         * @param objFailure - The object that will deal with failed service calls
         * @param objQuery - The query params object
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  19 February 2019
         */
        getExternalDocuments: function getExternalDocuments(objContext, sProperty, funcSuccess, objFailure, objQuery) {

            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency-group/" + sAgencyGroupId + "/lookup/external-document");

            a24RestUrlConstruct.addQueryParamObject(objQuery);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Returns a list of portfolios
         *
         * @param objCaller - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The success function
         * @param objFailure - The object that will deal with failed service calls
         * @param objQuery - The query params object
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  06 May 2019
         */
        getPortfolios: function getPortfolios(objContext, sProperty, funcSuccess, objFailure, objQuery) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency-group/" + sAgencyGroupId + "/lookup/portfolio");

            a24RestUrlConstruct.addQueryParamObject(objQuery);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Returns a list of portfolio consultants
         *
         * @param objCaller - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The success function
         * @param objFailure - The object that will deal with failed service calls
         * @param objQuery - The query params object
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  06 May 2019
         */
        getPortfolioConsultants: function getPortfolioConsultants(objContext, sProperty, funcSuccess, objFailure, objQuery) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency-group/" + sAgencyGroupId + "/lookup/portfolio-consultant");

            a24RestUrlConstruct.addQueryParamObject(objQuery);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Returns a list of portfolio consultant types
         *
         * @param objCaller - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The success function
         * @param objFailure - The object that will deal with failed service calls
         * @param objQuery - The query params object
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  06 May 2019
         */
        getPortfolioConsultantTypes: function getPortfolioConsultantTypes(objContext, sProperty, funcSuccess, objFailure, objQuery) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency-group/" + sAgencyGroupId + "/lookup/portfolio-consultant-type");

            a24RestUrlConstruct.addQueryParamObject(objQuery);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Returns a list of verification types
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The success function
         * @param objFailure - The object that will deal with failed service calls
         * @param objQuery - The query params object
         *
         * @author Langton Mudyiwa<langtonfavor@gmail.com>
         * @since  17 June 2019
         */
        getExternalVerification: function getExternalVerification(objContext, sProperty, funcSuccess, objFailure, objQuery) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency-group/" + sAgencyGroupId + "/lookup/external-verification");

            a24RestUrlConstruct.addQueryParamObject(objQuery);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Returns a list of mail templates
         *
         * @param objCaller - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The success function
         * @param objFailure - The object that will deal with failed service calls
         * @param objQuery - The query params object
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  15 August 2019
         */
        getMailTemplates: function getMailTemplates(objContext, sProperty, funcSuccess, objFailure, objQuery) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency-group/" + sAgencyGroupId + "/lookup/mail-template");

            a24RestUrlConstruct.addQueryParamObject(objQuery);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Returns a list of external days to expiry items
         *
         * @param objContext - The context of this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The success function
         * @param objFailure - The object that will deal with failed service calls
         * @param objQuery - The query params object
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  28 August 2019
         */
        getExternalDaysToExpiry: function getExternalDaysToExpiry(objContext, sProperty, funcSuccess, objFailure, objQuery) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency-group/" + sAgencyGroupId + "/lookup/external-days-to-expiry");

            a24RestUrlConstruct.addQueryParamObject(objQuery);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});