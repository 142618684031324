var a24Core = {
    /**
     * Checks if the value passed in is empty or not
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since  7 June 2016
     *
     * @param objValue - The value to to check if it is empty
     *
     * @return Boolean - If the object is empty or not
     */
    isEmpty: function(objValue) {
        if (
            typeof(objValue) === "undefined" ||
            objValue === null ||
            (typeof(objValue) === "string" && objValue.trim().length === 0) ||
            (objValue instanceof Array && objValue.length === 0) ||
            // Ember components that are being removed from the dom, may partially exist
            // this code detects when there were dom changes, but the ember js object is still in memory
            // previously doing a get would have given a console error
            // doing "get" on a null
            (
                objValue instanceof Object &&
                (
                    objValue.isDestroyed ||
                    objValue.isDestroying
                )
            ) ||
            (
                objValue instanceof Object &&
                (
                    objValue.constructor.prototype.jquery &&
                    objValue.length === 0
                )
            )
        ) {
            return true;
        }
        return false;
    },
    /**
     * This function will replace the tokens in the string with values sent in and return the replaced tokens string
     *
     * @author Junior van Wyk <johannes.vanwyk@a24group.com>
     * @since  16 January 2018
     *
     * @param sString - The string with the tokens to be replaced
     * @param arrTokens - The values the tokens should change
     * @param sOpenTag - The opening tag for the token regex check
     * @param sCloseTag - The closing tag for the token regex check
     *
     * @return String - The un-escaped locale html string
     */
    replaceTokensInString: function(sString, arrTokens, sOpenTag, sCloseTag) {

        if (a24Core.isEmpty(sOpenTag)) {
            sOpenTag = "{";
        }
        if (a24Core.isEmpty(sCloseTag)) {
            sCloseTag = "}";
        }

        var iTokenCount = arrTokens.length;

        // We have tokens, that need to be replaced
        for (var i = 0; i < iTokenCount; i++) {
            // replace tokens
            sString = sString.replace(
                new RegExp("\\" + sOpenTag + i + "\\" + sCloseTag, "g"),
                arrTokens[i]
            );
        }

        return sString;
    },
    /**
     * This function will compare two object and return true if equals else will return false
     *
     * @author Junior van Wyk <johannes.vanwyk@a24group.com>
     * @since  16 January 2018
     *
     * @param objEmber - Ember object so we can copy the object
     * @param objInitial - The initial object to compare
     * @param objAfterwards - The object to compare with
     * @param bSetEmptyToNull - A flag to set the empty values to null (defaults to true)
     *
     * @return Boolean - true or false depending on equal or not
     */
    compareObjects: function(objEmber, objInitial, objAfterwards, bSetEmptyToNull) {
        bSetEmptyToNull = typeof bSetEmptyToNull !== "undefined" ? bSetEmptyToNull : true;
        objInitial = objEmber.copy(objInitial);
        objAfterwards = objEmber.copy(objAfterwards);

        if (bSetEmptyToNull) {
            objInitial = this.setEmptyValuesToNull(objInitial);
            objAfterwards = this.setEmptyValuesToNull(objAfterwards);
        }

        return _.isEqual(objInitial, objAfterwards);
    },
    /**
     * This function will replace any empty values with null
     *
     * @author Junior van Wyk <johannes.vanwyk@a24group.com>
     * @since  16 January 2018
     *
     * @param mData - The data to replace empty values with null
     *
     * @return Mixed - The value sent in with empty values set to null or return just null
     */
    setEmptyValuesToNull: function(mData) {

        // Check the type of the mData
        if (mData !== null && mData instanceof Array) {
            // If the mData is type of array
            for (var i = 0; i < mData.length; i++) {
                // Recursive call for each of the array items
                mData[i] = a24Core.setEmptyValuesToNull(mData[i]);
            }
            // Return the modified array
            return mData;
        } else if (mData !== null && mData instanceof Object) {
            // If the mData is type of object
            for (var sProperty in mData) {
                if (mData.hasOwnProperty(sProperty)) {
                    // Recursive call for each of the object keys
                    mData[sProperty] = a24Core.setEmptyValuesToNull(mData[sProperty]);
                }
            }
            // Return the modified object
            return mData;
        } else if (typeof mData === "string") {
            return a24Core.getTrimedValue(mData, true);
        }

        if (a24Core.isEmpty(mData)) {
            // Return null if the item is empty
            return null;
        }
        // Return value if item is not empty
        return mData;
    },
    /**
     * Gets the trimmed value of a string
     *
     * @author Junior van Wyk <johannes.vanwyk@a24group.com>
     * @since  16 January 2018
     *
     * @param sStringToTrim - The string to trim
     * @param bReturnNull - If the string is empty will return either null or empty string
     *
     * @returns String - The string trimmed value
     */
    getTrimedValue: function(sStringToTrim, bReturnNull) {

        // If the string is not empty
        if (!a24Core.isEmpty(sStringToTrim)) {
            return sStringToTrim.trim();
        }

        // Return null or "" depending on bReturnNull flag
        if (bReturnNull) {
            return null;
        } else {
            return "";
        }
    },
    /**
     * This will generate an array of value/label objects that can be used for dropdowns
     *
     * This method will allow you to generate ascending or descending lists depending on the order of the arguments
     *
     * @author Jacques Slabber <jacques.slabber@a24group.com>
     * @since  21 December 2016
     *
     * @param iFrom - The from value
     * @param iTo - The to value
     *
     * @return Array - The array with the value and label object pairs
     */
    generateRangeOptions: function(iFrom, iTo) {
        var arrResult = [];

        var iFinalValue = 0;
        var bAscending = false;
        if (iFrom > iTo) {
            iFinalValue = iTo - 1;
        } else {
            bAscending = true;
            iFinalValue = iTo + 1;
        }
        while (iFrom !== iFinalValue) {
            arrResult.push({sValue: iFrom.toString(), sLabel: iFrom.toString()});
            (!bAscending) ? iFrom-- : iFrom++;
        }
        return arrResult;
    },
    /**
     * This function will check if a value is an number
     *
     * @author Junior van Wyk <johannes.vanwyk@a24group.com>
     * @since  16 January 2018
     *
     * @param mNumber - The value to check if it is a number
     *
     * @return Boolean - If the value is a number or not
     */
    isNumber: function(mNumber) {
        return !isNaN(parseInt(mNumber)) && isFinite(mNumber);
    },
    /**
     * Checks if the haystack passed in ends with the needle
     *
     * @author Junior van Wyk <johannes.vanwyk@a24group.com>
     * @since  16 January 2018
     *
     * @param sHaystack - The haystack to check
     * @param sNeedle - The needle to match
     *
     * @returns Boolean - If the value ends with needle
     */
    endsWith: function(sHaystack, sNeedle) {
        var iNeedleLength = sNeedle.length;
        if (sHaystack.length >= iNeedleLength) {
            return sHaystack.substr(sHaystack.length - iNeedleLength, iNeedleLength) === sNeedle;
        } else {
            return false;
        }
    },
    /**
     * This function will remove ember id query params from the url, but only the ones that have been set up to be
     * removed, for instance the popups that add query params to allow back button closing the popup.
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since  17 May 2017
     *
     * @param sUrlPopupConstant - The constant for the 'r-pop' to be passed in
     * @param sUrl (optional) When supplied it will strip from that string otherwise it will use the current url
     *
     * @returns The stripped string
     */
    stripEmberIdsFromUrl: function(sUrlPopupConstant, sUrl) {
        var sStripedUrl = sUrl;
        if (a24Core.isEmpty(sStripedUrl)) {
            sStripedUrl = window.location.href;
        }

        //Trim open popup url params from the url
        var sPopupReplace1 = "ember\\d{1,}=" + encodeURI(sUrlPopupConstant) + "&";
        var sPopupReplace2 = "[?]ember\\d{1,}=" + encodeURI(sUrlPopupConstant);
        var sPopupReplace3 = "&ember\\d{1,}=" + encodeURI(sUrlPopupConstant);
        var objPopupReplace1 = new RegExp(sPopupReplace1, "g");
        var objPopupReplace2 = new RegExp(sPopupReplace2, "g");
        var objPopupReplace3 = new RegExp(sPopupReplace3, "g");

        //First replace all with trailing &
        sStripedUrl = sStripedUrl.replace(objPopupReplace1, "");
        //Now we only have 2 case left, the one right in front(starting with ?) or right at the back (starting with &)
        sStripedUrl = sStripedUrl.replace(objPopupReplace2, "");
        sStripedUrl = sStripedUrl.replace(objPopupReplace3, "");

        return sStripedUrl;
    },
    /**
     * This function is to return the current url. This is here because this is a browser function and we can not mock
     * it. This is so that we can at least mock this function in the tests
     *
     * @author Junior van Wyk <johannes.vanwyk@a24group.com>
     * @since  04 May 2017
     *
     * @return String - The current url
     */
    getCurrentUrl: function() {
        return window.location.href;
    },
    /**
     * This function is just a simple copy of whatever is selected. This function is here because we can not mock
     * a browser function so this is so we can at least mock this function in tests
     *
     * @author Junior van Wyk <johannes.vanwyk@a24group.com>
     * @since  04 May 2017
     *
     * @return Boolean - If the execute copy command executed successfully
     */
    copySelected: function() {
        return document.execCommand("copy");
    },
    /**
     * This function will check the mixed value for null values and remove them
     *
     * @author Junior van Wyk <johannes.vanwyk@a24group.com>
     * @since  09 August 2017
     *
     * @param objEmber - The Ember object
     * @param mValue - The value to remove the null values from
     *
     * @return Mixed - Object/Array with the null values removed
     */
    removeNullsFromValue: function (objEmber, mValue) {
        var mData = objEmber.copy(mValue, true);
        for (var sProp in mData) {
            if (a24Core.isEmpty(mData[sProp])) {
                delete mData[sProp];
            } else if (mData[sProp] instanceof Array) {
                for (var i = 0; i < mData[sProp].length; i++) {
                    if (a24Core.isEmpty(mData[sProp][i])) {
                        mData[sProp].splice(i, 1);
                    } else {
                        mData[sProp][i] = a24Core.removeNullsFromValue(objEmber, mData[sProp][i]);

                        // This is to check if the removal did not make the array empty
                        if (a24Core.isEmpty(mData[sProp][i])) {
                            mData[sProp].splice(i, 1);
                        } else if (
                            typeof mData[sProp][i] === "object" &&
                            !Object.keys(mData[sProp][i]).length
                        ) {
                            mData[sProp].splice(i, 1);
                        }
                    }
                }
            } else if (mData[sProp] !== null && typeof mData[sProp] === "object") {
                for (var sInnerProp in mData[sProp]) {
                    if (a24Core.isEmpty(mData[sProp][sInnerProp])) {
                        delete mData[sProp][sInnerProp];
                    } else {
                        mData[sProp][sInnerProp] = a24Core.removeNullsFromValue(objEmber, mData[sProp][sInnerProp]);

                        // This is to check if the removal did not make the object null
                        if (
                            typeof mData[sProp][sInnerProp] === "object" &&
                            !Object.keys(mData[sProp][sInnerProp]).length
                        ) {
                            delete mData[sProp][sInnerProp];
                        }
                    }
                }
            }
        }

        return mData;
    },
    /**
     * This function return a safe string href for links
     *
     * @author Junior van Wyk <johannes.vanwyk@a24group.com>
     * @since  16 January 2018
     *
     * @param objEmber - The Ember object
     * @param sValue - The display value
     * @param sLink - The actual link
     * @param sHref - The type of href it needs to be
     *
     * @return String - The safe string href
     */
    getSafeStringLink: function (objEmber, sValue, sLink, sHref) {
        if (a24Core.isEmpty(sHref)) {
            sHref = "";
        }
        if (a24Core.isEmpty(sLink)) {
            sLink = sValue;
        }

        var sLabel = "";

        switch (sHref) {
            case "email":
                sLabel = "<a href='mailto:" +
                    sLink +
                    "' class='a24-strings-html-link-aps' target='_top'>" +
                    sValue +
                    "</a>";
                break;
            case "phone":
                sLabel = "<a href='tel:" +
                    sLink +
                    "' class='a24-strings-html-link-aps' target='_top'>" +
                    sValue +
                    "</a>";
                break;
            case "link":
                sLabel = "<a href='" +
                    sLink +
                    "' class='a24-strings-html-link-aps' target='_top'>" +
                    sValue +
                    "</a>";
                break;
            case "address":
                sLabel = "<a href='//maps.google.com/maps?q=" +
                    sLink +
                    "' class='a24-strings-html-link-aps' target='_blank'>" +
                    sValue +
                    "</a>";
                break;
            case "externalLink":
                sLabel = "<a href='" +
                    sLink +
                    "' class='a24-strings-html-link-aps' target='_blank'>" +
                    sValue +
                    "</a>";
                break;
            default:
                sLabel = sValue;
        }

        return objEmber.String.htmlSafe(sLabel);
    }
};
