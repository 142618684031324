define("a24-ember-staffshift-core/components/card-datagrid-custom-filter-ward", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/mixins/input-form-element", "a24-ember-staffshift-core/templates/components/card-datagrid-custom-filter-ward"], function (exports, _didRenderChangesMixin, _inputFormElement, _cardDatagridCustomFilterWard) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _cardDatagridCustomFilterWard.default,
        objHeaderItem: null,

        _objSitePromise: null,
        _objWardPromise: null,
        _funcGetSiteSuggestions: null,
        _bWardsLoading: false,
        _bShowWards: false,
        _objSSCoreStringsService: Ember.inject.service("a24-strings-staffshift-core"),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            var funcGetSiteSuggestions = function funcGetSiteSuggestions(sSearchVal, funcCallback) {
                var objQuery = {
                    sSiteName: {
                        mValue: sSearchVal,
                        bLike: true,
                        bEncode: true
                    },
                    bAgencyLinked: {
                        mValue: true
                    },
                    sortBy: {
                        mValue: "sSiteName"
                    },
                    items_per_page: {
                        mValue: 10
                    }
                };

                _this.get("objHeaderItem.objFilterSiteService").getSites(_this, "_objSitePromise", objQuery, function (arrData) {
                    if (a24Core.isEmpty(arrData)) {
                        arrData = [];
                    }

                    var arrFormatted = [];
                    for (var i = 0; i < arrData.length; i++) {
                        var objDataItem = arrData[i];
                        var sLabel = objDataItem.sOrganisationName + ": " + objDataItem.sSiteName;
                        objDataItem.sDisplayName = sLabel;

                        arrFormatted.push(objDataItem);
                    }

                    funcCallback(arrFormatted);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    // Call the callback with empty
                    funcCallback([]);
                }));
            };
            this.set("_funcGetSiteSuggestions", funcGetSiteSuggestions);

            this.set("objHeaderItem._objFilterWardsSelected", null);
            this.set("objHeaderItem._objFilterWardsDropdown", []);

            this._observeAllValues();
        },

        _observeAllValues: Ember.observer("objHeaderItem._objFilterSite", "objHeaderItem._arrFilterWard", function () {
            if (!a24Core.isEmpty(this.get("objChildren.site")) && a24Core.isEmpty(this.get("objHeaderItem._objFilterSite"))) {
                this.get("objChildren.site").clear();
            }
            if (a24Core.isEmpty(this.get("objHeaderItem._arrFilterWard")) && !a24Core.isEmpty(this.get("objHeaderItem._objFilterWardsSelected"))) {
                this.set("objHeaderItem._objFilterWardsSelected", null);
            }

            Ember.run.once(this, "_setupFilterForEdit");
            Ember.run.once(this, "_setFlags");
        }),

        onInputValueChanged: function onInputValueChanged(arrPathToInputMixin) {
            var _this2 = this;

            var sLookup = arrPathToInputMixin.join(".");

            if (sLookup === "objChildren.site") {
                var objSite = this.get(sLookup + ".mValue");

                if (a24Core.isEmpty(objSite)) {
                    this.set("objHeaderItem._objFilterSite", null);
                    this.set("objHeaderItem._arrFilterWard", null);
                    this.set("_bShowWards", false);
                } else {
                    this.set("objHeaderItem._objFilterSite", objSite);

                    this.set("_bWardsLoading", true);
                    this.set("_bShowWards", true);
                    this.set("objHeaderItem._objFilterWardsSelected", null), this.set("objHeaderItem._objFilterWardsDropdown", []);

                    var objQuery = {
                        agency_linked: {
                            mValue: true
                        },
                        sortBy: {
                            mValue: "sWardName"
                        },
                        items_per_page: {
                            mValue: 1000
                        }
                    };

                    this.get("objHeaderItem.objFilterWardService").getWards(this, "_objWardPromise", objSite.sOrganisationId, objSite.sSiteId, objQuery, function (arrData) {
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        _this2.set("objHeaderItem._objFilterWardsDropdown", arrData);
                        if (!a24Core.isEmpty(_this2.get("objHeaderItem._arrFilterWard"))) {
                            _this2.set("objHeaderItem._objFilterWardsSelected", Ember.copy(_this2.get("objHeaderItem._arrFilterWard"), true));
                        }
                        _this2.set("_bWardsLoading", false);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        _this2.set("objHeaderItem._objFilterWardsSelected", null), _this2.set("objHeaderItem._objFilterWardsDropdown", []);
                        _this2.set("_bWardsLoading", false);
                    }));
                }
            } else if (sLookup === "objChildren.ward") {
                if (!this.get("_bWardsLoading")) {
                    var objWard = this.get(sLookup + ".mValue");

                    if (a24Core.isEmpty(objWard)) {
                        this.set("objHeaderItem._arrFilterWard", null);
                    } else {
                        this.set("objHeaderItem._arrFilterWard", objWard);
                    }
                }
            }
        },

        _setupFilterForEdit: function _setupFilterForEdit() {
            var _this3 = this;

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    if (!a24Core.isEmpty(_this3.get("objHeaderItem._objFilterSite")) && a24Core.isEmpty(_this3.get("objChildren.site.mValue"))) {
                        _this3.get("objChildren.site").setValueFromOutside(Ember.copy(_this3.get("objHeaderItem._objFilterSite"), true));
                    }

                    //Other field values set after api call
                });
            });
        },

        _setFlags: function _setFlags() {
            this.customFilterDisplayAddButtonEvent(!a24Core.isEmpty(this.get("objHeaderItem._objFilterSite")) && !a24Core.isEmpty(this.get("objHeaderItem._arrFilterWard")));
        }
    });
});