define("a24-ember-staffshift-core/services/conversation-preview-generator", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSSCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objFormInputsStringsService: Ember.inject.service("a24-strings-form-inputs"),
        _objSSCoreEnums: Ember.inject.service("a24-enums-staffshift-core"),

        /**
         * Will generate the entire page for the conversations
         *
         * @param object objConversation - The conversation object
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  9 Dec 2019
         *
         * @return An html string
         */
        generateFullHtmlWithHTMLWrappingFromConversation: function generateFullHtmlWithHTMLWrappingFromConversation(objConversation) {
            var sHtml = "\n            <html>\n                <head>\n                    <link rel=\"stylesheet\" type=\"text/css\" href=\"/assets/a24-ember-staffshift-core/conversation-preview/font.css\">\n                    <link rel=\"stylesheet\" type=\"text/css\" href=\"/assets/a24-ember-staffshift-core/conversation-preview/style.css\">\n                </head>\n                <body>\n                    " + this.generateFullHtmlFromConversation(objConversation) + "\n                </body>\n            </html>\n        ";

            return sHtml;
        },

        /**
         * Will generate the HTML for the stylesheet
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  8 Jan 2020
         *
         * @return An html string
         */
        generateFullHtmlForStyleSheet: function generateFullHtmlForStyleSheet() {
            var sHtml = "\n            <link rel=\"stylesheet\" type=\"text/css\" href=\"/assets/a24-ember-staffshift-core/conversation-preview/style.css\">\n        ";

            // Return a safe version of the html
            return Ember.String.htmlSafe(sHtml);
        },

        /**
         * Will generate the HMTL for the conversations
         *
         * @param object objConversation - The conversation object
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  9 Dec 2019
         *
         * @return An html safe object
         */
        generateFullHtmlFromConversation: function generateFullHtmlFromConversation(objConversation) {
            var _this = this;

            var arrMessages = [];
            var bConversationNoData = false;
            if (objConversation.status !== "open") {
                if (!a24Core.isEmpty(objConversation.message_listing)) {
                    arrMessages = objConversation.message_listing.map(function (objMessage) {
                        var sMemberType = "";
                        var objParticipant = objConversation.participant_listing.findBy("participant_id", objMessage.participant_id);
                        if (!a24Core.isEmpty(objParticipant) && !a24Core.isEmpty(objParticipant.attributes) && !a24Core.isEmpty(objParticipant.attributes.member_type)) {
                            sMemberType = objParticipant.attributes.member_type;
                        }
                        return _this._generateHTMLForMessage(objMessage, sMemberType, objParticipant, objConversation);
                    });
                } else {
                    bConversationNoData = true;
                }
            } else {
                return Ember.String.htmlSafe(this.get("_objSSCoreStrings").getString("conversationNoPreview"));
            }

            var sHeaderHtml = this._generateHTMLForConversationHeader(objConversation);

            var sMessageHtml = "";
            if (bConversationNoData) {
                sMessageHtml = Ember.String.htmlSafe(this.get("_objSSCoreStrings").getString("noMessages"));
            } else {
                sMessageHtml = "<div class=\"chat-messages-wrapper" + (a24Core.isEmpty(sHeaderHtml) ? " full-height" : "") + "\">\n                    <div class=\"chat-messages\">\n                        <ul class=\"messages-list\">\n                            " + arrMessages.join("") + "\n                        </ul>\n                    </div>\n                </div>";
            }

            var sHtml = "\n            <div class=\"a24-conversation-preview\">\n                " + sHeaderHtml + "\n                " + sMessageHtml + "\n            </div>\n        ";

            // Return a safe version of the html
            return Ember.String.htmlSafe(sHtml);
        },

        /**
         * Generate a single DOM Node for a conversation header
         *
         * @param object objConversation - The conversation object
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  12 Dec 2019
         *
         * @return A DOM Node for conversation header
         */
        _generateHTMLForConversationHeader: function _generateHTMLForConversationHeader(objConversation) {
            var objCreatedDate = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
            objCreatedDate.setDateFromRest(objConversation.created_at);

            var arrParticipants = objConversation.participant_listing.reduce(function (arrMapping, objParticipant) {
                if (objParticipant.attributes.member_type === "chatterbox") {
                    if (!a24Core.isEmpty(objConversation.chatterbox_profiles) && (objParticipant.attributes.medium === "sms" || objParticipant.attributes.medium === "whatsapp")) {
                        arrMapping.push({
                            sDisplayName: objConversation.chatterbox_profiles[0].entity_name,
                            sUserId: objConversation.chatterbox_profiles[0].user_id ? objConversation.chatterbox_profiles[0].user_id : objConversation.chatterbox_profiles[0]._id
                        });
                    } else if (!a24Core.isEmpty(objParticipant.attributes) && !a24Core.isEmpty(objParticipant.attributes.authorDisplayName)) {
                        arrMapping.push({
                            sDisplayName: objParticipant.attributes.authorDisplayName,
                            sUserId: objParticipant.user_id
                        });
                    }
                } else {
                    var objMessage = objConversation.message_listing.findBy("participant_id", objParticipant.participant_id);
                    if (!a24Core.isEmpty(objMessage)) {
                        if (objParticipant.attributes.member_type === "bot") {
                            var objBot = objConversation.bots.findBy("_id", objParticipant.identity);
                            arrMapping.push({
                                sDisplayName: !a24Core.isEmpty(objBot) ? objBot.name : "Shai",
                                sUserId: objConversation.identity
                            });
                        } else if (!a24Core.isEmpty(objParticipant.attributes) && !a24Core.isEmpty(objParticipant.attributes.authorDisplayName)) {
                            arrMapping.push({
                                sDisplayName: objParticipant.attributes.authorDisplayName,
                                sUserId: objParticipant.user_id
                            });
                        }
                    }
                }
                return arrMapping;
            }, []);

            // We need to remove duplicates thus this filter
            var objSeen = {};
            arrParticipants = arrParticipants.filter(function (objParticipant) {
                // Have we seen this user before?
                if (objSeen.hasOwnProperty(objParticipant.sUserId)) {
                    // Don't keep this entry
                    return false;
                }
                // Remember that we've seen it
                objSeen[objParticipant.sUserId] = objParticipant;
                // Keep this one
                return true;
            });

            var arrParticipantNames = arrParticipants.map(function (objParticipant) {
                return objParticipant.sDisplayName;
            });

            var sClosedDateRow = "";

            if (objConversation.status === this.get("_objSSCoreEnums.conversationStatus.CLOSED.backend") && !a24Core.isEmpty(objConversation.updated_at)) {
                var objClosedDate = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                objClosedDate.setDateFromRest(objConversation.updated_at);

                sClosedDateRow = this._generateHeaderCellHtml(this.get("_objSSCoreStrings").getString("closedDate") + ":", objClosedDate.getDateTimeFormat());
            }

            var arrRows = [this._generateHeaderCellHtml(this.get("_objFormInputsStringsService").getString("startDate") + ":", objCreatedDate.getDateTimeFormat()), sClosedDateRow, this._generateHeaderCellHtml(this.get("_objStringsService").getString("status") + ":", this.get("_objSSCoreEnums").getFrontendValue("conversationStatus", objConversation.status))];

            if (!a24Core.isEmpty(objConversation.team) && !a24Core.isEmpty(objConversation.team.team_name)) {
                arrRows.push(this._generateHeaderCellHtml(this.get("_objSSCoreStrings").getString("team") + ":", objConversation.team.team_name));
            }

            arrRows.push(this._generateHeaderCellHtml(this.get("_objSSCoreStrings").getString("participants") + ":", arrParticipantNames.join(", ")));

            return "\n            <div class=\"a24-conversation-heading-block\">\n                <table class=\"a24-conversation-details-table\">" + arrRows.join("") + "\n                </table>\n            </div>\n        ";
        },

        /**
         * Generates a single line for the conversation heading details
         *
         * @param string sLabel - The label to display
         * @param string sValue - The value associated with the label
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  17 Dec 2019
         *
         * @return An html string
         */
        _generateHeaderCellHtml: function _generateHeaderCellHtml(sLabel, sValue) {
            return "\n            <tr>\n                <td class=\"a24-conversation-details-label\">" + sLabel + "</td>\n                <td class=\"a24-conversation-details-value\">" + sValue + "</td>\n            </tr>";
        },


        /**
         * Generate a single DOM Node for avater
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  12 Dec 2019
         *
         * @return A DOM Node for avatar
         */
        _generateHTMLForAvatar: function _generateHTMLForAvatar(displayName, sMemberType) {
            return "\n            <div class=\"avater a24-conversation-preview-tooltip\">\n                <div class=\"avater-placeholder\">\n                    <i class=\"material-icons\">" + (sMemberType === "bot" ? "android" : "person") + "</i>\n                </div>" + (displayName ? "<span class=\"tooltiptext\">" + displayName + "</span>" : "") + "\n            </div>\n        ";
        },

        /**
         * Generate a single DOM Node for a conversation message
         *
         * @param object objMessage - A conversation message object
         * {
         *     "participant_id": "5de7dce6bc0ea0cce8869349",
         *     "body": "helop\n",
         *     "type": "text",
         *     "created_at": "2019-12-06T09:25:47.000Z",
         *     "attributes": {
         *        "memberType": "chatterbox"
         *      }
         * }
         * @param string sType - The conversation type
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  9 Dec 2019
         *
         * @return A DOM Node for chat message
         */
        _generateHTMLForMessage: function _generateHTMLForMessage(objMessage, sType, objParticipant, objConversation) {
            var sTimeAgo = momentHelper.getTimeFromNow(objMessage.created_at, a24Constants.DATE_FORMAT_ISO_FORMAT);
            var sDisplayName = null;
            if (!a24Core.isEmpty(objParticipant) && !a24Core.isEmpty(objParticipant.attributes)) {
                sDisplayName = objParticipant.attributes.authorDisplayName;
            }

            if (!a24Core.isEmpty(objConversation.chatterbox_profiles) && objParticipant.attributes.member_type === "chatterbox" && (objParticipant.attributes.medium === "sms" || objParticipant.attributes.medium === "whatsapp")) {
                sDisplayName = objConversation.chatterbox_profiles[0].entity_name;
            }

            if (objParticipant.attributes.member_type === "bot") {
                var objBot = objConversation.bots.findBy("_id", objParticipant.identity);
                sDisplayName = !a24Core.isEmpty(objBot) ? objBot.name : "Shai";
            }

            var sNode = "\n            <li class=\"chat-message " + sType + "\">\n                <div class=\"bubble\">" + objMessage.body + "</div>\n                " + this._generateHTMLForAvatar(sDisplayName, objParticipant.attributes.member_type) + "\n                <span class=\"subtext\">" + sTimeAgo + "</span>\n            </li>\n        ";

            return sNode;
        }
    });
});