define("a24-ember-candidate-profile/services/card-datagrid-custom-filter-candidate-position-held", ["exports", "a24-ember-lib/services/card-datagrid-filter-custom-base"], function (exports, _cardDatagridFilterCustomBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _cardDatagridFilterCustomBase.default.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objStringsService: Ember.inject.service("a24-strings"),

        _objJobTitleService: null,
        funcFilterCustomPrimaryQuery: function funcFilterCustomPrimaryQuery(objHeaderItem /* , objHeaderSecondaries */) {
            var objQuery = {
                bEncode: true,
                sFilterValue: objHeaderItem._objJobTitle.sJobFamilyName,
                mValue: objHeaderItem._objJobTitle.sJobFamilyId,
                arSec: [{
                    "sProp": "job_title_id",
                    "sFilterValue": objHeaderItem._objJobTitle.sTitleName,
                    "bEncode": true,
                    "mValue": objHeaderItem._objJobTitle.id
                }]
            };

            if (!a24Core.isEmpty(objHeaderItem._objStatus) && !a24Core.isEmpty(objHeaderItem._objStatus.sValue)) {
                objQuery.arSec.push({
                    "sProp": "work_suggestion_status",
                    "bEncode": true,
                    "mValue": objHeaderItem._objStatus.sValue
                });
            }

            if (!a24Core.isEmpty(objHeaderItem._objVerifiedStatus) && !a24Core.isEmpty(objHeaderItem._objVerifiedStatus.sValue)) {
                objQuery.arSec.push({
                    "sProp": "status_name",
                    "bEncode": true,
                    "mValue": objHeaderItem._objVerifiedStatus.sValue
                });
            }

            if (objHeaderItem._objExperienceDuration.sValue === this.get("_objCandidateProfileEnumsService.experienceDuration.AT_LEAST.backend")) {
                if (!a24Core.isEmpty(objHeaderItem._objVerifiedStatus) && !a24Core.isEmpty(objHeaderItem._objVerifiedStatus.sValue)) {
                    objQuery.arSec.push({
                        "sProp": "status_total_time",
                        "bEncode": true,
                        "bFrom": true,
                        "mValue": objHeaderItem._iNumberOfMonths * (30 * 86400),
                        "iNumberOfMonths": objHeaderItem._iNumberOfMonths
                    });
                } else {
                    objQuery.arSec.push({
                        "sProp": "overall_duration",
                        "bEncode": true,
                        "bFrom": true,
                        "mValue": objHeaderItem._iNumberOfMonths * (30 * 86400),
                        "iNumberOfMonths": objHeaderItem._iNumberOfMonths
                    });
                }
            } else if (objHeaderItem._objExperienceDuration.sValue === this.get("_objCandidateProfileEnumsService.experienceDuration.LAST.backend")) {
                var objDate = this.getDateForPastNumberOfMonths(objHeaderItem._iNumberOfMonths);

                objQuery.arSec.push({
                    "sProp": "status_last_end_date",
                    "bEncode": true,
                    "bFrom": true,
                    "mValue": objDate.getDateStringForRest(),
                    "iNumberOfMonths": objHeaderItem._iNumberOfMonths
                });
            }

            return objQuery;
        },

        getDateForPastNumberOfMonths: function getDateForPastNumberOfMonths(iNumberOfMonths) {
            var objDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            objDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_MONTHS"), iNumberOfMonths);

            objDate.setTimeToStartOfDay();

            return objDate;
        },

        funcFilterCustomTag: function funcFilterCustomTag(objHeaderItem, objQuery, objHeadersSecondaries, bSkipTitle) {
            var sPositionHeldTagKey = bSkipTitle ? "positionHeldTagNoTitle" : "positionHeldTag";

            var sJobFamily = objQuery.sFilterValue;
            var sJobTitle = objQuery.arSec[0].sFilterValue;
            var sPeriod = "";
            var sStatus = "";
            var sVerifiedStatus = "";
            var iNumberOfMonths = null;
            var sMonth = this.get("_objCandidateProfileStringsService").getString("months");
            var objExperienceDuration = this.get("_objCandidateProfileEnumsService.experienceDuration");
            var arrSeconderies = objQuery.arSec;
            for (var i = 0; i < arrSeconderies.length; i++) {
                iNumberOfMonths = arrSeconderies[i].iNumberOfMonths;
                if (arrSeconderies[i].sProp === "overall_duration" || arrSeconderies[i].sProp === "status_total_time") {
                    sPeriod = this.get("_objCandidateProfileStringsService").getString("for").toLowerCase() + " " + objExperienceDuration.AT_LEAST.frontend + " " + iNumberOfMonths;
                } else if (arrSeconderies[i].sProp === "status_last_end_date") {
                    sPeriod = this.get("_objCandidateProfileStringsService").getString("inThe").toLowerCase() + " " + objExperienceDuration.LAST.frontend + " " + iNumberOfMonths;
                } else if (arrSeconderies[i].sProp === "status_name") {
                    sVerifiedStatus = this.get("_objCandidateProfileStringsService").getString("thatIs").toLowerCase() + " " + this.get("_objCandidateProfileEnumsService").getFrontendValue("positionHeldStatus", arrSeconderies[i].mValue) + " ";
                } else if (arrSeconderies[i].sProp === "work_suggestion_status") {
                    sStatus = " " + this.get("_objStringsService").getString("and").toLowerCase() + " " + this.get("_objCandidateProfileStringsService").getString("workSuggest") + " " + this.get("_objStringsService").getString("is").toLowerCase() + " " + this.get("_objCandidateProfileEnumsService").getFrontendValue("positionHeldWorkSuggestStatus", arrSeconderies[i].mValue);
                } else {
                    sPeriod = this.get("_objCandidateProfileStringsService").getString("for").toLowerCase() + " " + objExperienceDuration.ANY_AMOUNT_OF.frontend;
                }

                if (!a24Core.isEmpty(iNumberOfMonths) && iNumberOfMonths === 1) {
                    sMonth = this.get("_objCandidateProfileStringsService").getString("month");
                }
            }

            var sDuration = sVerifiedStatus + sPeriod.toLowerCase() + " " + sMonth.toLowerCase() + sStatus;

            return {
                sTag: this.get("_objCandidateProfileStringsService").getTokenString(sPositionHeldTagKey, sJobFamily, sJobTitle, sDuration)
            };
        },

        funcFilterCustomPrimaryReset: function funcFilterCustomPrimaryReset(objHeaderItem) {
            //Reset the state of the custom filter input
            Ember.set(objHeaderItem, "_objJobTitle", null);
            Ember.set(objHeaderItem, "_objExperienceDuration", null);
            Ember.set(objHeaderItem, "_objVerifiedStatus", null);
            Ember.set(objHeaderItem, "_iNumberOfMonths", null);
            Ember.set(objHeaderItem, "_objStatus", null);
        },

        funcFilterCustomFixupQuery: function funcFilterCustomFixupQuery(objHeader /*, objHeaderSecondaries */) {
            if (a24Core.isEmpty(objHeader.mFilterValue)) {
                return;
            }
            for (var i = 0; i < objHeader.mFilterValue.length; i++) {
                var arrSeconderies = objHeader.mFilterValue[i].arSec;
                for (var j = 0; j < arrSeconderies.length; j++) {
                    if (arrSeconderies[j].sProp === "status_last_end_date") {
                        Ember.set(objHeader, "mFilterValue." + i + ".arSec." + j + ".mValue", this.getDateForPastNumberOfMonths(arrSeconderies[j].iNumberOfMonths).getDateStringForRest());
                    }
                }
            }
        },
        funcFilterCustomSetEditState: function funcFilterCustomSetEditState(objHeaderItem, iIndex) {
            var objTag = objHeaderItem.mFilterValue[iIndex];

            var objJobTitle = {
                sJobFamilyId: objTag.mValue,
                sJobFamilyName: objTag.sFilterValue
            };

            for (var i = 0; i < objTag.arSec.length; i++) {
                var objSecondary = objTag.arSec[i];
                if (objSecondary.sProp === "job_title_id") {
                    objJobTitle.id = objSecondary.mValue;
                    objJobTitle.sTitleName = objSecondary.sFilterValue;

                    Ember.set(objHeaderItem, "_objJobTitle", objJobTitle);
                } else if (objSecondary.sProp === "overall_duration") {
                    Ember.set(objHeaderItem, "_iNumberOfMonths", objSecondary.iNumberOfMonths);
                    Ember.set(objHeaderItem, "_objExperienceDuration", {
                        sValue: this.get("_objCandidateProfileEnumsService.experienceDuration.AT_LEAST.backend")
                    });
                } else if (objSecondary.sProp === "status_total_time") {
                    Ember.set(objHeaderItem, "_iNumberOfMonths", objSecondary.iNumberOfMonths);
                    Ember.set(objHeaderItem, "_objExperienceDuration", {
                        sValue: this.get("_objCandidateProfileEnumsService.experienceDuration.AT_LEAST.backend")
                    });
                } else if (objSecondary.sProp === "status_last_end_date") {
                    Ember.set(objHeaderItem, "_iNumberOfMonths", objSecondary.iNumberOfMonths);
                    Ember.set(objHeaderItem, "_objExperienceDuration", {
                        sValue: this.get("_objCandidateProfileEnumsService.experienceDuration.LAST.backend")
                    });
                } else if (objSecondary.sProp === "status_name") {
                    Ember.set(objHeaderItem, "_objVerifiedStatus", {
                        sValue: objSecondary.mValue
                    });
                } else if (objSecondary.sProp === "work_suggestion_status") {
                    Ember.set(objHeaderItem, "_objStatus", {
                        sValue: objSecondary.mValue
                    });
                }
            }

            if (a24Core.isEmpty(objHeaderItem._objExperienceDuration)) {
                Ember.set(objHeaderItem, "_objExperienceDuration", {
                    sValue: this.get("_objCandidateProfileEnumsService.experienceDuration.ANY_AMOUNT_OF.backend")
                });
            }
        }
    });
});