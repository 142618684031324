define("a24-ember-candidate-profile/components/candidate-info-section-component", ["exports", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-info-section-component"], function (exports, _inputFormElement, _didRenderChangesMixin, _candidateInfoSectionComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        _ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        layout: _candidateInfoSectionComponent.default,
        classNames: ["candidate-info-section"],
        sCandidateId: null,
        sUserId: null,
        objSettingsRestService: null,
        objBasicInfoRestService: null,
        objCandidateTypeRestService: null,
        objAvatarRestService: null,
        objCandidateMetaRestService: null,
        arrCandidateData: null,
        bShowAvatar: true,
        bShowOpenInNewTab: false,

        _iTimesGetCalled: 0,
        _iDelayTime: 1000,

        _objAvatarGetPromise: null,
        _objAvatarPutPromise: null,
        _objAvatarDeletePromise: null,
        _objMetaGetPromise: null,
        _objFileReader: null,
        _bAddAvatarUploading: false,
        _sProfilePicHeader: "",
        _sLoaderText: "",
        _bScriptLoaded: false,
        _objCropper: null,
        _sProfileIcon: "",
        _sChangeImage: "",
        _bShowDeleteConfirm: false,
        _bDeletePopupLoading: false,

        _objMinDate: null,
        _objMaxDate: null,
        _bOpenProfilePic: false,
        _bProfilePicLoading: true,
        _bLoading: false,
        _bShowUnsavedData: false,
        _bAllowEditIdNumber: false,
        _bAllowEditCandidateType: false,
        _bShowEditPopup: false,
        _bPopupLoading: false,
        _bAllowEdit: true,
        _objSelectedTitle: null,
        _objSelectedGender: null,
        _sPopupHeader: null,
        _objBasicInfoData: null,
        _objSpecialAddEditPopupAction: null,
        _arrHeaderActions: null,
        _arrTitleDropdown: null,
        _arrGenderDropdown: null,
        _objBasicInfoUpdatePromise: null,
        _objBasicInfoGetPromise: null,
        _objCandidateProfileCandidateTypePromise: null,
        _objUserSession: Ember.inject.service("user-session"),
        _objEnumsService: Ember.inject.service("a24-enums"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objSSCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objNavigation: Ember.inject.service("navigation"),
        _bServerError: false,
        _arrReloadActions: null,
        _objSelectedCandidateType: null,
        _arrCandidateTypeDropdown: null,
        _objPromiseBasicInfoRestService: null,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            var objMinDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            var objMaxDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            this.set("_sProfileIcon", "camera_alt");
            this.set("_sChangeImage", this.get("_objCandidateProfileStringsService").getString("changeProfilePic"));

            if (a24Core.isEmpty(this.get("_objFileReader"))) {
                this.set("_objFileReader", new FileReader());
            }

            this.set("_sProfilePicHeader", this.get("_objCandidateProfileStringsService").getString("profilePicture"));
            this.set("_sLoaderText", this.get("_objCandidateProfileStringsService").getString("uploadingProfilePic"));

            objMinDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_YEARS"), 120);
            objMaxDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_YEARS"), 16);

            this.set("_objMinDate", objMinDate);
            this.set("_objMaxDate", objMaxDate);

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.getCandidateBasicInfo();
                }
            }]);

            if (!this.get("objSettingsRestService.objSettings.profile_editable")) {
                this.set("_bAllowEdit", false);
            }

            var sPopupHeader = this.get("_objStringsService").getTokenString("editItem", this.get("_objCandidateProfileStringsService").getString("personalInfo"));

            this.set("_sPopupHeader", sPopupHeader);

            this.set("_objSpecialAddEditPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onPopupClose");
                }
            });

            var objValidationConfig = {};

            objValidationConfig["objChildren.first_name"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 2);
                a24Validation.addMaxLength(objMixin, 100);
                a24Validation.addRequired(objMixin, true);
            };

            objValidationConfig["objChildren.last_name"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 2);
                a24Validation.addMaxLength(objMixin, 100);
                a24Validation.addRequired(objMixin, true);
            };

            objValidationConfig["objChildren.candidate_type_id"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };

            this.set("objChildConfig", objValidationConfig);

            this.set("_arrGenderDropdown", this.get("_objCandidateProfileEnumsService").getList("genderBinary"));

            this.set("_arrTitleDropdown", this.get("_objCandidateProfileEnumsService").getList("title"));

            this.set("arrCandidateData", []);

            if (!this.get("_bIsCandidateContext")) {
                if (this.get("bShowAvatar")) {
                    this._getCandidateUserId();
                } else {
                    this.getCandidateBasicInfo();
                }
            } else {
                this.set("sUserId", this.get("_objUserSession").objAuthData.context.sUserId);
                this.getCandidateBasicInfo();
            }
        },

        _getCandidateUserId: function _getCandidateUserId() {
            var _this2 = this;

            this.set("_bLoading", true);
            this.set("_bServerError", false);

            // This is temporary until the candidate object returns the avatar url
            this.get("objCandidateMetaRestService").getCandidateMeta(this, "_objMetaGetPromise", this.get("sCandidateId"), function (objResponse) {
                if (!a24Core.isEmpty(objResponse) && !a24Core.isEmpty(objResponse.user_id)) {
                    _this2.set("sUserId", objResponse.user_id);
                }
                _this2.getCandidateBasicInfo();
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
            function (sCode) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this2.set("_bServerError", true);
                    return;
                }

                _this2.set("sUserId", "");
                // this is so that we can just go through to get the candidate info. Dont want to block the candidate
                _this2.getCandidateBasicInfo();
            }));
        },

        _onLoadingChange: Ember.observer("_bLoading", function () {
            var _this3 = this;

            if (this.get("_bLoading")) {
                this.set("_arrHeaderActions", []);
            } else {
                var arrHeaderActions = [];

                if (this.get("_bAllowEdit")) {
                    arrHeaderActions.push({
                        sTitle: this.get("_objStringsService").getString("edit"),
                        sIcon: "edit",
                        executeCardAction: function executeCardAction() {
                            _this3.openEditPopup();
                        }
                    });
                }

                if (this.get("bShowOpenInNewTab")) {
                    arrHeaderActions.push({
                        sTitle: this.get("_objSSCoreStrings").getString("openInNewTab"),
                        sIcon: "open_in_new",
                        executeCardAction: function executeCardAction() {
                            if (_this3.get("objBasicInfoRestService.sContext") === _this3.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP")) {
                                var sAgencyGroupLink = _this3.get("_objNavigation").navigateWithinContext("candidate-search-view", {
                                    "candidate-search-view": [_this3.get("sCandidateId")]
                                }, null, true);
                                _this3._openLink(sAgencyGroupLink);
                            } else if (_this3.get("objBasicInfoRestService.sContext") === _this3.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY")) {
                                var sAgencyLink = _this3.get("_objNavigation").navigateWithinContext("agency-candidate-search-view", {
                                    "agency-candidate-search-view": [_this3.get("sCandidateId")]
                                }, null, true);
                                _this3._openLink(sAgencyLink);
                            }
                        }
                    });
                }
                this.set("_arrHeaderActions", arrHeaderActions);
            }
        }).on("init"),
        _bIsCandidateContext: Ember.computed("objBasicInfoRestService.sContext", function () {
            return this.get("objBasicInfoRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE");
        }),
        _openLink: function _openLink(sLink) {
            window.open(sLink);
        },
        prepareForDisplay: function prepareForDisplay(objData) {
            var sTitle = null;
            var sGender = null;
            var sFirstName = null;
            var sLastName = null;
            var sDOB = null;
            var sIdNumber = null;
            var sCandidateType = null;

            if (!a24Core.isEmpty(objData) && !a24Core.isEmpty(objData.title)) {
                sTitle = this.get("_objCandidateProfileEnumsService").getFrontendValue("title", objData.title);
            }

            if (!a24Core.isEmpty(objData) && !a24Core.isEmpty(objData.gender)) {
                sGender = this.get("_objEnumsService").getFrontendValue("gender", objData.gender);
            }

            if (!a24Core.isEmpty(objData) && !a24Core.isEmpty(objData.first_name)) {
                sFirstName = objData.first_name;
            }

            if (!a24Core.isEmpty(objData) && !a24Core.isEmpty(objData.last_name)) {
                sLastName = objData.last_name;
            }

            if (!a24Core.isEmpty(objData) && !a24Core.isEmpty(objData.id_number)) {
                sIdNumber = objData.id_number;
            }

            if (!a24Core.isEmpty(objData) && !a24Core.isEmpty(objData.date_of_birth)) {
                var objDateOfBirth = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                objDateOfBirth.setDateFromRest(objData.date_of_birth, true);
                sDOB = objDateOfBirth.getDateFormat();
            }

            if (!this.get("_bIsCandidateContext") && !a24Core.isEmpty(objData) && !a24Core.isEmpty(objData.candidate_type_id)) {
                var objSelectedCandidateType = this.get("_arrCandidateTypeDropdown").findBy("sValue", objData.candidate_type_id);

                if (!a24Core.isEmpty(objSelectedCandidateType)) {
                    sCandidateType = objSelectedCandidateType.sLabel;
                }
            }

            var arrItems = [{
                sLabel: this.get("_objStringsService").getString("title"),
                sValue: sTitle,
                bText: true
            }, {
                sLabel: this.get("_objStringsService").getString("firstName"),
                sValue: sFirstName,
                bText: true
            }, {
                sLabel: this.get("_objStringsService").getString("lastName"),
                sValue: sLastName,
                bText: true
            }, {
                sLabel: this.get("_objStringsService").getString("dateOfBirth"),
                sValue: sDOB,
                bDate: true
            }, {
                sLabel: this.get("_objStringsService").getString("gender"),
                sValue: sGender,
                bText: true
            }];

            var objIdNumberData = {
                sLabel: this.get("_objCandidateProfileStringsService").getString("idNumber"),
                sValue: sIdNumber,
                bText: true
            };

            if (this.get("_bIsCandidateContext") && !a24Core.isEmpty(sIdNumber)) {
                arrItems.pushObject(objIdNumberData);
            } else if (!this.get("_bIsCandidateContext") && this.get("_objUserSession.objContextDetails.sCountryId") === "710") {
                arrItems.pushObject(objIdNumberData);
                this.set("_bAllowEditIdNumber", true);
            }

            if (!this.get("_bIsCandidateContext")) {
                this.set("_bAllowEditCandidateType", true);

                var objCandidateTypeData = {
                    sLabel: this.get("_objCandidateProfileStringsService").getString("candidateType"),
                    sValue: sCandidateType,
                    bText: true
                };

                arrItems.pushObject(objCandidateTypeData);
            } else {
                this.set("_bAllowEditCandidateType", false);
            }

            this.set("arrCandidateData", [{
                arrItems: arrItems
            }]);
        },

        getCandidateBasicInfo: function getCandidateBasicInfo() {
            var _this4 = this;

            this.set("_bLoading", true);
            this.set("_bServerError", false);

            var objServiceCalls = {};
            var arrRequiredResponses = [];

            var objPromiseCandidateType = null;
            if (!this.get("_bIsCandidateContext") && a24Core.isEmpty(this.get("_arrCandidateTypeDropdown"))) {
                var sId = this.get("_objUserSession").getUserContextReference().mId; // the agency or agency group id

                objPromiseCandidateType = this.get("objCandidateTypeRestService").getCandidateTypes(this, "_objCandidateProfileCandidateTypePromise", sId, function () {}, // handled in rsvp
                a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode) {
                    if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                        _this4.set("_bServerError", true);
                        return;
                    }
                    // Handle 404 for no candidate types
                    if (sCode === "404") {
                        _this4.set("_arrCandidateTypeDropdown", []);
                        _this4.set("_bLoading", false);
                    }
                }));

                objServiceCalls._objRestCandidateTypeProperty = objPromiseCandidateType;
                arrRequiredResponses.push("_objRestCandidateTypeProperty");
            }

            var _objPromiseBasicInfoRestService = this.get("objBasicInfoRestService").getBasicInfo(this, "_objBasicInfoGetPromise", this.get("sCandidateId"), function () {}, // handled in rsvp
            a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
            function (sCode) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this4.set("_bServerError", true);
                    return;
                }
                if (sCode === "404" || sCode === "403") {
                    if (!a24Core.isEmpty(_this4.get("onGetBasicInfoFailure"))) {
                        _this4.get("onGetBasicInfoFailure")(sCode);
                        return;
                    }
                }

                _this4.set("_objBasicInfoData", null);
                _this4.set("arrCandidateData", null);
                _this4.set("_bLoading", false);
            }));

            if (!a24Core.isEmpty(this.get("sUserId")) && this.get("bShowAvatar")) {
                var _objPromiseAvatarRestService = this.get("objAvatarRestService").getAvatar(this, "_objAvatarGetPromise", this.get("sUserId"), function () {}, // handled in rsvp
                a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
                function (sCode) {
                    if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                        _this4.set("_bServerError", true);
                        return;
                    }

                    _this4.set("_sAvatar", "");
                    _this4.set("_bLoading", false);
                }));
                objServiceCalls._objRestAvatarProperty = _objPromiseAvatarRestService;
                arrRequiredResponses.push("_objRestAvatarProperty");
            }

            objServiceCalls._objRestBasicInfoProperty = _objPromiseBasicInfoRestService;
            arrRequiredResponses.push("_objRestBasicInfoProperty");

            Ember.copy(a24RSVP).configure(this, objServiceCalls, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    if (!a24Core.isEmpty(objResponses._objRestCandidateTypeProperty) && !a24Core.isEmpty(objResponses._objRestCandidateTypeProperty.objHeaders) && objResponses._objRestCandidateTypeProperty.objHeaders.statusText === "abort" || !a24Core.isEmpty(objResponses._objRestBasicInfoProperty) && !a24Core.isEmpty(objResponses._objRestBasicInfoProperty.objHeaders) && objResponses._objRestBasicInfoProperty.objHeaders.statusText === "abort" || !a24Core.isEmpty(objResponses._objRestAvatarProperty) && !a24Core.isEmpty(objResponses._objRestAvatarProperty.objHeaders) && objResponses._objRestAvatarProperty.objHeaders.statusText === "abort") {
                        return;
                    }

                    if (!a24Core.isEmpty(objResponses._objRestCandidateTypeProperty) && !a24Core.isEmpty(objResponses._objRestCandidateTypeProperty.objResponse)) {
                        var arrCandidateTypes = objResponses._objRestCandidateTypeProperty.objResponse;
                        var arrCandidateTypesDropdown = [];
                        for (var i = 0; i < arrCandidateTypes.length; i++) {
                            if (!arrCandidateTypes[i].disabled) {
                                var objCandidateTypeItem = {
                                    sValue: arrCandidateTypes[i]._id + "",
                                    sLabel: arrCandidateTypes[i].name
                                };

                                arrCandidateTypesDropdown.push(objCandidateTypeItem);
                            }
                        }

                        _this4.set("_arrCandidateTypeDropdown", arrCandidateTypesDropdown);
                    }

                    if (!a24Core.isEmpty(objResponses._objRestBasicInfoProperty) && !a24Core.isEmpty(objResponses._objRestBasicInfoProperty.objResponse)) {
                        var objData = objResponses._objRestBasicInfoProperty.objResponse;
                        _this4.set("_objBasicInfoData", objData);
                        if (!a24Core.isEmpty(_this4.get("onGetBasicInfoSuccess"))) {
                            _this4.get("onGetBasicInfoSuccess")(objData);
                        }
                        if (!a24Core.isEmpty(objResponses._objRestAvatarProperty) && !a24Core.isEmpty(objResponses._objRestAvatarProperty.objResponse)) {
                            var objAvatarData = objResponses._objRestAvatarProperty.objResponse;
                            _this4.set("_sAvatar", a24.getUserContextAvatarUrl(_this4.get("_ENV.sTriageBaseUrl"), _this4.get("_objStaffshiftCoreConstants"), objAvatarData.avatar_url));
                        } else {
                            _this4.set("_sAvatar", "");
                        }
                        _this4.prepareForDisplay(objData);
                        _this4.set("_bLoading", false);
                    } else {
                        _this4.set("_objBasicInfoData", null);
                        _this4.set("arrCandidateData", null);
                        _this4.set("_bLoading", false);
                    }
                }
            }]);
        },

        _getRestAvatar: function _getRestAvatar(funcSuccess, funcFailure) {
            this.get("objAvatarRestService").getAvatar(this, "_objAvatarGetPromise", this.get("sUserId"), funcSuccess, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
            funcFailure));
        },

        _getAvatarWithDelay: function _getAvatarWithDelay() {
            var _this5 = this;

            var funcSuccess = function funcSuccess(objResponse) {
                if (!a24Core.isEmpty(objResponse)) {
                    if (!a24Core.isEmpty(objResponse.avatar_url)) {
                        _this5.set("_sAvatar", a24.getUserContextAvatarUrl(_this5.get("_ENV.sTriageBaseUrl"), _this5.get("_objStaffshiftCoreConstants"), objResponse.avatar_url));
                        _this5.set("_sProfileIcon", "camera_alt");
                        _this5.set("_sChangeImage", _this5.get("_objCandidateProfileStringsService").getString("changeProfilePic"));
                    } else {
                        _this5.set("_sAvatar", "");
                        if (_this5.get("_iTimesGetCalled") < 4) {
                            _this5.set("_iTimesGetCalled", _this5.get("_iTimesGetCalled") + 1);
                            Ember.run.later(_this5, _this5._getAvatarWithDelay, _this5.get("_iDelayTime"));
                        } else {
                            _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objCandidateProfileStringsService").getString("avatarFailedToRetrieve"));
                            _this5.set("_sChangeImage", _this5.get("_objCandidateProfileStringsService").getString("reloadImage"));
                            _this5.set("_sProfileIcon", "refresh");
                        }
                    }
                } else {
                    _this5.set("_sAvatar", "");
                    _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objCandidateProfileStringsService").getString("avatarFailedToRetrieve"));
                    _this5.set("_sChangeImage", _this5.get("_objCandidateProfileStringsService").getString("reloadImage"));
                    _this5.set("_sProfileIcon", "refresh");
                }
            };

            var funcFailure = function funcFailure(sCode) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this5.set("_bServerError", true);
                    return;
                }
                _this5.set("_sAvatar", "");
                _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objCandidateProfileStringsService").getString("avatarFailedToRetrieve"));
                _this5.set("_sChangeImage", _this5.get("_objCandidateProfileStringsService").getString("reloadImage"));
                _this5.set("_sProfileIcon", "refresh");
                _this5.set("_bLoading", false);
            };
            this._getRestAvatar(funcSuccess, funcFailure);
        },

        _getAvatar: function _getAvatar() {
            var _this6 = this;

            var funcSuccess = function funcSuccess(objResponse) {
                if (!a24Core.isEmpty(objResponse)) {
                    if (!a24Core.isEmpty(objResponse.avatar_url)) {
                        _this6.set("_sAvatar", a24.getUserContextAvatarUrl(_this6.get("_ENV.sTriageBaseUrl"), _this6.get("_objStaffshiftCoreConstants"), objResponse.avatar_url));
                        _this6.set("_sProfileIcon", "camera_alt");
                        _this6.set("_sChangeImage", _this6.get("_objCandidateProfileStringsService").getString("changeProfilePic"));
                    } else {
                        _this6.set("_sAvatar", "");
                        _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objCandidateProfileStringsService").getString("avatarFailedToRetrieve"));
                        _this6.set("_sChangeImage", _this6.get("_objCandidateProfileStringsService").getString("reloadImage"));
                        _this6.set("_sProfileIcon", "refresh");
                    }
                } else {
                    _this6.set("_sAvatar", "");
                    _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objCandidateProfileStringsService").getString("avatarFailedToRetrieve"));
                    _this6.set("_sChangeImage", _this6.get("_objCandidateProfileStringsService").getString("reloadImage"));
                    _this6.set("_sProfileIcon", "refresh");
                }
            };

            var funcFailure = function funcFailure(sCode) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this6.set("_bServerError", true);
                    return;
                }
                _this6.set("_sAvatar", "");
                _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objCandidateProfileStringsService").getString("avatarFailedToRetrieve"));
                _this6.set("_sChangeImage", _this6.get("_objCandidateProfileStringsService").getString("reloadImage"));
                _this6.set("_sProfileIcon", "refresh");
                _this6.set("_bLoading", false);
            };
            this._getRestAvatar(funcSuccess, funcFailure);
        },

        openEditPopup: function openEditPopup() {
            var _this7 = this;

            this.clearAllErrorMessages();
            this.clearForm();

            this.set("_bShowEditPopup", true);
            if (!a24Core.isEmpty(this.get("setBreadCrumbAction"))) {
                this.get("setBreadCrumbAction")();
            }

            var objBasicInfoData = this.get("_objBasicInfoData");

            if (!a24Core.isEmpty(objBasicInfoData)) {
                this.populateInputFields(objBasicInfoData);
            }

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this7)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    _this7.set("_objOriginalData", _this7.getPlainFormValueObject());
                });
            });
        },
        populateInputFields: function populateInputFields(objData) {
            if (!a24Core.isEmpty(objData)) {
                this.get("objChildren.first_name").setValueFromOutside(objData.first_name);
                this.get("objChildren.last_name").setValueFromOutside(objData.last_name);

                if (this.get("_bAllowEditIdNumber")) {
                    this.get("objChildren.id_number").setValueFromOutside(objData.id_number);
                }

                if (!a24Core.isEmpty(objData.date_of_birth)) {
                    var objDate = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                    objDate.setDateFromRest(objData.date_of_birth, true);

                    this.get("objChildren.date_of_birth").setValueFromOutside(objDate.getDateFormat());
                }

                if (!a24Core.isEmpty(objData.title)) {
                    this.set("_objSelectedTitle", {
                        sLabel: this.get("_objCandidateProfileEnumsService").getFrontendValue("title", objData.title),
                        sValue: objData.title
                    });
                }
                if (!a24Core.isEmpty(objData.gender)) {
                    this.set("_objSelectedGender", {
                        sLabel: this.get("_objEnumsService").getFrontendValue("gender", objData.gender),
                        sValue: objData.gender
                    });
                }
                if (!a24Core.isEmpty(objData.candidate_type_id)) {
                    var objSelectedCandidateType = {
                        "sValue": objData.candidate_type_id
                    };

                    this.set("_objSelectedCandidateType", objSelectedCandidateType);
                }
            }
        },
        getPayload: function getPayload() {
            var objData = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());

            if (!a24Core.isEmpty(objData.date_of_birth)) {
                var objDate = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                objDate.setDateFromBrowser(objData.date_of_birth, a24Constants.DATE_FORMAT);

                objData.date_of_birth = objDate.getDateStringForRest(true);
            }

            return objData;
        },
        setSuccessResponse: function setSuccessResponse(sSnackbarMessage) {
            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            this.set("_bPopupLoading", false);
            this.set("_bShowEditPopup", false);
            this.getCandidateBasicInfo();
        },
        setErrorResponse: function setErrorResponse(sCode, objError, sSnackbarMessage) {
            if (sCode === "MAX_LENGTH") {
                this.setResponseError(objError.arrPath, "validationRestMaxLength");
            } else if (sCode === "MIN_LENGTH") {
                this.setResponseError(objError.arrPath, "validationRestMaxLength");
            } else if (sCode === "PATTERN" || sCode === "INVALID_FORMAT") {
                this.setResponseError(objError.arrPath, "validationRestInvalidFormat");
            } else if (sCode === "ENUM_MISMATCH") {
                this.setResponseError(objError.arrPath, "validationRestUnsupportedValue");
            } else {
                this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            }

            this.set("_bPopupLoading", false);
        },

        _hexToBase64: function _hexToBase64(sString) {
            var sFormattedString = sString.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ");

            return btoa(String.fromCharCode.apply(null, sFormattedString));
        },

        _getPayload: function _getPayload(objFile, objBlob, funcSuccess) {
            var _this8 = this;

            var objReader = this.get("_objFileReader");

            objReader.onloadend = function (objEvent) {
                if (objEvent.target.readyState === FileReader.DONE) {
                    var objSpark = new SparkMD5.ArrayBuffer(objBlob);
                    objSpark.append(objEvent.target.result);
                    var sEtag = objSpark.end();
                    var sMd5Digest = _this8._hexToBase64(sEtag);

                    var objPayload = {
                        name: "avatar",
                        metadata: {
                            //this is kb as backend uses this in kb. Note on documents its in bytes ugh...
                            file_size: parseInt(objBlob.size / 1024),
                            file_type: objBlob.type,
                            etag: sEtag,
                            md5_digest: sMd5Digest,
                            original_file_name: objFile.name
                        }
                    };

                    funcSuccess(objPayload);
                }
            };

            objReader.readAsArrayBuffer(objBlob);
        },

        _createAvatar: function _createAvatar(objFile, objBlob, funcSuccess) {
            var _this9 = this;

            this._getPayload(objFile, objBlob, function (objPayload) {
                _this9.get("_objSnackbarService").showSnackbarMessage(_this9.get("_objStringsService").getTokenString("addingItem", _this9.get("_objCandidateProfileStringsService").getString("profilePicture")));

                _this9.get("objAvatarRestService").updateAvatar(_this9, "_objAvatarPutPromise", _this9.get("sUserId"), objPayload, function (objResponse) {
                    _this9.set("_bOpenProfilePic", false);
                    _this9.set("_bProfilePicLoading", false);

                    funcSuccess(objResponse);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
                function () {
                    _this9.get("_objSnackbarService").showSnackbarMessage(_this9.get("_objCandidateProfileStringsService").getString("uploadProfilePicFailure"));

                    _this9.set("_bProfilePicLoading", false);
                }));
            });
        },

        _getXhrObject: function _getXhrObject() {
            return $.ajaxSettings.xhr();
        },

        _uploadAvatar: function _uploadAvatar(objBlob, sSignedUploadUrl, objHeaders) {
            var _this10 = this;

            this.set("_bAddAvatarUploading", true);

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objCandidateProfileStringsService").getString("uploadingProfilePic"));

            var funcXhr = function funcXhr() {
                return Ember.run(function () {
                    // get the native XmlHttpRequest object
                    var objXhr = _this10._getXhrObject();
                    // set the onprogress event handler
                    objXhr.upload.onprogress = function (objEvent) {
                        var iProgress = parseInt(objEvent.loaded / objEvent.total * 100);
                        _this10.set("_iUploadProgress", iProgress);
                    };
                    // return the customized object
                    return objXhr;
                });
            };

            this._performUpload(sSignedUploadUrl, objBlob, objBlob.type, objHeaders, funcXhr, Ember.run.bind(this, this._uploadAvatarSuccess), Ember.run.bind(this, this._uploadAvatarFailure));
        },

        _uploadAvatarSuccess: function _uploadAvatarSuccess(objErrorData, sStatus /* , objErrorThrown */) {
            // We do not want to deal with an abort service call
            if (sStatus === "abort" || a24Core.isEmpty(this)) {
                return;
            }

            this.set("_sProfileIcon", "hourglass_empty");
            this.set("_sChangeImage", this.get("_objCandidateProfileStringsService").getString("imagePreparing"));
            this.set("_sAvatar", "");

            this.set("_bAddAvatarUploading", false);
            this.set("_iUploadProgress", 0);

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objCandidateProfileStringsService").getString("uploadProfilePicSuccess"));
            Ember.run.later(this, this._getAvatarWithDelay, this.get("_iDelayTime"));
        },

        _uploadAvatarFailure: function _uploadAvatarFailure(objErrorData, sStatus /* , objErrorThrown */) {
            // We do not want to deal with an abort service call
            if (sStatus === "abort" || a24Core.isEmpty(this)) {
                return;
            }

            this.set("_bAddAvatarUploading", false);
            this.set("_iUploadProgress", 0);

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objCandidateProfileStringsService").getString("uploadProfilePicFailure"));
        },

        _performUpload: function _performUpload(sUrl, arrBlob, sContentType, objHeaders, funcXhr, funcSuccess, funcFailure) {
            /**
             * This is done manually because of the precise nature of this call and also
             * that because of how the upload is done, it is safe since the first get call
             * will handle the spamming scenario, since if user spams service call it
             * will stop the success from running thus stopping the upload calls aswell
             */
            return $.ajax({
                url: sUrl,
                type: "PUT",
                data: arrBlob,
                contentType: sContentType,
                processData: false,
                headers: objHeaders,
                xhr: funcXhr,
                success: funcSuccess,
                error: funcFailure
            });
        },

        _funcCreateObjectUrl: function _funcCreateObjectUrl(arrBlob) {
            return URL.createObjectURL(arrBlob);
        },

        _deleteAvatar: function _deleteAvatar() {
            var _this11 = this;

            this.set("_bProfilePicLoading", true);
            this.set("_bDeletePopupLoading", true);
            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objCandidateProfileStringsService").getString("removingProfilePic"));
            this.get("objAvatarRestService").deleteAvatar(this, "_objAvatarDeletePromise", this.get("sUserId"), function () {
                _this11.set("_sAvatar", "");
                _this11.set("_sProfileIcon", "camera_alt");
                _this11.set("_sChangeImage", _this11.get("_objCandidateProfileStringsService").getString("changeProfilePic"));
                _this11.get("_objSnackbarService").showSnackbarMessage(_this11.get("_objCandidateProfileStringsService").getString("removeProfilePicSuccess"));
                _this11.set("_bOpenProfilePic", false);
                _this11.set("_bProfilePicLoading", false);
                _this11.set("_bDeletePopupLoading", false);
                _this11.set("_bShowDeleteConfirm", false);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
            function (sCode) {
                if (sCode === "403" || sCode === "401") {
                    _this11.get("_objSnackbarService").showSnackbarMessage(_this11.get("_objCandidateProfileStringsService").getString("removePermissionError"));
                } else {
                    _this11.get("_objSnackbarService").showSnackbarMessage(_this11.get("_objCandidateProfileStringsService").getString("removeProfilePicFailure"));
                }

                _this11.set("_bOpenProfilePic", false);
                _this11.set("_bProfilePicLoading", false);
                _this11.set("_bDeletePopupLoading", false);
                _this11.set("_bShowDeleteConfirm", false);
            }));
        },

        actions: {
            onPopupEditConfirm: function onPopupEditConfirm() {
                var _this12 = this;

                if (!this.doFullValidation()) {
                    this.set("_bPopupLoading", true);

                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objCandidateProfileStringsService").getString("personalInfo")));

                    this.get("objBasicInfoRestService").updateBasicInfo(this, "_objBasicInfoUpdatePromise", this.get("sCandidateId"), this.getPayload(), function () {
                        _this12.setSuccessResponse(_this12.get("_objStringsService").getTokenString("successUpdateItem", _this12.get("_objCandidateProfileStringsService").getString("personalInfo")));
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                        _this12.setErrorResponse(sCode, objError, _this12.get("_objStringsService").getTokenString("failUpdateItem", _this12.get("_objCandidateProfileStringsService").getString("personalInfo")));
                    }));
                }
            },
            onPopupClose: function onPopupClose() {
                if (!this.get("_bPopupLoading")) {
                    var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));
                    if (bDataMatches) {
                        this.set("_bShowEditPopup", false);
                    } else {
                        this.set("_bShowUnsavedData", true);
                    }
                }
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowEditPopup", false);
                this.set("_bShowUnsavedData", false);
            },
            onProfilePicPopupToggle: function onProfilePicPopupToggle(bShow) {
                if (this.get("_sProfileIcon") === "refresh") {
                    this.set("_sProfileIcon", "hourglass_empty");
                    this.set("_sChangeImage", this.get("_objCandidateProfileStringsService").getString("imagePreparing"));
                    this.set("_sAvatar", "");
                    this._getAvatar();
                } else {
                    if (this.get("_bIsCandidateContext")) {
                        this.set("_bOpenProfilePic", bShow);
                    }
                }
            },
            onDeleteImage: function onDeleteImage() {
                this._deleteAvatar();
            },
            onDeleteTextClicked: function onDeleteTextClicked() {
                this.set("_bShowDeleteConfirm", true);
            },
            onUploadImage: function onUploadImage(objFile, objBlob) {
                var _this13 = this;

                this.set("_bProfilePicLoading", true);
                this._createAvatar(objFile, objBlob, function (objResponse) {
                    var objHeaders = objResponse.headers.reduce(function (objHeaders, objHeader) {
                        objHeaders[objHeader.name] = objHeader.value;
                        return objHeaders;
                    }, {});

                    _this13._uploadAvatar(objBlob, objResponse.signed_upload_url, objHeaders);
                });
            },
            onPopupDeleteConfirm: function onPopupDeleteConfirm() {
                this.set("_bDeletePopupLoading", true);
                this._deleteAvatar();
            },
            onPopupDeletePopupClose: function onPopupDeletePopupClose() {
                if (this.get("_bDeletePopupLoading")) {
                    return;
                }

                this.set("_bShowDeleteConfirm", false);
            }
        }
    });
});