define("a24-ember-lib/components/tag-component-shrink", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/templates/components/tag-component-shrink"], function (exports, _didRenderChangesMixin, _tagComponentShrink) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _tagComponentShrink.default,
        classNames: ["display-inline-block"],
        objData: null,
        objActions: null
    });
});