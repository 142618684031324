define("a24-ember-staffshift-users/services/rest-agency-group", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * This service method will make a POST call to the agency group user route, to create
         * a user
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sAgencyGroupId - The agency group id
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objPayload - The new user details
         *
         * @author Deon De Wet<deon.dewet@gmail.com>
         * @since  10 January 2018
         */
        addUser: function addUser(objCaller, sProperty, sAgencyGroupId, funcSuccess, objFailure, objPayload) {
            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, Ember.get(this, "_objUserSession"), Ember.get(this, "_objNavigation"), "POST", Ember.get(this, "sRestApiBaseUrl") + "/v1/agency-group/" + sAgencyGroupId + "/user", funcSuccess, objFailure, objPayload));
        }
    });
});