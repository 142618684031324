define("a24-ember-staffshift-candidate-availability/components/bulk-availability-update-popup", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/mixins/input-form-element", "a24-ember-staffshift-candidate-availability/templates/components/bulk-availability-update-popup"], function (exports, _didRenderChangesMixin, _inputFormElement, _bulkAvailabilityUpdatePopup) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _bulkAvailabilityUpdatePopup.default,
        bShowPopup: null,
        bPopupLoading: false,
        iNumberOfMonths: 6,
        iMonth: null,
        iYear: null,
        _objStringsService: Ember.inject.service("a24-strings"),
        _objAvailabilityStrings: Ember.inject.service("a24-strings-staffshift-availability"),
        _objFormInputStringsService: Ember.inject.service("a24-strings-form-inputs"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _bShowUnsavedData: false,
        _objSpecialPopupAction: null,
        _sCurrentDate: null,
        _objAvailabilityFormData: null,
        _objAvailabilityData: null,
        _bDisplayDates: false,
        _objMinDate: null,
        _objMaxDate: null,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_objAvailabilityData", {
                objEarly: {
                    sState: this.get("_objAvailabilityStrings").getString("unknown"),
                    sLabel: this.get("_objAvailabilityStrings").getString("unknown")
                },
                objLate: {
                    sState: this.get("_objAvailabilityStrings").getString("unknown"),
                    sLabel: this.get("_objAvailabilityStrings").getString("unknown")
                },
                objNight: {
                    sState: this.get("_objAvailabilityStrings").getString("unknown"),
                    sLabel: this.get("_objAvailabilityStrings").getString("unknown")
                }
            });

            this._setMaxMinRange();

            this.set("_objSpecialPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._popupCancel();
                }
            });

            this.set("objChildConfig", {
                "objChildren.dStart": function objChildrenDStart(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.dEnd": function objChildrenDEnd(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                    objMixin.get("arrValidation").pushObject(function () {
                        if (!a24Core.isEmpty(_this.get("objChildren.dStart.mValue")) && !a24Core.isEmpty(_this.get("objChildren.dEnd.mValue"))) {
                            var objStartDate = a24DateManager.createDate(_this.get("_objUserSession.objContextDetails.sTimezone"));
                            var objEndDate = a24DateManager.createDate(_this.get("_objUserSession.objContextDetails.sTimezone"));
                            objStartDate.setDateFromBrowser(_this.get("objChildren.dStart.mValue"), _this.get("_objLibConstants.DATE_FORMAT"));
                            objEndDate.setDateFromBrowser(objMixin.get("mValue"), _this.get("_objLibConstants.DATE_FORMAT"));

                            if (objStartDate.isAfter(objEndDate)) {
                                return _this.get("_objStringsService").getTokenString("validationDateBefore", _this.get("objChildren.dEnd.sFieldName"), _this.get("objChildren.dStart.sFieldName"));
                            }
                        }
                    });
                },
                "objChildren.weekItems": function objChildrenWeekItems(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                }
            });
        },
        lookupNextAvailabilityState: function lookupNextAvailabilityState(sState) {
            var arrOrder = [this.get("_objAvailabilityStrings").getString("unknown"), this.get("_objAvailabilityStrings").getString("available"), this.get("_objAvailabilityStrings").getString("unavailable"), this.get("_objAvailabilityStrings").getString("unknown")];

            return arrOrder[arrOrder.indexOf(sState) + 1];
        },
        _onDateChange: Ember.observer("iMonth", "iYear", function () {
            Ember.run.once(this, this._setMaxMinRange);
        }),
        _setMaxMinRange: function _setMaxMinRange() {
            var _this2 = this;

            this.set("_bDisplayDates", false);

            var objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            var sCurrentDate = objCurrentDate.getDateFormat();

            if (!a24Core.isEmpty(this.get("iMonth"))) {
                objCurrentDate.setTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_MONTHS"), this.get("iMonth"));
            }
            if (!a24Core.isEmpty(this.get("iYear"))) {
                objCurrentDate.setTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_YEARS"), this.get("iYear"));
            }

            //If month and year is not the current month then set to first day of month
            if (sCurrentDate !== objCurrentDate.getDateFormat()) {
                objCurrentDate.setTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_DATE"), 1);
            }

            this.set("_sCurrentDate", objCurrentDate.getDateFormat());

            var objMaxDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            objMaxDate.setDateFromBrowser(objCurrentDate.getDateFormat(), a24Constants.DATE_FORMAT);
            objMaxDate.addTimeToDate(a24Constants.MOMENT_TIME_MONTHS, this.get("iNumberOfMonths"));

            this.set("_objMinDate", objCurrentDate);
            this.set("_objMaxDate", objMaxDate);

            //unfortunatly needed to update the max and min ranges of the date picker
            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    _this2.set("_bDisplayDates", true);
                });
            });
        },
        _getAvailabilityFormData: function _getAvailabilityFormData() {
            var objAvailabilityFormData = this.getPlainFormValueObject();
            objAvailabilityFormData.objAvailability = this.get("_objAvailabilityData");
            return objAvailabilityFormData;
        },
        _popupCancel: function _popupCancel() {
            this.get("onPopupCloseAction")();
        },
        actions: {
            onPopupCancel: function onPopupCancel() {
                this._popupCancel();
            },
            onPopupConfirm: function onPopupConfirm() {
                this.clearAllErrorMessages();
                if (!this.doFullValidation()) {
                    var objAvailabilityFormData = this._getAvailabilityFormData();
                    this.get("onPreview")(objAvailabilityFormData);
                }
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowUnsavedData", false);
                this.get("onPopupCloseAction")();
            },
            onShiftClick: function onShiftClick(sPeriod, objHash) {
                var sState = objHash.sState;
                sState = this.lookupNextAvailabilityState(sState);
                var objNewState = {
                    sState: sState,
                    sLabel: sState
                };
                this.set("_objAvailabilityData." + sPeriod, objNewState);
            }
        }
    });
});