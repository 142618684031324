define("a24-ember-lib/components/input-dropdown", ["exports", "a24-ember-lib/templates/components/input-dropdown", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _inputDropdown, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _inputDropdown.default,
        classNames: ["md-form"],
        sFieldName: "",
        sOptionsPrompt: null,
        arrOptions: null,
        bMultiSelect: false,
        objValue: null,
        arrValues: null,
        sOptionsValueKey: "sValue",
        sOptionsLabelKey: "sLabel",
        _sInputId: null,
        _objStringsService: Ember.inject.service("a24-strings"),
        init: function init() {
            this._super.apply(this, arguments);

            // If button not empty string set it to default
            if (a24Core.isEmpty(this.get("sOptionsPrompt"))) {
                this.set("sOptionsPrompt", this.get("_objStringsService").getString("selectOption"));
            }

            var objThis = this;
            this.set("_sInputId", Ember.guidFor({}));
            if (a24Core.isEmpty(this.get("arrOptions"))) {
                this.set("arrOptions", []);
            }
            if (a24Core.isEmpty(this.get("arrValues"))) {
                this.set("arrValues", []);
            }

            Ember.run.scheduleOnce("afterRender", this, function () {
                var objDropdown = $("#" + objThis.get("_sInputId"));
                objThis.setValuesOnDropdownInput(false);
                objDropdown.material_select();
                objDropdown.change(function () {
                    objThis.getValuesOnDropdownInput(objDropdown);
                });
            });
        },
        observeValueChanges: Ember.observer("objValue", "arrValues", function () {
            this.setValuesOnDropdownInput(true);
        }),
        observeOptionsChange: Ember.observer("arrOptions.@each", "sOptionsPrompt", function () {
            Ember.run.once(this, this.setValuesAndDoAfterChange);
        }),
        setValuesAndDoAfterChange: function setValuesAndDoAfterChange() {
            var _this = this;

            //Reset values since they there are new options
            this.set("objValue", null);
            this.set("arrValues", []);
            this.setValuesOnDropdownInput(false);
            Ember.run.next(function () {
                Ember.run.scheduleOnce("afterRender", function () {
                    _this.functionAfterOptionChange();
                });
            });
        },
        functionAfterOptionChange: function functionAfterOptionChange() {
            var objDropdown = $("#" + this.get("_sInputId"));
            objDropdown.material_select("destroy");
            objDropdown.material_select();
        },
        setValuesOnDropdownInput: function setValuesOnDropdownInput(bCheckCurrentValue) {
            var objDropdown = $("#" + this.get("_sInputId"));
            var bSetValues = true;

            //If bCheckCurrentValue is true we know it is from the observers and then we need to check if the value changed
            //since if it did not then setting the value on the input again will trigger another observe and then create
            //infinite loop
            if (bCheckCurrentValue) {
                if (this.get("bMultiSelect")) {
                    var bMatch = true;
                    var arrDropdown = objDropdown.val();
                    var arrChange = this.get("arrValues");
                    if (a24Core.isEmpty(arrDropdown)) {
                        arrDropdown = [];
                    }
                    if (a24Core.isEmpty(arrChange)) {
                        arrChange = [];
                    }
                    if (arrDropdown.length === arrChange.length) {
                        for (var i = 0; i < arrDropdown.length; i++) {
                            if (arrDropdown[i] !== arrChange[i][this.get("sOptionsValueKey")]) {
                                bMatch = false;
                                break;
                            }
                        }
                    } else {
                        bMatch = false;
                    }
                    bSetValues = !bMatch;
                } else {
                    var objCurrent = this.getSelectedObject(objDropdown);
                    var objNew = this.get("objValue");
                    if (a24Core.isEmpty(objCurrent) && a24Core.isEmpty(objNew) || !a24Core.isEmpty(objCurrent) && !a24Core.isEmpty(objNew) && objCurrent[this.get("sOptionsValueKey")] === objNew[this.get("sOptionsValueKey")]) {
                        bSetValues = false;
                    }
                }
            }

            if (bSetValues) {
                if (this.get("bMultiSelect")) {
                    var arrNewValues = [];
                    var arrValues = this.get("arrValues");
                    for (var j = 0; j < arrValues.length; j++) {
                        arrNewValues.push(arrValues[j][this.get("sOptionsValueKey")]);
                    }

                    objDropdown.material_select("destroy");
                    objDropdown.val(arrNewValues);
                    objDropdown.material_select();
                } else {
                    var objNewValue = null;
                    if (!a24Core.isEmpty(this.get("objValue"))) {
                        objNewValue = this.get("objValue")[this.get("sOptionsValueKey")];
                    }

                    objDropdown.material_select("destroy");
                    objDropdown.val(objNewValue);
                    objDropdown.material_select();
                }
            }
        },
        getValuesOnDropdownInput: function getValuesOnDropdownInput(objDropdown) {
            if (this.get("bMultiSelect")) {
                this.set("arrValues", this.getSelectedObjects(objDropdown));
            } else {
                this.set("objValue", this.getSelectedObject(objDropdown));
            }
        },
        getSelectedObject: function getSelectedObject(objDropdown) {
            var sValue = objDropdown.val();
            var arrOptionsSingle = this.get("arrOptions");
            if (a24Core.isEmpty(sValue)) {
                sValue = null;
            }
            var objSelectedItem = null;

            for (var k = 0; k < arrOptionsSingle.length; k++) {
                if (sValue === arrOptionsSingle[k][this.get("sOptionsValueKey")]) {
                    objSelectedItem = arrOptionsSingle[k];
                }
            }
            return objSelectedItem;
        },
        getSelectedObjects: function getSelectedObjects(objDropdown) {
            var arrValues = objDropdown.val();
            var arrOptions = this.get("arrOptions");
            if (a24Core.isEmpty(arrValues)) {
                arrValues = [];
            }
            var arrSelectedItems = [];

            for (var i = 0; i < arrOptions.length; i++) {
                for (var j = 0; j < arrValues.length; j++) {
                    if (arrValues[j] === arrOptions[i][this.get("sOptionsValueKey")]) {
                        arrSelectedItems.push(arrOptions[i]);
                    }
                }
            }
            return arrSelectedItems;
        },
        actions: {
            onChangeOption: function onChangeOption(objEvent) {
                if (!a24Core.isEmpty(this.get("onChangeOptions"))) {
                    var arrOptions = this.getSelectedObjects($(objEvent.target));
                    this.get("onChangeOptions")(arrOptions);
                }
            }
        }
    });
});