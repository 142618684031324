define("a24-ember-candidate-profile/services/rest-agency-candidate-referee", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _sRestApiBaseUrl: Ember.computed.readOnly("_objEnv.sRestApiBaseUrl"),
        sContext: null,

        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        init: function init() {
            this._super.apply(this, arguments);

            this.set("sContext", this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY"));
        },

        /**
         * Retrieves all referee for the candidate
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id to fetch referee data for
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         * @param objQueryParams (Optional) - The query params to filter on for referee
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  08 July 2019
         */
        getReferees: function getReferees(objContext, sProperty, sCandidateId, funcSuccess, objFailure, objQueryParams) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency/" + sAgencyId + "/candidate/" + sCandidateId + "/referee");
            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, null, null, true));
        }
    });
});