define("a24-ember-candidate-profile/services/rest-agency-group-status-reason", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        sContext: null,
        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        init: function init() {
            this._super.apply(this, arguments);

            this.set("sContext", this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP"));
        },

        /**
         * Retrieves all status reasons for the agency group
         *
         * @param objContext - The caller of this function
         * @param sProperty - The Property name to get the promise object
         * @param sStatusId - The status id
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  17 August 2018
         */
        getStatusReasons: function getStatusReasons(objContext, sProperty, sStatusId, funcSuccess, objFailure) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency-group/" + sAgencyGroupId + "/status/" + sStatusId + "/reason");

            a24RestUrlConstruct.addQueryParamObject({
                name: {
                    bSortBy: true,
                    mValue: "asc"
                }
            });

            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: {
                    mValue: 1000
                }
            });

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Create a new reason for a status
         *
         * @param objContext - The caller of this function
         * @param sProperty - The Property name to get the promise object
         * @param sStatusId - The status id
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         * @param objPayload (Optional) - The payload used to create the status
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  28 August 2018
         */
        createStatusReasons: function createStatusReasons(objContext, sProperty, sStatusId, funcSuccess, objFailure, objPayload) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency-group/" + sAgencyGroupId + "/status/" + sStatusId + "/reason");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", this.get("sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objPayload));
        },

        /**
         * Update new reason for a status
         *
         * @param objContext - The caller of this function
         * @param sProperty - The Property name to get the promise object
         * @param sStatusId - The status id
         * @param sReasonId - The reason id
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         * @param objPayload (Optional) - The payload used to create the status
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  28 August 2018
         */
        updateStatusReasons: function updateStatusReasons(objContext, sProperty, sStatusId, sReasonId, funcSuccess, objFailure, objPayload) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency-group/" + sAgencyGroupId + "/status/" + sStatusId + "/reason/" + sReasonId);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "PUT", this.get("sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objPayload));
        }

    });
});