define("a24-ember-menu/components/left-menu-item", ["exports", "a24-ember-menu/templates/components/left-menu-item", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _leftMenuItem, _didRenderChangesMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
    /*
     * Default values.
    */
    layout: _leftMenuItem.default,
    classNames: ["left-menu-item", "py-2", "px-3", "d-flex", "waves-effect", "align-items-center"],
    tagName: "a",
    attributeBindings: ['sRouteName:href'],

    classNameBindings: ["bIsSelected:selected"],

    /*
     * An icon name.
    */
    sIcon: null,

    /*
     * A menu title
    */
    sTitle: null,

    /*
     * True if it is selected
    */
    bIsSelected: false,

    /*
     * An icon to display to the right
    */
    sRightIcon: null,

    /*
     * The route this menu item will link to
    */
    sRouteName: null
  });
});