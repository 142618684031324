define("a24-ember-candidate-profile/services/rest-candidate-profile-equality", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objNavigation: Ember.inject.service("navigation"),
        _objUserSession: Ember.inject.service("user-session"),

        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * This service method will be used to retrieve the the candidates equality monitoring
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The id of the candidate
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Tarun Arora <tarunarora0807@gmail.com>
         * @since  13 Nov 2017
         */
        getEquality: function getEquality(objCaller, sProperty, sCandidateId, funcSuccess, objFailure) {
            var sUrl = Ember.get(this, "sRestApiBaseUrl") + "/v1/candidate/" + sCandidateId + "/equality";

            a24RestUrlConstruct.setBaseUrlSwagger(sUrl);

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },
        updateEquality: function updateEquality(objCaller, sProperty, sCandidateId, objData, funcSuccess, objFailure) {
            var sUrl = Ember.get(this, "sRestApiBaseUrl") + "/v1/candidate/" + sCandidateId + "/equality";

            a24RestUrlConstruct.setBaseUrlSwagger(sUrl);

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "PUT", a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        }
    });
});