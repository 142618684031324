define("a24-ember-menu/components/navigation-bar", ["exports", "a24-ember-menu/templates/components/navigation-bar", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _navigationBar, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        /*
          Default values
        */
        layout: _navigationBar.default,
        tagName: "nav",

        classNames: ["navigation-bar", "d-flex", "align-items-center", "z-depth-1", "py-1"],

        /*
          @property sImageUrl
          The navbar image url.
        */
        sImageUrl: null,

        /*
          @property sRightComponent
          The component to render on the right of the navbar.
        */
        sRightComponent: null,

        /*
          Actions
        */
        actions: {
            onClickMenu: function onClickMenu() {
                Ember.get(this, "onClickMenu").apply(undefined, arguments);
            }
        }
    });
});