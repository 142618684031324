define("a24-ember-lib/components/card-datagrid-selected-item", ["exports", "a24-ember-lib/templates/components/card-datagrid-selected-item", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _cardDatagridSelectedItem, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _cardDatagridSelectedItem.default,
        classNameBindings: ["bHasSelectedItems:cover-parent"],
        arrSelectedItems: null,
        bHasSelectedItems: false,
        sSelected: "",
        _objStringsService: Ember.inject.service("a24-strings"),
        init: function init() {
            this._super.apply(this, arguments);

            if (a24Core.isEmpty(this.get("arrSelectedItems"))) {
                this.set("arrSelectedItems", []);
            }
        },
        setSelectedText: Ember.observer("arrSelectedItems", function () {
            var iSelectedItems = 0;
            if (!a24Core.isEmpty(this.get("arrSelectedItems"))) {
                iSelectedItems = this.get("arrSelectedItems").length;
                this.set("bHasSelectedItems", true);
                this.set("sSelected", iSelectedItems + " " + this.get("_objStringsService").getString("itemsSelected"));
            } else {
                this.set("bHasSelectedItems", false);
                this.set("sSelected", "");
            }
        }),
        actions: {
            clearSelected: function clearSelected() {
                this.get("arrSelectedItems").forEach(function (objDataItem) {
                    Ember.set(objDataItem, "bSelected", false);
                });
                this.set("arrSelectedItems", null);
                this.set("bHasSelectedItems", false);
            }
        }
    });
});