define("a24-ember-staffshift-users/services/a24-enums-staffshift-users", ["exports", "a24-ember-lookup/services/a24-enums-base"], function (exports, _a24EnumsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24EnumsBase.default.extend({
        _objStringsService: Ember.inject.service("a24-strings"),
        _objStaffshiftUsersStringsService: Ember.inject.service("a24-strings-staffshift-users"),

        init: function init() {
            this._super.apply(this, arguments);

            this.set("aclRoles", {
                "ADMIN": {
                    "backend": "admin",
                    "frontend": this.get("_objStaffshiftUsersStringsService").getString("admin")
                },
                "REPORTER": {
                    "backend": "reporter",
                    "frontend": this.get("_objStaffshiftUsersStringsService").getString("reporter")
                },
                "BOOKER": {
                    "backend": "booker",
                    "frontend": this.get("_objStaffshiftUsersStringsService").getString("booker")
                },
                "MANAGER": {
                    "backend": "manager",
                    "frontend": this.get("_objStaffshiftUsersStringsService").getString("manager")
                },
                "ACCOUNT": {
                    "backend": "account",
                    "frontend": this.get("_objStaffshiftUsersStringsService").getString("account")
                },
                "VACANCY_MANAGER": {
                    "backend": "vacancy_manager",
                    "frontend": this.get("_objStaffshiftUsersStringsService").getString("vacancyManager")
                },
                "COMPLIANCE": {
                    "backend": "compliance",
                    "frontend": this.get("_objStaffshiftUsersStringsService").getString("compliance")
                },
                "DATA_PROCESSOR": {
                    "backend": "data_processor",
                    "frontend": this.get("_objStaffshiftUsersStringsService").getString("dataProcessor")
                }
            });

            this.set("statuses", {
                "ENABLED": {
                    "backend": "enabled",
                    "frontend": this.get("_objStaffshiftUsersStringsService").getString("enabled")
                },
                "DISABLED": {
                    "backend": "disabled",
                    "frontend": this.get("_objStringsService").getString("disabled")
                }
            });
        }
    });
});