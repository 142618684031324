define("a24-ember-lookup/services/a24-constants-base", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    /**
     * This file is an abstraction of any constant lookup files.
     * This file is currently empty as there is no shared behaviour between constant files
     */
  });
});