define("a24-ember-lib/components/input-form-file", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/templates/components/input-form-file", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _inputFormFile, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _inputFormFile.default,
        classNames: ["md-form", "a24ErrorInputParent"],
        sPlaceholder: null,
        sInputType: "file",
        bStaticHeader: true,

        // Id for the hidden input
        _sUploadInputId: null,
        _objInputFormFileService: Ember.inject.service("input-form-file-service"),
        _objInputFormStringsService: Ember.inject.service("a24-strings-ember-lib"),
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            if (a24Core.isEmpty(this.get("sPlaceholder"))) {
                this.set("sPlaceholder", this.get("_objInputFormStringsService").getString("fileInputPlaceHolder"));
            }

            this.set("_sUploadInputId", this.get("_objInputFormFileService").getFileInputId());

            Ember.run.scheduleOnce("afterRender", function () {
                var objTextInput = $("#" + _this.get("_sInputId"));
                var objFileInput = $("#" + _this.get("_sUploadInputId"));

                objFileInput.on("change", function () {
                    _this.setValueFromOutside(_this.get("_objInputFormFileService").getFileObject(objFileInput));
                });
                objTextInput.on("keydown", function (objEvent) {
                    if (objEvent.which === 13) {
                        objEvent.preventDefault();
                        objEvent.stopPropagation();
                        objFileInput.trigger("click");
                    }
                });
                objTextInput.on("keyup", function (objEvent) {
                    if (objEvent.which === 27) {
                        // Escape key
                        objTextInput.change();
                        objTextInput.blur();
                    } else {
                        objTextInput.val(_this.get("_objInputFormFileService").getFileObject(objFileInput, true));
                    }
                });
                objTextInput.on("blur", function () {
                    _this.resetFloatingLabel(objTextInput);
                });
                objTextInput.on("click", function () {
                    objFileInput.trigger("click");
                });

                _this.resetFloatingLabel(objTextInput);
            });
        },
        resetFloatingLabel: function resetFloatingLabel(objTextInput) {
            //If the input field has no value then the label should NOT have the active class and
            //float at placeholder position
            if (!a24Core.isEmpty(objTextInput.val()) || !a24Core.isEmpty(this.get("sPlaceholder"))) {
                this.$("label").addClass("active");
            } else if (a24Core.isEmpty(objTextInput.val())) {
                this.$("label").removeClass("active");
            }
        },
        clear: function clear() {
            $("#" + this.get("_sUploadInputId")).val("");

            this.setValueFromOutside(null);
        },
        setValueFromOutside: function setValueFromOutside(objFile) {
            if (a24Core.isEmpty(objFile)) {
                objFile = null;
            }

            this.setMValueWithInternalFlag(objFile, false);

            var objTextInput = $("#" + this.get("_sInputId"));
            if (a24Core.isEmpty(objFile)) {
                objTextInput.val("");
            } else {
                objTextInput.val(objFile.name);
            }
            objTextInput.change();

            this.resetFloatingLabel(objTextInput);
        },
        actions: {
            onClearAction: function onClearAction() {
                this.clear();
            }
        }
    });
});