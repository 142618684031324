var a24RestCallHelperAgencySubdomain = {
    _funcGoToDefaultErrorPage: function(objNavigationService, objError/*, objErrorData, bNonSwaggerError*/) {
        if (objError.sCode === "IP_ADDRESS_NOT_WHITELISTED") {
            objNavigationService.navigateOutsideContext("access-denied");
        } else {
            objNavigationService.navigateToGWTPage("error-general");
        }
    },
    _funcErrorCallbackDetails: function(objFailure, sErrorCode, sStatus, objErrorThrown, objError, bNonSwaggerError) {
        if (bNonSwaggerError) {
            objFailure.funcCallback(sErrorCode, sStatus, objErrorThrown, objError.iIndex, objError.sField);
        } else {
            objFailure.funcCallback(sErrorCode, sStatus, objErrorThrown, objError);
        }
    }
};

var a24RestResponseHandlerAgencySubdomain = {
    /**
     * These params will be used by the below functions to store values for the initial call
     * to use when it returns the code.
     */
    sField: null,
    iIndex: null, // This is set to null as to not confuse it with index -1 that gets returned from the backend
    sCode: null,
    /**
     * This function is used to parse through the body of a failure response and
     * retrieve the first Error Constant based on a generic structures for the
     * response.
     *
     * @param objError - The json body of the error response
     * @param bReturn - Whether this itteration of the service call should return the object
     * @param bNonSwaggerError (Optional) - True if the error method does not follow the swagger pattern
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     * @since  09 December 2015
     *
     * @returns An object containing the Error Code, The array index and the Field for the error
     *     {
     *         sField: "fieldname",
     *         iIndex: 1,
     *         sCode: "STATUS_CODE"
     *     }
     */
    getFullErrorLookup: function(objError, bReturn, bNonSwaggerError) {

        if (bNonSwaggerError) {
            var objThis = this;

            // Get an instance of the current class
            if (objError.errors) {
                // If the object has an "errors" property

                //Store the field value
                this.sField = objError.field;

                // This is a safe way to get the first object for the set
                $.each(objError.errors, function (sKey, sValue) {
                    if (sKey !== -1) {
                        // Call this function recursively with return false
                        objThis.getFullErrorLookup(sValue, false, bNonSwaggerError);
                        return false;//Break
                    }
                });

            } else if (objError.embeddedErrors) {
                // If the object has an "embeddedErrors" property

                // Call this function recursively with return false
                this.getFullErrorLookup(objError.embeddedErrors, false, bNonSwaggerError);
            } else if (objError.embeddedErrorsInArray) {
                // If the object has an "embeddedErrors" property

                // This is a safe way to get the first object for the set
                $.each(objError.embeddedErrorsInArray, function(sKey, sValue) {
                    if (sKey !== -1) {
                        //Store the index value
                        objThis.iIndex = sKey;
                        // Call this function recursively with return false
                        objThis.getFullErrorLookup(sValue, false, bNonSwaggerError);
                        return false;//Break
                    }
                });
            } else if (typeof objError === "string") {
                // If the object is of type string

                // Store the code value
                this.sCode = objError;
            } else if (objError.exceptionCode) {
                this.sCode = objError.exceptionCode;
                this.sField = null;
                this.iIndex = null;
            }

            // If return is specified
            if (bReturn) {
                // Return all the values on a single object
                return {
                    sField: this.sField,
                    iIndex: this.iIndex,
                    sCode: this.sCode
                };
            }
        } else {
            return this.getSwaggerError(objError, bReturn, bNonSwaggerError);
        }
    }
};

if (typeof a24RestCallHelper === "undefined") {
    var a24RestCallHelper = {};
}

Object.keys(a24RestCallHelperAgencySubdomain).forEach(function(sKey) {
    a24RestCallHelper[sKey] = a24RestCallHelperAgencySubdomain[sKey];
});

if (typeof a24RestResponseHandler === "undefined") {
    var a24RestResponseHandler = {};
}

Object.keys(a24RestResponseHandlerAgencySubdomain).forEach(function(sKey) {
    a24RestResponseHandler[sKey] = a24RestResponseHandlerAgencySubdomain[sKey];
});
