define("a24-ember-lib/components/input-number", ["exports", "a24-ember-lib/templates/components/input-number", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _inputNumber, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _inputNumber.default,
        classNames: ["md-form"],
        sFieldName: "",
        iValue: null,
        bAllowFloat: false,
        bAllowNegative: false,
        iFloatPlaces: 1,
        _sValue: "",
        _sInputId: null,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.set("_sInputId", Ember.guidFor({}));

            Ember.run.scheduleOnce("afterRender", function () {
                var objTextInput = $("#" + _this.get("_sInputId"));

                if (!a24Core.isEmpty(_this.get("iValue"))) {
                    _this._setValueFromInputChange(true);
                }

                objTextInput.on("blur", function () {
                    _this.resetFloatingLabel();
                });
            });
        },
        _setValueFromInputChange: function _setValueFromInputChange(bUseIValue) {
            this.set("_bInternalChange", true);
            var objTextInput = $("#" + this.get("_sInputId"));
            var objParsedNum = null;
            if (!a24Core.isEmpty(bUseIValue) && bUseIValue) {
                objParsedNum = this._parseToNumber(this.get("iValue") + "");
            } else {
                objParsedNum = this._parseToNumber(objTextInput.val().trim());
            }

            var iInputValue = objParsedNum.iValue;
            if (a24Core.isEmpty(iInputValue) && objParsedNum.bTrailingDot) {
                this.set("_sValue", ".");
            } else if (a24Core.isEmpty(iInputValue)) {
                this.set("_sValue", "");
            } else {
                if (objParsedNum.bTrailingDot) {
                    this.set("_sValue", "" + iInputValue + ".");
                } else {
                    this.set("_sValue", "" + iInputValue);
                }
            }

            this.set("iValue", iInputValue);
            objTextInput.val(this.get("_sValue"));
            objTextInput.change();

            this.resetFloatingLabel();
            this.set("_bInternalChange", false);
        },
        _observeIValue: Ember.observer("iValue", function () {
            if (!this.get("_bInternalChange")) {
                this._setValueFromInputChange(true);
            }
        }),
        _observeSValue: Ember.observer("_sValue", function () {
            if (!this.get("_bInternalChange")) {
                this._setValueFromInputChange();
            }
        }),
        _parseToNumber: function _parseToNumber(sInputValue) {
            if (a24Core.isEmpty(sInputValue)) {
                return {
                    iValue: null,
                    bTrailingDot: false
                };
            }

            var sParsedNumber = "";
            var arrValids = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."];

            if (this.get("bAllowNegative")) {
                arrValids.push("-");
            }

            for (var i = 0; i < sInputValue.length; i++) {
                if (arrValids.indexOf(sInputValue.charAt(i)) !== -1) {
                    sParsedNumber += sInputValue.charAt(i);
                }
            }

            //Make sure that there is only one "." in the string, we keep the first one and just remove the rest.
            var arrSplit = sParsedNumber.split(".");
            if (arrSplit.length !== 1) {
                sParsedNumber = arrSplit[0] + "." + arrSplit[1];
                for (var j = 2; j < arrSplit.length; j++) {
                    sParsedNumber += arrSplit[j];
                }
            }

            if (sParsedNumber.charAt(0) === "." && !this.get("bAllowFloat")) {
                sParsedNumber = "";
            }

            if (a24Core.isEmpty(sParsedNumber)) {
                return {
                    iValue: null,
                    bTrailingDot: false
                };
            } else if (sParsedNumber === ".") {
                return {
                    iValue: null,
                    bTrailingDot: true
                };
            } else if (this.get("bAllowFloat")) {
                //Trim the string to the allowed float places.
                var iIndexOfDot = sParsedNumber.indexOf(".");
                if (iIndexOfDot !== -1) {
                    sParsedNumber = sParsedNumber.substring(0, iIndexOfDot + 1 + this.get("iFloatPlaces"));
                }

                return {
                    iValue: parseFloat(sParsedNumber),
                    bTrailingDot: sParsedNumber[sParsedNumber.length - 1] === "."
                };
            } else if (this.get("bAllowNegative")) {
                var iIndexOfNeg = sParsedNumber.indexOf("-");
                var iIndexOfOtherNeg = sParsedNumber.indexOf("-", 1);
                var sBeforeNeg = null;
                var sAfterNeg = null;
                if (iIndexOfNeg === 0) {
                    if (iIndexOfOtherNeg !== -1) {
                        sBeforeNeg = sParsedNumber.substring(0, iIndexOfOtherNeg);
                        sAfterNeg = sParsedNumber.substring(iIndexOfOtherNeg + 1, sParsedNumber.length);
                        sParsedNumber = sBeforeNeg + sAfterNeg;
                    }
                    if (sParsedNumber.length > 1) {
                        return {
                            iValue: parseInt(sParsedNumber),
                            bTrailingDot: false
                        };
                    }
                    return {
                        iValue: sParsedNumber,
                        bTrailingDot: false
                    };
                } else if (iIndexOfNeg > 0) {
                    sBeforeNeg = sParsedNumber.substring(0, iIndexOfNeg);
                    sAfterNeg = sParsedNumber.substring(iIndexOfNeg + 1, sParsedNumber.length);
                    sParsedNumber = sBeforeNeg + sAfterNeg;
                }
                return {
                    iValue: parseInt(sParsedNumber),
                    bTrailingDot: false
                };
            } else {
                return {
                    iValue: parseInt(sParsedNumber),
                    bTrailingDot: false
                };
            }
        },
        resetFloatingLabel: function resetFloatingLabel() {
            //If the input field has no value then the label should NOT have the active class and
            //float at placeholder position
            if (a24Core.isEmpty(this.get("_sValue"))) {
                this.$("label").removeClass("active");
            }
        }
    });
});