define("a24-ember-staffshift-candidate-availability/services/retrieve-shift-pdf", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * Performs a single candidate shift ajax GET call
         *
         * @param sShiftId - The Shift to retrieve
         * @param sTestFileName - The name you wish to give the PDF getting downloaded
         * @param funcSuccess - The success function
         * @param funcFailure - The failure function
         * @param objQueryParams - Any extra query params for the get list
         * @param objHeaders - Headers for the request
         *
         */
        downloadCandidateShift: function downloadCandidateShift(sShiftId, sTestFileName, funcSuccess, funcFailure, objQueryParams, objHeaders) {
            a24RestUrlConstruct.setBaseUrl("/v1/timesheet.pdf");

            if (a24Core.isEmpty(objQueryParams)) {
                objQueryParams = {};
            }

            objQueryParams["sShiftId"] = {
                mValue: sShiftId
            };

            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.downloadPdfByGET(Ember.get(this, "sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), sTestFileName, funcSuccess, funcFailure, objHeaders);
        }
    });
});