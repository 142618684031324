define("a24-ember-lib/helpers/card-datagrid-table-get-custom-val", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.cardDatagridTableGetCustomVal = cardDatagridTableGetCustomVal;


  /**
   * Get the custom value for the datagrid row and header data passed in
   *
   * @param arrParams - The array of params sent to the helper
   *
   * @author Deon De Wet <deon.dewet@a24group.com>
   * @since  30 November 2018
   */
  function cardDatagridTableGetCustomVal(arrParams) {
    return arrParams[0].funcTableCustomVal(arrParams[0], arrParams[1]);
  }

  exports.default = Ember.Helper.helper(cardDatagridTableGetCustomVal);
});