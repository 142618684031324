define("a24-ember-window/services/dom-event", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend(Ember.Evented, {
        iPageWidth: 0,
        iPageHeight: 0,
        _objTimer: null,
        init: function init() {
            this._super.apply(this, arguments);

            var objService = this;
            var objWindow = $(window);

            this.set("iPageWidth", window.innerWidth);
            this.set("iPageHeight", window.innerHeight);

            objWindow.on("resize", function () {
                Ember.run.cancel(objService.get("_objTimer"));
                objService.set("_objTimer", Ember.run.later(objService, function () {
                    if (objService !== null) {
                        objService.set("iPageWidth", window.innerWidth);
                        objService.set("iPageHeight", window.innerHeight);
                        objService.trigger("resize", window.innerWidth, window.innerHeight);
                    }
                }, 50));
            });
        },
        willDestroy: function willDestroy() {
            this._super.apply(this, arguments);
            Ember.run.cancel(this.get("_objTimer"));
            $(window).off("resize");
        }
    });
});