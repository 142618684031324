define("a24-ember-candidate-profile/components/candidate-references", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-references", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _candidateReferences, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _candidateReferences.default,
        sCandidateId: null,
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),

        init: function init() {
            this._super.apply(this, arguments);
        }

    });
});