define("a24-ember-candidate-profile/components/card-datagrid-custom-filter-consultant-role", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/mixins/input-form-element", "a24-ember-candidate-profile/templates/components/card-datagrid-custom-filter-consultant-role"], function (exports, _didRenderChangesMixin, _inputFormElement, _cardDatagridCustomFilterConsultantRole) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _cardDatagridCustomFilterConsultantRole.default,
        _bShowConsultantField: true,

        _arrQueryType: null,
        _arrConsultantRoles: null,
        _arrConsultantRolesAnyRemoved: null,
        _objGetConsultantsSuggestions: null,

        _objStringsService: Ember.inject.service("a24-strings"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),

        _objEnumEmberLibService: Ember.inject.service("a24-enums-ember-lib"),
        _bAnyIncluded: true,
        init: function init() {
            this._super.apply(this, arguments);

            this.set("_sQueryTypeOptionPrompt", this.get("_objStringsService").getString("is"));
            var arrQueryType = this.get("_objEnumEmberLibService").getList("filterOptionsType");

            var arrQueryTypeDropdown = [];
            for (var i = 0; i < arrQueryType.length; i++) {
                arrQueryTypeDropdown.push({
                    sTitle: arrQueryType[i].sLabel,
                    sValue: arrQueryType[i].sValue
                });
            }
            this.set("_arrQueryType", arrQueryTypeDropdown);

            this.set("_arrConsultantRoles", this.get("objHeaderItem.arrConsultantRoles"));
            var arrConsultantRoles = Ember.copy(this.get("_arrConsultantRoles"), true);
            if (!a24Core.isEmpty(arrConsultantRoles)) {
                // remove 'any'
                arrConsultantRoles.splice(0, 1);
                this.set("_arrConsultantRolesAnyRemoved", arrConsultantRoles);
            }

            if (!a24Core.isEmpty(this.get("objHeaderItem._objSelectedQueryType"))) {
                this.set("_bShowConsultantField", false);
            }

            var funcGetSuggestions = this.get("objHeaderItem.funcFilterGetSuggestions");

            if (a24Core.isEmpty(funcGetSuggestions)) {
                this.setGetSuggestionFunction();
            } else {
                this.set("_funcGetSuggestions", funcGetSuggestions);
            }

            this._observeAllValues();
        },
        _observeAllValues: Ember.observer("objHeaderItem._objConsultantRole", "objHeaderItem._objConsultant", "objHeaderItem._objSelectedQueryType", function () {
            if (!a24Core.isEmpty(this.get("objHeaderItem._objSelectedQueryType"))) {
                this.set("_bAnyIncluded", false);
                this.set("_bShowConsultantField", false);
            } else {
                this.set("_bAnyIncluded", true);
                this.set("_bShowConsultantField", true);
            }

            Ember.run.once(this, "_setupFilterForEdit");
            Ember.run.once(this, "_onInputChanges");
        }),
        _setupFilterForEdit: function _setupFilterForEdit() {
            var _this = this;

            //NB only input-form-??? fields that work the current way with setValueFromOutside should be in a next
            //all other inputs will bind correctly on their inits
            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    if (!a24Core.isEmpty(_this.get("objHeaderItem._objConsultant")) && a24Core.isEmpty(_this.get("objChildren.objConsultant.mValue"))) {
                        _this.get("objChildren.objConsultant").setValueFromOutside(_this.get("objHeaderItem._objConsultant"));
                    }
                });
            });
        },

        _onInputChanges: function _onInputChanges() {
            if (!a24Core.isEmpty(this.get("objHeaderItem._objConsultantRole"))) {
                if (this.get("objHeaderItem._objConsultantRole.sValue") === "*") {
                    if (!a24Core.isEmpty(this.get("objHeaderItem._objSelectedQueryType"))) {
                        this.customFilterDisplayAddButtonEvent(true);
                    } else {
                        this.customFilterDisplayAddButtonEvent(false);
                        if (!a24Core.isEmpty(this.get("objHeaderItem._objConsultant.name"))) {
                            this.customFilterDisplayAddButtonEvent(true);
                        }
                    }
                } else {
                    this.customFilterDisplayAddButtonEvent(true);
                }
            } else {
                this.customFilterDisplayAddButtonEvent(false);

                if (a24Core.isEmpty(this.get("objHeaderItem._objConsultantRole")) && !a24Core.isEmpty(this.get("objChildren.objConsultantRole"))) {
                    this.get("objChildren.objConsultantRole").clear();
                }

                if (a24Core.isEmpty(this.get("objHeaderItem._objConsultant")) && !a24Core.isEmpty(this.get("objChildren.objConsultant"))) {
                    this.get("objChildren.objConsultant").clear();
                }
            }
        },

        onInputValueChanged: function onInputValueChanged(arrPathToInputMixin) {
            var sLookup = arrPathToInputMixin.join(".");

            if (sLookup === "objChildren.objConsultant") {
                var objConsultant = this.get(sLookup + ".mValue");
                if (!a24Core.isEmpty(objConsultant)) {
                    this.set("objHeaderItem._objConsultant", objConsultant);
                } else {
                    this.get(sLookup).clearAllErrorMessages();
                    this.set("objHeaderItem._objConsultant", null);
                }
            } else if (sLookup === "objChildren.objConsultantRole") {
                var objConsultantRole = this.get(sLookup + ".mValue");
                if (!a24Core.isEmpty(objConsultantRole)) {
                    this.set("objHeaderItem._objConsultantRole", objConsultantRole);
                } else {
                    this.get(sLookup).clearAllErrorMessages();
                    this.set("objHeaderItem._objConsultantRole", null);
                }
            }
        },

        setGetSuggestionFunction: function setGetSuggestionFunction() {
            var _this2 = this;

            this.set("_funcGetSuggestions", function (sQueryString, funcOnComplete) {
                var objQuery = {
                    search_term: { mValue: sQueryString },
                    size: { mValue: 10 }
                };

                _this2.get("objHeaderItem.objConsultantAutocompleteService")._getAutocomplete(_this2, "_objGetConsultantsSuggestions", objQuery, "consultant", function (arrData) {
                    var arrResult = [];
                    // If the data is empty, ensure it's an empty array
                    if (a24Core.isEmpty(arrData.results)) {
                        arrResult = [];
                    }

                    arrData = arrData.results;
                    for (var i = 0; i < arrData.length; i++) {
                        arrResult.push({
                            name: arrData[i]
                        });
                    }

                    // Call the callback with the formatted data
                    funcOnComplete(arrResult);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    // Call the callback with empty
                    funcOnComplete([]);
                }));
            });
        }
    });
});