define("a24-ember-lib/components/input-form-week", ["exports", "a24-ember-lib/templates/components/input-form-week", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _inputFormWeek, _inputFormElement, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _inputFormWeek.default,
        classNames: ["a24ErrorInputParent", "input-form-week"],
        sInputType: "week",
        arrWeekItems: null, // the items that will display on screen
        sOptionsValueKey: "sValue",
        sOptionsLabelKey: "sLabel",
        sOptionsSelectedKey: "bSelected",
        init: function init() {
            this._super.apply(this, arguments);

            if (a24Core.isEmpty(this.get("arrWeekItems"))) {
                var arrWeekItems = [];

                var objSun = {};
                objSun[this.get("sOptionsLabelKey")] = "S";
                objSun[this.get("sOptionsValueKey")] = 0;
                var objMon = {};
                objMon[this.get("sOptionsLabelKey")] = "M";
                objMon[this.get("sOptionsValueKey")] = 1;
                var objTue = {};
                objTue[this.get("sOptionsLabelKey")] = "T";
                objTue[this.get("sOptionsValueKey")] = 2;
                var objWed = {};
                objWed[this.get("sOptionsLabelKey")] = "W";
                objWed[this.get("sOptionsValueKey")] = 3;
                var objThu = {};
                objThu[this.get("sOptionsLabelKey")] = "T";
                objThu[this.get("sOptionsValueKey")] = 4;
                var objFri = {};
                objFri[this.get("sOptionsLabelKey")] = "F";
                objFri[this.get("sOptionsValueKey")] = 5;
                var objSat = {};
                objSat[this.get("sOptionsLabelKey")] = "S";
                objSat[this.get("sOptionsValueKey")] = 6;

                arrWeekItems.push(objSun);
                arrWeekItems.push(objMon);
                arrWeekItems.push(objTue);
                arrWeekItems.push(objWed);
                arrWeekItems.push(objThu);
                arrWeekItems.push(objFri);
                arrWeekItems.push(objSat);
                this.set("arrWeekItems", arrWeekItems);
            }
        },
        clear: function clear() {
            //Sets all to false
            this.get("arrWeekItems").setEach(this.get("sOptionsSelectedKey"), false);
            this.setValueFromOutside(null, false);
        },
        setValueFromOutside: function setValueFromOutside(arrSelectedItems, bInternalProcess) {
            var arrWeekItems = this.get("arrWeekItems");
            arrWeekItems.setEach(this.get("sOptionsSelectedKey"), false);

            var arrNewSelectedItems = [];
            if (!a24Core.isEmpty(arrSelectedItems)) {
                for (var i = 0; i < arrWeekItems.length; i++) {
                    for (var j = 0; j < arrSelectedItems.length; j++) {
                        if (Ember.get(arrWeekItems[i], this.get("sOptionsValueKey")) === Ember.get(arrSelectedItems[j], this.get("sOptionsValueKey"))) {
                            arrNewSelectedItems.pushObject(arrWeekItems[i]);
                            Ember.set(arrWeekItems[i], this.get("sOptionsSelectedKey"), true);
                        }
                    }
                }
            }

            if (a24Core.isEmpty(arrNewSelectedItems)) {
                arrNewSelectedItems = null;
            }

            this.setMValueWithInternalFlag(arrNewSelectedItems, bInternalProcess);
        },
        observeOptionsChange: Ember.observer("arrWeekItems.@each", function () {
            Ember.run.once(this, this.updateInputSelectedValuesFromObserve);
        }),
        updateInputSelectedValuesFromObserve: function updateInputSelectedValuesFromObserve() {
            this.setValueFromOutside(null, true);
        },
        actions: {
            onItemSelected: function onItemSelected(objWeekItem, objEvent) {
                if (a24Core.isEmpty(objEvent) || objEvent.which === 13 || objEvent.keyCode === 13) {
                    var bPrevSelected = Ember.get(objWeekItem, this.get("sOptionsSelectedKey"));
                    Ember.set(objWeekItem, this.get("sOptionsSelectedKey"), !bPrevSelected);

                    var arrValues = [];
                    var arrPrevValues = this.get("mValue");
                    if (a24Core.isEmpty(arrPrevValues)) {
                        arrPrevValues = [];
                    }
                    arrValues.addObjects(arrPrevValues);
                    if (bPrevSelected) {
                        arrValues.removeObject(objWeekItem);
                    } else {
                        arrValues.pushObject(objWeekItem);
                    }

                    if (a24Core.isEmpty(arrValues)) {
                        arrValues = null;
                    }

                    this.setMValueWithInternalFlag(arrValues, false);
                }
            }
        }
    });
});