define("a24-ember-lib/services/card-datagrid-temporal-variable", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),

        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objEnumEmberLibService: Ember.inject.service("a24-enums-ember-lib"),

        _objUnitMap: null,

        convertQueryToTemporalVariables: function convertQueryToTemporalVariables(objQuery, objDatagridConfig) {
            if (a24Core.isEmpty(this.get("_objUnitMap"))) {
                this.set("_objUnitMap", {
                    days: "d",
                    months: "m",
                    years: "y"
                });
            }

            this._convertQueryAndOrToTemporal(objQuery.and, objDatagridConfig);
            return objQuery;
        },

        _convertQueryAndOrToTemporal: function _convertQueryAndOrToTemporal(arrQuery, objDatagridConfig) {
            var _this = this;

            $.each(arrQuery, function (iKey, objQueryItem) {
                if (!a24Core.isEmpty(objQueryItem.and)) {
                    _this._convertQueryAndOrToTemporal(objQueryItem.and, objDatagridConfig);
                } else if (!a24Core.isEmpty(objQueryItem.or)) {
                    _this._convertQueryAndOrToTemporal(objQueryItem.or, objDatagridConfig);
                } else {
                    _this._convertQueryItemToTemporal(objQueryItem, objDatagridConfig, iKey);
                }
            });
        },

        _convertQueryItemToTemporal: function _convertQueryItemToTemporal(objQueryItem, objDatagridConfig, iQueryIndex) {
            var _this2 = this;

            var arrQueryItemKeys = Object.keys(objQueryItem);
            arrQueryItemKeys.forEach(function (sQueryItemProp, iItemIndex) {
                $.each(objQueryItem[sQueryItemProp].contains, function (iQueryKey, objEachQuery) {
                    var arrEachQueryKeys = Object.keys(objEachQuery);
                    arrEachQueryKeys.forEach(function (sEachQueryProp) {
                        $.each(objDatagridConfig.arrHeaderConfig, function (iKey, objHeaderItem) {
                            var arrProps = objHeaderItem.sProperty.split("___");
                            if (sQueryItemProp === arrProps[0]) {
                                if (sEachQueryProp === arrProps[1]) {
                                    if (objHeaderItem.bTemporal) {
                                        Ember.set(objEachQuery, sEachQueryProp, _this2._convertToTemporal(objHeaderItem, objEachQuery[sEachQueryProp], false));
                                    }
                                } else {
                                    if (objHeaderItem.bFilterSecondary || objHeaderItem.bFilterAnd || objHeaderItem.bFilterOr || objHeaderItem.sFilterType === "custom") {
                                        if (objHeaderItem.bFilterSecondary) {
                                            var arrSecondaries = objDatagridConfig["arrFilterSecondary_" + objHeaderItem.sProperty];
                                            $.each(arrSecondaries, function (iSecKey, objSecondaryItem) {
                                                if (objSecondaryItem.sProperty === sEachQueryProp && objSecondaryItem.bTemporal) {
                                                    var iIndex = objHeaderItem.mFilterValue.length > 1 ? iQueryIndex : iItemIndex;

                                                    var objFilterItem = objHeaderItem.mFilterValue[iIndex];
                                                    $.each(objFilterItem.arSec, function (iFilterKey, objFilterItem) {
                                                        if (!a24Core.isEmpty(objFilterItem) && objFilterItem.sProp === sEachQueryProp) {
                                                            Ember.set(objEachQuery, sEachQueryProp, _this2._convertToTemporal(objFilterItem, objEachQuery[sEachQueryProp], true));
                                                        }
                                                    });
                                                }
                                            });
                                        } else {
                                            $.each(objHeaderItem.mFilterValue, function (iKey, objFilter) {
                                                Ember.set(objEachQuery, sEachQueryProp, _this2._convertToTemporal(objFilter, objEachQuery[sEachQueryProp], true));
                                            });
                                        }
                                    }
                                }
                            }
                        });
                    });
                });
            });
        },

        _convertToTemporal: function _convertToTemporal(objFilter, objQuery, bHasDays) {
            var objUnitMap = null;
            if (a24Core.isEmpty(this.get("_objUnitMap"))) {
                objUnitMap = {
                    days: "d",
                    months: "m",
                    years: "y"
                };
                this.set("_objUnitMap", objUnitMap);
            } else {
                objUnitMap = this.get("_objUnitMap");
            }
            var sUnit = objFilter.sFilterValue.split("_")[1];
            var iDays = 0;
            if (bHasDays) {
                iDays = objFilter.iDays;
            } else {
                var objFromDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                var objToDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                if (objFilter.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED_GRACE.backend"), 0) === 0) {
                    objFromDate.setDateFromBrowser(objFilter.sFilterFromValue, this.get("_objLibConstants.DATE_FORMAT"));

                    iDays = Math.round(objToDate.difference(objFromDate, sUnit, true));
                } else if (objFilter.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_WILL_NOT_EXPIRE.backend"), 0) === 0) {
                    objFromDate.setDateFromBrowser(objFilter.sFilterFromValue, this.get("_objLibConstants.DATE_FORMAT"));

                    iDays = Math.round(objFromDate.difference(objToDate, sUnit, true));
                } else if (objFilter.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_WITHIN_LAST.backend"), 0) === 0) {
                    objFromDate.setDateFromBrowser(objFilter.sFilterFromValue, this.get("_objLibConstants.DATE_FORMAT"));
                    objToDate.setDateFromBrowser(objFilter.sFilterToValue, this.get("_objLibConstants.DATE_FORMAT"));

                    iDays = Math.round(objToDate.difference(objFromDate, sUnit, true));
                } else if (objFilter.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_AT_LEAST.backend"), 0) === 0) {
                    objToDate.setDateFromBrowser(objFilter.sFilterToValue, this.get("_objLibConstants.DATE_FORMAT"));

                    iDays = Math.round(objFromDate.difference(objToDate, sUnit, true));
                }
            }
            if (objFilter.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED.backend")) {
                Ember.set(objQuery, "greater_than_equal", "{{CURRENT_DATE}}");
            } else if (objFilter.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED_GRACE.backend"), 0) === 0) {
                Ember.set(objQuery, "greater_than_equal", "{{CURRENT_DATE}} - " + iDays + objUnitMap[sUnit]);
            } else if (objFilter.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_WILL_NOT_EXPIRE.backend"), 0) === 0) {
                Ember.set(objQuery, "greater_than_equal", "{{CURRENT_DATE}} + " + iDays + objUnitMap[sUnit]);
            } else if (objFilter.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_WITHIN_LAST.backend"), 0) === 0) {
                Ember.set(objQuery, "less_than_equal", "{{CURRENT_DATE}}");
                Ember.set(objQuery, "greater_than_equal", "{{CURRENT_DATE}} - " + iDays + objUnitMap[sUnit]);
            } else if (objFilter.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_AT_LEAST.backend"), 0) === 0) {
                Ember.set(objQuery, "less_than_equal", "{{CURRENT_DATE}} - " + iDays + objUnitMap[sUnit]);
            }

            return objQuery;
        }

    });
});