/* CHARACTER COUNTER */

(function ($) {

    $.fn.characterCounter = function () {
        return this.each(function () {

            var itHasLengthAttribute = $(this).attr('length') !== undefined;

            if (itHasLengthAttribute) {
                $(this).on('input', updateCounter);
                $(this).on('focus', updateCounter);
                $(this).on('blur', removeCounterElement);

                addCounterElement($(this));
            }

        });
    };

    function updateCounter() {
        var maxLength = +$(this).attr('length'),
            actualLength = +$(this).val().trim().length, //A24Mod added trim
            isValidLength = actualLength <= maxLength;

        $(this).parent().find('span[class="character-counter"]')
            .html(actualLength + '/' + maxLength);

        addInputStyle(isValidLength, $(this));
    }

    function addCounterElement($input) {
        var $counterElement = $('<span/>')
            .addClass('character-counter')
            .css('float', 'right')
            .css('font-size', '0.75rem') //A24Mod, 0.75rem = 12px
            .css('height', 1);

        $input.parent().append($counterElement);
    }

    function removeCounterElement() {
        $(this).parent().find('span[class="character-counter"]').html('');
    }

    function addInputStyle(isValidLength, $input) {
        var inputHasInvalidClass = $input.hasClass('invalid');
        if (isValidLength && inputHasInvalidClass) {
            $input.removeClass('invalid');
        } else if (!isValidLength && !inputHasInvalidClass) {
            $input.removeClass('valid');
            $input.addClass('invalid');
        }
    }

    // A24MOD removed this
    // $(document).ready(function () {
    //     $('input, textarea').characterCounter();
    // });

}(jQuery));
