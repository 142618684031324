define("a24-ember-candidate-profile/services/rest-agency-candidate-notifications", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),
        sRestApiBaseUrl: Ember.computed.readOnly("_objEnv.sRestApiBaseUrl"),

        /**
         * Route for sending an agency notification to a candidate
         *
         * @param objContext - The caller of this function
         * @param sProperty - The property name to get the promise object
         * @param sCandidateId - The candidate id of candidate to send to
         * @param objData - The data for the notification
         * @param funcSuccess - The success function
         * @param objFailure - The object that will deal with failed service calls
          * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  04 September 2020
         */
        sendNotification: function sendNotification(objContext, sProperty, sCandidateId, objData, funcSuccess, objFailure) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency/" + sAgencyId + "/candidate/" + sCandidateId + "/notifications");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", this.get("sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        }
    });
});