define("a24-ember-staffshift-candidate-availability/services/agency-availability", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        sContext: null,

        /*
          Services
        */
        _objNavigation: Ember.inject.service("navigation"),
        _objUserSession: Ember.inject.service("user-session"),
        _objAvailabilityService: Ember.inject.service("availability"),
        _objAvailabilityStrings: Ember.inject.service("a24-strings-staffshift-availability"),
        _objConstantsEmberLibService: Ember.inject.service("a24-constants-ember-lib"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),

        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        init: function init() {
            this._super.apply(this, arguments);

            this.set("sContext", this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY"));
        },

        /*
          Functions
        */
        /**
         * Performs a single availability ajax call and formats the availability data
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id to fetch availability data for
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         * @param objQueryParams - Any extra query params for the get list
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since 13 May 2020
         */
        getFormattedAvailability: function getFormattedAvailability(objCaller, sProperty, sCandidateId, iCurrentMonth, iCurrentYear, funcSuccess, objFailure, objQueryParams) {

            var objThis = this;
            var funcSuccessFormatData = function funcSuccessFormatData(arrData) {
                funcSuccess(objThis.get("_objAvailabilityService").formatAvailabilityData(arrData, iCurrentMonth, iCurrentYear));
            };

            return this.getAvailability(objCaller, sProperty, sCandidateId, funcSuccessFormatData, objFailure, objQueryParams);
        },

        /**
         * Performs a single availability ajax call
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id to fetch availability data for
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         * @param objQueryParams - Any extra query params for the get list
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since 13 May 2020
         */
        getAvailability: function getAvailability(objCaller, sProperty, sCandidateId, funcSuccess, objFailure, objQueryParams) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/candidate/" + sCandidateId + "/availability");
            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, Ember.get(this, "_objUserSession"), Ember.get(this, "_objNavigation"), "GET", Ember.get(this, 'sRestApiBaseUrl') + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        updateAvailability: function updateAvailability(objCaller, sProperty, sCandidateId, funcSuccess, objFailure, objPayload) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/candidate/" + sCandidateId + "/availability");

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, Ember.get(this, "_objUserSession"), Ember.get(this, "_objNavigation"), "POST", Ember.get(this, 'sRestApiBaseUrl') + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objPayload));
        }
    });
});