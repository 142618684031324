define("a24-ember-lib/helpers/equalsOr", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.equalsOr = equalsOr;


    /**
     * This function will return whether the first param passed in matches any other param
     *
     * @param arrParams - The array of params sent to the helper
     *
     * @author Deon De Wet<deon.dewet@a24group.com>
     * @since  17 Sep 2019
     */
    function equalsOr(arrParams) {
        for (var i = 1; i < arrParams.length; i++) {
            if (arrParams[0] === arrParams[i]) {
                return true;
            }
        }

        return false;
    }

    exports.default = Ember.Helper.helper(equalsOr);
});