define("a24-ember-candidate-profile/components/candidate-position-held", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-position-held", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _candidatePositionHeld, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _candidatePositionHeld.default,
        objCandidatePositionHeldRestService: null,
        classNames: ["candidate-position-held"],
        sCandidateId: null,

        _bLoading: false,
        _arrPositionHeld: null,
        _objChartData: null,
        _objChartOptions: null,
        _arrReloadActions: null,
        _objCandidatePositionHeldServicePromise: null,

        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objRestEntityActionSection: Ember.inject.service("rest-entity-action-section"),
        _bNoUpdatePermission: null,
        _sPopupMessage: null,
        _bShowConfirmation: false,
        _sCurrentCheckBoxLookup: null,
        _bPopupLoading: false,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.getPositionsHeld();
                }
            }]);

            this.doServiceCalls();
        },
        doServiceCalls: function doServiceCalls() {
            var _this2 = this;

            var objEntityActionPromise = this.get("_objRestEntityActionSection").getEntityActionsForSection(this, "_objEntityActionPromise", "position-held", function () {}, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                _this2.set("_bNoUpdatePermission", true);
            }));

            var objPositionHeldPromise = this.get("objCandidatePositionHeldRestService").getPositionsHeld(this, "_objCandidatePositionHeldServicePromise", this.get("sCandidateId"), function () {}, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                _this2.set("_bServerError", true);
            }), {
                items_per_page: {
                    mValue: 1000
                },
                overall_duration: {
                    mValue: "desc",
                    bSortBy: true
                }
            });

            var objServiceCalls = {
                _objRestCandidatePositionHeldProperty: objPositionHeldPromise,
                _objRestEntityActionProperty: objEntityActionPromise
            };

            var arrRequiredResponses = ["_objRestCandidatePositionHeldProperty", "_objRestEntityActionProperty"];

            this.set("_bLoading", true);
            this.set("_bServerError", false);
            Ember.copy(a24RSVP).configure(this, objServiceCalls, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    if (!a24Core.isEmpty(objResponses._objRestCandidatePositionHeldProperty) && !a24Core.isEmpty(objResponses._objRestCandidatePositionHeldProperty.objHeaders) && objResponses._objRestCandidatePositionHeldProperty.objHeaders.statusText === "abort" || !a24Core.isEmpty(objResponses._objRestEntityActionProperty) && !a24Core.isEmpty(objResponses._objRestEntityActionProperty.objHeaders) && objResponses._objRestEntityActionProperty.objHeaders.statusText === "abort") {
                        return;
                    }

                    if (!a24Core.isEmpty(objResponses._objRestEntityActionProperty) && !a24Core.isEmpty(objResponses._objRestEntityActionProperty.objResponse)) {
                        var arrResponse = objResponses._objRestEntityActionProperty.objResponse;
                        var objUpdatePermission = arrResponse.find(function (objEntityAction) {
                            var objUpdateAction = objEntityAction.actions.find(function (objAction) {
                                return objAction.action === "update_status";
                            });

                            if (!a24Core.isEmpty(objUpdateAction) && objUpdateAction.allowed) {
                                return true;
                            }

                            return false;
                        });

                        if (!a24Core.isEmpty(objUpdatePermission)) {
                            _this2.set("_bNoUpdatePermission", false);
                        } else {
                            _this2.set("_bNoUpdatePermission", true);
                        }
                    }

                    if (!a24Core.isEmpty(objResponses._objRestCandidatePositionHeldProperty) && !a24Core.isEmpty(objResponses._objRestCandidatePositionHeldProperty.objResponse)) {
                        var arrData = objResponses._objRestCandidatePositionHeldProperty.objResponse;

                        _this2.setPositionHeldData(arrData);
                    } else if (a24Core.isEmpty(objResponses._objRestCandidatePositionHeldProperty.objResponse)) {
                        _this2.set("_bLoading", false);
                    }
                }
            }]);
        },
        setPositionHeldData: function setPositionHeldData(arrData) {
            if (a24Core.isEmpty(arrData)) {
                this.set("_arrPositionHeld", []);
                this.setupChartData([]);
            } else {
                var sActiveBackendValue = this.get("_objCandidateProfileEnumsService.positionHeldWorkSuggestStatus.ACTIVE.backend");
                arrData.forEach(function (objData) {
                    Ember.set(objData, "bActive", objData.work_suggestion_status === sActiveBackendValue);
                    Ember.set(objData, "bLoading", false);
                    Ember.set(objData, "sProperty", "workSuggest" + objData._id);
                });
                this.set("_arrPositionHeld", arrData);

                this.setupChartData(arrData);
            }

            this.set("_bLoading", false);
        },
        getPositionsHeld: function getPositionsHeld() {
            var _this3 = this;

            this.set("_bLoading", true);
            this.set("_bServerError", false);

            this.get("objCandidatePositionHeldRestService").getPositionsHeld(this, "_objCandidatePositionHeldServicePromise", this.get("sCandidateId"), function (arrData) {
                _this3.setPositionHeldData(arrData);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                _this3.set("_bLoading", false);
                _this3.set("_bServerError", true);
            }), {
                items_per_page: {
                    mValue: 1000
                },
                overall_duration: {
                    mValue: "desc",
                    bSortBy: true
                }
            });
        },
        setupChartData: function setupChartData(arrData) {
            var _this4 = this;

            var arrChartLabels = arrData.map(function (objData) {
                return [objData.job_family_name, objData.job_title_name];
            });

            var iSecondsInAYear = 60 * 60 * 24 * 365;
            var objCandidateProfileStringsService = this.get("_objCandidateProfileStringsService");

            this.set("_objChartData", {
                labels: arrChartLabels,
                datasets: [{
                    label: objCandidateProfileStringsService.getString("verified"),
                    backgroundColor: this.get("_objLibConstants.COLOR_SUCCESS"),
                    data: arrData.map(function (objData) {
                        var fValue = 0.0;
                        if (!a24Core.isEmpty(objData.states)) {
                            var objVerified = objData.states.findBy("status", _this4.get("_objCandidateProfileEnumsService.positionHeldStatus.VERIFIED.backend"));
                            if (!a24Core.isEmpty(objVerified)) {
                                fValue = objVerified.total_time / iSecondsInAYear;
                            }
                        }
                        return fValue;
                    })
                }, {
                    label: objCandidateProfileStringsService.getString("unverified"),
                    backgroundColor: this.get("_objLibConstants.COLOR_WARNING"),
                    data: arrData.map(function (objData) {
                        var fValue = 0.0;
                        if (!a24Core.isEmpty(objData.states)) {
                            var objVerified = objData.states.findBy("status", _this4.get("_objCandidateProfileEnumsService.positionHeldStatus.VERIFIED.backend"));
                            var objUnverified = objData.states.findBy("status", _this4.get("_objCandidateProfileEnumsService.positionHeldStatus.UNVERIFIED.backend"));
                            if (!a24Core.isEmpty(objVerified) && !a24Core.isEmpty(objUnverified)) {
                                fValue = (objVerified.total_time + objUnverified.total_time) / iSecondsInAYear;
                            } else if (!a24Core.isEmpty(objUnverified)) {
                                fValue = objUnverified.total_time / iSecondsInAYear;
                            }
                        }
                        return fValue;
                    })
                }],
                borderWidth: 0
            });

            this.set("_objChartOptions", {
                tooltips: {
                    callbacks: {
                        label: function label(objTooltipItem, objData) {
                            var iValue = objTooltipItem.xLabel;

                            if (objTooltipItem.datasetIndex === 1) {
                                iValue = objTooltipItem.xLabel - objData.datasets[0].data[objTooltipItem.index];
                            }

                            if (iValue <= 0) {
                                return;
                            }

                            var iYear = parseInt(iValue);
                            var iMonth = Math.round(12 * (iValue - iYear));
                            var sLabel = objData.datasets[objTooltipItem.datasetIndex].label + ": ";

                            if (iYear <= 0 && iMonth <= 0) {
                                return sLabel + objCandidateProfileStringsService.getString("lessThanAMonth");
                            }

                            var sYear = null;
                            if (iYear > 1) {
                                sYear = objCandidateProfileStringsService.getString("years").toLowerCase();
                            } else {
                                sYear = objCandidateProfileStringsService.getString("year").toLowerCase();
                            }

                            var sMonth = null;
                            if (iMonth > 1) {
                                sMonth = objCandidateProfileStringsService.getString("months").toLowerCase();
                            } else {
                                sMonth = objCandidateProfileStringsService.getString("month").toLowerCase();
                            }

                            if (!a24Core.isEmpty(iYear) && iYear > 0) {
                                sLabel += iYear + " " + sYear;
                            }

                            if (!a24Core.isEmpty(iMonth) && iMonth > 0) {
                                if (!a24Core.isEmpty(iYear) && iYear > 0) {
                                    sLabel += ", ";
                                }
                                sLabel += iMonth + " " + sMonth;
                            }

                            return sLabel;
                        }
                    }
                },
                title: {
                    display: true,
                    position: "bottom",
                    text: objCandidateProfileStringsService.getString("totalYearsOfExperience")
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: true
                        },
                        ticks: {
                            beginAtZero: true,
                            callback: function callback(iValue /*, index, values*/) {
                                if (iValue !== 0) {
                                    // check if more than 5 decimal places, limit to 5 if more
                                    if (iValue.toString().includes(".") && iValue.toString().split(".")[1].length > 5) {
                                        return iValue.toFixed(5);
                                    } else {
                                        return iValue;
                                    }
                                }
                            }
                        }
                    }],
                    yAxes: [{
                        stacked: true,
                        barThickness: 30,
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                },
                responsive: true,
                maintainAspectRatio: false
            });
        },

        onInputValueChanged: function onInputValueChanged(arrChildRef) {
            if (!a24Core.isEmpty(arrChildRef)) {
                var sLookup = arrChildRef.join(".");
                var objCheckbox = this.get(sLookup + ".mCheckboxValue");
                this.set("_sCurrentCheckBoxLookup", sLookup);

                var bActive = !objCheckbox.bActive;
                // update checkbox
                this.set(sLookup + ".mCheckboxValue.bActive", bActive);

                this.set("_sPopupMessage", this.get("_objCandidateProfileStringsService").getHTMLTokenString("positionHeldSetToActiveMessage", objCheckbox.job_family_name + " - " + objCheckbox.job_title_name));
                if (!bActive) {
                    this.set("_sPopupMessage", this.get("_objCandidateProfileStringsService").getHTMLTokenString("positionHeldSetToInactiveMessage", objCheckbox.job_family_name + " - " + objCheckbox.job_title_name));
                }

                this.set("_bShowConfirmation", true);
            }
        },
        actions: {
            onPopupConfirm: function onPopupConfirm() {
                var _this5 = this;

                var sLookup = this.get("_sCurrentCheckBoxLookup");
                var objCheckbox = this.get(sLookup + ".mCheckboxValue");

                var bActive = objCheckbox.bActive;

                var sActive = this.get("_objCandidateProfileEnumsService.positionHeldWorkSuggestStatus.ACTIVE.backend");
                if (!bActive) {
                    sActive = this.get("_objCandidateProfileEnumsService.positionHeldWorkSuggestStatus.INACTIVE.backend");
                }

                var objPayload = {
                    "action": sActive
                };

                this.set("_bPopupLoading", true);

                this.get("objCandidatePositionHeldRestService").updatePositionsHeld(this, null, this.get("sCandidateId"), objCheckbox.job_family_id, objCheckbox.job_title_id, objPayload, function () {
                    _this5.set("_bPopupLoading", false);
                    // close popup
                    _this5.set("_bShowConfirmation", false);

                    _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("successUpdateItem", _this5.get("_objCandidateProfileStringsService").getString("positionHeld")));
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("failUpdateItem", _this5.get("_objCandidateProfileStringsService").getString("positionHeld")));
                    _this5.set("_bPopupLoading", false);
                }));
            },
            onPopupCancel: function onPopupCancel() {
                var sLookup = this.get("_sCurrentCheckBoxLookup");
                var objCheckbox = this.get(sLookup + ".mCheckboxValue");
                var bActive = objCheckbox.bActive;
                this.set("_bPopupLoading", false);

                // update checkbox
                this.set(sLookup + ".mCheckboxValue.bActive", !bActive);

                this.set("_bShowConfirmation", false);
            }
        }

    });
});