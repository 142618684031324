define("a24-ember-candidate-profile/components/candidate-languages", ["exports", "a24-ember-candidate-profile/templates/components/candidate-languages", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/mixins/input-form-element"], function (exports, _candidateLanguages, _didRenderChangesMixin, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _candidateLanguages.default,
        objLanguagesRestService: null,
        sCandidateId: "",
        _bLoading: true,
        _arrLanguages: null,
        _objLanguagesGetPromise: null,
        _objAddLanguageResponse: null,
        _objEditLanguageResponse: null,
        _objRemoveLanguageResponse: null,
        _arrProficiencyOrder: null,
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objSpecialAddEditPopupAction: null,
        _bShowLanguagePopup: false,
        _bPopupLoading: false,
        _bShowUnsavedData: false,
        _bShowConfirmRemoveData: false,
        _bLoadingRemovePopup: false,

        _bServerError: false,
        _arrReloadActions: null,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.getLanguageDetails();
                }
            }]);

            if (a24Core.isEmpty(this.get("_arrLanguages"))) {
                this.set("_arrLanguages", []);
            }

            this.set("_objSpecialAddEditPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onPopupAddEditCancel");
                }
            });

            this.set("_arrHeaderActions", [{
                sTitle: this.get("_objStringsService").getString("add"),
                sIcon: "add",
                executeCardAction: function executeCardAction() {
                    _this.setAddEditPopup();
                }
            }]);

            this.set("objChildConfig", {
                "objChildren.code": function objChildrenCode(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.proficiency": function objChildrenProficiency(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                }
            });

            // Set the order of the of the language proficiency
            this.set("_arrProficiencyOrder", [this.get("_objCandidateProfileEnumsService").getFrontendValue("languageProficiency", "native"), this.get("_objCandidateProfileEnumsService").getFrontendValue("languageProficiency", "read_write_speak"), this.get("_objCandidateProfileEnumsService").getFrontendValue("languageProficiency", "limited")]);
            this.getLanguageDetails();

            this.set("_arrLanguageOptions", this.get("_objCandidateProfileEnumsService").getList("language"));
            this.set("_arrProficiencyOptions", this.get("_objCandidateProfileEnumsService").getList("languageProficiency"));
        },
        prepareLanguageForDisplay: function prepareLanguageForDisplay(arrLanguages) {
            var _this2 = this;

            // Iterate over languages to build up an array of languages using locale values
            var arrLanguageLocale = arrLanguages.map(function (objLanguage) {
                return {
                    sProficiency: _this2.get("_objCandidateProfileEnumsService").getFrontendValue("languageProficiency", objLanguage.proficiency),
                    sLanguage: _this2.get("_objCandidateProfileEnumsService").getFrontendValue("language", objLanguage.code),
                    sLanguageId: objLanguage._id,
                    sProficiencyBackend: objLanguage.proficiency,
                    sLanguageBackend: objLanguage.code
                };
            });

            // We sort the languages by proficiency and then by language
            arrLanguageLocale.sort(function (a, b) {
                // Get the index of the proficiency order
                var iProfIndexA = _this2.get("_arrProficiencyOrder").indexOf(a.sProficiency);
                var iProfIndexB = _this2.get("_arrProficiencyOrder").indexOf(b.sProficiency);

                // Sort items based on proficiency order
                if (iProfIndexA < iProfIndexB) {
                    return -1;
                } else if (iProfIndexA > iProfIndexB) {
                    return 1;
                }
                // Sort item based on language
                if (a.sLanguage < b.sLanguage) {
                    return -1;
                } else if (a.sLanguage > b.sLanguage) {
                    return 1;
                }
                return 0;
            });

            var objThis = this;
            var funcCardActionEditExecute = function funcCardActionEditExecute(sId) {
                return function () {
                    objThis.setAddEditPopup(sId);
                };
            };
            var funcCardActionRemoveExecute = function funcCardActionRemoveExecute(sId) {
                return function () {
                    objThis.removeLanguage(sId);
                };
            };

            var arrItems = [];
            // Create items for display on card.
            for (var i = 0; i < arrLanguageLocale.length; i++) {

                var objLanguage = arrLanguageLocale[i];

                var arrActions = [];
                arrActions.push({
                    sTitle: this.get("_objStringsService").getString("edit"),
                    sIcon: "edit",
                    executeCardAction: funcCardActionEditExecute(objLanguage.sLanguageId)
                });
                arrActions.push({
                    sTitle: this.get("_objStringsService").getString("remove"),
                    sIcon: "delete",
                    executeCardAction: funcCardActionRemoveExecute(objLanguage.sLanguageId)
                });

                arrItems.push({
                    sLabel: objLanguage.sProficiency,
                    sValue: objLanguage.sLanguage,
                    sIcon: "language",
                    arrActions: arrActions,
                    sLanguageId: objLanguage.sLanguageId,
                    sProficiencyBackend: objLanguage.sProficiencyBackend,
                    sLanguageBackend: objLanguage.sLanguageBackend
                });
            }

            return arrItems;
        },
        setAddEditPopup: function setAddEditPopup(sLanguageId) {
            var _this3 = this;

            this.clearAllErrorMessages();
            this.clearForm();
            this.set("_bPopupLoading", false);

            this.set("_sHeaderPopup", this.get("_objCandidateProfileStringsService").getString("addLanguage"));
            this.set("_bIsAdd", true);

            if (!a24Core.isEmpty(sLanguageId)) {
                // set inputs based on id
                this.set("_sHeaderPopup", this.get("_objCandidateProfileStringsService").getString("editLanguage"));
                this.set("_bIsAdd", false);
                this.set("_sLanguageId", sLanguageId);

                this.populateInputFields(sLanguageId);
            }

            this.set("_bShowLanguagePopup", true);
            if (!a24Core.isEmpty(this.get("setBreadCrumbAction"))) {
                this.get("setBreadCrumbAction")();
            }

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this3)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    _this3.set("_objOriginalData", _this3.getPlainFormValueObject());
                });
            });
        },
        removeLanguage: function removeLanguage(sLanguageId) {
            this.set("_sLanguageId", sLanguageId);
            this.set("_bLoadingRemovePopup", false);
            this.set("_bShowConfirmRemoveData", true);
        },
        getLanguageDetails: function getLanguageDetails() {
            var _this4 = this;

            this.set("_bLoading", true);
            this.set("_bServerError", false);

            var sCandidateId = this.get("sCandidateId");

            this.get("objLanguagesRestService").getLanguages(this, "_objLanguagesGetPromise", sCandidateId, function (arrData) {
                if (!a24Core.isEmpty(arrData)) {
                    _this4.set("_arrLanguages", _this4.prepareLanguageForDisplay(arrData));
                } else {
                    _this4.set("_arrLanguages", []);
                }
                _this4.set("_bLoading", false);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this4.set("_bServerError", true);
                    return;
                }

                // Clear data
                _this4.set("_arrLanguages", []);
                _this4.set("_bLoading", false);
            }));
        },
        populateInputFields: function populateInputFields(sId) {
            var arrLanguages = this.get("_arrLanguages");

            if (!a24Core.isEmpty(sId) && !a24Core.isEmpty(arrLanguages)) {
                for (var i = 0; i < arrLanguages.length; i++) {
                    if (arrLanguages[i].sLanguageId === sId) {
                        this.set("objSelectedLanguage", {
                            sValue: arrLanguages[i].sLanguageBackend
                        });
                        this.set("objSelectedProficiency", {
                            sValue: arrLanguages[i].sProficiencyBackend
                        });
                    }
                }
            }
        },
        actions: {
            onPopupAddEditCancel: function onPopupAddEditCancel() {
                if (this.get("_bPopupLoading")) {
                    return;
                }
                // Check if the form has any unsaved data
                var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

                if (bDataMatches) {
                    this.set("_bShowLanguagePopup", false);
                } else {
                    this.set("_bShowUnsavedData", true);
                }
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowLanguagePopup", false);
                this.set("_bShowUnsavedData", false);
            },
            onPopupRemoveConfirm: function onPopupRemoveConfirm() {
                var _this5 = this;

                this.set("_bLoadingRemovePopup", true);

                var sLanguageId = this.get("_sLanguageId");
                var sCandidateId = this.get("sCandidateId");

                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objCandidateProfileStringsService").getString("removingLang"));

                this.get("objLanguagesRestService").deleteLanguage(this, "_objRemoveLanguageResponse", sCandidateId, sLanguageId, function () {
                    _this5.set("_bShowConfirmRemoveData", false);

                    _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objCandidateProfileStringsService").getString("successRemoveLang"));

                    _this5.getLanguageDetails();
                }, a24RestCallHelper.createJsonAjaxFailureObject(["404"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    _this5.set("_bLoadingRemovePopup", false);
                    if (sCode === "404") {
                        _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getString("removeFailedRefreshPage"));
                    } else {
                        _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objCandidateProfileStringsService").getString("failRemoveLang"));
                    }
                }));
            },
            onPopupRemoveCancel: function onPopupRemoveCancel() {
                if (this.get("_bLoadingRemovePopup")) {
                    return;
                }
                this.set("_bShowConfirmRemoveData", false);
            },
            onPopupAddEditConfirm: function onPopupAddEditConfirm() {
                var _this6 = this;

                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    this.set("_bPopupLoading", true);

                    var sCandidateId = this.get("sCandidateId");
                    var objData = this.getPlainFormValueObject();

                    if (this.get("_bIsAdd")) {

                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objCandidateProfileStringsService").getString("creatingLang"));

                        this.get("objLanguagesRestService").createLanguage(this, "_objAddLanguageResponse", sCandidateId, objData, function () {
                            _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objCandidateProfileStringsService").getString("successAddLang"));

                            _this6.set("_bShowLanguagePopup", false);

                            _this6.getLanguageDetails();
                        }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{

                            _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objCandidateProfileStringsService").getString("failAddLang"));

                            _this6.set("_bPopupLoading", false);
                        }));
                    } else {
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objCandidateProfileStringsService").getString("updatingLang"));

                        var sLanguageId = this.get("_sLanguageId");

                        this.get("objLanguagesRestService").editLanguage(this, "_objEditLanguageResponse", sCandidateId, sLanguageId, objData, function () {
                            _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objCandidateProfileStringsService").getString("successUpdateLang"));

                            _this6.set("_bShowLanguagePopup", false);

                            _this6.getLanguageDetails();
                        }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                            if (sCode === "404") {
                                _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objStringsService").getString("updateFailedRefreshPage"));
                                _this6.set("_bPopupLoading", false);
                            } else {
                                _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objCandidateProfileStringsService").getString("failUpdateLang"));

                                _this6.set("_bPopupLoading", false);
                            }
                        }));
                    }
                }
            }
        }
    });
});