define("a24-ember-candidate-profile/components/candidate-external-documents", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-external-documents"], function (exports, _didRenderChangesMixin, _candidateExternalDocuments) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _candidateExternalDocuments.default,
        arrExternalDocumentsData: null,
        _arrExternalDocumentsDataView: null,
        _arrExternalDocumentsOrigData: null,
        _bLoading: true,
        _objStringsService: Ember.inject.service("a24-strings"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objUserSession: Ember.inject.service("user-session"),
        _objDatagridConfig: null,
        _arrFilterConfig: null,
        init: function init() {
            this._super.apply(this, arguments);

            this.set("_arrFilterConfig", [{
                sTitle: this.get("_objStringsService").getString("documentName"),
                sProperty: "name",
                sFilterType: "text",
                bHideEmptyCheck: true,
                bText: true
            }]);

            this.prepareExternalDataForDisplay();
        },
        prepareExternalDataForDisplay: Ember.observer("arrExternalDocumentsData", function () {
            this.set("_bLoading", false);
            var arrExternalDocumentsData = this.get("arrExternalDocumentsData");
            if (!a24Core.isEmpty(arrExternalDocumentsData)) {
                arrExternalDocumentsData.sort(function (objA, objB) {
                    if (objA.name < objB.name) {
                        return -1;
                    } else if (objA.name > objB.name) {
                        return 1;
                    }
                    return 0;
                });

                arrExternalDocumentsData = this._prepareForDisplay(arrExternalDocumentsData);

                this.set("_arrExternalDocumentsDataView", arrExternalDocumentsData);
                this.set("_arrExternalDocumentsOrigData", arrExternalDocumentsData);
            }
        }),
        _prepareForDisplay: function _prepareForDisplay(arrDocuments) {
            var _this = this;

            return arrDocuments.map(function (objDocument) {
                var sUpdatedDateFormatted = _this.get("_objStringsService").getHTMLTokenString("a24-string-html-dot-value", _this.get("_objCandidateProfileStringsService").getString("lastUploaded"), momentHelper.getTimeFromNow(objDocument.date, a24Constants.DATE_FORMAT_REST_FORMAT));

                var objDate = a24DateManager.createDate(_this.get("_objUserSession").get("objContextDetails.sTimezone"));

                objDate.setDateFromRest(objDocument.date);
                var sDateLastUpdated = objDate.getDateTimeFormat();

                var arrItems = [{
                    sLabel: _this.get("_objStringsService").getString("name"),
                    sValue: objDocument.name,
                    bText: true
                }, {
                    sLabel: _this.get("_objCandidateProfileStringsService").getString("lastUploadedDate"),
                    sValue: sDateLastUpdated,
                    bText: true
                }];

                if (!a24Core.isEmpty(objDocument.expiry_date)) {
                    var objExpiryDate = a24DateManager.createDate(_this.get("_objUserSession").get("objContextDetails.sTimezone"));

                    objExpiryDate.setDateFromRest(objDocument.expiry_date);

                    arrItems.push({
                        sLabel: _this.get("_objCandidateProfileStringsService").getString("expiryDate"),
                        sValue: objExpiryDate.getDateTimeFormat(),
                        bText: true
                    });
                }

                return {
                    sIcon: "insert_drive_file",
                    sLabel: objDocument.name,
                    sExtra: sUpdatedDateFormatted,
                    arrItems: [{
                        arrItems: arrItems
                    }]
                };
            });
        },
        filterDocuments: function filterDocuments(arrData, sFilterValue) {
            // loop over data and filter documents
            var arrExternalDocumentsData = [];
            if (!a24Core.isEmpty(arrData)) {
                arrData.forEach(function (objDocument) {
                    if (objDocument.sLabel.toLowerCase().indexOf(sFilterValue.toLowerCase()) !== -1) {
                        arrExternalDocumentsData.pushObject(objDocument);
                    }
                });
            }

            if (a24Core.isEmpty(arrExternalDocumentsData)) {
                this.set("_arrExternalDocumentsDataView", []);
            } else {
                this.set("_arrExternalDocumentsDataView", arrExternalDocumentsData);
            }
        },
        actions: {
            cardDatagridFilterChangeEvent: function cardDatagridFilterChangeEvent(sProperty, objValue) {
                var arrExternalDocumentsData = this.get("_arrExternalDocumentsOrigData");
                if (!a24Core.isEmpty(objValue.name)) {
                    var sFilterValue = objValue.name.mValue;
                    this.filterDocuments(arrExternalDocumentsData, sFilterValue);
                } else {
                    this.set("_arrExternalDocumentsDataView", arrExternalDocumentsData);
                }
            }
        }
    });
});