define('a24-ember-menu/services/left-menu-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    /*
     * Default values
    */
    /*
     * @property sImageLogoUrl
     * True if menu items are loading
    */
    bLoading: false,

    /*
     * @property sImageLogoUrl
     * An array holding the list of menu items
    */
    arrMenuItems: null,

    /*
     * @property sTopBarComponent
     * String holding the top bar component name
    */
    sTopBarComponent: null,

    /*
     * @property iTopBarComponentHeight
     * Number(Integer) holding the top bar component height in px
    */
    iTopBarComponentHeight: 0,
    objLeftMenuUIComponent: null,

    /*
     * @property sRightPanelComponent
     * String holding the name of the component that will load in the right panel
     */
    sRightPanelComponent: null,

    /*
     * @property sRightPanelComponentData
     * Mixed var holding the data to pass on to the right panel
     */
    mRightPanelComponentData: null,

    mRightPanelComponentHideOverflow: false,

    /*
     * @property iRightPanelComponentWidth
     * Integer holding the width of the right panel
     */
    iRightPanelComponentWidth: 300,

    /**
     * @property bRightPanelInitClosed
     * Boolean to set whether the right side should be open on init
     */
    bRightPanelInitClosed: false,

    /*
     * @property iRightPanelWidth
     * Integer holding the actial width of the right panel
     */
    iRightPanelWidth: 0,

    /*
     * @property sRightPanelState
     * String saves the current right panel animation state
     */
    sRightPanelState: '',

    /*
     * @property iRightPanelCollapsedWidth
     * Integer saves the width of the right panel in the collapsed state
     */
    iRightPanelCollapsedWidth: 50,

    /**
     * Function to trigger the right panel to open or close
     *
     * @param bOpen - Whether the panel should be open
     */
    funcRigthPanelOpen: function funcRigthPanelOpen(bOpen) {
      if (bOpen) {
        this.set("_iRigthPanelOpenCounter", this.get("_iRigthPanelOpenCounter") + 1);
      } else {
        this.set("_iRigthPanelCloseCounter", this.get("_iRigthPanelCloseCounter") + 1);
      }
    },
    _iRigthPanelOpenCounter: 0,
    _iRigthPanelCloseCounter: 0,

    /**
     * This function is used to get an array of menu items
     * This function needs to be overriden by a subclass
     *
     * @param funcOnComplete - A function to call with the menu items
     *
     * @author Ahmed Onawale <ahmedonawale@gmail.com>
     * @since  3 November 2017
     */
    getMenuItems: function getMenuItems() {
      (true && !(false) && Ember.assert("The `getMenuItems` function in `left-menu-service` needs to be overridden by a subclass"));
    },


    /**
     * This function is invoked when a menu item is clicked
     *
     * @param objMenuItem - The clicked menu item
     *
     * @author Ahmed Onawale <ahmedonawale@gmail.com>
     * @since  6 November 2017
     */
    onClickMenuItem: function onClickMenuItem() /* objMenuItem */{/* noop */},


    /**
     * This function is invoked after left menu animation is completed
     *
     * @author Ahmed Onawale <ahmedonawale@gmail.com>
     * @since  16 November 2017
     */
    afterMenuAnimation: function afterMenuAnimation() {/* noop */}
  });
});