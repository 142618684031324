define("a24-ember-staffshift-core/services/firebase", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objFirebase: window.firebase,
        _ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),
        _objConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objNavigation: Ember.inject.service("navigation"),
        _objUserSession: Ember.inject.service("user-session"),
        _objFirebaseCustomToken: Ember.inject.service("rest-firebase-custom-token"),

        _bFirebaseInitDone: false, //Dont reset flag, once init is done it can not be done again.

        _objFirebaseCustomTokenPromise: null,
        _funcInitAuthObserverUnsubscriber: null,
        _funcRemoveFirebaseObserver: null,
        _bFirebaseLoginDone: false,
        _bFirebaseSetupInProgress: false,
        _objPromise: null,
        _funcUnload: null,
        _arrDataObservers: null,
        _arrSignOutCallbacks: null,

        initializeApp: function initializeApp() {
            var _this = this;

            if (a24Core.isEmpty(this.get("_arrDataObservers"))) {
                this.set("_arrDataObservers", []);
            }
            if (a24Core.isEmpty(this.get("_arrSignOutCallbacks"))) {
                this.set("_arrSignOutCallbacks", []);
            }
            if (this.get("_bFirebaseSetupInProgress")) {
                return this.get("_objPromise");
            } else if (!this.get("_bFirebaseLoginDone") && !a24Core.isEmpty(this.get("_ENV.objFirebaseConfig"))) {
                this.set("_bFirebaseSetupInProgress", true);

                // Initialize Firebase
                var bDoInitAuthObserver = false;
                if (!this.get("_bFirebaseInitDone")) {
                    this.get("_objFirebase").initializeApp(this.get("_ENV.objFirebaseConfig"));
                    this.set("_bFirebaseInitDone", true);

                    //This caters for when the user navigates to a gwt page.
                    var funcUnload = function funcUnload() {
                        if (_this.get("_objNavigation.bNavigatingToGWT")) {
                            _this.signOut();
                        }
                    };
                    this.set("_funcUnload", funcUnload);
                    if (window.addEventListener) {
                        // For all major browsers, except IE 8 and earlier
                        window.addEventListener("beforeunload", funcUnload);
                    } else if (window.attachEvent) {
                        // For IE 8 and earlier versions
                        window.attachEvent("beforeunload", funcUnload);
                    }

                    bDoInitAuthObserver = true;
                }

                var funcDone = null;
                var funcFail = null;
                var objPromise = new Ember.RSVP.Promise(function (funcResolve, funcReject) {
                    funcDone = function funcDone() {
                        _this.set("_bFirebaseSetupInProgress", false);
                        funcResolve();
                    };
                    funcFail = function funcFail() {
                        _this.set("_bFirebaseSetupInProgress", false);
                        funcReject();
                    };
                });

                if (bDoInitAuthObserver) {
                    this.set("_funcInitAuthObserverUnsubscriber", this.get("_objFirebase").auth().onAuthStateChanged(function (objUser) {
                        //This is just for init so immediately unsubscribe on first trigger
                        _this.get("_funcInitAuthObserverUnsubscriber")();
                        _this.set("_funcInitAuthObserverUnsubscriber", null);

                        if (a24Core.isEmpty(objUser)) {
                            _this._getFirebaseCustomToken(funcDone, funcFail);
                        } else {
                            if (objUser.uid === _this.get("_objUserSession.objAuthData.identity.id")) {
                                _this._funcFirebaseSignInSuccess(funcDone);
                            } else {
                                _this.signOut().finally(function () {
                                    _this._getFirebaseCustomToken(funcDone, funcFail);
                                });
                            }
                        }
                    }));
                } else {
                    if (a24Core.isEmpty(this.get("_objFirebase").auth().currentUser) || this.get("_objFirebase").auth().currentUser.uid === this.get("_objUserSession.objContextDetails.id")) {
                        this._getFirebaseCustomToken(funcDone, funcFail);
                    } else {
                        this.signOut().finally(function () {
                            _this._getFirebaseCustomToken(funcDone, funcFail);
                        });
                    }
                }

                this.set("_objPromise", objPromise);
                return objPromise;
            } else {
                return new Ember.RSVP.Promise(function (funcResolve) {
                    funcResolve();
                });
            }
        },
        _getFirebaseCustomToken: function _getFirebaseCustomToken(funcDone, funcFail) {
            var _this2 = this;

            this.get("_objFirebaseCustomToken").getFirebaseCustomToken(this, "_objFirebaseCustomTokenPromise", function (objData) {
                _this2.get("_objFirebase").auth().signInWithCustomToken(objData.custom_jwt).then(function () {
                    _this2._funcFirebaseSignInSuccess(funcDone);
                }).catch(function () {
                    funcFail();
                });
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE"], function () {
                funcFail();
            }));
        },
        _funcFirebaseSignInSuccess: function _funcFirebaseSignInSuccess(funcDone) {
            var _this3 = this;

            //Only add the listener now else it might trigger with user as null before the login call is done
            var funcRemoveFirebaseObserver = this.get("_objFirebase").auth().onAuthStateChanged(function (objUser) {
                if (a24Core.isEmpty(objUser)) {
                    _this3.signOut(true).finally(function () {
                        var arrSignOutCallbacks = _this3.get("_arrSignOutCallbacks");
                        for (var j = 0; j < arrSignOutCallbacks.length; j++) {
                            arrSignOutCallbacks[j](_this3.get("_objConstants").FIREBASE_ERROR_SIGNED_OUT);
                        }
                        _this3.set("_arrSignOutCallbacks", []);
                    });
                }
            });
            // Set the firebase remove observer function
            this.set("_funcRemoveFirebaseObserver", funcRemoveFirebaseObserver);

            this.set("_bFirebaseLoginDone", true);
            funcDone();
        },
        _resetAllVars: function _resetAllVars(bSkipSignOutCallbacksClear) {
            this.set("_objFirebaseCustomTokenPromise", null);
            this.set("_funcRemoveFirebaseObserver", null);
            this.set("_funcInitAuthObserverUnsubscriber", null);
            this.set("_bFirebaseLoginDone", false);
            this.set("_bFirebaseSetupInProgress", false);
            this.set("_objPromise", null);
            this.set("_arrDataObservers", []);
            if (!bSkipSignOutCallbacksClear) {
                this.set("_arrSignOutCallbacks", []);
            }
        },
        signOut: function signOut(bSkipSignOutCallbacksClear) {
            var _this4 = this;

            //First remove the login state observers
            var funcInitAuthObserverUnsubscriber = this.get("_funcInitAuthObserverUnsubscriber");
            if (!a24Core.isEmpty(funcInitAuthObserverUnsubscriber)) {
                funcInitAuthObserverUnsubscriber();
            }
            var funcRemoveFirebaseObserver = this.get("_funcRemoveFirebaseObserver");
            if (!a24Core.isEmpty(funcRemoveFirebaseObserver)) {
                funcRemoveFirebaseObserver();
            }

            //Clear all firestore observers
            var arrObservers = this.get("_arrDataObservers");
            if (!a24Core.isEmpty(arrObservers)) {
                for (var i = 0; i < arrObservers.length; i++) {
                    arrObservers[i]();
                }
            }

            if (this.get("_bFirebaseSetupInProgress") && !a24Core.isEmpty(this.get("_objFirebaseCustomTokenPromise")) && typeof this.get("_objFirebaseCustomTokenPromise").abort === "function") {
                this.get("_objFirebaseCustomTokenPromise").abort();
            }

            this._resetAllVars(bSkipSignOutCallbacksClear);

            return new Ember.RSVP.Promise(function (funcResolve) {
                //Can only call signout if initializeApp was done, also only call if we have logged in user.
                if (_this4.get("_bFirebaseInitDone") && !a24Core.isEmpty(_this4.get("_objFirebase").auth().currentUser)) {
                    _this4.get("_objFirebase").auth().signOut().finally(function () {
                        funcResolve();
                    });
                } else {
                    funcResolve();
                }
            });
        },
        updateCurrentDataWithNew: function updateCurrentDataWithNew(mCurrentData, mNewData, sUniqueId, sSortOnProperty) {
            if (a24Core.isEmpty(mNewData)) {
                if (Ember.typeOf(mCurrentData) === "array") {
                    return [];
                } else {
                    return null;
                }
            }
            if (a24Core.isEmpty(mCurrentData)) {
                if (Ember.typeOf(mNewData) === "array" && !a24Core.isEmpty(sSortOnProperty)) {
                    return mNewData.sortBy(sSortOnProperty);
                } else {
                    return mNewData;
                }
            }
            if (Ember.typeOf(mCurrentData) === Ember.typeOf(mNewData)) {
                if (Ember.typeOf(mNewData) === "array") {
                    for (var i = 0; i < mNewData.length; i++) {
                        var objNewData = mNewData.objectAt(i);
                        var objToUpdate = mCurrentData.findBy(sUniqueId, objNewData[sUniqueId]);
                        if (a24Core.isEmpty(objToUpdate)) {
                            mCurrentData.pushObject(objNewData);
                        } else {
                            this.updateObjectProperties(objToUpdate, objNewData);
                        }
                    }
                    // Remove objects that are in the current data array but not in the new data array
                    for (var j = 0; j < mCurrentData.length; j++) {
                        var objCurrentData = mCurrentData.objectAt(j);
                        if (a24Core.isEmpty(mNewData.findBy(sUniqueId, objCurrentData[sUniqueId]))) {
                            mCurrentData.removeObject(objCurrentData);
                        }
                    }
                    // Sort current data array if sSortOnProperty is provided
                    if (!a24Core.isEmpty(sSortOnProperty)) {
                        mCurrentData.sortBy(sSortOnProperty);
                    }
                    return mCurrentData;
                } else if (Ember.typeOf(mNewData) === "object") {
                    return this.updateObjectProperties(mCurrentData, mNewData);
                }
            }
        },
        updateObjectProperties: function updateObjectProperties(objToUpdate, objNewData) {
            // Delete properties that are in the old object but not in the new one
            for (var sOldProp in objToUpdate) {
                if (objToUpdate.hasOwnProperty(sOldProp) && !objNewData.hasOwnProperty(sOldProp)) {
                    delete objToUpdate[sOldProp];
                }
            }
            // Update new object properties with the old one
            for (var sNewProp in objNewData) {
                if (objNewData.hasOwnProperty(sNewProp)) {
                    Ember.set(objToUpdate, sNewProp, objNewData[sNewProp]);
                }
            }
            return objToUpdate;
        },

        //NOTE: we can use the below as base for all snapshot observers or if things need to be more custom per call then
        //we can copy what is in here, key points being that it needs to put the observer in the _arrDataObservers and also
        //the _arrSignOutCallbacks and give back the funcUnsubscribe to parent item;
        _snapShotBase: function _snapShotBase(funcFirebaseCollection, funcSuccess, funcFail, funcRemoveObserver) {
            var _this5 = this;

            //This will init firebase on run time when call is made.
            //funcFail - Will be called with string code to tell you why it failed
            //  Codes from us:
            //      FIREBASE_ERROR_SIGNED_OUT - means use signed out on another tab and thus we should provide user with popup to
            //                      retry call or someting like that. Then we can also
            //      FIREBASE_ERROR_QUERY_NOT_EXIST - Telling you that collection or document that you tried to query on firebase does not
            //                      exist. Might present user wit popup like "The shift you are viewing in no longer
            //                      availible"
            //      FIREBASE_ERROR_SIGNED_IN_FAILED - Either we could not get costim token of firebase signin failed for whatever reason.
            //                      Here we could possibly just give popup saying to refresh or retry call since call retry
            //                      would automatically retry the login.
            //  Codes from firebase (currently I only know of the one but there might be more)
            //      permission-denied - This will happen when you try and query docs you dont have permisson to. This should
            //                          happen or be possible but we should still handle it and just tell user to refresh
            //                          page
            this.initializeApp().then(function () {
                var funcUnsubscribe = funcFirebaseCollection().onSnapshot(function (objSnapshot) {
                    if (!a24Core.isEmpty(objSnapshot)) {
                        if (Ember.typeOf(objSnapshot.docs) === "array") {
                            var arrData = [];
                            for (var i = 0; i < objSnapshot.docs.length; i++) {
                                if (objSnapshot.docs[i].exists) {
                                    arrData.push(objSnapshot.docs[i].data());
                                }
                            }
                            if (a24Core.isEmpty(arrData) && objSnapshot.docs.length > 0) {
                                funcFail(_this5.get("_objConstants").FIREBASE_ERROR_QUERY_NOT_EXIST);
                            } else {
                                funcSuccess(arrData);
                            }
                        } else if (objSnapshot.exists) {
                            funcSuccess(objSnapshot.data());
                        } else {
                            funcFail(_this5.get("_objConstants").FIREBASE_ERROR_QUERY_NOT_EXIST);
                        }
                    } else {
                        funcSuccess();
                    }
                }, function (objError) {
                    //It appear that the observer stops running after error was thrown so no need to manually stop it.
                    funcFail(objError.code); //errors from firebase e.g permission-denied
                });
                _this5.get("_arrDataObservers").pushObject(funcUnsubscribe);
                _this5.get("_arrSignOutCallbacks").pushObject(funcFail);
                funcRemoveObserver(function () {
                    _this5.get("_arrDataObservers").removeObject(funcUnsubscribe);
                    funcUnsubscribe();
                });
            }).catch(function () {
                //Note: error that happen in the `then` will also trigger this catch
                funcFail(_this5.get("_objConstants").FIREBASE_ERROR_SIGNED_IN_FAILED);
            });
        },

        //Now we can have small wrappers for each call to firebase, same kinda wrappers as in our rest services
        getAvailibleShifts: function getAvailibleShifts(funcSuccess, funcFail, funcRemoveObserver) {
            var _this6 = this;

            this._snapShotBase(function () {
                return _this6.get("_objFirebase").firestore().collection("candidate_possible_shifts").where("user_id", "==", _this6.get("_objUserSession.objAuthData.identity.id"));
                //Example of doing query on date, note that dont use because of the way we
                //need to sort manually anyway plus not sure indexes etc was setup on live for this.
                //.where("shift_start_date", ">", this.get("_objFirebase").firestore.Timestamp.now())
            }, funcSuccess, funcFail, funcRemoveObserver);
        }
        //
        // //NOTE: Example of how calls to firebase should be done from parent side
        // this.get("_objFirebaseService").getAvailibleShifts(
        //     //Success callback, NOTE this will be called multiple times as data updates in real time so you need to update
        //     //view each time this callback gets called.
        //     (objData) => {
        //         //TODO take the data and update view. Might be called miltiple times so prob good in most cases to have
        //         //loading show up or something indicating to user new data was loaded.
        //     },
        //     //Failure callback, this will give you multiple codes to tell you what happened. In most cases this will only
        //     //be called once and then no other callbacks will be called again. However in the case of query_not_exist the
        //     //success might be called again when the doc becomes availible and in turn fail can be called again when that
        //     //doc get deleted.
        //     (sCode) => {
        //         //TODO: Handle the sCode like we do with rest error codes and show user some popups or messages.
        //     },
        //     //The unsubscribe callback, this is used to stop your component from listening to real time changes on the data.
        //     //This means that you need to save this func somewhere and call it when you want to stop observing the data AND
        //     //(very important) you need to call this function on component destory else it will keep firing and break stuff.
        //     (funcUnsubscribe) => {
        //         //Save the unsubscribe function for later
        //         this.set("funcAvailShiftUnsubscribe", funcUnsubscribe);
        //
        //         //this in the on destroy of component call the function
        //         // this.get("funcAvailShiftUnsubscribe")();
        //     }
        // );

    });
});