define("a24-ember-candidate-profile/services/rest-agency-group-candidate-advanced-search", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _sRestApiBaseUrl: Ember.computed.readOnly("_objEnv.sRestApiBaseUrl"),

        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        init: function init() {
            this._super.apply(this, arguments);
        },

        /**
         * Performs a the advanced search for a candidate
         *
         * @param objContext - The the caller of this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - candidate id
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  22 August 2019
         */
        searchCandidate: function searchCandidate(objContext, sProperty, sCandidateId, funcSuccess, objFailure) {
            var objQuery = {
                "page": 1,
                "items_per_page": 1,
                "project": {
                    "candidate_id": 1,
                    "agency_group_id": 1,
                    "full_name": 1,
                    "first_name": 1,
                    "last_name": 1,
                    "dbs_status": 1,
                    "last_contacted_at": 1,
                    "external_documents": 1,
                    "external_verifications": 1,
                    "external_workforce": 1
                },
                "query": {
                    "and": [{
                        "candidate_id": {
                            "equal": sCandidateId
                        }
                    }]
                }
            };

            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency-group/" + sAgencyGroupId + "/advanced-search-v2/candidate");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", Ember.get(this, "_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objQuery));
        }
    });
});