define("a24-ember-candidate-profile/services/rest-agency-group-candidate-activity", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        /*
          Services
        */
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),

        _sRestApiBaseUrl: Ember.computed.readOnly("_objEnv.sRestApiBaseUrl"),

        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        init: function init() {
            this._super.apply(this, arguments);

            this.set("sContext", this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP"));
        },

        /*
          Functions
        */

        /**
         * Performs a single candidate activities ajax call
         *
         * @param objCaller - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id to fetch activities data for
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         * @param objQueryParams - Any extra query params for the get list
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  05 September 2018
         */
        getActivities: function getActivities(objContext, sProperty, sCandidateId, funcSuccess, objFailure, objQueryParams) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency-group/" + sAgencyGroupId + "/candidate/" + sCandidateId + "/activity");

            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", Ember.get(this, "_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Create a new activity for a candidate
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id
         * @param objData - The activity data
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  05 September 2018
         */
        createActivity: function createActivity(objContext, sProperty, sCandidateId, objData, funcSuccess, objFailure) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency-group/" + sAgencyGroupId + "/candidate/" + sCandidateId + "/activity");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        }
    });
});