enifed('@glimmer/program', ['exports', 'ember-babel', '@glimmer/util'], function (exports, _emberBabel) {
    'use strict';

    exports.Opcode = exports.Program = exports.RuntimeProgram = exports.WriteOnlyProgram = exports.Heap = exports.LazyConstants = exports.Constants = exports.RuntimeConstants = exports.WriteOnlyConstants = undefined;


    var UNRESOLVED = {};
    var WELL_KNOWN_EMPTY_ARRAY_POSITION = 0;
    var WELL_KNOW_EMPTY_ARRAY = Object.freeze([]);

    var WriteOnlyConstants = function () {
        function WriteOnlyConstants() {
            (0, _emberBabel.classCallCheck)(this, WriteOnlyConstants);

            // `0` means NULL
            this.strings = [];
            this.arrays = [WELL_KNOW_EMPTY_ARRAY];
            this.tables = [];
            this.handles = [];
            this.resolved = [];
            this.numbers = [];
        }

        WriteOnlyConstants.prototype.string = function string(value) {
            var index = this.strings.indexOf(value);
            if (index > -1) {
                return index;
            }
            return this.strings.push(value) - 1;
        };

        WriteOnlyConstants.prototype.stringArray = function stringArray(strings) {
            var _strings = new Array(strings.length);
            for (var i = 0; i < strings.length; i++) {
                _strings[i] = this.string(strings[i]);
            }
            return this.array(_strings);
        };

        WriteOnlyConstants.prototype.array = function array(values) {
            if (values.length === 0) {
                return WELL_KNOWN_EMPTY_ARRAY_POSITION;
            }
            var index = this.arrays.indexOf(values);
            if (index > -1) {
                return index;
            }
            return this.arrays.push(values) - 1;
        };

        WriteOnlyConstants.prototype.handle = function handle(_handle) {
            var index = this.handles.indexOf(_handle);
            if (index > -1) {
                return index;
            }
            this.resolved.push(UNRESOLVED);
            return this.handles.push(_handle) - 1;
        };

        WriteOnlyConstants.prototype.serializable = function serializable(value) {
            var str = JSON.stringify(value);
            var index = this.strings.indexOf(str);
            if (index > -1) {
                return index;
            }
            return this.strings.push(str) - 1;
        };

        WriteOnlyConstants.prototype.number = function number(_number) {
            var index = this.numbers.indexOf(_number);
            if (index > -1) {
                return index;
            }
            return this.numbers.push(_number) - 1;
        };

        WriteOnlyConstants.prototype.toPool = function toPool() {
            return {
                strings: this.strings,
                arrays: this.arrays,
                handles: this.handles,
                numbers: this.numbers
            };
        };

        return WriteOnlyConstants;
    }();

    var RuntimeConstants = function () {
        function RuntimeConstants(resolver, pool) {
            (0, _emberBabel.classCallCheck)(this, RuntimeConstants);

            this.resolver = resolver;
            this.strings = pool.strings;
            this.arrays = pool.arrays;
            this.handles = pool.handles;
            this.resolved = this.handles.map(function () {
                return UNRESOLVED;
            });
            this.numbers = pool.numbers;
        }

        RuntimeConstants.prototype.getString = function getString(value) {
            return this.strings[value];
        };

        RuntimeConstants.prototype.getNumber = function getNumber(value) {
            return this.numbers[value];
        };

        RuntimeConstants.prototype.getStringArray = function getStringArray(value) {
            var names = this.getArray(value);
            var _names = new Array(names.length);
            for (var i = 0; i < names.length; i++) {
                var n = names[i];
                _names[i] = this.getString(n);
            }
            return _names;
        };

        RuntimeConstants.prototype.getArray = function getArray(value) {
            return this.arrays[value];
        };

        RuntimeConstants.prototype.resolveHandle = function resolveHandle(index) {
            var resolved = this.resolved[index];
            if (resolved === UNRESOLVED) {
                var handle = this.handles[index];
                resolved = this.resolved[index] = this.resolver.resolve(handle);
            }
            return resolved;
        };

        RuntimeConstants.prototype.getSerializable = function getSerializable(s) {
            return JSON.parse(this.strings[s]);
        };

        return RuntimeConstants;
    }();

    var Constants = function (_WriteOnlyConstants) {
        (0, _emberBabel.inherits)(Constants, _WriteOnlyConstants);

        function Constants(resolver, pool) {
            (0, _emberBabel.classCallCheck)(this, Constants);

            var _this = (0, _emberBabel.possibleConstructorReturn)(this, _WriteOnlyConstants.call(this));

            _this.resolver = resolver;
            if (pool) {
                _this.strings = pool.strings;
                _this.arrays = pool.arrays;
                _this.handles = pool.handles;
                _this.resolved = _this.handles.map(function () {
                    return UNRESOLVED;
                });
                _this.numbers = pool.numbers;
            }
            return _this;
        }

        Constants.prototype.getNumber = function getNumber(value) {
            return this.numbers[value];
        };

        Constants.prototype.getString = function getString(value) {
            return this.strings[value];
        };

        Constants.prototype.getStringArray = function getStringArray(value) {
            var names = this.getArray(value);
            var _names = new Array(names.length);
            for (var i = 0; i < names.length; i++) {
                var n = names[i];
                _names[i] = this.getString(n);
            }
            return _names;
        };

        Constants.prototype.getArray = function getArray(value) {
            return this.arrays[value];
        };

        Constants.prototype.resolveHandle = function resolveHandle(index) {
            var resolved = this.resolved[index];
            if (resolved === UNRESOLVED) {
                var handle = this.handles[index];
                resolved = this.resolved[index] = this.resolver.resolve(handle);
            }
            return resolved;
        };

        Constants.prototype.getSerializable = function getSerializable(s) {
            return JSON.parse(this.strings[s]);
        };

        return Constants;
    }(WriteOnlyConstants);

    var LazyConstants = function (_Constants) {
        (0, _emberBabel.inherits)(LazyConstants, _Constants);

        function LazyConstants() {
            (0, _emberBabel.classCallCheck)(this, LazyConstants);

            var _this2 = (0, _emberBabel.possibleConstructorReturn)(this, _Constants.apply(this, arguments));

            _this2.others = [];
            _this2.serializables = [];
            return _this2;
        }

        LazyConstants.prototype.serializable = function serializable(value) {
            var index = this.serializables.indexOf(value);
            if (index > -1) {
                return index;
            }
            return this.serializables.push(value) - 1;
        };

        LazyConstants.prototype.getSerializable = function getSerializable(s) {
            return this.serializables[s];
        };

        LazyConstants.prototype.getOther = function getOther(value) {
            return this.others[value - 1];
        };

        LazyConstants.prototype.other = function other(_other) {
            return this.others.push(_other);
        };

        return LazyConstants;
    }(Constants);

    var Opcode = function () {
        function Opcode(heap) {
            (0, _emberBabel.classCallCheck)(this, Opcode);

            this.heap = heap;
            this.offset = 0;
        }

        (0, _emberBabel.createClass)(Opcode, [{
            key: 'size',
            get: function () {
                var rawType = this.heap.getbyaddr(this.offset);
                return ((rawType & 768 /* OPERAND_LEN_MASK */) >> 8 /* ARG_SHIFT */) + 1;
            }
        }, {
            key: 'isMachine',
            get: function () {
                var rawType = this.heap.getbyaddr(this.offset);
                return rawType & 1024 /* MACHINE_MASK */;
            }
        }, {
            key: 'type',
            get: function () {
                return this.heap.getbyaddr(this.offset) & 255 /* TYPE_MASK */;
            }
        }, {
            key: 'op1',
            get: function () {
                return this.heap.getbyaddr(this.offset + 1);
            }
        }, {
            key: 'op2',
            get: function () {
                return this.heap.getbyaddr(this.offset + 2);
            }
        }, {
            key: 'op3',
            get: function () {
                return this.heap.getbyaddr(this.offset + 3);
            }
        }]);
        return Opcode;
    }();

    function encodeTableInfo(size, scopeSize, state) {
        return size | scopeSize << 16 | state << 30;
    }
    function changeState(info, newState) {
        return info | newState << 30;
    }
    var PAGE_SIZE = 0x100000;
    /**
     * The Heap is responsible for dynamically allocating
     * memory in which we read/write the VM's instructions
     * from/to. When we malloc we pass out a VMHandle, which
     * is used as an indirect way of accessing the memory during
     * execution of the VM. Internally we track the different
     * regions of the memory in an int array known as the table.
     *
     * The table 32-bit aligned and has the following layout:
     *
     * | ... | hp (u32) |       info (u32)          |
     * | ... |  Handle  | Size | Scope Size | State |
     * | ... | 32-bits  | 16b  |    14b     |  2b   |
     *
     * With this information we effectively have the ability to
     * control when we want to free memory. That being said you
     * can not free during execution as raw address are only
     * valid during the execution. This means you cannot close
     * over them as you will have a bad memory access exception.
     */

    var Heap = function () {
        function Heap(serializedHeap) {
            (0, _emberBabel.classCallCheck)(this, Heap);

            this.placeholders = [];
            this.offset = 0;
            this.handle = 0;
            this.capacity = PAGE_SIZE;
            if (serializedHeap) {
                var buffer = serializedHeap.buffer,
                    table = serializedHeap.table,
                    handle = serializedHeap.handle;

                this.heap = new Uint16Array(buffer);
                this.table = table;
                this.offset = this.heap.length;
                this.handle = handle;
                this.capacity = 0;
            } else {
                this.heap = new Uint16Array(PAGE_SIZE);
                this.table = [];
            }
        }

        Heap.prototype.push = function push(item) {
            this.sizeCheck();
            this.heap[this.offset++] = item;
        };

        Heap.prototype.sizeCheck = function sizeCheck() {
            if (this.capacity === 0) {
                var heap = slice(this.heap, 0, this.offset);
                this.heap = new Uint16Array(heap.length + PAGE_SIZE);
                this.heap.set(heap, 0);
                this.capacity = PAGE_SIZE;
            }
            this.capacity--;
        };

        Heap.prototype.getbyaddr = function getbyaddr(address) {
            return this.heap[address];
        };

        Heap.prototype.setbyaddr = function setbyaddr(address, value) {
            this.heap[address] = value;
        };

        Heap.prototype.malloc = function malloc() {
            this.table.push(this.offset, 0);
            var handle = this.handle;
            this.handle += 2 /* ENTRY_SIZE */;
            return handle;
        };

        Heap.prototype.finishMalloc = function finishMalloc(handle, scopeSize) {
            var start = this.table[handle];
            var finish = this.offset;
            var instructionSize = finish - start;
            var info = encodeTableInfo(instructionSize, scopeSize, 0 /* Allocated */);
            this.table[handle + 1 /* INFO_OFFSET */] = info;
        };

        Heap.prototype.size = function size() {
            return this.offset;
        };

        Heap.prototype.getaddr = function getaddr(handle) {
            return this.table[handle];
        };

        Heap.prototype.gethandle = function gethandle(address) {
            this.table.push(address, encodeTableInfo(0, 0, 3 /* Pointer */));
            var handle = this.handle;
            this.handle += 2 /* ENTRY_SIZE */;
            return handle;
        };

        Heap.prototype.sizeof = function sizeof(handle) {
            return -1;
        };

        Heap.prototype.scopesizeof = function scopesizeof(handle) {
            var info = this.table[handle + 1 /* INFO_OFFSET */];
            return (info & 1073676288 /* SCOPE_MASK */) >> 16;
        };

        Heap.prototype.free = function free(handle) {
            var info = this.table[handle + 1 /* INFO_OFFSET */];
            this.table[handle + 1 /* INFO_OFFSET */] = changeState(info, 1 /* Freed */);
        };

        Heap.prototype.compact = function compact() {
            var compactedSize = 0;
            var table = this.table,
                length = this.table.length,
                heap = this.heap;

            for (var i = 0; i < length; i += 2 /* ENTRY_SIZE */) {
                var offset = table[i];
                var info = table[i + 1 /* INFO_OFFSET */];
                var size = info & 65535;
                var state = info & 3221225472 /* STATE_MASK */ >> 30;
                if (state === 2 /* Purged */) {
                        continue;
                    } else if (state === 1 /* Freed */) {
                        // transition to "already freed" aka "purged"
                        // a good improvement would be to reuse
                        // these slots
                        table[i + 1 /* INFO_OFFSET */] = changeState(info, 2 /* Purged */);
                        compactedSize += size;
                    } else if (state === 0 /* Allocated */) {
                        for (var j = offset; j <= i + size; j++) {
                            heap[j - compactedSize] = heap[j];
                        }
                        table[i] = offset - compactedSize;
                    } else if (state === 3 /* Pointer */) {
                        table[i] = offset - compactedSize;
                    }
            }
            this.offset = this.offset - compactedSize;
        };

        Heap.prototype.pushPlaceholder = function pushPlaceholder(valueFunc) {
            this.sizeCheck();
            var address = this.offset++;
            this.heap[address] = 65535 /* MAX_SIZE */;
            this.placeholders.push([address, valueFunc]);
        };

        Heap.prototype.patchPlaceholders = function patchPlaceholders() {
            var placeholders = this.placeholders;

            for (var i = 0; i < placeholders.length; i++) {
                var _placeholders$i = placeholders[i],
                    address = _placeholders$i[0],
                    getValue = _placeholders$i[1];

                this.setbyaddr(address, getValue());
            }
        };

        Heap.prototype.capture = function capture() {
            var offset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.offset;

            this.patchPlaceholders();
            // Only called in eager mode
            var buffer = slice(this.heap, 0, offset).buffer;
            return {
                handle: this.handle,
                table: this.table,
                buffer: buffer
            };
        };

        return Heap;
    }();

    var WriteOnlyProgram = function () {
        function WriteOnlyProgram() {
            var constants = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new WriteOnlyConstants();
            var heap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Heap();
            (0, _emberBabel.classCallCheck)(this, WriteOnlyProgram);

            this.constants = constants;
            this.heap = heap;
            this._opcode = new Opcode(this.heap);
        }

        WriteOnlyProgram.prototype.opcode = function opcode(offset) {
            this._opcode.offset = offset;
            return this._opcode;
        };

        return WriteOnlyProgram;
    }();

    var RuntimeProgram = function () {
        function RuntimeProgram(constants, heap) {
            (0, _emberBabel.classCallCheck)(this, RuntimeProgram);

            this.constants = constants;
            this.heap = heap;
            this._opcode = new Opcode(this.heap);
        }

        RuntimeProgram.hydrate = function hydrate(rawHeap, pool, resolver) {
            var heap = new Heap(rawHeap);
            var constants = new RuntimeConstants(resolver, pool);
            return new RuntimeProgram(constants, heap);
        };

        RuntimeProgram.prototype.opcode = function opcode(offset) {
            this._opcode.offset = offset;
            return this._opcode;
        };

        return RuntimeProgram;
    }();

    var Program = function (_WriteOnlyProgram) {
        (0, _emberBabel.inherits)(Program, _WriteOnlyProgram);

        function Program() {
            (0, _emberBabel.classCallCheck)(this, Program);
            return (0, _emberBabel.possibleConstructorReturn)(this, _WriteOnlyProgram.apply(this, arguments));
        }

        return Program;
    }(WriteOnlyProgram);

    function slice(arr, start, end) {
        if (arr.slice !== undefined) {
            return arr.slice(start, end);
        }
        var ret = new Uint16Array(end);
        for (; start < end; start++) {
            ret[start] = arr[start];
        }
        return ret;
    }

    exports.WriteOnlyConstants = WriteOnlyConstants;
    exports.RuntimeConstants = RuntimeConstants;
    exports.Constants = Constants;
    exports.LazyConstants = LazyConstants;
    exports.Heap = Heap;
    exports.WriteOnlyProgram = WriteOnlyProgram;
    exports.RuntimeProgram = RuntimeProgram;
    exports.Program = Program;
    exports.Opcode = Opcode;
});