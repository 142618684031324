define("a24-ember-form-inputs/services/a24-strings-form-inputs", ["exports", "a24-ember-lookup/services/a24-strings-base"], function (exports, _a24StringsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24StringsBase.default.extend({
        _objStrings: null,
        init: function init() {
            this._super.apply(this, arguments);
            Ember.set(this, "_objStrings", {
                total: "Total",
                startDate: "Start Date",
                endDate: "End Date",
                startTime: "Start Time",
                endTime: "End Time",

                telNumberInputCountryError: "The number you have entered is not valid for the selected country",

                validationEmail: "{0} is not a valid email address",
                invalidEmail: "Email is not valid/allowed"
            });
        }
    });
});