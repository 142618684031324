define("a24-ember-lib/components/stepper-item", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/templates/components/stepper-item"], function (exports, _didRenderChangesMixin, _stepperItem) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _stepperItem.default,
        classNames: ["a24-stepper-item"],
        classNameBindings: ["bSelected:is-selected", "bCompleted:is-completed", "bEnabled::is-disabled"],
        sStepNumber: null,
        sTitle: null,
        sSubTitle: null,
        bCompleted: false,
        bSelected: false,
        bEnabled: false,

        _sItemId: null,

        _onPropertiesChange: Ember.observer("sStepNumber", "sTitle", "sSubTitle", "bCompleted", "bSelected", "bEnabled", function () {
            if (!a24Core.isEmpty(this.get("onLinkActionItemStateChanged"))) {
                Ember.run.once(this, this._notifyPropertiesChange);
            }
        }),

        init: function init() {
            this._super.apply(this, arguments);
            if (a24Core.isEmpty(this.get("_sItemId"))) {
                this.set("_sItemId", Ember.guidFor({}));
            }
            this._onPropertiesChange();
        },

        _notifyPropertiesChange: function _notifyPropertiesChange() {
            this.get("onLinkActionItemStateChanged")(this.get("_sItemId"), this.getProperties("sStepNumber", "sTitle", "sSubTitle", "bCompleted", "bSelected", "bEnabled"));
        },

        actions: {
            onLinkActionStepperItemClicked: function onLinkActionStepperItemClicked() {
                if (!a24Core.isEmpty(this.get("onLinkActionStepperItemClicked")) && this.get("bEnabled") && !this.get("bSelected")) {
                    this.get("onLinkActionStepperItemClicked")(this.get("sStepNumber"), this.get("sTitle"), this.get("_sItemId"));
                }
            }
        }
    });
});