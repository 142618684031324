define("a24-ember-lib/mixins/outside-click-handler-mixin", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        onOutsideClick: function onOutsideClick() {},
        // Placeholder function
        handleOutsideClick: function handleOutsideClick(objEvent) {
            var _this = this;

            Ember.run(function () {
                var objElement = _this.$();
                var objTarget = Ember.$(objEvent.target);

                // Check if the click is inside the target
                if (!objTarget.closest(objElement).length) {
                    _this.onOutsideClick(objEvent); // Handle the click
                }
            });
        },
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            this.setupOutsideClickListener();
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);
            this.removeOutsideClickListener();
        },
        setupOutsideClickListener: function setupOutsideClickListener() {
            // Set up the click handler
            var objClickHandler = this.get("handleOutsideClick").bind(this);
            return Ember.$(window).on("click touchstart", objClickHandler);
        },
        removeOutsideClickListener: function removeOutsideClickListener() {
            // remove the click handler
            var objClickHandler = this.get("handleOutsideClick").bind(this);
            return Ember.$(window).off("click touchstart", Ember.run.cancel(this, objClickHandler));
        }
    });
});