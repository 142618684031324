define("a24-ember-candidate-profile/components/tab-agency-group-candidate-profile-general", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/tab-agency-group-candidate-profile-general"], function (exports, _didRenderChangesMixin, _tabAgencyGroupCandidateProfileGeneral) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _tabAgencyGroupCandidateProfileGeneral.default,
        _objEmailService: Ember.inject.service("rest-agency-group-candidate-emails"),
        _objStatusService: Ember.inject.service("rest-agency-group-status-details"),
        _objStatusReasonService: Ember.inject.service("rest-agency-group-status-reason"),
        _objCandidateStatusService: Ember.inject.service("rest-agency-group-candidate-status"),
        _objBasicInfoService: Ember.inject.service("rest-agency-group-candidate-basic-info"),
        _objAdditionalNoteService: Ember.inject.service("rest-agency-group-candidate-additional-note"),
        _objContactNumbersService: Ember.inject.service("rest-agency-group-candidate-contact-numbers"),
        _objMiscDetailsService: Ember.inject.service("rest-agency-group-candidate-misc-details"),
        _objAddressDetailsService: Ember.inject.service("rest-agency-group-candidate-address-details"),
        _objUserService: Ember.inject.service("rest-agency-group-users"),
        _objUserAvatarService: Ember.inject.service("rest-agency-group-user-avatar"),
        _objMetaRestService: Ember.inject.service("rest-agency-group-candidate-meta"),

        _objActivityRestService: Ember.inject.service("rest-agency-group-candidate-activity"),
        _objActivityTypeRestService: Ember.inject.service("rest-advanced-search-activity-type"),

        _objStringsCandidateProfile: Ember.inject.service("a24-strings-candidate-profile"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),

        _objConsultantService: Ember.inject.service("rest-agency-group-candidate-consultant"),
        _objConsultantRoleService: Ember.inject.service("rest-agency-group-consultant-role"),
        _objCandidateTypeRestService: Ember.inject.service("rest-agency-group-candidate-type"),
        _objSettingsRestService: Ember.inject.service("rest-agency-group-candidate-settings"),
        _objRestAgencyGroupCandidateActivityEmailComm: Ember.inject.service("rest-agency-group-activity-email-communication-message"),
        _objRestAgencyGroupCandidateActivityConversation: Ember.inject.service("rest-agency-group-activity-conversation"),
        _objRestAgencyGroupCandidateActivityNotification: Ember.inject.service("rest-agency-group-activity-notification"),

        _objStatusErrorPageService: Ember.inject.service("status-error-page"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objUserSession: Ember.inject.service("user-session"),
        _bDisplayPermissionError: false,
        _sActivityContext: null,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            var objCandidateViewParams = this.get("_objNavigationService.objRouteParams")["agency-group.candidate-view"];
            var objCandidateSearchViewParams = this.get("_objNavigationService.objRouteParams")["agency-group.candidate-search-view"];

            if (!a24Core.isEmpty(objCandidateViewParams)) {
                this.set("_sCandidateId", objCandidateViewParams.candidate_id);
            }
            if (!a24Core.isEmpty(objCandidateSearchViewParams)) {
                this.set("_sCandidateId", objCandidateSearchViewParams.candidate_id);
            }

            this.set("_sActivityContext", this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP_HYPHEN").toLowerCase());

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objStringsCandidateProfile").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.set("_bDisplayReloadCard", false);
                }
            }]);
        },
        actions: {
            onGetBasicInfoFailure: function onGetBasicInfoFailure(sCode) {
                if (sCode === "403") {
                    this.set("_objStatusErrorPageService.sIcon", "warning");
                    this.set("_objStatusErrorPageService.sMessage", this.get("_objStringsCandidateProfile").getString("permissionsError"));
                    this.set("_bDisplayPermissionError", true);
                } else {
                    this.set("_bDisplayReloadCard", true);
                }
            },
            onGetBasicInfoSuccess: function onGetBasicInfoSuccess(objData) {
                var sCandidateProfile = this.get("_objStringsCandidateProfile").getString("candidateProfile");
                var sCandidateName = objData.first_name + " " + objData.last_name;

                this.set("_sCandidateName", sCandidateName);

                this.get("_objTitleBarService").setTitleBarPartially(sCandidateProfile + " - " + sCandidateName);
            }
        }
    });
});