define("a24-ember-lib/mixins/query-param-object-builder", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        objQueryParams: null,
        _sPageParam: null,
        _objQueryParamBuilderService: Ember.inject.service("query-param-object-builder"),
        _funcObserverCallback: function _funcObserverCallback() {
            Ember.run.once(this, this.createQueryParamObject);
        },
        _funcQueryParamsObserverCallback: function _funcQueryParamsObserverCallback() {
            this.setQueryParams();
        },
        init: function init() {
            this._super.apply(this, arguments);
            //If the page param is going to be used(thus not unset) then check to see that is has a required default value
            if (typeof this.get("page") !== "undefined") {
                if (a24Core.isEmpty(this.get("page"))) {
                    console.error( //eslint-disable-line no-console
                    "The url query param for 'page' cannot have a default value of empty");
                } else {
                    this.set("_sPageParam", this.get("page"));
                }
            }

            var objThis = this;
            var arrQueryParams = this.get("queryParams");

            //Manually add observes since we want to control them so that they do not fire when the mixin is changing these
            //values else you would end up with an infinite observe
            this.addObserver("objQueryParams", this, this.get("_funcQueryParamsObserverCallback"));
            for (var i = 0; i < arrQueryParams.length; i++) {
                var sQueryParam = arrQueryParams[i];
                //Add observer to each of the query params so that we can rebuild the objQueryParams when any params change
                this.addObserver(sQueryParam, this, this.get("_funcObserverCallback"));
            }

            this.set("objQueryParams", this.get("_objQueryParamBuilderService").createQueryParamObject(this.get("queryParams"), this, null, true, false));

            Ember.run.once(objThis, objThis.createQueryParamObject);
        },
        createQueryParamObject: function createQueryParamObject() {
            //This function will fire when value of query params in the url changes

            //Remove observer from objQueryParams while we build the new object for it so that it only triggers observes for
            //outside components observing the objQueryParams
            this.removeObserver("objQueryParams", this, this.get("_funcQueryParamsObserverCallback"));

            //We copy the object here without using the ember copy since we want a clean object without any binding and not
            //an ember object that would be returned by the ember copy
            var objExistingQueryParams = JSON.parse(JSON.stringify(this.get("objQueryParams")));

            //Set the copy of the object back onto the property so that it triggers observe for entire object
            //This is so that components only need to observe the object and not all the properties individually,
            //since @each observe only works on arrays
            this.set("objQueryParams", this.get("_objQueryParamBuilderService").createQueryParamObject(this.get("queryParams"), this, objExistingQueryParams, false, true));

            //Add the observer for the objQueryParams back
            this.addObserver("objQueryParams", this, this.get("_funcQueryParamsObserverCallback"));
        },
        setQueryParams: function setQueryParams() {
            //This function will listen for changes on the objQueryParams from outside and then update the url with the new
            //values for the query params
            var objQueryParams = this.get("objQueryParams");
            for (var sProperty in objQueryParams) {
                this.removeObserver(sProperty, this, this.get("_funcObserverCallback"));
                this.set(sProperty, objQueryParams[sProperty].sValue);
                this.addObserver(sProperty, this, this.get("_funcObserverCallback"));
            }
        },
        //We have to observe the page param separately since this is a special case for datagrids, since it is not part if
        //the config in for the array of headers
        //We observe the objQueryParams.page.sValue here and set that onto another property since the objQueryParams will
        //get replaced completely each time and thus fire the observes each time regardless if the actual value for page
        //changed
        observeParamObjectPageParam: Ember.observer("objQueryParams.page.sValue", function () {
            this.set("_sPageParam", this.get("objQueryParams.page.sValue"));
        }),
        setPageParam: Ember.observer("_sPageParam", function () {
            this.removeObserver("page", this, this.get("_funcObserverCallback"));
            this.set("page", this.get("_sPageParam"));
            this.addObserver("page", this, this.get("_funcObserverCallback"));
        })

        //#### EXAMPLE OF WHAT CONTROLLER SHOULD LOOK LIKE

        //Add all query param names the queryParams and make property for each in controller
        //queryParams: ["one", "two", "three", "four__one", "four___one"],
        //one: "some default", //Give filter default value, when param has this value it will not display in url
        //two: "", //Set to empty string and give value in run next inside init if you want default value in url
        //three: "", //Set all other params that do not have default values to empty string
        //four__one: "", //Use "__" for nested objects
        //four___one: "", //Use "___" for nested arrays
        //init: function() {
        //    this._super(...arguments);
        //    var objThis = this;
        //    //Set value in run next when you want it to display default value in url
        //    Ember.run.next(function() {
        //        objThis.set("two", "show default value two in url");
        //    });
        //}
    });
});