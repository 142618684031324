var a24RestUrlConstructExtend = {
    sUrl: "",
    arrParameterList: null,
    arrSortByList: null,
    bIsSwagger: false,
    /**
     * Set the swagger base url for the construction helper
     *
     * Note: This will reset all other fields
     *
     * @param sBaseUrl - The base url that has no params added to it.
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since  24 May 2017
     *
     * @return void
     */
    setBaseUrlSwagger: function(sBaseUrl) {
        a24RestUrlConstruct.setBaseUrl(sBaseUrl);
        a24RestUrlConstruct.bIsSwagger = true;
    },
    /**
     * Set the base url for the construction helper.
     *
     * Note: This will reset all other fields
     *
     * @param sBaseUrl - The base url that has no params added to it.
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     * @since  13 August 2014
     *
     * @return void
     */
    setBaseUrl: function(sBaseUrl) {
        a24RestUrlConstruct.bIsSwagger = false;
        a24RestUrlConstruct.arrParameterList = [];
        a24RestUrlConstruct.arrSortByList = [];
        a24RestUrlConstruct.sUrl = sBaseUrl;
    },
    /**
     * Add a query field to the url
     *
     * Note: If mFieldValue is empty, then the mDefault will be applied.
     * Note: If mFieldValue and mDefault is empty, the param will be omitted from the url.
     *
     * @param sFieldName - The name of the field used in the url
     * @param mFieldValue - The value of this field
     * @param mDefault (Optional) - If no value is set, the value that will be given
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     * @since  13 August 2014
     *
     * @return void
     */
    addQueryParam: function(sFieldName, mFieldValue, mDefault) {
        mFieldValue = (a24Core.isEmpty(mFieldValue)) ? mDefault : mFieldValue;

        if (!a24Core.isEmpty(mFieldValue)) {
            a24RestUrlConstruct.arrParameterList.push(sFieldName + "=" + mFieldValue);
        }
    },
    /**
     * This function will add Query Params from a prebuilt object list
     *
     * @param objQueryParams - The object containing the optional query params
     * @param objDefault - The default object containing optional query params
     *
     *     {
     *         sFieldName: {
     *             mValue: "value",
     *             mDefault: "value",
     *             bDelimited: false
     *         }
     *     }
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     * @since  13 August 2014
     *
     * @return void
     */
    addQueryParamObject: function(objQueryParams, objDefault) {
        objQueryParams = (a24Core.isEmpty(objQueryParams)) ? objDefault : objQueryParams;

        if (!a24Core.isEmpty(objQueryParams)) {
            // Loop over all the query params
            $.each(objQueryParams, function(sFieldName, objField) {
                var mValue = objField.mValue;
                //The encoding for the bFromAndTo will be done in the addFromAndToParam function since encoding need
                //to happen after the query param has been build up
                if (!objField.bFromAndTo && !objField.bNotWithinLast && !objField.bNotWithinNext) {
                    if (!a24Core.isEmpty(objField.bEncode) && objField.bEncode) {
                        mValue = encodeURIComponent(mValue);
                    }
                    if (!a24Core.isEmpty(objField.bLike) && objField.bLike) {
                        mValue = "*" + mValue + "*";
                    }
                }
                if (objField.bDelimited) {
                    // Add delimited field
                    a24RestUrlConstruct.addDelimitedParam(
                        sFieldName,
                        mValue,
                        objField.mDefault
                    );
                } else if (objField.bFrom) {
                    a24RestUrlConstruct.addFromParam(sFieldName, mValue, objField.mDefault);
                } else if (objField.bTo) {
                    a24RestUrlConstruct.addToParam(sFieldName, mValue, objField.mDefault);
                } else if (objField.bSortBy) {
                    a24RestUrlConstruct.addSortBy(sFieldName, mValue);
                } else if (objField.bFromAndTo || objField.bNotWithinLast || objField.bNotWithinNext) {
                    var bEncode = !a24Core.isEmpty(objField.bEncode) && objField.bEncode;
                    if (objField.bFromAndTo) {
                        a24RestUrlConstruct.addFromAndToParam(
                            sFieldName, mValue, bEncode, objField.mDefault
                        );
                    } else if (objField.bNotWithinLast) {
                        a24RestUrlConstruct.addToParam(sFieldName, mValue.sFrom, objField.mDefault);
                    } else if (objField.bNotWithinNext) {
                        a24RestUrlConstruct.addFromParam(sFieldName, mValue.sTo, objField.mDefault);
                    }
                } else if (objField.bSubProperty) {
                    //This is when we need to query on sub properties eg. someproperty.subproperty
                    a24RestUrlConstruct.addQueryParam(
                        objField.sName,
                        mValue,
                        objField.mDefault
                    );
                } else {
                    // Add standard field
                    a24RestUrlConstruct.addQueryParam(
                        sFieldName,
                        mValue,
                        objField.mDefault
                    );
                }
            });
        }
    },
    /**
     * Add a query field with the "from::???|to::???" for date queries.
     *
     * Note: If mFieldValue is empty, then the mDefault will be applied.
     * Note: If mFieldValue and mDefault is empty, the param will be omitted from the url.
     *
     * @param sFieldName - The name of the field used in the url
     * @param mFieldValue - The value of this field
     * @param bEncode - Whether to encode the param
     * @param mDefault (Optional) - If no value is set, the value that will be given
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since  13 Nov 2014
     *
     * @return void
     */
    addFromAndToParam: function(sFieldName, mFieldValue, bEncode, mDefault) {
        mFieldValue = (a24Core.isEmpty(mFieldValue)) ? mDefault : mFieldValue;

        if (!a24Core.isEmpty(mFieldValue)) {
            if (a24RestUrlConstruct.bIsSwagger) {
                var sFromFieldName = "from-" + sFieldName;
                var sQueryStringFrom = mFieldValue.sFrom;
                if (bEncode) {
                    sQueryStringFrom = encodeURIComponent(sQueryStringFrom);
                }
                a24RestUrlConstruct.addQueryParam(sFromFieldName, sQueryStringFrom);
                var sToFieldName = "to-" + sFieldName;
                var sQueryStringTo = mFieldValue.sTo;
                if (bEncode) {
                    sQueryStringTo = encodeURIComponent(sQueryStringTo);
                }
                a24RestUrlConstruct.addQueryParam(sToFieldName, sQueryStringTo);
            } else {
                var sQueryString = "from::" + mFieldValue.sFrom + "|to::" + mFieldValue.sTo;
                if (bEncode) {
                    sQueryString = encodeURIComponent(sQueryString);
                }
                a24RestUrlConstruct.addQueryParam(sFieldName, sQueryString);
            }
        }
    },
    /**
     * Add a query field with the "in::" prefixed to the value, to the url.
     *
     * Note: If mFieldValue is empty, then the mDefault will be applied.
     * Note: If mFieldValue and mDefault is empty, the param will be omitted from the url.
     *
     * @param sFieldName - The name of the field used in the url
     * @param mFieldValue - The value of this field
     * @param mDefault (Optional) - If no value is set, the value that will be given
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     * @since  13 August 2014
     *
     * @return void
     */
    addDelimitedParam: function(sFieldName, mFieldValue, mDefault) {
        mFieldValue = (a24Core.isEmpty(mFieldValue)) ? mDefault : mFieldValue;
        if (!a24Core.isEmpty(mFieldValue)) {
            if (a24RestUrlConstruct.bIsSwagger) {
                var mValue = mFieldValue.replace(/%7C/g, "%2C");
                // the /g modifier replaces all occurances of the string
                // using the encoded "|" and "," since mFieldValue is already encoded at this stage
                a24RestUrlConstruct.addQueryParam(sFieldName, mValue);
            } else {
                a24RestUrlConstruct.addQueryParam(sFieldName, "in::" + mFieldValue);
            }
        }
    },
    /**
     * Add a query field with the "to::" prefixed to the value, to the url.
     *
     * Note: If mFieldValue is empty, then the mDefault will be applied.
     * Note: If mFieldValue and mDefault is empty, the param will be omitted from the url.
     *
     * @param sFieldName - The name of the field used in the url
     * @param mFieldValue - The value of this field
     * @param mDefault (Optional) - If no value is set, the value that will be given
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since  01 Dec 2014
     *
     * @return void
     */
    addToParam: function(sFieldName, mFieldValue, mDefault) {
        mFieldValue = (a24Core.isEmpty(mFieldValue)) ? mDefault : mFieldValue;

        if (!a24Core.isEmpty(mFieldValue)) {
            if (a24RestUrlConstruct.bIsSwagger) {
                a24RestUrlConstruct.addQueryParam("to-" + sFieldName, mFieldValue);
            } else {
                a24RestUrlConstruct.addQueryParam(sFieldName, "to::" + mFieldValue);
            }
        }
    },
    /**
     * Add a query field with the "from::" prefixed to the value, to the url.
     *
     * Note: If mFieldValue is empty, then the mDefault will be applied.
     * Note: If mFieldValue and mDefault is empty, the param will be omitted from the url.
     *
     * @param sFieldName - The name of the field used in the url
     * @param mFieldValue - The value of this field
     * @param mDefault (Optional) - If no value is set, the value that will be given
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since  01 Dec 2014
     *
     * @return void
     */
    addFromParam: function(sFieldName, mFieldValue, mDefault) {
        mFieldValue = (a24Core.isEmpty(mFieldValue)) ? mDefault : mFieldValue;

        if (!a24Core.isEmpty(mFieldValue)) {
            if (a24RestUrlConstruct.bIsSwagger) {
                a24RestUrlConstruct.addQueryParam("from-" + sFieldName, mFieldValue);
            } else {
                a24RestUrlConstruct.addQueryParam(sFieldName, "from::" + mFieldValue);
            }
        }
    },
    /**
     * Add a sort by criteria for the url
     *
     * Note: If sOrder is empty, "desc" will be applied by default
     *
     * @param sFieldName - The name of the field used in the url
     * @param sOrder (Optional) - Whether to sort the field asc or desc
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     * @since  13 August 2014
     *
     * @return void
     */
    addSortBy: function(sFieldName, sOrder) {
        // If order is not asc, any other case becomes desc
        if (a24RestUrlConstruct.bIsSwagger) {
            sOrder = (sOrder === "asc") ? "" : "-";
            a24RestUrlConstruct.arrSortByList.push(sOrder + sFieldName);
        } else {
            sOrder = (sOrder === "asc") ? "asc" : "desc";
            a24RestUrlConstruct.arrSortByList.push(sFieldName + "::" + sOrder);
        }
    },
    /**
     * Adds a list of sort by fields
     *
     * @param arrSortObject - The sortBy object array build up as [{sFieldName: sOrder}, {sFieldName2: sOrder}]
     * @param arrDefaultSortObject - The default value for the sort object array
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     * @since  13 August 2014
     *
     * @return void
     */
    addMultiSortObject: function(arrSortObject, arrDefaultSortObject) {
        arrSortObject =  (a24Core.isEmpty(arrSortObject)) ? arrDefaultSortObject : arrSortObject;
        // Loop over indexed list (for order integrity)
        $.each(arrSortObject, function(sKey, objValue) {
            // Pull key value from object to assign new field
            $.each(objValue, function(sFieldName, sFieldValue) {
                a24RestUrlConstruct.addSortBy(sFieldName, sFieldValue);
            });
        });
    },
    /**
     * This function will build up the final url with parameters and sorting applied
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     * @since  13 August 2014
     *
     * @returns String The completed url.
     */
    getConstructedUrl: function() {
        var sFinalUrl = a24RestUrlConstruct.sUrl;
        if (a24RestUrlConstruct.arrSortByList.length !== 0) {
            var sSortString = "sortBy=";
            if (a24RestUrlConstruct.bIsSwagger) {
                sSortString += a24RestUrlConstruct.arrSortByList.join("%2C");
            } else {
                sSortString += a24RestUrlConstruct.arrSortByList.join("%7C");
            }

            a24RestUrlConstruct.arrParameterList.push(sSortString);
        }
        if (a24RestUrlConstruct.arrParameterList.length !== 0) {
            sFinalUrl += "?" + a24RestUrlConstruct.arrParameterList.join("&");
        }
        return sFinalUrl;
    }
};

/**
 * This is the a24RSVP that will be used to make a series of service calls and
 * have callbacks only execute once all the services they require are complete.
 *
 * This acts similar to the Ember.RSVP but this RSVP adds the ability to do multiple
 * callbacks rather than a single success, and secondly allows failures to happen so that
 * a 404 does not make the entire RSVP object cancel and fail.
 *
 * @author Michael Barnard <michael.barnard@a24group.com>
 * @since  12 November 2015
 */
var a24RSVPExtend = {
    objPromiseConfig: {},
    arrCallback: [],
    objControllerInstance: null,
    /**
     * This function is the main entry point for the a24RSVP
     *
     * This function will prep the configurations sent in to
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     * @since  12 November 2015
     *
     * @param objController - The controller that will have the data set to it on local properties
     * @param objPromiseConfig - The object containing all the promise objects
     * @param arrCallbackConfigurations - The configurations used to set up all the callback functions that need
     *     to be called when the various service calls complete
     */
    configure: function(objController, objPromiseConfig, arrCallbackConfigurations) {
        var objRSVP = this; // Create a this reference
        $.each(objPromiseConfig, function(sKey, objPromise) {
            // Create a new structure from the promise
            objPromiseConfig[sKey] = {
                bDone: false, // State indicator
                objPromise: objPromise //promise object
            };
            // Add a "listener" to the promise
            $.when(objPromise).then(
                // When the promise is resolved
                function(objData, sStatus, jqXHR) {
                    objRSVP.onSingleSuccess(sKey, objData, sStatus, jqXHR);
                },
                // When the promise is rejected
                function(jqXHR, sStatus, errorThrown) {
                    objRSVP.onSingleFailure(sKey, jqXHR, sStatus, errorThrown);
                }
            );
        });
        // Set the new promise config to an object variable
        this.objPromiseConfig = objPromiseConfig;
        // Set the callback config to an object variable
        this.arrCallback = arrCallbackConfigurations;
        // Set the new promise config to an object variable
        this.objControllerInstance = objController;
    },
    /**
     * This function will be called when a promise is resolved
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     * @since  12 November 2015
     *
     * @param sKey - The key of the promise that succeeded
     * @param objData - The response of the service call
     * @param sStatus - The status of the service call
     * @param jqXHR - The response header of the service call
     */
    onSingleSuccess: function(sKey, objData, sStatus, jqXHR) {
        this.objPromiseConfig[sKey].bDone = true;
        if (a24Core.isEmpty(objData)) {
            // If the data is empty, we standard it to null
            objData = null;
        }
        this.objPromiseConfig[sKey].objResponse = objData; // Set the data on the promise config
        this.objControllerInstance.set(sKey, objData); // Set the data on the controller
        this.objPromiseConfig[sKey].objHeaders = jqXHR; // Set the headers on the promise config
        // Process to see if callbacks should be called
        this.processCallbackConfig(sKey);
    },
    /**
     * This function will be called when a promise is rejected
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     * @since  12 November 2015
     *
     * @param sKey - The key of the promise that succeeded
     * @param jqXHR - The response header of the service call
     * @param sStatus - The status of the service call
     * @param errorThrown - The error that is thrown by the promise
     */
    onSingleFailure: function(sKey, jqXHR, sStatus, errorThrown) {
        this.objPromiseConfig[sKey].bDone = true;
        this.objPromiseConfig[sKey].objResponse = null; // Set the data on the promise to null
        this.objControllerInstance.set(sKey, null); // Set the data on the controller to null
        this.objPromiseConfig[sKey].objHeaders = jqXHR; // Set the headers on the promise config
        // Process to see if callbacks should be called
        this.processCallbackConfig(sKey);
    },
    /**
     * This function is used to check whether any of the callbacks should be called based on
     * a completed service call. This will fire every time any of the promise objects gets
     * rejected or resolved.
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     * @since  12 November 2015
     *
     * @param sKey - The key of the promise that completed
     */
    processCallbackConfig: function(sKey) {
        // This reference
        var objRSVP = this;
        // Iterate over the various callback configurations
        $.each(this.arrCallback, function(iCallbackIndex, objCallbackConfig) {
            // Set whether we execute equal to whether the current config needs this service
            var bExecute = $.inArray(sKey, objCallbackConfig.arrRequiredResponses) != -1;
            // If the current config does not need the service, we skip this step
            if (bExecute) {
                // Iterate over the keys required for a specific configuration
                $.each(objCallbackConfig.arrRequiredResponses, function(sRequireKey, sRequireValue) {

                    // If the current keys service is not done
                    if (!objRSVP.objPromiseConfig[sRequireValue].bDone) {
                        // Stop function from executing
                        bExecute = false;
                        return false; // Break
                    }
                });
            }
            // Execute callback if all services have completed and service is required in service config
            if (bExecute) {
                objCallbackConfig.funcOnComplete(
                    objRSVP.objPromiseConfig // Send along promise object for data
                );
            }
        });
    }
};

var a24RestCallHelperExtend = {
    /**
     * This function will be used to take all the data given by the datagrid and convert it to the correct
     * post body that can be used by a post type datagrid
     *
     * @param objFilterParamObject - The param generated by the filter component
     * @param objPermFilterParamObject - The param that must always be applied
     * @param objSortObject - The param generated by the sorting on the datagrid
     * @param iPage - The current page number of the data
     * @param iPerPage - The amount of results to display per page
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     */
    convertHooksToPostBody: function(
        objFilterParamObject,
        objPermFilterParamObject,
        objSortObject,
        iPage,
        iPerPage,
        objPayloadOverride,
        objProjectionQuery
    ) {
        var objReturn = {
            page: iPage,
            items_per_page: iPerPage
        };

        objReturn = this.createQueryParamForBody(objReturn, objFilterParamObject);
        objReturn = this.createQueryParamForBody(objReturn, objProjectionQuery);
        objReturn = this.createQueryParamForBody(objReturn, objPermFilterParamObject);
        objReturn = this.createQueryParamForBody(objReturn, objSortObject);

        if (!a24Core.isEmpty(objPayloadOverride)) {
            $.each(objPayloadOverride, function(sFieldName, mFieldValue) {
                // For now this is just straight override with no support for query types like bLike etc.
                // This is because this is meant to override post body params on top level.
                // Can add more support in future when we have better idea of other cases
                if (!a24Core.isEmpty(mFieldValue) && !a24Core.isEmpty(mFieldValue.mValue)) {
                    objReturn[sFieldName] = mFieldValue.mValue;
                }
            });
        }

        return objReturn;
    },

    /**
     * This function will be used to take all the data given by the datagrid and convert it to the correct
     * post body that can be used by a post type datagrid
     *
     * @param objFilterParamObject - The param generated by the filter component
     * @param objPermFilterParamObject - The param that must always be applied
     * @param objSortObject - The param generated by the sorting on the datagrid
     * @param iPage - The current page number of the data
     * @param iPerPage - The amount of results to display per page
     *
     * @author Ahmed Onawale<ahmedonawale@gmail.com>
     * @since  19 September 2018
     */
    convertHooksToPostBodyV2: function(
        objFilterParamObject,
        objPermFilterParamObject,
        objSortObject,
        iPage,
        iPerPage,
        objPayloadOverride,
        objProjectionQuery
    ) {
        var objReturn = {
            page: iPage,
            items_per_page: iPerPage
        };

        objReturn = this.createQueryParamForBodyV2(objReturn, objFilterParamObject);
        objReturn = this.createQueryParamForBodyV2(objReturn, objProjectionQuery);
        objReturn = this.createQueryParamForBodyV2(objReturn, objPermFilterParamObject);
        objReturn = this.createQueryParamForBodyV2(objReturn, objSortObject);

        if (!a24Core.isEmpty(objPayloadOverride)) {
            $.each(objPayloadOverride, function(sFieldName, mFieldValue) {
                // For now this is just straight override with no support for query types like bLike etc.
                // This is because this is meant to override post body params on top level.
                // Can add more support in future when we have better idea of other cases
                if (!a24Core.isEmpty(mFieldValue) && !a24Core.isEmpty(mFieldValue.mValue)) {
                    objReturn[sFieldName] = mFieldValue.mValue;
                }
            });
        }

        return objReturn;
    },

    createQueryParamForBodyV2: function(objBody, objQueryParams) {
        var objThis = this;
        if (!a24Core.isEmpty(objQueryParams)) {

            // Loop over all the query params
            $.each(objQueryParams, function(sFieldName, mFieldValue) {
                if (mFieldValue.bProject) {
                    // Create the projection part if not yet created
                    if (!objBody.hasOwnProperty("project")) {
                        objBody.project = {};
                    }

                    objBody.project[sFieldName] = mFieldValue.mValue;

                } else if (mFieldValue.bSortBy) {
                    // Create the sort part if not yet created
                    if (a24Core.isEmpty(objBody.sort)) {
                        objBody.sort = [];
                    }

                    // Handle the sort by field
                    var sSortItem = sFieldName.replace("__", ".");

                    if (mFieldValue.mValue === "desc") {
                        sSortItem = "-" + sSortItem;
                    }

                    objBody.sort.push(sSortItem);
                } else {
                    // Create the query part if not yet created
                    if (a24Core.isEmpty(objBody.query)) {
                        objBody.query = {
                            and: []
                        };
                    }

                    // For now we only support nested object or nested array, not nested array in nested object etc
                    // For now we also build up nested object and arrays the same way until there is need for more
                    // complex queries.
                    var arrProperties = [sFieldName];
                    var bNestedArray = false;
                    if (sFieldName.indexOf("___") != -1) { //nested array
                        arrProperties = sFieldName.split("___");
                        bNestedArray = true;
                    } else if (sFieldName.indexOf("__") != -1) { //nested object
                        arrProperties = sFieldName.split("__");
                    }

                    var mValue = null;
                    if (mFieldValue instanceof Array && mFieldValue.length > 1) {
                        var bAndPrio = false;
                        if (!a24Core.isEmpty(mFieldValue[1].bA) && mFieldValue[1].bA) {
                            bAndPrio = true;
                        }

                        var arrTagItems = [];
                        var iGroup = 0;
                        var bNeedsBrackets = false;

                        //First we group all the items based on the first operation the user chose
                        for (var i = 0; i < mFieldValue.length; i++) {
                            var objTagItem = {
                                iGroup: null,
                                objFilter: {}
                            };

                            let arrPropertiesInner = arrProperties;
                            let bNestedArrayInner = bNestedArray;
                            if (mFieldValue[i].sProp) {
                                arrPropertiesInner = [mFieldValue[i].sProp];
                                bNestedArrayInner = false;
                                if (mFieldValue[i].sProp.indexOf("___") != -1) { //nested array
                                    arrPropertiesInner = mFieldValue[i].sProp.split("___");
                                    bNestedArrayInner = true;
                                } else if (mFieldValue[i].sProp.indexOf("__") != -1) { //nested object
                                    arrPropertiesInner = mFieldValue[i].sProp.split("__");
                                }
                            }

                            //NOTE for now we only support a nesting one level deep for advance filter items
                            if (arrPropertiesInner.length > 1) {
                                if (!a24Core.isEmpty(mFieldValue[i].mCstmQry)) {
                                    // We went with this approach since we wanted to override the query
                                    // and the best way is for it to run through the create query func again
                                    // for when we want a custom query and attach that to the existing prop
                                    // This way we can still feed it a query our datagrid understands
                                    // and our rest helper can create a backend query for it
                                    let objQuery = {}
                                    objQuery[sFieldName] = mFieldValue[i].mCstmQry
                                    let objCustomQuery = objThis.createQueryParamForBodyV2(
                                        {},
                                        objQuery
                                    );
                                    objTagItem.objFilter = objCustomQuery.query.and[0]
                                } else {
                                    objTagItem.objFilter[arrPropertiesInner[0]] = objThis._createQueryValuesV2(
                                        arrPropertiesInner[arrPropertiesInner.length - 1],
                                        mFieldValue[i],
                                        bNestedArrayInner
                                    );
                                }
                            } else {
                                if (!a24Core.isEmpty(mFieldValue[i].mCstmQry)) {
                                    // We went with this approach since we wanted to override the query
                                    // and the best way is for it to run through the create query func again
                                    // for when we want a custom query and attach that to the existing prop
                                    // This way we can still feed it a query our datagrid understands
                                    // and our rest helper can create a backend query for it
                                    let objQuery = {}
                                    objQuery[sFieldName] = mFieldValue[i].mCstmQry
                                    let objCustomQuery = objThis.createQueryParamForBodyV2(
                                        {},
                                        objQuery
                                    );
                                    objTagItem.objFilter = objCustomQuery.query.and[0]
                                } else {
                                    objTagItem.objFilter = objThis._createQueryValuesV2(
                                        arrPropertiesInner[arrPropertiesInner.length - 1],
                                        mFieldValue[i],
                                        bNestedArrayInner
                                    );
                                }
                            }

                            if (i === 0) {
                                iGroup = iGroup + 1;
                                objTagItem.iGroup = iGroup;
                            } else if (
                                bAndPrio && mFieldValue[i].bA ||
                                !bAndPrio && mFieldValue[i].bO
                            ) {
                                if (a24Core.isEmpty(arrTagItems[i - 1].iGroup)) {
                                    iGroup = iGroup + 1;
                                    objTagItem.iGroup = iGroup;
                                    arrTagItems[i - 1].iGroup = iGroup;
                                } else {
                                    objTagItem.iGroup = arrTagItems[i - 1].iGroup;
                                }
                            } else {
                                bNeedsBrackets = true;
                            }
                            arrTagItems.push(objTagItem);
                        }

                        var arrMain = [];
                        var arrGroup = null;
                        var iCurrentGroup = 0;

                        for (var j = 0; j < arrTagItems.length; j++) {
                            if (bNeedsBrackets) {
                                if (a24Core.isEmpty(arrTagItems[j].iGroup)) {
                                    if (!a24Core.isEmpty(arrGroup)) {
                                        arrMain.push(bAndPrio ? {and: arrGroup} : {or: arrGroup});
                                        arrGroup = null;
                                        iCurrentGroup = 0;
                                    }
                                    arrMain.push(arrTagItems[j].objFilter);
                                } else if (arrTagItems[j].iGroup !== iCurrentGroup) {
                                    if (a24Core.isEmpty(arrGroup)) {
                                        iCurrentGroup = arrTagItems[j].iGroup;
                                        arrGroup = [arrTagItems[j].objFilter];
                                    } else {
                                        arrMain.push(bAndPrio ? {and: arrGroup} : {or: arrGroup});
                                        iCurrentGroup = arrTagItems[j].iGroup;
                                        arrGroup = [arrTagItems[j].objFilter];
                                    }
                                } else {
                                    arrGroup.push(arrTagItems[j].objFilter);
                                }
                            } else {
                                arrMain.push(arrTagItems[j].objFilter);
                            }
                        }
                        //This caters for the scenario where there is only one group and also for the last group in the
                        //set
                        if (!a24Core.isEmpty(arrGroup)) {
                            arrMain.push(bAndPrio ? {and: arrGroup} : {or: arrGroup});
                        }

                        var objFilter = null;
                        if (bNeedsBrackets) {
                            objFilter = bAndPrio ? {or: arrMain} : {and: arrMain};
                        } else {
                            objFilter = bAndPrio ? {and: arrMain} : {or: arrMain};
                        }

                        objBody.query.and.push(objFilter);
                    } else if (mFieldValue instanceof Array) {

                        var objFilterSingle = {};

                        if (mFieldValue[0].sProp) {
                            arrProperties = [mFieldValue[0].sProp];
                            bNestedArray = false;
                            if (mFieldValue[0].sProp.indexOf("___") != -1) { //nested array
                                arrProperties = mFieldValue[0].sProp.split("___");
                                bNestedArray = true;
                            } else if (mFieldValue[0].sProp.indexOf("__") != -1) { //nested object
                                arrProperties = mFieldValue[0].sProp.split("__");
                            }
                        }
                        if (!a24Core.isEmpty(mFieldValue[0].mCstmQry)) {
                            // We went with this approach since we wanted to override the query
                            // and the best way is for it to run through the create query func again
                            // for when we want a custom query and attach that to the existing prop
                            // This way we can still feed it a query our datagrid understands
                            // and our rest helper can create a backend query for it
                            let objQuery = {}
                            objQuery[sFieldName] = mFieldValue[0].mCstmQry
                            let objCustomQuery = objThis.createQueryParamForBodyV2(
                                {},
                                objQuery
                            );
                            mValue = objCustomQuery.query.and[0];
                        } else {
                            mValue = objThis._createQueryValuesV2(
                                arrProperties[arrProperties.length - 1],
                                mFieldValue[0],
                                bNestedArray
                            );
                        }

                        if (!a24Core.isEmpty(mFieldValue[0].mCstmQry)) {
                            objFilterSingle = mValue;
                        } else if (arrProperties.length > 1) {
                            objFilterSingle[arrProperties[0]] = mValue;
                        } else {
                            objFilterSingle = mValue;
                        }

                        objBody.query.and.push(objFilterSingle);
                    } else {
                        if (!a24Core.isEmpty(mFieldValue.mCstmQry)) {
                            // We went with this approach since we wanted to override the query
                            // and the best way is for it to run through the create query func again
                            // for when we want a custom query and attach that to the existing prop
                            // This way we can still feed it a query our datagrid understands
                            // and our rest helper can create a backend query for it
                            let objQuery = {}
                            objQuery[sFieldName] = mFieldValue.mCstmQry
                            let objCustomQuery = objThis.createQueryParamForBodyV2(
                                {},
                                objQuery
                            );
                            mValue = objCustomQuery.query.and[0];
                        } else {
                            mValue = objThis._createQueryValueV2(mFieldValue);
                        }

                        if (mFieldValue.sProp) {
                            arrProperties = [mFieldValue.sProp];
                            bNestedArray = false;
                            if (mFieldValue.sProp.indexOf("___") != -1) { //nested array
                                arrProperties = mFieldValue.sProp.split("___");
                                bNestedArray = true;
                            } else if (mFieldValue.sProp.indexOf("__") != -1) { //nested object
                                arrProperties = mFieldValue.sProp.split("__");
                            }
                        }

                        var objFilterPlain = {};
                        if (!a24Core.isEmpty(mFieldValue.mCstmQry)) {
                            objFilterPlain = mValue;
                        } else if (mFieldValue.bContainsAny) {
                            objFilterPlain[arrProperties[0]] = mValue;
                        } else {
                            var objTemp = objFilterPlain;
                            for(var k = 0; k < arrProperties.length; k++) {
                                if (k === arrProperties.length - 1) {
                                    if (bNestedArray && mFieldValue.bApplyContains) {
                                        objTemp["contains"] = [{}];
                                        objTemp = objTemp.contains[0][arrProperties[k]] = mValue;
                                    } else {
                                        objTemp = objTemp[arrProperties[k]] = mValue;
                                    }
                                } else {
                                    objTemp = objTemp[arrProperties[k]] = {};
                                }
                            }
                        }

                        objBody.query.and.push(objFilterPlain);
                    }
                }
            });
        }

        return objBody;
    },
    _createQueryValuesV2: function(sPrimaryProperty, objField, bNestedArray) {
        var objFilterMulti = {};
        if (bNestedArray && !a24Core.isEmpty(objField.arSec) && !objField.bContainsAny) {
            objFilterMulti["contains"] = [{}];
            if (!objField.bAny) {
                objFilterMulti.contains[0][sPrimaryProperty] = this._createQueryValueV2(objField);
            }
        } else if (objField.bContainsAny) {
            objFilterMulti = this._createQueryValueV2(objField);
        } else {
            objFilterMulti[sPrimaryProperty] = this._createQueryValueV2(objField);
        }
        if (!a24Core.isEmpty(objField.arSec)) {
            for (var i = 0; i < objField.arSec.length; i++) {
                if (bNestedArray) {
                    objFilterMulti.contains[0][objField.arSec[i].sProp] = this._createQueryValueV2(objField.arSec[i]);
                } else {
                    objFilterMulti[objField.arSec[i].sProp] = this._createQueryValueV2(objField.arSec[i]);
                }
            }
        }
        return objFilterMulti;
    },
    _createQueryValueV2: function(objField) {
        // Get the value from the field
        var mValue = objField.mValue;

        if (mValue == "null") {
            // Null value
            mValue = {
                equal: null
            };
        } else if (objField.bDelimited) {
            var arrValues = mValue.split("|");
            for (var i = 0; i < arrValues.length; i++) {
                if (arrValues[i] === "null") {
                    arrValues[i] = null;
                }
            }
            mValue = {
                in: arrValues
            };
        } else if (objField.bDelimitedNot) {
            mValue = {
                nin: mValue.split("|")
            };
        } else if (objField.bLike) {
            // Like value
            mValue = {
                like: "*" + mValue + "*"
            };
        } else if (objField.bStartWith) {
            mValue = {
                like: mValue + "*"
            };
        } else if (objField.bEndsWith) {
            mValue = {
                like: "*" + mValue
            };
        } else if (
            objField.bFrom ||
            objField.bTo ||
            objField.bFromAndTo ||
            objField.bNotWithinLast ||
            objField.bNotWithinNext
        ) {
            // The field has been identified as a date
            if (objField.bFrom) {
                mValue = {
                    greater_than_equal: mValue
                };
            } else if (objField.bTo) {
                mValue = {
                    less_than_equal: mValue
                };
            } else if (objField.bFromAndTo) {
                mValue = {
                    greater_than_equal: mValue.sFrom,
                    less_than_equal: mValue.sTo
                };
            } else if (objField.bNotWithinLast) {
                mValue = {
                    less_than_equal: mValue.sFrom
                };
            } else if (objField.bNotWithinNext) {
                mValue = {
                    greater_than_equal: mValue.sTo
                };
            }
        } else if (
            objField.bAfter ||
            objField.bBefore ||
            objField.bAfterAndBefore
        ) {
            // The field has been identified as a date
            if (objField.bAfter) {
                mValue = {
                    greater_than: mValue
                };
            } else if (objField.bBefore) {
                mValue = {
                    less_than: mValue
                };
            } else if (objField.bAfterAndBefore) {
                mValue = {
                    greater_than: mValue.sFrom,
                    less_than: mValue.sTo
                };
            }
        } else if (objField.bContainsAny) {
            mValue = [];
            for (var i = 0; i < objField.arExt.length; i++) {
                mValue.push(this._createQueryValuesV2(objField.arExt[i].sProp, objField.arExt[i], false));
            }
            mValue = {"contains_any": mValue};
        } else if (objField.bNear) {
            mValue = {
                "near": {
                    "coordinates": [
                        objField.fLong, objField.fLat
                    ],
                    "max_distance": objField.iMax,
                    "min_distance": objField.iMin
                }
            };
        } else if (objField.bWithin) {
            mValue = {
                "within": {
                    "coordinates": [
                        objField.fLong, objField.fLat
                    ],
                    "max_distance": objField.iMax
                }
            };
        } else {
            mValue = {
                equal: mValue
            };
        }

        return mValue;
    },

    /**
     * This method is used to convert standard datagrid filter values to a post type
     * query object. This function is called with the existing body, and will append the properties
     * that are not yet set. The function will then return the modified object.
     *
     * @param objBody - The original body that will be used to do the post
     * @param objQueryParams - The query param object that should be applied to the body
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     */
    createQueryParamForBody: function(objBody, objQueryParams) {
        if (!a24Core.isEmpty(objQueryParams)) {

            // Loop over all the query params
            $.each(objQueryParams, function(sFieldName, objField) {
                // Get the value from the field
                var mValue = objField.mValue;

                if (objField.bProject) {
                    // Create the projection part if not yet created
                    if (!objBody.hasOwnProperty("project")) {
                        objBody.project = {};
                    }

                    objBody.project[sFieldName] = mValue;

                } else if (objField.bSortBy) {
                    // Create the sort part if not yet created
                    if (!objBody.hasOwnProperty("sort")) {
                        objBody.sort = [];
                    }

                    // Handle the sort by field
                    var sSortItem = sFieldName;

                    if (mValue === "desc") {
                        sSortItem = "-" + sFieldName;
                    }

                    objBody.sort.push(sSortItem);
                } else {
                    // Create the query part if not yet created
                    if (!objBody.hasOwnProperty("query")) {
                        objBody.query = {
                            and: []
                        };
                    }

                    // Append stars to the like field
                    if (mValue == "null") {
                        // Null value
                        mValue = {
                            equal: null
                        };
                    } else if (objField.bDelimited) {
                        mValue = {
                            in: mValue.split("|")
                        };
                    } else if (
                        objField.bFrom ||
                        objField.bTo ||
                        objField.bFromAndTo ||
                        objField.bNotWithinLast ||
                        objField.bNotWithinNext
                    ) {
                        // The field has been identified as a date
                        if (objField.bFrom) {
                            mValue = {
                                greater_than_equal: mValue
                            };
                        } else if (objField.bTo) {
                            mValue = {
                                less_than_equal: mValue
                            };
                        } else if (objField.bFromAndTo) {
                            mValue = {
                                greater_than_equal: mValue.sFrom,
                                less_than_equal: mValue.sTo,
                            };
                        } else if (objField.bNotWithinLast) {
                            mValue = {
                                less_than_equal: mValue.sFrom
                            };
                        } else if (objField.bNotWithinNext) {
                            mValue = {
                                greater_than_equal: mValue.sTo
                            };
                        }
                    } else if (objField.bLike) {
                        // Like value
                        mValue = {
                            like: "*" + mValue + "*"
                        };
                    } else {
                        // Normal equal value
                        mValue = {
                            equal: mValue
                        };
                    }

                    // Add a standard query field
                    var objFilter = {
                        field: sFieldName,
                        op: mValue
                    };

                    objBody.query.and.push(objFilter);
                }
            });

        }
        return objBody;
    },

    /**
     * This function will get the X-Result-Count from the jqXHR and the return it as an int
     * or as 0 if not defined or not a number.
     *
     * @param jqXHR - The jquery XHR from the reponse.
     *
     * @return int - The X-Result-Count value or 0 if it was not found or not a number
     */
    getXResultCount: function(jqXHR) {
        var iXResultCount = parseInt(jqXHR.getResponseHeader("X-Result-Count"));
        if (isNaN(iXResultCount) || a24Core.isEmpty(iXResultCount)) {
            return 0;
        } else {
            return iXResultCount;
        }
    },
    /**
     * This function will simply abort the given promise if not empty and has abort function
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since  22 Jan 2016
     */
    abortRestCall: function(objPromise) {
        if (!a24Core.isEmpty(objPromise) && typeof(objPromise.abort) === "function") {
            objPromise.abort();
        }
    },
    /**
     * Will determine if there is already a service taking place, if there is an existing promise, we will abort it
     *
     * @param objEmberItem The ember object on which the property must exist, component / controller
     * @param sProperty The property name on which to get and set the promise. Can be send in as null for service calls
     *                  that specifically do no need to be canceled when making the same call again
     * @param objPromise The ajax promise
     *
     * @author Neil Nienaber <neil.nienaber@a24group.com>
     * @since  21 September 2015
     *
     * @returns The ajax promise
     */
    doRestServiceCall: function(objEmberItem, sProperty, objPromise) {
        if (!a24Core.isEmpty(sProperty)) {
            var objExistingService = objEmberItem.get(sProperty);
            a24RestCallHelper.abortRestCall(objExistingService);
            objEmberItem.set(sProperty, objPromise);
        }
        return objPromise;
    },
    /**
     * Get the rel="next" part of the Link header from a response
     *
     * @param jqXHR - The header object from the response
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     * @since  6 January 2016
     *
     * @returns The string url for the next page, null if none is found
     */
    getNextUrlFromResponseHeaders: function(jqXHR) {
        // Get the "Link" header as a string
        var sLinkHeader = jqXHR.getResponseHeader("Link");

        // Define the link that will be returned
        var sNextLinkUrl = null;

        // Split sLinkHeader into multiple items
        var arrLinks = sLinkHeader.split(",");

        // Iterate over the items
        $.each(arrLinks, function(iKey, sValue) {
            // Split the url part up
            var arrLinkParts = sValue.split(";");
            // If the end part defines it as next
            if (arrLinkParts[1].trim() === 'rel="next"') {
                // Get the url part for next
                var sUrlPart = arrLinkParts[0].trim();
                // Trim off opening "<" and closing ">"
                sNextLinkUrl = sUrlPart.substring(1, sUrlPart.length - 1);
                // Break from the $.each
                return false;
            }
        });

        // Return the url
        return sNextLinkUrl;
    },
    /**
     * This function is used to make an ajax call with the required headers
     * This is a generic helper used to reduce code duplication
     * This function can perform both GET and POST requests
     *
     * Note: This function only supports Json encoding.
     *
     * @param objContext - The current component calling the make ajax call
     * @param objUserSessionService - An instance of the user session service
     * @param objNavigationService - An instance of the navigation service
     * @param sRequestType - Whether it service call is a GET or a POST
     * @param sUrl - The url of the service call that should be made
     * @param funcSuccess - The function that will be called on a successful service call
     * @param objFailure (Optional) - The object that will deal with failed service calls
     * @param objRequestData (Optional) - The data that will be passed along if the sRequestType is 'POST'
     * @param sContentType (Optional) - The content type i.e. application/json
     * @param bRetrieveAll (Optional) - This option makes the rel="next" link recursively fire until all results
     *     are retrieved.
     * @param bNonSwaggerError (Optional) - True if the error method does not follow the swagger pattern
     * @param bXhrFieldsCredentials (Optional) - True if not including xhrFields with withCredentials
     * @param iTimeout (Optional) - The  request timeout
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     * @since  8 August 2014
     *
     * @return The jquery ajax object
     */
    makeJsonAjaxCall: function(
        objContext,
        objUserSessionService,
        objNavigationService,
        sRequestType,
        sUrl,
        funcSuccess,
        objFailure,
        objRequestData,
        sContentType,
        bRetrieveAll,
        bNonSwaggerError,
        bXhrFieldsCredentials,
        iTimeout,
        objCustomHeaders
    ) {
        /**
         * This if is a man in the middle that will fire when the bearer function is specified. This will do
         * some logic to get the token and once the token is retrieved (either by persistance or by making a refresh
         * attempt) it will make the service call using that token.
         */
        if (
            !a24Core.isEmpty(objUserSessionService) &&
            !a24Core.isEmpty(objUserSessionService.get("objRestDetails.funcBearer")) &&
            typeof objUserSessionService.get("objRestDetails.funcBearer") === "function"
        ) {
            var objAjax = null;
            // Define a new promise
            var objDeferred = $.Deferred();

            // Set up a custom abort function
            objDeferred.abort = function() {
                objDeferred.reject();
                if (objAjax != null) {
                    // Abort the service call if it is in progress
                    objAjax.abort();
                }
            };

            var objThis = this;

            // Get the token promise using the funcBearer
            objUserSessionService.get("objRestDetails").funcBearer().then(

                // Once the token is given
                function (sAccessToken) {

                    // Set the new token on the bearer
                    objUserSessionService.set(
                        "objRestDetails.sBearer",
                        sAccessToken
                    );

                    var arrErrorCodes = null;
                    if (!a24Core.isEmpty(objFailure) && !a24Core.isEmpty(objFailure.arrErrorCodes)) {
                        arrErrorCodes = objFailure.arrErrorCodes;
                    }
                    var funcOriginalFail = null;
                    if (!a24Core.isEmpty(objFailure) && !a24Core.isEmpty(objFailure.funcCallback)) {
                        funcOriginalFail = objFailure.funcCallback;
                    }

                    // Make the new service call
                    objAjax = objThis.makeJsonAjaxCallActual(
                        objContext,
                        objUserSessionService,
                        objNavigationService,
                        sRequestType,
                        sUrl,
                        function(objData, sStatus, jqXHR) {
                            /**
                             * Custom success callback
                             */

                            // We do not want to deal with an abort service call
                            if (sStatus !== "abort" && !a24Core.isEmpty(objContext)) {
                                // Call the success function
                                funcSuccess(objData, sStatus, jqXHR);
                                // Resolve the promise
                                objDeferred.resolve(
                                    objData, sStatus, jqXHR
                                );
                            }
                        },
                        a24RestCallHelper.createJsonAjaxFailureObject(
                            arrErrorCodes,
                            function (objErrorData, sStatus, objErrorThrown, objError) {
                                /**
                                 * Custom failure callback
                                 */
                                if (sStatus !== "abort" && !a24Core.isEmpty(objContext)) {
                                    // Call the failure function
                                    funcOriginalFail(objErrorData, sStatus, objErrorThrown, objError);
                                     // Reject the promise
                                    objDeferred.reject(objErrorData, sStatus, objErrorThrown, objError);
                                }
                            }
                        ),
                        objRequestData,
                        sContentType,
                        bRetrieveAll,
                        bNonSwaggerError,
                        bXhrFieldsCredentials,
                        iTimeout,
                        objCustomHeaders
                    );
                }
            );

            return objDeferred;
        }

        return this.makeJsonAjaxCallActual(
            objContext,
            objUserSessionService,
            objNavigationService,
            sRequestType,
            sUrl,
            funcSuccess,
            objFailure,
            objRequestData,
            sContentType,
            bRetrieveAll,
            bNonSwaggerError,
            bXhrFieldsCredentials,
            iTimeout,
            objCustomHeaders
        );
    },
    /**
     * This function is used to make an ajax call with the required headers
     * This is a generic helper used to reduce code duplication
     * This function can perform both GET and POST requests
     *
     * Note: This function only supports Json encoding.
     *
     * @param objUserSessionService - An instance of the user session service
     * @param objNavigationService - An instance of the navigation service
     * @param sRequestType - Whether it service call is a GET or a POST
     * @param sUrl - The url of the service call that should be made
     * @param funcSuccess - The function that will be called on a successful service call
     * @param objFailure (Optional) - The object that will deal with failed service calls
     * @param objRequestData (Optional) - The data that will be passed along if the sRequestType is 'POST'
     * @param sContentType (Optional) - The content type i.e. application/json
     * @param bRetrieveAll (Optional) - This option makes the rel="next" link recursively fire until all results
     *     are retrieved.
     * @param bNonSwaggerError (Optional) - True if the error method does not follow the swagger pattern
     * @param bXhrFieldsCredentials (Optional) - True if xhrFields with withCredentials
     * @param iTimeout (Optional) - The  request timeout
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     * @since  8 August 2014
     *
     * @return The jquery ajax object
     */
    makeJsonAjaxCallActual: function(
        objContext,
        objUserSessionService,
        objNavigationService,
        sRequestType,
        sUrl,
        funcSuccess,
        objFailure,
        objRequestData,
        sContentType,
        bRetrieveAll,
        bNonSwaggerError,
        bXhrFieldsCredentials,
        iTimeout,
        objCustomHeaders
    ) {
        sContentType = a24Core.isEmpty(sContentType) ? "application/json" : sContentType;

        var objThis = this;
        // Build up the Ajax request that should always be present
        var objAjaxRequestObject;

        objAjaxRequestObject = {
            beforeSend: function(xhr) {
                xhr.setRequestHeader("Content-Type", sContentType);
                xhr.setRequestHeader("Accept", "application/json");
                if (!a24Core.isEmpty(objUserSessionService)) {
                    if (!a24Core.isEmpty(objUserSessionService.get("objRestDetails.sContext"))) {
                        xhr.setRequestHeader("Context", objUserSessionService.get("objRestDetails.sContext"));
                    }
                    if (!a24Core.isEmpty(objUserSessionService.get("objRestDetails.sBearer"))) {
                        xhr.setRequestHeader("Authorization", objUserSessionService.get("objRestDetails.sBearer"));
                    }
                    if (!a24Core.isEmpty(objUserSessionService.get("objContextDetails.sTimezone"))) {
                        xhr.setRequestHeader("X-Accept-Timezone", objUserSessionService.get("objContextDetails.sTimezone"));
                    }
                }

                if (!a24Core.isEmpty(bNonSwaggerError) && bNonSwaggerError) {
                    xhr.setRequestHeader("X-Accept-Date-ISO", "on");
                }

                if (!a24Core.isEmpty(objCustomHeaders)) {
                    $.each(objCustomHeaders, function(sKey, sValue) {
                        xhr.setRequestHeader(sKey, sValue);
                    });
                }
            },
            url: sUrl,
            timeout: iTimeout
        };
        // Add additional data based on the type of ajax call (sRequestType)
        if (sRequestType === "POST" || sRequestType === "post" || sRequestType === "Post") {
            objAjaxRequestObject.type = "POST";
            if (sContentType === "application/json") {
                objRequestData = JSON.stringify(objRequestData);
            }
            objAjaxRequestObject.data = objRequestData;
        } else if (sRequestType === "PUT" || sRequestType === "put" || sRequestType === "Put") {
            objAjaxRequestObject.type = "PUT";
            if (sContentType === "application/json") {
                objRequestData = JSON.stringify(objRequestData);
            }
            objAjaxRequestObject.data = objRequestData;
        } else if (sRequestType === "DELETE" || sRequestType === "delete" || sRequestType === "Delete") {
            objAjaxRequestObject.type = "DELETE";
        } else {
            objAjaxRequestObject.type = "GET";
        }

        // add xhrFields with withCredentials for sub domain support
        if (bXhrFieldsCredentials) {
            objAjaxRequestObject.xhrFields = {
                withCredentials: true
            };
        }

        // Construct the function that will handle all failed responses from the service calls
        var funcFailure = function(objErrorData, sStatus, objErrorThrown) {
            //@see http://www.w3schools.com/ajax/ajax_xmlhttprequest_onreadystatechange.asp
            // this appears to happen when the iframe is destroyed just after a service call takes place.
            // we have no info to handle this, so do not continue with normal error handling
            if (
                a24Core.isEmpty(objErrorData) ||
                objErrorData.readyState === 0 && objErrorData.status === 0 && a24Core.isEmpty(objErrorData.responseText)
            ) {
                //The request was not initialized, we can not continue with normal error handling
                if (
                    !a24Core.isEmpty(objFailure) &&
                    !a24Core.isEmpty(objFailure.arrErrorCodes) &&
                    objFailure.arrErrorCodes.indexOf("*MALFORMED") !== -1 &&
                    !a24Core.isEmpty(objContext)
                ) {
                    return objFailure.funcCallback(
                        "*MALFORMED",
                        sStatus,
                        objErrorThrown
                    );
                }
                return;
            }

            // If the service call was aborted, we do the callback with status being = to abort
            if (sStatus === "abort") {
                //The service call was aborted
                if (!a24Core.isEmpty(objFailure) && !a24Core.isEmpty(objContext)) {
                    //Error handler exists, we will still call it for the abort status
                    objFailure.funcCallback(null, sStatus, objErrorThrown);
                }
                return; // We do not wish to continue with normal error handling
            }

            var objError = {
                sField: null,
                iIndex: null,
                sCode: null
            };

            // If the response is not empty
            if (!a24Core.isEmpty(objErrorData.responseText)) {
                // Try parsing the response as json
                try {
                    // Retrieve the response body from the error
                    var objErrorBody = JSON.parse(objErrorData.responseText);
                    // Parse body for error code and location
                    objError = $.extend(true, {}, a24RestResponseHandler).getErrorCodeFromResponse(objErrorBody, bNonSwaggerError);
                } catch(objError) {
                    // Ignore any failures
                }
            }

            // Make sure that some failure handling has been put in place
            if (!a24Core.isEmpty(objFailure)) {
                var sErrorCode = objError.sCode;

                /**
                 * If the first error code was found on the body and is allowed by the array,
                 * the custom failure callback will be executed.
                 *
                 * Note that if the array of allowed error codes contain the '*' as an item
                 * the function will execute the callback on any non-empty code.
                 */
                if (
                    !a24Core.isEmpty(sErrorCode) &&
                    !a24Core.isEmpty(objFailure.arrErrorCodes) &&
                    (
                        objFailure.arrErrorCodes.indexOf(sErrorCode) !== -1 ||
                        objFailure.arrErrorCodes.indexOf("*") !== -1
                    )
                ) {
                    if (
                        sErrorCode === "IP_ADDRESS_NOT_WHITELISTED" &&
                        objFailure.arrErrorCodes.indexOf(sErrorCode) === -1
                    ) {
                        a24RestCallHelper._funcGoToDefaultErrorPage(objNavigationService, objError, objErrorData, bNonSwaggerError);
                    } else {
                        // Call specified callback function with the error code
                        a24RestCallHelper._funcErrorCallbackDetails(
                            objFailure, sErrorCode, sStatus, objErrorThrown, objError, bNonSwaggerError
                        );
                    }
                } else {
                    if (sErrorCode === "IP_ADDRESS_NOT_WHITELISTED") {
                        a24RestCallHelper._funcGoToDefaultErrorPage(objNavigationService, objError, objErrorData, bNonSwaggerError);
                    } else {
                        //no error code found so we will look at the status code.
                        sErrorCode = objErrorData.status.toString();
                        if (
                            objFailure.arrErrorCodes.indexOf(sErrorCode) !== -1 ||
                            objFailure.arrErrorCodes.indexOf("*CODE") !== -1
                        ) {
                            a24RestCallHelper._funcErrorCallbackDetails(
                                objFailure, sErrorCode, sStatus, objErrorThrown, objError, bNonSwaggerError
                            );
                        } else {
                            // Direct to the general error as no error was found in the body, or
                            // an error was found, but not expected on the route

                            a24RestCallHelper._funcGoToDefaultErrorPage(objNavigationService, objError, objErrorData, bNonSwaggerError);
                        }
                    }
                }
            } else {
                if (sStatus === "error" && a24Core.isEmpty(objErrorData.responseText)) {
                    return;
                }

                // Direct to General error as no error handling was specified
                a24RestCallHelper._funcGoToDefaultErrorPage(objNavigationService, objError, objErrorData, bNonSwaggerError);
            }
        };

        // If the service call should be recursive
        if (bRetrieveAll) {
            /**
             * NOTE: This section of the code does not deal with concurrency. If data changes
             * while the service calls are being made, it might cause duplicate entries or completely
             * empty responses.
             */

            // Stores the last service call made in the sequence
            var objAjax = null;

            // Create a new deferred object
            var objDeferred = $.Deferred();
            // Create abort function on the deferred
            objDeferred.abort = function() {
                objDeferred.reject();
                if (objAjax != null) {
                    objAjax.abort();
                }
            };

            // Store the service call result as an array
            var objReturn = [];
            /**
             * This function is defined to make it possible to recursively call a url
             *
             * @author Michael Barnard <michael.barnard@a24group.com>
             * @since  6 January 2016
             *
             * @param objAjaxRequestObject - The request object for the service call
             */
            var funcDoRecursiveServiceCall = function(objAjaxRequestObject) {

                // Wrapper function for the success call
                var funcWrapSuccess = function(objData, sStatus, jqXHR) {

                    // Append data to the return object
                    if (objData == null) {
                        // If the data is null the return is null
                        /**
                         * NOTE: This section of the code does not deal with concurrency. If for i.e. page 2 retrieves
                         * data and the header indicates a page 3 has more records and someone deletes this entries,
                         * then when page 3 is seen as 204(No Data) then it will see the entire response as empty.
                         */
                        objReturn = null;
                    } else {
                        // Append data array to the return array
                        objReturn = objReturn.concat(objData);
                    }

                    // Get the url from the response headers
                    var sLinkUrl = a24RestCallHelper.getNextUrlFromResponseHeaders(jqXHR);

                    // If the next link url is set
                    if (sLinkUrl != null && !a24Core.isEmpty(objContext)) {
                        // Set new url on request object
                        objAjaxRequestObject.url = sLinkUrl;
                        // Make next service call
                        funcDoRecursiveServiceCall(objAjaxRequestObject);
                    } else   {
                        // We do not want to deal with an abort service call
                        if (sStatus !== "abort" && !a24Core.isEmpty(objContext)) {
                            // Call the success function
                            funcSuccess(objReturn, sStatus, jqXHR);
                            // Resolve the promise
                            objDeferred.resolve(
                                objReturn, sStatus, jqXHR
                            );
                        }
                    }
                };

                // Wrapper function for the failure call
                var funcWrapFailure = function(objErrorData, sStatus, objErrorThrown) {
                    // We do not want to deal with an abort service call
                    if (sStatus !== "abort" && !a24Core.isEmpty(objContext)) {
                        // Call the failure function
                        funcFailure(objErrorData, sStatus, objErrorThrown);
                        // Reject the promise
                        objDeferred.reject(
                            objErrorData, sStatus, objErrorThrown
                        );
                    }
                };

                // Make ajax call
                objAjax = objThis._funcAjaxCall(objAjaxRequestObject, funcWrapSuccess, funcWrapFailure);
            };

            // Initial call on recursive function
            funcDoRecursiveServiceCall(objAjaxRequestObject);

            // Return the deffered objects promise
            return objDeferred;
        } else {
            // Wrapper function for the success call
            var funcWrapSuccess = function(objData, sStatus, jqXHR) {
                // We do not want to deal with an abort service call
                if (sStatus !== "abort" && !a24Core.isEmpty(objContext)) {
                    return funcSuccess(objData, sStatus, jqXHR);
                }
            };

            // Wrapper function for the failure call
            var funcWrapFailure = function(objErrorData, sStatus, objErrorThrown) {
                // We do not want to deal with an abort service call
                if (sStatus !== "abort" && !a24Core.isEmpty(objContext)) {
                    //return the response of the failure function, status not abort
                    return funcFailure(objErrorData, sStatus, objErrorThrown);
                }
            };

            // Make ajax call
            return objThis._funcAjaxCall(objAjaxRequestObject, funcWrapSuccess, funcWrapFailure);
        }
    },
    _funcAjaxCall: function(objAjaxRequestObject, funcSuccess, funcFailure) {
        return $.ajax(objAjaxRequestObject).done(funcSuccess).fail(funcFailure);
    },
    _funcErrorCallbackDetails: function(objFailure, sErrorCode, sStatus, objErrorThrown, objError) {
        objFailure.funcCallback(sErrorCode, sStatus, objErrorThrown, objError);
    },
    _funcGoToDefaultErrorPage: function(objNavigationService, objError/*, objErrorData, bNonSwaggerError*/) {
        if (objError.sCode === "IP_ADDRESS_NOT_WHITELISTED") {
            objNavigationService.navigateOutsideContext("access-denied");
        } else {
            objNavigationService.navigateOutsideContext("error");
        }
    },
    /**
     * This function is used as a quick way to construct the failure object used
     * by the makeJsonAjaxCall
     *
     * @param arrExpectedErrorCodes - An array of expected failure keyword strings, can also have status codes
     * @param funcErrorCodeHandlerFunction - The callback function that will be called if the code is found
     *     function(sCode, sStatus, objErrorThrown) {}
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     * @since  13 August 2014
     *
     * @returns Object constructed as {{arrErrorCodes: *, funcCallback: *}}
     */
    createJsonAjaxFailureObject: function(arrExpectedErrorCodes, funcErrorCodeHandlerFunction) {
        return {
            arrErrorCodes: arrExpectedErrorCodes,
            funcCallback: funcErrorCodeHandlerFunction
        };
    },

    /**
     * The method will download a PDF for a GET route
     *
     * @param sUrl - The URL of the pdf
     * @param sFileName - The name you wish to give the PDF getting downloaded
     * @param funcSuccess - The function you want to call once the pdf has successfully downloaded
     * @param funcFailure - The function you want to call once the pdf has failed to download
     * @param objHeaders - The object containing the xhr headers for the request - Defaults to empty object
     *
     * @author Michael Banrard <michael.barnard@a24group.com>
     * @since  22 June 2017
     *
     * @return Ajax Promise object
     */
    downloadPdfByGET: function(
        sUrl,
        sFileName,
        funcSuccess,
        funcFailure,
        objHeaders
    ) {
        objHeaders = objHeaders || {};

        return this._downloadPdf(
            sUrl,
            "GET",
            null,
            null,
            objHeaders,
            sFileName,
            funcSuccess,
            funcFailure
        );
    },
    /**
     * The method will download a PDF for a POST route
     *
     * @param sUrl - The URL of the pdf
     * @param objBody - The body you wish to post to the route
     * @param sFileName - The name you wish to give the PDF getting downloaded
     * @param funcSuccess - The function you want to call once the pdf has successfully downloaded
     * @param funcFailure - The function you want to call once the pdf has failed to download
     * @param objHeaders - The object containing the xhr headers for the request - Defaults to empty object
     * @param sContentType - The content type of the post body - Defaults to application/json and if application/json will auto JSON.stringify content
     *
     * @author Michael Banrard <michael.barnard@a24group.com>
     * @since  22 June 2017
     *
     * @return Ajax Promise object
     */
    downloadPdfByPOST: function(
        sUrl,
        objBody,
        sFileName,
        funcSuccess,
        funcFailure,
        objHeaders,
        sContentType
    ) {
        objHeaders = objHeaders || {};
        sContentType = sContentType || "application/json";

        return this._downloadPdf(
            sUrl,
            "POST",
            objBody,
            sContentType,
            objHeaders,
            sFileName,
            funcSuccess,
            funcFailure
        );
    },
    /**
     * Private function used to download a pdf
     *
     * @param sUrl - The URL of the pdf
     * @param sMethod - The request verb i.e. GET or POST
     * @param objBody - The body you wish to post to the route
     * @param sContentType - The content type of the post body - If application/json will auto JSON.stringify content
     * @param objHeaders - The object containing the xhr headers for the request
     * @param sFileName - The name you wish to give the PDF getting downloaded
     * @param funcSuccess - The function you want to call once the pdf has successfully downloaded
     * @param funcFailure - The function you want to call once the pdf has failed to download
     *
     * @author Michael Banrard <michael.barnard@a24group.com>
     * @since  22 June 2017
     *
     * @return Ajax Promise object
     */
    _downloadPdf: function(sUrl, sMethod, objBody, sContentType, objHeaders, sFileName, funcSuccess, funcFailure) {

        var objAjaxConfig = {
            cache: false, // Force a no-cache since this is a document
            url: sUrl, // Url for the pdf
            type: sMethod, // The request verb i.e. GET or POST,
            processData: false, // Do not auto process data
            xhrFields: {
                responseType: "blob"
            }
        };

        if (sMethod === "POST") {
            if (sContentType === "application/json") {
                // JSON encode if the content type is application/json
                objBody = JSON.stringify(objBody);
            }
            objAjaxConfig.data = objBody; // Append the data if it is a post type
            objAjaxConfig.contentType = sContentType; // Add content type for the post data
        }

        if (a24Core.isEmpty(objHeaders)) {
            objHeaders = {};
        }
        objAjaxConfig.beforeSend = function(xhr) {
            $.each(objHeaders, function(sKey, sValue) {
                xhr.setRequestHeader(sKey, sValue);
            });
            xhr.setRequestHeader("Accept", "application/pdf");
        };
        return this._funcAjaxCall(
            objAjaxConfig,
            function (objResponse, sStatus, objXhr) {
                // Wraped in a try-catch incase the document can not be saved
                try {
                    // Create a blob from the response
                    var objBlob = new Blob(
                        [
                            objResponse
                        ],
                        {
                            type: 'application/octet-stream'
                        }
                    );

                    // Call FileSaver Lib
                    saveAs(objBlob, sFileName);

                    if (!a24Core.isEmpty(funcSuccess)) {
                        funcSuccess();
                    }

                } catch (eException) {
                    if (!a24Core.isEmpty(funcFailure)) {
                        funcFailure(eException);
                    }
                }
            },
            function(objErrorData, sStatus, objErrorThrown) {
                if (!a24Core.isEmpty(funcFailure)) {
                    funcFailure(objErrorData, sStatus, objErrorThrown);
                }
            }
        );
    }
};

var a24RestResponseHandlerExtend = {
    /**
     * These params will be used by the below functions to store values for the initial call
     * to use when it returns the code.
     */
    sCode: null,
    /**
     * These params will be used by the below functions to store values for the initial call
     * to use when it returns the path.
     */
    arrPath: null,
    /**
     * This is a wrapper for a recursive calling method to allow single param entry
     *
     * This function is used to parse through the body of a failure response and
     * retrieve the first Error Constant based on a generic structures for the
     * response.
     *
     * @param objError - The json body of the error response
     * @param bNonSwaggerError (Optional) - True if the error method does not follow the swagger pattern
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     * @since  09 December 2015
     *
     * @returns An object containing the Error Code, The array index and the Field for the error
     */
    getErrorCodeFromResponse: function(objError, bNonSwaggerError) {
        return this.getFullErrorLookup(objError, true, bNonSwaggerError);
    },
    /**
     * This function is used to parse through the body of a failure response and
     * retrieve the first Error Constant based on a generic structures for the
     * response.
     *
     * @param objError - The json body of the error response
     * @param bReturn - Whether this itteration of the service call should return the object
     * @param bNonSwaggerError (Optional) - True if the error method does not follow the swagger pattern
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     * @since  03 October 2016
     *
     * @returns An object containing the Error Code
     *     {
     *         sCode: "STATUS_CODE"
     *     }
     */
    getFullErrorLookup: function(objError, bReturn, bNonSwaggerError) {
        return this.getSwaggerError(objError, bReturn, bNonSwaggerError);
    },
    /**
     * This function is used to parse through the body of a failure response and
     * retrieve the first Error Constant based on a swagger structures for the
     * response.
     *
     * @param objError - The json body of the error response
     * @param bReturn - Whether this itteration of the service call should return the object
     * @param bNonSwaggerError (Optional) - True if the error method does not follow the swagger pattern
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     * @since  03 October 2016
     *
     * @returns An object containing the Error Code
     *     {
     *         sCode: "STATUS_CODE"
     *     }
     */
    getSwaggerError: function(objError, bReturn, bNonSwaggerError) {
        if (objError.errors) {
            // If the errors is present, it means that there are lower levels
            this.getFullErrorLookup(objError.errors[0], false, bNonSwaggerError);
        } else if (objError.path || objError.stack) {
            // If path or stack is present, it means that this is the lowest level
            this.sCode = objError.code;
            this.arrPath = objError.path;
            if (a24Core.isEmpty(this.arrPath)) {
                this.arrPath = null;
            }
        } else if (objError.exceptionCode) {
            this.sCode = objError.exceptionCode;
            this.arrPath = null;
        }
        // If return is specified
        if (bReturn) {
            // Return the value on a single object
            return {
                sCode: this.sCode,
                arrPath: this.arrPath
            };
        }
    }
};

if (typeof a24RestUrlConstruct === "undefined") {
    var a24RestUrlConstruct = {};
}
if (typeof a24RestCallHelper === "undefined") {
    var a24RestCallHelper = {};
}
if (typeof a24RestResponseHandler === "undefined") {
    var a24RestResponseHandler = {};
}
if (typeof a24RSVP === "undefined") {
    var a24RSVP = {};
}

//Only add the extended values to the global if they do not exist on it already
Object.keys(a24RestUrlConstructExtend).forEach(function(sKey) {
    if (!a24RestUrlConstruct.hasOwnProperty(sKey)) {
        a24RestUrlConstruct[sKey] = a24RestUrlConstructExtend[sKey];
    }
});
Object.keys(a24RestCallHelperExtend).forEach(function(sKey) {
    if (!a24RestCallHelper.hasOwnProperty(sKey)) {
        a24RestCallHelper[sKey] = a24RestCallHelperExtend[sKey];
    }
});
Object.keys(a24RestResponseHandlerExtend).forEach(function(sKey) {
    if (!a24RestResponseHandler.hasOwnProperty(sKey)) {
        a24RestResponseHandler[sKey] = a24RestResponseHandlerExtend[sKey];
    }
});
Object.keys(a24RSVPExtend).forEach(function(sKey) {
    if (!a24RSVP.hasOwnProperty(sKey)) {
        a24RSVP[sKey] = a24RSVPExtend[sKey];
    }
});
