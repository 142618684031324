define("a24-ember-candidate-profile/components/tab-agency-candidate-profile-general", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/tab-agency-candidate-profile-general"], function (exports, _didRenderChangesMixin, _tabAgencyCandidateProfileGeneral) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _tabAgencyCandidateProfileGeneral.default,
        _sCandidateId: null,
        _sCandidateName: null,
        _arrReloadActions: null,
        _bDisplayReloadCard: false,
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objLanguagesService: Ember.inject.service("rest-agency-candidate-languages"),
        _objEmailService: Ember.inject.service("rest-agency-candidate-emails"),
        _objCandidateStatusService: Ember.inject.service("rest-agency-candidate-status"),
        _objStatusService: Ember.inject.service("rest-agency-status-details"),
        _objEqualityMonitoringService: Ember.inject.service("rest-agency-candidate-equality"),
        _objBasicInfoService: Ember.inject.service("rest-agency-candidate-basic-info"),
        _objAdditionalNoteService: Ember.inject.service("rest-agency-candidate-additional-note"),
        _objStringsCandidateProfile: Ember.inject.service("a24-strings-candidate-profile"),
        _objContactNumbersService: Ember.inject.service("rest-agency-candidate-contact-numbers"),
        _objAddressDetailsService: Ember.inject.service("rest-agency-candidate-address-details"),
        _objCandidateTypeRestService: Ember.inject.service("rest-agency-candidate-type"),
        _objSettingsRestService: Ember.inject.service("rest-agency-candidate-settings"),
        _objStatusErrorPageService: Ember.inject.service("status-error-page"),
        _objUserAvatarService: Ember.inject.service("rest-agency-user-avatar"),
        _objMetaRestService: Ember.inject.service("rest-agency-candidate-meta"),
        _bDisplayPermissionError: false,
        _objActivityRestService: Ember.inject.service("rest-agency-candidate-activity"),
        _objRestAgencyCandidateActivityEmailComm: Ember.inject.service("rest-agency-activity-email-communication-message"),
        _objRestAgencyCandidateActivityConversation: Ember.inject.service("rest-agency-activity-conversation"),
        _objRestAgencyCandidateActivityNotification: Ember.inject.service("rest-agency-activity-notification"),

        _objActivityTypeRestService: Ember.inject.service("rest-advanced-search-activity-type"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objConsultantService: Ember.inject.service("rest-agency-candidate-consultant"),
        _objAgencyAgencyGroupService: Ember.inject.service("rest-agency-agency-group"),
        _sActivityContext: null,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            var objCandidateViewParams = this.get("_objNavigation.objRouteParams")["index.agency-candidate-profile"];

            var objCandidateSearchViewParams = this.get("_objNavigation.objRouteParams")["index.agency-candidate-search-view"];

            if (!a24Core.isEmpty(objCandidateViewParams)) {
                this.set("_sCandidateId", objCandidateViewParams.candidate_id);
            }
            if (!a24Core.isEmpty(objCandidateSearchViewParams)) {
                this.set("_sCandidateId", objCandidateSearchViewParams.candidate_id);
            }

            this.set("_sActivityContext", this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY").toLowerCase());

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objStringsCandidateProfile").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.set("_bDisplayReloadCard", false);
                }
            }]);
        },
        actions: {
            onGetBasicInfoFailure: function onGetBasicInfoFailure(sCode) {
                if (sCode === "403") {
                    this.set("_objStatusErrorPageService.sIcon", "warning");
                    this.set("_objStatusErrorPageService.sMessage", this.get("_objStringsCandidateProfile").getString("permissionsError"));
                    this.set("_bDisplayPermissionError", true);
                } else {
                    this.set("_bDisplayReloadCard", true);
                }
            },
            onGetBasicInfoSuccess: function onGetBasicInfoSuccess(objData) {
                var sCandidateProfile = this.get("_objStringsCandidateProfile").getString("candidateProfile");
                var sCandidateName = objData.first_name + " " + objData.last_name;

                this.set("_sCandidateName", sCandidateName);

                this.get("_objTitleBarService").setTitleBarPartially(sCandidateProfile + " - " + sCandidateName);
            }
        }
    });
});