define("a24-ember-lib/components/input-form-header", ["exports", "a24-ember-lib/templates/components/input-form-header", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _inputFormHeader, _inputFormElement, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _inputFormHeader.default,
        classNames: ["a24ErrorInputParent"],
        sHeaderClass: ""
        // Currently error message set on header does not work as this will go apply to the child that is in the yield
        // of this component
    });
});