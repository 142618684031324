define("a24-ember-staffshift-core/services/triage-menu", ["exports", "a24-ember-menu/services/left-menu-service"], function (exports, _leftMenuService) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _leftMenuService.default.extend({
        _objBreadCrumbService: Ember.inject.service("bread-crumb"),
        _objNavigation: Ember.inject.service("navigation"),
        _objRestMenu: Ember.inject.service("rest-menu"),
        //_objIntercom: inject("intercom"), // commenting this out in case it needs to be added back
        _objStaffshiftCoreStringsService: Ember.inject.service("a24-strings-staffshift-core"),
        _objDidRenderService: Ember.inject.service("component-did-render"),
        _objChatterBoxService: Ember.inject.service("chatter-box-service"),
        _objMenuProperty: null,

        afterMenuAnimation: null,
        objLeftMenuUIComponent: null,

        // Store the original response for the menu service call
        arrMenuResponse: null,
        // Store whether this is a fresh reload of the menu or just a redraw
        bFreshLoad: false,
        bAddSupportChat: true,
        // Init function
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            // Instantiate the navigation service so that we can observe properties on it
            this.get("_objNavigation");
            this.set("afterMenuAnimation", function () {
                // This is a temporary fix for some outdated components that still
                // listen to the window resize to control their renders
                Ember.run(function () {
                    Ember.get(_this, "_objDidRenderService").countRendered();
                });
                $(window).trigger("resize");
            });
        },

        _sortMenuItems: function _sortMenuItems(arrMenuItems) {
            if (a24Core.isEmpty(arrMenuItems)) {
                return [];
            }
            var funcSorter = function funcSorter(objMenuItem1, objMenuItem2) {
                return objMenuItem1.iOrder - objMenuItem2.iOrder;
            };

            arrMenuItems.sort(funcSorter);

            arrMenuItems.forEach(function (objMenuItem) {
                var arrSubMenuItems = objMenuItem.arrSubmenus;
                if (!a24Core.isEmpty(arrSubMenuItems)) {
                    arrSubMenuItems.sort(funcSorter);
                }
            });

            return arrMenuItems;
        },


        /**
         * This function is used to get the actual left menu items. This will do a service call and store
         * the response for later use. This will also mark that the left menu data as fresh telling the component to
         * reload.
         */
        getMenuItems: function getMenuItems() {
            var _this2 = this;

            this.set("bLoading", true);

            return this.get("_objRestMenu").getMenuItems(this, "_objMenuProperty", function (arrMenuItems) {
                //Set the menu items and let RSVP handle rest
                _this2.set("arrMenuResponse", _this2._sortMenuItems(arrMenuItems));
                _this2.set("bFreshLoad", true);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE"], function () /*sCode, sStatus*/{
                //RSVP will handle any failures
                _this2.set("bLoading", false);
            }));
        },
        getCurrentRoute: function getCurrentRoute() {
            var sCurrentRoute = this.get("_objNavigation.sCurrentRouteName");
            var arrRouteParts = sCurrentRoute.split(".");

            if (arrRouteParts[arrRouteParts.length - 1] === "plugin") {
                sCurrentRoute = sCurrentRoute + "." + this.get("_objNavigation.objRouteParams")[sCurrentRoute].plugin_id;
            }
            if (arrRouteParts[arrRouteParts.length - 1] === "policy-group-list") {
                sCurrentRoute = sCurrentRoute + "." + this.get("_objNavigation.objRouteParams")[sCurrentRoute].policy_group_type + "." + this.get("_objNavigation.objRouteParams")[sCurrentRoute].policy_group_id;
            }
            if (arrRouteParts.length >= 3) {
                // Treat these as tabs
                sCurrentRoute = arrRouteParts.slice(0, 2).join(".");
            }

            return sCurrentRoute;
        },
        parseMenuItems: function parseMenuItems(arrMenuItems) {
            var _this3 = this;

            // Store the context
            var sContext = this.get("_objNavigation").get("sContext");

            // Store the current route
            var sCurrentRoute = this.getCurrentRoute();

            // Store the display name for the breadcrumb
            var sRouteDisplayName = null;

            // Store an array of parsed menu items
            var arrParsedMenuItems = [];
            // Store a boolean of whether any of the menu items match the current route
            var bMenuItemSelected = false;

            // Define the empty breadcrumb bar items
            var arrBreadCrumbItems;

            // Itterate over the menu items
            arrMenuItems.forEach(function (objTopMenuItem) {

                // Define the main final menu object
                var objParsedMenuItem = {
                    sTitle: objTopMenuItem.sLabel,
                    iOrder: objTopMenuItem.iOrder,
                    sIcon: _this3._getMenuSectionMaterialIcon(objTopMenuItem.sIcon),
                    arrSubMenuItems: null
                };

                // Loop over the sub menu items
                for (var i = 0; i < objTopMenuItem.arrSubmenus.length; i++) {
                    var bSubItemMatch = false;

                    // Get the place name of the sub menu
                    var sPlaceName = Ember.get(objTopMenuItem.arrSubmenus[i], "sPlaceName");
                    // Get the route name of the sub menu
                    var sRoutePlaceName = "";
                    if (sContext == null) {
                        sRoutePlaceName = "index." + sPlaceName;
                    } else {
                        sRoutePlaceName = sContext + "." + sPlaceName;
                    }

                    // Add custom behaviour for the plugin
                    if (sPlaceName === "plugin") {
                        sRoutePlaceName = sRoutePlaceName + "." + objTopMenuItem.sPluginId;
                    }

                    // Add custom behaviour for the policy group list
                    if (sPlaceName === "policy-group-list") {
                        sRoutePlaceName = sRoutePlaceName + "." + objTopMenuItem.arrSubmenus[i].objParams.sPolicyGroupType + "." + objTopMenuItem.arrSubmenus[i].objParams.sPolicyGroupId;
                    }

                    /**
                     * If the current item place name matches the current route name then we set it selected, otherwise
                     * we check if it matches any of the sub sub menu items in the sReferencedNamePlaces
                     */
                    if (sRoutePlaceName === sCurrentRoute) {
                        bMenuItemSelected = true;
                        bSubItemMatch = true;
                        sRouteDisplayName = Ember.get(objTopMenuItem.arrSubmenus[i], "sLabel");
                    } else {
                        // Check the sReferencedNamePlaces (it's an array) for any matches
                        for (var j = 0; j < objTopMenuItem.arrSubmenus[i].sReferencedNamePlaces.length; j++) {

                            var sReferencedNamePlace = "";
                            // Get the sReferenced name place
                            if (sContext == null) {
                                sReferencedNamePlace = "index." + objTopMenuItem.arrSubmenus[i].sReferencedNamePlaces[j];
                            } else {
                                sReferencedNamePlace = sContext + "." + objTopMenuItem.arrSubmenus[i].sReferencedNamePlaces[j];
                            }

                            // Check if it matches the current route
                            if (sReferencedNamePlace === sCurrentRoute) {

                                // This means that the base refernce name matches

                                // Add an additional check for the policy group list
                                if (sPlaceName === "policy-group-list") {
                                    // Check that the policy group type and policy group id also match
                                    if (objTopMenuItem.arrSubmenus[i].objParams.sPolicyGroupType === _this3.get("_objNavigation.objRouteParams")[sCurrentRoute].policy_group_type && objTopMenuItem.arrSubmenus[i].objParams.sPolicyGroupId === _this3.get("_objNavigation.objRouteParams")[sCurrentRoute].policy_group_id) {
                                        // All checks match
                                        bMenuItemSelected = true;
                                        bSubItemMatch = true;
                                        sRouteDisplayName = Ember.get(objTopMenuItem.arrSubmenus[i], "sLabel");
                                        break;
                                    }
                                } else {
                                    // Mark the menu item as having a match
                                    bMenuItemSelected = true;
                                    bSubItemMatch = true;
                                    sRouteDisplayName = Ember.get(objTopMenuItem.arrSubmenus[i], "sLabel");
                                    break;
                                }
                            }
                        }
                    }

                    // Create a complete url
                    var sCompleteUrl = "";
                    // Define an object for the route params
                    var objRouteParams = null;
                    // Set the route params if it is a plugin
                    if (sPlaceName === "plugin") {
                        objRouteParams = {
                            plugin: [objTopMenuItem.sPluginId]
                        };
                    }
                    // Set the route params if it is a policy group list
                    if (sPlaceName === "policy-group-list") {
                        objRouteParams = {
                            "policy-group-list": [objTopMenuItem.arrSubmenus[i].objParams.sPolicyGroupType, objTopMenuItem.arrSubmenus[i].objParams.sPolicyGroupId]
                        };
                    }

                    // Create the final completed url from the current item. This will use the placename
                    // and the route params to create a final url
                    sCompleteUrl = _this3.get("_objNavigation").navigateWithinContext(sPlaceName, objRouteParams, null, true);

                    // Create a sub menu item
                    var objSubMenuItem = {
                        sTitle: objTopMenuItem.arrSubmenus[i].sLabel,
                        iOrder: objTopMenuItem.arrSubmenus[i].iOrder,
                        sIcon: null,
                        sRouteName: sCompleteUrl,
                        sPlaceName: sRoutePlaceName,
                        objRouteParams: objRouteParams,
                        arrReferencedNamePlaces: objTopMenuItem.arrSubmenus[i].sReferencedNamePlaces,
                        bIsSelected: bSubItemMatch
                    };

                    // Create a list of sub menu items if one does not yet exist
                    if (a24Core.isEmpty(objParsedMenuItem.arrSubMenuItems)) {
                        objParsedMenuItem.arrSubMenuItems = [];
                    }

                    // Push items to main menu item
                    objParsedMenuItem.arrSubMenuItems.push(objSubMenuItem);

                    // If this is the selected item, set the appropriate bread crumb items
                    if (bSubItemMatch) {
                        arrBreadCrumbItems = [];

                        //If we have more than one sub menu item we add the top menu item to the breadcrumb bar
                        if (objTopMenuItem.arrSubmenus.length !== 1 && sPlaceName !== "policy-group-list") {
                            arrBreadCrumbItems.push({
                                sTitle: Ember.get(objTopMenuItem, "sLabel"),
                                sLink: objTopMenuItem.arrSubmenus[i].sRouteName
                            });
                        }

                        // Create the bread crumb bar item based on the sub menu display name
                        arrBreadCrumbItems.push({
                            sTitle: sRouteDisplayName,
                            sLink: sCompleteUrl
                        });
                    }
                }

                // If only ONE item is present in the sub menus, we collapse it to be part of the main menu
                if (objParsedMenuItem.arrSubMenuItems.length === 1) {

                    // Set the single item route name to the top menu item
                    objParsedMenuItem.sRouteName = objParsedMenuItem.arrSubMenuItems[0].sRouteName;
                    objParsedMenuItem.sPlaceName = objParsedMenuItem.arrSubMenuItems[0].sPlaceName;
                    objParsedMenuItem.objRouteParams = objParsedMenuItem.arrSubMenuItems[0].objRouteParams;
                    objParsedMenuItem.arrReferencedNamePlaces = objParsedMenuItem.arrSubMenuItems[0].arrReferencedNamePlaces;
                    objParsedMenuItem.bIsSelected = objParsedMenuItem.arrSubMenuItems[0].bIsSelected;

                    // Clear the sub menu items
                    objParsedMenuItem.arrSubMenuItems = null;
                }

                arrParsedMenuItems.push(objParsedMenuItem);
            });

            if (!bMenuItemSelected && sCurrentRoute !== "candidate.chat" && sCurrentRoute.substring(sCurrentRoute.length - ".react-easter-time".length, sCurrentRoute.length) !== ".react-easter-time") {
                // No matching items found, we need to go to the error page
                this.get("_objNavigation").navigateOutsideContext("404");
            }

            // Set the breadcrumb bar item if the route was successfully navigated to
            this.get("_objBreadCrumbService").setBreadCrumbItems(arrBreadCrumbItems);

            if (sContext === "candidate" && this.get("bAddSupportChat")) {
                var objSubMenuChat = {
                    sTitle: this.get("_objStaffshiftCoreStringsService").getString("supportChat"),
                    iOrder: 9999,
                    sIcon: "question_answer",
                    arrSubMenuItems: null
                };

                arrParsedMenuItems.push(objSubMenuChat);
            }

            return arrParsedMenuItems;
        },
        updateSelectedMenu: function updateSelectedMenu() {

            var arrParsedMenuItems = this.parseMenuItems(this.get("arrMenuResponse"));

            for (var i = 0; i < arrParsedMenuItems.length; i++) {
                this.set("arrMenuItems." + i + ".bIsSelected", arrParsedMenuItems[i].bIsSelected);
                if (!a24Core.isEmpty(this.get("arrMenuItems." + i + ".arrSubMenuItems"))) {
                    for (var j = 0; j < arrParsedMenuItems[i].arrSubMenuItems.length; j++) {
                        this.set("arrMenuItems." + i + ".arrSubMenuItems." + j + ".bIsSelected", arrParsedMenuItems[i].arrSubMenuItems[j].bIsSelected);
                    }
                }
            }
        },
        onRouteChange: Ember.observer("_objNavigation.iRouteTransitions", function () {
            var _this4 = this;

            Ember.run.next(function () {
                if (!a24Core.isEmpty(_this4.get("objLeftMenuUIComponent"))) {
                    if (_this4.get("bFreshLoad")) {
                        _this4.set("bFreshLoad", false);
                        var arrParsedMenuItems = _this4.parseMenuItems(_this4.get("arrMenuResponse"));
                        _this4.set("arrMenuItems", arrParsedMenuItems);
                        _this4.set("bLoading", false);
                    } else {
                        if (!a24Core.isEmpty(_this4.get("objLeftMenuUIComponent"))) {
                            _this4.updateSelectedMenu();
                        }
                    }
                }
            });
        }),
        /**
         * This method will ensure that an icon is set, if not, it will provide the texture as default
         */
        _getMenuSectionMaterialIcon: function _getMenuSectionMaterialIcon(sIcon) {
            return !a24Core.isEmpty(sIcon) ? sIcon : "texture";
        },
        /**
         * Menu click handler
         *
         * @param objItem - menu item clicked
         */
        onClickMenuItem: function onClickMenuItem(objItem) {

            if (objItem.sTitle === this.get("_objStaffshiftCoreStringsService").getString("supportChat"))
                // && (!a24Core.isEmpty(this.get("_objIntercom"))) // commenting this out in case it needs to be added back
                {
                    //this.get("_objIntercom").show();
                    this.get("_objChatterBoxService").toggleChatBox();
                }
        }
    });
});