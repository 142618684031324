define("a24-ember-lib/components/fab-button", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-window/mixins/a24responsive", "a24-ember-lib/templates/components/fab-button"], function (exports, _didRenderChangesMixin, _a24responsive, _fabButton) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _a24responsive.default, {
        layout: _fabButton.default,
        classNames: ["a24-fab-button-component"],
        sLeftMenuService: "",
        _objFabCurrent: null,
        _sFabState: "hideEnd", //showStart, showEnd, hideStart, hideEnd
        _iAnimInSpeed: 250,
        _objFabService: Ember.inject.service("fab-button"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objLeftMenuService: Ember.computed("sLeftMenuService", function () {
            if (a24Core.isEmpty(this.get("sLeftMenuService"))) {
                return null;
            } else {
                return Ember.getOwner(this).lookup("service:" + this.get("sLeftMenuService"));
            }
        }),
        _objWindowConstants: Ember.inject.service("a24-constants-window"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _bIsClickable: false, //This will be false during animations
        _bClearFab: false,

        init: function init() {
            this._super.apply(this, arguments);
            this.get("_objFabService");
            this.get("_objSnackbarService");
            this.get("_objLeftMenuService");
            this.set("_objFabCurrent", {
                funcFabAction: null,
                sIconName: "add", //This needs a default otherwise the jQuery `.material-icons` select on afterRender wont work

                //NOTE: the sIconFaName and sIconSvg being passed on for now but will not work untill the fab button
                //component is updated to not use the `.material-icons` to select the icon to animate the font
                sIconFaName: null,
                sIconSvg: null,

                sTooltip: "",
                sButtonColor: "",
                sIconColor: "white-text",
                bLightWaves: true
            });
        },
        didInsertElement: function didInsertElement() {
            var _this = this;

            this._super.apply(this, arguments);

            Ember.run.scheduleOnce("afterRender", this, function () {
                //Set the initial values of the animating parts
                _this.$(".a24-fab-button-container").css({
                    marginBottom: "0px",
                    width: _this.get("_objLibConstants.ROUND_ICON_SIZE_EXTRA_LARGE_PX") + "px",
                    height: _this.get("_objLibConstants.ROUND_ICON_SIZE_EXTRA_LARGE_PX") + "px",
                    padding: _this.get("_objLibConstants.ROUND_ICON_SIZE_EXTRA_LARGE_PX") / 2 + "px"
                });

                _this.$(".a24-fab-button-main .material-icons").css({
                    fontSize: "0px",
                    lineHeight: "0px"
                });

                _this.showSnackbarMessage();
            });
        },
        showSnackbarMessage: Ember.observer("_objFabService._bHasNewAction", function () {
            if (this.get("_objFabService._bHasNewAction")) {
                this.set("_objFabService._bHasNewAction", false);

                if (this.get("_objFabService._arrFabActions").length === 0) {
                    //In this case we want to hide the FAB button

                    if (this.get("_sFabState") === "showStart") {
                        this.set("_bClearFab", true);
                    } else if (this.get("_sFabState") === "showEnd") {
                        this.hideFabButton();
                    }
                    //else if (this.get("_sFabState") === "hideStart") {
                    //    //Already busy hiding so nothing to do here
                    //} else if (this.get("_sFabState") === "hideEnd") {
                    //    //Already hidden so nothing to do here
                    //}
                } else {
                    //In this case we want to display the next queued FAB action

                    //if (this.get("_sFabState") === "showStart") {
                    //    //The showFabButtonEnd will cover this scenario
                    //} else
                    if (this.get("_sFabState") === "showEnd") {
                        this.hideFabButton();
                        //} else if (this.get("_sFabState") === "hideStart") {
                        //    //The hideFabButtonEnd will cover this scenario
                    } else if (this.get("_sFabState") === "hideEnd") {
                        this.setFabButtonDetails();
                    }
                }
            }
        }),
        observeSnackbarHeight: Ember.observer("_objSnackbarService.sSnackbarState", function () {
            var sSize = this.get("sScreenSize");
            if (sSize === this.get("_objWindowConstants.MEDIA_SIZE_TABLET") || sSize === this.get("_objWindowConstants.MEDIA_SIZE_DESKTOP") || sSize === this.get("_objWindowConstants.MEDIA_SIZE_JUMBO")) {
                this.$(".a24-fab-button-container").stop("fabSnackbarSyncAnim");
                this.$(".a24-fab-button-container").css({
                    marginBottom: "0px"
                });
            } else {
                if (this.get("_objSnackbarService.sSnackbarState") === "showStart") {
                    this.animateFabSnackbarSync(this.get("_objSnackbarService.iSnackbarHeight"));
                } else if (this.get("_objSnackbarService.sSnackbarState") === "hideStart") {
                    this.animateFabSnackbarSync(0);
                }
            }
        }),
        observeScreenSize: Ember.observer("sScreenSize", "_objSnackbarService.iSnackbarHeight", function () {
            if (a24Core.isEmpty(this.$(".a24-fab-button-container"))) {
                return;
            }

            var sSize = this.get("sScreenSize");

            if (sSize === this.get("_objWindowConstants.MEDIA_SIZE_TABLET") || sSize === this.get("_objWindowConstants.MEDIA_SIZE_DESKTOP") || sSize === this.get("_objWindowConstants.MEDIA_SIZE_JUMBO")) {
                this.$(".a24-fab-button-container").stop("fabSnackbarSyncAnim");
                this.$(".a24-fab-button-container").css({
                    marginBottom: "0px"
                });
            } else {
                if (this.get("_objSnackbarService.sSnackbarState") === "showStart") {
                    this.animateFabSnackbarSync(this.get("_objSnackbarService.iSnackbarHeight"));
                } else if (this.get("_objSnackbarService.sSnackbarState") === "showEnd") {
                    this.$(".a24-fab-button-container").css({
                        marginBottom: this.get("_objSnackbarService.iSnackbarHeight") + "px"
                    });
                }
            }
        }),
        observeRightPanel: Ember.observer("_objLeftMenuService.iRightPanelWidth", function () {
            if (a24Core.isEmpty(this.$(".a24-fab-button-container"))) {
                return;
            }
            if (this.get("_objLeftMenuService.sRightPanelState") === "showStart" || this.get("_objLeftMenuService.sRightPanelState") === "hideStart") {
                this.animateFabRightPanelSync(this.get("_objLeftMenuService.iRightPanelWidth"));
            } else {
                this.$(".a24-fab-button-container").css({ marginRight: this.get("_objLeftMenuService.iRightPanelWidth") });
            }
        }),
        animateFabSnackbarSync: function animateFabSnackbarSync(iHeight) {
            this.$(".a24-fab-button-container").stop("fabSnackbarSyncAnim");
            this.$(".a24-fab-button-container").animate({
                marginBottom: iHeight + "px"
            }, {
                duration: a24Constants.SNACKBAR_SLIDE_TIME,
                queue: "fabSnackbarSyncAnim"
            });
            this.$(".a24-fab-button-container").dequeue("fabSnackbarSyncAnim"); //Start the animation queue
        },
        animateFabRightPanelSync: function animateFabRightPanelSync(iValue) {
            this.$(".a24-fab-button-container").stop("fabRightPanelSyncAnim");
            this.$(".a24-fab-button-container").animate({
                marginRight: iValue + "px"
            }, {
                duration: "fast",
                queue: "fabRightPanelSyncAnim"
            });
            this.$(".a24-fab-button-container").dequeue("fabRightPanelSyncAnim"); //Start the animation queue
        },
        setFabButtonDetails: function setFabButtonDetails() {
            var objFabCurrent = this.get("_objFabService._arrFabActions").objectAt(0);
            this.get("_objFabService._arrFabActions").removeAt(0);
            this.set("_objFabCurrent", objFabCurrent);
            this.showFabButton();
        },
        showFabButton: function showFabButton() {
            var _this2 = this;

            this.set("_sFabState", "showStart");
            this.$(".a24-fab-button-container").removeClass("display-none");

            //Stop the current animation if there is one and create new one with the correct height
            this.$(".a24-fab-button-container").stop("fabGrowAnim");
            this.$(".a24-fab-button-container").animate({
                padding: "0px"
            }, {
                duration: this.get("_iAnimInSpeed"),
                complete: function complete() {
                    Ember.run(function () {
                        _this2.showFabButtonEnd();
                    });
                },
                queue: "fabGrowAnim"
            });
            this.$(".a24-fab-button-container").dequeue("fabGrowAnim"); //Start the animation queue
            this.$(".a24-fab-button-main .material-icons").animate({
                fontSize: this.get("_objLibConstants.FONT_SIZE_H4_PX") + "px",
                lineHeight: this.get("_objLibConstants.ROUND_ICON_SIZE_EXTRA_LARGE_PX") + "px"
            }, this.get("_iAnimInSpeed"));
        },
        showFabButtonEnd: function showFabButtonEnd() {
            this.set("_sFabState", "showEnd");

            //If there are other fabs that want to show then hide this one
            if (this.get("_bClearFab")) {
                this.set("_bClearFab", false);
                this.hideFabButton();
            } else if (this.get("_objFabService._arrFabActions").length > 0) {
                this.hideFabButton();
            }
        },
        hideFabButton: function hideFabButton() {
            var _this3 = this;

            this.set("_sFabState", "hideStart");

            //Stop the current animation if there is one and create new one with the correct height
            this.$(".a24-fab-button-container").stop("fabGrowAnim");
            this.$(".a24-fab-button-container").animate({
                padding: this.get("_objLibConstants.ROUND_ICON_SIZE_EXTRA_LARGE_PX") / 2 + "px"
            }, {
                duration: this.get("_iAnimInSpeed"),
                complete: function complete() {
                    Ember.run(function () {
                        _this3.hideFabButtonEnd();
                    });
                },
                queue: "fabGrowAnim"
            });
            this.$(".a24-fab-button-container").dequeue("fabGrowAnim"); //Start the animation queue
            this.$(".a24-fab-button-main .material-icons").animate({
                fontSize: "0px",
                lineHeight: "0px"
            }, this.get("_iAnimInSpeed"));
        },
        hideFabButtonEnd: function hideFabButtonEnd() {
            this.set("_sFabState", "hideEnd");
            this.$(".a24-fab-button-container").addClass("display-none");
            if (this.get("_objFabService._arrFabActions").length > 0) {
                this.setFabButtonDetails();
            }
        },
        actions: {
            onFabClick: function onFabClick() {
                if (this.get("_sFabState") === "showEnd") {
                    this.get("_objFabCurrent.funcFabAction")();
                }
            }
        }
    });
});