define("a24-ember-lib/components/input-form-date-picker", ["exports", "a24-ember-lib/templates/components/input-form-date-picker", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _inputFormDatePicker, _inputFormElement, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _inputFormDatePicker.default,
        classNames: ["input-form-date-picker", "md-form", "a24ErrorInputParent"],
        classNameBindings: ["bIsOutline:a24-form-outlined", "bDisabled:is-disabled"],
        bDisabled: false,
        bIsOutline: false,
        sSelectedDate: null,
        objMaxDate: null, //This should be a TimeZoneDate object, see a24DateManager.createDate(sTimeZone)
        objMinDate: null, //This should be a TimeZoneDate object, see a24DateManager.createDate(sTimeZone)
        sInputType: "date",
        _objUserSession: Ember.inject.service("user-session"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _bInternalProcess: false,
        _arrDayLetters: null,
        _arrDayLong: null,
        _arrMonthShort: null,
        _arrMonthLong: null,
        _sDefaultTestDate: "15 Jan 2015",
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_arrDayLetters", [this.get("_objStringsService").getString("daySunLetter"), this.get("_objStringsService").getString("dayMonLetter"), this.get("_objStringsService").getString("dayTueLetter"), this.get("_objStringsService").getString("dayWedLetter"), this.get("_objStringsService").getString("dayThuLetter"), this.get("_objStringsService").getString("dayFriLetter"), this.get("_objStringsService").getString("daySatLetter")]);
            this.set("_arrDayLong", [this.get("_objStringsService").getString("daySunLong"), this.get("_objStringsService").getString("dayMonLong"), this.get("_objStringsService").getString("dayTueLong"), this.get("_objStringsService").getString("dayWedLong"), this.get("_objStringsService").getString("dayThuLong"), this.get("_objStringsService").getString("dayFriLong"), this.get("_objStringsService").getString("daySatLong")]);
            this.set("_arrMonthShort", [this.get("_objStringsService").getString("monthJanShort"), this.get("_objStringsService").getString("monthFebShort"), this.get("_objStringsService").getString("monthMarShort"), this.get("_objStringsService").getString("monthAprShort"), this.get("_objStringsService").getString("monthMayShort"), this.get("_objStringsService").getString("monthJunShort"), this.get("_objStringsService").getString("monthJulShort"), this.get("_objStringsService").getString("monthAugShort"), this.get("_objStringsService").getString("monthSepShort"), this.get("_objStringsService").getString("monthOctShort"), this.get("_objStringsService").getString("monthNovShort"), this.get("_objStringsService").getString("monthDecShort")]);
            this.set("_arrMonthLong", [this.get("_objStringsService").getString("monthJanLong"), this.get("_objStringsService").getString("monthFebLong"), this.get("_objStringsService").getString("monthMarLong"), this.get("_objStringsService").getString("monthAprLong"), this.get("_objStringsService").getString("monthMayLong"), this.get("_objStringsService").getString("monthJunLong"), this.get("_objStringsService").getString("monthJulLong"), this.get("_objStringsService").getString("monthAugLong"), this.get("_objStringsService").getString("monthSepLong"), this.get("_objStringsService").getString("monthOctLong"), this.get("_objStringsService").getString("monthNovLong"), this.get("_objStringsService").getString("monthDecLong")]);

            Ember.run.scheduleOnce("afterRender", this, function () {
                var objDateInput = $("#" + _this.get("_sInputId"));
                var objMaxDate = _this.get("objMaxDate");
                var objMinDate = _this.get("objMinDate");
                if (a24Core.isEmpty(objMaxDate)) {
                    objMaxDate = a24DateManager.createDate(_this.get("_objUserSession.objContextDetails.sTimezone"));
                    objMaxDate.setDateFromBrowser("31 Dec 2040", _this.get("_objLibConstants.DATE_FORMAT"));
                }
                if (a24Core.isEmpty(objMinDate)) {
                    objMinDate = a24DateManager.createDate(_this.get("_objUserSession.objContextDetails.sTimezone"));
                    objMinDate.setDateFromBrowser("01 Jan 1930", _this.get("_objLibConstants.DATE_FORMAT"));
                }
                var arrMaxDate = [objMaxDate.getTimeOnDate(_this.get("_objLibConstants.MOMENT_TIME_YEARS")), objMaxDate.getTimeOnDate(_this.get("_objLibConstants.MOMENT_TIME_MONTHS")), objMaxDate.getTimeOnDate(_this.get("_objLibConstants.MOMENT_TIME_DATE"))];
                var arrMinDate = [objMinDate.getTimeOnDate(_this.get("_objLibConstants.MOMENT_TIME_YEARS")), objMinDate.getTimeOnDate(_this.get("_objLibConstants.MOMENT_TIME_MONTHS")), objMinDate.getTimeOnDate(_this.get("_objLibConstants.MOMENT_TIME_DATE"))];

                //set the initial value before adding the date picker to the field
                objDateInput.pickadate({
                    format: _this.get("_objLibConstants.DATE_FORMAT").toLowerCase(),
                    weekdaysShort: _this.get("_arrDayLetters"),
                    weekdaysFull: _this.get("_arrDayLong"),
                    monthsShort: _this.get("_arrMonthShort"),
                    monthsFull: _this.get("_arrMonthLong"),
                    closeOnSelect: false,
                    selectYears: 110, //This is the max amount of years that will display in the years dropdown at a time
                    today: null,
                    clear: _this.get("_objStringsService").getString("clear"),
                    close: _this.get("_objStringsService").getString("ok"),
                    labelMonthNext: _this.get("_objStringsService").getString("nextMonth"),
                    labelMonthPrev: _this.get("_objStringsService").getString("previousMonth"),
                    labelMonthSelect: _this.get("_objStringsService").getString("monthSelect"),
                    labelYearSelect: _this.get("_objStringsService").getString("yearSelect"),
                    max: arrMaxDate,
                    min: arrMinDate,
                    onSet: function onSet() {
                        //Set mValue when the value changes and also reset floater if value is empty
                        _this.setMValueWithInternalFlag(objDateInput.val(), _this.get("_bInternalProcess"));

                        _this.resetFloatingLabel(objDateInput);
                    }
                });
                var env = Ember.getOwner(_this).resolveRegistration("config:environment");
                if (env.environment === "test") {
                    objDateInput.on("onSetDateForTest", function (objEvent, sDate) {
                        _this.set("sSelectedDate", sDate);
                    });
                    if (a24Core.isEmpty(_this.get("sSelectedDate"))) {
                        _this.set("sSelectedDate", _this.get("_sDefaultTestDate"));
                    }
                }
                if (!a24Core.isEmpty(_this.get("sSelectedDate"))) {
                    //Set the init date if we have one
                    _this.set("_bInternalProcess", true);
                    objDateInput.pickadate("picker").set("select", _this.get("sSelectedDate"), {
                        format: _this.get("_objLibConstants.DATE_FORMAT").toLowerCase()
                    });
                    _this.set("_bInternalProcess", false);
                }
                objDateInput.on("blur", function () {
                    _this.resetFloatingLabel(objDateInput);
                });

                $(_this.$()).on("keyup", function (objEvent) {
                    if (objEvent.which === 27) {
                        objEvent.stopPropagation();
                    }
                });

                _this.$().on("keyup", function (objEvent) {
                    if (objEvent.which === 27) {
                        objEvent.stopPropagation();
                    }
                });

                _this.setMValueWithInternalFlag(_this.get("sSelectedDate"), true);
            });
        },
        resetFloatingLabel: function resetFloatingLabel(objDateInput) {
            //If the input field has no value then the label should NOT have the active class and
            //float at placeholder position
            if (a24Core.isEmpty(objDateInput.val())) {
                this.$("label").removeClass("active");
            }
        },
        clear: function clear() {
            this.setValueFromOutside(null);
        },
        setValueFromOutside: function setValueFromOutside(sSelectedDate) {
            var objDateInput = $("#" + this.get("_sInputId"));

            if (a24Core.isEmpty(sSelectedDate)) {
                objDateInput.pickadate("picker").clear();
            } else if (objDateInput.val() !== sSelectedDate) {
                objDateInput.pickadate("picker").set("select", sSelectedDate, {
                    format: this.get("_objLibConstants.DATE_FORMAT").toLowerCase()
                });
            }
        },
        setInputValue: Ember.observer("sSelectedDate", function () {
            this.set("_bInternalProcess", true);
            this.setValueFromOutside(this.get("sSelectedDate"));
            this.set("_bInternalProcess", false);
        })
    });
});