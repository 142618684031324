define("a24-ember-candidate-profile/components/tab-agency-candidate-profile-external-info", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/tab-agency-candidate-profile-external-info"], function (exports, _didRenderChangesMixin, _tabAgencyCandidateProfileExternalInfo) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _tabAgencyCandidateProfileExternalInfo.default,
        _objNavigation: Ember.inject.service("navigation"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objUserSession: Ember.inject.service("user-session"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objBasicInfoService: Ember.inject.service("rest-agency-candidate-basic-info"),
        _objCandidateMetaService: Ember.inject.service("rest-agency-candidate-meta"),
        _arrExternalData: null,
        _arrExternalDocumentsData: null,
        _arrExternalVerificationsData: null,
        // Commented out for this ticket https://github.com/A24Group/react-core/issues/2995
        // _arrExternalDaysToExpiryData: null,
        _bDisplayReloadCard: false,
        _arrReloadActions: null,
        _bLoading: true,
        _bDisplayPermissionError: false,
        _sActivityContext: null,
        _objBasicInfoGetPromise: null,
        _objCandidateMetaPromise: null,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            var objCandidateViewParams = this.get("_objNavigation.objRouteParams")["index.agency-candidate-profile"];

            var objCandidateSearchViewParams = this.get("_objNavigation.objRouteParams")["index.agency-candidate-search-view"];

            if (!a24Core.isEmpty(objCandidateViewParams)) {
                this.set("_sCandidateId", objCandidateViewParams.candidate_id);
            }
            if (!a24Core.isEmpty(objCandidateSearchViewParams)) {
                this.set("_sCandidateId", objCandidateSearchViewParams.candidate_id);
            }

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.doServiceCalls();
                }
            }]);

            this.doServiceCalls();
        },

        _setTitleBar: function _setTitleBar() {
            this.get("_objTitleBarService").setTitleBarPartially(this.get("_objCandidateProfileStringsService").getString("candidateProfile") + " - " + this.get("_objBasicInfoService.objLastCandidateDetails.first_name") + " " + this.get("_objBasicInfoService.objLastCandidateDetails.last_name"));
        },

        doServiceCalls: function doServiceCalls() {
            var _this2 = this;

            var sCandidateId = this.get("_sCandidateId");

            var objRestBasicInfoPromise = this.get("_objBasicInfoService").getBasicInfo(this, "_objBasicInfoGetPromise", sCandidateId, function () {}, // handled in rsvp
            a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
            function () {
                // do nothing
            }));

            var objRestCandidateMetaPromise = this.get("_objCandidateMetaService").getCandidateMeta(this, "_objCandidateMetaPromise", sCandidateId, function () {}, // handled in rsvp
            a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
            function (sCode /*, sStatus, objErrorThrown, objError*/) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this2.set("_bDisplayReloadCard", true);
                    return;
                }

                // Clear data
                _this2.set("_arrExternalData", []);
                _this2.set("_arrExternalDocumentsData", []);
                _this2.set("_arrExternalVerificationsData", []);
                _this2.set("_bLoading", false);
            }));

            var objServiceCalls = {
                _objRestBasicInfoProperty: objRestBasicInfoPromise,
                _objRestCandidateMetaProperty: objRestCandidateMetaPromise
            };

            var arrRequiredResponses = ["_objRestBasicInfoProperty", "_objRestCandidateMetaProperty"];

            this.set("_bLoading", true);

            Ember.copy(a24RSVP).configure(this, objServiceCalls, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    if (!a24Core.isEmpty(objResponses._objRestBasicInfoProperty) && !a24Core.isEmpty(objResponses._objRestBasicInfoProperty.objHeaders) && objResponses._objRestBasicInfoProperty.objHeaders.statusText === "abort" || !a24Core.isEmpty(objResponses._objRestCandidateMetaProperty) && !a24Core.isEmpty(objResponses._objRestCandidateMetaProperty.objHeaders) && objResponses._objRestCandidateMetaProperty.objHeaders.statusText === "abort") {
                        return;
                    }

                    if (!a24Core.isEmpty(objResponses._objRestBasicInfoProperty) && !a24Core.isEmpty(objResponses._objRestBasicInfoProperty.objResponse)) {
                        _this2._setTitleBar();
                    }

                    if (!a24Core.isEmpty(objResponses._objRestCandidateMetaProperty) && !a24Core.isEmpty(objResponses._objRestCandidateMetaProperty.objResponse)) {
                        var _objCandidateMetaData = objResponses._objRestCandidateMetaProperty.objResponse;

                        var arrExternalDocuments = [];
                        if (!a24Core.isEmpty(_objCandidateMetaData.external_documents)) {
                            arrExternalDocuments = _objCandidateMetaData.external_documents;
                        }

                        _this2.set("_arrExternalDocumentsData", arrExternalDocuments);

                        var arrExternalVerifications = [];
                        if (!a24Core.isEmpty(_objCandidateMetaData.external_verifications)) {
                            arrExternalVerifications = _objCandidateMetaData.external_verifications;
                        }

                        _this2.set("_arrExternalVerificationsData", arrExternalVerifications);

                        // Commented out for this ticket https://github.com/A24Group/react-core/issues/2995
                        // var arrExternalDaysToExpiry = [];
                        // if (!a24Core.isEmpty(_objCandidateMetaData.external_days_to_expiry)) {
                        //     arrExternalDaysToExpiry = _objCandidateMetaData.external_days_to_expiry;
                        // }

                        // this.set("_arrExternalDaysToExpiryData", arrExternalDaysToExpiry);

                        _this2.set("_bLoading", false);
                    }
                }
            }]);
        }

    });
});