define("a24-ember-staffshift-core/services/a24-enums-staffshift-core", ["exports", "a24-ember-lookup/services/a24-enums-base"], function (exports, _a24EnumsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24EnumsBase.default.extend({
        _objBaseStringsService: Ember.inject.service("a24-strings"),
        _objStringsService: Ember.inject.service("a24-strings-staffshift-core"),
        _objConstantsService: Ember.inject.service("a24-constants-staffshift-core"),

        init: function init() {
            this._super.apply(this, arguments);

            var objStrings = this.get("_objStringsService");
            var objBaseStrings = this.get("_objBaseStringsService");
            var objConstants = this.get("_objConstantsService");

            this.set("conversationStatus", {
                "OPEN": {
                    "backend": "open",
                    "frontend": objStrings.getString("open")
                },
                "CLOSED": {
                    "backend": "closed",
                    "frontend": objStrings.getString("closed")
                },
                "CLOSING": {
                    "backend": "closing",
                    "frontend": objStrings.getString("closing")
                },
                "ORPHAN": {
                    "backend": "orphan",
                    "frontend": objStrings.getString("orphan")
                },
                "ARCHIVED": {
                    "backend": "archived",
                    "frontend": objStrings.getString("archived")
                }
            });

            this.set("mediumType", {
                "EMAIL": {
                    "backend": "email",
                    "frontend": objBaseStrings.getString("email"),
                    "icon": "email"
                },
                "SMS": {
                    "backend": "sms",
                    "frontend": objStrings.getString("sms"),
                    "icon": "textsms"
                },
                "WHATSAPP": {
                    "backend": "whatsapp",
                    "frontend": objStrings.getString("whatsapp"),
                    "icon_fa": "fa-whatsapp"
                }
            });

            this.set("notificationStatus", {
                "PROCESSING": {
                    "backend": "processing",
                    "frontend": "Processing"
                },
                "DISPATCHED": {
                    "backend": "dispatched",
                    "frontend": "Dispatched"
                },
                "COMPLETED": {
                    "backend": "completed",
                    "frontend": "Completed"
                },
                "NEW": {
                    "backend": "new",
                    "frontend": "New"
                },
                "PERMANENT_FAILURE": {
                    "backend": "permanent_failure",
                    "frontend": "Permanent failure"
                },
                "TEMPORARY_FAILURE": {
                    "backend": "temporary_failure",
                    "frontend": "Temporary failure"
                },
                "UNSUBSCRIBED": {
                    "backend": "unsubscribed",
                    "frontend": "Unsubscribed"
                },
                "COMPLAINED": {
                    "backend": "complained",
                    "frontend": "Complained"
                },
                "DELIVERED": {
                    "backend": "delivered",
                    "frontend": "Delivered"
                },
                "INITIATED": {
                    "backend": "initiated",
                    "frontend": "Initiated"
                },
                "QUEUED": {
                    "backend": "queued",
                    "frontend": "Queued"
                },
                "SENT": {
                    "backend": "sent",
                    "frontend": "Sent"
                },
                "READ": {
                    "backend": "read",
                    "frontend": "Read"
                },
                "OPT_OUT": {
                    "backend": "opt_out",
                    "frontend": "Opt out"
                }
            });

            this.set("whitelistIps", {
                "SUTTON": {
                    "backend": "109.176.72.130",
                    "frontend": "109.176.72.130"
                },
                "GRANTHAM": {
                    "backend": "82.20.68.14",
                    "frontend": "82.20.68.14"
                },
                "PORTSMOUTH": {
                    "backend": "81.105.219.2",
                    "frontend": "81.105.219.2"
                },
                "STOCKPORT": {
                    "backend": "81.149.198.104",
                    "frontend": "81.149.198.104"
                },
                "FIRSTCALL_EMPLOYMENT_PITSEA": {
                    "backend": "81.130.209.196",
                    "frontend": "81.130.209.196"
                },
                "LEONARDSLEE": {
                    "backend": "62.252.177.138",
                    "frontend": "62.252.177.138"
                },
                "MANNINGS_HEATH": {
                    "backend": "213.105.51.186",
                    "frontend": "213.105.51.186"
                },
                "BELLVILLE": {
                    "backend": "196.14.255.105",
                    "frontend": "196.14.255.105"
                }
            });

            this.set("context", {
                "CANDIDATE": {
                    "backend": objConstants.CONTEXT_CANDIDATE,
                    "frontend": objStrings.getString("candidate")
                },
                "AGENCY": {
                    "backend": objConstants.CONTEXT_AGENCY,
                    "frontend": objStrings.getString("agency")
                },
                "AGENCY_GROUP": {
                    "backend": objConstants.CONTEXT_AGENCY_GROUP,
                    "frontend": objStrings.getString("agencyGroup")
                },
                "ORGANISATION": {
                    "backend": objConstants.CONTEXT_ORGANISATION,
                    "frontend": objStrings.getString("organisation")
                },
                "SYSTEM_ADMIN": {
                    "backend": objConstants.CONTEXT_SYSTEM_ADMIN,
                    "frontend": objStrings.getString("systemAdmin")
                }
            });

            this.set("customMaterialSvgIcons", {
                "facebook": {
                    frontend: "Facebook",
                    backend: "facebook",
                    image: "<svg class=\"material-icons-svg\" viewBox=\"0 0 24 24\"><path fill=\"#000000\" d=\"M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z\" /></svg>"
                },
                "instagram": {
                    frontend: "Instagram",
                    backend: "instagram",
                    image: "<svg class=\"material-icons-svg\" viewBox=\"0 0 24 24\"><path fill=\"#000000\" d=\"M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z\" /></svg>"
                },
                "pinterest": {
                    frontend: "Pinterest",
                    backend: "pinterest",
                    image: "<svg class=\"material-icons-svg\" viewBox=\"0 0 24 24\"><path fill=\"#000000\" d=\"M9.04,21.54C10,21.83 10.97,22 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2A10,10 0 0,0 2,12C2,16.25 4.67,19.9 8.44,21.34C8.35,20.56 8.26,19.27 8.44,18.38L9.59,13.44C9.59,13.44 9.3,12.86 9.3,11.94C9.3,10.56 10.16,9.53 11.14,9.53C12,9.53 12.4,10.16 12.4,10.97C12.4,11.83 11.83,13.06 11.54,14.24C11.37,15.22 12.06,16.08 13.06,16.08C14.84,16.08 16.22,14.18 16.22,11.5C16.22,9.1 14.5,7.46 12.03,7.46C9.21,7.46 7.55,9.56 7.55,11.77C7.55,12.63 7.83,13.5 8.29,14.07C8.38,14.13 8.38,14.21 8.35,14.36L8.06,15.45C8.06,15.62 7.95,15.68 7.78,15.56C6.5,15 5.76,13.18 5.76,11.71C5.76,8.55 8,5.68 12.32,5.68C15.76,5.68 18.44,8.15 18.44,11.43C18.44,14.87 16.31,17.63 13.26,17.63C12.29,17.63 11.34,17.11 11,16.5L10.33,18.87C10.1,19.73 9.47,20.88 9.04,21.57V21.54Z\" /></svg>"
                },
                "skype": {
                    frontend: "Skype",
                    backend: "skype",
                    image: "<svg class=\"material-icons-svg\" viewBox=\"0 0 24 24\"><path fill=\"#000000\" d=\"M18,6C20.07,8.04 20.85,10.89 20.36,13.55C20.77,14.27 21,15.11 21,16A5,5 0 0,1 16,21C15.11,21 14.27,20.77 13.55,20.36C10.89,20.85 8.04,20.07 6,18C3.93,15.96 3.15,13.11 3.64,10.45C3.23,9.73 3,8.89 3,8A5,5 0 0,1 8,3C8.89,3 9.73,3.23 10.45,3.64C13.11,3.15 15.96,3.93 18,6M12.04,17.16C14.91,17.16 16.34,15.78 16.34,13.92C16.34,12.73 15.78,11.46 13.61,10.97L11.62,10.53C10.86,10.36 10,10.13 10,9.42C10,8.7 10.6,8.2 11.7,8.2C13.93,8.2 13.72,9.73 14.83,9.73C15.41,9.73 15.91,9.39 15.91,8.8C15.91,7.43 13.72,6.4 11.86,6.4C9.85,6.4 7.7,7.26 7.7,9.54C7.7,10.64 8.09,11.81 10.25,12.35L12.94,13.03C13.75,13.23 13.95,13.68 13.95,14.1C13.95,14.78 13.27,15.45 12.04,15.45C9.63,15.45 9.96,13.6 8.67,13.6C8.09,13.6 7.67,14 7.67,14.57C7.67,15.68 9,17.16 12.04,17.16Z\" /></svg>"
                },
                "twitter": {
                    frontend: "Twitter",
                    backend: "twitter",
                    image: "<svg class=\"material-icons-svg\" viewBox=\"0 0 24 24\"><path fill=\"#000000\" d=\"M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z\" /></svg>"
                },
                "youtube": {
                    frontend: "Youtube",
                    backend: "youtube",
                    image: "<svg class=\"material-icons-svg\" viewBox=\"0 0 24 24\"><path fill=\"#000000\" d=\"M10,15L15.19,12L10,9V15M21.56,7.17C21.69,7.64 21.78,8.27 21.84,9.07C21.91,9.87 21.94,10.56 21.94,11.16L22,12C22,14.19 21.84,15.8 21.56,16.83C21.31,17.73 20.73,18.31 19.83,18.56C19.36,18.69 18.5,18.78 17.18,18.84C15.88,18.91 14.69,18.94 13.59,18.94L12,19C7.81,19 5.2,18.84 4.17,18.56C3.27,18.31 2.69,17.73 2.44,16.83C2.31,16.36 2.22,15.73 2.16,14.93C2.09,14.13 2.06,13.44 2.06,12.84L2,12C2,9.81 2.16,8.2 2.44,7.17C2.69,6.27 3.27,5.69 4.17,5.44C4.64,5.31 5.5,5.22 6.82,5.16C8.12,5.09 9.31,5.06 10.41,5.06L12,5C16.19,5 18.8,5.16 19.83,5.44C20.73,5.69 21.31,6.27 21.56,7.17Z\" /></svg>"
                },
                "label_off": {
                    frontend: "Label Off",
                    backend: "label_off",
                    image: "<svg class=\"material-icons-svg\" viewBox=\"0 0 24 24\"><path fill=\"#000000\" d=\"M3.25 2.75l17 17L19 21l-2-2H5c-1.1 0-2-.9-2-2V7c0-.55.23-1.05.59-1.41L2 4l1.25-1.25zM22 12l-4.37-6.16C17.27 5.33 16.67 5 16 5H8l11 11 3-4z\"/></svg>"
                },
                "group_add": {
                    frontend: "Group Add",
                    backend: "group_add",
                    image: "<svg class=\"material-icons-svg\" viewBox=\"0 0 24 24\"><path d=\"M0 20v-2.8q0-.85.438-1.563.437-.712 1.162-1.087 1.55-.775 3.15-1.163Q6.35 13 8 13t3.25.387q1.6.388 3.15 1.163.725.375 1.162 1.087Q16 16.35 16 17.2V20Zm8-8q-1.65 0-2.825-1.175Q4 9.65 4 8q0-1.65 1.175-2.825Q6.35 4 8 4q1.65 0 2.825 1.175Q12 6.35 12 8q0 1.65-1.175 2.825Q9.65 12 8 12Zm4.5-.05q.725-.8 1.113-1.825Q14 9.1 14 8t-.387-2.125Q13.225 4.85 12.5 4.05q1.5.2 2.5 1.325T16 8q0 1.5-1 2.625t-2.5 1.325ZM18 20v-3q0-.9-.4-1.712-.4-.813-1.05-1.438 1.275.45 2.363 1.162Q20 15.725 20 17v3Zm2-7v-2h-2V9h2V7h2v2h2v2h-2v2Z\"/></svg>"
                },
                "group_remove": {
                    frontend: "Group Remove",
                    backend: "group_remove",
                    image: "<svg class=\"material-icons-svg\" viewBox=\"0 0 24 24\"><path d=\"M12.5 11.95q.725-.8 1.113-1.825Q14 9.1 14 8t-.387-2.125Q13.225 4.85 12.5 4.05q1.5.2 2.5 1.325T16 8q0 1.5-1 2.625t-2.5 1.325ZM18 20v-3q0-.9-.4-1.712-.4-.813-1.05-1.438 1.275.45 2.363 1.162Q20 15.725 20 17v3Zm6-9h-6V9h6ZM8 12q-1.65 0-2.825-1.175Q4 9.65 4 8q0-1.65 1.175-2.825Q6.35 4 8 4q1.65 0 2.825 1.175Q12 6.35 12 8q0 1.65-1.175 2.825Q9.65 12 8 12Zm-8 8v-2.8q0-.85.438-1.563.437-.712 1.162-1.087 1.55-.775 3.15-1.163Q6.35 13 8 13t3.25.387q1.6.388 3.15 1.163.725.375 1.162 1.087Q16 16.35 16 17.2V20Z\"/></svg>"
                },
                "diversity_1": {
                    frontend: "Diversity 1",
                    backend: "diversity_1",
                    image: "<svg class=\"material-icons-svg\" viewBox=\"0 0 24 24\"><path d=\"M.95 13.3q-.45-.9-.7-1.825Q0 10.55 0 9.6q0-2.8 1.9-4.7Q3.8 3 6.6 3q1.575 0 3 .662 1.425.663 2.4 1.838.975-1.175 2.4-1.838Q15.825 3 17.4 3q2.8 0 4.7 1.9Q24 6.8 24 9.6q0 .95-.25 1.875t-.7 1.825q-.275-.475-.65-.85t-.875-.6q.225-.575.35-1.125Q22 10.175 22 9.6q0-1.95-1.325-3.275T17.4 5q-2.025 0-3.112 1.112Q13.2 7.225 12 8.6q-1.2-1.4-2.287-2.5Q8.625 5 6.6 5 4.65 5 3.325 6.325T2 9.6q0 .575.125 1.125t.35 1.125q-.5.225-.875.6t-.65.85ZM0 22v-1.575q0-1.1 1.113-1.762Q2.225 18 4 18q.325 0 .625.012.3.013.575.063-.35.5-.525 1.075-.175.575-.175 1.225V22Zm6 0v-1.625q0-1.625 1.663-2.625 1.662-1 4.337-1 2.7 0 4.35 1 1.65 1 1.65 2.625V22Zm13.5 0v-1.625q0-.65-.163-1.225-.162-.575-.487-1.075.275-.05.563-.063Q19.7 18 20 18q1.8 0 2.9.663 1.1.662 1.1 1.762V22ZM4 17q-.825 0-1.412-.587Q2 15.825 2 15q0-.85.588-1.425Q3.175 13 4 13q.85 0 1.425.575Q6 14.15 6 15q0 .825-.575 1.413Q4.85 17 4 17Zm16 0q-.825 0-1.413-.587Q18 15.825 18 15q0-.85.587-1.425Q19.175 13 20 13q.85 0 1.425.575Q22 14.15 22 15q0 .825-.575 1.413Q20.85 17 20 17Zm-8-1q-1.25 0-2.125-.875T9 13q0-1.275.875-2.137Q10.75 10 12 10q1.275 0 2.137.863Q15 11.725 15 13q0 1.25-.863 2.125Q13.275 16 12 16Z\"/></svg>"
                },
                "punch_clock": {
                    frontend: "Punch Clock",
                    backend: "punch_clock",
                    image: "<svg class=\"material-icons-svg\" viewBox=\"0 0 24 24\"><path d=\"M5 22q-.825 0-1.413-.587Q3 20.825 3 20V8q0-.825.587-1.412Q4.175 6 5 6h1V1h12v5h1q.825 0 1.413.588Q21 7.175 21 8v12q0 .825-.587 1.413Q19.825 22 19 22ZM8 6h8V3H8Zm4 8Zm0 4.2q1.725 0 2.963-1.238Q16.2 15.725 16.2 14t-1.237-2.963Q13.725 9.8 12 9.8t-2.962 1.237Q7.8 12.275 7.8 14t1.238 2.962Q10.275 18.2 12 18.2Zm1.15-2.35L11.5 14.2v-2.7h1v2.3l1.35 1.35Z\"/></svg>"
                },
                "punch_clock_white": {
                    frontend: "Punch Clock White",
                    backend: "punch_clock_white",
                    image: "<svg class=\"material-icons-svg\" viewBox=\"0 0 24 24\"><path fill=\"#FFFFFF\" d=\"M5 22q-.825 0-1.413-.587Q3 20.825 3 20V8q0-.825.587-1.412Q4.175 6 5 6h1V1h12v5h1q.825 0 1.413.588Q21 7.175 21 8v12q0 .825-.587 1.413Q19.825 22 19 22ZM8 6h8V3H8Zm4 8Zm0 4.2q1.725 0 2.963-1.238Q16.2 15.725 16.2 14t-1.237-2.963Q13.725 9.8 12 9.8t-2.962 1.237Q7.8 12.275 7.8 14t1.238 2.962Q10.275 18.2 12 18.2Zm1.15-2.35L11.5 14.2v-2.7h1v2.3l1.35 1.35Z\"/></svg>"
                    // add svg's here for some icons
                } });

            this.set("socialIcons", {
                "FACEBOOK": {
                    frontend: "Facebook",
                    backend: "facebook"
                },
                "INSTAGRAM": {
                    frontend: "Instagram",
                    backend: "instagram"
                },
                "PINTEREST": {
                    frontend: "Pinterest",
                    backend: "pinterest"
                },
                "SKYPE": {
                    frontend: "Skype",
                    backend: "skype"
                },
                "TWITTER": {
                    frontend: "Twitter",
                    backend: "twitter"
                },
                "YOUTUBE": {
                    frontend: "Youtube",
                    backend: "youtube"
                }
            });

            this.set("assignmentStatus", {
                "NEW": {
                    "backend": "New",
                    "frontend": objStrings.getString("new")
                },
                "ACCEPTED": {
                    "backend": "Accepted",
                    "frontend": objStrings.getString("accepted")
                },
                "CLOSED": {
                    "backend": "Closed",
                    "frontend": objStrings.getString("closed")
                },
                "CANCELLED": {
                    "backend": "Cancelled",
                    "frontend": objStrings.getString("cancelled")
                },
                "REOPENED": {
                    "backend": "Reopened",
                    "frontend": objStrings.getString("reopened")
                }
            });

            this.set("activitySubjectTypes", {
                "CANDIDATE": {
                    "backend": "candidate",
                    "frontend": objStrings.getString("candidate")
                },
                "CLIENT": {
                    "backend": "client",
                    "frontend": objStrings.getString("client")
                }
            });

            this.set("conversationTypes", {
                "CANDIDATE_SUPPORT": {
                    "backend": "candidate_support",
                    "frontend": objStrings.getString("candidateSupport")
                },
                "CANDIDATE_SUPPORT_INITIATED": {
                    "backend": "candidate_support_initiated",
                    "frontend": objStrings.getString("candidateSupportInitiated")
                },
                "CLIENT_SUPPORT": {
                    "backend": "client_support",
                    "frontend": objStrings.getString("clientSupport")
                },
                "CLIENT_SUPPORT_INITIATED": {
                    "backend": "client_support_initiated",
                    "frontend": objStrings.getString("clientSupportInitiated")
                },
                "GUEST_SUPPORT": {
                    "backend": "guest_support",
                    "frontend": objStrings.getString("guestSupport")
                }
            });

            this.set("conversationTypesAgencyGroup", {
                "CANDIDATE_SUPPORT": {
                    "backend": "candidate_support",
                    "frontend": objStrings.getString("candidateSupport")
                },
                "CANDIDATE_SUPPORT_INITIATED": {
                    "backend": "candidate_support_initiated",
                    "frontend": objStrings.getString("candidateSupportInitiated")
                },
                "GUEST_SUPPORT": {
                    "backend": "guest_support",
                    "frontend": objStrings.getString("guestSupport")
                }
            });

            this.set("conversationMedium", {
                "SMS": {
                    "backend": "sms",
                    "frontend": objStrings.getString("sms")
                },
                "CHAT": {
                    "backend": "chat",
                    "frontend": objStrings.getString("chat")
                },
                "WHATSAPP": {
                    "backend": "whatsapp",
                    "frontend": objStrings.getString("whatsapp")
                }
            });
        },

        getMaterialSvgIcon: function getMaterialSvgIcon(sIconRef) {
            var sImage = this.get("customMaterialSvgIcons." + sIconRef + ".image");
            if (a24Core.isEmpty(sImage)) {
                return "<svg class=\"material-icons-svg\" viewBox=\"0 0 24 24\"></svg>";
            } else {
                return sImage;
            }
        }
    });
});