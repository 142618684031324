define("a24-ember-form-inputs/components/input-form-number", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-form-inputs/templates/components/input-form-number", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _inputFormNumber, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _inputFormNumber.default,
        classNames: ["input-form-number", "md-form", "a24ErrorInputParent"],
        classNameBindings: ["bIsOutline:a24-form-outlined", "bDisabled:is-disabled"],
        sInputType: "number",
        iStepValue: 1,
        iMinValue: null,
        iMaxValue: null,
        bForceStep: false,
        bSpinValue: false,
        bDisabled: false,
        bIsOutline: false,
        bFormatOnFocusout: false,
        bDisplayDefault: false,
        bAllowFloat: false,
        bAllowNegative: false,
        iFloatPlaces: 1,
        iLeadingZero: null,
        _sValue: "",
        _sInputId: null,
        init: function init() {
            this._super.apply(this, arguments);
            this.set("_sInputId", Ember.guidFor({}));

            Ember.run.scheduleOnce("afterRender", this, function () {
                var _this = this;

                var objTextInput = $("#" + this.get("_sInputId"));

                objTextInput.on("blur", function () {
                    _this.resetFloatingLabel();

                    if (!_this.get("_bInternalChange") && _this.get("bFormatOnFocusout")) {
                        _this._setValueFromInputChange(_this.get("_sValue"));
                    }
                });

                objTextInput.on("keyup", function (objEvent) {
                    if (objEvent.keyCode === 27) {
                        // Escape key
                        objTextInput.change();
                        objTextInput.blur();
                    }
                });

                objTextInput.on("keydown", function (objEvent) {
                    if (objEvent.keyCode === 38) {
                        _this._incrementValue();
                    } else if (objEvent.keyCode === 40) {
                        _this._decrementValue();
                    }
                });

                objTextInput.on("wheel", function (objEvent) {
                    if (objTextInput.is(":focus")) {
                        objEvent.preventDefault();
                        objEvent.stopPropagation();
                        Ember.run.once(_this, _this._handleWheelEvent, objEvent);
                    }
                });

                this._setValueFromInputChange(null);
            });
        },
        _handleWheelEvent: function _handleWheelEvent(objEvent) {
            if (!a24Core.isEmpty(objEvent.originalEvent) && !a24Core.isEmpty(objEvent.originalEvent.deltaY)) {
                if (objEvent.originalEvent.deltaY < 0) {
                    this._incrementValue();
                } else if (objEvent.originalEvent.deltaY > 0) {
                    this._decrementValue();
                }
            }
        },
        _focus: function _focus() {
            var _this2 = this;

            Ember.run(function () {
                _this2.$("input").eq(0).focus();
            });
        },
        _observeMaxValue: Ember.observer("iMaxValue", function () {
            if (!this.get("_bInternalChange")) {
                if (!a24Core.isEmpty(this.get("mValue")) && !a24Core.isEmpty(this.get("iMaxValue"))) {
                    if (this.get("mValue") > this.get("iMaxValue")) {
                        this._setValueFromInputChange(this.get("iMaxValue") + "");
                    }
                }
            }
        }),
        _observeMinValue: Ember.observer("iMinValue", function () {
            if (!this.get("_bInternalChange")) {
                if (!a24Core.isEmpty(this.get("mValue")) && !a24Core.isEmpty(this.get("iMinValue"))) {
                    if (this.get("mValue") < this.get("iMinValue")) {
                        this._setValueFromInputChange(this.get("iMinValue") + "");
                    }
                }
            }
        }),
        _observeSValue: Ember.observer("_sValue", function () {
            if (!this.get("_bInternalChange") && !this.get("bFormatOnFocusout")) {
                this._setValueFromInputChange(this.get("_sValue"));
            }
        }),
        clear: function clear() {
            this.setValueFromOutside("");
        },
        _roundToNearestStep: function _roundToNearestStep(iValue) {
            return Math.round(iValue / this.get("iStepValue")) * this.get("iStepValue");
        },
        _getDefaultDisplayValue: function _getDefaultDisplayValue() {
            var sValue = "0";

            if (!a24Core.isEmpty(this.get("iMinValue")) && this.get("iMinValue") > 0) {
                sValue = "" + this.get("iMinValue");
            } else if (!a24Core.isEmpty(this.get("iMaxValue")) && this.get("iMaxValue") < 0) {
                sValue = "" + this.get("iMaxValue");
            }

            if (!a24Core.isEmpty(this.get("iLeadingZero"))) {
                sValue = this._addLeadingZerosToNumber(this._parseToNumber(sValue));
            }

            return sValue;
        },
        _setValueFromInputChange: function _setValueFromInputChange(sValue) {
            this.set("_bInternalChange", true);
            var sNewValue = "";

            if (!a24Core.isEmpty(sValue)) {
                var objParsed = this._parseToString(sValue);
                var iInputValue = null;

                if (objParsed.sValue === "-") {
                    sNewValue = objParsed.sValue;
                } else if (a24Core.isEmpty(objParsed.sValue) && objParsed.bTrailingDot) {
                    sNewValue = ".";
                } else if (!a24Core.isEmpty(objParsed.sValue)) {
                    iInputValue = this._parseToNumber(objParsed.sValue);
                }

                if (!a24Core.isEmpty(iInputValue)) {
                    if (!a24Core.isEmpty(this.get("iMaxValue")) && iInputValue > this.get("iMaxValue")) {
                        iInputValue = this.get("iMaxValue");
                    }
                    if (!a24Core.isEmpty(this.get("iMinValue")) && iInputValue < this.get("iMinValue")) {
                        iInputValue = this.get("iMinValue");
                    }
                    if (this.get("bForceStep") && !this.get("bAllowFloat")) {
                        iInputValue = this._roundToNearestStep(iInputValue);
                    }
                    if (!a24Core.isEmpty(this.get("iLeadingZero"))) {
                        sNewValue = this._addLeadingZerosToNumber(iInputValue);
                        if (objParsed.bTrailingDot) {
                            sNewValue = sNewValue + ".";
                        }
                    } else if (objParsed.bTrailingDot) {
                        sNewValue = "" + iInputValue + ".";
                    } else {
                        sNewValue = "" + iInputValue;
                    }
                } else if (this.get("bDisplayDefault")) {
                    sNewValue = this._getDefaultDisplayValue();
                }
            } else if (this.get("bDisplayDefault")) {
                sNewValue = this._getDefaultDisplayValue();
            }

            this._setInputValue(sNewValue);
            this.set("_bInternalChange", false);
        },
        _setInputValue: function _setInputValue(sValue) {
            this.set("_sValue", sValue);
            var objTextInput = $("#" + this.get("_sInputId"));
            objTextInput.val(sValue);
            objTextInput.change();
            this.resetFloatingLabel();

            var iValue = null;
            if (sValue !== "-" && sValue !== ".") {
                iValue = this._parseToNumber(sValue);
            }
            this.setMValueWithInternalFlag(iValue, false);
        },
        _parseToString: function _parseToString(sInputValue) {
            if (a24Core.isEmpty(sInputValue)) {
                return {
                    sValue: null,
                    bTrailingDot: false
                };
            }

            var sParsedNumber = "";
            var arrValids = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

            if (this.get("bAllowFloat")) {
                arrValids.push(".");
            }

            if (this.get("bAllowNegative")) {
                arrValids.push("-");
            }

            for (var i = 0; i < sInputValue.length; i++) {
                if (arrValids.indexOf(sInputValue.charAt(i)) !== -1) {
                    sParsedNumber += sInputValue.charAt(i);
                }
            }

            //Make sure that there is only one "." in the string, we keep the first one and just remove the rest.
            var arrSplit = sParsedNumber.split(".");
            if (arrSplit.length !== 1) {
                sParsedNumber = arrSplit[0] + "." + arrSplit[1];
                for (var j = 2; j < arrSplit.length; j++) {
                    sParsedNumber += arrSplit[j];
                }
            }

            if (sParsedNumber.charAt(0) === "." && !this.get("bAllowFloat")) {
                sParsedNumber = "";
            }

            if (a24Core.isEmpty(sParsedNumber)) {
                return {
                    sValue: null,
                    bTrailingDot: false
                };
            } else if (sParsedNumber === ".") {
                return {
                    sValue: null,
                    bTrailingDot: true
                };
            } else if (this.get("bAllowFloat")) {
                //Trim the string to the allowed float places.
                var iIndexOfDot = sParsedNumber.indexOf(".");
                if (iIndexOfDot !== -1) {
                    sParsedNumber = sParsedNumber.substring(0, iIndexOfDot + 1 + this.get("iFloatPlaces"));
                }

                return {
                    sValue: sParsedNumber,
                    bTrailingDot: sParsedNumber[sParsedNumber.length - 1] === "."
                };
            } else if (this.get("bAllowNegative")) {
                var iIndexOfNeg = sParsedNumber.indexOf("-");
                var iIndexOfOtherNeg = sParsedNumber.indexOf("-", 1);
                var sBeforeNeg = null;
                var sAfterNeg = null;
                if (iIndexOfNeg === 0) {
                    if (iIndexOfOtherNeg !== -1) {
                        sBeforeNeg = sParsedNumber.substring(0, iIndexOfOtherNeg);
                        sAfterNeg = sParsedNumber.substring(iIndexOfOtherNeg + 1, sParsedNumber.length);
                        sParsedNumber = sBeforeNeg + sAfterNeg;
                    }
                    if (sParsedNumber.length > 1) {
                        return {
                            sValue: sParsedNumber,
                            bTrailingDot: false
                        };
                    }
                    return {
                        sValue: sParsedNumber,
                        bTrailingDot: false
                    };
                } else if (iIndexOfNeg > 0) {
                    sBeforeNeg = sParsedNumber.substring(0, iIndexOfNeg);
                    sAfterNeg = sParsedNumber.substring(iIndexOfNeg + 1, sParsedNumber.length);
                    sParsedNumber = sBeforeNeg + sAfterNeg;
                }
                return {
                    sValue: sParsedNumber,
                    bTrailingDot: false
                };
            } else {
                return {
                    sValue: sParsedNumber,
                    bTrailingDot: false
                };
            }
        },
        _parseToNumber: function _parseToNumber(sValue) {
            if (a24Core.isEmpty(sValue)) {
                return null;
            } else if (sValue === "-") {
                return sValue;
            } else if (this.get("bAllowFloat")) {
                return parseFloat(sValue);
            } else {
                return parseInt(sValue);
            }
        },
        resetFloatingLabel: function resetFloatingLabel() {
            //If the input field has no value then the label should NOT have the active class and
            //float at placeholder position
            if (a24Core.isEmpty(this.get("_sValue"))) {
                this.$("label").removeClass("active");
            }
        },
        setValueFromOutside: function setValueFromOutside(iValue) {
            this._setValueFromInputChange(iValue + "");
        },

        _addLeadingZerosToNumber: function _addLeadingZerosToNumber(iValue) {
            var sValue = iValue < 0 ? "-" : "";
            var sAbsValue = Math.abs(iValue) + "";
            var iValueLength = sAbsValue.split(".")[0].length;
            var iLength = this.get("iLeadingZero") - iValueLength;

            if (this.get("iLeadingZero") > iValueLength) {
                for (var i = 0; i < iLength; i++) {
                    sValue += "0";
                }
            } else {
                sAbsValue = sAbsValue.substring(iValueLength - this.get("iLeadingZero"));
            }

            return sValue + sAbsValue;
        },
        _addFloatingZerosToNumber: function _addFloatingZerosToNumber(sValue) {
            if (!a24Core.isEmpty(sValue.split(".")[1])) {
                return sValue;
            }
            var sReturn = sValue;
            if (sReturn.indexOf(".") === -1) {
                sReturn += ".";
            }
            for (var i = 0; i < this.get("iFloatPlaces"); i++) {
                sReturn += "0";
            }
            return sReturn;
        },
        _incrementValue: function _incrementValue() {
            if (this.get("bDisabled")) {
                return;
            }

            this.set("_bInternalChange", true);
            var sValue = this.get("_sValue");

            if (a24Core.isEmpty(sValue) && !a24Core.isEmpty(this.get("iMinValue"))) {
                sValue = this.get("iMinValue") + "";
            } else if (a24Core.isEmpty(sValue)) {
                sValue = "0";
            }

            var objParsed = this._parseToString(sValue);
            var iInputValue = this._parseToNumber(objParsed.sValue);
            if (this.get("bAllowFloat")) {
                iInputValue = this._parseToNumber(iInputValue.toFixed(this.get("iFloatPlaces")));
            }
            if (!a24Core.isEmpty(iInputValue)) {
                if (!a24Core.isEmpty(this.get("iMaxValue")) && iInputValue >= this.get("iMaxValue")) {
                    if (this.get("bSpinValue") === true && !a24Core.isEmpty(this.get("iMinValue"))) {
                        var iValue = this._roundToNearestStep(this.get("iMinValue"));
                        if (iValue < this.get("iMinValue")) {
                            iValue = iValue + this.get("iStepValue");
                        }
                        if (!a24Core.isEmpty(this.get("iLeadingZero"))) {
                            sValue = this._addLeadingZerosToNumber(iValue);
                        } else {
                            sValue = "" + iValue;
                        }
                        if (!a24Core.isEmpty(this.get("onSpinMin"))) {
                            this.get("onSpinMin")(iValue);
                        }
                    }
                } else if (!a24Core.isEmpty(this.get("iMinValue")) && iInputValue < this.get("iMinValue")) {
                    if (!a24Core.isEmpty(this.get("iLeadingZero"))) {
                        sValue = this._addLeadingZerosToNumber(this.get("iMinValue"));
                    } else {
                        sValue = "" + this.get("iMinValue");
                    }
                } else {
                    if (!this.get("bAllowFloat")) {
                        iInputValue = Math.floor(iInputValue / this.get("iStepValue")) * this.get("iStepValue");
                    }
                    var iNewValue = iInputValue + this.get("iStepValue");
                    if (this.get("bAllowFloat")) {
                        iNewValue = this._parseToNumber(iNewValue.toFixed(this.get("iFloatPlaces")));
                    }
                    if (!a24Core.isEmpty(this.get("iMaxValue")) && iNewValue > this.get("iMaxValue")) {
                        if (this.get("bSpinValue") && !a24Core.isEmpty(this.get("iMinValue"))) {
                            var iRounded = this._roundToNearestStep(this.get("iMinValue"));
                            var sNewValue = iNewValue + "";

                            if (sNewValue.indexOf(".") !== -1 && !a24Core.isEmpty(sNewValue.split(".")[1])) {
                                sNewValue = iRounded + "" + "." + sNewValue.split(".")[1];
                                iNewValue = this._parseToNumber(sNewValue);
                            } else {
                                iNewValue = iRounded;
                            }

                            if (iNewValue < this.get("iMinValue")) {
                                iNewValue = this._parseToNumber((iNewValue + this.get("iStepValue")).toFixed(this.get("iFloatPlaces")));
                            }

                            if (!a24Core.isEmpty(this.get("onSpinMin"))) {
                                this.get("onSpinMin")(iNewValue);
                            }
                        } else {
                            iNewValue = iInputValue;
                        }
                    }
                    if (objParsed.bTrailingDot) {
                        sValue = "" + iNewValue + ".";
                    } else {
                        sValue = "" + iNewValue;
                    }

                    if (!a24Core.isEmpty(this.get("iLeadingZero"))) {
                        sValue = this._addLeadingZerosToNumber(iNewValue);
                    }
                }
            }
            this._setInputValue(sValue);

            this._focus();

            this.set("_bInternalChange", false);
        },
        _decrementValue: function _decrementValue() {
            if (this.get("bDisabled")) {
                return;
            }

            this.set("_bInternalChange", true);
            var sValue = this.get("_sValue");

            if (a24Core.isEmpty(sValue) && !a24Core.isEmpty(this.get("iMinValue"))) {
                sValue = this.get("iMinValue") + "";
            } else if (a24Core.isEmpty(sValue)) {
                sValue = "0";
            }

            var objParsed = this._parseToString(sValue);
            var iInputValue = this._parseToNumber(objParsed.sValue);

            if (!a24Core.isEmpty(iInputValue)) {
                if (!a24Core.isEmpty(this.get("iMinValue")) && iInputValue <= this.get("iMinValue")) {
                    if (this.get("bSpinValue") === true && !a24Core.isEmpty(this.get("iMaxValue"))) {
                        var iValue = this._roundToNearestStep(this.get("iMaxValue"));
                        if (iValue > this.get("iMaxValue")) {
                            iValue = iValue - this.get("iStepValue");
                        }
                        if (!a24Core.isEmpty(this.get("iLeadingZero"))) {
                            sValue = this._addLeadingZerosToNumber(iValue);
                        } else {
                            sValue = "" + iValue;
                        }
                        if (!a24Core.isEmpty(this.get("onSpinMax"))) {
                            this.get("onSpinMax")(iValue);
                        }
                    }
                } else {
                    if (!this.get("bAllowFloat")) {
                        iInputValue = Math.ceil(iInputValue / this.get("iStepValue")) * this.get("iStepValue");
                    }
                    var iNewValue = iInputValue - this.get("iStepValue");
                    if (this.get("bAllowFloat")) {
                        iNewValue = this._parseToNumber(iNewValue.toFixed(this.get("iFloatPlaces")));
                    }

                    if (!a24Core.isEmpty(this.get("iMinValue")) && iNewValue < this.get("iMinValue")) {
                        if (this.get("bSpinValue") && !a24Core.isEmpty(this.get("iMaxValue"))) {
                            var iRounded = this._roundToNearestStep(this.get("iMaxValue"));
                            var sNewValue = iNewValue + "";

                            if (sNewValue.indexOf(".") !== -1 && !a24Core.isEmpty(sNewValue.split(".")[1])) {
                                sNewValue = iRounded + "" + "." + sNewValue.split(".")[1];
                                iNewValue = this._parseToNumber(sNewValue);
                            } else {
                                iNewValue = iRounded;
                            }

                            if (iNewValue > this.get("iMaxValue")) {
                                iNewValue = this._parseToNumber((iNewValue - this.get("iStepValue")).toFixed(this.get("iFloatPlaces")));
                            }

                            if (!a24Core.isEmpty(this.get("onSpinMax"))) {
                                this.get("onSpinMax")(iNewValue);
                            }
                        } else {
                            iNewValue = iInputValue;
                        }
                    } else if (!this.get("bAllowNegative") && a24Core.isEmpty(this.get("iMinValue")) && iNewValue < 0) {
                        iNewValue = 0;
                    }

                    if (objParsed.bTrailingDot) {
                        sValue = "" + iNewValue + ".";
                    } else {
                        sValue = "" + iNewValue;
                    }

                    if (!a24Core.isEmpty(this.get("iLeadingZero"))) {
                        sValue = this._addLeadingZerosToNumber(iNewValue);
                    }
                }
            }
            this._setInputValue(sValue);

            this._focus();

            this.set("_bInternalChange", false);
        },
        actions: {
            incrementValue: function incrementValue() {
                this._incrementValue();
            },
            decrementValue: function decrementValue() {
                this._decrementValue();
            }
        }
    });
});