define("a24-ember-candidate-profile/services/rest-agency-autocomplete", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * Returns a list for autocomplete for agency
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param objQuery - The query params object
         * @param sField - The autocomplete field
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  9 August 2021
         */
        _getAutocomplete: function _getAutocomplete(objContext, sProperty, objQuery, sField, funcSuccess, objFailure) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/autocomplete/" + sField);

            a24RestUrlConstruct.addQueryParamObject(objQuery);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Returns a list of counties for agency
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param objQuery - The query params object
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  9 August 2021
         */
        getCounties: function getCounties(objContext, sProperty, objQuery, funcSuccess, objFailure) {
            return this._getAutocomplete(objContext, sProperty, objQuery, "county", funcSuccess, objFailure);
        },

        /**
         * Returns a list of cities for agency
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param objQuery - The query params object
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  9 August 2021
         */
        getCities: function getCities(objContext, sProperty, objQuery, funcSuccess, objFailure) {
            return this._getAutocomplete(objContext, sProperty, objQuery, "city", funcSuccess, objFailure);
        }
    });
});