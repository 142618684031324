define("a24-ember-staffshift-core/components/chatter-right-conversation-component", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/chatter-right-conversation-component"], function (exports, _didRenderChangesMixin, _chatterRightConversationComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _chatterRightConversationComponent.default,
        sConversationUserId: null,
        objChatConversationRestService: null,
        classNames: ["a24-chatter-right-conversation-component"],
        _arrReloadActions: null,
        _bServerError: false,
        _bArchivedTabActive: false,
        _objSSCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objSSCoreStrings").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.set("_bServerError", false);
                }
            }]);
        },

        actions: {
            onClickCurrent: function onClickCurrent() {
                this.set("_bArchivedTabActive", false);
            },

            onClickArchived: function onClickArchived() {
                this.set("_bArchivedTabActive", true);
            },

            onServerError: function onServerError() {
                this.set("_bServerError", true);
            },

            onGoToConversation: function onGoToConversation(objConversation) {
                if (this.get("onGoToConversation")) {
                    this.get("onGoToConversation")(objConversation);
                }
            }
        }
    });
});