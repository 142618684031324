define("a24-ember-staffshift-core/components/chatter-box", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/chatter-box", "a24-ember-staffshift-core/mixins/chat-boxes-component-base"], function (exports, _didRenderChangesMixin, _chatterBox, _chatBoxesComponentBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _chatBoxesComponentBase.default, {
        layout: _chatterBox.default,
        classNames: ["a24-chat-box"],

        objChatBoxService: Ember.inject.service("chatter-box-service"),
        _objHelpBoxService: Ember.inject.service("help-box-service"),

        sChatBoxUrl: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment").sChatboxUrl;
        }),

        _handleMessages: function _handleMessages(objData) {
            this._super(objData);
            if (objData.type === "help_box_data") {
                this.get("_objHelpBoxService").postMessage(objData.data);
            }
        }
    });
});