define("a24-ember-candidate-profile/services/rest-agency-notification-types", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),
        sRestApiBaseUrl: Ember.computed.readOnly("_objEnv.sRestApiBaseUrl"),

        /**
         * Route for agency notification types get list
         *
         * @param objContext - The the caller this function
         * @param sProperty - The property name to get the promise object
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         * @param objQueryParams (Optional) - An object containing query params
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  1 Sept 2020
         */
        getNotificationTypes: function getNotificationTypes(objContext, sProperty, funcSuccess, objFailure, objQueryParams) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency/" + sAgencyId + "/lookup/candidate-profile-notifications");

            if (!a24Core.isEmpty(objQueryParams)) {
                a24RestUrlConstruct.addQueryParamObject(objQueryParams);
            }

            a24RestUrlConstruct.addQueryParamObject({
                name: {
                    bSortBy: true,
                    mValue: "asc"
                }
            });

            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: {
                    mValue: 1000
                }
            });

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});