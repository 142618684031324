define("a24-ember-candidate-profile/components/tab-agency-group-candidate-profile-external-info", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/tab-agency-group-candidate-profile-external-info"], function (exports, _didRenderChangesMixin, _tabAgencyGroupCandidateProfileExternalInfo) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _tabAgencyGroupCandidateProfileExternalInfo.default,
        _objNavigationService: Ember.inject.service("navigation"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objUserSession: Ember.inject.service("user-session"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateAdvancedSearchRestService: Ember.inject.service("rest-agency-group-candidate-advanced-search"),
        _arrExternalData: null,
        _arrExternalDocumentsData: null,
        _arrExternalVerificationsData: null,
        _arrExternalDaysToExpiryData: null,
        _bDisplayReloadCard: false,
        _arrReloadActions: null,
        _bLoading: true,
        _bDisplayPermissionError: false,
        _sActivityContext: null,
        _objCandidateSearchPostPromise: null,
        _objLastCandidateDetails: null,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            var objCandidateViewParams = this.get("_objNavigationService.objRouteParams")["agency-group.candidate-view"];
            var objCandidateSearchViewParams = this.get("_objNavigationService.objRouteParams")["agency-group.candidate-search-view"];

            if (!a24Core.isEmpty(objCandidateViewParams)) {
                this.set("_sCandidateId", objCandidateViewParams.candidate_id);
            }
            if (!a24Core.isEmpty(objCandidateSearchViewParams)) {
                this.set("_sCandidateId", objCandidateSearchViewParams.candidate_id);
            }

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.doServiceCalls();
                }
            }]);

            this.doServiceCalls();
        },

        _setTitleBar: function _setTitleBar() {
            this.get("_objTitleBarService").setTitleBarPartially(this.get("_objCandidateProfileStringsService").getString("candidateProfile") + " - " + this.get("_objLastCandidateDetails.first_name") + " " + this.get("_objLastCandidateDetails.last_name"));
        },

        doServiceCalls: function doServiceCalls() {
            var _this2 = this;

            var sCandidateId = this.get("_sCandidateId");

            this.get("_objCandidateAdvancedSearchRestService").searchCandidate(this, "_objCandidateSearchPostPromise", sCandidateId, function (arrCandidateData) {
                var objData = arrCandidateData[0];
                _this2.set("_objLastCandidateDetails", objData);
                _this2._setTitleBar();

                var arrExternalGeneral = [];
                var sDbsStatus = "";
                if (!a24Core.isEmpty(objData.dbs_status)) {
                    sDbsStatus = _this2.get("_objCandidateProfileEnumsService").getFrontendValue("dbsStatuses", objData.dbs_status);
                }

                var sDbsWorkforce = "";
                if (!a24Core.isEmpty(objData.external_workforce)) {
                    sDbsWorkforce = _this2.get("_objCandidateProfileEnumsService").getFrontendValue("dbsWorforce", objData.external_workforce);
                }

                var sLastContacted = "";
                if (!a24Core.isEmpty(objData.last_contacted_at)) {
                    var objLastContactedyDateTime = a24DateManager.createDate(_this2.get("_objUserSession").get("objContextDetails.sTimezone"));
                    objLastContactedyDateTime.setDateFromRest(objData.last_contacted_at);
                    sLastContacted = objLastContactedyDateTime.getDateFormat();
                }

                arrExternalGeneral = [];

                if (_this2.get("_objUserSession.objContextDetails.sCountryId") === "826") {
                    arrExternalGeneral.push({
                        sLabel: _this2.get("_objCandidateProfileStringsService").getString("dbsStatus"),
                        sValue: sDbsStatus,
                        bText: true
                    });
                }
                arrExternalGeneral.push({
                    sLabel: _this2.get("_objCandidateProfileStringsService").getString("lastContacted"),
                    sValue: sLastContacted,
                    bText: true
                });
                if (_this2.get("_objUserSession.objContextDetails.sCountryId") === "826") {
                    arrExternalGeneral.push({
                        sLabel: _this2.get("_objCandidateProfileStringsService").getString("externalWorkforce"),
                        sValue: sDbsWorkforce,
                        bText: true
                    });
                }

                _this2.set("_arrExternalData", arrExternalGeneral);

                var arrExternalDocuments = [];
                if (!a24Core.isEmpty(objData.external_documents)) {
                    arrExternalDocuments = objData.external_documents;
                }

                _this2.set("_arrExternalDocumentsData", arrExternalDocuments);

                var arrExternalVerifications = [];
                if (!a24Core.isEmpty(objData.external_verifications)) {
                    arrExternalVerifications = objData.external_verifications;
                }

                _this2.set("_arrExternalVerificationsData", arrExternalVerifications);

                var arrExternalDaysToExpiry = [];
                if (!a24Core.isEmpty(objData.external_days_to_expiry)) {
                    arrExternalDaysToExpiry = objData.external_days_to_expiry;
                }

                _this2.set("_arrExternalDaysToExpiryData", arrExternalDaysToExpiry);

                _this2.set("_bLoading", false);
            }, // handled in rsvp
            a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this2.set("_bDisplayReloadCard", true);
                    return;
                }

                // Clear data
                _this2.set("_arrExternalData", []);
                _this2.set("_arrExternalDocumentsData", []);
                _this2.set("_arrExternalVerificationsData", []);
                _this2.set("_bLoading", false);
            }));
        }
    });
});