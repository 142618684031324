define("a24-ember-candidate-profile/services/rest-agency-group-candidate-report-interview-profile", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objNavigation: Ember.inject.service("navigation"),
        _objUserSession: Ember.inject.service("user-session"),
        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * Retrieves the agency group candidate interview document details
         *
         * @param objContext - The caller of this function
         * @param sProperty - The Property name to get the promise object
         * @param sAgencyGroupId - The agency group
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         * 
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  25 March 2019
         */
        getCandidateReportInterviewProfile: function getCandidateReportInterviewProfile(objContext, sProperty, sCandidateId, funcSuccess, objFailure) {

            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency-group/" + sAgencyGroupId + "/candidate/" + sCandidateId + "/report/interview-profile");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});