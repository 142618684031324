define("a24-ember-staffshift-core/services/rest-firebase-custom-token", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        _ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),
        _sRestApiBaseUrl: Ember.computed.readOnly("_ENV.sRestApiBaseUrl"),

        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        /**
         * This service method will get the custom firebase auth token for the user
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object with error codes and callback to handle errors
         *
         * @author Ruan Naude <ruan.naude@a24group.com>
         * @since  08 July 2019
         */
        getFirebaseCustomToken: function getFirebaseCustomToken(objCaller, sProperty, funcSuccess, objFailure) {
            var sUrl = this.get("_sRestApiBaseUrl") + "/v1/firebase-custom-token";

            a24RestUrlConstruct.setBaseUrl(sUrl);

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "POST", a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }

    });
});