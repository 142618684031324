define("a24-ember-candidate-profile/services/card-datagrid-custom-filter-candidate-distance", ["exports", "a24-ember-lib/services/card-datagrid-filter-custom-base"], function (exports, _cardDatagridFilterCustomBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _cardDatagridFilterCustomBase.default.extend({
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileConstants: Ember.inject.service("a24-constants-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),

        funcFilterCustomPrimaryQuery: function funcFilterCustomPrimaryQuery(objHeaderItem /* , objHeaderSecondaries */) {
            var iMax = null;

            if (!objHeaderItem._bEmptyEntriesOnly) {
                if (objHeaderItem._objDistanceUnit.sValue === this.get("_objCandidateProfileStringsService").getString("km").toLowerCase()) {
                    iMax = objHeaderItem._iWithinDistance * this.get("_objCandidateProfileConstants").CONVERT_KILOMETERS_TO_METERS;
                }

                if (objHeaderItem._objDistanceUnit.sValue === this.get("_objCandidateProfileEnumsService.distanceUnitOptions.MILES.backend")) {
                    iMax = objHeaderItem._iWithinDistance * this.get("_objCandidateProfileConstants").CONVERT_MILES_TO_METERS;
                }

                var sLocName = "";
                var sLat = "";
                var sLong = "";
                var sSite = "";
                var sOrg = "";
                var sCan = "";

                if (!a24Core.isEmpty(objHeaderItem._objLocationOption) && objHeaderItem._objLocationOption.sValue === "custom_location" && !a24Core.isEmpty(objHeaderItem._objAddressSuggest) && !a24Core.isEmpty(objHeaderItem._objAddressSuggest.sLatitude) && !a24Core.isEmpty(objHeaderItem._objAddressSuggest.sLongitude) && objHeaderItem._objAddressSuggest.sLatitude === objHeaderItem._sLatitude && objHeaderItem._objAddressSuggest.sLongitude === objHeaderItem._sLongitude) {
                    sLocName = objHeaderItem._objAddressSuggest.sAddressString;
                } else if (a24Core.isEmpty(objHeaderItem._objLocationOption) && !a24Core.isEmpty(objHeaderItem._objLinkedClient) && !a24Core.isEmpty(objHeaderItem._objLinkedClient.objSiteGeoLocation) && objHeaderItem._objLinkedClient.objSiteGeoLocation.fLatitude === objHeaderItem._sLatitude && objHeaderItem._objLinkedClient.objSiteGeoLocation.fLongitude === objHeaderItem._sLongitude) {
                    sOrg = objHeaderItem._objLinkedClient.sOrganisationName;
                    sSite = objHeaderItem._objLinkedClient.sSiteName;
                } else if (a24Core.isEmpty(objHeaderItem._objLocationOption) && !a24Core.isEmpty(objHeaderItem._objSelectedCandidate) && !a24Core.isEmpty(objHeaderItem._objSelectedCandidate.objAddressGeoLocation) && objHeaderItem._objSelectedCandidate.objAddressGeoLocation.fLatitude === objHeaderItem._sLatitude && objHeaderItem._objSelectedCandidate.objAddressGeoLocation.fLongitude === objHeaderItem._sLongitude) {
                    sCan = objHeaderItem._objSelectedCandidate.sDisplayName;
                }
                sLat = objHeaderItem._sLatitude;
                sLong = objHeaderItem._sLongitude;

                var objQuery = {
                    bWithin: true,
                    sUnit: objHeaderItem._objDistanceUnit.sValue,
                    sLocNme: sLocName,
                    fLong: sLong,
                    fLat: sLat,
                    iMax: Math.round(iMax),
                    sSite: sSite,
                    sOrg: sOrg,
                    sCan: sCan
                };

                return objQuery;
            }

            return { mValue: "null" };
        },

        funcFilterCustomTag: function funcFilterCustomTag(objHeaderItem, objQuery, objHeadersSecondaries, bSkipTitle) {
            var sStrDistanceTagKey = "distanceTag";
            var sStrDistanceCoordsTagKey = "distanceTagCoords";

            if (objQuery.mValue === "null") {
                return {
                    sTag: this.get("_objCandidateProfileStringsService").getString("distanceIsEmpty")
                };
            }

            if (bSkipTitle) {
                sStrDistanceTagKey = "distanceTagNoTitle";
                sStrDistanceCoordsTagKey = "distanceTagCoordsNoTitle";
            }

            var sDistance = "";

            if (objQuery.sUnit === this.get("_objCandidateProfileStringsService").getString("km").toLowerCase()) {
                var iKMValue = objQuery.iMax / this.get("_objCandidateProfileConstants").CONVERT_KILOMETERS_TO_METERS;
                sDistance = Math.round(iKMValue) + this.get("_objCandidateProfileStringsService").getString("km");
            }

            if (objQuery.sUnit === this.get("_objCandidateProfileEnumsService.distanceUnitOptions.MILES.backend")) {
                var iMilesValue = objQuery.iMax / this.get("_objCandidateProfileConstants").CONVERT_MILES_TO_METERS;
                sDistance = Math.round(iMilesValue) + this.get("_objCandidateProfileEnumsService.distanceUnitOptions.MILES.backend");
            }

            if (a24Core.isEmpty(objQuery.sLocNme)) {
                if (!a24Core.isEmpty(objQuery.sSite) && !a24Core.isEmpty(objQuery.sOrg)) {
                    return {
                        sTag: this.get("_objCandidateProfileStringsService").getTokenString(sStrDistanceTagKey, sDistance, objQuery.sOrg + ": " + objQuery.sSite)
                    };
                }
                if (!a24Core.isEmpty(objQuery.sCan)) {
                    return {
                        sTag: this.get("_objCandidateProfileStringsService").getTokenString(sStrDistanceTagKey, sDistance, objQuery.sCan)
                    };
                }
                return {
                    sTag: this.get("_objCandidateProfileStringsService").getTokenString(sStrDistanceCoordsTagKey, sDistance, objQuery.fLat + ", " + objQuery.fLong)
                };
            } else {
                return {
                    sTag: this.get("_objCandidateProfileStringsService").getTokenString(sStrDistanceTagKey, sDistance, objQuery.sLocNme)
                };
            }
        },

        funcFilterCustomPrimaryReset: function funcFilterCustomPrimaryReset(objHeaderItem) {
            //Reset the state of the custom filter input
            Ember.set(objHeaderItem, "_objDistanceUnit", null);
            Ember.set(objHeaderItem, "_objLinkedClient", null);
            Ember.set(objHeaderItem, "_objSelectedCandidate", null);
            Ember.set(objHeaderItem, "_iWithinDistance", null);
            Ember.set(objHeaderItem, "_objAddressSuggest", null);
            Ember.set(objHeaderItem, "_sLatitude", null);
            Ember.set(objHeaderItem, "_sLongitude", null);
            Ember.set(objHeaderItem, "_objLocationOption", null);
            Ember.set(objHeaderItem, "_bEmptyEntriesOnly", false);
        },

        funcFilterCustomFixupQuery: function funcFilterCustomFixupQuery() /*objHeader, objHeaderSecondaries */{},

        funcFilterCustomSetEditState: function funcFilterCustomSetEditState(objHeaderItem, iIndex) {
            var objHeaderTag = objHeaderItem.mFilterValue[iIndex];

            if (objHeaderTag.mValue === "null") {
                Ember.set(objHeaderItem, "_bEmptyEntriesOnly", true);
            } else {
                if (objHeaderTag.sUnit === this.get("_objCandidateProfileStringsService").getString("km").toLowerCase()) {
                    Ember.set(objHeaderItem, "_objDistanceUnit", {
                        sValue: objHeaderTag.sUnit
                    });
                    var iKMValue = objHeaderTag.iMax / this.get("_objCandidateProfileConstants").CONVERT_KILOMETERS_TO_METERS;
                    Ember.set(objHeaderItem, "_iWithinDistance", Math.round(iKMValue));
                } else if (objHeaderTag.sUnit === this.get("_objCandidateProfileEnumsService.distanceUnitOptions.MILES.backend")) {
                    Ember.set(objHeaderItem, "_objDistanceUnit", {
                        sValue: objHeaderTag.sUnit
                    });

                    var iMilesValue = objHeaderTag.iMax / this.get("_objCandidateProfileConstants").CONVERT_MILES_TO_METERS;
                    Ember.set(objHeaderItem, "_iWithinDistance", Math.round(iMilesValue));
                }

                if (!a24Core.isEmpty(objHeaderTag.sSite) && !a24Core.isEmpty(objHeaderTag.sOrg)) {
                    Ember.set(objHeaderItem, "_objLocationOption", null);

                    Ember.set(objHeaderItem, "_objLinkedClient", {
                        sSiteName: objHeaderTag.sSite,
                        sOrganisationName: objHeaderTag.sOrg,
                        sDisplayName: objHeaderTag.sOrg + ": " + objHeaderTag.sSite,
                        objSiteGeoLocation: {
                            fLatitude: objHeaderTag.fLat,
                            fLongitude: objHeaderTag.fLong
                        }
                    });
                } else if (!a24Core.isEmpty(objHeaderTag.sCan)) {
                    Ember.set(objHeaderItem, "_objLocationOption", null);

                    Ember.set(objHeaderItem, "_objSelectedCandidate", {
                        sDisplayName: objHeaderTag.sCan,
                        objAddressGeoLocation: {
                            fLatitude: objHeaderTag.fLat,
                            fLongitude: objHeaderTag.fLong
                        }
                    });
                } else {
                    Ember.set(objHeaderItem, "_objLocationOption", {
                        sValue: "custom_location"
                    });

                    Ember.set(objHeaderItem, "_objAddressSuggest", {
                        sLatitude: objHeaderTag.fLat,
                        sLongitude: objHeaderTag.fLong,
                        sAddressString: objHeaderTag.sLocNme
                    });
                }

                Ember.set(objHeaderItem, "_sLatitude", objHeaderTag.fLat);
                Ember.set(objHeaderItem, "_sLongitude", objHeaderTag.fLong);
            }
        }
    });
});