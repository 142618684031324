define("a24-ember-candidate-profile/components/add-edit-work-history", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/add-edit-work-history", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _addEditWorkHistory, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _addEditWorkHistory.default,
        objData: null,
        sCandidateId: null,
        objCandidateEmploymentRestService: null,
        objSettingsRestService: null,
        bShowAddEditPopup: false,
        classNames: ["add-edit-work-history"],

        _arrExcludeJobTitles: null,

        _sHeaderPopup: null,
        _objDataCopy: null,
        _bIsAddState: false,
        _bShowUnsavedData: false,
        _bPopupLoading: true,
        _bShowAddEditPopup: false,
        _objSpecialPopupAction: null,
        _objOriginalData: null,
        _bInputsReady: false,
        _bIsUkSaCountry: false,
        _objRestCreateEmploymentProperty: null,
        _objRestUpdateEmploymentProperty: null,

        _arrCountries: null,
        _arrWorkTypes: null,
        _bIsSelfEmployed: false,
        _objEmployerWorkType: null,
        _sEmployerName: null,
        _sEmployerContactNumber: null,
        _sEmployerLocationAddressLine1: null,
        _sEmployerLocationAddressLine2: null,
        _sEmployerLocationPostCode: null,
        _sEmployerLocationCity: null,
        _sEmployerLocationProvince: null,
        _objEmployerLocationProvince: null,
        _sEmployerLocationCountry: null,
        _bAddEmployerStepCompleted: false,
        _bAddEmployerStepSelected: true,
        _bAddEmployerStepEnabled: true,
        _objAddEmployerOriginalData: null,
        _objProvidePositionOriginalData: null,

        _bIsOtherPositionType: false,
        _objRestGetPositionTypesProperty: null,
        _objRestGetJobFamiliesProperty: null,
        _objRestGetJobTitlesProperty: null,
        _sProvidePosition: null,
        _arrPositionTypes: null,
        _objProvidePositionPositionType: null,
        _arrJobFamilies: null,
        _objProvidePositionJobFamily: null,
        _bShowProvidePositionJobFamily: false,
        _bProvidePositionPositionTypesLoading: false,
        _bProvidePositionJobFamiliesLoading: false,
        _arrJobTitles: null,
        _objProvidePositionJobTitle: null,
        _bShowProvidePositionJobTitle: false,
        _bProvidePositionJobTitlesLoading: false,
        _bProvidePositionStepCompleted: false,
        _bProvidePositionStepSelected: false,
        _bProvidePositionStepEnabled: false,

        _bLeavingReasonRequired: false,
        _objAddDurationOriginalData: null,
        _bAddDurationIsCurrent: false,
        _bAddDurationStepCompleted: false,
        _bAddDurationStepSelected: false,
        _bAddDurationStepEnabled: false,
        _objEndDateMaxDate: null,
        _objStartDateMaxDate: null,
        _sSelectedCountryCode: "",

        _bStep2InitDone: true,

        _funcGetCountriesSuggestions: null,
        _funcGetCountiesSuggestions: null,

        _objSystemRestService: Ember.inject.service("rest-system"),
        _objSSCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objEnumsService: Ember.inject.service("a24-enums"),
        _objUserSession: Ember.inject.service("user-session"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _bIsUnemployed: false,
        _bStudent: false,
        _sMainResponsibilitiesReason: null,
        _bLeavingReasonVisible: Ember.computed("_bIsUnemployed", "_bLeavingReasonRequired", "_bStudent", function () {
            return !this.get("_bIsUnemployed") && !this.get("_bStudent") && this.get("_bLeavingReasonRequired");
        }),
        _bEmploymentAddressVisible: Ember.computed("_bIsUnemployed", "_bStudent", function () {
            return !this.get("_bIsUnemployed") && !this.get("_bStudent");
        }),
        _bProvidePositionStepLoading: Ember.computed("_bStep2InitDone", "_bProvidePositionPositionTypesLoading", "_bProvidePositionJobFamiliesLoading", "_bProvidePositionJobTitlesLoading", function () {
            if (!this.get("_bStep2InitDone") || this.get("_bProvidePositionPositionTypesLoading") || this.get("_bProvidePositionJobFamiliesLoading") || this.get("_bProvidePositionJobTitlesLoading")) {
                Ember.run.once(this, "_setSpecialPopupAction", true);
                return true;
            }
            Ember.run.once(this, "_setSpecialPopupAction", false);
            return false;
        }),

        _setSpecialPopupAction: function _setSpecialPopupAction(bLoading) {
            var _this = this;

            var objSpecialAction = null;
            if (!bLoading) {
                objSpecialAction = {
                    sTitle: this.get("_objStringsService").getString("close"),
                    sIcon: "close",
                    executeCardAction: function executeCardAction() {
                        _this._popupAddEditCancel();
                    }
                };
            }
            this.set("_objSpecialPopupAction", objSpecialAction);
        },

        init: function init() {
            this._super.apply(this, arguments);

            this.set("_arrExcludeJobTitles", ['on call', 'on-call', 'sleep-in', 'sleep in']);

            var sCountryId = this.get("_objUserSession.objContextDetails.sCountryId");
            var sCountryCode = sCountryId === "710" ? "ZA" : sCountryId === "840" ? "US" : "GB";
            // default country code is that of the context details
            this.set("_sSelectedCountryCode", sCountryCode);

            Ember.run.once(this, "_setSpecialPopupAction", false);

            this.set("_arrPositionTypes", []);
            this.set("_arrJobFamilies", []);
            this.set("_arrJobTitles", []);
            this.set("_arrWorkTypes", this.get("_objCandidateProfileEnumsService").getList("workTypesPost"));

            this._setCountriesDropdown();
            this._setupValidations();

            var objStartDateMaxDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            this.set("_objStartDateMaxDate", objStartDateMaxDate);

            var objEndDateMaxDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            this.set("_objEndDateMaxDate", objEndDateMaxDate);
            this.set("_bIsUnemployed", false);
            this.set("_sMainResponsibilitiesReason", this.get("_objCandidateProfileStringsService").getString("mainResponsibilities"));
        },

        _resetAllFields: function _resetAllFields() {
            this.set("_arrPositionTypes", []);
            this.set("_arrJobFamilies", []);
            this.set("_arrJobTitles", []);
            this.set("_bIsUkSaCountry", false);
            this.set("_bIsSelfEmployed", false);
            this.set("_bIsUnemployed", false);
            this.set("_bStudent", false);

            this.set("_bIsOtherPositionType", false);
            this.set("_bShowProvidePositionJobFamily", false);
            this.set("_bShowProvidePositionJobTitle", false);
            this.set("_bAddDurationIsCurrent", false);
            this.set("_bLeavingReasonRequired", false);
            this.set("_sMainResponsibilitiesReason", this.get("_objCandidateProfileStringsService").getString("mainResponsibilities"));

            this.clearForm();
        },

        onInputValueChanged: function onInputValueChanged(arrChildRef) {
            this._super.apply(this, arguments);

            // Get the reference of the field that changed
            var sLookup = arrChildRef.join(".");

            if (sLookup === "objChildren.work_type") {
                this.set("_bIsSelfEmployed", false);
                this.set("_bIsUnemployed", false);
                this.set("_bStudent", false);

                if (this.get(sLookup + ".mValue.sValue") === this.get("_objCandidateProfileEnumsService.workTypesPost.SELF_EMPLOYED.backend")) {
                    this.set("_bIsSelfEmployed", true);
                }
                if (this.get(sLookup + ".mValue.sValue") === this.get("_objCandidateProfileEnumsService.workTypesPost.UNEMPLOYED.backend")) {
                    this.set("_bIsUnemployed", true);
                    this.set("_bProvidePositionStepEnabled", false);
                    if (!this.get("_bIsAddState")) {
                        this.set("_bAddDurationStepCompleted", true);
                    }
                } else if (this.get(sLookup + ".mValue.sValue") === this.get("_objCandidateProfileEnumsService.workTypesPost.STUDENT_FULL_TIME.backend")) {
                    this.set("_bStudent", true);
                    this.set("_bProvidePositionStepEnabled", false);
                    if (!this.get("_bIsAddState")) {
                        this.set("_bAddDurationStepCompleted", true);
                    }
                } else {
                    if (!this.get("_bIsAddState")) {
                        this.set("_bProvidePositionStepEnabled", true);
                        if (a24Core.isEmpty(this.get("objData.position_type")) && a24Core.isEmpty(this.get("_objProvidePositionOriginalData.position_type"))) {
                            // set postion step to incomplete if editing and position not set
                            this.set("_bProvidePositionStepCompleted", false);
                        }

                        if (a24Core.isEmpty(this.get("objData.leaving_reason")) && !this.get("objData.current") && a24Core.isEmpty(this.get("objChildren.leaving_reason.mValue")) && !this.get("objChildren.current.mValue.bValue")) {
                            this.set("_bAddDurationStepCompleted", false);
                        }
                    } else {
                        if (!a24Core.isEmpty(this.get("objChildren.position_type.mValue.code"))) {
                            // set postion step to complete and enabled if adding and position set
                            this.set("_bProvidePositionStepEnabled", true);
                            this.set("_bProvidePositionStepCompleted", true);
                        }
                        if (a24Core.isEmpty(this.get("objChildren.leaving_reason.mValue")) && !this.get("objChildren.current.mValue.bValue")) {
                            this.set("_bAddDurationStepCompleted", false);
                        }
                    }
                }
            } else if (sLookup === "objChildren.employer_location.country_code") {
                var objCountryCode = this.get("objChildren")["employer_location.country_code"];

                if (!a24Core.isEmpty(objCountryCode) && !a24Core.isEmpty(objCountryCode.mValue)) {
                    var sCountryCode = objCountryCode.mValue.sValue;
                    this.set("_sSelectedCountryCode", sCountryCode);

                    if (sCountryCode !== this.get("_objDataCopy.employer_location.country_code")) {
                        this.set("_arrPositionTypes", []);
                        this.set("_arrJobFamilies", []);
                        this.set("_arrJobTitles", []);
                        this.set("_objProvidePositionOriginalData", {
                            position: null,
                            position_type: null,
                            job_family_id: null,
                            job_title_id: null
                        });

                        this.set("_bProvidePositionStepEnabled", false);
                        this.set("_bProvidePositionStepCompleted", false);
                        // if changing country or from unemployed to employed
                        this.set("_bAddDurationStepEnabled", false);
                    }

                    this._setCountiesFromCountryCode(sCountryCode);

                    this.get("objChildren")["employer_location.post_code"].get("arrValidation").clear();
                    this.get("objChildren")["employer_location.post_code"].set("funcValidationRequired", null);

                    if (sCountryCode === "UK" || sCountryCode === "GB" || sCountryCode === "US") {
                        a24Validation.addPostCode(this.get("objChildren")["employer_location.post_code"], sCountryCode);
                    } else {
                        a24Validation.addMinLength(this.get("objChildren")["employer_location.post_code"], 1);
                        a24Validation.addMaxLength(this.get("objChildren")["employer_location.post_code"], 100);
                    }

                    this._formatPostCode();
                }
            } else if (sLookup === "objChildren.position_type" && this.get("_bStep2InitDone")) {
                if (this.get(sLookup + ".mValue.code") === "other") {
                    this.set("_bShowProvidePositionJobFamily", false);
                    this.set("_bShowProvidePositionJobTitle", false);
                    this.set("_bIsOtherPositionType", true);
                } else if (!a24Core.isEmpty(this.get(sLookup + ".mValue.code"))) {
                    this.set("_bIsOtherPositionType", false);
                    this.set("_bShowProvidePositionJobFamily", true);
                    this.set("_bShowProvidePositionJobTitle", false);
                    this._getJobFamilies(this.get(sLookup + ".mValue.code"));
                } else {
                    this.set("_bIsOtherPositionType", false);
                    this.set("_bShowProvidePositionJobFamily", false);
                    this.set("_bShowProvidePositionJobTitle", false);
                }
            } else if (sLookup === "objChildren.job_family_id" && this.get("_bStep2InitDone")) {
                if (!a24Core.isEmpty(this.get(sLookup + ".mValue._id"))) {
                    this.set("_bShowProvidePositionJobTitle", true);
                    this._getJobTitles(this.get("objChildren.position_type.mValue.code"), this.get(sLookup + ".mValue._id"));
                } else {
                    this.set("_bShowProvidePositionJobTitle", false);
                }
            } else if (sLookup === "objChildren.current") {
                this.set("_bAddDurationIsCurrent", this.get(sLookup + ".mValue.bValue") === true);
                this.set("_bLeavingReasonRequired", !this.get("_bAddDurationIsCurrent"));
            } else if (sLookup === "objChildren.employer_location.post_code") {
                this._formatPostCode();
            }

            if (this.get("_bIsUnemployed")) {
                this.set("_sMainResponsibilitiesReason", this.get("_objCandidateProfileStringsService").getString("unemploymentReason"));
            } else {
                this.set("_sMainResponsibilitiesReason", this.get("_objCandidateProfileStringsService").getString("mainResponsibilities"));
            }
        },

        _formatPostCode: function _formatPostCode() {
            var objCountryCode = this.get("objChildren")["employer_location.country_code"];
            var objPostCode = this.get("objChildren")["employer_location.post_code"];

            if (!a24Core.isEmpty(objCountryCode) && !a24Core.isEmpty(objCountryCode.mValue) && (objCountryCode.mValue.sValue === "UK" || objCountryCode.mValue.sValue === "GB")) {
                if (!a24Core.isEmpty(objPostCode.mValue)) {
                    var sInputValue = objPostCode.mValue.trim();
                    if (!/\s/.test(sInputValue)) {
                        var sValue1 = sInputValue.slice(0, sInputValue.length - 3);
                        var sValue2 = sInputValue.slice(sInputValue.length - 3, sInputValue.length);
                        objPostCode.setValueFromOutside(sValue1.toUpperCase() + " " + sValue2.toUpperCase());
                    } else if (sInputValue.split(" ").length > 1) {
                        objPostCode.setValueFromOutside(sInputValue.replace(/  +/g, " ").toUpperCase());
                    }
                }
            }
        },

        _getJobFamilies: function _getJobFamilies(sPositionType) {
            var _this2 = this;

            this.set("_bProvidePositionJobFamiliesLoading", true);

            this.get("_objSystemRestService").getJobFamilies(this, "_objRestGetJobFamiliesProperty", sPositionType, function (arrData) {
                if (a24Core.isEmpty(arrData)) {
                    _this2.set("_arrJobFamilies", []);
                } else {
                    _this2.set("_arrJobFamilies", arrData);
                }

                _this2.set("_bProvidePositionJobFamiliesLoading", false);
            });
        },

        _getJobTitles: function _getJobTitles(sPositionType, sJobFamilyId) {
            var _this3 = this;

            this.set("_bProvidePositionJobTitlesLoading", true);

            this.get("_objSystemRestService").getJobTitles(this, "_objRestGetJobTitlesProperty", sPositionType, sJobFamilyId, function (arrData) {
                if (a24Core.isEmpty(arrData)) {
                    _this3.set("_arrJobTitles", []);
                } else {
                    var arrLimitedData = arrData.filter(function (objValue) {
                        return !_this3.get("_arrExcludeJobTitles").find(function (sExclude) {
                            return objValue.name.toLowerCase().includes(sExclude);
                        });
                    });
                    _this3.set("_arrJobTitles", arrLimitedData);
                }
                _this3.set("_bProvidePositionJobTitlesLoading", false);
            });
        },

        _setupValidations: function _setupValidations() {
            var _this4 = this;

            var objValidationConfig = {};

            objValidationConfig["objChildren.work_type"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.employer_name"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
                a24Validation.addMinLength(objMixin, 1);
            };
            objValidationConfig["objChildren.employer_contact_number"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.employer_location.address_line1"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 1);
                a24Validation.addMaxLength(objMixin, 100);
            };
            objValidationConfig["objChildren.employer_location.address_line2"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 1);
                a24Validation.addMaxLength(objMixin, 100);
            };
            objValidationConfig["objChildren.employer_location.post_code"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 1);
                a24Validation.addMaxLength(objMixin, 100);
            };
            objValidationConfig["objChildren.employer_location.city"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 1);
                a24Validation.addMaxLength(objMixin, 100);
            };
            objValidationConfig["objChildren.province_other"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 2);
                a24Validation.addMaxLength(objMixin, 100);
            };
            objValidationConfig["objChildren.employer_location.country_code"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };

            objValidationConfig["objChildren.position_type"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.position"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.job_family_id"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.job_title_id"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.institute_name"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };

            objValidationConfig["objChildren.start_date"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
                objMixin.get("arrValidation").pushObject(function () {
                    if (!_this4.get("objChildren.current.mValue.bValue") && !a24Core.isEmpty(_this4.get("objChildren.end_date")) && !a24Core.isEmpty(_this4.get("objChildren.end_date.mValue"))) {
                        var objStartDate = a24DateManager.createDate(_this4.get("_objUserSession.objContextDetails.sTimezone"));
                        var objEndDate = a24DateManager.createDate(_this4.get("_objUserSession.objContextDetails.sTimezone"));
                        objStartDate.setDateFromBrowser(objMixin.get("mValue"), _this4.get("_objLibConstants.DATE_FORMAT"));
                        objEndDate.setDateFromBrowser(_this4.get("objChildren.end_date.mValue"), _this4.get("_objLibConstants.DATE_FORMAT"));
                        if (objStartDate.isAfter(objEndDate)) {
                            return _this4.get("_objCandidateProfileStringsService").getTokenString("validationStartDateLessEndDate", objMixin.get("sFieldName"));
                        }
                    }
                });
            };
            objValidationConfig["objChildren.end_date"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.responsibilities"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.leaving_reason"] = function (objMixin) {
                objMixin.get("arrValidation").pushObject(function () {
                    if (!_this4.get("objChildren.current.mValue.bValue")) {
                        a24Validation.addRequired(objMixin, true);
                    } else {
                        a24Validation.addRequired(objMixin, false);
                    }
                });
            };

            this.set("objChildConfig", objValidationConfig);
        },

        _setCountyDropdown: function _setCountyDropdown(sCountryCode) {
            var arrCounties = null;
            if (sCountryCode === "ZA") {
                arrCounties = this.get("_objCandidateProfileEnumsService").getList("saProvinces");
            } else if (sCountryCode === "UK" || sCountryCode === "GB") {
                arrCounties = this.get("_objCandidateProfileEnumsService").getList("ukProvinces");
            } else if (sCountryCode === "US") {
                arrCounties = this.get("_objCandidateProfileEnumsService").getList("usProvinces");
            }

            this.set("_funcGetCountiesSuggestions", function (sQueryString, funcOnComplete) {
                var arrFilteredCounties = [];

                if (!a24Core.isEmpty(sQueryString)) {
                    for (var i = 0; i < arrCounties.length; i++) {
                        if (arrCounties[i].sLabel.toLowerCase().indexOf(sQueryString.toLowerCase()) !== -1) {
                            arrFilteredCounties.push(arrCounties[i]);
                        }
                    }
                } else {
                    arrFilteredCounties = arrCounties;
                }

                funcOnComplete(arrFilteredCounties);
            });
            this.set("_arrCounties", arrCounties);
        },

        _setCountriesDropdown: function _setCountriesDropdown() {
            var _this5 = this;

            this.set("_arrCountries", this.get("_objEnumsService").getList("country"));
            this.set("_funcGetCountriesSuggestions", function (sQueryString, funcOnComplete) {
                var arrCountries = _this5.get("_arrCountries");
                var arrFilteredCountries = [];

                if (!a24Core.isEmpty(sQueryString)) {
                    for (var i = 0; i < arrCountries.length; i++) {
                        if (arrCountries[i].sLabel.toLowerCase().indexOf(sQueryString.toLowerCase()) !== -1) {
                            arrFilteredCountries.push(arrCountries[i]);
                        }
                    }
                } else {
                    arrFilteredCountries = arrCountries;
                }

                funcOnComplete(arrFilteredCountries);
            });
        },

        _setAddEditPopup: Ember.observer("bShowAddEditPopup", function () {
            var _this6 = this;

            if (this.get("bShowAddEditPopup")) {
                this.clearAllErrorMessages();
                this.clearForm();

                if (!a24Core.isEmpty(this.get("objData"))) {
                    this.set("_objDataCopy", $.extend(true, {}, this.get("objData")));
                    this.set("_bIsAddState", false);

                    this.set("_bAddEmployerStepSelected", true);
                    this.set("_bAddEmployerStepEnabled", true);
                    this.set("_bAddEmployerStepCompleted", true);

                    this.set("_bProvidePositionStepSelected", false);

                    if (this.get("objData.work_type") === this.get("_objCandidateProfileEnumsService.workTypesPost.UNEMPLOYED.backend") || this.get("objData.work_type") === this.get("_objCandidateProfileEnumsService.workTypesPost.STUDENT_FULL_TIME.backend")) {
                        this.set("_bProvidePositionStepEnabled", false);
                    } else {
                        this.set("_bProvidePositionStepEnabled", true);
                    }
                    if (!a24Core.isEmpty(this.get("objData.position_type"))) {
                        this.set("_bProvidePositionStepCompleted", true);
                    } else {
                        this.set("_bProvidePositionStepCompleted", false);
                    }

                    this.set("_bAddDurationStepSelected", false);
                    this.set("_bAddDurationStepEnabled", true);
                    this.set("_bAddDurationStepCompleted", true);

                    this.set("_sHeaderPopup", this.get("_objStringsService").getTokenString("editItem", this.get("_objCandidateProfileStringsService").getString("workHistory")));

                    var objAddEmployerOriginalData = a24Core.removeNullsFromValue(Ember, Ember.getProperties(this.get("objData"), "work_type", "employer_name", "employer_contact_number", "institute_name"));

                    if (!a24Core.isEmpty(this.get("objData.employer_location"))) {
                        objAddEmployerOriginalData.employer_location = a24Core.removeNullsFromValue(Ember, Ember.getProperties(this.get("objData.employer_location"), "address_line1", "address_line2", "post_code", "city", "province", "country_code"));
                    } else {
                        if (objAddEmployerOriginalData.work_type === this.get("_objCandidateProfileEnumsService.workTypesPost.UNEMPLOYED.backend") || objAddEmployerOriginalData.work_type === this.get("_objCandidateProfileEnumsService.workTypesPost.STUDENT_FULL_TIME.backend")) {
                            delete objAddEmployerOriginalData.employer_location;
                        } else {
                            objAddEmployerOriginalData.employer_location = {};
                        }
                    }

                    var objProvidePositionOriginalData = a24Core.removeNullsFromValue(Ember, Ember.getProperties(this.get("objData"), "position", "position_type", "job_family_id", "job_title_id"));

                    var objAddDurationOriginalData = a24Core.removeNullsFromValue(Ember, Ember.getProperties(this.get("objData"), "start_date", "end_date", "current", "responsibilities", "leaving_reason"));

                    if (!a24Core.isEmpty(objAddDurationOriginalData.start_date)) {
                        var objStartDate = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                        objStartDate.setDateFromRest(objAddDurationOriginalData.start_date, true);

                        objStartDate.setTimeToStartOfDay();

                        objAddDurationOriginalData.start_date = objStartDate.getDateStringForRest();
                    }

                    if (!a24Core.isEmpty(objAddDurationOriginalData.end_date)) {
                        var objEndDate = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                        objEndDate.setDateFromRest(objAddDurationOriginalData.end_date, true);

                        objEndDate.setTimeToEndOfDay();

                        objAddDurationOriginalData.end_date = objEndDate.getDateStringForRest();
                    }

                    this.set("_objAddEmployerOriginalData", objAddEmployerOriginalData);
                    this.set("_objProvidePositionOriginalData", objProvidePositionOriginalData);
                    this.set("_objAddDurationOriginalData", objAddDurationOriginalData);
                } else {
                    var sCountryCode = null;
                    if (this.get("_objUserSession").getUserContextReference().sResource === this.get("_objSSCoreConstants.CONTEXT_AGENCY_GROUP")) {
                        sCountryCode = this.get("_objUserSession.objContextDetails.sCountryId") === "710" ? "ZA" : "GB";
                    } else if (this.get("_objUserSession").getUserContextReference().sResource === this.get("_objSSCoreConstants.CONTEXT_CANDIDATE")) {
                        var arrCandidateAgencyGroups = this.get("objSettingsRestService.objSettings.agency_groups");
                        if (arrCandidateAgencyGroups.length === 1) {
                            sCountryCode = arrCandidateAgencyGroups[0].country_code;
                        }
                    }

                    var objCountry = this.get("_arrCountries").findBy("sValue", sCountryCode);

                    var objEmployerLocation = {};

                    if (!a24Core.isEmpty(objCountry)) {
                        objEmployerLocation.country_code = this.get("_sSelectedCountryCode");
                    }

                    this.set("objData", {
                        employer_location: objEmployerLocation
                    });
                    this.set("_objDataCopy", {
                        employer_location: objEmployerLocation
                    });
                    this.set("_bIsAddState", true);

                    this.set("_bAddEmployerStepSelected", true);
                    this.set("_bAddEmployerStepEnabled", true);
                    this.set("_bAddEmployerStepCompleted", false);

                    this.set("_bProvidePositionStepSelected", false);
                    this.set("_bProvidePositionStepEnabled", false);
                    this.set("_bProvidePositionStepCompleted", false);

                    this.set("_bAddDurationStepSelected", false);
                    this.set("_bAddDurationStepEnabled", false);
                    this.set("_bAddDurationStepCompleted", false);

                    this.set("_bIsOtherPositionType", false);

                    this.set("_sHeaderPopup", this.get("_objStringsService").getTokenString("addItem", this.get("_objCandidateProfileStringsService").getString("workHistory")));

                    if (!a24Core.isEmpty(objCountry)) {
                        this.get("objChildren")["employer_location.country_code"].setValueFromOutside(Ember.copy(objCountry));
                    }

                    this.set("_objAddEmployerOriginalData", {
                        employer_location: objEmployerLocation
                    });
                    this.set("_objProvidePositionOriginalData", {});
                    this.set("_objAddDurationOriginalData", {
                        current: false
                    });
                }

                this.set("_bShowAddEditPopup", true);

                if (!a24Core.isEmpty(this.get("setBreadCrumbAction"))) {
                    this.get("setBreadCrumbAction")();
                }

                Ember.run.next(function () {
                    if (a24Core.isEmpty(_this6)) {
                        return;
                    }
                    Ember.run.schedule("afterRender", function () {
                        if (!_this6.get("_bIsAddState")) {
                            _this6._populateAddEmployerInputFields(_this6.get("_objAddEmployerOriginalData"));
                        }
                    });
                });
            } else {
                this._resetAllFields();
                this.set("_bShowAddEditPopup", false);
            }
        }),

        _getPayload: function _getPayload() {
            var objPayload = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());

            if (!a24Core.isEmpty(objPayload.province_uksa)) {
                objPayload.employer_location.province = objPayload.province_uksa.sValue;
                delete objPayload.province_uksa;
            } else if (!a24Core.isEmpty(objPayload.province_other)) {
                objPayload.employer_location.province = objPayload.province_other;
                delete objPayload.province_other;
            }

            if (!a24Core.isEmpty(objPayload.employer_location) && !a24Core.isEmpty(objPayload.employer_location.country_code)) {
                objPayload.employer_location.country_code = objPayload.employer_location.country_code.sValue;
            }

            if (!a24Core.isEmpty(objPayload.start_date)) {
                var objStartDate = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                objStartDate.setDateFromBrowser(objPayload.start_date, a24Constants.DATE_FORMAT);

                objStartDate.setTimeToStartOfDay();

                objPayload.start_date = objStartDate.getDateStringForRest();
            }

            if (!a24Core.isEmpty(objPayload.end_date)) {
                var objEndDate = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                objEndDate.setDateFromBrowser(objPayload.end_date, a24Constants.DATE_FORMAT);

                objEndDate.setTimeToEndOfDay();

                objPayload.end_date = objEndDate.getDateStringForRest();
            }

            return objPayload;
        },

        _popupAddEditCancel: function _popupAddEditCancel() {
            if (!this.get("_bPopupLoading")) {
                var objPayload = a24Core.removeNullsFromValue(Ember, this._getPayload());
                var objCurrentStepOriginalData = this._getCurrentStepData();

                var bCurrentStepDataMatches = a24Core.compareObjects(Ember, objPayload, objCurrentStepOriginalData);

                var objData = this.get("objData");
                var objDataCopy = this.get("_objDataCopy");

                var bDataMatches = a24Core.compareObjects(Ember, objData, objDataCopy);

                if (bCurrentStepDataMatches && bDataMatches) {
                    if (!a24Core.isEmpty(this.get("onPopupCloseAction"))) {
                        this.get("onPopupCloseAction")();
                    }
                } else {
                    this.set("_bCloseButtonClicked", true);
                    this.set("_bShowUnsavedData", true);
                }
            }
        },

        _setCountiesFromCountryCode: function _setCountiesFromCountryCode(sCountryCode) {
            if (sCountryCode === "ZA" || sCountryCode === "UK" || sCountryCode === "GB" || sCountryCode === "US") {
                this.set("_bIsUkSaCountry", true);

                this.get("objChildren.province_uksa").clear();
                this._setCountyDropdown(sCountryCode);
            } else {
                this.set("_bIsUkSaCountry", false);
            }
        },

        _populateAddDurationInputFields: function _populateAddDurationInputFields(objData) {
            if (!a24Core.isEmpty(objData.start_date)) {
                var objStartDate = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                objStartDate.setDateFromRest(objData.start_date, true);

                this.get("objChildren.start_date").setValueFromOutside(objStartDate.getDateFormat());
            }

            if (objData.current) {
                this.set("_bAddDurationIsCurrent", true);
                this.set("_bLeavingReasonRequired", false);
            } else {
                this.set("_bAddDurationIsCurrent", false);
                this.set("_bLeavingReasonRequired", true);
                if (!a24Core.isEmpty(objData.end_date)) {
                    var objEndDate = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                    objEndDate.setDateFromRest(objData.end_date, true);

                    this.get("objChildren.end_date").setValueFromOutside(objEndDate.getDateFormat());
                }
            }

            this.get("objChildren")["responsibilities"].setValueFromOutside(objData.responsibilities);

            if (!a24Core.isEmpty(objData.leaving_reason)) {
                this.get("objChildren")["leaving_reason"].setValueFromOutside(objData.leaving_reason);
            }
        },

        _populateProvidePositionInputFields: function _populateProvidePositionInputFields(objData) {
            var _this7 = this;

            this.set("_bStep2InitDone", false);

            var objServiceCalls = {};
            var arrRequiredResponses = [];

            var sCountryCode = this.get("_objDataCopy.employer_location.country_code");

            if (this.get("_objUserSession").getUserContextReference().sResource === this.get("_objSSCoreConstants.CONTEXT_AGENCY_GROUP")) {
                sCountryCode = this.get("_objUserSession.objContextDetails.sCountryId") === "710" ? "ZA" : "GB";
            } else if (this.get("_objUserSession").getUserContextReference().sResource === this.get("_objSSCoreConstants.CONTEXT_CANDIDATE")) {
                var arrCandidateAgencyGroups = this.get("objSettingsRestService.objSettings.agency_groups");
                if (arrCandidateAgencyGroups.length === 1) {
                    sCountryCode = arrCandidateAgencyGroups[0].country_code;
                }
            }

            if (a24Core.isEmpty(this.get("_arrPositionTypes"))) {
                objServiceCalls._objRestPositionTypesProperty = this.get("_objSystemRestService").getPositionTypes(this, "_objRestGetPositionTypesProperty", function () {}, // handled in rsvp
                null, {
                    country_code: { mValue: sCountryCode }
                });
                arrRequiredResponses.push("_objRestPositionTypesProperty");
                this.set("_bProvidePositionPositionTypesLoading", true);
            } else {
                var objPositionType = this.get("_arrPositionTypes").findBy("code", objData.position_type);

                if (!a24Core.isEmpty(objPositionType)) {
                    this.set("_objProvidePositionPositionType", Ember.copy(objPositionType));
                } else {
                    this.set("_objProvidePositionPositionType", null);
                }

                this.set("_bIsOtherPositionType", objData.position_type === "other");

                Ember.run.next(function () {
                    if (a24Core.isEmpty(_this7)) {
                        return;
                    }
                    Ember.run.schedule("afterRender", function () {
                        if (!a24Core.isEmpty(_this7.get("objChildren")["position"]) && !a24Core.isEmpty(objData.position)) {
                            _this7.get("objChildren")["position"].setValueFromOutside(objData.position);
                        }
                    });
                });
            }

            if (a24Core.isEmpty(this.get("_arrJobFamilies")) && !a24Core.isEmpty(objData.position_type) && !a24Core.isEmpty(objData.job_family_id)) {
                objServiceCalls._objRestJobFamiliesProperty = this.get("_objSystemRestService").getJobFamilies(this, "_objRestGetJobFamiliesProperty", objData.position_type, function () {} // handled in rsvp
                );
                arrRequiredResponses.push("_objRestJobFamiliesProperty");
                this.set("_bShowProvidePositionJobFamily", true);
                this.set("_bProvidePositionJobFamiliesLoading", true);
            } else if (!a24Core.isEmpty(this.get("_arrJobFamilies")) && !a24Core.isEmpty(objData.job_family_id)) {
                var objJobFamily = this.get("_arrJobFamilies").findBy("_id", objData.job_family_id);

                if (!a24Core.isEmpty(objJobFamily)) {
                    this.set("_objProvidePositionJobFamily", Ember.copy(objJobFamily));
                } else {
                    this.set("_objProvidePositionJobFamily", null);
                }

                this.set("_bShowProvidePositionJobFamily", true);
            } else {
                this.set("_bShowProvidePositionJobFamily", false);
            }

            if (a24Core.isEmpty(this.get("_arrJobTitles")) && !a24Core.isEmpty(objData.position_type) && !a24Core.isEmpty(objData.job_family_id) && !a24Core.isEmpty(objData.job_title_id)) {
                objServiceCalls._objRestJobTitlesProperty = this.get("_objSystemRestService").getJobTitles(this, "_objRestGetJobTitlesProperty", objData.position_type, objData.job_family_id, function () {} // handled in rsvp
                );
                arrRequiredResponses.push("_objRestJobTitlesProperty");
                this.set("_bShowProvidePositionJobTitle", true);
                this.set("_bProvidePositionJobTitlesLoading", true);
            } else if (!a24Core.isEmpty(this.get("_arrJobTitles")) && !a24Core.isEmpty(objData.job_title_id)) {
                var objJobTitle = this.get("_arrJobTitles").findBy("_id", objData.job_title_id);

                if (!a24Core.isEmpty(objJobTitle)) {
                    this.set("_objProvidePositionJobTitle", Ember.copy(objJobTitle));
                } else {
                    this.set("_objProvidePositionJobTitle", null);
                }

                this.set("_bShowProvidePositionJobTitle", true);
            } else {
                this.set("_bShowProvidePositionJobTitle", false);
            }

            if (a24Core.isEmpty(arrRequiredResponses)) {
                Ember.run.next(function () {
                    if (a24Core.isEmpty(_this7)) {
                        return;
                    }
                    Ember.run.schedule("afterRender", function () {
                        _this7.set("_bStep2InitDone", true);
                    });
                });
            } else {
                a24RSVP.configure(this, objServiceCalls, [{
                    arrRequiredResponses: arrRequiredResponses,
                    funcOnComplete: function funcOnComplete(objResponses) {
                        var arrPositionTypes = [];
                        if (!a24Core.isEmpty(objResponses._objRestPositionTypesProperty)) {
                            if (!a24Core.isEmpty(objResponses._objRestPositionTypesProperty.objResponse)) {
                                arrPositionTypes = objResponses._objRestPositionTypesProperty.objResponse;
                            }
                            arrPositionTypes.push({
                                code: "other",
                                name: _this7.get("_objStringsService").getString("other")
                            });

                            _this7.set("_arrPositionTypes", arrPositionTypes);

                            if (!a24Core.isEmpty(objData.position_type)) {
                                var objPositionType = arrPositionTypes.findBy("code", objData.position_type);

                                _this7.set("_bIsOtherPositionType", objData.position_type === "other");

                                if (!a24Core.isEmpty(objPositionType)) {
                                    _this7.set("_objProvidePositionPositionType", Ember.copy(objPositionType));
                                } else {
                                    _this7.set("_objProvidePositionPositionType", null);
                                }
                            } else {
                                _this7.set("_objProvidePositionPositionType", null);
                            }
                        }

                        if (!a24Core.isEmpty(objResponses._objRestJobFamiliesProperty) && !a24Core.isEmpty(objResponses._objRestJobFamiliesProperty.objResponse)) {
                            var arrJobFamilies = objResponses._objRestJobFamiliesProperty.objResponse;
                            var objJobFamily = arrJobFamilies.findBy("_id", objData.job_family_id);

                            _this7.set("_arrJobFamilies", arrJobFamilies);

                            if (!a24Core.isEmpty(objJobFamily)) {
                                _this7.set("_objProvidePositionJobFamily", Ember.copy(objJobFamily));
                            } else {
                                _this7.set("_objProvidePositionJobFamily", null);
                            }
                        }

                        if (!a24Core.isEmpty(objResponses._objRestJobTitlesProperty) && !a24Core.isEmpty(objResponses._objRestJobTitlesProperty.objResponse)) {
                            var arrJobTitles = objResponses._objRestJobTitlesProperty.objResponse;
                            var objJobTitle = arrJobTitles.findBy("_id", objData.job_title_id);

                            var arrLimitedData = arrJobTitles.filter(function (objValue) {
                                return !_this7.get("_arrExcludeJobTitles").find(function (sExclude) {
                                    return objValue.name.toLowerCase().includes(sExclude);
                                });
                            });

                            _this7.set("_arrJobTitles", arrLimitedData);

                            if (!a24Core.isEmpty(objJobTitle)) {
                                _this7.set("_objProvidePositionJobTitle", Ember.copy(objJobTitle));
                            } else {
                                _this7.set("_objProvidePositionJobTitle", null);
                            }
                        }

                        _this7.set("_bProvidePositionPositionTypesLoading", false);
                        _this7.set("_bProvidePositionJobFamiliesLoading", false);
                        _this7.set("_bProvidePositionJobTitlesLoading", false);

                        Ember.run.next(function () {
                            if (a24Core.isEmpty(_this7)) {
                                return;
                            }
                            Ember.run.schedule("afterRender", function () {
                                if (!a24Core.isEmpty(_this7.get("objChildren")["position"]) && !a24Core.isEmpty(objData.position)) {
                                    _this7.get("objChildren")["position"].setValueFromOutside(objData.position);
                                }
                                _this7.set("_bStep2InitDone", true);
                            });
                        });
                    }
                }]);
            }
        },

        _populateAddEmployerInputFields: function _populateAddEmployerInputFields(objData) {
            var objWorkType = this.get("_arrWorkTypes").findBy("sValue", objData.work_type);
            if (objData.work_type === this.get("_objCandidateProfileEnumsService.workTypesPost.UNEMPLOYED.backend")) {
                this.set("_bIsUnemployed", true);
            } else if (objData.work_type === this.get("_objCandidateProfileEnumsService.workTypesPost.STUDENT_FULL_TIME.backend")) {
                this.set("_bStudent", true);
                this.get("objChildren")["institute_name"].setValueFromOutside(objData.institute_name);
            }
            if (!a24Core.isEmpty(objWorkType)) {
                this.set("_objEmployerWorkType", Ember.copy(objWorkType));
            } else {
                this.set("_objEmployerWorkType", null);
            }
            if (!this.get("_bIsSelfEmployed") && !this.get("_bIsUnemployed") && !this.get("_bStudent")) {
                this.get("objChildren")["employer_name"].setValueFromOutside(objData.employer_name);
                this._setupContactNumber();
            }

            if (!this.get("_bIsUnemployed") && !this.get("_bStudent")) {
                this.get("objChildren")["employer_location.address_line1"].setValueFromOutside(objData.employer_location.address_line1);
                this.get("objChildren")["employer_location.address_line2"].setValueFromOutside(objData.employer_location.address_line2);
                this.get("objChildren")["employer_location.post_code"].setValueFromOutside(objData.employer_location.post_code);
                this.get("objChildren")["employer_location.city"].setValueFromOutside(objData.employer_location.city);

                // Country
                var sCountryCode = objData.employer_location.country_code;

                var objCountry = this.get("_arrCountries").findBy("sValue", sCountryCode);

                if (!a24Core.isEmpty(objCountry)) {
                    this.get("objChildren")["employer_location.country_code"].setValueFromOutside(Ember.copy(objCountry));
                }

                // County
                this._setCountiesFromCountryCode(sCountryCode);

                if (sCountryCode === "ZA" || sCountryCode === "UK" || sCountryCode === "GB" || sCountryCode === "US") {
                    var objCounty = this.get("_arrCounties").findBy("sValue", objData.employer_location.province);

                    if (!a24Core.isEmpty(objCounty)) {
                        this.get("objChildren")["province_uksa"].setValueFromOutside(Ember.copy(objCounty));
                    }
                } else {
                    this.get("objChildren")["province_other"].setValueFromOutside(objData.employer_location.province);
                }
            }
        },

        _setupContactNumber: function _setupContactNumber() {
            var objContactNumberField = this.get("objChildren")["employer_contact_number"];

            if (this.get("_bInputsReady")) {
                if (!a24Core.isEmpty(objContactNumberField)) {
                    if (!a24Core.isEmpty(this.get("_objAddEmployerOriginalData.employer_contact_number"))) {
                        objContactNumberField.setValueFromOutside(this.get("_objAddEmployerOriginalData.employer_contact_number"));
                    }

                    if (this.get("_bShowContactNumberError")) {
                        this.setResponseError(["employer_contact_number"], "validationRestInvalidFormat");
                    }
                }
            }
        },

        _goBackToAddEmployerStep: function _goBackToAddEmployerStep() {
            var _this8 = this;

            if (this.get("_objAddEmployerOriginalData.work_type") === this.get("_objCandidateProfileEnumsService.workTypesPost.UNEMPLOYED.backend") || this.get("_objAddEmployerOriginalData.work_type") === this.get("_objCandidateProfileEnumsService.workTypesPost.STUDENT_FULL_TIME.backend")) {
                this.set("_bAddDurationStepEnabled", this.get("_bAddDurationStepCompleted"));
                this.set("_bAddDurationStepSelected", false);
            } else {
                this.set("_bProvidePositionStepEnabled", this.get("_bProvidePositionStepCompleted"));
                this.set("_bProvidePositionStepSelected", false);
            }
            this.set("_bAddEmployerStepSelected", true);

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this8)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    _this8._populateAddEmployerInputFields(_this8.get("_objAddEmployerOriginalData"));
                });
            });
        },

        _goBackToProvidePositionStep: function _goBackToProvidePositionStep() {
            var _this9 = this;

            this.set("_bAddDurationStepEnabled", this.get("_bAddDurationStepCompleted"));
            this.set("_bAddDurationStepSelected", false);
            this.set("_bProvidePositionStepSelected", true);

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this9)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    _this9._populateProvidePositionInputFields(_this9.get("_objProvidePositionOriginalData"));
                });
            });
        },

        _selectAddEmployerStep: function _selectAddEmployerStep(arrErrorPath, sMessageRef) {
            var _this10 = this;

            this.set("_bProvidePositionStepSelected", false);
            this.set("_bAddDurationStepSelected", false);
            this.set("_bAddEmployerStepSelected", true);

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this10)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    _this10._populateAddEmployerInputFields(_this10.get("_objAddEmployerOriginalData"));

                    if (!a24Core.isEmpty(arrErrorPath) && !a24Core.isEmpty(sMessageRef)) {
                        _this10.setResponseError(arrErrorPath, sMessageRef);
                    }
                });
            });
        },

        _selectProvidePositionStep: function _selectProvidePositionStep(arrErrorPath, sMessageRef) {
            var _this11 = this;

            this.set("_bAddDurationStepSelected", false);
            this.set("_bAddEmployerStepSelected", false);
            this.set("_bShowContactNumberError", false);
            this.set("_bProvidePositionStepSelected", true);

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this11)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    _this11._populateProvidePositionInputFields(_this11.get("_objProvidePositionOriginalData"));

                    if (!a24Core.isEmpty(arrErrorPath) && !a24Core.isEmpty(sMessageRef)) {
                        _this11.setResponseError(arrErrorPath, sMessageRef);
                    }
                });
            });
        },

        _selectAddDurationStep: function _selectAddDurationStep() {
            var _this12 = this;

            this.set("_bAddEmployerStepSelected", false);
            this.set("_bShowContactNumberError", false);
            this.set("_bProvidePositionStepSelected", false);
            this.set("_bAddDurationStepSelected", true);

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this12)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    _this12._populateAddDurationInputFields(_this12.get("_objAddDurationOriginalData"));
                });
            });
        },

        _saveCurrentStepData: function _saveCurrentStepData() {
            var objData = this._getPayload();

            if (this.get("_bAddEmployerStepSelected")) {
                this.set("_objAddEmployerOriginalData", objData);
                Ember.assign(this.get("_objDataCopy"), objData);
                this.set("_bAddEmployerStepCompleted", true);
            } else if (this.get("_bProvidePositionStepSelected")) {
                this.set("_objProvidePositionOriginalData", objData);
                Ember.assign(this.get("_objDataCopy"), objData);
                this.set("_bProvidePositionStepCompleted", true);
            } else if (this.get("_bAddDurationStepSelected")) {
                this.set("_objAddDurationOriginalData", objData);
                Ember.assign(this.get("_objDataCopy"), objData);
                this.set("_bAddDurationStepCompleted", true);
            }
        },

        _getCurrentStepData: function _getCurrentStepData() {
            if (this.get("_bAddEmployerStepSelected")) {
                return this.get("_objAddEmployerOriginalData");
            } else if (this.get("_bProvidePositionStepSelected")) {
                return this.get("_objProvidePositionOriginalData");
            } else if (this.get("_bAddDurationStepSelected")) {
                return this.get("_objAddDurationOriginalData");
            }
        },

        _setErrorResponse: function _setErrorResponse(sCode, objError, sSnackbarMessage) {
            var sJoinedPath = null;

            if (!a24Core.isEmpty(objError) && !a24Core.isEmpty(objError.arrPath)) {
                sJoinedPath = objError.arrPath.join(".");
            }

            if (sCode === "PATTERN" && sJoinedPath === "employer_contact_number") {
                this._selectAddEmployerStep();
                this.set("_bShowContactNumberError", true);
            } else if (sCode === "MAX_LENGTH" && objError.arrPath[0] === "employer_location") {
                this._selectAddEmployerStep([sJoinedPath], "validationRestMaxLength");
            } else if (sCode === "MIN_LENGTH" && (objError.arrPath[0] === "employer_location" || objError.arrPath[0] === "employer_name")) {
                this._selectAddEmployerStep([sJoinedPath], "validationRestMinLength");
            } else if (sCode === "ENUM_MISMATCH" && (sJoinedPath === "position_type" || sJoinedPath === "job_family_id" || sJoinedPath === "job_title_id")) {
                this._selectProvidePositionStep([sJoinedPath], "validationRestUnsupportedValue");
            } else if (sCode === "MIN_LENGTH" && sJoinedPath === "responsibilities") {
                this.setResponseError([sJoinedPath], "validationRestMinLength");
            } else if (sCode === "SHOULD_BE_LESS_THAN_END_DATE" && sJoinedPath === "start_date") {
                this.setResponseError([sJoinedPath], "validationStartDateLessEndDate", false, false, this.get("_objCandidateProfileStringsService"));
            } else {
                this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            }

            this.set("_bPopupLoading", false);
        },

        _createEmployment: function _createEmployment(objPayload) {
            var _this13 = this;

            this.set("_bPopupLoading", true);

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objCandidateProfileStringsService").getString("workHistory")));

            this.get("objCandidateEmploymentRestService").createEmployment(this, "_objRestCreateEmploymentProperty", this.get("sCandidateId"), objPayload, function (objResponse) {
                _this13.get("_objSnackbarService").showSnackbarMessage(_this13.get("_objStringsService").getTokenString("successAddItem", _this13.get("_objCandidateProfileStringsService").getString("workHistory")));

                if (!a24Core.isEmpty(_this13.get("onWorkHistoryAddedAction"))) {
                    _this13.get("onWorkHistoryAddedAction")(objResponse);
                }

                _this13.set("_bPopupLoading", false);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {

                _this13._setErrorResponse(sCode, objError, _this13.get("_objStringsService").getTokenString("failAddItem", _this13.get("_objCandidateProfileStringsService").getString("workHistory")));
            }));
        },

        _updateEmployment: function _updateEmployment(objPayload) {
            var _this14 = this;

            this.set("_bPopupLoading", true);

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objCandidateProfileStringsService").getString("workHistory")));

            this.get("objCandidateEmploymentRestService").updateEmployment(this, "_objRestUpdateEmploymentProperty", this.get("sCandidateId"), this.get("objData._id"), objPayload, function (objResponse) {
                _this14.get("_objSnackbarService").showSnackbarMessage(_this14.get("_objStringsService").getTokenString("successUpdateItem", _this14.get("_objCandidateProfileStringsService").getString("workHistory")));

                if (!a24Core.isEmpty(_this14.get("onWorkHistoryUpdateAction"))) {
                    _this14.get("onWorkHistoryUpdateAction")(objResponse);
                }

                _this14.set("_bPopupLoading", false);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                _this14._setErrorResponse(sCode, objError, _this14.get("_objStringsService").getTokenString("failUpdateItem", _this14.get("_objCandidateProfileStringsService").getString("workHistory")));
            }));
        },

        actions: {
            onStepperItemClicked: function onStepperItemClicked(sStepNumber /*, sTitle, sItemId */) {
                if (this.get("_bProvidePositionStepSelected") && this.get("_bProvidePositionStepLoading")) {
                    return;
                }

                if (sStepNumber === "1" && this.get("_bAddEmployerStepCompleted") && !this.get("_bAddEmployerStepSelected")) {
                    if (!this.doFullValidation()) {
                        this._saveCurrentStepData();
                        this._selectAddEmployerStep();
                    }
                } else if (sStepNumber === "2" && this.get("_bProvidePositionStepCompleted") && !this.get("_bProvidePositionStepSelected")) {
                    if (!this.doFullValidation()) {
                        this._saveCurrentStepData();
                        this._selectProvidePositionStep();
                    }
                } else if (sStepNumber === "3" && this.get("_bAddDurationStepCompleted") && !this.get("_bAddDurationStepSelected")) {
                    if (!this.doFullValidation()) {
                        this._saveCurrentStepData();
                        this._selectAddDurationStep();
                    }
                }
            },

            onPopupAddEditCancel: function onPopupAddEditCancel() {
                if (this.get("_bProvidePositionStepSelected") && this.get("_bProvidePositionStepLoading")) {
                    return;
                }

                this._popupAddEditCancel();
            },

            onPopupUnsavedCancel: function onPopupUnsavedCancel() {
                this.set("_bShowUnsavedData", false);
                this.set("_bCloseButtonClicked", false);
            },

            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowUnsavedData", false);

                if (this.get("_bCloseButtonClicked") || this.get("_bAddEmployerStepSelected")) {
                    if (!a24Core.isEmpty(this.get("onPopupCloseAction"))) {
                        this.get("onPopupCloseAction")();
                    }
                } else if (this.get("_bProvidePositionStepSelected")) {
                    this.clearForm();
                    this._goBackToAddEmployerStep();
                } else if (this.get("_bAddDurationStepSelected")) {
                    this.clearForm();
                    this._goBackToProvidePositionStep();
                }

                this.set("_bCloseButtonClicked", false);
            },

            onPopupAddEmployerBack: function onPopupAddEmployerBack() {
                this._popupAddEditCancel();
            },

            onPopupAddEmployerNext: function onPopupAddEmployerNext() {
                var _this15 = this;

                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    this._saveCurrentStepData();

                    this.set("_bAddEmployerStepSelected", false);
                    this.set("_bShowContactNumberError", false);

                    if (this.get("_bIsUnemployed") || this.get("_bStudent")) {
                        this.set("_bAddDurationStepEnabled", true);
                        this.set("_bAddDurationStepSelected", true);

                        Ember.run.next(function () {
                            if (a24Core.isEmpty(_this15)) {
                                return;
                            }
                            Ember.run.schedule("afterRender", function () {
                                _this15._populateAddDurationInputFields(_this15.get("_objAddDurationOriginalData"));
                            });
                        });
                    } else {
                        this.set("_bProvidePositionStepEnabled", true);
                        this.set("_bProvidePositionStepSelected", true);

                        Ember.run.next(function () {
                            if (a24Core.isEmpty(_this15)) {
                                return;
                            }
                            Ember.run.schedule("afterRender", function () {
                                _this15._populateProvidePositionInputFields(_this15.get("_objProvidePositionOriginalData"));
                            });
                        });
                    }
                }
            },

            onPopupProvidePositionBack: function onPopupProvidePositionBack() {
                if (!this.get("_bIsAddState")) {
                    if (!this.doFullValidation()) {
                        this._saveCurrentStepData();
                        this._goBackToAddEmployerStep();
                    }
                } else {
                    var bDataMatches = a24Core.compareObjects(Ember, this._getPayload(), this.get("_objProvidePositionOriginalData"));

                    if (bDataMatches) {
                        this._goBackToAddEmployerStep();
                    } else {
                        if (!this.doFullValidation()) {
                            this._saveCurrentStepData();
                            this._goBackToAddEmployerStep();
                        } else {
                            this.set("_bShowUnsavedData", true);
                        }
                    }
                }
            },

            onPopupProvidePositionNext: function onPopupProvidePositionNext() {
                var _this16 = this;

                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    this._saveCurrentStepData();

                    this.set("_bProvidePositionStepSelected", false);
                    this.set("_bAddDurationStepEnabled", true);
                    this.set("_bAddDurationStepSelected", true);

                    Ember.run.next(function () {
                        if (a24Core.isEmpty(_this16)) {
                            return;
                        }
                        Ember.run.schedule("afterRender", function () {
                            _this16._populateAddDurationInputFields(_this16.get("_objAddDurationOriginalData"));
                        });
                    });
                }
            },

            onPopupAddDurationBack: function onPopupAddDurationBack() {
                if (!this.get("_bIsAddState")) {
                    if (!this.doFullValidation()) {
                        this._saveCurrentStepData();
                        if (this.get("_bIsUnemployed") || this.get("_bStudent")) {
                            this._goBackToAddEmployerStep();
                        } else {
                            this._goBackToProvidePositionStep();
                        }
                    }
                } else {
                    var bDataMatches = a24Core.compareObjects(Ember, this._getPayload(), this.get("_objAddDurationOriginalData"));

                    if (bDataMatches) {
                        if (this.get("_bIsUnemployed") || this.get("_bStudent")) {
                            this._goBackToAddEmployerStep();
                        } else {
                            this._goBackToProvidePositionStep();
                        }
                    } else {
                        if (!this.doFullValidation()) {
                            this._saveCurrentStepData();
                            if (this.get("_bIsUnemployed") || this.get("_bStudent")) {
                                this._goBackToAddEmployerStep();
                            } else {
                                this._goBackToProvidePositionStep();
                            }
                        } else {
                            this.set("_bShowUnsavedData", true);
                        }
                    }
                }
            },

            onPopupAddDurationSave: function onPopupAddDurationSave() {
                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    this._saveCurrentStepData();

                    var objPositionData = {};
                    if (!this.get("_bIsUnemployed") && !this.get("_bStudent")) {
                        objPositionData = this.get("_objProvidePositionOriginalData");
                    }

                    var objPayload = Ember.assign({}, this.get("_objAddEmployerOriginalData"), objPositionData, this.get("_objAddDurationOriginalData"));

                    delete objPayload.current;

                    if (this.get("_bIsAddState")) {
                        this._createEmployment(objPayload);
                    } else {
                        this._updateEmployment(objPayload);
                    }
                }
            },

            onInputFormTelNumberInitDone: function onInputFormTelNumberInitDone() {
                this.set("_bInputsReady", true);
                this.set("_bPopupLoading", false);
                if (this.get("_bShowAddEditPopup")) {
                    this._setupContactNumber();
                }
                if (!a24Core.isEmpty(this.get("onTelNumberInputLoaded"))) {
                    var objContactNumberField = this.get("objChildren")["employer_contact_number"];

                    this.get("onTelNumberInputLoaded")({
                        formatNumberForDisplay: objContactNumberField.formatNumberForDisplay.bind(objContactNumberField)
                    });
                }
            }
        }
    });
});