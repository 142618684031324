enifed('ember-template-compiler/plugins/assert-if-helper-without-arguments', ['exports', 'ember-debug', 'ember-template-compiler/system/calculate-location-display'], function (exports, _emberDebug, _calculateLocationDisplay) {
  'use strict';

  exports.default = assertIfHelperWithoutArguments;
  function assertIfHelperWithoutArguments(env) {
    var moduleName = env.meta.moduleName;


    return {
      name: 'assert-if-helper-without-arguments',

      visitor: {
        BlockStatement: function (node) {
          if (isInvalidBlockIf(node)) {
            (true && !(false) && (0, _emberDebug.assert)(blockAssertMessage(node.path.original) + ' ' + (0, _calculateLocationDisplay.default)(moduleName, node.loc)));
          }
        },
        MustacheStatement: function (node) {
          if (isInvalidInlineIf(node)) {
            (true && !(false) && (0, _emberDebug.assert)(inlineAssertMessage(node.path.original) + ' ' + (0, _calculateLocationDisplay.default)(moduleName, node.loc)));
          }
        },
        SubExpression: function (node) {
          if (isInvalidInlineIf(node)) {
            (true && !(false) && (0, _emberDebug.assert)(inlineAssertMessage(node.path.original) + ' ' + (0, _calculateLocationDisplay.default)(moduleName, node.loc)));
          }
        }
      }
    };
  }

  function blockAssertMessage(original) {
    return '#' + original + ' requires a single argument.';
  }

  function inlineAssertMessage(original) {
    return 'The inline form of the \'' + original + '\' helper expects two or three arguments.';
  }

  function isInvalidInlineIf(node) {
    return node.path.original === 'if' && (!node.params || node.params.length < 2 || node.params.length > 3);
  }

  function isInvalidBlockIf(node) {
    return node.path.original === 'if' && (!node.params || node.params.length !== 1);
  }
});