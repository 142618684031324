define("a24-ember-candidate-profile/services/rest-agency-group-candidate-basic-info", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        sContext: null,

        // Stores the last retrieved candidates details.
        // This is cleared each time a new candidate profile page is loaded(page-agency-group-candidate-view.js)
        objLastCandidateDetails: null,

        /*
          Services
        */
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),

        _sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        init: function init() {
            this._super.apply(this, arguments);

            this.set("sContext", this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP"));
        },

        /*
          Functions
        */
        /**
         * Performs a single candidate basic info ajax call
         *
         * @param objCaller - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id to fetch basic info data for
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  26 October 2017
         */
        getBasicInfo: function getBasicInfo(objContext, sProperty, sCandidateId, funcSuccess, objFailure) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;
            var objThis = this;
            var funcSuccessWrapper = function funcSuccessWrapper(objData) {
                objThis.set("objLastCandidateDetails", objData);
                funcSuccess.apply(undefined, arguments);
            };

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency-group/" + sAgencyGroupId + "/candidate/" + sCandidateId + "/basic-info");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", Ember.get(this, "_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccessWrapper, objFailure));
        },

        /**
         * Update contact number for the candidate
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id
         * @param objData - The contact number data
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  15 May 2018
         */
        updateBasicInfo: function updateBasicInfo(objContext, sProperty, sCandidateId, objData, funcSuccess, objFailure) {
            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency-group/" + sAgencyGroupId + "/candidate/" + sCandidateId + "/basic-info");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "PUT", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        }
    });
});