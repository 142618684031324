define("a24-calendar/components/calendar-month", ["exports", "a24-calendar/templates/components/calendar-month", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _calendarMonth, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        /*
          Default values
        */
        layout: _calendarMonth.default,
        classNames: ["calendar-month"],

        /*
          @property iYear
          The year of the month being displayed.
        */
        iYear: moment().year(),

        /*
          @property iMonth
          The month (0-11) the component should display.
        */
        iMonth: moment().month(),

        /*
          @property iOffset
          The number of days the week days will be offset by.
        */
        iOffset: 0,

        /*
          @property bLoading
          It is true when the month days data is loading.
        */
        bLoading: false,

        /*
          @property bShowWeekHeading
          It is true when the week heading is displayed.
        */
        bShowWeekHeading: true,

        /*
          @property objMonthDays
          The list of days data that will be displayed for the specified month.
        */
        objMonthDays: null,

        /*
          @property objPrevMonthDays
          The list of days data that will be displayed for the previous month.
        */
        objPrevMonthDays: null,

        /*
          @property objNextMonthDays
          The list of days data that will be displayed for the next month.
        */
        objNextMonthDays: null,

        /*
          @property onDayAction
          A generic action that is triggered in the event of any action on a day component. The first argument is the type (String) of the action followed by any number of arguments.
        */
        onDayAction: null,

        /*
          @property sDayComponent
          The name of the component that will render inside each day block of the current month.
        */
        sDayComponent: "calendar-day",

        /*
          Inline computed properties
        */
        /*
          @property sPrevMonthDayComponent
          The name of the component that will render inside each day block of the previous month.
        */
        sPrevMonthDayComponent: Ember.computed.reads("sDayComponent"),

        /*
          @property sNextMonthDayComponent
          The name of the component that will render inside each day block of the next month.
        */
        sNextMonthDayComponent: Ember.computed.reads("sPrevMonthDayComponent"),

        /*
          Multiline computed properties
        */
        /*
          A valid offset.
          @computed iValidOffset
          @return {Integer} offset.
        */
        iValidOffset: Ember.computed("iOffset", function () {
            return Math.max(0, Math.min(this.get("iOffset"), 6));
        }).readOnly(),

        /*
          The current moment object of the month and year.
          @computed objCurrentMoment
          @return {Moment} The moment object representing the current month and year.
        */
        objCurrentMoment: Ember.computed("iYear", "iMonth", function () {
            return moment().year(this.get("iYear")).month(this.get("iMonth"));
        }).readOnly(),

        /*
          Calculates the names of the week days for desktop and mobile.
          @computed arrWeekdays
          @return {Array<Object>} Week days.
        */
        arrWeekdays: Ember.computed("media.isMobile", "iValidOffset", function () {
            var iValidOffset = this.get("iValidOffset");
            var objCurrentMoment = this.get("objCurrentMoment");
            var iWeekday = objCurrentMoment.weekday();
            var bIsMobile = this.get("media.isMobile");
            var bIsCurrentMonth = moment().isSame(objCurrentMoment, "month");
            var arrWeekDays = moment.weekdaysShort().map(function (sDay, iIndex) {
                return {
                    sId: iIndex,
                    sName: bIsMobile ? sDay.charAt(0) : sDay,
                    bIsToday: iWeekday === iIndex && bIsCurrentMonth
                };
            });

            return [].concat(arrWeekDays.slice(iValidOffset, arrWeekDays.length), arrWeekDays.slice(0, iValidOffset));
        }).readOnly(),

        objFirstDay: Ember.computed("objCurrentMoment", "iValidOffset", function () {
            var objDay = this.get("objCurrentMoment").clone().startOf("month");
            var iValidOffset = this.get("iValidOffset");
            while (objDay.isoWeekday() % 7 !== iValidOffset) {
                objDay.add(-1, "day");
            }
            return objDay;
        }).readOnly(),

        objLastDay: Ember.computed("objFirstDay", function () {
            var iValidOffset = this.get("iValidOffset");
            var objDay = this.get("objCurrentMoment").clone().endOf("month");
            var iEndOfWeek = (iValidOffset + 6) % 7;
            while (objDay.isoWeekday() % 7 !== iEndOfWeek) {
                objDay.add(1, "day");
            }
            return objDay;
        }).readOnly(),

        /*
          Computes the structure of each day in the month.
          @computed arrDaysInMonth
          @return {Array<Object>} Array containing objects of each days in the month.
        */
        arrDaysInMonth: Ember.computed("objLastDay", "iMonth", "objMonthDays", "objPrevMonthDays", "objNextMonthDays", function () {
            var _ref = [momentHelper.getCurrentDateString(a24Constants.DATE_FORMAT), this.get("iMonth")],
                objToday = _ref[0],
                iMonth = _ref[1];
            var _ref2 = [Ember.A(), this.get("objLastDay")],
                arrDays = _ref2[0],
                objLastDay = _ref2[1];

            var objCurrentDay = this.get("objFirstDay").clone();
            while (objCurrentDay.isBefore(objLastDay)) {
                arrDays.pushObject(this._buildDay(objCurrentDay, objToday, iMonth));
                objCurrentDay.add(1, "day");
            }

            return arrDays;
        }).readOnly(),

        /*
          Calculates the data for the month weeks.
          @computed arrWeeks
          @return {Array<Object>} Array containing objects of each weeks in the month.
        */
        arrWeeks: Ember.computed("arrDaysInMonth.{[],@each.sId}", "objMonthDays", "objPrevMonthDays", "objNextMonthDays", function () {
            var arrWeeks = Ember.A();
            var arrDaysInMonth = this.get("arrDaysInMonth");
            var iDaysLength = arrDaysInMonth.length;
            for (var i = 0; i < iDaysLength; i += 7) {
                var objDay = arrDaysInMonth.objectAt(i);
                arrWeeks.pushObject({
                    sId: objDay.objMoment.format("YYYY-w"),
                    arrDays: arrDaysInMonth.slice(i, i + 7)
                });
            }

            return arrWeeks;
        }).readOnly(),
        /*
          private functions
        */
        _buildDay: function _buildDay(objDayMoment, objToday, iMonth) {
            var objMoment = objDayMoment.clone();
            var iMomentMonth = objMoment.month();

            var bIsThisMonthDay = iMomentMonth === iMonth;
            var bIsPrevMonthDay = iMomentMonth < iMonth;
            var bIsNextMonthDay = iMomentMonth > iMonth;

            var iNumber = objMoment.date();

            var objDay = {};

            if (bIsThisMonthDay) {
                objDay = this.get("objMonthDays." + iNumber);
            } else if (bIsPrevMonthDay) {
                objDay = this.get("objPrevMonthDays." + iNumber);
            } else if (bIsNextMonthDay) {
                objDay = this.get("objNextMonthDays." + iNumber);
            }

            if (typeof objDay === "undefined") {
                objDay = {};
            }

            Ember.set(objDay, "sId", objMoment.format("YYYY-MM-DD"));
            Ember.set(objDay, "objMoment", objMoment);
            Ember.set(objDay, "objDate", objMoment.toDate());
            Ember.set(objDay, "iNumber", iNumber);
            Ember.set(objDay, "bIsPast", objMoment.isBefore(objToday, "day"));
            Ember.set(objDay, "bIsFuture", objMoment.isAfter(objToday, "day"));
            Ember.set(objDay, "bIsToday", objMoment.isSame(objToday, "day"));
            Ember.set(objDay, "bIsThisMonthDay", bIsThisMonthDay);
            Ember.set(objDay, "bIsPrevMonthDay", bIsPrevMonthDay);
            Ember.set(objDay, "bIsNextMonthDay", bIsNextMonthDay);

            return objDay;
        }
    });
});