define("a24-ember-candidate-profile/components/candidate-work-history", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-work-history"], function (exports, _didRenderChangesMixin, _candidateWorkHistory) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _candidateWorkHistory.default,
        sCandidateId: null,
        objCandidateEmploymentRestService: null,
        objSettingsRestService: null,

        _sNoDataText: null,
        _sHeader: null,
        _sEndText: null,
        _bLoading: true,
        _bAllowEdit: true,
        _bTelInputDone: false,
        _bAPICallsDone: false,
        _bServerError: false,
        _arrData: null,
        _arrWorkHistory: null,
        _objCandidateWorkHistoryPromise: null,
        _objTelInput: null,
        _objSystemServicePromise: null,
        _bShowAddEditWorkHistoryPopup: false,
        _objEditEmploymentData: null,

        _objUserSession: Ember.inject.service("user-session"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objSystemService: Ember.inject.service("rest-system"),
        _objCandidateDocGenService: Ember.inject.service("candidate-document-generation-service"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objFabButtonService: Ember.inject.service("fab-button"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            if (!this.get("objSettingsRestService.objSettings.employment_history_editable")) {
                this.set("_bAllowEdit", false);
            }

            this.set("_sNoDataText", this.get("_objCandidateProfileStringsService").getHTMLTokenString("noEmploymentHistoryData"));
            this.set("_sHeader", this.get("_objCandidateProfileStringsService").getString("workHistoryWithGaps"));

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this._getWorkHistory();
                }
            }]);

            if (this.get("_bAllowEdit")) {
                if (this.get("objCandidateEmploymentRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE") || this.get("objCandidateEmploymentRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP")) {
                    this.get("_objFabButtonService").showFabButton(function () {
                        _this.addWorkHistory();
                    });
                }

                var objPromiseWorkHistory = this.get("objCandidateEmploymentRestService").getWorkHistory(this, "_objCandidateWorkHistoryPromise", this.get("sCandidateId"), function () {}, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                        _this.set("_bServerError", true);
                        return;
                    }
                }));

                var objPromisePositionTypes = this.get("_objSystemService").getPositionTypes(this, "_objSystemServicePromise", function () {}, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{}), {
                    sortBy: {
                        mValue: "name"
                    },
                    items_per_page: {
                        mValue: 1000
                    }
                });

                var objServiceCalls = {
                    _objRestWorkHistoryProperty: objPromiseWorkHistory,
                    _objRestSystemServiceProperty: objPromisePositionTypes
                };

                var arrRequiredResponses = ["_objRestWorkHistoryProperty", "_objRestSystemServiceProperty"];

                Ember.copy(a24RSVP).configure(this, objServiceCalls, [{
                    arrRequiredResponses: arrRequiredResponses,
                    funcOnComplete: function funcOnComplete(objResponses) {
                        if (!a24Core.isEmpty(objResponses._objRestWorkHistoryProperty) && !a24Core.isEmpty(objResponses._objRestWorkHistoryProperty.objHeaders) && objResponses._objRestWorkHistoryProperty.objHeaders.statusText === "abort" || !a24Core.isEmpty(objResponses._objRestSystemServiceProperty) && !a24Core.isEmpty(objResponses._objRestSystemServiceProperty.objHeaders) && objResponses._objRestSystemServiceProperty.objHeaders.statusText === "abort") {
                            return;
                        }

                        if (!a24Core.isEmpty(objResponses._objRestWorkHistoryProperty) && !a24Core.isEmpty(objResponses._objRestWorkHistoryProperty.objResponse)) {
                            _this.set("_arrWorkHistory", objResponses._objRestWorkHistoryProperty.objResponse);
                        } else {
                            _this.set("_arrWorkHistory", []);
                        }

                        var arrPositionTypes = [];

                        if (!a24Core.isEmpty(objResponses._objRestSystemServiceProperty) && !a24Core.isEmpty(objResponses._objRestSystemServiceProperty.objResponse)) {
                            arrPositionTypes = objResponses._objRestSystemServiceProperty.objResponse;
                        }
                        arrPositionTypes.push({
                            code: "other",
                            name: _this.get("_objStringsService").getString("other")
                        });
                        _this.set("_arrPositionTypes", arrPositionTypes);

                        _this.set("_bAPICallsDone", true);

                        _this._prepareForDisplay();
                    }
                }]);
            }
        },
        _getWorkHistory: function _getWorkHistory() {
            var _this2 = this;

            if (this.get("_bAllowEdit")) {
                this.set("_bLoading", true);

                this.get("objCandidateEmploymentRestService").getWorkHistory(this, "_objCandidateWorkHistoryPromise", this.get("sCandidateId"), function (arrData) {
                    _this2.set("_arrWorkHistory", arrData);
                    _this2.set("_bAPICallsDone", true);
                    _this2._prepareForDisplay();
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                        _this2.set("_bServerError", true);
                        return;
                    }
                    _this2.set("_arrWorkHistory", []);
                    _this2.set("_bLoading", false);
                }));
            }
        },
        _prepareForDisplay: function _prepareForDisplay() {
            var _this3 = this;

            if (!this.get("_bTelInputDone") || !this.get("_bAPICallsDone")) {
                return;
            }

            this.set("_bLoading", false);

            this.get("_objCandidateDocGenService").sortWorkHistoryItems(this.get("_arrWorkHistory"));

            var objStartDateFinal = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            var objStartDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            var objEndDateFinal = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            var objEndDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            var objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            var arrWorkHistory = this.get("_arrWorkHistory");
            var arrWorkHistoryWithGaps = [];
            var arrDisplayData = [];

            var sStartDate = "";
            var sEndDate = "";

            var objStartDateTest = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            var objEndDateTest = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            if (!a24Core.isEmpty(arrWorkHistory)) {

                for (var i = 0; i < arrWorkHistory.length; i++) {
                    arrWorkHistoryWithGaps.push(arrWorkHistory[i]);
                    if (i === arrWorkHistory.length - 1) {
                        break;
                    }
                    objStartDate.setDateFromRest(arrWorkHistory[i].start_date);
                    objEndDate.setDateFromRest(arrWorkHistory[i + 1].end_date);
                    sStartDate = objStartDate.getDateStringForRest();
                    sEndDate = objEndDate.getDateStringForRest();

                    // Need to check if the current work history item falls anywhere in between any of the work history
                    // items. If so then there shouldnt be a gap as the start date will be extended to cater for that gap
                    for (var k = 0; k < arrWorkHistory.length; k++) {
                        objStartDateFinal.setDateFromRest(arrWorkHistory[k].start_date);
                        objEndDateFinal.setDateFromRest(arrWorkHistory[k].end_date);
                        objStartDateTest.setDateFromRest(sStartDate);
                        objEndDateTest.setDateFromRest(sEndDate);
                        if (objStartDate.difference(objStartDateFinal, a24Constants.MOMENT_TIME_DAYS) > 0 && objStartDate.difference(objEndDateFinal, a24Constants.MOMENT_TIME_DAYS) <= 0) {
                            if (objStartDateTest.difference(objStartDateFinal, a24Constants.MOMENT_TIME_DAYS) >= 0) {
                                sStartDate = objStartDateFinal.getDateStringForRest();
                            }
                        }

                        if (objEndDate.difference(objStartDateFinal, a24Constants.MOMENT_TIME_DAYS) > 0 && objEndDate.difference(objEndDateFinal, a24Constants.MOMENT_TIME_DAYS) <= 0) {
                            if (objEndDateTest.difference(objEndDateFinal, a24Constants.MOMENT_TIME_DAYS) <= 0) {
                                sEndDate = objEndDateFinal.getDateStringForRest();
                            }
                        }
                    }

                    objStartDate.setDateFromRest(sStartDate);
                    objEndDate.setDateFromRest(sEndDate);

                    if (objStartDate.difference(objEndDate, a24Constants.MOMENT_TIME_DAYS) > 30) {
                        objStartDate.subtractTimeFromDate(a24Constants.MOMENT_TIME_DAYS, 1);
                        objEndDate.addTimeToDate(a24Constants.MOMENT_TIME_DAYS, 1);
                        arrWorkHistoryWithGaps.push({
                            start_date: objEndDate.getDateStringForRest(),
                            end_date: objStartDate.getDateStringForRest(),
                            gap: true
                        });
                    }
                }

                var objCurrentStartDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                objStartDate.setDateFromRest(arrWorkHistory[arrWorkHistory.length - 1].start_date);

                for (var l = 0; l < arrWorkHistory.length; l++) {
                    objCurrentStartDate.setDateFromRest(arrWorkHistory[l].start_date);
                    if (objCurrentStartDate.isBefore(objStartDate)) {
                        objStartDate.setDateFromRest(arrWorkHistory[l].start_date);
                    }
                }

                var iEndMonths = objCurrentDate.difference(objStartDate, a24Constants.MOMENT_TIME_MONTHS, true);
                var iEndYears = iEndMonths / 12;
                iEndMonths = iEndMonths % 12;

                this.set("_sEndText", parseInt(iEndYears) + "y " + parseInt(iEndMonths) + "m");

                var objThis = this;

                var objData = null;
                var objActions = null;
                var sDotColor = null;
                var sComponent = null;
                var funcCardActionEditExecute = function funcCardActionEditExecute(objWorkHistory) {
                    return function () {
                        objThis.editWorkHistory(objWorkHistory);
                    };
                };

                for (var j = 0; j < arrWorkHistoryWithGaps.length; j++) {
                    arrWorkHistoryWithGaps[j].arrPositionTypes = this.get("_arrPositionTypes");
                    sDotColor = null;
                    sComponent = "timeline-card";
                    objData = arrWorkHistoryWithGaps[j];
                    objData.objTelInput = this.get("_objTelInput");
                    if (this.get("objCandidateEmploymentRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE") || this.get("objCandidateEmploymentRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP")) {
                        if (arrWorkHistoryWithGaps[j].system_generated) {
                            objActions = null;
                        } else {
                            objActions = {
                                arrActions: [{
                                    sTitle: this.get("_objStringsService").getString("edit"),
                                    sIcon: "edit",
                                    executeCardAction: funcCardActionEditExecute(objData)
                                }]
                            };
                        }
                    }

                    // Checks if the first element is current. If not then add gap data for first element
                    if (j === 0 && (!arrWorkHistoryWithGaps[j].current || !a24Core.isEmpty(arrWorkHistoryWithGaps[j].end_date))) {
                        var objStartDateGap = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                        objStartDateGap.setDateFromRest(arrWorkHistoryWithGaps[j].end_date);
                        objStartDateGap.addTimeToDate(a24Constants.MOMENT_TIME_DAYS, 1);

                        var objAddActions = {};
                        if (this.get("objCandidateEmploymentRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE") || this.get("objCandidateEmploymentRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP")) {
                            objAddActions = {
                                funcAddAction: function funcAddAction() {
                                    _this3.addWorkHistory();
                                }
                            };
                        }

                        arrDisplayData.push({
                            sDotColor: "warning-color",
                            sComponent: "timeline-card-no-data",
                            objData: {
                                start_date: objStartDateGap.getDateStringForRest(),
                                end_date: null,
                                current: true
                            },
                            objActions: objAddActions
                        });
                    } else if (arrWorkHistoryWithGaps[j].gap) {
                        sComponent = "timeline-card-no-data";
                        sDotColor = "warning-color";
                        objData = {
                            start_date: arrWorkHistoryWithGaps[j].start_date,
                            end_date: arrWorkHistoryWithGaps[j].end_date,
                            current: false
                        };
                        if (this.get("objCandidateEmploymentRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE") || this.get("objCandidateEmploymentRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP")) {
                            objActions = {
                                funcAddAction: function funcAddAction() {
                                    _this3.addWorkHistory();
                                }
                            };
                        } else {
                            objActions = {};
                        }
                    }

                    objData.sHeaderColor = "";

                    if (!a24Core.isEmpty(objData.work_type) && objData.work_type === this.get("_objCandidateProfileEnumsService.workTypesPost.UNEMPLOYED.backend")) {
                        sDotColor = "grey lighten-1";
                        objData.sHeaderColor = "grey lighten-1";
                    }
                    arrDisplayData.push({
                        sDotColor: sDotColor,
                        sComponent: sComponent,
                        objData: objData,
                        objActions: objActions

                    });
                }
            } else {
                this.set("_sEndText", "0y 0m");
            }

            this.set("_bPreparedData", true);

            this.set("_arrData", arrDisplayData);
        },

        addWorkHistory: function addWorkHistory() {
            this.set("_objEditEmploymentData", null);
            this.set("_bShowAddEditWorkHistoryPopup", true);
        },
        editWorkHistory: function editWorkHistory(objItem) {
            this.set("_bShowAddEditWorkHistoryPopup", true);
            this.set("_objEditEmploymentData", objItem);
        },
        actions: {
            onWorkHistoryUpdateAction: function onWorkHistoryUpdateAction() {
                this.set("_bShowAddEditWorkHistoryPopup", false);
                this.set("_objEditEmploymentData", null);
                this.set("_bLoading", true);
                this._getWorkHistory();
            },
            onWorkHistoryAddedAction: function onWorkHistoryAddedAction() {
                this.set("_bShowAddEditWorkHistoryPopup", false);
                this.set("_objEditEmploymentData", null);
                this.set("_bLoading", true);
                this._getWorkHistory();
            },
            onAddEditWorkHistoryPopupCloseAction: function onAddEditWorkHistoryPopupCloseAction() {
                this.set("_bShowAddEditWorkHistoryPopup", false);
                this.set("_objEditEmploymentData", null);
            },
            onTelNumberInputLoaded: function onTelNumberInputLoaded(objTelInput) {
                this.set("_bTelInputDone", true);
                this.set("_objTelInput", objTelInput);
                this._prepareForDisplay();
            }
        }
    });
});