define("a24-ember-lib/components/round-icon-header-component", ["exports", "a24-ember-lib/templates/components/round-icon-header-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _roundIconHeaderComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _roundIconHeaderComponent.default,
        // Header
        sHeader: null,
        sHeaderSizeClass: null,
        sHeaderClass: null,
        // Icon flag
        bHasIcon: false,
        // Icon details
        sRoundButtonSize: null,
        sLetter: null,
        sIconSvg: null,
        sIconName: null,
        sIconFa: null,
        sImageUrl: null,
        sIconColor: null,
        sIconClass: null,
        sIconSizeClass: null,
        // Actions
        bApplyPaddingToYield: true,
        objSpecialAction: null,
        arrActionItems: null,
        bHasBlock: false,
        bOverrideDropdown: false,
        bMobileView: false,
        bEllipseHeader: true,
        funcCallback: null,

        _sActionsWidthClass: "",
        _iVisibleActions: 3,
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        bActionsSpecified: Ember.computed("objSpecialAction", "arrActionItems", function () {
            return !a24Core.isEmpty(this.get("arrActionItems")) || !a24Core.isEmpty(this.get("objSpecialAction"));
        }),
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            Ember.run.schedule("afterRender", function () {

                // Enforce round button size
                switch (_this.get("sRoundButtonSize")) {
                    case _this.get("_objLibConstants.ROUND_ICON_SIZE_SMALL"):
                    case _this.get("_objLibConstants.ROUND_ICON_SIZE_MEDIUM"):
                    case _this.get("_objLibConstants.ROUND_ICON_SIZE_LARGE"):
                    case _this.get("_objLibConstants.ROUND_ICON_SIZE_EXTRA_LARGE"):
                        break;
                    default:
                        _this.set("sRoundButtonSize", _this.get("_objLibConstants.ROUND_ICON_SIZE_MEDIUM"));
                }

                // Enforce round icon size
                switch (_this.get("sIconSizeClass")) {
                    case _this.get("_objLibConstants.ROUND_ICON_CONTENT_SIZE_SMALL"):
                    case _this.get("_objLibConstants.ROUND_ICON_CONTENT_SIZE_MEDIUM"):
                    case _this.get("_objLibConstants.ROUND_ICON_CONTENT_SIZE_LARGE"):
                        break;
                    default:
                        _this.set("sIconSizeClass", _this.get("_objLibConstants.ROUND_ICON_CONTENT_SIZE_MEDIUM"));
                }

                // Enforce the header size
                switch (_this.get("sHeaderSizeClass")) {
                    case _this.get("_objLibConstants.ROUND_ICON_HEADER_SIZE_SMALL"):
                        if (a24Core.isEmpty(_this.get("sHeaderClass"))) {
                            _this.set("sHeaderClass", "heading-small-default");
                        }
                        break;
                    case _this.get("_objLibConstants.ROUND_ICON_HEADER_SIZE_MEDIUM"):
                    case _this.get("_objLibConstants.ROUND_ICON_HEADER_SIZE_LARGE"):
                        break;
                    default:
                        _this.set("sHeaderSizeClass", _this.get("_objLibConstants.ROUND_ICON_HEADER_SIZE_LARGE"));
                }

                if (a24Core.isEmpty(_this.get("arrActionItems"))) {
                    _this.set("arrActionItems", []);
                }
                _this.setActionsMargin();
            });
        },
        setActionsMargin: function setActionsMargin() {
            this.set("_sActionsWidthClass", a24.getCardActionsMarginStyle(this.get("arrActionItems"), this.get("objSpecialAction"), this.get("_iVisibleActions")));
        },
        observeActions: Ember.observer("arrActionItems", "objSpecialAction", function () {
            Ember.run.once(this, this.setActionsMargin);
        }),
        actions: {
            onCallbackClick: function onCallbackClick() {
                if (!a24Core.isEmpty(this.get("funcCallback"))) {
                    return this.get("funcCallback")();
                }
            }
        }
    });
});