define("a24-ember-staffshift-users/services/rest-acl-group-users", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * This service method will make a GET to the acl group route to fetch all its users
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sAclGroupId - The id of the acl group to get users for
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objQueryParams - Query Parameters for the request
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  30 November 2017
         */
        getUserList: function getUserList(objCaller, sProperty, sAclGroupId, funcSuccess, objFailure, objQueryParams) {

            var sUrl = Ember.get(this, "sRestApiBaseUrl") + "/v1/acl/group/" + sAclGroupId + "/userdetails";

            a24RestUrlConstruct.setBaseUrlSwagger(sUrl);

            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, Ember.get(this, "_objUserSession"), Ember.get(this, "_objNavigation"), "GET", a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },


        /**
         * This service method will make a DELETE to the acl group route with acl group id and user id to remove user linked to that ACL Group
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sAclGroupId - The id of the Acl Group
         * @param sUserId - The id of the user which needs to be removed
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         *
         * @author Tarun Arora <tarunarora0807@gmail.com>
         * @since  04 December 2017
         */
        removeUser: function removeUser(objCaller, sProperty, sAclGroupId, sUserId, funcSuccess, objFailure) {
            var sUrl = Ember.get(this, "sRestApiBaseUrl") + "/v1/acl/group/" + sAclGroupId + "/user/" + sUserId;

            a24RestUrlConstruct.setBaseUrlSwagger(sUrl);

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, Ember.get(this, "_objUserSession"), Ember.get(this, "_objNavigation"), "DELETE", a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },


        /**
         * This service method will make a POST to the acl group route with acl group id and user id to link the user to the ACL Group
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sAclGroupId - The id of the Acl Group
         * @param sUserId - The id of the user which needs to be removed
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  04 December 2017
         */
        addUser: function addUser(objCaller, sProperty, sAclGroupId, sUserId, funcSuccess, objFailure) {
            var sUrl = Ember.get(this, "sRestApiBaseUrl") + "/v1/acl/group/" + sAclGroupId + "/user/" + sUserId;

            a24RestUrlConstruct.setBaseUrlSwagger(sUrl);

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, Ember.get(this, "_objUserSession"), Ember.get(this, "_objNavigation"), "POST", a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, {}));
        }
    });
});