define("a24-ember-lib/services/a24-strings-ember-lib", ["exports", "a24-ember-lookup/services/a24-strings-base"], function (exports, _a24StringsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    /**
     * The string service for the ember lib
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since  31 Oct 2018
     */
    var objStrings = {
        _objStrings: {
            alreadyHaveFilter: "You already have a filter applied to this field, would you like to:",
            refine: "Refine(AND)",
            expand: "Expand(OR)",
            applyAndRefine: "Apply and Refine(AND)",
            applyAndExpand: "Apply and Expand(OR)",
            updateFilter: "Update Filter",
            startOver: "Start Over",
            datagridReload: "Could not load data, please click the refresh icon at the top right of the table to retry",
            reload: "Reload",
            seeMore: "See more",
            suggestionEmpty: "No Suggestions",
            empty: "Empty",
            loading: "Loading...",

            chooseImage: "Choose Image",
            selectImage: "Select Image",
            zoomIn: "Zoom In",
            zoomOut: "Zoom Out",
            rotateLeft: "Rotate Left",
            rotateRight: "Rotate Right",
            removeAvatarConfirmation: "Are you sure you want to remove your profile picture?",

            between: "Between",
            after: "After",
            before: "Before",

            many: "Many",

            fileInputPlaceHolder: "Please select a file...",

            savedSearch: "Saved Search",
            saveSearch: "Save Search",
            addSavedSearch: "Add saved search",
            requiredPermission: "You do not have the required permissions to perform this operation",
            selectSavedSearch: "Select Saved Search...",
            nameAlreadyExists: "Name already exists",
            exportData: "Export Data",
            areYouSureYouWantToExport: "Are you sure you want to export all the data?",
            dataWillBeEmailed: "The data will be emailed to your email address on the system as a csv file.",
            largeDataExport: "This is a large data export",
            tooManyResults: "There are too many search results to export, please apply more search criteria.",
            dataHasBeenSent: "The data has been sent to your email address.",
            somethingWentWrongTryAgain: "Something went wrong. Please try again.",
            endOfResults: "You have reached the end of your result set",

            validationFirstPartPostCodeTooManyCharacters: "The first part of the postcode has too many characters. Max 4 characters is allowed",
            validationFirstPartPostCodeCannotHaveMoreThanTwoNumbers: "The first part of the postcode cannot contain more than two numbers",
            validationFirstPartPostCodeMustAlwaysStartWithLetter: "The first part of the postcode must always start with a letter",
            validationFirstPartPostCodeFirstLetter: "The first letter of the first part may not contain one of the following letters. 'Q,V,X'",
            validationFirstPartPostCodeSecondLetter: "The second letter of the first part may not contain one of the following letters. 'I,J,Z'",
            validationFirstPartPostCodeMustAlwaysContainNumber: "The first part of the postcode must always contain a number",
            validationFirstPartPostCodeLastLetterA9A: "The first part of the postcode must always end with the following letters 'A,B,C,D,E,F,G,H,J,K,P,S,T,U,W' when postcode structure is 'A9A'",
            validationFirstPartPostCodeLastLetterAA9A: "The first part of the postcode must always end with the following letters 'A,B,E,H,M,N,P,R,V,W,X,Y' when postcode structure is 'AA9A'",
            validationFirstPartPostCodeMustEndWithNumber: "The first part of the postcode must end with a number when postcode structures is 'A99, AA9, AA99'",
            validationFirstPartPostCode2CharStucture: "The first part of the post code does not match the structure of 'A9'",
            validationFirstPartPostCode3CharStucture: "The first part of the post code does not match the following structures. 'A99', 'A9A', 'AA9'",
            validationFirstPartPostCode4CharStucture: "The first part of the post code does not match the following structures. 'AA9A', 'AA99'",

            validationSecondPartPostCodeHasInvalidCharacters: "The second part of the post code can not contain any of the following characters 'C, I, K, M, O, V'",
            validationSecondPartPostCodeMustStartWithNumber: "The second part of the post code must start with a number",
            validationSecondPartPostCodeCannotHaveMoreThanTwoNumbers: "The second part of the post code cannot contain more than one number",
            validationSecondPartPostIsNotValidFormat: "The second part of the post code does not match the structure of '9AA'",
            validationPostCodeHasSpecialCharacters: "Postcode may only contain numbers and letters",

            validationUsPostCode: "The Zip code is not Valid",

            moreWithIcon: "More<span class='title-bar-more-dropdown-arrow cursor-clickable'></span>"
        }
    };
    exports.default = _a24StringsBase.default.extend(objStrings);
});