define("a24-ember-candidate-profile/services/a24-enums-candidate-profile", ["exports", "a24-ember-lookup/services/a24-enums-base"], function (exports, _a24EnumsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24EnumsBase.default.extend({
        _objStringsService: Ember.inject.service("a24-strings"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objSSCoreStringsService: Ember.inject.service("a24-strings-staffshift-core"),

        init: function init() {
            this._super.apply(this, arguments);

            var objStrings = this.get("_objStringsService");
            var objCandidateProfileStrings = this.get("_objCandidateProfileStringsService");
            var objSSCoreStringsService = this.get("_objSSCoreStringsService");

            this.set("userStatuses", {
                "SETUP_COMPLETE": {
                    "backend": "Setup Complete",
                    "frontend": objCandidateProfileStrings.getString("setupComplete")
                },
                "EMAIL_NOT_VERIFIED": {
                    "backend": "Email not Verified",
                    "frontend": objCandidateProfileStrings.getString("emailNotVerified")
                },
                "INVITE_SENT": {
                    "backend": "Invite Sent",
                    "frontend": objCandidateProfileStrings.getString("inviteSent")
                },
                "USER_REG_NOT_INITIATED": {
                    "backend": "User Reg not Initiated",
                    "frontend": objCandidateProfileStrings.getString("userRegNotInitiated")
                }
            });

            this.set("distanceUnitOptions", {
                "KM": {
                    "backend": objCandidateProfileStrings.getString("km"),
                    "frontend": objCandidateProfileStrings.getString("kilometers")
                },
                "MILES": {
                    "backend": objCandidateProfileStrings.getString("mi"),
                    "frontend": objCandidateProfileStrings.getString("miles")
                }
            });

            this.set("positionHeldStatus", {
                "VERIFIED": {
                    "backend": "verified",
                    "frontend": objCandidateProfileStrings.getString("verified")
                },
                "UNVERIFIED": {
                    "backend": "unverified",
                    "frontend": objCandidateProfileStrings.getString("unverified")
                }
            });

            this.set("positionHeldWorkSuggestStatus", {
                "ACTIVE": {
                    "backend": "active",
                    "frontend": objStrings.getString("active")
                },
                "INACTIVE": {
                    "backend": "inactive",
                    "frontend": objStrings.getString("inactive")
                }
            });

            this.set("playedKeywords", {
                "PLAYED": {
                    "backend": "played",
                    "frontend": objCandidateProfileStrings.getString("played")
                },
                "PAUSED": {
                    "backend": "paused",
                    "frontend": objCandidateProfileStrings.getString("paused")
                }
            });

            this.set("referenceTypes", {
                "PROFESSIONAL": {
                    "backend": "professional",
                    "frontend": objCandidateProfileStrings.getString("professional")
                },
                "CHARACTER": {
                    "backend": "character",
                    "frontend": objCandidateProfileStrings.getString("character")
                }
            });

            this.set("genderBinary", {
                "MALE": {
                    "backend": "male",
                    "frontend": objStrings.getString("male")
                },
                "FEMALE": {
                    "backend": "female",
                    "frontend": objStrings.getString("female")
                }
            });

            this.set("systemSkillStatus", {
                "ACTIVE": {
                    "backend": "active",
                    "frontend": objCandidateProfileStrings.getString("active")
                }
            });

            this.set("systemQualificationStatus", {
                "ACTIVE": {
                    "backend": "active",
                    "frontend": objCandidateProfileStrings.getString("active")
                }
            });

            this.set("documentStatuses", {
                "SUCCESS": {
                    "backend": "success",
                    "frontend": objCandidateProfileStrings.getString("unverified")
                },
                "VERIFIED": {
                    "backend": "verified",
                    "frontend": objCandidateProfileStrings.getString("verified")
                }
            });

            this.set("uploadedDocumentStatuses", {
                "PENDING": {
                    "backend": "pending",
                    "frontend": objCandidateProfileStrings.getString("unverified")
                },
                "SUCCESS": {
                    "backend": "success",
                    "frontend": objCandidateProfileStrings.getString("unverified")
                },
                "VERIFIED": {
                    "backend": "verified",
                    "frontend": objCandidateProfileStrings.getString("verified")
                },
                "REJECTED": {
                    "backend": "rejected",
                    "frontend": objCandidateProfileStrings.getString("rejected")
                }
            });

            this.set("experienceDuration", {
                "ANY_AMOUNT_OF": {
                    "backend": "anyAmountOf",
                    "frontend": objCandidateProfileStrings.getString("anyAmountOf")
                },
                "AT_LEAST": {
                    "backend": "atLeast",
                    "frontend": objCandidateProfileStrings.getString("atLeast")
                },
                "LAST": {
                    "backend": "last",
                    "frontend": objCandidateProfileStrings.getString("last")
                }
            });

            this.set("expiringIn", {
                "NEVER": {
                    "backend": "never",
                    "frontend": objCandidateProfileStrings.getString("never")
                },
                "LESS_THAN_AND_EXPIRED": {
                    "backend": "lessThanAndExpired",
                    "frontend": objCandidateProfileStrings.getString("lessThanAndExpired")
                },
                "LESS_THAN_NOT_EXPIRED": {
                    "backend": "lessThanNotExpired",
                    "frontend": objCandidateProfileStrings.getString("lessThanNotExpired")
                },
                "MORE_THAN": {
                    "backend": "moreThan",
                    "frontend": objCandidateProfileStrings.getString("moreThan")
                    // Commenting this out for now. As backend does not support a exact date search. So we cant do this.
                    // NOTE: test cases for this is commented out for now aswel for when we add this in the future
                    // "EXACTLY": {
                    //     "backend": "exactly",
                    //     "frontend": objCandidateProfileStrings.getString("exactly")
                    // }
                } });

            this.set("activityTypes", {
                "REGISTRATION_CALL_MADE": {
                    "backend": "REGISTRATION_CALL_MADE",
                    "frontend": objCandidateProfileStrings.getString("registrationCallMade")
                },
                "REGISTRATION_CALL_RECEIVED": {
                    "backend": "REGISTRATION_CALL_RECEIVED",
                    "frontend": objCandidateProfileStrings.getString("registrationCallReceived")
                },
                "REGISTRATION_EMAIL_SENT": {
                    "backend": "REGISTRATION_EMAIL_SENT",
                    "frontend": objCandidateProfileStrings.getString("registrationEmailSent")
                },
                "REGISTRATION_EMAIL_RECEIVED": {
                    "backend": "REGISTRATION_EMAIL_RECEIVED",
                    "frontend": objCandidateProfileStrings.getString("registrationEmailReceived")
                },
                "REGISTRATION_TEXT_SENT": {
                    "backend": "REGISTRATION_TEXT_SENT",
                    "frontend": objCandidateProfileStrings.getString("registrationTextSent")
                },
                "LEFT_VOICEMAIL": {
                    "backend": "LEFT_VOICEMAIL",
                    "frontend": objCandidateProfileStrings.getString("leftVoicemail")
                },
                "CALL_MADE_NO_ANSWER": {
                    "backend": "CALL_MADE_NO_ANSWER",
                    "frontend": objCandidateProfileStrings.getString("callMadeNoAnswer")
                },
                "INTERVIEW_BOOKED": {
                    "backend": "INTERVIEW_BOOKED",
                    "frontend": objCandidateProfileStrings.getString("interviewBooked")
                },
                "INITIAL_SCREENING_COMPLETED": {
                    "backend": "INITIAL_SCREENING_COMPLETED",
                    "frontend": objCandidateProfileStrings.getString("initialScreeningCompleted")
                },
                "INITIAL_SCREENING_PAUSED": {
                    "backend": "INITIAL_SCREENING_PAUSED",
                    "frontend": objCandidateProfileStrings.getString("initialScreeningPaused")
                },
                "APPLICATION_PACK_SENT": {
                    "backend": "APPLICATION_PACK_SENT",
                    "frontend": objCandidateProfileStrings.getString("applicationPackSent")
                },
                "INTERVIEW_HELD": {
                    "backend": "INTERVIEW_HELD",
                    "frontend": objCandidateProfileStrings.getString("interviewHeld")
                },
                "DBS_ONLINE_CHECK_DONE": {
                    "backend": "DBS_ONLINE_CHECK_DONE",
                    "frontend": objCandidateProfileStrings.getString("dbsOnlineCheckDone")
                },
                "INTERVIEW_FAILED": {
                    "backend": "INTERVIEW_FAILED",
                    "frontend": objCandidateProfileStrings.getString("interviewFailed")
                },
                "INTERVIEW_PASSED": {
                    "backend": "INTERVIEW_PASSED",
                    "frontend": objCandidateProfileStrings.getString("interviewPassed")
                }
            });

            this.set("phoneTypes", {
                "WHATSAPP": {
                    "backend": "whatsapp",
                    "frontend": objSSCoreStringsService.getString("whatsapp"),
                    "icon_fa": "fa-whatsapp"
                },
                "MOBILE": {
                    "backend": "mobile",
                    "frontend": objCandidateProfileStrings.getString("mobile"),
                    "icon": "phone_android"
                },
                "HOME": {
                    "backend": "home",
                    "frontend": objStrings.getString("home"),
                    "icon": "phone"
                },
                "WORK": {
                    "backend": "work",
                    "frontend": objStrings.getString("work"),
                    "icon": "phone"
                },
                "WORK_FAX": {
                    "backend": "work_fax",
                    "frontend": objCandidateProfileStrings.getString("workFax"),
                    "icon": "print"
                },
                "HOME_FAX": {
                    "backend": "home_fax",
                    "frontend": objCandidateProfileStrings.getString("homeFax"),
                    "icon": "print"
                },
                "ADDITIONAL": {
                    "backend": "additional",
                    "frontend": objCandidateProfileStrings.getString("additional"),
                    "icon": "phone"
                }
            });

            this.set("callTypes", {
                "MOBILE": {
                    "backend": "mobile",
                    "frontend": objCandidateProfileStrings.getString("mobile"),
                    "icon": "phone_android"
                },
                "HOME": {
                    "backend": "home",
                    "frontend": objStrings.getString("home"),
                    "icon": "home"
                },
                "WORK": {
                    "backend": "work",
                    "frontend": objStrings.getString("work"),
                    "icon": "work"
                },
                "ADDITIONAL": {
                    "backend": "additional",
                    "frontend": objCandidateProfileStrings.getString("additional"),
                    "icon": "phone"
                }
            });

            this.set("religion", {
                "BAHAI": {
                    "backend": "bahai",
                    "frontend": objCandidateProfileStrings.getString("bahai")
                },
                "BUDDHIST": {
                    "backend": "buddhist",
                    "frontend": objCandidateProfileStrings.getString("buddhist")
                },
                "CHRISTIAN": {
                    "backend": "christian",
                    "frontend": objCandidateProfileStrings.getString("christian")
                },
                "HINDU": {
                    "backend": "hindu",
                    "frontend": objCandidateProfileStrings.getString("hindu")
                },
                "JAIN": {
                    "backend": "jain",
                    "frontend": objCandidateProfileStrings.getString("jain")
                },
                "JEWISH": {
                    "backend": "jewish",
                    "frontend": objCandidateProfileStrings.getString("jewish")
                },
                "MUSLIM": {
                    "backend": "muslim",
                    "frontend": objCandidateProfileStrings.getString("muslim")
                },
                "NO_RELIGION": {
                    "backend": "no_religion",
                    "frontend": objCandidateProfileStrings.getString("noReligion")
                },
                "SIKH": {
                    "backend": "sikh",
                    "frontend": objCandidateProfileStrings.getString("sikh")
                },
                "PROTESTANT": {
                    "backend": "protestant",
                    "frontend": objCandidateProfileStrings.getString("protestant")
                },
                "ROMAN_CATHOLIC": {
                    "backend": "roman_catholic",
                    "frontend": objCandidateProfileStrings.getString("romanCatholic")
                },
                "OTHER": {
                    "backend": "other",
                    "frontend": objStrings.getString("other")
                },
                "PREFER_NOT_TO_SAY": {
                    "backend": "prefer_not_to_say",
                    "frontend": objCandidateProfileStrings.getString("preferNotToSay")
                }
            });

            this.set("addressType", {
                "ALTERNATIVE_ADDRESS": {
                    "backend": "alternative_address",
                    "frontend": objCandidateProfileStrings.getString("alternativeAddress")
                },
                "PHYSICAL_ADDRESS": {
                    "backend": "physical_address",
                    "frontend": objCandidateProfileStrings.getString("homeAddress")
                },
                "POSTAL_ADDRESS": {
                    "backend": "postal_address",
                    "frontend": objCandidateProfileStrings.getString("postalAddress")
                }
            });

            this.set("emailStatus", {
                "PRIMARY": {
                    "backend": "primary",
                    "frontend": objStrings.getString("primary")
                },
                "VERIFIED": {
                    "backend": "verified",
                    "frontend": objCandidateProfileStrings.getString("verified")
                },
                "UNVERIFIED": {
                    "backend": "unverified",
                    "frontend": objCandidateProfileStrings.getString("unverified")
                }
            });

            this.set("skillsStatus", {
                "VERIFIED": {
                    "backend": "verified",
                    "frontend": objCandidateProfileStrings.getString("verified")
                },
                "UNVERIFIED": {
                    "backend": "unverified",
                    "frontend": objCandidateProfileStrings.getString("unverified")
                },
                "PARTIALLY_VERIFIED": {
                    "backend": "partially_verified",
                    "frontend": objCandidateProfileStrings.getString("partiallyVerified")
                }
            });

            this.set("skillsStatusSearch", {
                "VERIFIED": {
                    "backend": "verified",
                    "frontend": objCandidateProfileStrings.getString("verified")
                },
                "UNVERIFIED": {
                    "backend": "unverified",
                    "frontend": objCandidateProfileStrings.getString("unverified")
                }
            });

            this.set("qualificationsStatus", {
                "VERIFIED": {
                    "backend": "verified",
                    "frontend": objCandidateProfileStrings.getString("verified")
                },
                "UNVERIFIED": {
                    "backend": "unverified",
                    "frontend": objCandidateProfileStrings.getString("unverified")
                }
            });

            this.set("ethnicOrigin", {
                "WHITE_BRITISH": {
                    "backend": "white_british",
                    "frontend": objCandidateProfileStrings.getString("whiteBritish")
                },
                "WHITE_IRISH": {
                    "backend": "white_irish",
                    "frontend": objCandidateProfileStrings.getString("whiteIrish")
                },
                "WHITE_OTHER": {
                    "backend": "white_other",
                    "frontend": objCandidateProfileStrings.getString("whiteOther")
                },
                "MIXED_WHITE_AND_BLACK_CARIBBEAN": {
                    "backend": "mixed_white_and_black_caribbean",
                    "frontend": objCandidateProfileStrings.getString("mixedWhiteAndBlackCaribbean")
                },
                "MIXED_WHITE_AND_BLACK_AFRICAN": {
                    "backend": "mixed_white_and_black_african",
                    "frontend": objCandidateProfileStrings.getString("mixedWhiteAndBlackAfrican")
                },
                "MIXED_WHITE_AND_ASIAN": {
                    "backend": "mixed_white_and_asian",
                    "frontend": objCandidateProfileStrings.getString("mixedWhiteAndAsian")
                },
                "MIXED_OTHER": {
                    "backend": "mixed_other",
                    "frontend": objCandidateProfileStrings.getString("mixedOther")
                },
                "ASIAN_BRITISH": {
                    "backend": "asian_british",
                    "frontend": objCandidateProfileStrings.getString("asianBritish")
                },
                "ASIAN_INDIAN": {
                    "backend": "asian_indian",
                    "frontend": objCandidateProfileStrings.getString("asianIndian")
                },
                "ASIAN_PAKISTANI": {
                    "backend": "asian_pakistani",
                    "frontend": objCandidateProfileStrings.getString("asianPakistani")
                },
                "ASIAN_BANGLADESHI": {
                    "backend": "asian_bangladeshi",
                    "frontend": objCandidateProfileStrings.getString("asianBangladeshi")
                },
                "TAMIL": {
                    "backend": "tamil",
                    "frontend": objCandidateProfileStrings.getString("tamil")
                },
                "ASIAN_OTHER": {
                    "backend": "asian_other",
                    "frontend": objCandidateProfileStrings.getString("asianOther")
                },
                "BLACK_BRITISH": {
                    "backend": "black_british",
                    "frontend": objCandidateProfileStrings.getString("blackBritish")
                },
                "BLACK_CARIBBEAN": {
                    "backend": "black_caribbean",
                    "frontend": objCandidateProfileStrings.getString("blackCaribbean")
                },
                "BLACK_AFRICAN": {
                    "backend": "black_african",
                    "frontend": objCandidateProfileStrings.getString("blackAfrican")
                },
                "BLACK_OTHER": {
                    "backend": "black_other",
                    "frontend": objCandidateProfileStrings.getString("blackOther")
                },
                "CHINESE": {
                    "backend": "chinese",
                    "frontend": objCandidateProfileStrings.getString("chinese")
                },
                "BLACK_EUROPEAN": {
                    "backend": "black_european",
                    "frontend": objCandidateProfileStrings.getString("blackEuropean")
                },
                "OTHER": {
                    "backend": "other",
                    "frontend": objStrings.getString("other")
                },
                "PREFER_NOT_TO_SAY": {
                    "backend": "prefer_not_to_say",
                    "frontend": objCandidateProfileStrings.getString("preferNotToSay")
                }
            });

            this.set("sexualOrientation", {
                "BISEXUAL": {
                    "backend": "bisexual",
                    "frontend": objCandidateProfileStrings.getString("bisexual")
                },
                "GAY_MAN": {
                    "backend": "gay_man",
                    "frontend": objCandidateProfileStrings.getString("gayMan")
                },
                "GAY_WOMEN": {
                    "backend": "gay_women",
                    "frontend": objCandidateProfileStrings.getString("gayWomen")
                },
                "STRAIGHT": {
                    "backend": "straight",
                    "frontend": objCandidateProfileStrings.getString("straight")
                },
                "PREFER_NOT_TO_SAY": {
                    "backend": "prefer_not_to_say",
                    "frontend": objCandidateProfileStrings.getString("preferNotToSay")
                }
            });

            this.set("genderIdentity", {
                "MALE": {
                    "backend": "male",
                    "frontend": objStrings.getString("male")
                },
                "FEMALE": {
                    "backend": "female",
                    "frontend": objStrings.getString("female")
                },
                "INTERSEX": {
                    "backend": "intersex",
                    "frontend": objCandidateProfileStrings.getString("intersex")
                },
                "TRANSGENDER": {
                    "backend": "transgender",
                    "frontend": objCandidateProfileStrings.getString("transgender")
                },
                "TRANSSEXUAL": {
                    "backend": "transsexual",
                    "frontend": objCandidateProfileStrings.getString("transsexual")
                },
                "PREFER_NOT_TO_SAY": {
                    "backend": "prefer_not_to_say",
                    "frontend": objCandidateProfileStrings.getString("preferNotToSay")
                }
            });

            this.set("languageProficiency", {
                "LIMITED": {
                    "backend": "limited",
                    "frontend": objCandidateProfileStrings.getString("limited")
                },
                "NATIVE": {
                    "backend": "native",
                    "frontend": objCandidateProfileStrings.getString("native")
                },
                "READ_WRITE_SPEAK": {
                    "backend": "read_write_speak",
                    "frontend": objCandidateProfileStrings.getString("readWriteSpeak")
                }
            });

            this.set("transportType", {
                "OWN": {
                    "backend": "own",
                    "frontend": objCandidateProfileStrings.getString("own")
                },
                "PUBLIC": {
                    "backend": "public",
                    "frontend": objCandidateProfileStrings.getString("public")
                }
            });

            this.set("title", {
                "MR": {
                    "backend": "mr",
                    "frontend": objCandidateProfileStrings.getString("mr")
                },
                "MRS": {
                    "backend": "mrs",
                    "frontend": objCandidateProfileStrings.getString("mrs")
                },
                "MS": {
                    "backend": "ms",
                    "frontend": objCandidateProfileStrings.getString("ms")
                },
                "MISS": {
                    "backend": "miss",
                    "frontend": objCandidateProfileStrings.getString("miss")
                },
                "DR": {
                    "backend": "dr",
                    "frontend": objCandidateProfileStrings.getString("dr")
                },
                "PROFESSOR": {
                    "backend": "professor",
                    "frontend": objCandidateProfileStrings.getString("professor")
                },
                "BARON": {
                    "backend": "baron",
                    "frontend": objCandidateProfileStrings.getString("baron")
                },
                "BARONESS": {
                    "backend": "baroness",
                    "frontend": objCandidateProfileStrings.getString("baroness")
                },
                "BRIGADIER": {
                    "backend": "brigadier",
                    "frontend": objCandidateProfileStrings.getString("brigadier")
                },
                "CANON": {
                    "backend": "canon",
                    "frontend": objCandidateProfileStrings.getString("canon")
                },
                "CAPTAIN": {
                    "backend": "captain",
                    "frontend": objCandidateProfileStrings.getString("captain")
                },
                "DUCHESS": {
                    "backend": "duchess",
                    "frontend": objCandidateProfileStrings.getString("duchess")
                },
                "DUKE": {
                    "backend": "duke",
                    "frontend": objCandidateProfileStrings.getString("duke")
                },
                "ESQ": {
                    "backend": "esq",
                    "frontend": objCandidateProfileStrings.getString("esq")
                },
                "FATHER": {
                    "backend": "father",
                    "frontend": objCandidateProfileStrings.getString("father")
                },
                "HON": {
                    "backend": "hon",
                    "frontend": objCandidateProfileStrings.getString("hon")
                },
                "INSPECTOR": {
                    "backend": "inspector",
                    "frontend": objCandidateProfileStrings.getString("inspector")
                },
                "LADY": {
                    "backend": "lady",
                    "frontend": objCandidateProfileStrings.getString("lady")
                },
                "LORD": {
                    "backend": "lord",
                    "frontend": objCandidateProfileStrings.getString("lord")
                },
                "LT_COL": {
                    "backend": "lt_col",
                    "frontend": objCandidateProfileStrings.getString("ltCol")
                },
                "MAJOR": {
                    "backend": "major",
                    "frontend": objCandidateProfileStrings.getString("major")
                },
                "MOST_REVER": {
                    "backend": "most_rever",
                    "frontend": objCandidateProfileStrings.getString("mostRever")
                },
                "PASTOR": {
                    "backend": "pastor",
                    "frontend": objCandidateProfileStrings.getString("pastor")
                },
                "RABBI": {
                    "backend": "rabbi",
                    "frontend": objCandidateProfileStrings.getString("rabbi")
                },
                "REV_DR": {
                    "backend": "rev_dr",
                    "frontend": objCandidateProfileStrings.getString("revDr")
                },
                "REVEREND": {
                    "backend": "reverend",
                    "frontend": objCandidateProfileStrings.getString("reverend")
                },
                "RT_REVEREN": {
                    "backend": "rt_reveren",
                    "frontend": objCandidateProfileStrings.getString("rtReveren")
                },
                "SIR": {
                    "backend": "sir",
                    "frontend": objCandidateProfileStrings.getString("sir")
                },
                "SISTER": {
                    "backend": "sister",
                    "frontend": objCandidateProfileStrings.getString("sister")
                },
                "SQUADRON_L": {
                    "backend": "squadron_l",
                    "frontend": objCandidateProfileStrings.getString("squadronL")
                },
                "WG_CDR": {
                    "backend": "wg_cdr",
                    "frontend": objCandidateProfileStrings.getString("wgCdr")
                }
            });

            this.set("language", {
                "AAR": {
                    "backend": "aar",
                    "frontend": "Afar"
                },
                "ABK": {
                    "backend": "abk",
                    "frontend": "Abkhazian"
                },
                "AFR": {
                    "backend": "afr",
                    "frontend": "Afrikaans"
                },
                "AKA": {
                    "backend": "aka",
                    "frontend": "Akan"
                },
                "ALB": {
                    "backend": "alb",
                    "frontend": "Albanian"
                },
                "AMH": {
                    "backend": "amh",
                    "frontend": "Amharic"
                },
                "ARA": {
                    "backend": "ara",
                    "frontend": "Arabic"
                },
                "ARG": {
                    "backend": "arg",
                    "frontend": "Aragonese"
                },
                "ARM": {
                    "backend": "arm",
                    "frontend": "Armenian"
                },
                "ASM": {
                    "backend": "asm",
                    "frontend": "Assamese"
                },
                "AVA": {
                    "backend": "ava",
                    "frontend": "Avaric"
                },
                "AVE": {
                    "backend": "ave",
                    "frontend": "Avestan"
                },
                "AYM": {
                    "backend": "aym",
                    "frontend": "Aymara"
                },
                "AZE": {
                    "backend": "aze",
                    "frontend": "Azerbaijani"
                },
                "BAK": {
                    "backend": "bak",
                    "frontend": "Bashkir"
                },
                "BAM": {
                    "backend": "bam",
                    "frontend": "Bambara"
                },
                "BAQ": {
                    "backend": "baq",
                    "frontend": "Basque"
                },
                "BEL": {
                    "backend": "bel",
                    "frontend": "Belarusian"
                },
                "BEN": {
                    "backend": "ben",
                    "frontend": "Bengali"
                },
                "BIH": {
                    "backend": "bih",
                    "frontend": "Bihari languages"
                },
                "BIS": {
                    "backend": "bis",
                    "frontend": "Bislama"
                },
                "BOS": {
                    "backend": "bos",
                    "frontend": "Bosnian"
                },
                "BRE": {
                    "backend": "bre",
                    "frontend": "Breton"
                },
                "BUL": {
                    "backend": "bul",
                    "frontend": "Bulgarian"
                },
                "BUR": {
                    "backend": "bur",
                    "frontend": "Burmese"
                },
                "CAT": {
                    "backend": "cat",
                    "frontend": "Catalan; Valencian"
                },
                "CHA": {
                    "backend": "cha",
                    "frontend": "Chamorro"
                },
                "CHE": {
                    "backend": "che",
                    "frontend": "Chechen"
                },
                "CHI": {
                    "backend": "chi",
                    "frontend": "Chinese"
                },
                "CHU": {
                    "backend": "chu",
                    "frontend": "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic"
                },
                "CHV": {
                    "backend": "chv",
                    "frontend": "Chuvash"
                },
                "COR": {
                    "backend": "cor",
                    "frontend": "Cornish"
                },
                "COS": {
                    "backend": "cos",
                    "frontend": "Corsican"
                },
                "CRE": {
                    "backend": "cre",
                    "frontend": "Cree"
                },
                "CZE": {
                    "backend": "cze",
                    "frontend": "Czech"
                },
                "DAN": {
                    "backend": "dan",
                    "frontend": "Danish"
                },
                "DIV": {
                    "backend": "div",
                    "frontend": "Divehi; Dhivehi; Maldivian"
                },
                "DUT": {
                    "backend": "dut",
                    "frontend": "Dutch; Flemish"
                },
                "DZO": {
                    "backend": "dzo",
                    "frontend": "Dzongkha"
                },
                "ENG": {
                    "backend": "eng",
                    "frontend": "English"
                },
                "EPO": {
                    "backend": "epo",
                    "frontend": "Esperanto"
                },
                "EST": {
                    "backend": "est",
                    "frontend": "Estonian"
                },
                "EWE": {
                    "backend": "ewe",
                    "frontend": "Ewe"
                },
                "FAO": {
                    "backend": "fao",
                    "frontend": "Faroese"
                },
                "FIJ": {
                    "backend": "fij",
                    "frontend": "Fijian"
                },
                "FIN": {
                    "backend": "fin",
                    "frontend": "Finnish"
                },
                "FRE": {
                    "backend": "fre",
                    "frontend": "French"
                },
                "FRY": {
                    "backend": "fry",
                    "frontend": "Western Frisian"
                },
                "FUL": {
                    "backend": "ful",
                    "frontend": "Fulah"
                },
                "GEO": {
                    "backend": "geo",
                    "frontend": "Georgian"
                },
                "GER": {
                    "backend": "ger",
                    "frontend": "German"
                },
                "GLA": {
                    "backend": "gla",
                    "frontend": "Gaelic; Scottish Gaelic"
                },
                "GLE": {
                    "backend": "gle",
                    "frontend": "Irish"
                },
                "GLG": {
                    "backend": "glg",
                    "frontend": "Galician"
                },
                "GLV": {
                    "backend": "glv",
                    "frontend": "Manx"
                },
                "GRE": {
                    "backend": "gre",
                    "frontend": "Greek, Modern (1453-)"
                },
                "GRN": {
                    "backend": "grn",
                    "frontend": "Guarani"
                },
                "GUJ": {
                    "backend": "guj",
                    "frontend": "Gujarati"
                },
                "HAT": {
                    "backend": "hat",
                    "frontend": "Haitian; Haitian Creole"
                },
                "HAU": {
                    "backend": "hau",
                    "frontend": "Hausa"
                },
                "HEB": {
                    "backend": "heb",
                    "frontend": "Hebrew"
                },
                "HER": {
                    "backend": "her",
                    "frontend": "Herero"
                },
                "HIN": {
                    "backend": "hin",
                    "frontend": "Hindi"
                },
                "HMO": {
                    "backend": "hmo",
                    "frontend": "Hiri Motu"
                },
                "HRV": {
                    "backend": "hrv",
                    "frontend": "Croatian"
                },
                "HUN": {
                    "backend": "hun",
                    "frontend": "Hungarian"
                },
                "IBO": {
                    "backend": "ibo",
                    "frontend": "Igbo"
                },
                "ICE": {
                    "backend": "ice",
                    "frontend": "Icelandic"
                },
                "IDO": {
                    "backend": "ido",
                    "frontend": "Ido"
                },
                "III": {
                    "backend": "iii",
                    "frontend": "Sichuan Yi; Nuosu"
                },
                "IKU": {
                    "backend": "iku",
                    "frontend": "Inuktitut"
                },
                "ILE": {
                    "backend": "ile",
                    "frontend": "Interlingue; Occidental"
                },
                "INA": {
                    "backend": "ina",
                    "frontend": "Interlingua (International Auxiliary Language Association)"
                },
                "IND": {
                    "backend": "ind",
                    "frontend": "Indonesian"
                },
                "IPK": {
                    "backend": "ipk",
                    "frontend": "Inupiaq"
                },
                "ITA": {
                    "backend": "ita",
                    "frontend": "Italian"
                },
                "JAV": {
                    "backend": "jav",
                    "frontend": "Javanese"
                },
                "JPN": {
                    "backend": "jpn",
                    "frontend": "Japanese"
                },
                "KAL": {
                    "backend": "kal",
                    "frontend": "Kalaallisut; Greenlandic"
                },
                "KAN": {
                    "backend": "kan",
                    "frontend": "Kannada"
                },
                "KAS": {
                    "backend": "kas",
                    "frontend": "Kashmiri"
                },
                "KAU": {
                    "backend": "kau",
                    "frontend": "Kanuri"
                },
                "KAZ": {
                    "backend": "kaz",
                    "frontend": "Kazakh"
                },
                "KHM": {
                    "backend": "khm",
                    "frontend": "Central Khmer"
                },
                "KIK": {
                    "backend": "kik",
                    "frontend": "Kikuyu; Gikuyu"
                },
                "KIN": {
                    "backend": "kin",
                    "frontend": "Kinyarwanda"
                },
                "KIR": {
                    "backend": "kir",
                    "frontend": "Kirghiz; Kyrgyz"
                },
                "KOM": {
                    "backend": "kom",
                    "frontend": "Komi"
                },
                "KON": {
                    "backend": "kon",
                    "frontend": "Kongo"
                },
                "KOR": {
                    "backend": "kor",
                    "frontend": "Korean"
                },
                "KUA": {
                    "backend": "kua",
                    "frontend": "Kuanyama; Kwanyama"
                },
                "KUR": {
                    "backend": "kur",
                    "frontend": "Kurdish"
                },
                "LAO": {
                    "backend": "lao",
                    "frontend": "Lao"
                },
                "LAT": {
                    "backend": "lat",
                    "frontend": "Latin"
                },
                "LAV": {
                    "backend": "lav",
                    "frontend": "Latvian"
                },
                "LIM": {
                    "backend": "lim",
                    "frontend": "Limburgan; Limburger; Limburgish"
                },
                "LIN": {
                    "backend": "lin",
                    "frontend": "Lingala"
                },
                "LIT": {
                    "backend": "lit",
                    "frontend": "Lithuanian"
                },
                "LTZ": {
                    "backend": "ltz",
                    "frontend": "Luxembourgish; Letzeburgesch"
                },
                "LUB": {
                    "backend": "lub",
                    "frontend": "Luba-Katanga"
                },
                "LUG": {
                    "backend": "lug",
                    "frontend": "Ganda"
                },
                "MAC": {
                    "backend": "mac",
                    "frontend": "Macedonian"
                },
                "MAH": {
                    "backend": "mah",
                    "frontend": "Marshallese"
                },
                "MAL": {
                    "backend": "mal",
                    "frontend": "Malayalam"
                },
                "MAO": {
                    "backend": "mao",
                    "frontend": "Maori"
                },
                "MAR": {
                    "backend": "mar",
                    "frontend": "Marathi"
                },
                "MAY": {
                    "backend": "may",
                    "frontend": "Malay"
                },
                "MLG": {
                    "backend": "mlg",
                    "frontend": "Malagasy"
                },
                "MLT": {
                    "backend": "mlt",
                    "frontend": "Maltese"
                },
                "MON": {
                    "backend": "mon",
                    "frontend": "Mongolian"
                },
                "NAU": {
                    "backend": "nau",
                    "frontend": "Nauru"
                },
                "NAV": {
                    "backend": "nav",
                    "frontend": "Navajo; Navaho"
                },
                "NBL": {
                    "backend": "nbl",
                    "frontend": "Ndebele, South; South Ndebele"
                },
                "NDE": {
                    "backend": "nde",
                    "frontend": "Ndebele, North; North Ndebele"
                },
                "NDO": {
                    "backend": "ndo",
                    "frontend": "Ndonga"
                },
                "NEP": {
                    "backend": "nep",
                    "frontend": "Nepali"
                },
                "NNO": {
                    "backend": "nno",
                    "frontend": "Norwegian Nynorsk; Nynorsk, Norwegian"
                },
                "NOB": {
                    "backend": "nob",
                    "frontend": "Bokmål, Norwegian; Norwegian Bokmål"
                },
                "NOR": {
                    "backend": "nor",
                    "frontend": "Norwegian"
                },
                "NYA": {
                    "backend": "nya",
                    "frontend": "Chichewa; Chewa; Nyanja"
                },
                "OCI": {
                    "backend": "oci",
                    "frontend": "Occitan (post 1500); Provençal"
                },
                "OJI": {
                    "backend": "oji",
                    "frontend": "Ojibwa"
                },
                "ORI": {
                    "backend": "ori",
                    "frontend": "Oriya"
                },
                "ORM": {
                    "backend": "orm",
                    "frontend": "Oromo"
                },
                "OSS": {
                    "backend": "oss",
                    "frontend": "Ossetian; Ossetic"
                },
                "PAN": {
                    "backend": "pan",
                    "frontend": "Panjabi; Punjabi"
                },
                "PER": {
                    "backend": "per",
                    "frontend": "Persian"
                },
                "PLI": {
                    "backend": "pli",
                    "frontend": "Pali"
                },
                "POL": {
                    "backend": "pol",
                    "frontend": "Polish"
                },
                "POR": {
                    "backend": "por",
                    "frontend": "Portuguese"
                },
                "PUS": {
                    "backend": "pus",
                    "frontend": "Pushto; Pashto"
                },
                "QUE": {
                    "backend": "que",
                    "frontend": "Quechua"
                },
                "ROH": {
                    "backend": "roh",
                    "frontend": "Romansh"
                },
                "RUM": {
                    "backend": "rum",
                    "frontend": "Romanian; Moldavian; Moldovan"
                },
                "RUN": {
                    "backend": "run",
                    "frontend": "Rundi"
                },
                "RUS": {
                    "backend": "rus",
                    "frontend": "Russian"
                },
                "SAG": {
                    "backend": "sag",
                    "frontend": "Sango"
                },
                "SAN": {
                    "backend": "san",
                    "frontend": "Sanskrit"
                },
                "SGN": {
                    "backend": "sgn",
                    "frontend": "Sign Languages"
                },
                "SIN": {
                    "backend": "sin",
                    "frontend": "Sinhala; Sinhalese"
                },
                "SLO": {
                    "backend": "slo",
                    "frontend": "Slovak"
                },
                "SLV": {
                    "backend": "slv",
                    "frontend": "Slovenian"
                },
                "SME": {
                    "backend": "sme",
                    "frontend": "Northern Sami"
                },
                "SMO": {
                    "backend": "smo",
                    "frontend": "Samoan"
                },
                "SNA": {
                    "backend": "sna",
                    "frontend": "Shona"
                },
                "SND": {
                    "backend": "snd",
                    "frontend": "Sindhi"
                },
                "SOM": {
                    "backend": "som",
                    "frontend": "Somali"
                },
                "SOT": {
                    "backend": "sot",
                    "frontend": "Sotho, Southern"
                },
                "SPA": {
                    "backend": "spa",
                    "frontend": "Spanish; Castilian"
                },
                "SRD": {
                    "backend": "srd",
                    "frontend": "Sardinian"
                },
                "SRP": {
                    "backend": "srp",
                    "frontend": "Serbian"
                },
                "SSW": {
                    "backend": "ssw",
                    "frontend": "Swati"
                },
                "SUN": {
                    "backend": "sun",
                    "frontend": "Sundanese"
                },
                "SWA": {
                    "backend": "swa",
                    "frontend": "Swahili"
                },
                "SWE": {
                    "backend": "swe",
                    "frontend": "Swedish"
                },
                "TAH": {
                    "backend": "tah",
                    "frontend": "Tahitian"
                },
                "TAM": {
                    "backend": "tam",
                    "frontend": "Tamil"
                },
                "TAT": {
                    "backend": "tat",
                    "frontend": "Tatar"
                },
                "TEL": {
                    "backend": "tel",
                    "frontend": "Telugu"
                },
                "TGK": {
                    "backend": "tgk",
                    "frontend": "Tajik"
                },
                "TGL": {
                    "backend": "tgl",
                    "frontend": "Tagalog"
                },
                "THA": {
                    "backend": "tha",
                    "frontend": "Thai"
                },
                "TIB": {
                    "backend": "tib",
                    "frontend": "Tibetan"
                },
                "TIR": {
                    "backend": "tir",
                    "frontend": "Tigrinya"
                },
                "TON": {
                    "backend": "ton",
                    "frontend": "Tonga (Tonga Islands)"
                },
                "TSN": {
                    "backend": "tsn",
                    "frontend": "Tswana"
                },
                "TSO": {
                    "backend": "tso",
                    "frontend": "Tsonga"
                },
                "TUK": {
                    "backend": "tuk",
                    "frontend": "Turkmen"
                },
                "TUR": {
                    "backend": "tur",
                    "frontend": "Turkish"
                },
                "TWI": {
                    "backend": "twi",
                    "frontend": "Twi"
                },
                "UIG": {
                    "backend": "uig",
                    "frontend": "Uighur; Uyghur"
                },
                "UKR": {
                    "backend": "ukr",
                    "frontend": "Ukrainian"
                },
                "URD": {
                    "backend": "urd",
                    "frontend": "Urdu"
                },
                "UZB": {
                    "backend": "uzb",
                    "frontend": "Uzbek"
                },
                "VEN": {
                    "backend": "ven",
                    "frontend": "Venda"
                },
                "VIE": {
                    "backend": "vie",
                    "frontend": "Vietnamese"
                },
                "VOL": {
                    "backend": "vol",
                    "frontend": "Volapük"
                },
                "WEL": {
                    "backend": "wel",
                    "frontend": "Welsh"
                },
                "WLN": {
                    "backend": "wln",
                    "frontend": "Walloon"
                },
                "WOL": {
                    "backend": "wol",
                    "frontend": "Wolof"
                },
                "XHO": {
                    "backend": "xho",
                    "frontend": "Xhosa"
                },
                "YID": {
                    "backend": "yid",
                    "frontend": "Yiddish"
                },
                "YOR": {
                    "backend": "yor",
                    "frontend": "Yoruba"
                },
                "ZHA": {
                    "backend": "zha",
                    "frontend": "Zhuang; Chuang"
                },
                "ZUL": {
                    "backend": "zul",
                    "frontend": "Zulu"
                }
            });

            this.set("dbsStatuses", {
                "COMPLETINGFORM": {
                    "backend": "completingForm",
                    "frontend": objCandidateProfileStrings.getString("completingForm")
                },
                "REQUIRESPAYMENT": {
                    "backend": "requiresPayment",
                    "frontend": objCandidateProfileStrings.getString("requiresPayment")
                },
                "REQUIRESCOUNTERSIGNERACTION": {
                    "backend": "requiresCountersignerAction",
                    "frontend": objCandidateProfileStrings.getString("requiresCountersignerAction")
                },
                "UNORDERED": {
                    "backend": "unordered",
                    "frontend": objCandidateProfileStrings.getString("unordered")
                },
                "ORDERED": {
                    "backend": "ordered",
                    "frontend": objCandidateProfileStrings.getString("ordered")
                },
                "RECEIPTED": {
                    "backend": "receipted",
                    "frontend": objCandidateProfileStrings.getString("receipted")
                },
                "INVALID": {
                    "backend": "invalid",
                    "frontend": objCandidateProfileStrings.getString("invalid")
                },
                "PASSED": {
                    "backend": "passed",
                    "frontend": objCandidateProfileStrings.getString("passed")
                },
                "MISSINGDOCUMENTS": {
                    "backend": "missingDocuments",
                    "frontend": objCandidateProfileStrings.getString("missingDocuments")
                },
                "REQUIRESCANDIDATEACTION": {
                    "backend": "requiresCandidateAction",
                    "frontend": objCandidateProfileStrings.getString("requiresCandidateAction")
                },
                "CANDIDATEACTIONED": {
                    "backend": "candidateActioned",
                    "frontend": objCandidateProfileStrings.getString("candidateActioned")
                },
                "NOCRB": {
                    "backend": "noCrb",
                    "frontend": objCandidateProfileStrings.getString("noCrb")
                },
                "EXPIRING": {
                    "backend": "expiring",
                    "frontend": objCandidateProfileStrings.getString("expiring")
                },
                "EXPIRED": {
                    "backend": "expired",
                    "frontend": objCandidateProfileStrings.getString("expired")
                },
                "COUNTERSIGNED": {
                    "backend": "counterSigned",
                    "frontend": objCandidateProfileStrings.getString("counterSigned")
                },
                "NOTREQUIRED": {
                    "backend": "notRequired",
                    "frontend": objCandidateProfileStrings.getString("notRequired")
                },
                "NEWAPPLICATION": {
                    "backend": "newApplication",
                    "frontend": objCandidateProfileStrings.getString("newApplication")
                },
                "NODISCLAIMER": {
                    "backend": "noDisclaimer",
                    "frontend": objCandidateProfileStrings.getString("noDisclaimer")
                },
                "NOLANGUAGE": {
                    "backend": "noLanguage",
                    "frontend": objCandidateProfileStrings.getString("noLanguage")
                },
                "MANUALAPPLICATION": {
                    "backend": "manualApplication",
                    "frontend": objCandidateProfileStrings.getString("manualApplication")
                },
                "A24REJECTED": {
                    "backend": "a24Rejected",
                    "frontend": objCandidateProfileStrings.getString("a24Rejected")
                },
                "A24REJECTEDNOTREVERSED": {
                    "backend": "a24RejectedNotReversed",
                    "frontend": objCandidateProfileStrings.getString("a24RejectedNotReversed")
                },
                "VALIDATEDFORM": {
                    "backend": "validatedForm",
                    "frontend": objCandidateProfileStrings.getString("validatedForm")
                }
            });

            this.set("workTypes", {
                "AGENCY": {
                    "backend": "agency",
                    "frontend": objCandidateProfileStrings.getString("agencyWork")
                },
                "CONTRACT": {
                    "backend": "contract",
                    "frontend": objCandidateProfileStrings.getString("contract")
                },
                "PART_TIME": {
                    "backend": "part_time",
                    "frontend": objCandidateProfileStrings.getString("partTime")
                },
                "PERMANENT": {
                    "backend": "permanent",
                    "frontend": objCandidateProfileStrings.getString("permanent")
                },
                "SELF_EMPLOYED": {
                    "backend": "self_employed",
                    "frontend": objCandidateProfileStrings.getString("selfEmployed")
                },
                "STUDENT_FULL_TIME": {
                    "backend": "student_full_time",
                    "frontend": objCandidateProfileStrings.getString("studentFullTime")
                },
                "STUDENT_PLACEMENT": {
                    "backend": "student_placement",
                    "frontend": objCandidateProfileStrings.getString("studentPlacement")
                },
                "UNKNOWN": {
                    "backend": "unknown",
                    "frontend": objCandidateProfileStrings.getString("unknown")
                },
                "UNEMPLOYED": {
                    "backend": "not_employed",
                    "frontend": objCandidateProfileStrings.getString("unemployed")
                }
            });

            this.set("workTypesPost", {
                "AGENCY": {
                    "backend": "agency",
                    "frontend": objCandidateProfileStrings.getString("agencyWork")
                },
                "CONTRACT": {
                    "backend": "contract",
                    "frontend": objCandidateProfileStrings.getString("contract")
                },
                "PART_TIME": {
                    "backend": "part_time",
                    "frontend": objCandidateProfileStrings.getString("partTime")
                },
                "PERMANENT": {
                    "backend": "permanent",
                    "frontend": objCandidateProfileStrings.getString("permanent")
                },
                "SELF_EMPLOYED": {
                    "backend": "self_employed",
                    "frontend": objCandidateProfileStrings.getString("selfEmployed")
                },
                "STUDENT_FULL_TIME": {
                    "backend": "student_full_time",
                    "frontend": objCandidateProfileStrings.getString("studentFullTime")
                },
                "STUDENT_PLACEMENT": {
                    "backend": "student_placement",
                    "frontend": objCandidateProfileStrings.getString("studentPlacement")
                },
                "UNEMPLOYED": {
                    "backend": "not_employed",
                    "frontend": objCandidateProfileStrings.getString("unemployed")
                }
            });

            this.set("saProvinces", {
                "EASTERN_CAPE": {
                    "backend": "Eastern Cape",
                    "frontend": "Eastern Cape"
                },
                "FREE_STATE": {
                    "backend": "Free State",
                    "frontend": "Free State"
                },
                "GAUTENG": {
                    "backend": "Gauteng",
                    "frontend": "Gauteng"
                },
                "KWAZULU_NATAL": {
                    "backend": "Kwazulu-Natal",
                    "frontend": "Kwazulu-Natal"
                },
                "LIMPOPO": {
                    "backend": "Limpopo",
                    "frontend": "Limpopo"
                },
                "MPUMALANGA": {
                    "backend": "Mpumalanga",
                    "frontend": "Mpumalanga"
                },
                "NORTH_WEST_PROVINCE": {
                    "backend": "North West Province",
                    "frontend": "North West Province"
                },
                "NORTHERN_CAPE": {
                    "backend": "Northern Cape",
                    "frontend": "Northern Cape"
                },
                "WESTERN_CAPE": {
                    "backend": "Western Cape",
                    "frontend": "Western Cape"
                }
            });

            this.set("ukProvinces", {
                "ABERDEENSHIRE": {
                    "backend": "Aberdeenshire",
                    "frontend": "Aberdeenshire"
                },
                "ANGLESEY_SIR_FON": {
                    "backend": "Anglesey/Sir Fon",
                    "frontend": "Anglesey/Sir Fon"
                },
                "ANGUS_FORFARSHIRE": {
                    "backend": "Angus/Forfarshire",
                    "frontend": "Angus/Forfarshire"
                },
                "ARGYLLSHIRE": {
                    "backend": "Argyllshire",
                    "frontend": "Argyllshire"
                },
                "AYRSHIRE": {
                    "backend": "Ayrshire",
                    "frontend": "Ayrshire"
                },
                "BALLYMENA": {
                    "backend": "Ballymena",
                    "frontend": "Ballymena"
                },
                "BANFFSHIRE": {
                    "backend": "Banffshire",
                    "frontend": "Banffshire"
                },
                "BEDFORDSHIRE": {
                    "backend": "Bedfordshire",
                    "frontend": "Bedfordshire"
                },
                "BELFAST": {
                    "backend": "Belfast",
                    "frontend": "Belfast"
                },
                "BERKSHIRE": {
                    "backend": "Berkshire",
                    "frontend": "Berkshire"
                },
                "BERWICKSHIRE": {
                    "backend": "Berwickshire",
                    "frontend": "Berwickshire"
                },
                "BRECKNOCKSHIRE_SIR_FRYCHEINIOG": {
                    "backend": "Brecknockshire/Sir Frycheiniog",
                    "frontend": "Brecknockshire/Sir Frycheiniog"
                },
                "BRISTOL": {
                    "backend": "Bristol",
                    "frontend": "Bristol"
                },
                "BUCKINGHAMSHIRE": {
                    "backend": "Buckinghamshire",
                    "frontend": "Buckinghamshire"
                },
                "BUTESHIRE": {
                    "backend": "Buteshire",
                    "frontend": "Buteshire"
                },
                "CAERNARFONSHIRE_SIR_GAERNARFON": {
                    "backend": "Caernarfonshire/Sir Gaernarfon",
                    "frontend": "Caernarfonshire/Sir Gaernarfon"
                },
                "CAITHNESS": {
                    "backend": "Caithness",
                    "frontend": "Caithness"
                },
                "CAMBRIDGESHIRE": {
                    "backend": "Cambridgeshire",
                    "frontend": "Cambridgeshire"
                },
                "CARDIGANSHIRE_CEREDIGION": {
                    "backend": "Cardiganshire/Ceredigion",
                    "frontend": "Cardiganshire/Ceredigion"
                },
                "CARMARTHENSHIRE": {
                    "backend": "Carmarthenshire",
                    "frontend": "Carmarthenshire"
                },
                "CHANNEL_ISLANDS": {
                    "backend": "Channel Islands",
                    "frontend": "Channel Islands"
                },
                "CHESHIRE": {
                    "backend": "Cheshire",
                    "frontend": "Cheshire"
                },
                "CLACKMANNANSHIRE": {
                    "backend": "Clackmannanshire",
                    "frontend": "Clackmannanshire"
                },
                "COLERAINE": {
                    "backend": "Coleraine",
                    "frontend": "Coleraine"
                },
                "COOKSTOWN": {
                    "backend": "Cookstown",
                    "frontend": "Cookstown"
                },
                "CORNWALL": {
                    "backend": "Cornwall",
                    "frontend": "Cornwall"
                },
                "COUNTY_ANTRIM": {
                    "backend": "County Antrim",
                    "frontend": "County Antrim"
                },
                "COUNTY_ARMAGH": {
                    "backend": "County Armagh",
                    "frontend": "County Armagh"
                },
                "COUNTY_DOWN": {
                    "backend": "County Down",
                    "frontend": "County Down"
                },
                "COUNTY_FERMANAGH": {
                    "backend": "County Fermanagh",
                    "frontend": "County Fermanagh"
                },
                "COUNTY_LONDONDERRY": {
                    "backend": "County Londonderry",
                    "frontend": "County Londonderry"
                },
                "COUNTY_TYRONE": {
                    "backend": "County Tyrone",
                    "frontend": "County Tyrone"
                },
                "CRAIGAVON": {
                    "backend": "Craigavon",
                    "frontend": "Craigavon"
                },
                "CROMARTYSHIRE": {
                    "backend": "Cromartyshire",
                    "frontend": "Cromartyshire"
                },
                "CUMBRIA": {
                    "backend": "Cumbria",
                    "frontend": "Cumbria"
                },
                "DENBIGHSHIRE_SIR_DDINBYCH": {
                    "backend": "Denbighshire/Sir Ddinbych",
                    "frontend": "Denbighshire/Sir Ddinbych"
                },
                "DERBYSHIRE": {
                    "backend": "Derbyshire",
                    "frontend": "Derbyshire"
                },
                "DERRY_CITY_AND_SURROUNDS": {
                    "backend": "Derry City and Surrounds",
                    "frontend": "Derry City and Surrounds"
                },
                "DEVON": {
                    "backend": "Devon",
                    "frontend": "Devon"
                },
                "DORSET": {
                    "backend": "Dorset",
                    "frontend": "Dorset"
                },
                "DOWN": {
                    "backend": "Down",
                    "frontend": "Down"
                },
                "DUMFRIESSHIRE": {
                    "backend": "Dumfriesshire",
                    "frontend": "Dumfriesshire"
                },
                "DUNBARTONSHIRE_DUMBARTONSHIRE": {
                    "backend": "Dunbartonshire/Dumbartonshire",
                    "frontend": "Dunbartonshire/Dumbartonshire"
                },
                "DUNDEE": {
                    "backend": "Dundee",
                    "frontend": "Dundee"
                },
                "DUNGANNON_AND_SOUTH_TYRONE": {
                    "backend": "Dungannon and South Tyrone",
                    "frontend": "Dungannon and South Tyrone"
                },
                "DURHAM": {
                    "backend": "Durham",
                    "frontend": "Durham"
                },
                "EAST_LOTHIAN_HADDINGTONSHIRE": {
                    "backend": "East Lothian/Haddingtonshire",
                    "frontend": "East Lothian/Haddingtonshire"
                },
                "EAST_RIDING_OF_YORKSHIRE": {
                    "backend": "East Riding of Yorkshire",
                    "frontend": "East Riding of Yorkshire"
                },
                "EAST_SUSSEX": {
                    "backend": "East Sussex",
                    "frontend": "East Sussex"
                },
                "EDINBURGH": {
                    "backend": "Edinburgh",
                    "frontend": "Edinburgh"
                },
                "ESSEX": {
                    "backend": "Essex",
                    "frontend": "Essex"
                },
                "FALKIRK": {
                    "backend": "Falkirk",
                    "frontend": "Falkirk"
                },
                "FERMANAGH": {
                    "backend": "Fermanagh",
                    "frontend": "Fermanagh"
                },
                "FIFE": {
                    "backend": "Fife",
                    "frontend": "Fife"
                },
                "FLINTSHIRE_SIR_FFLINT": {
                    "backend": "Flintshire/Sir Fflint",
                    "frontend": "Flintshire/Sir Fflint"
                },
                "GLAMORGAN_MORGANNWG": {
                    "backend": "Glamorgan/Morgannwg",
                    "frontend": "Glamorgan/Morgannwg"
                },
                "GLASGOW": {
                    "backend": "Glasgow",
                    "frontend": "Glasgow"
                },
                "GLOUCESTERSHIRE": {
                    "backend": "Gloucestershire",
                    "frontend": "Gloucestershire"
                },
                "GREATER_LONDON": {
                    "backend": "Greater London",
                    "frontend": "Greater London"
                },
                "GREATER_MANCHESTER": {
                    "backend": "Greater Manchester",
                    "frontend": "Greater Manchester"
                },
                "GWENT": {
                    "backend": "Gwent",
                    "frontend": "Gwent"
                },
                "HAMPSHIRE": {
                    "backend": "Hampshire",
                    "frontend": "Hampshire"
                },
                "HEREFORDSHIRE": {
                    "backend": "Herefordshire",
                    "frontend": "Herefordshire"
                },
                "HERTFORDSHIRE": {
                    "backend": "Hertfordshire",
                    "frontend": "Hertfordshire"
                },
                "HUNTINGDONSHIRE": {
                    "backend": "Huntingdonshire",
                    "frontend": "Huntingdonshire"
                },
                "INVERNES_SSHIRE": {
                    "backend": "Inverness-shire",
                    "frontend": "Inverness-shire"
                },
                "ISLE_OF_WIGHT": {
                    "backend": "Isle of Wight",
                    "frontend": "Isle of Wight"
                },
                "KENT": {
                    "backend": "Kent",
                    "frontend": "Kent"
                },
                "KINCARDINESHIRE": {
                    "backend": "Kincardineshire",
                    "frontend": "Kincardineshire"
                },
                "KINROSS_SHIRE": {
                    "backend": "Kinross-shire",
                    "frontend": "Kinross-shire"
                },
                "KIRKCUDBRIGHTSHIRE": {
                    "backend": "Kirkcudbrightshire",
                    "frontend": "Kirkcudbrightshire"
                },
                "KIRKWALL": {
                    "backend": "Kirkwall",
                    "frontend": "Kirkwall"
                },
                "LANARKSHIRE": {
                    "backend": "Lanarkshire",
                    "frontend": "Lanarkshire"
                },
                "LANCASHIRE": {
                    "backend": "Lancashire",
                    "frontend": "Lancashire"
                },
                "LARNE": {
                    "backend": "Larne",
                    "frontend": "Larne"
                },
                "LEICESTERSHIRE": {
                    "backend": "Leicestershire",
                    "frontend": "Leicestershire"
                },
                "LINCOLNSHIRE": {
                    "backend": "Lincolnshire",
                    "frontend": "Lincolnshire"
                },
                "MERIONETH_MEIRIONNYDD": {
                    "backend": "Merioneth/Meirionnydd",
                    "frontend": "Merioneth/Meirionnydd"
                },
                "MERSEYSIDE": {
                    "backend": "Merseyside",
                    "frontend": "Merseyside"
                },
                "MIDDLESEX": {
                    "backend": "Middlesex",
                    "frontend": "Middlesex"
                },
                "MIDLOTHIAN_EDINBURGHSHIRE": {
                    "backend": "Midlothian/Edinburghshire",
                    "frontend": "Midlothian/Edinburghshire"
                },
                "MONMOUTHSHIRE_SIR_FYNWY": {
                    "backend": "Monmouthshire/Sir Fynwy",
                    "frontend": "Monmouthshire/Sir Fynwy"
                },
                "MONTGOMERYSHIRE_SIR_DREFALDWYN": {
                    "backend": "Montgomeryshire/Sir Drefaldwyn",
                    "frontend": "Montgomeryshire/Sir Drefaldwyn"
                },
                "MORAYSHIRE": {
                    "backend": "Morayshire",
                    "frontend": "Morayshire"
                },
                "MOTHERWELL": {
                    "backend": "Motherwell",
                    "frontend": "Motherwell"
                },
                "NAIRNSHIRE": {
                    "backend": "Nairnshire",
                    "frontend": "Nairnshire"
                },
                "NEWRY_AND_MOURNE": {
                    "backend": "Newry and Mourne",
                    "frontend": "Newry and Mourne"
                },
                "NORFOLK": {
                    "backend": "Norfolk",
                    "frontend": "Norfolk"
                },
                "NORTH_DOWN_AND_ARDS": {
                    "backend": "North Down and Ards",
                    "frontend": "North Down and Ards"
                },
                "NORTH_YORKSHIRE": {
                    "backend": "North Yorkshire",
                    "frontend": "North Yorkshire"
                },
                "NORTHAMPTONSHIRE": {
                    "backend": "Northamptonshire",
                    "frontend": "Northamptonshire"
                },
                "NORTHUMBERLAND": {
                    "backend": "Northumberland",
                    "frontend": "Northumberland"
                },
                "NOTTINGHAMSHIRE": {
                    "backend": "Nottinghamshire",
                    "frontend": "Nottinghamshire"
                },
                "OMAGH": {
                    "backend": "Omagh",
                    "frontend": "Omagh"
                },
                "ORKNEY": {
                    "backend": "Orkney",
                    "frontend": "Orkney"
                },
                "OXFORDSHIRE": {
                    "backend": "Oxfordshire",
                    "frontend": "Oxfordshire"
                },
                "PAISLEY": {
                    "backend": "Paisley",
                    "frontend": "Paisley"
                },
                "PEEBLESSHIRE": {
                    "backend": "Peeblesshire",
                    "frontend": "Peeblesshire"
                },
                "PERTHSHIRE": {
                    "backend": "Perthshire",
                    "frontend": "Perthshire"
                },
                "RADNORSHIRE_SIR_FAESYFED": {
                    "backend": "Radnorshire/Sir Faesyfed",
                    "frontend": "Radnorshire/Sir Faesyfed"
                },
                "RENFREWSHIRE": {
                    "backend": "Renfrewshire",
                    "frontend": "Renfrewshire"
                },
                "ROSSSHIRE": {
                    "backend": "Ross-shire",
                    "frontend": "Ross-shire"
                },
                "ROXBURGHSHIRE": {
                    "backend": "Roxburghshire",
                    "frontend": "Roxburghshire"
                },
                "RUTLAND": {
                    "backend": "Rutland",
                    "frontend": "Rutland"
                },
                "SELKIRKSHIRE": {
                    "backend": "Selkirkshire",
                    "frontend": "Selkirkshire"
                },
                "SHETLAND": {
                    "backend": "Shetland",
                    "frontend": "Shetland"
                },
                "SHROPSHIRE": {
                    "backend": "Shropshire",
                    "frontend": "Shropshire"
                },
                "SOMERSET": {
                    "backend": "Somerset",
                    "frontend": "Somerset"
                },
                "SOUTH_YORKSHIRE": {
                    "backend": "South Yorkshire",
                    "frontend": "South Yorkshire"
                },
                "STAFFORDSHIRE": {
                    "backend": "Staffordshire",
                    "frontend": "Staffordshire"
                },
                "STIRLINGSHIRE": {
                    "backend": "Stirlingshire",
                    "frontend": "Stirlingshire"
                },
                "SUFFOLK": {
                    "backend": "Suffolk",
                    "frontend": "Suffolk"
                },
                "SURREY": {
                    "backend": "Surrey",
                    "frontend": "Surrey"
                },
                "SUTHERLAND": {
                    "backend": "Sutherland",
                    "frontend": "Sutherland"
                },
                "TYNE_AND_WEAR": {
                    "backend": "Tyne and Wear",
                    "frontend": "Tyne and Wear"
                },
                "UNKNOWNCOUNTY": {
                    "backend": "UnknownCounty",
                    "frontend": "UnknownCounty"
                },
                "WARWICKSHIRE": {
                    "backend": "Warwickshire",
                    "frontend": "Warwickshire"
                },
                "WEST_LOTHIAN_LINLITHGOWSHIRE": {
                    "backend": "West Lothian/Linlithgowshire",
                    "frontend": "West Lothian/Linlithgowshire"
                },
                "WEST_MIDLANDS": {
                    "backend": "West Midlands",
                    "frontend": "West Midlands"
                },
                "WEST_SUSSEX": {
                    "backend": "West Sussex",
                    "frontend": "West Sussex"
                },
                "WEST_YORKSHIRE": {
                    "backend": "West Yorkshire",
                    "frontend": "West Yorkshire"
                },
                "WESTMORLAND": {
                    "backend": "Westmorland",
                    "frontend": "Westmorland"
                },
                "WIGTOWNSHIRE": {
                    "backend": "Wigtownshire",
                    "frontend": "Wigtownshire"
                },
                "WILTSHIRE": {
                    "backend": "Wiltshire",
                    "frontend": "Wiltshire"
                },
                "WORCESTERSHIRE": {
                    "backend": "Worcestershire",
                    "frontend": "Worcestershire"
                }
            });

            this.set("usProvinces", {
                "ALABAMA": {
                    "backend": "Alabama",
                    "frontend": "Alabama"
                },
                "ALASKA": {
                    "backend": "Alaska",
                    "frontend": "Alaska"
                },
                "ARIZONA": {
                    "backend": "Arizona",
                    "frontend": "Arizona"
                },
                "ARKANSAS": {
                    "backend": "Arkansas",
                    "frontend": "Arkansas"
                },
                "CALIFORNIA": {
                    "backend": "California",
                    "frontend": "California"
                },
                "COLORADO": {
                    "backend": "Colorado",
                    "frontend": "Colorado"
                },
                "CONNECTICUT": {
                    "backend": "Connecticut",
                    "frontend": "Connecticut"
                },
                "DELAWARE": {
                    "backend": "Delaware",
                    "frontend": "Delaware"
                },
                "FLORIDA": {
                    "backend": "Florida",
                    "frontend": "Florida"
                },
                "GEORGIA": {
                    "backend": "Georgia",
                    "frontend": "Georgia"
                },
                "HAWAII": {
                    "backend": "Hawaii",
                    "frontend": "Hawaii"
                },
                "IDAHO": {
                    "backend": "Idaho",
                    "frontend": "Idaho"
                },
                "ILLINOIS": {
                    "backend": "Illinois",
                    "frontend": "Illinois"
                },
                "INDIANA": {
                    "backend": "Indiana",
                    "frontend": "Indiana"
                },
                "IOWA": {
                    "backend": "Iowa",
                    "frontend": "Iowa"
                },
                "KANSAS": {
                    "backend": "Kansas",
                    "frontend": "Kansas"
                },
                "KENTUCKY": {
                    "backend": "Kentucky",
                    "frontend": "Kentucky"
                },
                "LOUISIANA": {
                    "backend": "Louisiana",
                    "frontend": "Louisiana"
                },
                "MAINE": {
                    "backend": "Maine",
                    "frontend": "Maine"
                },
                "MARYLAND": {
                    "backend": "Maryland",
                    "frontend": "Maryland"
                },
                "MASSACHUSETTS": {
                    "backend": "Massachusetts",
                    "frontend": "Massachusetts"
                },
                "MICHIGAN": {
                    "backend": "Michigan",
                    "frontend": "Michigan"
                },
                "MINNESOTA": {
                    "backend": "Minnesota",
                    "frontend": "Minnesota"
                },
                "MISSISSIPPI": {
                    "backend": "Mississippi",
                    "frontend": "Mississippi"
                },
                "MISSOURI": {
                    "backend": "Missouri",
                    "frontend": "Missouri"
                },
                "MONTANA": {
                    "backend": "Montana",
                    "frontend": "Montana"
                },
                "NEBRASKA": {
                    "backend": "Nebraska",
                    "frontend": "Nebraska"
                },
                "NEVADA": {
                    "backend": "Nevada",
                    "frontend": "Nevada"
                },
                "NEW_HAMPSHIRE": {
                    "backend": "New Hampshire",
                    "frontend": "New Hampshire"
                },
                "NEW_JERSEY": {
                    "backend": "New Jersey",
                    "frontend": "New Jersey"
                },
                "NEW_MEXICO": {
                    "backend": "New Mexico",
                    "frontend": "New Mexico"
                },
                "NEW_YORK": {
                    "backend": "New York",
                    "frontend": "New York"
                },
                "NORTH_CAROLINA": {
                    "backend": "North Carolina",
                    "frontend": "North Carolina"
                },
                "NORTH_DAKOTA": {
                    "backend": "North Dakota",
                    "frontend": "North Dakota"
                },
                "OHIO": {
                    "backend": "Ohio",
                    "frontend": "Ohio"
                },
                "OKLAHOMA": {
                    "backend": "Oklahoma",
                    "frontend": "Oklahoma"
                },
                "OREGON": {
                    "backend": "Oregon",
                    "frontend": "Oregon"
                },
                "PENNSYLVANIA": {
                    "backend": "Pennsylvania",
                    "frontend": "Pennsylvania"
                },
                "RHODE_ISLAND": {
                    "backend": "Rhode Island",
                    "frontend": "Rhode Island"
                },
                "SOUTH_CAROLINA": {
                    "backend": "South Carolina",
                    "frontend": "South Carolina"
                },
                "SOUTH_DAKOTA": {
                    "backend": "South Dakota",
                    "frontend": "South Dakota"
                },
                "TENNESSEE": {
                    "backend": "Tennessee",
                    "frontend": "Tennessee"
                },
                "TEXAS": {
                    "backend": "Texas",
                    "frontend": "Texas"
                },
                "UTAH": {
                    "backend": "Utah",
                    "frontend": "Utah"
                },
                "VERMONT": {
                    "backend": "Vermont",
                    "frontend": "Vermont"
                },
                "VIRGINIA": {
                    "backend": "Virginia",
                    "frontend": "Virginia"
                },
                "WASHINGTON": {
                    "backend": "Washington",
                    "frontend": "Washington"
                },
                "WEST_VIRGINIA": {
                    "backend": "West Virginia",
                    "frontend": "West Virginia"
                },
                "WISCONSIN": {
                    "backend": "Wisconsin",
                    "frontend": "Wisconsin"
                },
                "WYOMING": {
                    "backend": "Wyoming",
                    "frontend": "Wyoming"
                }
            });

            this.set("mediumTypeDnc", {
                "EMAIL": {
                    "backend": "email_dnc",
                    "frontend": objStrings.getString("email")
                },
                "SMS": {
                    "backend": "sms_dnc",
                    "frontend": objSSCoreStringsService.getString("sms")
                },
                "WHATSAPP": {
                    "backend": "whatsapp_dnc",
                    "frontend": objSSCoreStringsService.getString("whatsapp")
                },
                "CALL": {
                    "backend": "call_dnc",
                    "frontend": objCandidateProfileStrings.getString("phoneCall")
                },
                "PUSH_NOTIFICATION": {
                    "backend": "push_notification_dnc",
                    "frontend": objCandidateProfileStrings.getString("pushNotification")
                }
            });

            this.set("dbsWorforce", {
                "ADULT": {
                    "backend": "workforce_adult",
                    "frontend": objCandidateProfileStrings.getString("workforceAdult")
                },
                "CHILD": {
                    "backend": "workforce_child",
                    "frontend": objCandidateProfileStrings.getString("workforceChild")
                },
                "ADULT_AND_CHILD": {
                    "backend": "workforce_child_adult",
                    "frontend": objCandidateProfileStrings.getString("workforceChildAndAdult")
                },
                "OTHER": {
                    "backend": "workforce_other",
                    "frontend": objCandidateProfileStrings.getString("workforceOther")
                },
                "NONE": {
                    "backend": "workforce_none",
                    "frontend": objCandidateProfileStrings.getString("workforceNone")
                }
            });

            this.set("severity", {
                "MINOR": {
                    "backend": "minor",
                    "frontend": objCandidateProfileStrings.getString("minor")
                },
                "SERIOUS": {
                    "backend": "serious",
                    "frontend": objCandidateProfileStrings.getString("serious")
                },
                "critical": {
                    "backend": "critical",
                    "frontend": objCandidateProfileStrings.getString("critical")
                }
            });

            this.set("clientType", {
                "ORGANISATION": {
                    "backend": "organisation",
                    "frontend": objStrings.getString("organisation")
                },
                "SITE": {
                    "backend": "site",
                    "frontend": objCandidateProfileStrings.getString("site")
                },
                "WARD": {
                    "backend": "ward",
                    "frontend": objCandidateProfileStrings.getString("ward")
                }
            });

            this.set("appliesTo", {
                "ORGANISATIONSTRUCTURE": {
                    "backend": "OrganisationStructure",
                    "frontend": objCandidateProfileStrings.getString("organisationStructure")
                },
                "SITESTRUCTURE": {
                    "backend": "SiteStructure",
                    "frontend": objCandidateProfileStrings.getString("siteStructure")
                },
                "SITESPECIFIC": {
                    "backend": "SiteSpecific",
                    "frontend": objCandidateProfileStrings.getString("siteSpecific")
                },
                "WARDSPECIFIC": {
                    "backend": "WardSpecific",
                    "frontend": objCandidateProfileStrings.getString("wardSpecific")
                }
            });
        }
    });
});