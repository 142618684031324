define("a24-ember-staffshift-core/helpers/inline-service-loader", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.inlineServiceLoader = inlineServiceLoader;


  /**
   * Return a service directly in the hbs
   *
   * @param arrParams - The array of params sent to the helper
   *
   * @author Michael Barnard <michael.barnard@a24group.com>
   * @since  16 November 2017
   */
  function inlineServiceLoader(arrParams) {
    return Ember.inject.service(arrParams[0]);
  }

  exports.default = Ember.Helper.helper(inlineServiceLoader);
});