define("a24-ember-staffshift-candidate-availability/components/calendar-card", ["exports", "a24-ember-staffshift-candidate-availability/templates/components/calendar-card", "a24-ember-lib/components/card-style-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _calendarCard, _cardStyleComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _cardStyleComponent.default.extend(_didRenderChangesMixin.default, {
        /*
          Default values
        */
        layout: _calendarCard.default,
        classNames: ["padding-top-double", "padding-bottom-double", "calendar-card"],

        /*
          @property arrActions
          An array of objects each specifying their google material icon name and an action that is invoked when the icon is clicked on.
        */
        arrActions: null,

        /*
          @property iYear
          The year of the month being displayed.
        */
        iYear: null,

        /*
          @property iMonth
          The month (0-11) the component should display.
        */
        iMonth: null,

        // Services
        _objUserSession: Ember.inject.service("user-session"),
        _objConstantsEmberLibService: Ember.inject.service("a24-constants-ember-lib"),

        /*
          @property iOffset
          The number of days the week days will be offset by.
        */
        iOffset: 0,

        /*
          @property bLoading
          It is true when the month days data is loading.
        */
        bLoading: false,

        /*
          @property bShowWeekHeading
          It is true when the week heading is displayed.
        */
        bShowWeekHeading: true,

        /*
          @property objMonthDays
          The list of days data that will be displayed for the specified month.
        */
        objMonthDays: null,

        objMonthTwoDays: null,

        objMonthThreeDays: null,

        /*
          @property objPrevMonthDays
          The list of days data that will be displayed for the previous month.
        */
        objPrevMonthDays: null,

        /*
          @property objNextMonthDays
          The list of days data that will be displayed for the next month.
        */
        objNextMonthDays: null,

        /*
          @property onDayAction
          A generic action that is triggered in the event of any action on a day component. The first argument is the type (String) of the action followed by any number of arguments.
        */
        onDayAction: null,

        /*
          @property sDayComponent
          The name of the component that will render inside each day block of the current month.
        */
        sDayComponent: "calendar-day",

        bShowPreview: false,

        /*
          Inline computed properties
        */
        /*
          @property sPrevMonthDayComponent
          The name of the component that will render inside each day block of the previous month.
        */
        sPrevMonthDayComponent: Ember.computed.reads("sDayComponent"),

        /*
          @property sNextMonthDayComponent
          The name of the component that will render inside each day block of the next month.
        */
        sNextMonthDayComponent: Ember.computed.reads("sPrevMonthDayComponent"),

        /*
          Inline computed properties
        */
        bNotDesktop: Ember.computed.not("bIsDesktop"),
        bIsDesktop: Ember.computed.or("media.isDesktop", "media.isJumbo"),
        bIsMobile: Ember.computed.or("media.isMobile", "media.isTiny", "media.isTablet"),
        bIsMobileOnly: Ember.computed.and("bNotDesktop", "bIsMobile"),

        init: function init() {
            this._super.apply(this, arguments);

            var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));

            // Default year if empty
            if (a24Core.isEmpty(Ember.get(this, "iYear"))) {
                Ember.set(this, "iYear", objDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS));
            }

            // Default month if empty
            if (a24Core.isEmpty(Ember.get(this, "iMonth"))) {
                Ember.set(this, "iMonth", objDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS));
            }
        },

        iMonthPlus1: Ember.computed("iMonth", "iYear", function () {
            var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
            objDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS, Ember.get(this, "iYear"));
            objDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS, Ember.get(this, "iMonth"));
            objDate.addTimeToDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS, 1);
            return objDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS);
        }),
        iMonthPlus2: Ember.computed("iMonth", "iYear", function () {
            var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
            objDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS, Ember.get(this, "iYear"));
            objDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS, Ember.get(this, "iMonth"));
            objDate.addTimeToDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS, 2);
            return objDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS);
        }),
        iYearPlus1: Ember.computed("iMonth", "iYear", function () {
            var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
            objDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS, Ember.get(this, "iYear"));
            objDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS, Ember.get(this, "iMonth"));
            objDate.addTimeToDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS, 1);
            return objDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS);
        }),
        iYearPlus2: Ember.computed("iMonth", "iYear", function () {
            var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
            objDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS, Ember.get(this, "iYear"));
            objDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS, Ember.get(this, "iMonth"));
            objDate.addTimeToDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS, 2);
            return objDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS);
        }),

        /*
          Multiline computed properties
        */
        /*
          A textual representation of the year.
          @computed sYear
          @return {String} year.
        */
        sMonthYear: Ember.computed("iYear", "iMonth", function () {
            var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
            objDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS, Ember.get(this, "iYear"));
            objDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS, Ember.get(this, "iMonth"));
            return objDate.getMonthYearFormat();
        }).readOnly(),

        sMonthTwoYear: Ember.computed("iYear", "iMonth", function () {
            var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
            objDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS, Ember.get(this, "iYear"));
            objDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS, Ember.get(this, "iMonth") + 1);
            return objDate.getMonthYearFormat();
        }).readOnly(),

        sMonthThreeYear: Ember.computed("iYear", "iMonth", function () {
            var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
            objDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS, Ember.get(this, "iYear"));
            objDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS, Ember.get(this, "iMonth") + 2);
            return objDate.getMonthYearFormat();
        }).readOnly()
    });
});