enifed('ember-template-compiler/plugins/transform-old-binding-syntax', ['exports', 'ember-debug', 'ember-template-compiler/system/calculate-location-display'], function (exports, _emberDebug, _calculateLocationDisplay) {
  'use strict';

  exports.default = transformOldBindingSyntax;
  function transformOldBindingSyntax(env) {
    var moduleName = env.meta.moduleName;

    var b = env.syntax.builders;

    return {
      name: 'transform-old-binding-syntax',

      visitor: {
        BlockStatement: function (node) {
          processHash(b, node, moduleName);
        },
        MustacheStatement: function (node) {
          processHash(b, node, moduleName);
        }
      }
    };
  }

  function processHash(b, node, moduleName) {
    for (var i = 0; i < node.hash.pairs.length; i++) {
      var pair = node.hash.pairs[i];
      var key = pair.key,
          value = pair.value;


      var sourceInformation = (0, _calculateLocationDisplay.default)(moduleName, pair.loc);

      if (key === 'classBinding') {
        return;
      }

      (true && !(key !== 'attributeBindings') && (0, _emberDebug.assert)('Setting \'attributeBindings\' via template helpers is not allowed ' + sourceInformation, key !== 'attributeBindings'));


      if (key.substr(-7) === 'Binding') {
        var newKey = key.slice(0, -7);

        (true && !(false) && (0, _emberDebug.deprecate)('You\'re using legacy binding syntax: ' + key + '=' + exprToString(value) + ' ' + sourceInformation + '. Please replace with ' + newKey + '=' + value.original, false, { id: 'ember-template-compiler.transform-old-binding-syntax', until: '3.0.0' }));


        pair.key = newKey;
        if (value.type === 'StringLiteral') {
          pair.value = b.path(value.original);
        }
      }
    }
  }

  function exprToString(expr) {
    switch (expr.type) {
      case 'StringLiteral':
        return '"' + expr.original + '"';
      case 'PathExpression':
        return expr.original;
    }
  }
});