define("a24-ember-lib/components/loader-blackout", ["exports", "a24-ember-lib/templates/components/loader-blackout", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _loaderBlackout, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _loaderBlackout.default,
        bLoading: false,
        sBlackOutClass: "",
        _objDomEventService: Ember.inject.service("dom-event"),
        _iMinHeight: 72, //36 (loader height) * 2
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.get("_objDomEventService");

            Ember.run.scheduleOnce("afterRender", function () {
                _this.setLoaderWrapperSize();
            });
        },
        setLoaderWrapperSize: function setLoaderWrapperSize() {
            this.$().removeClass("loader-min-height");
            if (this.get("bLoading")) {
                if (this.$().parent().css("position") === "relative") {

                    if (this.$().parent().height() < this.get("_iMinHeight")) {
                        this.$().addClass("loader-min-height");
                    }
                } else {
                    console.error( //eslint-disable-line no-console
                    "The loader-blackout component should always be within a parent div " + "that has position:relative on it.");
                }
            }
        },
        _onWindowResize: Ember.observer("_objDomEventService.iPageWidth", "_objDomEventService.iPageHeight", "bLoading", function () {
            Ember.run.once(this, "setLoaderWrapperSize");
        })
    });
});