define("a24-ember-candidate-profile/services/rest-entity-action-section", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        _sEntityActionApiBaseUrl: Ember.computed.readOnly("env.sEntityActionApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * Get entity actions for section
         *
         * @param objContext - The the caller this function
         * @param sProperty - The property name to get the promise object
         * @param sSection - The section name
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  12 Feb 2021
         */
        getEntityActionsForSection: function getEntityActionsForSection(objContext, sProperty, sSection, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/entity-action-section/" + sSection);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sEntityActionApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});