var a24SSCandidateProfile = {
    getIconForPhoneNumber: function(sType) {
        var sIcon = "";
        switch(sType) {
            case "home":
            case "work":
            case "additional":
                sIcon = "phone";
                break;
            case "home_fax":
            case "work_fax":
                sIcon = "print";
                break;
            case "mobile":
                sIcon = "phone_android";
                break;
        }

        return sIcon;
    },

    getIconForAddress: function (sType) {
        var sIcon = "";
        switch (sType) {
            case "physical_address":
            case "alternative_address":
                sIcon = "home";
                break;
            case "postal_address":
                sIcon = "markunread_mailbox";
                break;
        }

        return sIcon;
    },

    getIconForEmail: function(sType) {
        var sIcon = "";
        switch(sType) {
            case "home":
                sIcon = "person";
                break;
            case "work":
                sIcon = "work";
                break;
            case "other":
                sIcon = "email";
                break;
        }

        return sIcon;
    }
};

if (typeof a24 === "undefined") {
    var a24 = {};
}

Object.keys(a24SSCandidateProfile).forEach(function(sKey) {
    a24[sKey] = a24SSCandidateProfile[sKey];
});
