define("a24-ember-staffshift-core/services/email-preview-generator", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        _sSrcDocPolyfilJs: "/assets/a24-ember-staffshift-core/email-preview/srcdoc-polyfill.min.js",
        _objStaffshiftCoreStringsService: Ember.inject.service("a24-strings-staffshift-core"),
        _sDeliverySuccessStatus: "Delivered",

        /**
         * Will generate the entire page for the email
         *
         * @param object objEmail - The object describing the email details
         * {
         *     sSubject: "Subject",
         *     objFrom: {name: "John", email: "john@a24testmail.com"},
         *     objTo: {name: "Steve", email: "steve@a24testmail.com"},
         *     arrCC: [{name: "Alex", email: "alex@a24testmail.com"}],
         *     arrBCC: [{name: "Mary", email: "mary@a24testmail.com"}],
         *     objReplyTo: {name: "Chris", email: "chris@a24testmail.com"},
         *     sHTML: "<p>Some <b>HTML</b> here</p>"
         *     sDeliveryStatus: "delivery failed",
         *     sDeliveryFailureReason: "the reason for delivery failure"
         * }
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  28 Febuary 2019
         *
         * @return An html string
         */
        generateFullHtmlWithHTMLWrappingFromEmailObject: function generateFullHtmlWithHTMLWrappingFromEmailObject(objEmail) {
            // If the email object is empty, it will return null
            if (a24Core.isEmpty(objEmail)) {
                return null;
            }

            // Generate the entire html block to be used
            var sHtml = "";
            sHtml += "<html>";
            sHtml += "<head>";
            sHtml += "<script src='" + this.get("_sSrcDocPolyfilJs") + "'></script>";
            sHtml += "</head>";
            sHtml += "<body>";
            sHtml += this.generateFullHtmlFromEmailObject(objEmail, "vh");
            sHtml += "</body>";
            sHtml += "</html>";
            return sHtml;
        },


        /**
         * Will generate the inner html for the email
         *
         * @param object objEmail - The object describing the email details
         * {
         *     sSubject: "Subject",
         *     objFrom: {name: "John", email: "john@a24testmail.com"},
         *     objTo: {name: "Steve", email: "steve@a24testmail.com"},
         *     arrCC: [{name: "Alex", email: "alex@a24testmail.com"}],
         *     arrBCC: [{name: "Mary", email: "mary@a24testmail.com"}],
         *     objReplyTo: {name: "Chris", email: "chris@a24testmail.com"},
         *     sHTML: "<p>Some <b>HTML</b> here</p>"
         *     sDeliveryStatus: "delivery failed",
         *     sDeliveryFailureReason: "the reason for delivery failure"
         * }
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  28 Febuary 2019
         *
         * @return An html string
         */
        generateFullHtmlFromEmailObject: function generateFullHtmlFromEmailObject(objEmail, sMeasure) {

            // If the email object is empty, it will return null
            if (a24Core.isEmpty(objEmail)) {
                return null;
            }

            if (a24Core.isEmpty(sMeasure)) {
                sMeasure = "%";
            }

            var objCharsEncode = {
                "<": "lt",
                ">": "gt",
                '"': "quot",
                "'": "apos",
                "&": "amp",
                "\r": "#10",
                "\n": "#13"
            };

            var sHTMLSafe = objEmail.sHTML.replace(/[<>"'\r\n&]/g, function (sChar) {
                return "&" + objCharsEncode[sChar] + ";";
            });

            // Generate the entire html block to be used
            var sHtml = "";
            sHtml += "<link rel='stylesheet' type='text/css' href='/assets/a24-ember-staffshift-core/email-preview/style.css'>";
            sHtml += "<div class='a24-email-preview'>";
            sHtml += "<div class='a24-email-heading-block'>" + this._generateHeaderHtml(objEmail) + "</div>";
            sHtml += "<div class='a24-email-html-block'>";
            sHtml += "<iframe srcdoc='" + sHTMLSafe + "' frameborder='0' onload='";
            // onload js for the iframe to get an accurate size.
            // This is calculated from the full height of the page minus the heading block
            sHtml += "var head = document.getElementsByClassName(\"a24-email-heading-block\")[0];";
            sHtml += "var frame = document.getElementsByClassName(\"a24-email-html-block\")[0].getElementsByTagName(\"iframe\")[0];";
            sHtml += "frame.style.height = \"calc(100" + sMeasure + " - \"+head.offsetHeight+\"px)\";";
            sHtml += "if(window.a24DidRenderCountIncrease){window.a24DidRenderCountIncrease();}";
            sHtml += "if (window.srcDoc) {";
            sHtml += "if (!frame.hasLoaded) {";
            sHtml += "window.srcDoc.set(frame, \"\" + frame.getAttribute(\"srcdoc\"));";
            sHtml += "frame.hasLoaded = true;";
            sHtml += "}";
            sHtml += "}";
            // End of iframe onload
            sHtml += "'></iframe>";
            sHtml += "</div>";
            sHtml += "</div>";

            // Return a safe version of the html
            return Ember.String.htmlSafe(sHtml);
        },

        /**
         * Generate the email header section as html
         *
         * @param object objEmail - The object describing the email details
         * {
         *     sSubject: "Subject",
         *     objFrom: {name: "John", email: "john@a24testmail.com"},
         *     objTo: {name: "Steve", email: "steve@a24testmail.com"},
         *     arrCC: [{name: "Alex", email: "alex@a24testmail.com"}],
         *     arrBCC: [{name: "Mary", email: "mary@a24testmail.com"}],
         *     objReplyTo: {name: "Chris", email: "chris@a24testmail.com"},
         *     sHTML: "<p>Some <b>HTML</b> here</p>"
         *     sDeliveryStatus: "delivery failed",
         *     sDeliveryFailureReason: "the reason for delivery failure"
         * }
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  28 Febuary 2019
         *
         * @return An html string
         */
        _generateHeaderHtml: function _generateHeaderHtml(objEmail) {
            var _this = this;

            var sHtml = "";

            // Add subject to the email
            sHtml += "<div class='a24-email-subject'>" + objEmail.sSubject + "</div>";

            sHtml += "<table class='a24-email-details-table'>";
            // Add the from address if it is set
            if (!a24Core.isEmpty(objEmail.objFrom)) {
                sHtml += this._generateHeaderCellHtml(this.get("_objStaffshiftCoreStringsService").getString("from") + ":", this._generateHTMLForSingleEmailNode(objEmail.objFrom));
            }
            // Add the to address if it is set
            if (!a24Core.isEmpty(objEmail.objTo)) {
                sHtml += this._generateHeaderCellHtml(this.get("_objStaffshiftCoreStringsService").getString("to") + ":", this._generateHTMLForSingleEmailNode(objEmail.objTo));
            }
            // Add the arr of ccs if it is set
            if (!a24Core.isEmpty(objEmail.arrCC)) {
                var sCC = objEmail.arrCC.map(function (objCCItem) {
                    return _this._generateHTMLForSingleEmailNode(objCCItem);
                }).join("\n");
                sHtml += this._generateHeaderCellHtml(this.get("_objStaffshiftCoreStringsService").getString("cc") + ":", sCC);
            }
            // Add the arr of bccs if it is set
            if (!a24Core.isEmpty(objEmail.arrBCC)) {
                var _sCC = objEmail.arrBCC.map(function (objCCItem) {
                    return _this._generateHTMLForSingleEmailNode(objCCItem);
                }).join("\n");
                sHtml += this._generateHeaderCellHtml(this.get("_objStaffshiftCoreStringsService").getString("bcc") + ":", _sCC);
            }
            // Add the reply to address if it is set
            if (!a24Core.isEmpty(objEmail.objReplyTo)) {
                sHtml += this._generateHeaderCellHtml("Reply to:", this._generateHTMLForSingleEmailNode(objEmail.objReplyTo));
            }

            // Add the delivery status if it is set
            if (!a24Core.isEmpty(objEmail.sDeliveryStatus)) {
                sHtml += this._generateHeaderCellHtml(this.get("_objStaffshiftCoreStringsService").getString("deliveryStatus") + ":", objEmail.sDeliveryStatus);
                // Add the delivery failure reason if email was not delivered
                if (objEmail.sDeliveryStatus !== this.get("_sDeliverySuccessStatus") && !a24Core.isEmpty(objEmail.sDeliveryFailureReason)) {
                    sHtml += this._generateHeaderCellHtml(this.get("_objStaffshiftCoreStringsService").getString("deliveryFailureReason") + ":", objEmail.sDeliveryFailureReason);
                }
            }

            sHtml += "</table>";

            return sHtml;
        },

        /**
         * Generates a single line for the email heading details
         *
         * @param string sLabel - The label to display
         * @param string sValue - The value associated with the label (can be split on lines by \n)
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  28 Febuary 2019
         *
         * @return An html string
         */
        _generateHeaderCellHtml: function _generateHeaderCellHtml(sLabel, sValue) {
            var sHtml = "<tr>";
            sHtml += "<td class='a24-email-details-label'>" + sLabel + "</td>";
            sHtml += "<td class='a24-email-details-value'>" + sValue + "</td>";
            sHtml += "</tr>";
            return sHtml;
        },

        /**
         * Generate a single readable value from a name email combo
         *
         * @param string objEmailNode - An object containing a name (optional) and email prop
         * {
         *     name: "Chris",
         *     email: "chris@a24testmail.com"
         * }
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  28 Febuary 2019
         *
         * @return An combo string of name and email
         */
        _generateHTMLForSingleEmailNode: function _generateHTMLForSingleEmailNode(objEmailNode) {
            var sHTMLEmailNode = "";
            // Use name and email if name is set, otherwise use email only
            if (!a24Core.isEmpty(objEmailNode.name)) {
                // Name and email
                sHTMLEmailNode += objEmailNode.name + " (" + objEmailNode.email + ")";
            } else {
                // Email only
                sHTMLEmailNode += objEmailNode.email;
            }
            return sHTMLEmailNode;
        }
    });
});