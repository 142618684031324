define("a24-ember-lib/components/input-date-picker", ["exports", "a24-ember-lib/templates/components/input-date-picker", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _inputDatePicker, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _inputDatePicker.default,
        classNames: ["md-form"],
        sFieldName: "",
        sSelectedDate: null,
        objMaxDate: null, //This should be a TimeZoneDate object, see a24DateManager.createDate(sTimeZone)
        objMinDate: null, //This should be a TimeZoneDate object, see a24DateManager.createDate(sTimeZone)
        _objUserSession: Ember.inject.service("user-session"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _sInputId: null,
        _arrDayLetters: null,
        _arrDayLong: null,
        _arrMonthShort: null,
        _arrMonthLong: null,
        init: function init() {
            this._super.apply(this, arguments);

            this.set("_arrDayLetters", [this.get("_objStringsService").getString("daySunLetter"), this.get("_objStringsService").getString("dayMonLetter"), this.get("_objStringsService").getString("dayTueLetter"), this.get("_objStringsService").getString("dayWedLetter"), this.get("_objStringsService").getString("dayThuLetter"), this.get("_objStringsService").getString("dayFriLetter"), this.get("_objStringsService").getString("daySatLetter")]);
            this.set("_arrDayLong", [this.get("_objStringsService").getString("daySunLong"), this.get("_objStringsService").getString("dayMonLong"), this.get("_objStringsService").getString("dayTueLong"), this.get("_objStringsService").getString("dayWedLong"), this.get("_objStringsService").getString("dayThuLong"), this.get("_objStringsService").getString("dayFriLong"), this.get("_objStringsService").getString("daySatLong")]);
            this.set("_arrMonthShort", [this.get("_objStringsService").getString("monthJanShort"), this.get("_objStringsService").getString("monthFebShort"), this.get("_objStringsService").getString("monthMarShort"), this.get("_objStringsService").getString("monthAprShort"), this.get("_objStringsService").getString("monthMayShort"), this.get("_objStringsService").getString("monthJunShort"), this.get("_objStringsService").getString("monthJulShort"), this.get("_objStringsService").getString("monthAugShort"), this.get("_objStringsService").getString("monthSepShort"), this.get("_objStringsService").getString("monthOctShort"), this.get("_objStringsService").getString("monthNovShort"), this.get("_objStringsService").getString("monthDecShort")]);
            this.set("_arrMonthLong", [this.get("_objStringsService").getString("monthJanLong"), this.get("_objStringsService").getString("monthFebLong"), this.get("_objStringsService").getString("monthMarLong"), this.get("_objStringsService").getString("monthAprLong"), this.get("_objStringsService").getString("monthMayLong"), this.get("_objStringsService").getString("monthJunLong"), this.get("_objStringsService").getString("monthJulLong"), this.get("_objStringsService").getString("monthAugLong"), this.get("_objStringsService").getString("monthSepLong"), this.get("_objStringsService").getString("monthOctLong"), this.get("_objStringsService").getString("monthNovLong"), this.get("_objStringsService").getString("monthDecLong")]);

            this.set("_sInputId", Ember.guidFor({}));

            var objThis = this;
            Ember.run.scheduleOnce("afterRender", this, function () {
                var objDateInput = $("#" + objThis.get("_sInputId"));
                var objMaxDate = objThis.get("objMaxDate");
                var objMinDate = objThis.get("objMinDate");
                if (a24Core.isEmpty(objMaxDate)) {
                    objMaxDate = a24DateManager.createDate(objThis.get("_objUserSession.objContextDetails.sTimezone"));
                    objMaxDate.setDateFromBrowser("31 Dec 2040", this.get("_objLibConstants.DATE_FORMAT"));
                }
                if (a24Core.isEmpty(objMinDate)) {
                    objMinDate = a24DateManager.createDate(objThis.get("_objUserSession.objContextDetails.sTimezone"));
                    objMinDate.setDateFromBrowser("01 Jan 1930", this.get("_objLibConstants.DATE_FORMAT"));
                }
                var arrMaxDate = [objMaxDate.getTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_YEARS")), objMaxDate.getTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_MONTHS")), objMaxDate.getTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_DATE"))];
                var arrMinDate = [objMinDate.getTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_YEARS")), objMinDate.getTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_MONTHS")), objMinDate.getTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_DATE"))];

                //set the initial value before adding the date picker to the field
                objDateInput.pickadate({
                    format: this.get("_objLibConstants.DATE_FORMAT").toLowerCase(),
                    weekdaysShort: objThis.get("_arrDayLetters"),
                    weekdaysFull: objThis.get("_arrDayLong"),
                    monthsShort: objThis.get("_arrMonthShort"),
                    monthsFull: objThis.get("_arrMonthLong"),
                    closeOnSelect: false,
                    selectYears: 110, //This is the max amount of years that will display in the years dropdown at a time
                    today: null,
                    clear: this.get("_objStringsService").getString("clear"),
                    close: this.get("_objStringsService").getString("ok"),
                    labelMonthNext: this.get("_objStringsService").getString("nextMonth"),
                    labelMonthPrev: this.get("_objStringsService").getString("previousMonth"),
                    labelMonthSelect: this.get("_objStringsService").getString("monthSelect"),
                    labelYearSelect: this.get("_objStringsService").getString("yearSelect"),
                    max: arrMaxDate,
                    min: arrMinDate,
                    onSet: function onSet() {
                        //Set the sSelectedDate when the value changes and also reset floater if value is empty
                        objThis.set("sSelectedDate", objDateInput.val());
                        objThis.resetFloatingLabel(objDateInput);
                    }
                });
                if (!a24Core.isEmpty(objThis.get("sSelectedDate"))) {
                    //Set the init date if we have one
                    objDateInput.pickadate("picker").set("select", this.get("sSelectedDate"), {
                        format: this.get("_objLibConstants.DATE_FORMAT").toLowerCase()
                    });
                }
                objDateInput.on("blur", function () {
                    objThis.resetFloatingLabel(objDateInput);
                });
            });
        },
        resetFloatingLabel: function resetFloatingLabel(objDateInput) {
            //If the input field has no value then the label should NOT have the active class and
            //float at placeholder position
            if (a24Core.isEmpty(objDateInput.val())) {
                this.$("label").removeClass("active");
            }
        },
        setInputValue: Ember.observer("sSelectedDate", function () {
            var objDateInput = $("#" + this.get("_sInputId"));

            if (a24Core.isEmpty(this.get("sSelectedDate"))) {
                objDateInput.pickadate("picker").clear();
            } else if (objDateInput.val() !== this.get("sSelectedDate")) {
                objDateInput.pickadate("picker").set("select", this.get("sSelectedDate"), {
                    format: this.get("_objLibConstants.DATE_FORMAT").toLowerCase()
                });
            }
        })
    });
});