define("a24-ember-staffshift-candidate-availability/helpers/availability-state", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.availabilityState = availabilityState;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    /**
     * Computes the availability state
     *
     * @param sState - The availability state
     * @param bEdit - If is editing
     * @param bShown
    
     * @return bState
     *
     * @author Ahmed Onawale <ahmedonawale@gmail.com>
     * @since  06 September 2017
     */
    function availabilityState(_ref) {
        var _ref2 = _slicedToArray(_ref, 3),
            sState = _ref2[0],
            bEdit = _ref2[1],
            _ref2$ = _ref2[2],
            bShown = _ref2$ === undefined ? true : _ref2$;

        if (bShown) {
            switch (sState) {
                case "Booked":
                case "Unavailable":
                case "Available":
                    return true;
                case "Unknown":
                    return bEdit;
                default:
                    return "";
            }
        } else {
            switch (sState) {
                case "Booked":
                    return false;
                case "Unavailable":
                case "Available":
                case "Unknown":
                    return bEdit;
                default:
                    return "";
            }
        }
    }

    exports.default = Ember.Helper.helper(availabilityState);
});