var a24EmberSSCore = {
    isUrlDefaultAvatar: function(objConstService, sUrl) {
        return (
            sUrl === objConstService.AGENCY_DEFAULT_AVATAR ||
            sUrl === objConstService.CANDIDATE_DEFAULT_AVATAR ||
            sUrl === objConstService.ORGANISATION_DEFAULT_AVATAR ||
            sUrl === objConstService.SYSTEM_ADMIN_DEFAULT_AVATAR ||
            sUrl === objConstService.AGENCY_GROUP_DEFAULT_AVATAR
        );
    },
    getUserContextAvatarUrl: function(sTriageBaseUrl, objConstService, sAvatarUrl) {

        // Reset the avatar if it is one of the default
        if (a24.isUrlDefaultAvatar(objConstService, sAvatarUrl)) {
            //Reset the icon if it is a default one
            sAvatarUrl = null;
        }

        if (
            !a24Core.isEmpty(sAvatarUrl) &&
            sAvatarUrl.substr(0, "http".length) !== "http"
        ) {
            if (sAvatarUrl[0] === "/") {
                return sTriageBaseUrl + sAvatarUrl;
            } else {
                return sTriageBaseUrl + "/" + sAvatarUrl;
            }
        } else {
            return sAvatarUrl;
        }
    }
};

if (typeof a24 === "undefined") {
    var a24 = {};
}

Object.keys(a24EmberSSCore).forEach(function(sKey) {
    a24[sKey] = a24EmberSSCore[sKey];
});
