define("a24-ember-staffshift-candidate-availability/components/month-view", ["exports", "a24-ember-staffshift-candidate-availability/templates/components/month-view", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _monthView, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _monthView.default,

        // Stores the date that is used for the month calendar view
        sDate: null,

        // Store Loading state
        bCalendarLoading: false,
        objAvailabilityService: null,
        sCandidateId: null,

        bShowBulkUpdatePopup: false,
        bShowPreview: false,
        bBulkUpdateLoading: false,
        // SERVICES
        _objUserSession: Ember.inject.service("user-session"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objNavigation: Ember.inject.service("navigation"),
        _objAvailabilityStrings: Ember.inject.service("a24-strings-staffshift-availability"),
        _objStrings: Ember.inject.service("a24-strings"),
        _objConstantsEmberLibService: Ember.inject.service("a24-constants-ember-lib"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),

        // Properties
        _objNonCurrentMonth: null,
        _bShowAvailabilityWarning: false,
        /**
         * Inline computed properties
         */
        bNotDesktop: Ember.computed.not("bIsDesktop"),
        bIsDesktop: Ember.computed.or("media.isDesktop", "media.isJumbo"),
        bIsMobile: Ember.computed.or("media.isMobile", "media.isTiny", "media.isTablet"),
        bIsMobileOnly: Ember.computed.and("bNotDesktop", "bIsMobile"),
        _objAvailabilityService: Ember.inject.service("availability"),
        _objPrevMonthDays: null,
        _objMonthDays: null,
        _objNextMonthDays: null,
        _objStoredMonthData: null,
        _objStoredTwoMonthData: null,
        _objStoredThreeMonthData: null,
        _objStoredNextMonthData: null,

        _objMonthTwoDays: null,
        _objMonthThreeDays: null,

        // Init function
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            var sCandidateId = "";
            if (Ember.get(this, "objAvailabilityService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE")) {
                sCandidateId = this.get("_objUserSession").getUserContextReference().mId;
            } else {
                var objCandidateSearchViewParams = this.get("_objNavigation.objRouteParams")["index.agency-candidate-search-view"];

                sCandidateId = objCandidateSearchViewParams.candidate_id;
            }

            Ember.set(this, "sCandidateId", sCandidateId);

            if (a24Core.isEmpty(this.get("funcAvailabilitySuccess"))) {
                this.set("funcAvailabilitySuccess", function (objData) {

                    _this.set("bBulkUpdateLoading", false);
                    _this.set("bShowBulkUpdatePopup", false);
                    _this.set("bShowPreview", true);

                    _this.setDayData(objData.objPrevMonth, objData.objMonth, objData.objNextMonth);

                    _this.setMonthEditState(false);
                    Ember.set(_this, "bCalendarLoading", false);
                    if (!a24Core.isEmpty(_this.get("onCalendarLoaded"))) {
                        _this.get("onCalendarLoaded")();
                    }
                });
            }

            // Load initial data
            this.onDateChange();
        },

        // Computed Properties
        iYear: Ember.computed("sDate", function () {
            var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
            objDate.setDateFromRest(Ember.get(this, "sDate"), true);

            return objDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS);
        }),
        iMonth: Ember.computed("sDate", function () {
            var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
            objDate.setDateFromRest(Ember.get(this, "sDate"), true);

            return objDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS);
        }),
        // Fire function on data change
        onDateChange: Ember.observer("iMonth", function () {

            this.setDayData({}, {}, {});

            this.set("bCalendarLoading", true);

            this.set("_objPreviewData", null);

            var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
            objDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS, Ember.get(this, "iYear"));
            objDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS, Ember.get(this, "iMonth"));

            // First 7 days of the next month
            var objNextMonthLimit = $.extend(true, objDate);
            objNextMonthLimit.addTimeToDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS, 1);
            objNextMonthLimit.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_DATE, 7);
            var sNextMonth = objNextMonthLimit.getDateStringForRest(true);

            // Last 7 days of the previous month
            var objPrevMonthLimit = $.extend(true, objDate);
            objPrevMonthLimit.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_DATE, 1);
            objPrevMonthLimit.subtractTimeFromDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_DAYS, 7);
            var sLastMonth = objPrevMonthLimit.getDateStringForRest(true);

            var objQueryParams = {
                "from-dAvailabilityDate": {
                    mValue: sLastMonth
                },
                "to-dAvailabilityDate": {
                    mValue: sNextMonth
                },
                "page": {
                    mValue: 1
                },
                "items_per_page": {
                    mValue: 50
                }
            };
            this.getAvailabiliityDataFromQuery(objQueryParams, this.get("funcAvailabilitySuccess"));
        }),
        // Fire event when the loading changes
        onLoadingChange: Ember.observer("bCalendarLoading", function () {
            var _this2 = this;

            Ember.run.next(function () {
                if (!a24Core.isEmpty(_this2.get("onLoadingStateChange"))) {
                    _this2.get("onLoadingStateChange")(Ember.get(_this2, "bCalendarLoading"));
                }
            });
        }),
        /**
         * Gets the availability data from a query and sets it on to the day data
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  12 September 2017
         */
        getAvailabiliityDataFromQuery: function getAvailabiliityDataFromQuery(objQueryParams, funcSuccessCallback) {

            Ember.get(this, "objAvailabilityService").getFormattedAvailability(this, "arrAvailabilityData", Ember.get(this, "sCandidateId"), Ember.get(this, "iMonth"), Ember.get(this, "iYear"), funcSuccessCallback, null, objQueryParams);
        },
        /**
         * Helper function for setting days data for current, previous and next month
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  12 September 2017
         *
         * @param objPrevMonthDays - Previous month days data
         * @param objMonthDays - Current month days data
         * @param objNextMonthDays - Next month days data
         */
        setDayData: function setDayData(objPrevMonthDays, objMonthDays, objNextMonthDays) {
            Ember.set(this, "_objPrevMonthDays", objPrevMonthDays);
            Ember.set(this, "_objMonthDays", objMonthDays);
            Ember.set(this, "_objNextMonthDays", objNextMonthDays);
        },
        /**
         * Looks up the next item for the edit state to change to
         *
         * @author Michael Banrard <michael.barnard@a24group.com>
         * @since  13 September 2017
         */
        lookupNextAvailabilityState: function lookupNextAvailabilityState(sState) {
            var arrOrder = [Ember.get(this, "_objAvailabilityStrings").getString("unknown"), Ember.get(this, "_objAvailabilityStrings").getString("available"), Ember.get(this, "_objAvailabilityStrings").getString("unavailable"), Ember.get(this, "_objAvailabilityStrings").getString("unknown")];

            return arrOrder[arrOrder.indexOf(sState) + 1];
        },

        bIsPreviousMonth: Ember.computed("iYear", "iMonth", function () {

            var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));

            return objDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS) === Ember.get(this, "iYear") && Ember.get(this, "iMonth") < objDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS) || Ember.get(this, "iYear") < objDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS);
        }),
        /**
         * Computes a diff between the original data and the data edited. If no change, null is returned,
         * else an array containing the diff data is returned.
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  12 September 2017
         */
        getDataObject: function getDataObject(bChangedOnly) {

            var objNewData = Ember.copy(this.get("_objMonthDays"), true);
            var objNewMonthTwoData = Ember.copy(this.get("_objMonthTwoDays"), true);
            var objNewMonthThreeData = Ember.copy(this.get("_objMonthThreeDays"), true);
            var objStoredCurrentMonthData = Ember.copy(this.get("_objStoredMonthData"), true);
            var objStoredTwoMonthData = Ember.copy(this.get("_objStoredTwoMonthData"), true);
            var objStoredThreeMonthData = Ember.copy(this.get("_objStoredThreeMonthData"), true);

            var arrAvailability = [];

            for (var key in objNewData) {
                if (objNewData.hasOwnProperty(key)) {

                    var iNum = objNewData[key].iNumber;

                    if (a24Core.isEmpty(iNum)) {
                        continue;
                    }

                    var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
                    var sDate = objNewData[key].dAvailabilityDate;
                    objDate.setDateFromRest(sDate, true);

                    var objShifts = objNewData[key].arrData;
                    objShifts = {
                        sEarly: objShifts.objEarly.sState,
                        sLate: objShifts.objLate.sState,
                        sNight: objShifts.objNight.sState,
                        dAvailabilityDate: sDate
                    };
                    var objPrevShifts = objStoredCurrentMonthData[key].arrData;

                    var objDateToday = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
                    objDateToday.setTimeToStartOfDay();

                    if (objShifts.sEarly !== objPrevShifts.objEarly.sState || objShifts.sLate !== objPrevShifts.objLate.sState || objShifts.sNight !== objPrevShifts.objNight.sState || !bChangedOnly && objDate.difference(objDateToday, this.get("_objConstantsEmberLibService").MOMENT_TIME_DATE) >= 0) {
                        arrAvailability.push(objShifts);
                    }
                }
            }

            for (var monthTwoKey in objNewMonthTwoData) {
                if (objNewMonthTwoData.hasOwnProperty(monthTwoKey)) {
                    var _iNum = objNewMonthTwoData[monthTwoKey].iNumber;

                    if (a24Core.isEmpty(_iNum)) {
                        continue;
                    }

                    var objTwoDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
                    var sTwoDate = objNewMonthTwoData[monthTwoKey].dAvailabilityDate;
                    objTwoDate.setDateFromRest(sTwoDate, true);

                    var objTwoShifts = objNewMonthTwoData[monthTwoKey].arrData;
                    objTwoShifts = {
                        sEarly: objTwoShifts.objEarly.sState,
                        sLate: objTwoShifts.objLate.sState,
                        sNight: objTwoShifts.objNight.sState,
                        dAvailabilityDate: sTwoDate
                    };

                    var objPrevTwoShifts = objStoredTwoMonthData[monthTwoKey].arrData;

                    if (objTwoShifts.sEarly !== objPrevTwoShifts.objEarly.sState || objTwoShifts.sLate !== objPrevTwoShifts.objLate.sState || objTwoShifts.sNight !== objPrevTwoShifts.objNight.sState || !bChangedOnly) {
                        arrAvailability.push(objTwoShifts);
                    }
                }
            }

            for (var monthThreeKey in objNewMonthThreeData) {
                if (objNewMonthThreeData.hasOwnProperty(monthThreeKey)) {
                    var _iNum2 = objNewMonthThreeData[monthThreeKey].iNumber;

                    if (a24Core.isEmpty(_iNum2)) {
                        continue;
                    }

                    var objThreeDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
                    var sThreeDate = objNewMonthThreeData[monthThreeKey].dAvailabilityDate;
                    objThreeDate.setDateFromRest(sThreeDate, true);

                    var objThreeShifts = objNewMonthThreeData[monthThreeKey].arrData;
                    objThreeShifts = {
                        sEarly: objThreeShifts.objEarly.sState,
                        sLate: objThreeShifts.objLate.sState,
                        sNight: objThreeShifts.objNight.sState,
                        dAvailabilityDate: sThreeDate
                    };
                    var objPrevThreeShifts = objStoredThreeMonthData[monthThreeKey].arrData;

                    if (objThreeShifts.sEarly !== objPrevThreeShifts.objEarly.sState || objThreeShifts.sLate !== objPrevThreeShifts.objLate.sState || objThreeShifts.sNight !== objPrevThreeShifts.objNight.sState || !bChangedOnly) {
                        arrAvailability.push(objThreeShifts);
                    }
                }
            }

            // add on non current months
            var objNonCurrentMonth = this.get("_objNonCurrentMonth");
            for (var iYear in objNonCurrentMonth) {
                if (objNonCurrentMonth.hasOwnProperty(iYear)) {
                    for (var iMonth in objNonCurrentMonth[iYear]) {
                        if (objNonCurrentMonth[iYear].hasOwnProperty(iMonth)) {
                            for (var iDay in objNonCurrentMonth[iYear][iMonth]) {
                                if (objNonCurrentMonth[iYear][iMonth].hasOwnProperty(iDay)) {
                                    var objNewAvailability = objNonCurrentMonth[iYear][iMonth][iDay];
                                    if (!a24Core.isEmpty(objNewAvailability)) {
                                        arrAvailability.push({
                                            sEarly: objNewAvailability.arrData.objEarly.sState,
                                            sLate: objNewAvailability.arrData.objLate.sState,
                                            sNight: objNewAvailability.arrData.objNight.sState,
                                            dAvailabilityDate: objNewAvailability.dAvailabilityDate
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
            }

            if (arrAvailability.length === 0) {
                return null;
            } else {
                return arrAvailability;
            }
        },
        /**
         * Check for unsaved data then present a popup if any is found, else sets back to view mode
         *
         * @author Michael Banrard <michael.barnard@a24group.com>
         * @since  13 September 2017
         */
        cancelChangesWithUnsavedCheck: function cancelChangesWithUnsavedCheck() {
            var arrChangedData = this.getDataObject(true);

            if (arrChangedData !== null) {
                Ember.set(this, "_bShowUnsavedData", true);
            } else {
                this.setMonthEditState(false);
            }
        },
        /**
         * Save the new availability data
         *
         * @author Michael Banrard <michael.barnard@a24group.com>
         * @since  13 September 2017
         */
        updateAvailability: function updateAvailability() {
            var _this3 = this;

            this.get("_objSnackbarService").showSnackbarMessage(Ember.get(this, "_objAvailabilityStrings").getString("updatingAvailability"));

            var arrAvailability = this.getDataObject(false);

            this.set("bCalendarLoading", true);

            var objData = {
                "arrAvailability": arrAvailability
            };

            var objThis = this;
            this.get("objAvailabilityService").updateAvailability(this, "arrAvailabilitySaveData", Ember.get(this, "sCandidateId"), function (objData) {
                objThis.setMonthEditState(false);
                objThis.set("bCalendarLoading", false);

                if (!a24Core.isEmpty(objData.arrWarnings)) {
                    objThis.get("_objSnackbarService").showSnackbarMessage(Ember.get(_this3, "_objAvailabilityStrings").getString("availabilityPartiallyUpdated"));
                } else {
                    objThis.get("_objSnackbarService").showSnackbarMessage(Ember.get(_this3, "_objAvailabilityStrings").getString("availabilityUpdated"));
                }
            }, null, objData);
        },
        /**
         * Set the calendar month to a particular state
         *
         * @author Michael Banrard <michael.barnard@a24group.com>
         * @since  13 September 2017
         */
        setMonthEditState: function setMonthEditState(bEditMode) {

            this.set("bEditMode", bEditMode);
            if (!a24Core.isEmpty(this.get("onEditStateChange"))) {
                this.get("onEditStateChange")(bEditMode);
            }

            if (bEditMode) {
                // Make a backup of the day data
                this.set("_objStoredMonthData", Ember.copy(this.get("_objMonthDays"), true));
                this.set("_objStoredNextMonthData", Ember.copy(this.get("_objNextMonthDays"), true));
            } else {
                this.set("_objNonCurrentMonth", null);
                this.set("bShowPreview", false);
                this.set("_objMonthTwoDays", null);
                this.set("_objMonthThreeDays", null);
            }

            var objData = this.get("_objMonthDays");
            for (var key in objData) {
                if (objData.hasOwnProperty(key)) {
                    Ember.set(objData[key], "bEdit", bEditMode);
                }
            }

            // EXPLAIN BELOW IS NEEDED FOR PER DAY CALCULATIONS FOR CLICK STATE
            var objPrevMonthDays = this.get("_objPrevMonthDays");
            for (var prevkey in objPrevMonthDays) {
                if (objPrevMonthDays.hasOwnProperty(prevkey)) {
                    Ember.set(objPrevMonthDays[prevkey], "bEdit", bEditMode);
                }
            }
            var objNextMonthDays = this.get("_objNextMonthDays");
            for (var nextkey in objNextMonthDays) {
                if (objNextMonthDays.hasOwnProperty(nextkey)) {
                    Ember.set(objNextMonthDays[nextkey], "bEdit", bEditMode);
                }
            }

            var objNextTwoMonthDays = this.get("_objMonthTwoDays");
            for (var nextTwokey in objNextTwoMonthDays) {
                if (objNextTwoMonthDays.hasOwnProperty(nextTwokey)) {
                    Ember.set(objNextTwoMonthDays[nextTwokey], "bEdit", bEditMode);
                }
            }

            var objNextThreeMonthDays = this.get("_objMonthThreeDays");
            for (var nextThreekey in objNextThreeMonthDays) {
                if (objNextThreeMonthDays.hasOwnProperty(nextThreekey)) {
                    Ember.set(objNextThreeMonthDays[nextThreekey], "bEdit", bEditMode);
                }
            }
        },

        onActionChnage: Ember.observer("bEditMode", "bCalendarLoading", "bIsPreviousMonth", "bIsMobileOnly", function () {
            var objThis = this;

            var bEditMode = Ember.get(this, "bEditMode");
            var bCalendarLoading = Ember.get(this, "bCalendarLoading");
            var bIsPreviousMonth = Ember.get(this, "bIsPreviousMonth");
            var bIsMobileOnly = Ember.get(this, "bIsMobileOnly");

            if (bEditMode && !bCalendarLoading) {
                Ember.set(this, "arrActions", [{
                    sTitle: Ember.get(this, "_objStrings").getString("cancel"),
                    sIcon: "close",
                    executeCardAction: function executeCardAction() {
                        objThis.cancelChangesWithUnsavedCheck();
                    }
                }, {
                    sTitle: Ember.get(this, "_objStrings").getString("save"),
                    sIcon: "check",
                    executeCardAction: function executeCardAction() {
                        var objNonCurrentMonth = objThis.get("_objNonCurrentMonth");
                        if (a24Core.isEmpty(objNonCurrentMonth) || Object.keys(objNonCurrentMonth).length === 0) {
                            objThis.updateAvailability();
                        } else {
                            objThis.set("_bShowAvailabilityWarning", true);
                        }
                    }
                }]);
            } else {
                if (bCalendarLoading || bIsPreviousMonth || bIsMobileOnly) {
                    Ember.set(this, "arrActions", null);
                } else {
                    Ember.set(this, "arrActions", [{
                        sTitle: Ember.get(this, "_objStrings").getString("edit"),
                        sIcon: "mode_edit",
                        executeCardAction: function executeCardAction() {
                            objThis.setMonthEditState(true);
                        }
                    }]);
                }
            }
        }),
        onMobileChange: Ember.observer("bIsMobileOnly", function () {
            if (Ember.get(this, "bIsMobileOnly") && Ember.get(this, "bEditMode")) {
                // Restore data
                this.set("_objMonthDays", Ember.copy(this.get("_objStoredMonthData"), true));
                // Set the screen back to view state
                this.setMonthEditState(false);
            }
        }),
        actions: {
            onDayAction: function onDayAction() {
                var sActionName = arguments[0];

                if (sActionName === "selectAvailability") {
                    var objAvailability = arguments[1];
                    var objDay = arguments[2];
                    var sPeriod = arguments[3];

                    // Get the day item
                    var objData = this.get("_objMonthDays");
                    var objTwoData = this.get("_objMonthTwoDays");
                    var objThreeData = this.get("_objMonthThreeDays");

                    var iKey = [objDay.iNumber];
                    var sNewState = this.lookupNextAvailabilityState(objAvailability.sState);
                    // Set the data
                    if (objData[iKey].dAvailabilityDate === objDay.dAvailabilityDate) {
                        Ember.set(objData[iKey].arrData[sPeriod], "sState", sNewState);
                        Ember.set(objData[iKey].arrData[sPeriod], "sLabel", sNewState);
                    }
                    if (!a24Core.isEmpty(objTwoData) && objTwoData[iKey].dAvailabilityDate === objDay.dAvailabilityDate) {
                        Ember.set(objTwoData[iKey].arrData[sPeriod], "sState", sNewState);
                        Ember.set(objTwoData[iKey].arrData[sPeriod], "sLabel", sNewState);
                    }
                    if (!a24Core.isEmpty(objThreeData) && objThreeData[iKey].dAvailabilityDate === objDay.dAvailabilityDate) {
                        Ember.set(objThreeData[iKey].arrData[sPeriod], "sState", sNewState);
                        Ember.set(objThreeData[iKey].arrData[sPeriod], "sLabel", sNewState);
                    }
                } else if (sActionName === "onDaySelect") {
                    var _objDay = arguments[1];

                    if (!a24Core.isEmpty(this.get("onDayClick"))) {
                        this.get("onDayClick")(_objDay);
                    }
                }
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                // Restore data
                this.set("_objMonthDays", Ember.copy(this.get("_objStoredMonthData"), true));
                this.set("_objNextMonthDays", Ember.copy(this.get("_objStoredNextMonthData"), true));
                // Set the screen back to view state
                this.setMonthEditState(false);
            },
            onPopupClose: function onPopupClose() {
                if (this.get("bBulkUpdateLoading")) {
                    return;
                }
                this.set("bShowBulkUpdatePopup", false);
            },
            onPreview: function onPreview(objBulkUpdateConfig) {
                var _this4 = this;

                this.set("bBulkUpdateLoading", true);
                var objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
                objDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS, Ember.get(this, "iYear"));
                objDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS, Ember.get(this, "iMonth") + 1);

                // Set To to end of the month
                var objNextMonthLimit = $.extend(true, objDate);
                objNextMonthLimit.addTimeToDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS, 1);
                objNextMonthLimit.endOf(this.get("_objConstantsEmberLibService").MOMENT_UNIT_MONTH);

                var sPreviewMonths = objNextMonthLimit.getDateStringForRest(true);

                // Set From to start of the month
                var objPrevMonthLimit = $.extend(true, objDate);
                objPrevMonthLimit.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_DATE, 1);
                var sLastMonth = objPrevMonthLimit.getDateStringForRest(true);

                var objQueryParams = {
                    "from-dAvailabilityDate": {
                        mValue: sLastMonth
                    },
                    "to-dAvailabilityDate": {
                        mValue: sPreviewMonths
                    },
                    "page": {
                        mValue: 1
                    },
                    "items_per_page": {
                        mValue: 90
                    }
                };

                var funcAvailSuccess = function funcAvailSuccess(objData) {

                    _this4.set("bBulkUpdateLoading", false);
                    _this4.set("bShowBulkUpdatePopup", false);
                    _this4.set("bShowPreview", true);

                    _this4.set("_objPreviewData", objData);

                    _this4.set("_objStoredTwoMonthData", Ember.copy(objData.objMonth, true));
                    _this4.set("_objStoredThreeMonthData", Ember.copy(objData.objNextMonth, true));

                    var objMonthTwo = {};
                    var objMonthThree = {};

                    if (a24Core.isEmpty(_this4.get("_objMonthTwoDays"))) {
                        objMonthTwo = objData.objMonth;
                    } else {
                        objMonthTwo = _this4.get("_objMonthTwoDays");
                    }

                    if (a24Core.isEmpty(_this4.get("_objMonthThreeDays"))) {
                        objMonthThree = objData.objNextMonth;
                    } else {
                        objMonthThree = _this4.get("_objMonthThreeDays");
                    }

                    var objFormatAvailabilityDataReturn = _this4.get("_objAvailabilityService").formatBulkAvailabilityData(_this4.get("_objMonthDays"), _this4.get("_objNextMonthDays"), _this4.get("_objNonCurrentMonth"), objBulkUpdateConfig, objMonthTwo, objMonthThree);
                    _this4.set("_objNonCurrentMonth", objFormatAvailabilityDataReturn.objNonCurrentMonth);
                    _this4.setDayData(_this4.get("_objPrevMonthDays"), objFormatAvailabilityDataReturn.objMonth, objFormatAvailabilityDataReturn.objNextMonth);
                    _this4.set("_objMonthTwoDays", objFormatAvailabilityDataReturn.objMonthTwoDays);
                    _this4.set("_objMonthThreeDays", objFormatAvailabilityDataReturn.objMonthThreeDays);

                    var objNextTwoMonthDays = _this4.get("_objMonthTwoDays");
                    for (var nextTwokey in objNextTwoMonthDays) {
                        if (objNextTwoMonthDays.hasOwnProperty(nextTwokey)) {
                            Ember.set(objNextTwoMonthDays[nextTwokey], "bEdit", true);
                        }
                    }

                    var objNextThreeMonthDays = _this4.get("_objMonthThreeDays");
                    for (var nextThreekey in objNextThreeMonthDays) {
                        if (objNextThreeMonthDays.hasOwnProperty(nextThreekey)) {
                            Ember.set(objNextThreeMonthDays[nextThreekey], "bEdit", true);
                        }
                    }
                };

                if (a24Core.isEmpty(this.get("_objPreviewData"))) {
                    var objPreviewDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
                    objPreviewDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS, this.get("iYear"));
                    objPreviewDate.setTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS, this.get("iMonth"));
                    objPreviewDate.addTimeToDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS, 1);
                    Ember.get(this, "objAvailabilityService").getFormattedAvailability(this, "arrAvailabilityData", Ember.get(this, "sCandidateId"), objPreviewDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS), objPreviewDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS), funcAvailSuccess, null, objQueryParams);
                } else {
                    funcAvailSuccess(this.get("_objPreviewData"));
                }
            },
            onPopupWarningConfirm: function onPopupWarningConfirm() {
                this.set("_bShowAvailabilityWarning", false);
                this.updateAvailability();
            }
        }
    });
});