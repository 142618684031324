define("a24-ember-candidate-profile/components/candidate-external-verification", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-external-verification"], function (exports, _didRenderChangesMixin, _candidateExternalVerification) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _candidateExternalVerification.default,
        arrExternalVerificationData: null,
        _arrExternalVerificationDataView: null,
        _arrExternalVerificationDataOrig: null,
        _arrHeaders: null,
        _bLoading: true,
        _objStringsService: Ember.inject.service("a24-strings"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objDatagridConfig: null,
        _arrFilterConfig: null,
        init: function init() {
            this._super.apply(this, arguments);

            this.prepareExternalDataForDisplay();
        },
        prepareExternalDataForDisplay: Ember.observer("arrExternalVerificationData", function () {
            this.set("_bLoading", false);
            var arrExternalVerificationData = this.get("arrExternalVerificationData");
            if (!a24Core.isEmpty(arrExternalVerificationData)) {
                arrExternalVerificationData.sort(function (objA, objB) {
                    if (objA.name < objB.name) {
                        return -1;
                    } else if (objA.name > objB.name) {
                        return 1;
                    }
                    return 0;
                });
                arrExternalVerificationData = this._prepareForDisplay(arrExternalVerificationData);
                this.set("_arrExternalVerificationDataView", arrExternalVerificationData);
                this.set("_arrExternalVerificationDataOrig", arrExternalVerificationData);
            }
        }),
        _prepareForDisplay: function _prepareForDisplay(arrExternalVerification) {
            var _this = this;

            return arrExternalVerification.map(function (objItem) {
                return {
                    sIcon: "assignment",
                    sLabel: objItem.name,
                    sExtra: objItem.value,
                    arrItems: [{
                        arrItems: [{
                            sLabel: _this.get("_objStringsService").getString("name"),
                            sValue: objItem.name,
                            bText: true
                        }, {
                            sLabel: _this.get("_objCandidateProfileStringsService").getString("verified"),
                            sValue: objItem.value,
                            bText: true
                        }]
                    }]
                };
            });
        }
    });
});