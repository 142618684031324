define("a24-ember-staffshift-users/components/agency-group-view-edit-card", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-users/templates/components/agency-group-view-edit-card"], function (exports, _didRenderChangesMixin, _agencyGroupViewEditCard) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _agencyGroupViewEditCard.default,

        // Services
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objRestAclGroup: Ember.inject.service("rest-acl-group"),
        _objStaffshiftUsersEnumsService: Ember.inject.service("a24-enums-staffshift-users"),
        _objStaffshiftUsersStringsService: Ember.inject.service("a24-strings-staffshift-users"),
        _objRestAclGroupResourceTreeService: Ember.inject.service("rest-acl-group-resource-tree"),
        _objStringsService: Ember.inject.service("a24-strings"),

        // Promises
        objAclGroupGetPromise: null,
        _objAclResourceTreePromise: null,

        // Default Properties
        bLoading: false,
        _arrAclGroupDetails: null,
        _bShowAddPopup: false,
        _objAclGroupDetails: null,

        init: function init() {
            this._super.apply(this, arguments);

            this.doServiceCalls();
        },
        doServiceCalls: function doServiceCalls() {
            Ember.set(this, "bLoading", true);

            var objRouteParams = Ember.get(this, "_objNavigationService.objRouteParams");
            var sAclGroupId = objRouteParams["agency-group.acl-group-view"].group_type_id;

            var objAclGroupByIdService = Ember.get(this, "_objRestAclGroup").getAclGroupById(this, "objAclGroupGetPromise", sAclGroupId, function () {
                // Handled in RSVP
            });

            var objAclGroupResourceTreeService = this.get("_objRestAclGroupResourceTreeService").getAclGroupResourceTreeByGroupId(this, "_objAclResourceTreePromise", sAclGroupId, function () {
                // Handled in RSVP
            });

            Ember.set(this, "_arrHeaderActions", []);

            var objPromiseList = {
                _objAclGroupResourceTree: objAclGroupResourceTreeService,
                _objAclGroupById: objAclGroupByIdService
            };

            var objThis = this;
            a24RSVP.configure(this, objPromiseList, [{
                arrRequiredResponses: ["_objAclGroupResourceTree", "_objAclGroupById"],
                funcOnComplete: function funcOnComplete(objResponses) {
                    //All responses set on corresponding keys
                    var objAclGroupResourceResponse = objResponses._objAclGroupResourceTree.objResponse;
                    var objAclGroupByIdResponse = objResponses._objAclGroupById.objResponse;

                    var arrStatuses = [];
                    if (!a24Core.isEmpty(objAclGroupResourceResponse) && !a24Core.isEmpty(objAclGroupResourceResponse[0]) && !a24Core.isEmpty(objAclGroupResourceResponse[0].arrStatus)) {
                        arrStatuses = objAclGroupResourceResponse[0].arrStatus;
                    }

                    objThis.set("_arrStatuses", arrStatuses.map(function (objItem) {
                        return {
                            sLabel: objItem.sName,
                            sValue: objItem.id
                        };
                    }));

                    arrStatuses = arrStatuses.filter(function (objItem) {
                        return !!objItem.sTreeMetaData.bSelected;
                    });

                    var sStatusValue = null;
                    var arrStatusesIds = [];

                    if (arrStatuses.length !== 0) {
                        arrStatusesIds = arrStatuses.map(function (objItem) {
                            return objItem.id;
                        });
                        arrStatuses = arrStatuses.map(function (objItem) {
                            return objItem.sName;
                        });
                        sStatusValue = Ember.String.htmlSafe(arrStatuses.join("<br/>"));
                    }

                    Ember.set(objThis, "_arrAclGroupDetails", [{
                        arrItems: [{
                            sLabel: objThis.get("_objStaffshiftUsersStringsService").getString("groupName"),
                            sValue: objAclGroupByIdResponse.sName,
                            bText: true
                        }, {
                            sLabel: objThis.get("_objStaffshiftUsersStringsService").getString("role"),
                            sValue: objThis.get("_objStaffshiftUsersEnumsService").getFrontendValue("aclRoles", objAclGroupByIdResponse.sRoleId),
                            bText: true
                        }, {
                            sLabel: objThis.get("_objStaffshiftUsersStringsService").getString("statuses"),
                            sValue: sStatusValue,
                            bText: true
                        }]
                    }]);
                    if (!a24Core.isEmpty(objThis.get("onTitleBarUpdate"))) {
                        objThis.get("onTitleBarUpdate")(objAclGroupByIdResponse.sName);
                    }

                    Ember.set(objThis, "_objAclGroupDetails", {
                        sGroupName: objAclGroupByIdResponse.sName,
                        sRoleId: objAclGroupByIdResponse.sRoleId,
                        arrStatuses: arrStatusesIds,
                        id: sAclGroupId
                    });

                    Ember.set(objThis, "_arrHeaderActions", [{
                        sTitle: objThis.get("_objStringsService").getString("edit"),
                        sIcon: "edit",
                        executeCardAction: function executeCardAction() {
                            Ember.set(objThis, "_bShowAddPopup", true);
                        }
                    }]);

                    Ember.set(objThis, "bLoading", false);
                }
            }]);
        },

        actions: {
            onPopupSuccess: function onPopupSuccess() {
                this.doServiceCalls();
                Ember.set(this, "_bShowAddPopup", false);
            }
        }
    });
});