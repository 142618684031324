define("a24-ember-lib/components/stepper-wrapper", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/templates/components/stepper-wrapper"], function (exports, _didRenderChangesMixin, _stepperWrapper) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _stepperWrapper.default,
        classNames: ["a24-stepper-wrapper"],
        classNameBindings: ["_bVerticalView:a24-stepper-wrapper-vertical:a24-stepper-wrapper-horizontal"],
        _objStepItems: null,
        _bVerticalView: false,

        _sStepStyle: Ember.computed("_objStepItems.@each", function () {
            var iNumOfSteps = Object.keys(this.get("_objStepItems")).length;
            var iWidth = 100 / iNumOfSteps;
            return Ember.String.htmlSafe("width: " + iWidth.toFixed(2) + "%;");
        }),

        _onReCalc: Ember.observer("_objDidRenderService._iCountRender", function () {
            Ember.run.scheduleOnce("afterRender", this, this._setMobileView);
        }),

        init: function init() {
            this._super.apply(this, arguments);
            if (a24Core.isEmpty(this.get("_objStepItems"))) {
                this.set("_objStepItems", {});
            }
        },

        _setMobileView: function _setMobileView() {
            if (this.$("table").eq(0).width() > this.$().width()) {
                this.set("_bVerticalView", true);
            } else {
                this.set("_bVerticalView", false);
            }
        },

        actions: {
            onStepperItemClicked: function onStepperItemClicked(sItemId, objStep) {
                if (!a24Core.isEmpty(this.get("onStepperItemClicked")) && objStep.bEnabled) {
                    this.get("onStepperItemClicked")(objStep.sStepNumber, objStep.sTitle, sItemId);
                }
            },

            onLinkActionStepperItemClicked: function onLinkActionStepperItemClicked() {
                if (!a24Core.isEmpty(this.get("onStepperItemClicked"))) {
                    this.get("onStepperItemClicked").apply(undefined, arguments);
                }
            },

            onLinkActionItemStateChanged: function onLinkActionItemStateChanged(sItemId, objItemData) {
                this.set("_objStepItems." + sItemId, objItemData);
            }
        }
    });
});