define("a24-ember-lib/services/a24-enums-ember-lib", ["exports", "a24-ember-lookup/services/a24-enums-base"], function (exports, _a24EnumsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24EnumsBase.default.extend({
        _objStringsService: Ember.inject.service("a24-strings"),
        _objConstantsLib: Ember.inject.service("a24-constants-ember-lib"),

        init: function init() {
            this._super.apply(this, arguments);

            var objStrings = this.get("_objStringsService");
            var objConstants = this.get("_objConstantsLib");

            this.set("dateFilterOptions", {
                "DATE_FILTER_TODAY": {
                    "backend": "dateFilterToday",
                    "frontend": objStrings.getString("dateFilterToday")
                },
                "DATE_FILTER_YESTERDAY": {
                    "backend": "dateFilterYesterday",
                    "frontend": objStrings.getString("dateFilterYesterday")
                },
                "DATE_FILTER_THIS_WEEK_TODAY": {
                    "backend": "dateFilterThisWeekToday",
                    "frontend": objStrings.getString("dateFilterThisWeekToday")
                },
                "DATE_FILTER_THIS_WEEK_TO_FRI": {
                    "backend": "dateFilterThisWeekToFri",
                    "frontend": objStrings.getString("dateFilterThisWeekToFri")
                },
                "DATE_FILTER_THIS_WEEK_TO_SUN": {
                    "backend": "dateFilterThisWeekToSun",
                    "frontend": objStrings.getString("dateFilterThisWeekToSun")
                },
                "DATE_FILTER_THIS_LAST_7_DAYS": {
                    "backend": "dateFilterLast7Days",
                    "frontend": objStrings.getString("dateFilterLast7Days")
                },
                "DATE_FILTER_THIS_LAST_WEEK_TO_FRI": {
                    "backend": "dateFilterLastWeekToFri",
                    "frontend": objStrings.getString("dateFilterLastWeekToFri")
                },
                "DATE_FILTER_THIS_LAST_WEEK_TO_SUN": {
                    "backend": "dateFilterLastWeekToSun",
                    "frontend": objStrings.getString("dateFilterLastWeekToSun")
                },
                "DATE_FILTER_THIS_LAST_14_DAYS": {
                    "backend": "dateFilterLast14Days",
                    "frontend": objStrings.getString("dateFilterLast14Days")
                },
                "DATE_FILTER_THIS_MONTH_TO_TODAY": {
                    "backend": "dateFilterThisMonthToToday",
                    "frontend": objStrings.getString("dateFilterThisMonthToToday")
                },
                "DATE_FILTER_THIS_MONTH_TO_END": {
                    "backend": "dateFilterThisMonthToEnd",
                    "frontend": objStrings.getString("dateFilterThisMonthToEnd")
                },
                "DATE_FILTER_THIS_LAST_30_DAYS": {
                    "backend": "dateFilterLast30Days",
                    "frontend": objStrings.getString("dateFilterLast30Days")
                },
                "DATE_FILTER_THIS_LAST_MONTH": {
                    "backend": "dateFilterLastMonth",
                    "frontend": objStrings.getString("dateFilterLastMonth")
                },
                "DATE_FILTER_WITHIN_THE_LAST": {
                    "backend": "dateFilterWithinTheLast",
                    "frontend": objStrings.getString("dateFilterWithinTheLast")
                },
                "DATE_FILTER_WITHIN_THE_NEXT": {
                    "backend": "dateFilterWithinTheNext",
                    "frontend": objStrings.getString("dateFilterWithinTheNext")
                },
                "DATE_FILTER_NOT_WITHIN_THE_LAST": {
                    "backend": "dateFilterNotWithinTheLast",
                    "frontend": objStrings.getString("dateFilterNotWithinTheLast")
                },
                "DATE_FILTER_NOT_WITHIN_THE_NEXT": {
                    "backend": "dateFilterNotWithinTheNext",
                    "frontend": objStrings.getString("dateFilterNotWithinTheNext")
                },
                "DATE_FILTER_CUSTOM_RANGE": {
                    "backend": "dateFilterCustomRange",
                    "frontend": objStrings.getString("dateFilterCustomRange")
                }
            });
            this.set("dateFilterOptionsFutureDates", {
                "DATE_FILTER_FUTURE_DATES_TODAY": {
                    "backend": "dateFilterFutureDatesToday",
                    "frontend": objStrings.getString("dateFilterFutureDatesToday")
                },
                "DATE_FILTER_FUTURE_DATES_REST_OF_WEEK": {
                    "backend": "dateFilterFutureDatesRestOfWeek",
                    "frontend": objStrings.getString("dateFilterFutureDatesRestOfWeek")
                },
                "DATE_FILTER_FUTURE_DATES_REST_OF_MONTH": {
                    "backend": "dateFilterFutureDatesRestOfMonth",
                    "frontend": objStrings.getString("dateFilterFutureDatesRestOfMonth")
                },
                "DATE_FILTER_FUTURE_DATES_WITHIN_THE_NEXT": {
                    "backend": "dateFilterFutureDatesWithinTheNext",
                    "frontend": objStrings.getString("dateFilterFutureDatesWithinTheNext")
                },
                "DATE_FILTER_FUTURE_DATES_CUSTOM": {
                    "backend": "dateFilterFutureDatesCustomRange",
                    "frontend": objStrings.getString("dateFilterFutureDatesCustomRange")
                }
            });

            this.set("dateFilterOptionsBetween", {
                "DATE_FILTER_BETWEEN": {
                    "backend": "dateFilterBetween",
                    "frontend": objStrings.getString("dateFilterBetween")
                }
            });

            this.set("dateFilterOptionsExpiry", {
                "DATE_FILTER_EXPIRY_NOT_EXPIRED": {
                    "backend": "dateFilterExpiryNotExpired",
                    "frontend": objStrings.getString("dateFilterExpiryNotExpired")
                },
                "DATE_FILTER_EXPIRY_NOT_EXPIRED_GRACE": {
                    "backend": "dateFilterExpiryNotExpiredGrace",
                    "frontend": objStrings.getString("dateFilterExpiryNotExpiredGrace")
                },
                "DATE_FILTER_EXPIRY_WILL_NOT_EXPIRE": {
                    "backend": "dateFilterExpiryWillNotExpire",
                    "frontend": objStrings.getString("dateFilterExpiryWillNotExpire")
                },
                "DATE_FILTER_EXPIRY_AFTER": {
                    "backend": "dateFilterExpiryAfter",
                    "frontend": objStrings.getString("dateFilterExpiryAfter")
                }
            });

            this.set("dateFilterOptionsObtained", {
                "DATE_FILTER_OBTAINED_WITHIN_LAST": {
                    "backend": "dateFilterObtainedWithinLast",
                    "frontend": objStrings.getString("dateFilterObtainedWithinLast")
                },
                "DATE_FILTER_OBTAINED_AT_LEAST": {
                    "backend": "dateFilterObtainedAtLeast",
                    "frontend": objStrings.getString("dateFilterObtainedAtLeast")
                }
            });

            this.set("dateFilterOptionsNotEmpty", {
                "DATE_FILTER_NOT_EMPTY": {
                    "backend": "dateFilterNotEmpty",
                    "frontend": objStrings.getString("dateFilterNotEmpty")
                }
            });

            this.set("filterOptionsTextType", {
                "IS": {
                    "backend": objConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_IS,
                    "frontend": objStrings.getString("is")
                },
                "IS_NOT": {
                    "backend": objConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_IS_NOT,
                    "frontend": objStrings.getString("isNot")
                },
                "STARTS_WITH": {
                    "backend": objConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_STARTS_WITH,
                    "frontend": objStrings.getString("startsWith")
                },
                "ENDS_WITH": {
                    "backend": objConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_ENDS_WITH,
                    "frontend": objStrings.getString("endsWith")
                }
            });

            this.set("filterOptionsType", {
                "IS_NOT": {
                    "backend": objConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_IS_NOT,
                    "frontend": objStrings.getString("isNot")
                }
            });

            this.set("intFilterOptions", {
                "EQUAL_TO": {
                    "backend": "equal_to",
                    "frontend": objStrings.getString("equalTo")
                },
                "BIGGER_THAN": {
                    "backend": "bigger_than",
                    "frontend": objStrings.getString("biggerThan")
                },
                "SMALLER_THAN": {
                    "backend": "smaller_than",
                    "frontend": objStrings.getString("smallerThan")
                }
            });
        }
    });
});