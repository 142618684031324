define("a24-ember-candidate-profile/components/tab-agency-candidate-profile-references-react", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/tab-agency-candidate-profile-references-react"], function (exports, _didRenderChangesMixin, _tabAgencyCandidateProfileReferencesReact) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _tabAgencyCandidateProfileReferencesReact.default,
        objQueryParams: null,
        _sCandidateId: null,
        _sViewReference: null,
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigation: Ember.inject.service("navigation"),
        _objBasicInfoService: Ember.inject.service("rest-agency-candidate-basic-info"),
        _objStringsCandidateProfile: Ember.inject.service("a24-strings-candidate-profile"),
        _objStatusErrorPageService: Ember.inject.service("status-error-page"),
        _objQueryParamBuilderService: Ember.inject.service("query-param-object-builder"),
        _bDisplayPermissionError: false,

        _bReactPopupOpen: false,
        _bReactPopupLoading: false,
        _objReactPopupPostData: null,

        init: function init() {
            this._super.apply(this, arguments);

            var objCandidateViewParams = this.get("_objNavigation.objRouteParams")["index.agency-candidate-profile"];

            var objCandidateSearchViewParams = this.get("_objNavigation.objRouteParams")["index.agency-candidate-search-view"];

            if (!a24Core.isEmpty(objCandidateViewParams)) {
                this.set("_sCandidateId", objCandidateViewParams.candidate_id);
            }
            if (!a24Core.isEmpty(objCandidateSearchViewParams)) {
                this.set("_sCandidateId", objCandidateSearchViewParams.candidate_id);
            }
            if (!a24Core.isEmpty(this.get("_objNavigation.objRouteQueryParams.viewReference"))) {
                this.set("_sViewReference", this.get("_objNavigation.objRouteQueryParams.viewReference"));
            }

            var objEnv = Ember.getOwner(this).resolveRegistration("config:environment");

            if (a24Core.isEmpty(this.get("_sIFrameUrl"))) {
                this.set("_sIFrameUrl", objEnv.sReactGatewayUrl);
            }

            if (!a24Core.isEmpty(this.get("_objNavigation.objRouteQueryParams.viewReference"))) {
                var arrQueryParams = ["referencepage"];
                var objQueryParamsUpdate = { referencepage: "v2" };
                objQueryParamsUpdate = Ember.Object.create(objQueryParamsUpdate);

                this.set("objQueryParams", this.get("_objQueryParamBuilderService").createQueryParamObject(arrQueryParams, objQueryParamsUpdate, this.get("objQueryParams"), false, true));

                this.set("_objNavigation.objRouteQueryParams.referencepage", "v2");
            }

            var objReactData = {
                candidateId: this.get("_sCandidateId")
            };

            if (!a24Core.isEmpty(this.get("_sViewReference"))) {
                var objViewRef = JSON.parse(atob(this.get("_sViewReference")));
                objReactData = Object.assign(objReactData, objViewRef);
            }

            this.set("_objReactIframeData", {
                type: "trigger_route",
                data: {
                    route: "candidate/references",
                    data: objReactData
                }
            });

            this.getBasicInfo();
        },

        _setTitleBar: function _setTitleBar() {
            this.get("_objTitleBarService").setTitleBarPartially(this.get("_objStringsCandidateProfile").getString("candidateProfile") + " - " + this.get("_objBasicInfoService.objLastCandidateDetails.first_name") + " " + this.get("_objBasicInfoService.objLastCandidateDetails.last_name"));
        },

        getBasicInfo: function getBasicInfo() {
            var _this = this;

            if (!a24Core.isEmpty(this.get("_objBasicInfoService.objLastCandidateDetails"))) {
                this._setTitleBar();
            } else {
                this.get("_objBasicInfoService").getBasicInfo(this, "_objBasicInfoGetPromise", this.get("_sCandidateId"), function () {
                    _this._setTitleBar();
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
                function (sCode) {
                    if (sCode === "403") {
                        _this.set("_objStatusErrorPageService.sIcon", "warning");
                        _this.set("_objStatusErrorPageService.sMessage", _this.get("_objStringsCandidateProfile").getString("permissionsError"));
                        _this.set("_bDisplayPermissionError", true);
                    }
                }));
            }
        },
        actions: {
            onReceiveReactData: function onReceiveReactData() {}
        }
    });
});