define("a24-ember-candidate-profile/components/page-agency-group-candidate-view", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/page-agency-group-candidate-view", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _pageAgencyGroupCandidateView, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _pageAgencyGroupCandidateView.default,
        classNames: ["candidate-notifications-container"],
        _sCandidateId: null,
        _sCandidateName: null,
        _arrReloadActions: null,
        _bDisplayReloadCard: false,
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigationService: Ember.inject.service("navigation"),

        _objStringsService: Ember.inject.service("a24-strings"),
        _objStringsCandidateProfile: Ember.inject.service("a24-strings-candidate-profile"),
        _objBasicInfoRestService: Ember.inject.service("rest-agency-group-candidate-basic-info"),
        _objStringsSSCore: Ember.inject.service("a24-strings-staffshift-core"),
        _objAvailabilityStrings: Ember.inject.service("a24-strings-staffshift-availability"),
        _objRestAgencyGroupCandidateInvite: Ember.inject.service("rest-invitation-verification"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objUserSession: Ember.inject.service("user-session"),
        _objCandidateTypeTabsService: Ember.inject.service("candidate-type-tabs"),
        _objStaffshiftCoreEnums: Ember.inject.service("a24-enums-staffshift-core"),
        _objSettingsRestService: Ember.inject.service("rest-agency-group-candidate-settings"),
        _objRestAgencyGroupCandidateReportInterviewProfile: Ember.inject.service("rest-agency-group-candidate-report-interview-profile"),
        _objCandidateDocumentGeneration: Ember.inject.service("candidate-document-generation-service"),
        _objRestCandidateType: Ember.inject.service("rest-agency-group-candidate-type"),
        _objConstantsSSCore: Ember.inject.service("a24-constants-staffshift-core"),
        _objSystemService: Ember.inject.service("rest-system"),
        _objReportInterviewDocumentResponse: null,
        _objCandidateTypesResponse: null,

        _objResendInviteServiceCall: null,
        _bInvitePopupLoading: false,
        _bShowResendInvitePopup: false,
        _bHasSkills: false,
        _bHasQualifications: false,
        _bHasProfessionalRegistrations: false,
        _sCurrentRouteName: "",
        _iLastSkillTabIndex: null,
        _iLastQualificationTabIndex: null,
        _iLastProfessionalRegistrationTabIndex: null,

        _bShowResendInviteSuccessPopup: false,
        _sResendInviteSuccessMessage: null,
        _objRestAgencyNotificationTypes: Ember.inject.service("rest-agency-group-notification-types"),
        _objRestAgencyCandidateNotification: Ember.inject.service("rest-agency-group-candidate-notification"),
        _objRestAgencyNotificationTypeTemplates: Ember.inject.service("rest-agency-group-notification-type-templates"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),

        _bShowReplyBackWithInput: false,
        _bShowReplyWithInput: false,
        _bShowCampaignNameInput: false,
        _bShowContentInput: false,
        _bShowRegards: false,
        _bShowReplyWithInputContact: false,

        _arrCandidateTypes: null,
        _arrPositionTypes: null,
        _bServerError: false,
        _bSendNotificationPopupLoading: true,
        _bShowSendNotificationPopup: false,
        _arrNotificationTypeDropdown: null,
        _objGetNotificationTypesServiceCall: null,
        _bShowNotificationPreviewPopup: false,
        _bDisplayPreviewSend: false,
        _sNotificationId: null,
        _objGetNotificationServiceCall: null,
        _objPreviewNotificationServiceCall: null,
        _objSendNotificationServiceCall: null,
        _objNotificationPreview: null,
        _bNotificationTypeLoading: false,
        _objSpecialNotificationPopupAction: null,
        _objSpecialNotificationTypePreviewPopupAction: null,
        _objOriginalData: null,
        _arrNotificationTypeComponents: null,
        _arrComponents: null,
        _bIsEmail: null,
        _bShowNotificationTypes: false,
        _arrNotificationCache: null,
        _bShowSentMessagePopup: false,
        _objBasicInfoGetPromise: null,

        _sPreviewLabel: "",

        _bShowField1: false,
        _bShowField2: false,
        _bShowField3: false,
        _bShowField4: false,
        _bShowField5: false,

        _sLabelField1: "",
        _sLabelField2: "",
        _sLabelField3: "",
        _sLabelField4: "",
        _sLabelField5: "",

        _bRequiredField1: false,
        _bRequiredField2: false,
        _bRequiredField3: false,
        _bRequiredField4: false,
        _bRequiredField5: false,

        _bComplianceUpdateNotification: false,
        _bShowNotificationInputs: Ember.computed("_bComplianceUpdateNotification", "_bCandidateCallbackRequestedNotification", "_bCandidateReminderNotification", function () {
            return this.get("_bComplianceUpdateNotification") || this.get("_bCandidateCallbackRequestedNotification") || this.get("_bCandidateReminderNotification");
        }),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_objBasicInfoRestService.objLastCandidateDetails", null);

            var objCandidateViewParams = this.get("_objNavigationService.objRouteParams")["agency-group.candidate-view"];
            var objCandidateSearchViewParams = this.get("_objNavigationService.objRouteParams")["agency-group.candidate-search-view"];

            if (!a24Core.isEmpty(objCandidateViewParams)) {
                this.set("_sCandidateId", objCandidateViewParams.candidate_id);
            }
            if (!a24Core.isEmpty(objCandidateSearchViewParams)) {
                this.set("_sCandidateId", objCandidateSearchViewParams.candidate_id);
            }

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objStringsCandidateProfile").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    // For now this does nothing since nothing sets _bServerError as true, but leaving this and the hbs
                    // parts there if we need this in future again.
                }
            }]);

            var objValidationConfig = {};

            objValidationConfig["objChildren.campaign_name"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };

            objValidationConfig["objChildren.reply_back_with"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };

            objValidationConfig["objChildren.reply_with_contact"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };

            objValidationConfig["objChildren.reply_with"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
                a24Validation.addMaxLength(objMixin, 50);
            };

            objValidationConfig["objChildren.content"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
                a24Validation.addMaxLength(objMixin, 200);
            };

            objValidationConfig["objChildren.regards"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
                a24Validation.addMaxLength(objMixin, 100);
            };

            this.set("objChildConfig", objValidationConfig);

            this.set("_objSpecialNotificationPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.onPopupNotificationCancel();
                }
            });

            this.set("_objSpecialNotificationTypePreviewPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.set("_bShowNotificationPreviewPopup", false);
                }
            });

            this.setTitleBarTextWithSetup();

            this.set("_arrNotificationCache", {});
        },
        setTitleBarTextWithSetup: function setTitleBarTextWithSetup() {
            var _this2 = this;

            var objThis = this;
            var objActionItems = {
                arrActionItems: []
            };

            objActionItems.arrActionItems.pushObject({
                sTitle: this.get("_objStringsCandidateProfile").getString("generateCandidateInterviewDocument"),
                sIcon: "assignment_ind",
                executeCardAction: function executeCardAction() {
                    objThis._onGenerateInterviewDocClick();
                }
            });

            // check settings
            if (this.get("_objSettingsRestService.objSettings.invitation_status") === "not_used" || this.get("_objSettingsRestService.objSettings.user_status") === "awaiting_verification" || a24Core.isEmpty(this.get("_objSettingsRestService.objSettings.user_status")) && this.get("_objSettingsRestService.objSettings.invitation_status") !== "used" || a24Core.isEmpty(this.get("_objSettingsRestService.objSettings.user_status")) && a24Core.isEmpty(this.get("_objSettingsRestService.objSettings.invitation_status"))) {
                objActionItems.arrActionItems.pushObject({
                    sTitle: this.get("_objStringsCandidateProfile").getString("resendInviteEmail"),
                    sIcon: "email",
                    executeCardAction: function executeCardAction() {
                        objThis.onResendEmailClick();
                    }
                });
            }

            objActionItems.arrActionItems.pushObject({
                sTitle: this.get("_objStringsCandidateProfile").getString("sendNotification"),
                sIcon: "notifications",
                executeCardAction: function executeCardAction() {
                    objThis.onNoticationEmailClick();
                }
            });

            this.get("_objTitleBarService").setTitleBar(this.get("_objStringsCandidateProfile").getString("candidateProfile"), function () {
                _this2.get("_objNavigationService").navigateBack();
            }, false, "card-actions-component", objActionItems);
        },
        openWindowWithHtml: function openWindowWithHtml(sHtml) {
            var objNewWindow = window.open();

            if (objNewWindow !== null) {
                objNewWindow.document.write(sHtml);
                objNewWindow.document.close();
                objNewWindow.focus();
            }
        },
        _generateInterviewDocument: function _generateInterviewDocument() {
            var _this3 = this;

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsCandidateProfile").getString("generateReport"));

            this.get("_objRestAgencyGroupCandidateReportInterviewProfile").getCandidateReportInterviewProfile(this, "_objReportInterviewDocumentResponse", this.get("_sCandidateId"), function (objData) {
                _this3.set("_objCandidateDocumentGeneration.arrTypes", _this3.get("_arrCandidateTypes"));
                var sHtml = _this3.get("_objCandidateDocumentGeneration").generateInterviewCandidateProfile(objData);
                _this3.openWindowWithHtml(sHtml);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this3.get("_objSnackbarService").showSnackbarMessage(_this3.get("_objStringsCandidateProfile").getString("failGenerateReport"));
            }));
        },
        _onGenerateInterviewDocClick: function _onGenerateInterviewDocClick() {
            var _this4 = this;

            if (a24Core.isEmpty(this.get("_arrCandidateTypes"))) {
                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsCandidateProfile").getString("preparingGeneration"));
                this.get("_objRestCandidateType").getCandidateTypes(this, "_objCandidateTypesResponse", this.get("_objUserSession").getUserContextReference().mId, function (objResponse) {
                    _this4.set("_arrCandidateTypes", objResponse);
                    _this4._generateInterviewDocument();
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    _this4.get("_objSnackbarService").showSnackbarMessage(_this4.get("_objStringsCandidateProfile").getString("failGenerateReport"));
                }));
            } else {
                this._generateInterviewDocument();
            }
        },
        onResendEmailClick: function onResendEmailClick() {
            this.set("_bInvitePopupLoading", false);
            // Show the popup
            this.set("_bShowResendInvitePopup", true);
        },
        _funcResetNotificationFields: function _funcResetNotificationFields() {
            this.set("_sPreviewLabel", "");

            this.set("_bShowField1", false);
            this.set("_bShowField2", false);
            this.set("_bShowField3", false);
            this.set("_bShowField4", false);
            this.set("_bShowField5", false);

            this.set("_sLabelField1", "");
            this.set("_sLabelField2", "");
            this.set("_sLabelField3", "");
            this.set("_sLabelField4", "");
            this.set("_sLabelField5", "");

            this.set("_bRequiredField1", false);
            this.set("_bRequiredField2", false);
            this.set("_bRequiredField3", false);
            this.set("_bRequiredField4", false);
            this.set("_bRequiredField5", false);
        },
        onNoticationEmailClick: function onNoticationEmailClick() {
            var _this5 = this;

            this.clearForm();

            this.set("_bShowSendNotificationPopup", true);
            this.set("_bDisplayPreviewSend", false);

            var objPromiseList = {};
            var arrRequiredResponses = [];

            if (a24Core.isEmpty(this.get("_arrNotificationTypeDropdown"))) {
                this.set("_bSendNotificationPopupLoading", true);
                var objExtraParams = {
                    enabled: {
                        mValue: true
                    }
                };

                var objNotificationsPromise = this.get("_objRestAgencyNotificationTypes").getNotificationTypes(this, "_objGetNotificationTypesServiceCall", function (arrData) {
                    if (!a24Core.isEmpty(arrData)) {

                        var arrNotificationTypeDropdown = [];
                        for (var l = 0; l < arrData.length; l++) {
                            arrNotificationTypeDropdown.push({
                                sTitle: arrData[l].name,
                                sValue: arrData[l].metadata.type
                            });
                        }

                        _this5.set("_arrNotificationTypeDropdown", arrNotificationTypeDropdown);
                    }
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    var sServerError = _this5.get("_objStringsService").getString("serverError");
                    _this5.get("_objSnackbarService").showSnackbarMessage(sServerError);

                    _this5.set("_bShowSendNotificationPopup", false);
                }), objExtraParams);

                objPromiseList._objNotificationsPromise = objNotificationsPromise;
                arrRequiredResponses.push("_objNotificationsPromise");
            }

            if (a24Core.isEmpty(this.get("_objBasicInfoRestService.objLastCandidateDetails"))) {
                this.set("_bSendNotificationPopupLoading", true);
                var objBasicInfoPromise = this.get("_objBasicInfoRestService").getBasicInfo(this, "_objBasicInfoGetPromise", this.get("_sCandidateId"), function () {
                    // Do nothing since the service will store the candidate data and we use it from there
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
                function () {
                    //Do nothing, we simply wont be able to autocomplete the candidate name
                }));

                objPromiseList._objBasicInfoPromise = objBasicInfoPromise;
                arrRequiredResponses.push("_objBasicInfoPromise");
            }

            if (arrRequiredResponses.length > 0) {
                a24RSVP.configure(this, objPromiseList, [{
                    arrRequiredResponses: arrRequiredResponses,
                    funcOnComplete: function funcOnComplete() {
                        _this5.set("_bSendNotificationPopupLoading", false);
                    }
                }]);
            }
        },
        hidePopupAndShowSnackbar: function hidePopupAndShowSnackbar(sMessage) {
            // Hide the popup
            this.set("_bShowResendInvitePopup", false);
            // Show the snackbar message
            this.get("_objSnackbarService").showSnackbarMessage(sMessage);
        },

        onInputValueChanged: function onInputValueChanged(arrPathToInputMixin) {
            var _this6 = this;

            var sLookup = arrPathToInputMixin.join(".");

            if (sLookup === "objChildren._objNotificationType") {
                if (a24Core.isEmpty(this.get("objChildren._objNotificationType.mValue"))) {
                    this.set("_bDisplayPreviewSend", false);
                    this.set("_bShowNotificationTypes", false);
                } else {
                    this.set("_sNotificationId", this.get("objChildren._objNotificationType.mValue.sValue"));

                    this.set("_bComplianceUpdateNotification", false);
                    this._funcResetNotificationFields();

                    this.set("_bShowCampaignNameInput", false);
                    this.set("_bShowReplyWithInput", false);
                    this.set("_bShowReplyBackWithInput", false);
                    this.set("_bShowContentInput", false);
                    this.set("_bShowRegards", false);
                    this.set("_bShowReplyWithInputContact", false);
                    if (this.get("_sNotificationId") === "failed_to_contact_registration") {
                        this.set("_bShowReplyWithInputContact", true);
                    } else if (this.get("_sNotificationId") === "failed_to_contact_campaign_registration") {
                        this.set("_bShowCampaignNameInput", true);
                        this.set("_bShowReplyBackWithInput", true);
                    } else if (this.get("_sNotificationId") === "compliance_update_request") {
                        this.set("_bComplianceUpdateNotification", true);
                    } else if (this.get("_sNotificationId") === "callback_requested_notification") {
                        this.set("_bShowReplyWithInput", true);
                        this.set("_bShowContentInput", true);
                        this.set("_bShowRegards", true);
                    } else if (this.get("_sNotificationId") === "candidate_reminder_on_action") {
                        this.set("_bShowReplyWithInput", true);
                        this.set("_bShowContentInput", true);
                        this.set("_bShowRegards", true);
                    }

                    this.set("_bShowNotificationTypes", true);
                    if (a24Core.isEmpty(this.get("_arrNotificationCache." + this.get("_sNotificationId")))) {

                        var arrAgencyGroups = this.get("_objSettingsRestService.objSettings.agency_groups");

                        var arrCandidateTypes = [];
                        if (!a24Core.isEmpty(arrAgencyGroups)) {
                            for (var i = 0; i < arrAgencyGroups.length; i++) {
                                if (!a24Core.isEmpty(arrAgencyGroups[i].candidate_type)) {
                                    arrCandidateTypes.push(arrAgencyGroups[i].candidate_type);
                                }
                            }
                        }

                        var objExtraParams = {
                            position_types: {
                                mValue: arrCandidateTypes
                            },
                            context_notification_type: {
                                mValue: this.get("_sNotificationId")
                            },
                            status: {
                                mValue: "enabled"
                            }
                        };

                        this.set("_bNotificationTypeLoading", true);
                        this.set("_bDisplayPreviewSend", false);
                        this.get("_objRestAgencyNotificationTypeTemplates").getNotificationTypeTemplates(this, "_objGetNotificationTypesServiceCall", function (arrData) {
                            if (!a24Core.isEmpty(arrData)) {
                                _this6.set("_arrNotificationTypeComponents", _this6._setupTagComponents(arrData));
                                _this6.set("_bDisplayPreviewSend", true);
                            } else {
                                _this6.set("_arrNotificationTypeComponents", []);
                            }
                            _this6.set("_arrNotificationCache." + _this6.get("_sNotificationId"), _this6.get("_arrNotificationTypeComponents"));
                            _this6._funcPrepopulateNotificationFields();
                            _this6.set("_bNotificationTypeLoading", false);
                            _this6.set("_bShowNotificationTypes", true);
                        }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                            var sServerError = _this6.get("_objStringsService").getString("serverError");
                            _this6.get("_objSnackbarService").showSnackbarMessage(sServerError);
                            _this6.set("_bNotificationTypeLoading", false);
                            _this6.set("_bDisplayPreviewSend", false);
                            _this6.set("_bShowNotificationTypes", false);
                        }), objExtraParams);
                    } else {
                        this.set("_arrNotificationTypeComponents", this.get("_arrNotificationCache." + this.get("_sNotificationId")));
                        this._funcPrepopulateNotificationFields();
                        this.set("_bDisplayPreviewSend", true);
                        this.set("_bNotificationTypeLoading", false);
                    }
                }
            }
        },
        setPriorityGroupNames: function setPriorityGroupNames(arrGroupings) {
            // replace priority 1 with Primary Delivery
            // replace 2 with 1st Fallback
            // replace 3 with 2nd Fallback
            // replace 4 with 3rd Fallback
            arrGroupings[0].sTitle = this.get("_objStringsCandidateProfile").getString("primaryDelivery");
            if (arrGroupings.length > 1) {
                arrGroupings[1].sTitle = this.get("_objStringsCandidateProfile").getString("firstFallback");
            }
            if (arrGroupings.length > 2) {
                arrGroupings[2].sTitle = this.get("_objStringsCandidateProfile").getString("secondFallback");
            }
            if (arrGroupings.length > 3) {
                arrGroupings[3].sTitle = this.get("_objStringsCandidateProfile").getString("thirdFallback");
            }
            if (arrGroupings.length > 4) {
                for (var i = 4; i < arrGroupings.length; i++) {
                    arrGroupings[i].sTitle = i + this.get("_objStringsCandidateProfile").getString("th") + " " + this.get("_objStringsCandidateProfile").getString("fallback");
                }
            }
            return arrGroupings;
        },
        _setupTagComponents: function _setupTagComponents(arrNotificationTypeTemplates) {
            var _this7 = this;

            var objThis = this;
            var funcGroupFilterExecute = function funcGroupFilterExecute(objItem) {
                return function (objComponent) {
                    return objItem.priority === objComponent.objData.mValue.priority;
                };
            };

            var arrGroupings = arrNotificationTypeTemplates.uniqBy("priority").sortBy("priority").map(function (objItem) {
                return {
                    sTitle: objItem.priority,
                    funcGroupFilter: funcGroupFilterExecute(objItem)
                };
            });

            arrGroupings = this.setPriorityGroupNames(arrGroupings);

            var arrComponents = arrNotificationTypeTemplates.map(function (objEntry) {
                return {
                    sComponent: "tag-component-shrink",
                    objData: {
                        sTitle: _this7.get("_objStaffshiftCoreEnums").getFrontendValue("mediumType", objEntry.type),
                        mValue: objEntry,
                        sColor: "a24-primary-color-hue-3-force white-text",
                        sActionIcon: "remove_red_eye",
                        sActionIconColor: "white-text"
                    },
                    objActions: {
                        onTagClicked: function onTagClicked(sTitle, objItem) {
                            objThis.clearAllErrorMessages();
                            if (!objThis.doFullValidation()) {
                                var objItemData = Ember.copy(objItem.data, true);
                                var objData = objThis.getPlainFormValueObject();

                                if (objItem.type === objThis.get("_objStaffshiftCoreEnums.mediumType.EMAIL.backend")) {
                                    objItemData.html = objThis._funcTokenReplaceNotificationPreview(objData, objItemData.html);
                                } else {
                                    objItemData.text = objThis._funcTokenReplaceNotificationPreview(objData, objItemData.text);
                                }

                                // show medium preview
                                objThis.activityNotificationFromMedium(objItem.type, objItemData);
                                objThis.set("_bShowNotificationPreviewPopup", true);
                            }
                        }
                    }
                };
            });

            return {
                arrComponents: arrComponents,
                arrGroupings: arrGroupings,
                sGroupTitleStyle: "font-md a24-grey-text-darken-1"
            };
        },
        _funcTokenReplaceNotificationPreview: function _funcTokenReplaceNotificationPreview(objData, sTokenString) {
            if (this.get("_bShowReplyWithInput")) {
                // Replace token for preview
                sTokenString = sTokenString.replace(new RegExp("{{reply_with}}", "g"), objData.reply_with);
            }
            if (this.get("_bShowReplyWithInputContact")) {
                // Replace token for preview
                sTokenString = sTokenString.replace(new RegExp("{{reply_with}}", "g"), objData.reply_with_contact);
            }
            if (this.get("_bShowCampaignNameInput")) {
                // Replace token for preview
                sTokenString = sTokenString.replace(new RegExp("{{campaign_name}}", "g"), objData.campaign_name);
            }
            if (this.get("_bShowReplyBackWithInput")) {
                // Replace token for preview
                sTokenString = sTokenString.replace(new RegExp("{{reply_back_with}}", "g"), objData.reply_back_with);
            }
            // tokens for callback_requested_notification
            if (this.get("_bShowContentInput")) {
                // Replace token for preview
                if (this.get("_sNotificationId") === "candidate_reminder_on_action") {
                    var sContent = objData.content + ". " + this.get("_objStringsCandidateProfile").getString("pleaseReplyWith") + " " + objData.reply_with;
                    sTokenString = sTokenString.replace(new RegExp("{{content}}", "g"), sContent);
                } else {
                    sTokenString = sTokenString.replace(new RegExp("{{content}}", "g"), objData.content);
                }

                var sTokenStringReplace = "";
                var sTitle = this.get("_objCandidateProfileEnumsService").getFrontendValue("title", this.get("_objBasicInfoRestService.objLastCandidateDetails.title"));

                if ((this.get("_objBasicInfoRestService.objLastCandidateDetails.candidate_type_id") === this.get("_objConstantsSSCore.UK_GP") || this.get("_objBasicInfoRestService.objLastCandidateDetails.candidate_type_id") === this.get("_objConstantsSSCore.UK_LOCUM") || this.get("_objBasicInfoRestService.objLastCandidateDetails.candidate_type_id") === this.get("_objConstantsSSCore.UK_LOCUM_ALLIED") || this.get("_objBasicInfoRestService.objLastCandidateDetails.candidate_type_id") === this.get("_objConstantsSSCore.ZA_LOCUM") || this.get("_objBasicInfoRestService.objLastCandidateDetails.candidate_type_id") === this.get("_objConstantsSSCore.ZA_LOCUM_ALLIED")) && !a24Core.isEmpty(this.get("_objBasicInfoRestService.objLastCandidateDetails.title"))) {
                    sTokenStringReplace = sTitle + " " + this.get("_objBasicInfoRestService.objLastCandidateDetails.last_name");
                } else {
                    sTokenStringReplace = this.get("_objBasicInfoRestService.objLastCandidateDetails.first_name");
                }

                sTokenString = sTokenString.replace(new RegExp("{{candidate_first_name}}", "g"), sTokenStringReplace);
            }

            if (this.get("_bShowRegards")) {
                // Replace token for preview
                sTokenString = sTokenString.replace(new RegExp("{{regards}}", "g"), objData.regards + ". " + this.get("_objUserSession.objContextDetails.sName") + ", " + this.get("_objStringsCandidateProfile").getString("aMemberOf"));
            }

            if (this.get("_bComplianceUpdateNotification")) {
                // {{greeting}} Please update {{update_entity}} as {{update_reason}}. Regards {{regards}}{{reply_with}}
                var sRegards = objData.field4;
                if (!a24Core.isEmpty(objData.field5)) {
                    sRegards = sRegards + ". " + this.get("_objStringsCandidateProfile").getString("pleaseReplyWith") + ": " + objData.field5;
                }
                sTokenString = sTokenString.replace("{{greeting}}", objData.field1 + ".");
                sTokenString = sTokenString.replace("{{update_entity}}", objData.field2);
                sTokenString = sTokenString.replace("{{update_reason}}", objData.field3);
                sTokenString = sTokenString.replace("{{regards}}", sRegards);
                return sTokenString;
            }

            return sTokenString;
        },
        activityNotificationFromMedium: function activityNotificationFromMedium(sType, objNotificationTypePreview) {
            if (sType === this.get("_objStaffshiftCoreEnums.mediumType.EMAIL.backend")) {
                this.set("_bIsEmail", true);
                this.set("_objNotificationPreview", {
                    sSubject: objNotificationTypePreview.subject,
                    objFrom: objNotificationTypePreview.send_by,
                    objTo: null,
                    arrCC: null,
                    arrBCC: null,
                    objReplyTo: objNotificationTypePreview.reply_to,
                    sHTML: objNotificationTypePreview.html
                });
            } else {
                this.set("_bIsEmail", false);
                this.set("_objNotificationPreview", {
                    collected_tokens: [],
                    sender_phone_number_formatted: null,
                    recipient_mobile_number_formatted: null,
                    medium: sType,
                    data: {
                        body: objNotificationTypePreview.text
                    },
                    created_at: null,
                    status: null
                });
            }
        },
        _funcSetInputStates: function _funcSetInputStates(sVal1, sVal2, sVal3, sVal4, sVal5, bDisabled1, bDisabled2, bDisabled3, bDisabled4, bDisabled5) {
            // Reset all inputs
            var objField1 = this.get("objChildren.field1");
            var objField2 = this.get("objChildren.field2");
            var objField3 = this.get("objChildren.field3");
            var objField4 = this.get("objChildren.field4");
            var objField5 = this.get("objChildren.field5");
            if (!a24Core.isEmpty(objField1)) {
                objField1.setValueFromOutside(sVal1 ? sVal1 : null);
                this.$("#" + objField1.get("_sInputId")).prop('disabled', bDisabled1 ? bDisabled1 : false);
                a24Validation.addRequired(objField1, this.get("_bRequiredField1"));
            }
            if (!a24Core.isEmpty(objField2)) {
                objField2.setValueFromOutside(sVal2 ? sVal2 : null);
                this.$("#" + objField2.get("_sInputId")).prop('disabled', bDisabled2 ? bDisabled2 : false);
                a24Validation.addRequired(objField2, this.get("_bRequiredField2"));
            }
            if (!a24Core.isEmpty(objField3)) {
                objField3.setValueFromOutside(sVal3 ? sVal3 : null);
                this.$("#" + objField3.get("_sInputId")).prop('disabled', bDisabled3 ? bDisabled3 : false);
                a24Validation.addRequired(objField3, this.get("_bRequiredField3"));
            }
            if (!a24Core.isEmpty(objField4)) {
                objField4.setValueFromOutside(sVal4 ? sVal4 : null);
                this.$("#" + objField4.get("_sInputId")).prop('disabled', bDisabled4 ? bDisabled4 : false);
                a24Validation.addRequired(objField4, this.get("_bRequiredField4"));
            }
            if (!a24Core.isEmpty(objField5)) {
                objField5.setValueFromOutside(sVal5 ? sVal5 : null);
                this.$("#" + objField5.get("_sInputId")).prop('disabled', bDisabled5 ? bDisabled5 : false);
                a24Validation.addRequired(objField5, this.get("_bRequiredField5"));
            }
        },
        _funcPrepopulateNotificationFields: function _funcPrepopulateNotificationFields() {
            var _this8 = this;

            //Reset error states when we switch notification types
            this.clearAllErrorMessages();

            if (this.get("_bComplianceUpdateNotification")) {
                this.set("_bShowField1", true);
                this.set("_bShowField2", true);
                this.set("_bShowField3", true);
                this.set("_bShowField4", true);
                this.set("_bShowField5", true);

                this.set("_sLabelField1", this.get("_objStringsCandidateProfile").getString("greeting"));
                this.set("_sLabelField2", this.get("_objStringsCandidateProfile").getString("updateEntity"));
                this.set("_sLabelField3", this.get("_objStringsCandidateProfile").getString("updateReason"));
                this.set("_sLabelField4", this.get("_objStringsCandidateProfile").getString("regards"));
                this.set("_sLabelField5", this.get("_objStringsCandidateProfile").getString("replyWith"));

                this.set("_bRequiredField1", true);
                this.set("_bRequiredField2", true);
                this.set("_bRequiredField3", true);
                this.set("_bRequiredField4", true);
                this.set("_bRequiredField5", true);

                Ember.run.next(function () {
                    if (a24Core.isEmpty(_this8)) {
                        return;
                    }
                    Ember.run.schedule("afterRender", function () {
                        _this8._funcSetInputStates(_this8.get("_objStringsCandidateProfile").getString("hi") + " " + _this8.get("_objBasicInfoRestService.objLastCandidateDetails.first_name"), null, null, _this8.get("_objUserSession").getUserFirstName());
                    });
                });
            } else if (this.get("_sNotificationId") === "callback_requested_notification") {
                // prepopulate the regards input with user's name
                Ember.run.next(function () {
                    if (a24Core.isEmpty(_this8)) {
                        return;
                    }
                    var sFullUsername = _this8.get("_objUserSession.objAuthData.identity.sFirstName") + " " + _this8.get("_objUserSession.objAuthData.identity.sLastName");
                    Ember.run.schedule("afterRender", function () {
                        _this8.get("objChildren.regards").setValueFromOutside(sFullUsername);
                    });
                });
            } else if (this.get("_sNotificationId") === "candidate_reminder_on_action") {
                // prepopulate the regards input with user's name
                Ember.run.next(function () {
                    if (a24Core.isEmpty(_this8)) {
                        return;
                    }
                    var sFullUsername = _this8.get("_objUserSession.objAuthData.identity.sFirstName") + " " + _this8.get("_objUserSession.objAuthData.identity.sLastName");
                    Ember.run.schedule("afterRender", function () {
                        _this8.get("objChildren.regards").setValueFromOutside(sFullUsername);
                    });
                });
            }
        },
        sendNotification: function sendNotification() {
            var _this9 = this;

            if (!this.doFullValidation()) {
                var objInputData = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());

                // call send notification service
                this.set("_bSendNotificationPopupLoading", true);

                var sCandidateId = this.get("_sCandidateId");
                var sNotificationId = this.get("_sNotificationId");

                var objData = {
                    type: sNotificationId,
                    data: {}
                };

                if (this.get("_bShowReplyWithInput")) {
                    objData.data = {
                        "reply_with": objInputData.reply_with
                    };
                }

                if (this.get("_bShowReplyWithInputContact")) {
                    objData.data = {
                        "reply_with": objInputData.reply_with_contact
                    };
                }

                if (this.get("_bShowCampaignNameInput") && this.get("_bShowReplyBackWithInput")) {
                    objData.data = {
                        "campaign_name": objInputData.campaign_name,
                        "reply_back_with": objInputData.reply_back_with
                    };
                }

                if (this.get("_bShowContentInput")) {
                    var sTokenStringReplace = "";
                    var sTitle = this.get("_objCandidateProfileEnumsService").getFrontendValue("title", this.get("_objBasicInfoRestService.objLastCandidateDetails.title"));
                    if ((this.get("_objBasicInfoRestService.objLastCandidateDetails.candidate_type_id") === this.get("_objConstantsSSCore.UK_GP") || this.get("_objBasicInfoRestService.objLastCandidateDetails.candidate_type_id") === this.get("_objConstantsSSCore.UK_LOCUM") || this.get("_objBasicInfoRestService.objLastCandidateDetails.candidate_type_id") === this.get("_objConstantsSSCore.UK_LOCUM_ALLIED") || this.get("_objBasicInfoRestService.objLastCandidateDetails.candidate_type_id") === this.get("_objConstantsSSCore.ZA_LOCUM") || this.get("_objBasicInfoRestService.objLastCandidateDetails.candidate_type_id") === this.get("_objConstantsSSCore.ZA_LOCUM_ALLIED")) && !a24Core.isEmpty(this.get("_objBasicInfoRestService.objLastCandidateDetails.title"))) {
                        sTokenStringReplace = sTitle + " " + this.get("_objBasicInfoRestService.objLastCandidateDetails.last_name");
                    } else {
                        sTokenStringReplace = this.get("_objBasicInfoRestService.objLastCandidateDetails.first_name");
                    }

                    if (this.get("_sNotificationId") === "candidate_reminder_on_action") {
                        var sContent = objInputData.content + ". " + this.get("_objStringsCandidateProfile").getString("pleaseReplyWith") + " " + objInputData.reply_with;

                        objData.data = {
                            candidate_first_name: sTokenStringReplace,
                            content: sContent,
                            regards: objInputData.regards + ". " + this.get("_objUserSession.objContextDetails.sName") + ", " + this.get("_objStringsCandidateProfile").getString("aMemberOf")
                        };
                    } else {
                        objData.data = {
                            candidate_first_name: sTokenStringReplace,
                            content: objInputData.content,
                            reply_with: objInputData.reply_with,
                            regards: objInputData.regards + ". " + this.get("_objUserSession.objContextDetails.sName") + ", " + this.get("_objStringsCandidateProfile").getString("aMemberOf")
                        };
                    }
                }

                if (this.get("_bComplianceUpdateNotification")) {
                    // {{greeting}} Please update {{update_entity}} as {{update_reason}}. Regards {{regards}}{{area}}
                    var sRegards = objInputData.field4;
                    if (!a24Core.isEmpty(objInputData.field5)) {
                        sRegards = sRegards + ". " + this.get("_objStringsCandidateProfile").getString("pleaseReplyWith") + ": " + objInputData.field5;
                    }
                    objData.data = {
                        greeting: objInputData.field1 + ".",
                        update_entity: objInputData.field2,
                        update_reason: objInputData.field3,
                        regards: sRegards
                    };
                }

                this.get("_objRestAgencyCandidateNotification").sendNotification(this, "_objSendNotificationServiceCall", sCandidateId, objData, function () {
                    _this9.set("_bSendNotificationPopupLoading", false);
                    _this9.set("_bShowSendNotificationPopup", false);
                    _this9.set("_bShowSentMessagePopup", true);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    var sServerError = "";
                    if (sCode === "404") {
                        sServerError = _this9.get("_objStringsCandidateProfile").getString("noNotificationForType");
                    } else {
                        sServerError = _this9.get("_objStringsService").getString("serverError");
                    }
                    _this9.get("_objSnackbarService").showSnackbarMessage(sServerError);

                    _this9.set("_bSendNotificationPopupLoading", false);
                }));
            }
        },
        onPopupNotificationCancel: function onPopupNotificationCancel() {
            if (this.get("_bSendNotificationPopupLoading")) {
                return;
            }

            this.set("_bShowSendNotificationPopup", false);
        },
        actions: {
            onPopupResendConfirm: function onPopupResendConfirm() {
                var _this10 = this;

                /**
                 * The user has confirmed that they wish to resend the invite
                 */

                // Set the popup into loading state
                this.set("_bInvitePopupLoading", true);

                // Get the ids that are required for the service call
                var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;
                var sCandidateId = this.get("_sCandidateId");
                // Call the service to resend candidate invite
                this.get("_objRestAgencyGroupCandidateInvite").resendCandidateInvite(this, "_objResendInviteServiceCall", sAgencyGroupId, sCandidateId, function (objData) {
                    // Show success message
                    if (objData.status === "invite_sent") {
                        _this10.set("_sResendInviteSuccessMessage", _this10.get("_objStringsCandidateProfile").getString("sendInviteEmailSuccess"));
                    } else if (objData.status === "invite_resent") {
                        _this10.set("_sResendInviteSuccessMessage", _this10.get("_objStringsCandidateProfile").getString("resendInviteEmailSuccess"));
                    } else if (objData.status === "verification_sent") {
                        _this10.set("_sResendInviteSuccessMessage", _this10.get("_objStringsCandidateProfile").getString("sendVerificationEmailSuccess"));
                    } else if (objData.status === "nothing_sent") {
                        _this10.set("_sResendInviteSuccessMessage", _this10.get("_objStringsCandidateProfile").getString("noInviteVerificationEmailSent"));
                    }

                    _this10.set("_bShowResendInvitePopup", false);
                    _this10.set("_bShowResendInviteSuccessPopup", true);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE"], function () {
                    // Show failure message
                    _this10.hidePopupAndShowSnackbar(_this10.get("_objStringsCandidateProfile").getString("resendInviteEmailFailure"));
                    _this10.set("_bShowResendInvitePopup", false);
                }));
            },
            onPopupResendCancel: function onPopupResendCancel() {
                // Hide the popup
                this.set("_bShowResendInvitePopup", false);
            },
            onPopupResendSuccessCancel: function onPopupResendSuccessCancel() {
                // Hide the popup
                this.set("_sResendInviteSuccessMessage", null);
                this.set("_bShowResendInviteSuccessPopup", false);
            },
            onPopupSendNotificationCancel: function onPopupSendNotificationCancel() {
                this.onPopupNotificationCancel();
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowSendNotificationPopup", false);
                this.set("_bShowUnsavedData", false);
            },
            onPopupSendNotificationPreviewSend: function onPopupSendNotificationPreviewSend() {
                // call send notification service
                this.sendNotification();
            },
            onNotificationPreviewPopupClose: function onNotificationPreviewPopupClose() {
                this.set("_bShowNotificationPreviewPopup", false);
            },
            onSentMessagePopupCancel: function onSentMessagePopupCancel() {
                this.set("_bShowSentMessagePopup", false);
            }
        }
    });
});