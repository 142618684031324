enifed('ember-runtime/ext/rsvp', ['exports', 'ember-babel', 'rsvp', 'ember-metal', 'ember-debug', 'container'], function (exports, _emberBabel, _rsvp, _emberMetal, _emberDebug, _container) {
  'use strict';

  exports.onerrorDefault = onerrorDefault;

  var _templateObject = (0, _emberBabel.taggedTemplateLiteralLoose)(['rsvpAfter'], ['rsvpAfter']);

  var backburner = _emberMetal.run.backburner;

  _rsvp.configure('async', function (callback, promise) {
    backburner.schedule('actions', null, callback, promise);
  });

  _rsvp.configure('after', function (cb) {
    backburner.schedule((0, _container.privatize)(_templateObject), null, cb);
  });

  _rsvp.on('error', onerrorDefault);

  function onerrorDefault(reason) {
    var error = errorFor(reason);
    if (error) {
      var overrideDispatch = (0, _emberMetal.getDispatchOverride)();
      if (overrideDispatch) {
        overrideDispatch(error);
      } else {
        throw error;
      }
    }
  }

  function errorFor(reason) {
    if (!reason) return;

    if (reason.errorThrown) {
      return unwrapErrorThrown(reason);
    }

    if (reason.name === 'UnrecognizedURLError') {
      (true && !(false) && (0, _emberDebug.assert)('The URL \'' + reason.message + '\' did not match any routes in your application', false));

      return;
    }

    if (reason.name === 'TransitionAborted') {
      return;
    }

    return reason;
  }

  function unwrapErrorThrown(reason) {
    var error = reason.errorThrown;
    if (typeof error === 'string') {
      error = new Error(error);
    }
    Object.defineProperty(error, '__reason_with_error_thrown__', {
      value: reason,
      enumerable: false
    });
    return error;
  }

  exports.default = _rsvp;
});