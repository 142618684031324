define("a24-ember-window/mixins/did-render-changes-mixin", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        _objDidRenderService: Ember.inject.service("component-did-render"),
        _objDomService: Ember.inject.service("dom-event"),
        _objNavigationWithWillDestroyService: null,
        _bCalledA24WillDestroy: false,
        _sCurrentRouteA24WillDestroy: "",
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.get("_objDomService");

            window.a24DidRenderCountIncrease = function () {
                Ember.run(function () {
                    _this.get("_objDidRenderService").countRendered();
                });
            };

            if (this.get("_objNavigationWithWillDestroyService") !== null) {
                this.set("_sCurrentRouteA24WillDestroy", this.getA24WillDestroyRoute(this.get("_objNavigationWithWillDestroyService.objRouteParams")));

                // This will trigger before the next route's active fires, so better place for component teardown
                this.addObserver("_objNavigationWithWillDestroyService.objNewRouteParams", this, this.get("_onNewRouteChange"));
            }
        },
        didRender: function didRender() {
            this._super.apply(this, arguments);
            this.sizeChanges();
        },
        willDestroyElement: function willDestroyElement() {
            //This is for a case where a component gets removed from the page without the route changing
            //Then we still want the a24WillDestroy to fire so that teardown can be handled.
            if (this.get("_objNavigationWithWillDestroyService") !== null && !this.get("_bCalledA24WillDestroy")) {
                this.a24WillDestroy();
            }
        },

        sizeChanges: Ember.observer("_objDomService.iPageWidth", "_objDomService.iPageHeight", function () {
            this.get("_objDidRenderService").countRendered();
        }),

        _getLongestRoute: function _getLongestRoute(objRouteObject) {
            var sLongestRoute = "";
            var arrRouteKeys = Object.keys(objRouteObject);
            for (var i = 0; i < arrRouteKeys.length; i++) {
                if (arrRouteKeys[i].length > sLongestRoute.length) {
                    sLongestRoute = arrRouteKeys[i];
                }
            }
            return sLongestRoute;
        },

        // This will trigger before the next route's active fires
        _onNewRouteChange: function _onNewRouteChange() {
            if (this.get("_sCurrentRouteA24WillDestroy") !== this.getA24WillDestroyRoute(this.get("_objNavigationWithWillDestroyService.objNewRouteParams"))) {
                this.set("_bCalledA24WillDestroy", true);
                this.a24WillDestroy();
            }
        },

        //This fucntion can be overridden if you want to set your own route to observe for changing.
        //For example a component that lives on the candidate profile page does not want a24WillDestroy firing each time
        //a tab changes, instead it will want to fire if user goes to other left menu item.
        //Use the objRouteParams to pull your route from, since the mixin will determine which route object to pass in at
        //what time.
        getA24WillDestroyRoute: function getA24WillDestroyRoute(objRouteParams) {
            return this._getLongestRoute(objRouteParams);
        },
        a24WillDestroy: function a24WillDestroy() {
            //Do nothing, this function is meant to be implemented by comopnent that want to use this hook
        }
    });
});