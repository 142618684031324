define("a24-ember-staffshift-core/components/notification-preview-popup", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/notification-preview-popup"], function (exports, _didRenderChangesMixin, _notificationPreviewPopup) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _notificationPreviewPopup.default,
        bShowPopup: false,
        bPopupLoading: false,
        objSpecialPopupAction: null,
        onPopupCloseAction: null,
        arrActions: null,
        bIsEmail: null,
        objNotificationPreview: null,
        _objSSCoreStringsService: Ember.inject.service("a24-strings-staffshift-core"),

        actions: {
            onPopupClose: function onPopupClose() {
                this.get("onPopupCloseAction")();
            }

        }
    });
});