define("a24-ember-candidate-profile/components/tab-agency-candidate-skills", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/tab-agency-candidate-skills"], function (exports, _didRenderChangesMixin, _tabAgencyCandidateSkills) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _tabAgencyCandidateSkills.default,
        _bLoading: false,
        _bServerError: false,
        _arrReloadActions: null,
        _sCandidateId: "",
        _sCandidateType: "",
        _sCandidateTypeName: "",
        _objUserSession: Ember.inject.service("user-session"),
        _objCandidateTypeRestService: Ember.inject.service("rest-agency-candidate-type"),
        _objCandidateProfileCandidateTypePromise: null,
        _objBasicInfoService: Ember.inject.service("rest-agency-candidate-basic-info"),
        _objBasicInfoGetPromise: null,
        _objSettingsRestService: Ember.inject.service("rest-agency-candidate-settings"),
        _objStringsCandidateProfile: Ember.inject.service("a24-strings-candidate-profile"),
        _objCandidateSkillsRestService: Ember.inject.service("rest-agency-candidate-skills"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objStatusErrorPageService: Ember.inject.service("status-error-page"),
        _objNavigationService: Ember.inject.service("navigation"),
        _bDisplayPermissionError: false,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            var objCandidateSearchViewParams = this.get("_objNavigationService.objRouteParams")["index.agency-candidate-search-view"];
            var objCandidateSearchViewSkillParams = this.get("_objNavigationService.objRouteParams")["index.agency-candidate-search-view.skills.skill"];

            if (!a24Core.isEmpty(objCandidateSearchViewParams)) {
                this.set("_sCandidateId", objCandidateSearchViewParams.candidate_id);
            }
            if (!a24Core.isEmpty(objCandidateSearchViewSkillParams)) {
                this.set("_sCandidateType", objCandidateSearchViewSkillParams.candidate_type);
            }

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objStringsCandidateProfile").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.set("_bServerError", false);
                    _this._getCandidateTypes();
                }
            }]);

            this._getCandidateTypes();
            this._getBasicInfo();
        },

        _setTitleBar: function _setTitleBar() {
            this.get("_objTitleBarService").setTitleBarPartially(this.get("_objStringsCandidateProfile").getString("candidateProfile") + " - " + this.get("_objBasicInfoService.objLastCandidateDetails.first_name") + " " + this.get("_objBasicInfoService.objLastCandidateDetails.last_name"));
        },

        _onCurrentRouteChange: Ember.observer("_objNavigationService.objCurrentRouteParams", function () {
            if (!a24Core.isEmpty(this.get("_objNavigationService.objCurrentRouteParams")["index.agency-candidate-search-view.skills"])) {
                var objCandidateSearchViewSkillsParams = this.get("_objNavigationService.objCurrentRouteParams")["index.agency-candidate-search-view.skills.skill"];
                if (a24Core.isEmpty(objCandidateSearchViewSkillsParams) && a24Core.isEmpty(this.get("_sCandidateType")) || !a24Core.isEmpty(objCandidateSearchViewSkillsParams) && this.get("_sCandidateType") === objCandidateSearchViewSkillsParams.candidate_type) {
                    //Do nothing since they match
                } else {
                    this.set("_sCandidateType", objCandidateSearchViewSkillsParams.candidate_type);
                    this.set("_sCandidateTypeName", "");
                    this._getCandidateTypes();
                }
            }
        }),

        _getCandidateTypes: function _getCandidateTypes() {
            var _this2 = this;

            this.set("_bLoading", true);
            Ember.run.next(function () {
                if (a24Core.isEmpty(_this2)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    _this2.get("_objCandidateTypeRestService").getCandidateTypesCached(_this2, "_objCandidateProfileCandidateTypePromise", _this2.get("_objUserSession").getUserContextReference().mId, function (arrData) {
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }
                        for (var i = 0; i < arrData.length; i++) {
                            if (_this2.get("_sCandidateType") === arrData[i]._id) {
                                _this2.set("_sCandidateTypeName", arrData[i].name);
                                break;
                            }
                        }
                        _this2.set("_bLoading", false);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () {
                        _this2.set("_bServerError", true);
                        _this2.set("_bLoading", false);
                    }));
                });
            });
        },

        _getBasicInfo: function _getBasicInfo() {
            var _this3 = this;

            if (!a24Core.isEmpty(this.get("_objBasicInfoService.objLastCandidateDetails"))) {
                this._setTitleBar();
            } else {
                this.get("_objBasicInfoService").getBasicInfo(this, "_objBasicInfoGetPromise", this.get("_sCandidateId"), function () {
                    _this3._setTitleBar();
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
                function (sCode) {
                    if (sCode === "403") {
                        _this3.set("_objStatusErrorPageService.sIcon", "warning");
                        _this3.set("_objStatusErrorPageService.sMessage", _this3.get("_objStringsCandidateProfile").getString("permissionsError"));
                        _this3.set("_bDisplayPermissionError", true);
                    }
                }));
            }
        }
    });
});