define("a24-ember-staffshift-core/services/status-error-page", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    sIcon: "",
    sMessage: ""
  });
});