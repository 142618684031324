define("a24-ember-lib/components/bread-crumb-bar-component", ["exports", "a24-ember-lib/templates/components/bread-crumb-bar-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _breadCrumbBarComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _breadCrumbBarComponent.default,
        _arrDisplayItems: null, // The items that will display on screen
        _arrDisplayItemsOriginal: null, // The original value of the items that will display on screen
        _bShowBreadCrumbEllipsis: true, // Whether to show the ellipsis

        // Services
        _objBreadCrumbService: Ember.inject.service("bread-crumb"), // Breadcrumb service
        _objDomEventService: Ember.inject.service("dom-event"), // Dom event service
        init: function init() {
            this._super.apply(this, arguments);

            if (this.get("_arrDisplayItems") === null) {
                this.set("_arrDisplayItems", []);
            }

            if (this.get("_arrDisplayItemsOriginal") === null) {
                this.set("_arrDisplayItemsOriginal", []);
            }

            /* Get the service so that it will be initialised
             * This needs to be done since we do not use the actual value on the service
             * and thus it will not init unless directly referenced
             *
             * Done on https://github.com/A24Group/Triage/issues/9458
             */
            this.get("_objDomEventService");
            this.get("_objBreadCrumbService");

            this._onBreadCrumbItemsChange();
            this._onWindowResize();
        },
        // This will set the data back to the original state so that calculations can start fresh
        resetDisplayState: function resetDisplayState() {
            this.set("_bShowBreadCrumbEllipsis", true); // Show the ellipsis
            this.set("_arrDisplayItems", this.get("_arrDisplayItemsOriginal")); // Reset display items
        },
        // Fires when the bread crumb items that comes from the service changes
        _onBreadCrumbItemsChange: Ember.observer("_objBreadCrumbService.arrBreadCrumbItems.@each", function () {
            var _this = this;

            Ember.run.scheduleOnce("afterRender", function () {
                // Set the new data to the original storage variable
                _this.set("_arrDisplayItemsOriginal", _this.get("_objBreadCrumbService.arrBreadCrumbItems"));
                Ember.run.once(_this, "resetBreadCrumbAndRecalc");
            });
        }),
        // If the window width changes
        _onWindowResize: Ember.observer("_objDomEventService.iPageWidth", function () {
            Ember.run.once(this, "resetBreadCrumbAndRecalc");
        }),
        resetBreadCrumbAndRecalc: function resetBreadCrumbAndRecalc() {
            var _this2 = this;

            this.resetDisplayState();
            Ember.run.next(function () {
                Ember.run.scheduleOnce("afterRender", function () {
                    _this2._trimItemsToViewport();
                });
            });
        },
        // Trim the breadcrumb items so that if there are too many items, it will be trimmed
        _trimItemsToViewport: function _trimItemsToViewport() {
            // Get the width of the container that the items will render in
            var iContainerWidth = this.$(".bread-crumb-bar").eq(0).width();

            // Get the width of the ellipsis item that will possibly need to display
            var iEllipsisWidth = this.$(".bread-crumb-ellipsis").eq(0).outerWidth(true);

            // Get a list of bread crumb items that are in the dom
            var arrBreadCrumbItems = this.$(".bread-crumb-item");

            // Create a counter to keep track of all the breadcrumb items width
            var iTotalWidth = 0;
            // Create an array that will store the final items that should be rendered
            var arrFinalBreadcrumbItems = [];

            /**
             * Loop over the breadcrumb items that were pulled from the dom
             *
             * This will itterate backwards through the array so that we can show only the latest
             * items in the array
             */
            for (var i = arrBreadCrumbItems.length - 1; i >= 0; i--) {
                // "i" will be the correct index for iterating backwards

                // Get the width of the item including it's margin
                var iBreadcrumbItemWidth = arrBreadCrumbItems.eq(i).outerWidth(true);

                if (iTotalWidth + iBreadcrumbItemWidth < iContainerWidth - iEllipsisWidth) {
                    /**
                     * If this item can fit in to the remaining space of the header
                     *
                     * This takes in to account all the previous items as well as the ellipsis
                     * that will be shown when some items are chopped off
                     */

                    // Update the total width with this item
                    iTotalWidth += iBreadcrumbItemWidth;
                    /**
                     * Add the current item to the beginning of the arrFinalBreadcrumbItems
                     */
                    arrFinalBreadcrumbItems.unshift(this.get("_arrDisplayItems")[i]);

                    // If this is the last item
                    if (i === 0) {
                        // We do not need an ellipsis if all the items can display
                        this.set("_bShowBreadCrumbEllipsis", false);
                    }
                } else if (i === 0 && iTotalWidth + iBreadcrumbItemWidth < iContainerWidth) {
                    /**
                     * If the last item can fit in to the remaining space of the header
                     *
                     * This takes in to account all the previous items only, since the ellipsis
                     * is not needed for the last item
                     */

                    /**
                     * Add the current item to the beginning of the arrFinalBreadcrumbItems
                     */
                    arrFinalBreadcrumbItems.unshift(this.get("_arrDisplayItems")[i]);
                    // We do not need an ellipsis if all the items can display
                    this.set("_bShowBreadCrumbEllipsis", false);
                } else {
                    /**
                     * Break out of the loop if more items can not be shown
                     */
                    break;
                }
            }

            // Set the correct items back on the display array
            this.set("_arrDisplayItems", arrFinalBreadcrumbItems);
        }
    });
});