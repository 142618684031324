define("a24-ember-staffshift-core/services/react-ember-utils", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        arrToResolve: null,
        objRequest: null,

        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        init: function init() {
            this._super.apply(this, arguments);

            if (a24Core.isEmpty(this.get("arrToResolve"))) {
                this.set("arrToResolve", []);
            }
        },

        genUid: function genUid() {
            var sChars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
            var iLength = 16;
            var sUid = '';
            for (var i = 0; i < iLength; i++) {
                sUid += sChars[Math.floor(Math.random() * sChars.length)];
            }

            if (this.get("_objEnv").environment === "test") {
                return "testUid";
            }
            return sUid;
        },

        makeReactApiRequest: function makeReactApiRequest(objRequest) {
            this.set("objRequest", null);
            var objPromiseObject = {};
            var sRequestId = this.genUid();
            var _objRequest = {
                endpoint: objRequest.sEndpoint,
                options: objRequest.objOptions,
                params: objRequest.objParams,
                requestId: sRequestId
            };
            var objPromise = new Promise(function (resolve, reject) {
                objPromiseObject = {
                    requestId: sRequestId,
                    resolve: resolve,
                    reject: reject,
                    request: _objRequest
                };
            });

            this.get("arrToResolve").push(objPromiseObject);

            this.set("objRequest", _objRequest);

            return objPromise;
        },

        triggerRequestQueue: function triggerRequestQueue() {
            var _this = this;

            this.set("objRequest", null);
            var arrToResolve = this.get("arrToResolve");
            if (arrToResolve.length > 0) {
                arrToResolve.forEach(function (objRequest) {
                    _this.set("objRequest", objRequest.request);
                });
            }
        },

        reactApiRequestResponse: function reactApiRequestResponse(objData, sRequestId) {
            var arrToResolve = this.get("arrToResolve");
            var iRequestIndex = null;

            var objToResolve = arrToResolve.find(function (objRequest, iIndex) {
                if (objRequest.requestId === sRequestId) {
                    iRequestIndex = iIndex;
                    return true;
                }
                return false;
            });
            if (!a24Core.isEmpty(objToResolve)) {
                objToResolve.resolve(objData);
                arrToResolve.splice(iRequestIndex, 1);
            }
        }
    });
});