define("a24-ember-staffshift-core/components/notification-preview", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/notification-preview", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _notificationPreview, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _notificationPreview.default,
        classNames: ["conversation-preview"],
        objNotification: null,

        _sPreviewStyleString: null,
        _sPreviewString: null,
        _bNumbersLibLoaded: false,
        _objSSCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objNotificationPreviewGeneratorService: Ember.inject.service("notification-preview-generator"),

        init: function init() {
            this._super.apply(this, arguments);

            this.set("_sPreviewStyleString", this.get("_objNotificationPreviewGeneratorService").generateFullHtmlForStyleSheet());
        },

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            // Re-generate the value when it changes
            if (!a24Core.isEmpty(this.get("objNotification"))) {
                var sSenderFormatted = "";
                var sRecipientFormatted = "";
                if (this.get("_bNumbersLibLoaded")) {
                    var sSender = "";
                    var sRecipient = "";
                    var arrTokens = this.get("objNotification.collected_tokens");
                    for (var i = 0; i < arrTokens.length; i++) {
                        switch (arrTokens[i].token) {
                            case "recipient_mobile_number":
                                sRecipient = arrTokens[i].value;
                                break;
                            case "sender_phone_number":
                                sSender = arrTokens[i].value;
                                break;
                            default:
                                break;
                        }
                    }
                    sSenderFormatted = this.get("objChildren.number").formatNumberForDisplay(sSender);
                    sRecipientFormatted = this.get("objChildren.number").formatNumberForDisplay(sRecipient);
                }
                var objNotification = this.get("objNotification");

                objNotification.sender_phone_number_formatted = sSenderFormatted;
                objNotification.recipient_mobile_number_formatted = sRecipientFormatted;

                this.set("_sPreviewString", this.get("_objNotificationPreviewGeneratorService").generateFullHtmlFromNotification(objNotification));
            } else {
                this.set("_sPreviewString", Ember.String.htmlSafe("<div class=\"padding-double\">\n                    " + this.get("_objSSCoreStrings").getString("failedToGenerateNotificationPreview") + "\n                </div>"));
            }
        },
        actions: {
            onInputFormTelNumberInitDone: function onInputFormTelNumberInitDone() {
                // NOTE: This specific case we are able to use the unformatted so we are not going to block the display
                // if the input has not loaded. So in short we are displaying the formatted number and if there aint
                // any formatted number we use the unformatted number
                this.set("_bNumbersLibLoaded", true);
            }
        }
    });
});