define("a24-ember-lib/services/ss-utils", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        sortObjectArrayOnText: function sortObjectArrayOnText(arrData, sKey, bAsc) {
            if (a24Core.isEmpty(arrData)) {
                return [];
            }
            var objX = null;
            var objY = null;
            return arrData.toArray().sort(function (objA, objB) {
                if (bAsc) {
                    objX = objA[sKey].toLowerCase();
                    objY = objB[sKey].toLowerCase();
                } else {
                    objX = objB[sKey].toLowerCase();
                    objY = objA[sKey].toLowerCase();
                }
                if (objX < objY) {
                    return -1;
                }
                if (objX > objY) {
                    return 1;
                }
                return 0;
            });
        },
        sortObjectArrayOnDate: function sortObjectArrayOnDate(sTimezone, arrData, sKey, bAsc) {
            if (a24Core.isEmpty(arrData)) {
                return [];
            }
            var objDateA = a24DateManager.createDate(sTimezone);
            var objDateB = a24DateManager.createDate(sTimezone);
            return arrData.toArray().sort(function (objA, objB) {
                if (bAsc) {
                    objDateA.setDateFromRest(objA[sKey]);
                    objDateB.setDateFromRest(objB[sKey]);
                } else {
                    objDateA.setDateFromRest(objB[sKey]);
                    objDateB.setDateFromRest(objA[sKey]);
                }

                if (objDateA.isBefore(objDateB)) {
                    return -1;
                }
                if (objDateA.isAfter(objDateB)) {
                    return 1;
                }
                return 0;
            });
        }
    });
});