define("a24-ember-menu/components/collapsible-left-menu-item", ["exports", "a24-ember-menu/templates/components/collapsible-left-menu-item", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _collapsibleLeftMenuItem, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        /*
         * Default values
        */
        layout: _collapsibleLeftMenuItem.default,
        classNames: ["collapsible-left-menu-item"],

        /*
         * An object holding the data for the menu item
        */
        objMenuItem: null,

        /*
         * True if this menu item has a parent menu item
        */
        bIsSubmenuItem: false,

        /*
         * True if this menu item is expanded
        */
        bIsExpanded: false,

        /*
         * Inline computed properties
         *
         * True if this menu item has submenu items
        */
        bHasSubmenuItems: Ember.computed.notEmpty("objMenuItem.arrSubMenuItems"),

        /**
         * The animation time for a single menu item in ms
         */
        _iMenuAnimationTime: 50,

        /*
         * Lifecycle
        */
        init: function init() {
            this._super.apply(this, arguments);
            Ember.set(this, "sCollapseId", Ember.guidFor({}));
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            this._setExpansionState();
        },
        didUpdateAttrs: function didUpdateAttrs() {
            this._super.apply(this, arguments);
            this._setExpansionState();
        },


        /*
         * Actions
        */
        actions: {
            onClickMenuItem: function onClickMenuItem() {
                if (Ember.get(this, "bHasSubmenuItems")) {
                    this.toggleProperty("bIsExpanded");

                    // Animate the sub menu items to the open or closed state
                    var height = 0;
                    if (this.get("bIsExpanded")) {
                        // Set the height of the submenu
                        height = this.$(".submenu").get(0).scrollHeight;
                    }
                    this.$(".submenu").stop().animate({
                        height: height + "px"
                    },
                    // _iMenuAnimationTime per item in the menu
                    this.get("_iMenuAnimationTime") * this.get("objMenuItem.arrSubMenuItems.length"));

                    var objMenuItem = Ember.get(this, "objMenuItem");
                    Ember.set(objMenuItem, "bIsSelected", Ember.get(objMenuItem, "arrSubMenuItems").isAny("bIsSelected") && !Ember.get(this, "bIsExpanded"));
                } else {
                    this.get("onClick").apply(undefined, arguments);
                }
            }
        },

        _setExpansionState: function _setExpansionState() {
            var _this = this;

            var objMenuItem = Ember.get(this, "objMenuItem");
            var arrSubMenuItems = Ember.A(Ember.get(objMenuItem, "arrSubMenuItems") || []);
            Ember.set(this, "bIsExpanded", arrSubMenuItems.isAny("bIsSelected") || Ember.get(objMenuItem, "bIsSelected"));

            // If the menu has submenus
            if (arrSubMenuItems.length > 0) {

                // Set the height after render to ensure submenu exists
                Ember.run.schedule("afterRender", function () {
                    var height = 0;

                    if (_this.get("bIsExpanded")) {
                        // if the menu is expanded, set the calculated height.
                        height = _this.$(".submenu").get(0).scrollHeight;
                    }

                    _this.$(".submenu").height(height + "px");
                });
            }
        }
    });
});