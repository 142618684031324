define("a24-ember-candidate-profile/services/rest-agency-lookup", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * Returns a list of the specified lookup type
         *
         * @param objCaller - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sLookupType - The lookup type to get
         * @param funcSuccess - The success function
         * @param objFailure - The object that will deal with failed service calls
         * @param objQuery - The query params object
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  5 May 2021
         */
        lookup: function lookup(objContext, sProperty, sLookupType, funcSuccess, objFailure, objQuery) {

            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/lookup/" + sLookupType);

            a24RestUrlConstruct.addQueryParamObject(objQuery);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Returns a list of the specified lookup types, supports a comma separated list
         * of lookup types
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sLookupTypes - The lookup types to get
         * @param funcSuccess - The success function
         * @param objFailure - The object that will deal with failed service calls
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  17 Nov 2021
         */
        allInOneLookup: function allInOneLookup(objContext, sProperty, sLookupTypes, funcSuccess, objFailure) {

            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/lookup");

            a24RestUrlConstruct.addQueryParam('lookup_type', sLookupTypes);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Returns a list of external documents
         *
         * @param objCaller - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The success function
         * @param objFailure - The object that will deal with failed service calls
         * @param objQuery - The query params object
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  5 May 2021
         */
        getExternalDocuments: function getExternalDocuments(objContext, sProperty, funcSuccess, objFailure, objQuery) {
            return this.lookup(objContext, sProperty, "external-document", funcSuccess, objFailure, objQuery);
        },

        /**
         * Returns a list of portfolios
         *
         * @param objCaller - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The success function
         * @param objFailure - The object that will deal with failed service calls
         * @param objQuery - The query params object
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  5 May 2021
         */
        getPortfolios: function getPortfolios(objContext, sProperty, funcSuccess, objFailure, objQuery) {
            return this.lookup(objContext, sProperty, "portfolio", funcSuccess, objFailure, objQuery);
        },

        /**
         * Returns a list of portfolio consultants
         *
         * @param objCaller - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The success function
         * @param objFailure - The object that will deal with failed service calls
         * @param objQuery - The query params object
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  5 May 2021
         */
        getPortfolioConsultants: function getPortfolioConsultants(objContext, sProperty, funcSuccess, objFailure, objQuery) {
            return this.lookup(objContext, sProperty, "portfolio-consultant", funcSuccess, objFailure, objQuery);
        },

        /**
         * Returns a list of portfolio consultant types
         *
         * @param objCaller - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The success function
         * @param objFailure - The object that will deal with failed service calls
         * @param objQuery - The query params object
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  5 May 2021
         */
        getPortfolioConsultantTypes: function getPortfolioConsultantTypes(objContext, sProperty, funcSuccess, objFailure, objQuery) {
            return this.lookup(objContext, sProperty, "portfolio-consultant-type", funcSuccess, objFailure, objQuery);
        },

        /**
         * Returns a list of verification types
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The success function
         * @param objFailure - The object that will deal with failed service calls
         * @param objQuery - The query params object
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  5 May 2021
         */
        getExternalVerification: function getExternalVerification(objContext, sProperty, funcSuccess, objFailure, objQuery) {
            return this.lookup(objContext, sProperty, "external-verification", funcSuccess, objFailure, objQuery);
        },

        /**
         * Returns a list of external days to expiry items
         *
         * @param objContext - The context of this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The success function
         * @param objFailure - The object that will deal with failed service calls
         * @param objQuery - The query params object
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  5 May 2021
         */
        getExternalDaysToExpiry: function getExternalDaysToExpiry(objContext, sProperty, funcSuccess, objFailure, objQuery) {
            return this.lookup(objContext, sProperty, "external-days-to-expiry", funcSuccess, objFailure, objQuery);
        }
    });
});