define("a24-ember-staffshift-users/components/agency-group-list-page", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-users/templates/components/agency-group-list-page"], function (exports, _didRenderChangesMixin, _agencyGroupListPage) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _agencyGroupListPage.default,

        // Services.
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objFabButton: Ember.inject.service("fab-button"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objStaffshiftUsersStringsService: Ember.inject.service("a24-strings-staffshift-users"),
        _objStaffshiftUsersEnumsService: Ember.inject.service("a24-enums-staffshift-users"),
        _objRestAclGroupResourceTreeService: Ember.inject.service("rest-acl-group-resource-tree"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _sTriageBaseRest: Ember.computed.readOnly("env.sTriageBaseRest"),
        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        // Default properties
        sUrlBase: "",
        objDatagridConfig: null,
        objSelectedItem: null,
        _bShowAddPopup: false,
        _objAclResponse: null,

        bReloader: true,

        // Stored Lists
        _arrRoles: null,
        arrRoleMapping: null,

        init: function init() {
            this._super.apply(this, arguments);

            this.set("sUrlBase", this.get("_sTriageBaseRest") + "/rest/acl/group");

            this.get("_objTitleBarService").setTitleBar(this.get("_objStaffshiftUsersStringsService").getString("listGroups"));

            this._getACLThenShowFab();
            this._setupDatagrid();
        },
        _getACLThenShowFab: function _getACLThenShowFab() {
            var _this = this;

            this.get("_objRestAclGroupResourceTreeService").getAclGroupResourceTree(this, "_objAclResponse", function (objData) {

                var arrStatuses = [];
                if (!a24Core.isEmpty(objData) && !a24Core.isEmpty(objData[0]) && !a24Core.isEmpty(objData[0].arrStatus)) {
                    arrStatuses = objData[0].arrStatus;
                    arrStatuses = arrStatuses.map(function (item) {
                        return {
                            sLabel: item.sName,
                            sValue: item.id
                        };
                    });
                }

                _this.set("_arrStatuses", arrStatuses);
                _this._setupFabButton();
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this.set("_arrStatuses", []);
                _this._setupFabButton();
            }));
        },
        _setupFabButton: function _setupFabButton() {
            var _this2 = this;

            Ember.get(this, "_objFabButton").showFabButton(function () {
                Ember.set(_this2, "_bShowAddPopup", true);
            });
        },
        onItemSelect: function onItemSelect(objSelectedItem) {
            // Set the selected item
            Ember.set(this, "objSelectedItem", objSelectedItem);
        },

        doAgencyGroupView: function doAgencyGroupView(sAgencyGroupId) {
            var _this3 = this;

            Ember.run.next(function () {
                _this3.get("_objNavigationService").navigateWithinContext("acl-group-view", {
                    "acl-group-view": [sAgencyGroupId]
                });
            });
        },
        _setupDatagrid: function _setupDatagrid() {
            var arrRoleMapping = this.get("_objStaffshiftUsersEnumsService").getList("aclRoles").map(function (objItem) {
                return {
                    sValue: objItem.sValue,
                    sTitle: objItem.sLabel
                };
            });

            var objThis = this;

            Ember.set(this, "objDatagridConfig", {
                bSelectColumn: true,
                sDatagridHeader: this.get("_objStaffshiftUsersStringsService").getString("listGroups"),
                iMaxHeight: 40 * 27,
                bEmitSelectRowEvent: true,
                arrSingleItemSelectActions: [{
                    sTitle: this.get("_objStringsService").getString("edit"),
                    sIcon: "edit",
                    executeCardAction: function executeCardAction() {
                        Ember.run.next(function () {
                            objThis.get("_objNavigationService").navigateWithinContext("acl-group-view", {
                                "acl-group-view": [objThis.get("objSelectedItem.id")]
                            });
                        });
                    }
                }],
                arrHeaderConfig: [{
                    iUniqueColNum: 1,
                    sTitle: this.get("_objStaffshiftUsersStringsService").getString("groupName"),
                    sProperty: "sName",
                    sFilterType: "text",
                    bText: true,
                    iWidth: 50,
                    bIsClickable: true
                }, {
                    iUniqueColNum: 2,
                    sTitle: this.get("_objStaffshiftUsersStringsService").getString("role"),
                    sProperty: "sRoleId",
                    sFilterType: "dropdown",
                    iWidth: 50,
                    bMapping: true,
                    arrMappingData: arrRoleMapping
                }]
            });
        },


        actions: {
            cardDatagridTableCell: function cardDatagridTableCell(sColumnName, objRowData) {
                // If any of the name columns are clicked
                if (sColumnName === "sName") {
                    var sGroupId = objRowData.id;

                    this.doAgencyGroupView(sGroupId);
                }
            },
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this.onItemSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this.onItemSelect(arrSelectedItems[0]);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected() {
                this.onItemSelect(null);
            },

            onPopupSuccess: function onPopupSuccess() {
                var _this4 = this;

                Ember.set(this, "bReloader", false);
                Ember.set(this, "_bShowAddPopup", false);
                Ember.run.schedule("afterRender", this, function () {
                    Ember.set(_this4, "bReloader", true);
                });
            }

        }
    });
});