define("a24-ember-lib/services/bread-crumb", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        arrBreadCrumbItems: null,
        _arrCustomBreadCrumbItems: null,
        init: function init() {
            this._super.apply(this, arguments);
            if (this.get("arrBreadCrumbItems") === null) {
                this.set("arrBreadCrumbItems", []);
            }
            if (this.get("_arrCustomBreadCrumbItems") === null) {
                this.set("_arrCustomBreadCrumbItems", []);
            }
        },
        /**
         * This is used to set a page's breadcrumb bar items
         *
         * @param arrBreadCrumbItems - Array of bread crumb objects
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  26 May 2016
         */
        setBreadCrumbItems: function setBreadCrumbItems(arrBreadCrumbItems) {
            if (!a24Core.isEmpty(this.get("_arrCustomBreadCrumbItems"))) {
                this.set("arrBreadCrumbItems", this.get("_arrCustomBreadCrumbItems"));
                this.set("_arrCustomBreadCrumbItems", []);
            } else {
                this.set("arrBreadCrumbItems", arrBreadCrumbItems);
            }
        },
        /**
         * This is used to set a page's custom breadcrumb bar items
         *
         * @param arrBreadCrumbItems - Array of bread crumb objects
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  26 May 2016
         */
        setCustomBreadItems: function setCustomBreadItems(arrBreadCrumbItems) {
            this.set("_arrCustomBreadCrumbItems", arrBreadCrumbItems);
        }
    });
});