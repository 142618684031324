define("a24-ember-window/breakpoints", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        tiny: "(max-width: 300px)",
        mobile: "(min-width: 301px) and (max-width: 768px)",
        tablet: "(min-width: 769px) and (max-width: 992px)",
        desktop: "(min-width: 993px) and (max-width: 1200px)",
        jumbo: "(min-width: 1201px)"
    };
});