define("a24-ember-candidate-profile/components/card-datagrid-custom-filter-candidate-external-portfolios", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/mixins/input-form-element", "a24-ember-candidate-profile/templates/components/card-datagrid-custom-filter-candidate-external-portfolios"], function (exports, _didRenderChangesMixin, _inputFormElement, _cardDatagridCustomFilterCandidateExternalPortfolios) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _cardDatagridCustomFilterCandidateExternalPortfolios.default,
        objHeaderItem: null,

        _arrPlayedKeywords: null,
        _objGetPortfolioSuggestions: null,
        _objGetPortfolioConsultantSuggestions: null,
        _funcGetPortfolioSuggestions: null,
        _funcGetPortfolioConsultantSuggestions: null,
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objFilterCustomCandidateExternalPortfoliosService: Ember.inject.service("card-datagrid-custom-filter-candidate-external-portfolios"),

        init: function init() {
            this._super.apply(this, arguments);

            this.set("_arrPlayedKeywords", this.get("_objCandidateProfileEnumsService").getList("playedKeywords"));

            this._setGetPortfolioSuggestionFunction();
            this._setGetPortfolioConsultantSuggestionFunction();
        },

        _setGetPortfolioSuggestionFunction: function _setGetPortfolioSuggestionFunction() {
            var _this = this;

            this.set("_funcGetPortfolioSuggestions", function (sQueryString, funcOnComplete) {
                var objQuery = {
                    name: {
                        mValue: sQueryString,
                        bLike: true,
                        bEncode: true
                    },
                    sortBy: {
                        mValue: "name"
                    },
                    items_per_page: {
                        mValue: 10
                    }
                };

                _this.get("_objFilterCustomCandidateExternalPortfoliosService._objLookupService").getPortfolios(_this, "_objGetPortfolioSuggestions", function (arrData) {
                    // If the data is empty, ensure it's an empty array
                    if (a24Core.isEmpty(arrData)) {
                        arrData = [];
                    }

                    var arrPortfoliosDropdown = [];
                    if (_this.get("objHeaderItem").sContext === _this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY")) {
                        for (var i = 0; i < arrData.length; i++) {
                            arrPortfoliosDropdown.push({
                                name: arrData[i].name,
                                _id: arrData[i].metadata.agency_group_lookup_id
                            });
                        }
                    } else {
                        arrPortfoliosDropdown = arrData;
                    }

                    // Call the callback with the formatted data
                    funcOnComplete(arrPortfoliosDropdown);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    // Call the callback with empty
                    funcOnComplete([]);
                }), objQuery);
            });
        },

        _setGetPortfolioConsultantSuggestionFunction: function _setGetPortfolioConsultantSuggestionFunction() {
            var _this2 = this;

            this.set("_funcGetPortfolioConsultantSuggestions", function (sQueryString, funcOnComplete) {
                var objQuery = {
                    name: {
                        mValue: sQueryString,
                        bLike: true,
                        bEncode: true
                    },
                    sortBy: {
                        mValue: "name"
                    },
                    items_per_page: {
                        mValue: 10
                    }
                };

                _this2.get("_objFilterCustomCandidateExternalPortfoliosService._objLookupService").getPortfolioConsultants(_this2, "_objGetPortfolioConsultantSuggestions", function (arrData) {
                    // If the data is empty, ensure it's an empty array
                    if (a24Core.isEmpty(arrData)) {
                        arrData = [];
                    }

                    var arrPortfolioConsultantsDropdown = [];
                    if (_this2.get("objHeaderItem").sContext === _this2.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY")) {
                        for (var i = 0; i < arrData.length; i++) {
                            arrPortfolioConsultantsDropdown.push({
                                name: arrData[i].name,
                                _id: arrData[i].metadata.agency_group_lookup_id
                            });
                        }
                    } else {
                        arrPortfolioConsultantsDropdown = arrData;
                    }
                    // Call the callback with the formatted data
                    funcOnComplete(arrPortfolioConsultantsDropdown);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    // Call the callback with empty
                    funcOnComplete([]);
                }), objQuery);
            });
        },

        onInputValueChanged: function onInputValueChanged(arrPathToInputMixin) {
            var sLookup = arrPathToInputMixin.join(".");

            if (sLookup === "objChildren._bEmptyEntriesOnly") {
                this.set("objHeaderItem._bEmptyEntriesOnly", this.get(sLookup + ".mValue.bValue") === true);
            } else if (sLookup === "objChildren._objPortfolio") {
                var objPortfolio = this.get(sLookup + ".mValue");

                if (!a24Core.isEmpty(objPortfolio)) {
                    this.set("objHeaderItem._objPortfolio", objPortfolio);
                } else {
                    this.set("objHeaderItem._objPortfolio", null);
                }
            } else if (sLookup === "objChildren._objConsultantType") {
                var objConsultantType = this.get(sLookup + ".mValue");

                if (!a24Core.isEmpty(objConsultantType)) {
                    this.set("objHeaderItem._objConsultantType", objConsultantType);
                } else {
                    this.set("objHeaderItem._objConsultantType", null);
                }
            } else if (sLookup === "objChildren._objConsultant") {
                var objConsultant = this.get(sLookup + ".mValue");

                if (!a24Core.isEmpty(objConsultant)) {
                    this.set("objHeaderItem._objConsultant", objConsultant);
                } else {
                    this.set("objHeaderItem._objConsultant", null);
                }
            } else if (sLookup === "objChildren._objPlayedKeyword") {
                var objPlayedKeyword = this.get(sLookup + ".mValue");

                if (!a24Core.isEmpty(objPlayedKeyword)) {
                    this.set("objHeaderItem._objPlayedKeyword", objPlayedKeyword);
                } else {
                    this.set("objHeaderItem._objPlayedKeyword", null);
                }
            }
        },

        _observeAllValues: Ember.observer("objHeaderItem._bEmptyEntriesOnly", "objHeaderItem._objPortfolio", "objHeaderItem._objConsultantType", "objHeaderItem._objConsultant", "objHeaderItem._objPlayedKeyword", function () {
            if (!a24Core.isEmpty(this.get("objChildren._objPortfolio")) && a24Core.isEmpty(this.get("objHeaderItem._objPortfolio"))) {
                this.get("objChildren._objPortfolio").clear();
            }
            if (!a24Core.isEmpty(this.get("objChildren._objConsultantType")) && a24Core.isEmpty(this.get("objHeaderItem._objConsultantType"))) {
                this.get("objChildren._objConsultantType").clear();
            }
            if (!a24Core.isEmpty(this.get("objChildren._objConsultant")) && a24Core.isEmpty(this.get("objHeaderItem._objConsultant"))) {
                this.get("objChildren._objConsultant").clear();
            }
            if (!a24Core.isEmpty(this.get("objChildren._objPlayedKeyword")) && a24Core.isEmpty(this.get("objHeaderItem._objPlayedKeyword"))) {
                this.get("objChildren._objPlayedKeyword").clear();
            }

            Ember.run.once(this, "_setupFilterForEdit");
            Ember.run.once(this, "_setFlags");
        }).on("init"),

        _setupFilterForEdit: function _setupFilterForEdit() {
            var _this3 = this;

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this3)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    if (!a24Core.isEmpty(_this3.get("objHeaderItem._objPortfolio")) && a24Core.isEmpty(_this3.get("objChildren._objPortfolio.mValue"))) {
                        _this3.get("objChildren._objPortfolio").setValueFromOutside(_this3.get("objHeaderItem._objPortfolio"));
                    }

                    if (!a24Core.isEmpty(_this3.get("objHeaderItem._objConsultant")) && a24Core.isEmpty(_this3.get("objChildren._objConsultant.mValue"))) {
                        _this3.get("objChildren._objConsultant").setValueFromOutside(_this3.get("objHeaderItem._objConsultant"));
                    }
                });
            });
        },

        _setFlags: function _setFlags() {
            if (this.get("objHeaderItem._bEmptyEntriesOnly")) {
                this.customFilterDisplayAddButtonEvent(true);
            } else {
                if (!a24Core.isEmpty(this.get("objHeaderItem._objPortfolio")) || !a24Core.isEmpty(this.get("objHeaderItem._objConsultantType")) || !a24Core.isEmpty(this.get("objHeaderItem._objConsultant")) || !a24Core.isEmpty(this.get("objHeaderItem._objPlayedKeyword"))) {
                    this.customFilterDisplayAddButtonEvent(true);
                } else {
                    this.customFilterDisplayAddButtonEvent(false);
                }
            }
        }
    });
});