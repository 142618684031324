define("a24-ember-lib/components/collapse-section-component", ["exports", "a24-ember-lib/templates/components/collapse-section-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _collapseSectionComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _collapseSectionComponent.default,
        // id for the component
        sCollapseSectionId: "",
        sCollapseGroupParent: "",
        // whether the component is initially expanded or collapsed
        bExpanded: false,
        didInsertElement: function didInsertElement() {
            var _this = this;

            this._super.apply(this, arguments);
            Ember.run.next(function () {
                _this.$().attr("id", _this.get("sCollapseSectionId"));
                _this.$().addClass("collapse");
                var objCollapseItem = $("#" + _this.get("sCollapseSectionId"));
                if (a24Core.isEmpty(_this.get("sCollapseGroupParent"))) {
                    objCollapseItem.collapse({
                        toggle: _this.get("bExpanded")
                    });
                } else {
                    objCollapseItem.collapse({
                        toggle: _this.get("bExpanded"),
                        parent: "#" + _this.get("sCollapseGroupParent")
                    });
                }
                objCollapseItem.on("show.bs.collapse", function () {
                    Ember.run(function () {
                        if (!a24Core.isEmpty(_this.get("onCollapseExpandStart"))) {
                            _this.get("onCollapseExpandStart")(_this.get("sCollapseSectionId"));
                        }
                    });
                }).on("shown.bs.collapse", function () {
                    Ember.run(function () {
                        if (!a24Core.isEmpty(_this.get("onCollapseExpandEnd"))) {
                            _this.get("onCollapseExpandEnd")(_this.get("sCollapseSectionId"));
                        }
                    });
                }).on("hide.bs.collapse", function () {
                    Ember.run(function () {
                        if (!a24Core.isEmpty(_this.get("onCollapseCollapseStart"))) {
                            _this.get("onCollapseCollapseStart")(_this.get("sCollapseSectionId"));
                        }
                    });
                }).on("hidden.bs.collapse", function () {
                    Ember.run(function () {
                        _this.$().height(0);
                        if (!a24Core.isEmpty(_this.get("onCollapseCollapseEnd"))) {
                            _this.get("onCollapseCollapseEnd")(_this.get("sCollapseSectionId"));
                        }
                    });
                });
            });
        },
        _observeExpandState: Ember.observer("bExpanded", function () {
            this._setExpandedState();
        }),
        _setExpandedState: function _setExpandedState() {
            var _this2 = this;

            Ember.run.schedule("afterRender", function () {
                if (_this2.get("bExpanded")) {
                    $("#" + _this2.get("sCollapseSectionId")).collapse("show");
                } else {
                    $("#" + _this2.get("sCollapseSectionId")).collapse("hide");
                }
            });
        }
    });
});