define("a24-ember-candidate-profile/services/card-datagrid-custom-filter-candidate-external-days-to-expiry", ["exports", "a24-ember-lib/services/card-datagrid-filter-custom-base"], function (exports, _cardDatagridFilterCustomBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _cardDatagridFilterCustomBase.default.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objDatagridFitlerService: Ember.inject.service("card-datagrid-filter"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objEnumEmberLibService: Ember.inject.service("a24-enums-ember-lib"),

        funcFilterCustomPrimaryQuery: function funcFilterCustomPrimaryQuery(objHeaderItem /* , objHeaderSecondaries */) {
            var objQuery = null;

            if (objHeaderItem._bEmptyEntriesOnly) {
                objQuery = {
                    bEncode: true,
                    sFilterValue: "empty",
                    mValue: "null"
                };
            } else {
                objQuery = {
                    bEncode: true,
                    bAny: a24Core.isEmpty(objHeaderItem._objExternalDaysType),
                    arSec: []
                };

                if (!a24Core.isEmpty(objHeaderItem._objExternalDaysType)) {
                    objQuery.sFilterValue = objHeaderItem._objExternalDaysType.name;
                    objQuery.mValue = objHeaderItem._objExternalDaysType._id;
                }

                if (!a24Core.isEmpty(objHeaderItem._objExpiringIn)) {
                    if (objHeaderItem._objExpiringIn.sValue === this.get("_objCandidateProfileEnumsService.expiringIn.NEVER.backend")) {
                        objQuery.arSec.push({
                            "sProp": "value",
                            "sFilterValue": null,
                            "bEncode": true,
                            "mValue": null
                        });
                    } else {
                        if (!a24Core.isEmpty(objHeaderItem._iNumberOfDays)) {

                            var objDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                            var objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                            objDate.addTimeToDate(a24Constants.MOMENT_TIME_DAYS, objHeaderItem._iNumberOfDays);

                            var sDate = objDate.getDateStringForRest(true);
                            var sCurrentDate = objCurrentDate.getDateStringForRest(true);
                            if (objHeaderItem._objExpiringIn.sValue === this.get("_objCandidateProfileEnumsService.expiringIn.LESS_THAN_AND_EXPIRED.backend")) {
                                objQuery.arSec.push({
                                    "sProp": "value",
                                    "sFilterValue": objHeaderItem._iNumberOfDays,
                                    "bEncode": true,
                                    "mValue": sDate,
                                    "bTo": true
                                });
                            } else if (objHeaderItem._objExpiringIn.sValue === this.get("_objCandidateProfileEnumsService.expiringIn.LESS_THAN_NOT_EXPIRED.backend")) {
                                objQuery.arSec.push({
                                    "sProp": "value",
                                    "sFilterValue": objHeaderItem._iNumberOfDays,
                                    "bEncode": true,
                                    "bFromAndTo": true,
                                    "mValue": {
                                        "sFrom": sCurrentDate,
                                        "sTo": sDate
                                    }
                                });
                            } else if (objHeaderItem._objExpiringIn.sValue === this.get("_objCandidateProfileEnumsService.expiringIn.MORE_THAN.backend")) {
                                objQuery.arSec.push({
                                    "sProp": "value",
                                    "sFilterValue": objHeaderItem._iNumberOfDays,
                                    "bEncode": true,
                                    "mValue": sDate,
                                    "bFrom": true
                                });
                            } else if (objHeaderItem._objExpiringIn.sValue === this.get("_objCandidateProfileEnumsService.expiringIn.EXACTLY.backend")) {
                                objQuery.arSec.push({
                                    "sProp": "value",
                                    "sFilterValue": objHeaderItem._iNumberOfDays,
                                    "bEncode": true,
                                    "mValue": sDate
                                });
                            }
                        }
                    }
                }
            }
            return objQuery;
        },

        funcFilterCustomTag: function funcFilterCustomTag(objHeaderItem, objQuery, objHeadersSecondaries, bSkipTitle) {
            // Doing this since the existing filter still is an ember filter and needs to run through that
            if (objQuery.sFilterValue === "empty") {
                if (bSkipTitle) {
                    return {
                        sTag: this.get("_objStringsService").getString("isEmpty")
                    };
                } else {
                    return {
                        sTag: this.get("_objCandidateProfileStringsService").getString("externalDaysTagEmpty")
                    };
                }
            } else {
                var sExternalDaysTagKey = bSkipTitle ? "externalPortfolioTagNoTitle" : "externalDaysTag";
                var sExternalTypeFilter = objQuery.sFilterValue;

                if (!a24Core.isEmpty(objHeaderItem) && !a24Core.isEmpty(objHeaderItem.sReactFilterRoute)) {
                    var sTag = "";

                    var objValue = objQuery.arSec.findBy("sProp", "value");
                    // value
                    var arrHeaderSecondary = Ember.get(objHeadersSecondaries, "arrFilterSecondary_" + objHeaderItem.sProperty);
                    var objValueHeader = {};

                    for (var i = 0; i < arrHeaderSecondary.length; i++) {
                        if (arrHeaderSecondary[i].sProperty === "value") {
                            objValueHeader = arrHeaderSecondary[i];
                        }
                    }

                    var objFromDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    var objToDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                    if (!a24Core.isEmpty(objValue)) {
                        var sExpiresFrom = "";
                        var sExpiresTo = "";
                        if (objValue.bFrom || objValue.bAfter) {
                            objFromDate.setDateFromRest(objValue.mValue, true);
                            sExpiresFrom = objFromDate.getDateStringForRest();
                        } else if (objValue.bTo) {
                            objToDate.setDateFromRest(objValue.mValue, true);
                            sExpiresTo = objToDate.getDateStringForRest();
                        } else if (objValue.bFromAndTo || objValue.bNotWithinNext || objValue.bNotWithinLast) {
                            objFromDate.setDateFromRest(objValue.mValue.sFrom, true);
                            sExpiresFrom = objFromDate.getDateStringForRest();
                            objToDate.setDateFromRest(objValue.mValue.sTo, true);
                            sExpiresTo = objToDate.getDateStringForRest();
                        }
                        var objValueTag = this.get("_objDatagridFitlerService").createDateQueryAndTag(objValue.sFilterValue, sExpiresFrom, sExpiresTo, objValueHeader, true);
                        if (objValueHeader.bTemporal) {
                            if (objValue.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED.backend") || objValue.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_AFTER.backend")) {
                                objValueTag.sTag = objValueTag.sTag.substring(objValueHeader.sTitle.length, objValueTag.sTag.length);
                            }
                            sTag += ", " + objValueTag.sTag;
                        } else {
                            sTag += ", " + objValueTag.sTag;
                        }
                    }
                    sExternalTypeFilter += sTag;
                } else {
                    var objDaysQuery = objQuery.arSec.findBy("sProp", "value");

                    if (!a24Core.isEmpty(objDaysQuery)) {
                        var sDays = " expiring in " + this.get("_objCandidateProfileStringsService").getString("exactly") + " " + objDaysQuery.sFilterValue + " days";
                        if (objDaysQuery.bFrom) {
                            sDays = " expiring in " + this.get("_objCandidateProfileStringsService").getString("moreThan") + " " + objDaysQuery.sFilterValue + " days";
                        } else if (objDaysQuery.bTo) {
                            sDays = " expiring in " + this.get("_objCandidateProfileStringsService").getString("lessThan") + " " + objDaysQuery.sFilterValue + " days " + this.get("_objCandidateProfileStringsService").getString("orExpired");
                        } else if (objDaysQuery.bFromAndTo) {
                            sDays = " expiring in " + this.get("_objCandidateProfileStringsService").getString("lessThan") + " " + objDaysQuery.sFilterValue + " days " + this.get("_objCandidateProfileStringsService").getString("andNotExpired");
                        } else if (a24Core.isEmpty(objDaysQuery.sFilterValue)) {
                            sDays = " expiring " + this.get("_objCandidateProfileStringsService").getString("never");
                        }

                        sExternalTypeFilter += sDays;
                    }
                }

                return {
                    sTag: this.get("_objCandidateProfileStringsService").getTokenString(sExternalDaysTagKey, sExternalTypeFilter)
                };
            }
        },

        funcFilterCustomPrimaryReset: function funcFilterCustomPrimaryReset(objHeaderItem) {
            //Reset the state of the custom filter input
            Ember.set(objHeaderItem, "_bEmptyEntriesOnly", false);
            Ember.set(objHeaderItem, "_objExternalDaysType", null);
            Ember.set(objHeaderItem, "_objExpiringIn", null);
            Ember.set(objHeaderItem, "_iNumberOfDays", null);
        },

        funcFilterCustomFixupQuery: function funcFilterCustomFixupQuery(objHeader, objHeaderSecondaries) {
            if (a24Core.isEmpty(objHeader.mFilterValue)) {
                return;
            }
            for (var i = 0; i < objHeader.mFilterValue.length; i++) {
                var arrSeconderies = objHeader.mFilterValue[i].arSec;
                if (!a24Core.isEmpty(arrSeconderies)) {
                    for (var j = 0; j < arrSeconderies.length; j++) {
                        if (!a24Core.isEmpty(objHeaderSecondaries)) {
                            var objSecondaryHeader = null;
                            var arrHeaderSecondary = Ember.get(objHeaderSecondaries, "arrFilterSecondary_" + objHeader.sProperty);
                            if (!a24Core.isEmpty(arrHeaderSecondary)) {
                                for (var k = 0; k < arrHeaderSecondary.length; k++) {
                                    if (arrHeaderSecondary[k].sProperty === arrSeconderies[j].sProp) {
                                        objSecondaryHeader = arrHeaderSecondary[k];
                                        break;
                                    }
                                }
                            }
                            if (arrSeconderies[j].sProp === "value") {
                                if (!a24Core.isEmpty(arrSeconderies[j].sFilterValue) && !a24Core.isEmpty(objSecondaryHeader) && objSecondaryHeader.bTemporal) {
                                    if (arrSeconderies[j].sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED.backend")) {
                                        var _objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                                        this.get("_objDatagridFitlerService").createDateQueryAndTag(arrSeconderies[j].sFilterValue, _objCurrentDate.getDateFormat(), "", objHeader, false);
                                    } else if (arrSeconderies[j].sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_AFTER.backend")) {
                                        this.get("_objDatagridFitlerService").createDateQueryAndTag(arrSeconderies[j].sFilterValue, arrSeconderies[j].mValue, "", objHeader, true);
                                    } else if (!a24Core.isEmpty(arrSeconderies[j].sFilterValue) && arrSeconderies[j].sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED_GRACE.backend"), 0) === 0) {
                                        var _objCurrentDate2 = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                                        _objCurrentDate2.setTimeToStartOfDay();
                                        var sUnit = arrSeconderies[j].sFilterValue.split("_")[1];
                                        _objCurrentDate2.subtractTimeFromDate(sUnit, arrSeconderies[j].iDays);

                                        this.get("_objDatagridFitlerService").createDateQueryAndTag(arrSeconderies[j].sFilterValue, _objCurrentDate2.getDateFormat(), "", objHeader, false);
                                    } else if (!a24Core.isEmpty(arrSeconderies[j].sFilterValue) && arrSeconderies[j].sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_WILL_NOT_EXPIRE.backend"), 0) === 0) {
                                        var _objCurrentDate3 = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                                        _objCurrentDate3.setTimeToStartOfDay();
                                        var _sUnit = arrSeconderies[j].sFilterValue.split("_")[1];
                                        _objCurrentDate3.addTimeToDate(_sUnit, arrSeconderies[j].iDays);

                                        this.get("_objDatagridFitlerService").createDateQueryAndTag(arrSeconderies[j].sFilterValue, _objCurrentDate3.getDateFormat(), "", objHeader, false);
                                    } else if (!a24Core.isEmpty(arrSeconderies[j].sFilterValue) && arrSeconderies[j].sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_WITHIN_LAST.backend"), 0) === 0) {
                                        var _objCurrentDate4 = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                                        var _sUnit2 = arrSeconderies[j].sFilterValue.split("_")[1];
                                        _objCurrentDate4.setTimeToEndOfDay();
                                        var sToDate = _objCurrentDate4.getDateFormat();
                                        _objCurrentDate4.setTimeToStartOfDay();
                                        _objCurrentDate4.subtractTimeFromDate(_sUnit2, arrSeconderies[j].iDays);
                                        var sFromDate = _objCurrentDate4.getDateFormat();

                                        this.get("_objDatagridFitlerService").createDateQueryAndTag(arrSeconderies[j].sFilterValue, sFromDate, sToDate, objHeader, false);
                                    } else if (!a24Core.isEmpty(arrSeconderies[j].sFilterValue) && arrSeconderies[j].sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_AT_LEAST.backend"), 0) === 0) {
                                        var _objCurrentDate5 = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                                        _objCurrentDate5.setTimeToEndOfDay();
                                        var _sUnit3 = arrSeconderies[j].sFilterValue.split("_")[1];
                                        _objCurrentDate5.subtractTimeFromDate(_sUnit3, arrSeconderies[j].iDays);

                                        this.get("_objDatagridFitlerService").createDateQueryAndTag(arrSeconderies[j].sFilterValue, "", _objCurrentDate5.getDateFormat(), objHeader, false);
                                    }
                                } else {
                                    if (!a24Core.isEmpty(arrSeconderies[j].sFilterValue)) {
                                        var objDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                                        var objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                                        objDate.addTimeToDate(a24Constants.MOMENT_TIME_DAYS, arrSeconderies[j].sFilterValue);

                                        var sDate = objDate.getDateStringForRest(true);
                                        var sCurrentDate = objCurrentDate.getDateStringForRest(true);

                                        if (arrSeconderies[j].bFromAndTo) {
                                            Ember.set(objHeader, "mFilterValue." + i + ".arSec." + j + ".mValue", {
                                                "sFrom": sCurrentDate,
                                                "sTo": sDate
                                            });
                                        } else {
                                            Ember.set(objHeader, "mFilterValue." + i + ".arSec." + j + ".mValue", sDate);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },

        funcFilterCustomSetEditState: function funcFilterCustomSetEditState(objHeaderItem, iIndex) {
            var objTag = objHeaderItem.mFilterValue[iIndex];
            Ember.set(objHeaderItem, "_bEmptyEntriesOnly", objTag.sFilterValue === "empty");

            if (objTag.sFilterValue !== "empty") {
                if (!a24Core.isEmpty(objTag.mValue) && !a24Core.isEmpty(objTag.sFilterValue)) {
                    var objExternalType = {
                        _id: objTag.mValue,
                        name: objTag.sFilterValue
                    };
                    Ember.set(objHeaderItem, "_objExternalDaysType", objExternalType);
                }

                if (!a24Core.isEmpty(objTag.arSec)) {
                    for (var i = 0; i < objTag.arSec.length; i++) {
                        var objSecondary = objTag.arSec[i];

                        if (objSecondary.sProp === "value") {
                            if (objSecondary.bTo) {
                                Ember.set(objHeaderItem, "_iNumberOfDays", objSecondary.sFilterValue);
                                Ember.set(objHeaderItem, "_objExpiringIn", {
                                    sLabel: this.get("_objCandidateProfileStringsService").getString("lessThanAndExpired"),
                                    sValue: this.get("_objCandidateProfileEnumsService.expiringIn.LESS_THAN_AND_EXPIRED.backend")
                                });
                            } else if (objSecondary.bFromAndTo) {
                                Ember.set(objHeaderItem, "_iNumberOfDays", objSecondary.sFilterValue);
                                Ember.set(objHeaderItem, "_objExpiringIn", {
                                    sLabel: this.get("_objCandidateProfileStringsService").getString("lessThanNotExpired"),
                                    sValue: this.get("_objCandidateProfileEnumsService.expiringIn.LESS_THAN_NOT_EXPIRED.backend")
                                });
                            } else if (objSecondary.bFrom) {
                                Ember.set(objHeaderItem, "_iNumberOfDays", objSecondary.sFilterValue);
                                Ember.set(objHeaderItem, "_objExpiringIn", {
                                    sLabel: this.get("_objCandidateProfileStringsService").getString("moreThan"),
                                    sValue: this.get("_objCandidateProfileEnumsService.expiringIn.MORE_THAN.backend")
                                });
                            } else if (a24Core.isEmpty(objSecondary.sFilterValue)) {
                                Ember.set(objHeaderItem, "_objExpiringIn", {
                                    sLabel: this.get("_objCandidateProfileStringsService").getString("never"),
                                    sValue: this.get("_objCandidateProfileEnumsService.expiringIn.NEVER.backend")
                                });
                            } else {
                                Ember.set(objHeaderItem, "_iNumberOfDays", objSecondary.sFilterValue);
                                Ember.set(objHeaderItem, "_objExpiringIn", {
                                    sLabel: this.get("_objCandidateProfileStringsService").getString("exactly"),
                                    sValue: this.get("_objCandidateProfileEnumsService.expiringIn.EXACTLY.backend")
                                });
                            }
                        }
                    }
                }
            }
        }
    });
});