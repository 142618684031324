define("a24-ember-staffshift-users/components/whitelisted-ip-addresses", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/mixins/input-form-element", "a24-ember-staffshift-users/templates/components/whitelisted-ip-addresses"], function (exports, _didRenderChangesMixin, _inputFormElement, _whitelistedIpAddresses) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _whitelistedIpAddresses.default,
        bLoading: false,
        objWhitelistIpService: null,

        // Services
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objUsersStringsService: Ember.inject.service("a24-strings-staffshift-users"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objIpifyIpAddressCheck: Ember.inject.service("ipify-ip-address-check"),

        // Properties
        _bShowIpAddressPopup: false,
        _bShowConfirmDelete: false,
        _bAddUserPopupLoading: false,
        _bAddEditIpAddressPopupLoading: false,
        _bShowUnsavedData: false,
        _bEditing: false,
        _bShowDeleteWarning: false,
        _bPopupDeleteLoading: false,

        _objSpecialAddEditPopupAction: null,
        _objOriginalData: null,
        _objGetIpWhitelistings: null,
        _objAddWhitelistIp: null,
        _objEditWhitelistIp: null,

        _sIpAddressToRemove: null,
        _sDescription: null,
        _sPopupHeader: null,
        _sEditingIpAddress: null,
        _sUserIpAddress: "",

        _arrIPAddresses: null,
        _arrHeaderActions: null,

        // Init function
        init: function init() {
            var _this = this;

            // Call super
            this._super.apply(this, arguments);

            this.set("_arrHeaderActions", [{
                sTitle: this.get("_objStringsService").getString("add"),
                sIcon: "add",
                executeCardAction: function executeCardAction() {
                    _this.addActionClicked();
                }
            }]);

            this.set("_objSpecialAddEditPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onPopupIpAddressClose");
                }
            });

            // Set the validation on the form
            this.set("objChildConfig", {
                "objChildren.ip": function objChildrenIp(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                    objMixin.get("arrValidation").pushObject(function () {
                        var sIpAddress = objMixin.get("mValue").trim();
                        if (!/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(sIpAddress)) {
                            return objMixin.get("_objStringsService").getTokenString("validationRestInvalidFormat", objMixin.get("sFieldName"));
                        }
                    });
                },
                "objChildren.description": function objChildrenDescription(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                }
            });

            // Do the service calls
            this.doServiceCalls();
        },

        // Make the service calls required for the card to display correctlty
        doServiceCalls: function doServiceCalls() {
            var _this2 = this;

            // Set the loading to true
            this.set("bLoading", true);

            // Get the list of whitelisted ips
            this.get("objWhitelistIpService").getIpWhitelistings(this, "_objGetIpWhitelistings", function (arrData) {
                // Prep the data then take the form out of loading
                _this2.prepareForDisplay(arrData);
                _this2.set("bLoading", false);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                // Prep empty data then take the form out of loading
                _this2.prepareForDisplay([]);
                _this2.set("bLoading", false);
            }));
        },

        // Prepare ip whitelist for display
        prepareForDisplay: function prepareForDisplay(arrData) {
            var _this3 = this;

            // Reset the data if it is empty
            if (a24Core.isEmpty(arrData)) {
                arrData = [];
            }

            // Store a this instance
            var objThis = this;

            // Function used to generate a custom function per item
            var funcCardActionRemoveExecute = function funcCardActionRemoveExecute(sIp) {
                // Return the function to remove the ip
                return function () {
                    // Set the current user id as the id
                    objThis.set("_sIpAddressToRemove", sIp);
                    // Show the confirm delete
                    objThis.set("_bShowConfirmDelete", true);
                };
            };

            // Define the array of ip address
            var arrIPAddresses = [];

            // Loop over the data that is present

            var _loop = function _loop(i) {

                var sIpAddress = arrData[i].ip;

                // Get the description id
                var sDescription = arrData[i].description;

                // Push the item in to the
                arrIPAddresses.push({
                    sLabel: _this3.get("_objStringsService").getHTMLTokenString("a24-string-html-dot-value", sIpAddress, sDescription),
                    sIcon: "network_check",
                    arrActions: [{
                        sTitle: _this3.get("_objStringsService").getString("edit"),
                        sIcon: "edit",
                        executeCardAction: function executeCardAction() {
                            _this3.openEditIPAddressPopup(sIpAddress, sDescription);
                        }
                    }, {
                        sTitle: _this3.get("_objStringsService").getString("remove"),
                        sIcon: "delete",
                        executeCardAction: funcCardActionRemoveExecute(sIpAddress)
                    }]
                });
            };

            for (var i = 0; i < arrData.length; i++) {
                _loop(i);
            }

            // Set the array of ip address
            this.set("_arrIPAddresses", arrIPAddresses);
        },

        // Remove the ip address
        removeIpFromWhitelist: function removeIpFromWhitelist(sIp) {
            var _this4 = this;

            this.set("_bPopupDeleteLoading", true);

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("removingItem", this.get("_objUsersStringsService").getString("ipAddress")));

            // Do the remove service call
            this.get("objWhitelistIpService").deleteIpWhitelist(this, "_objRemoveUser", sIp, function () {
                // Hide the confirm delete popup
                _this4.set("_bShowConfirmDelete", false);

                _this4.get("_objSnackbarService").showSnackbarMessage(_this4.get("_objStringsService").getTokenString("successRemoveItem", _this4.get("_objUsersStringsService").getString("ipAddress")));
                _this4.set("_bPopupDeleteLoading", false);
                // GET ip whitelist again
                _this4.doServiceCalls();
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this4.set("_bShowConfirmDelete", false);

                _this4.get("_objSnackbarService").showSnackbarMessage(_this4.get("_objStringsService").getTokenString("failRemoveItem", _this4.get("_objUsersStringsService").getString("ipAddress")));

                _this4.set("_bPopupDeleteLoading", false);
            }));
        },
        addEditIpWhitelist: function addEditIpWhitelist(sIp, sDescription) {
            var _this5 = this;

            // Set the card in to loading
            this.set("_bAddEditIpAddressPopupLoading", true);

            var objData = {
                "ip": sIp,
                "description": sDescription
            };
            if (!this.get("_bEditing")) {

                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objUsersStringsService").getString("ipAddress")));
                // Do the add service call
                this.get("objWhitelistIpService").addIpToWhitelist(this, "_objAddWhitelistIp", objData, function () {

                    // Hide the add/edit ip address popup
                    _this5.set("_bShowIpAddressPopup", false);

                    _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("successAddItem", _this5.get("_objUsersStringsService").getString("ipAddress")));

                    // GET ip whitelist list again
                    _this5.doServiceCalls();
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    if (sCode === "409") {
                        _this5.setResponseError(["ip"], "validationIpAlreadyExist", false, false, _this5.get("_objUsersStringsService"));
                    } else {
                        // Hide the add/edit ip address popup
                        _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("failAddItem", _this5.get("_objUsersStringsService").getString("ipAddress")));
                    }
                    _this5.set("_bAddEditIpAddressPopupLoading", false);
                }));
            } else {
                objData = {
                    "description": sDescription
                };

                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objUsersStringsService").getString("ipAddress")));

                // Do the edit service call
                this.get("objWhitelistIpService").editIpWhitelist(this, "_objEditWhitelistIp", this.get("_sEditingIpAddress"), function () {

                    // Hide the add/edit ip address popup
                    _this5.set("_bShowIpAddressPopup", false);

                    _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("successUpdateItem", _this5.get("_objUsersStringsService").getString("ipAddress")));

                    // GET ip whitelist list again
                    _this5.doServiceCalls();
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{

                    _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("failUpdateItem", _this5.get("_objUsersStringsService").getString("ipAddress")));

                    _this5.set("_bAddEditIpAddressPopupLoading", false);
                }), objData);
            }
        },
        openEditIPAddressPopup: function openEditIPAddressPopup(sIpAddress, sDescription) {
            var _this6 = this;

            this.clearAllErrorMessages();
            this.clearForm();
            this.set("_bAddEditIpAddressPopupLoading", false);
            this.set("_bShowIpAddressPopup", true);
            this.set("_bEditing", true);
            this.set("_sPopupHeader", this.get("_objUsersStringsService").getString("editIpAddress"));
            this.set("_sEditingIpAddress", sIpAddress);
            this.get("objChildren")["description"].setValueFromOutside(sDescription);

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    _this6.set("_objOriginalData", _this6.getPlainFormValueObject());
                });
            });
        },
        addActionClicked: function addActionClicked() {
            var _this7 = this;

            this.clearForm();
            this.clearAllErrorMessages();
            this.set("_bAddEditIpAddressPopupLoading", false);
            this.set("_bShowIpAddressPopup", true);
            this.set("_bEditing", false);
            this.set("_sPopupHeader", this.get("_objUsersStringsService").getString("addIpAddress"));

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    _this7.set("_objOriginalData", _this7.getPlainFormValueObject());
                });
            });
        },
        actions: {
            onPopupDeleteClose: function onPopupDeleteClose() {
                if (this.get("_bPopupDeleteLoading")) {
                    return;
                }
                this.set("_bShowConfirmDelete", false);
            },
            onRemoveIpAddressPopupConfirm: function onRemoveIpAddressPopupConfirm() {
                var _this8 = this;

                if (a24Core.isEmpty(this.get("_sUserIpAddress"))) {
                    this.set("_bPopupDeleteLoading", true);
                    // check user's ip
                    var funcGetIpAddressAndRemove = function funcGetIpAddressAndRemove(objResponse) {
                        _this8.set("_bPopupDeleteLoading", false);
                        if (!a24Core.isEmpty(objResponse)) {
                            _this8.set("_sUserIpAddress", objResponse.ip);
                            if (_this8.get("_sUserIpAddress") !== _this8.get("_sIpAddressToRemove")) {
                                _this8.removeIpFromWhitelist(_this8.get("_sIpAddressToRemove"));
                            } else {
                                // show warning popup
                                _this8.set("_bShowDeleteWarning", true);
                            }
                        } else {
                            _this8.removeIpFromWhitelist(_this8.get("_sIpAddressToRemove"));
                        }
                    };

                    this.get("_objIpifyIpAddressCheck").getUserIpAddress(funcGetIpAddressAndRemove);
                } else {
                    if (this.get("_sUserIpAddress") !== this.get("_sIpAddressToRemove")) {
                        this.removeIpFromWhitelist(this.get("_sIpAddressToRemove"));
                    } else {
                        // show warning popup
                        this.set("_bShowDeleteWarning", true);
                    }
                }
            },
            onRemoveIpAddressWarningPopupConfirm: function onRemoveIpAddressWarningPopupConfirm() {
                this.set("_bShowDeleteWarning", false);
                this.removeIpFromWhitelist(this.get("_sIpAddressToRemove"));
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.clearForm();
                this.clearAllErrorMessages();
                this.set("_bShowIpAddressPopup", false);
            },
            onPopupIpAddressClose: function onPopupIpAddressClose() {
                if (!this.get("_bAddEditIpAddressPopupLoading")) {
                    var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

                    if (bDataMatches) {
                        this.clearForm();
                        this.clearAllErrorMessages();
                        this.set("_bShowIpAddressPopup", false);
                    } else {
                        this.set("_bShowUnsavedData", true);
                    }
                }
            },
            onPopupIpAddressConfirm: function onPopupIpAddressConfirm() {
                // Do the validation on the form
                if (!this.doFullValidation()) {
                    // If the validation passed
                    var objPayload = this.getPlainFormValueObject();
                    if (!a24Core.isEmpty(objPayload.ip)) {
                        objPayload.ip = objPayload.ip.trim();
                    }

                    this.addEditIpWhitelist(objPayload.ip, objPayload.description);
                }
            }
        }
    });
});