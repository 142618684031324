define("a24-ember-lib/components/input-checkbox", ["exports", "a24-ember-lib/templates/components/input-checkbox", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _inputCheckbox, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _inputCheckbox.default,
        classNames: ["form-group"],
        classNameBindings: ["hasErrorMessageSpace::clear-margin-bottom"],
        hasErrorMessageSpace: false,
        sFieldName: null,
        bValue: false,
        mValue: null,
        _sInputId: null,
        init: function init() {
            this._super.apply(this, arguments);
            this.set("_sInputId", Ember.guidFor({}));
        },
        _onCheckBoxClicked: function _onCheckBoxClicked() {
            if (!a24Core.isEmpty(this.get("onCheckBoxClicked"))) {
                return this.get("onCheckBoxClicked")(this.get("mValue"));
            }
        },
        actions: {
            onClick: function onClick() {
                if (!a24Core.isEmpty(this.get("mValue"))) {
                    var objThis = this;
                    //Run next to give ember/mdb chance to first set the new value of the checkbox before firing the event
                    Ember.run.next(function () {
                        objThis._onCheckBoxClicked();
                    });
                }
            }
        }
    });
});