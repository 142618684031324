define("a24-ember-lib/components/horizontal-line-component", ["exports", "a24-ember-lib/templates/components/horizontal-line-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _horizontalLineComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _horizontalLineComponent.default,
        classNames: ["horizontal-line-component"],
        classNameBindings: ["sCustomClassName"],
        sCustomClassName: ""
    });
});