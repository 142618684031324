define("a24-ember-lib/components/waves-inception-component", ["exports", "a24-ember-lib/templates/components/waves-inception-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _wavesInceptionComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _wavesInceptionComponent.default,
        // Used to determine if waves should be light or dark
        bLight: false
    });
});