define("a24-ember-staffshift-users/services/rest-agency-group-users", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * This service method will make a GET to the agency group to look up available users
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sAgencyGroupId - The id of the agency group to get users for
         * @param sQueryString - The query string for the query params
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  30 November 2017
         */
        getAvailableUserByQuery: function getAvailableUserByQuery(objCaller, sProperty, sAgencyGroupId, sQueryString, funcSuccess, objFailure) {

            var sUrl = Ember.get(this, "sRestApiBaseUrl") + "/v1/agency-group/" + sAgencyGroupId + "/user";

            a24RestUrlConstruct.setBaseUrlSwagger(sUrl);
            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: {
                    mValue: 10
                },
                q: {
                    mValue: sQueryString,
                    bLike: true,
                    bEncode: true
                },
                sStatus: {
                    mValue: "active"
                }
            });

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, Ember.get(this, "_objUserSession"), Ember.get(this, "_objNavigation"), "GET", a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },
        suggestUsers: function suggestUsers(objCaller, sProperty, sAgencyGroupId, sQueryString, funcSuccess, objFailure) {

            var sUrl = Ember.get(this, "sRestApiBaseUrl") + "/v1/agency-group/" + sAgencyGroupId + "/user";

            a24RestUrlConstruct.setBaseUrlSwagger(sUrl);
            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: {
                    mValue: 10
                },
                q: {
                    mValue: sQueryString,
                    bLike: true,
                    bEncode: true
                }
            });

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, Ember.get(this, "_objUserSession"), Ember.get(this, "_objNavigation"), "GET", a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});