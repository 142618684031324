define("a24-ember-candidate-profile/services/rest-candidate-contact-numbers", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * Retrieves all contact number entries for the candidate
         *
         * @param objContext - The caller of this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id to fetch basic info data for
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  04 April 2018
         */
        getContactNumbers: function getContactNumbers(objContext, sProperty, sCandidateId, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/candidate/" + sCandidateId + "/contact-numbers");

            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: {
                    mValue: 1000
                }
            });

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Creates a contact number entry for the candidate
         *
         * @param objContext - The caller of this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id to fetch basic info data for
         * @param objData - The candidate contact number data
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  04 April 2018
         */
        createContactNumber: function createContactNumber(objContext, sProperty, sCandidateId, objData, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/candidate/" + sCandidateId + "/contact-numbers");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", this.get("sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        },

        /**
         * Update contact number for the candidate
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id
         * @param sContantNumberId - The contact number id
         * @param objData - The contact number data
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  11 May 2018
         */
        updateContactNumber: function updateContactNumber(objContext, sProperty, sCandidateId, sContantNumberId, objData, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/candidate/" + sCandidateId + "/contact-numbers/" + sContantNumberId);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "PUT", this.get("sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        },

        /**
         * Deletes a contact number entry for the candidate
         *
         * @param objContext - The caller of this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The candidate id to fetch basic info data for
         * @param sContactNumberId - The contact number id
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  04 April 2018
         */
        deleteContactNumber: function deleteContactNumber(objContext, sProperty, sCandidateId, sContactNumberId, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/candidate/" + sCandidateId + "/contact-numbers/" + sContactNumberId);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "DELETE", this.get("sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});