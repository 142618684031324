define("a24-ember-candidate-profile/services/rest-candidate-document-types", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _sRestApiBaseUrl: Ember.computed.readOnly("_objEnv.sRestApiBaseUrl"),

        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * Get all document types
         *
         * @param objContext - The object on which the property must exist
         * @param sProperty - The property name on which to get and set the promise.
         * @param sCandidateId - The candidate  id
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The object containing the callback function to execute when the service call fails
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  25 Feb 2019
         */
        getDocumentTypes: function getDocumentTypes(objContext, sProperty, sCandidateId, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/candidate/" + sCandidateId + "/document-types");

            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: {
                    mValue: 1000
                },
                name: {
                    mValue: "asc",
                    bSortBy: true
                }
            });

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});