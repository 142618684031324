var a24ConstantsSSAgency = {
    //======== A24 ADDON OVERRIDES START ========

    DATE_FORMAT_REST_FORMAT_MONTH_YEAR_ONLY: "YYYY-MM",
    DATE_FORMAT_REST_FORMAT_DATE_ONLY: "YYYY-MM-DD",
    DATE_FORMAT_REST_FORMAT_WITHOUT_TZ: "YYYY-MM-DDTHH:mm:ss",
    DATE_FORMAT_REST_FORMAT: "YYYY-MM-DDTHH:mm:ssZ"

    //======== A24 ADDON OVERRIDES END   ========
};

if (typeof a24Constants === "undefined") {
    var a24Constants = {};
}

//Only add the extended values to the a24Constants if they do not exist on it already
Object.keys(a24ConstantsSSAgency).forEach(function(sKey) {
    a24Constants[sKey] = a24ConstantsSSAgency[sKey];
});
