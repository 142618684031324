enifed('ember-template-compiler/plugins/transform-angle-bracket-components', ['exports'], function (exports) {
  'use strict';

  exports.default = transformAngleBracketComponents;
  function transformAngleBracketComponents() /* env */{
    return {
      name: 'transform-angle-bracket-components',

      visitor: {
        ComponentNode: function (node) {
          node.tag = '<' + node.tag + '>';
        }
      }
    };
  }
});