define("a24-ember-policy-library/components/page-candidate-policy-view", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-policy-library/templates/components/page-candidate-policy-view"], function (exports, _didRenderChangesMixin, _pageCandidatePolicyView) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _pageCandidatePolicyView.default,
        classNames: ["page-candidate-policy-view"],
        _bLoading: false,
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objBreadCrumbService: Ember.inject.service("bread-crumb"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objPolicyStringsService: Ember.inject.service("a24-strings-policy-library"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objPolicyRestService: Ember.inject.service("rest-candidate-policy"),
        _objConstantsEmberLibService: Ember.inject.service("a24-constants-ember-lib"),
        _objUserPolicyRest: Ember.inject.service("rest-user-policy"),
        _objCandidatePolicyRest: Ember.inject.service("rest-candidate-policy"),
        _objRestCandidatePolicyServiceProperty: null,
        _sTitle: "",
        _sPolicyGroupType: "",
        _sPolicyGroupId: "",
        _sPolicyTypeId: "",
        _sVersion: "",
        _objData: null,
        _bLatest: true,
        _objDownloadAction: null,
        _sContent: null,
        _sPrompt: null,
        _sContentCss: null,
        _sPromptCss: null,
        _sViewMoreText: null,
        _bDisplayConfirmButton: false,
        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),
        init: function init() {
            this._super.apply(this, arguments);

            this.set("_sViewMoreText", this.get("_objPolicyStringsService").getString("clickHereViewPolicy").toUpperCase());

            this.observeRouteParams();
        },
        observeRouteParams: Ember.observer("_objNavigationService.objRouteParams", function () {
            //Only do the service call if the current instance is not busy destroying and if we have
            //a candidate policy group route
            if (!a24Core.isEmpty(this) && !a24Core.isEmpty(this.get("_objNavigationService.objRouteParams")["candidate.policy-view"])) {
                var objPolicyViewParams = this.get("_objNavigationService.objRouteParams")["candidate.policy-view"];

                this.set("_sPolicyGroupType", objPolicyViewParams.policy_group_type);
                this.set("_sPolicyGroupId", objPolicyViewParams.policy_group_id);
                this.set("_sPolicyTypeId", objPolicyViewParams.policy_type_id);

                if (objPolicyViewParams.policy_group_type === "staffshift") {
                    this.set("_objPolicyRestService", this.get("_objUserPolicyRest"));
                } else {
                    this.set("_objPolicyRestService", this.get("_objCandidatePolicyRest"));
                }
                this.setupPage();
            }
        }),
        setupPage: function setupPage() {
            this.doServiceCall();
        },
        setAllData: function setAllData(objData) {
            var _this = this;

            Ember.run.schedule("afterRender", function () {
                Ember.run.next(function () {
                    _this.set("_sVersion", objData.version);

                    var arrBreadCrumbItems = _this.get("_objBreadCrumbService.arrBreadCrumbItems");
                    var objThis = _this;

                    _this.set("_bLatest", objData.latest);

                    // Set the content and prompt text
                    _this.set("_sContent", objData.content);
                    if (objData.policy_type.classification === "acknowledgeable") {
                        _this.set("_sPrompt", objData.prompt);
                    }

                    if (!a24Core.isEmpty(objData.content_css)) {
                        _this.set("_sContentCss", objData.content_css);
                    } else {
                        // Blank out style for safety
                        _this.set("_sContentCss", "");
                    }
                    if (!a24Core.isEmpty(objData.prompt_css)) {
                        _this.set("_sPromptCss", objData.prompt_css);
                    } else {
                        // Blank out style for safety
                        _this.set("_sPromptCss", "");
                    }

                    // Set the confirm button if needed
                    if (objData.latest && objData.action) {
                        var bIsAcknowledged = objData.action.isAny("action_type", "acknowledged");
                        var bIsAcknowledgeable = objData.policy_type.classification === "acknowledgeable";
                        _this.set("_bDisplayConfirmButton", !bIsAcknowledged && bIsAcknowledgeable);
                    }

                    _this.set("_objDownloadAction", {
                        sTitle: _this.get("_objStringsService").getString("download"),
                        sIcon: "get_app",
                        executeCardAction: function executeCardAction() {
                            objThis.get("_objSnackbarService").showSnackbarMessage(objThis.get("_objStringsService").getString("downloadPdf"));

                            objThis.get("_objPolicyRestService").downloadPdfByGET(objThis.get("_sPolicyTypeId"), objThis.get("_sVersion"), objData.policy_type.title + ".pdf", function () {
                                objThis.get("_objSnackbarService").showSnackbarMessage(objThis.get("_objStringsService").getString("downloadPdfFinish"));
                            }, function () {
                                objThis.get("_objSnackbarService").showSnackbarMessage(objThis.get("_objStringsService").getString("downloadPdfFailed"));
                            });
                        }
                    });

                    // Set the Title
                    _this.set("_sTitle", _this.get("_objStringsService").getHTMLTokenString("a24-string-html-span", objData.policy_type.title, "(" + objData.display_version + ")"));

                    // Set the breadcrumb
                    var arrCurrentBreadCrumb = [{
                        sLink: _this.get("_objNavigationService").navigateWithinContext("policy-group-list", {
                            "policy-group-list": [_this.get("_sPolicyGroupType"), _this.get("_sPolicyGroupId")]
                        }, null, true),
                        sTitle: _this.get("_objPolicyStringsService").getString("policies")
                    }, {
                        sLink: _this.get("_objNavigationService").navigateWithinContext("policy-group-list", {
                            "policy-group-list": [_this.get("_sPolicyGroupType"), _this.get("_sPolicyGroupId")]
                        }, null, true),
                        sTitle: arrBreadCrumbItems[arrBreadCrumbItems.length - 1].sTitle
                    }, {
                        sLink: _this.get("_objNavigationService").navigateWithinContext("policy-view", {
                            "policy-view": [_this.get("_sPolicyGroupType"), _this.get("_sPolicyGroupId"), _this.get("_sPolicyTypeId"), _this.get("_objNavigationService").get("objRouteParams")["candidate.policy-view"].version]
                        }, null, true),
                        sTitle: objData.policy_type.title
                    }];

                    _this.get("_objBreadCrumbService").setCustomBreadItems(arrCurrentBreadCrumb);

                    // Set the default items so that the custom can be applied
                    _this.get("_objBreadCrumbService").setBreadCrumbItems();
                });
            });
        },
        doServiceCall: function doServiceCall() {
            var _this2 = this;

            // Set loading true
            this.set("_bLoading", true);

            this.get("_objPolicyRestService").getPolicyDetails(this, "_objRestCandidatePolicyServiceProperty", this.get("_objNavigationService").get("objRouteParams")["candidate.policy-view"].policy_type_id, this.get("_objNavigationService").get("objRouteParams")["candidate.policy-view"].version, function (objData) {
                _this2.setAllData(objData);
                _this2.set("_bLoading", false);
            });
        },
        actions: {
            backButtonAction: function backButtonAction() {
                this.get("_objNavigationService").navigateWithinContext("policy-group-list", {
                    "policy-group-list": [this.get("_sPolicyGroupType"), this.get("_sPolicyGroupId")]
                });
            },
            confirmButtonAction: function confirmButtonAction() {
                var _this3 = this;

                this.set("_bLoading", true);

                var objBody = this.get("_objPolicyRestService").getConfirmationPostBody("acknowledged");

                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objPolicyStringsService").getString("updatingPolicy"));

                this.get("_objPolicyRestService").postPolicyDetailsAction(this, "_objRestCandidatePolicyServiceProperty", this.get("_objNavigationService").get("objRouteParams")["candidate.policy-view"].policy_type_id, this.get("_sVersion"), function () /*objData*/{
                    _this3.get("_objSnackbarService").showSnackbarMessage(_this3.get("_objPolicyStringsService").getString("acceptedPolicy"));
                    _this3.get("_objNavigationService").navigateWithinContext("policy-group-list", {
                        "policy-group-list": [_this3.get("_sPolicyGroupType"), _this3.get("_sPolicyGroupId")]
                    });
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () {
                    _this3.set("_bLoading", false);
                    _this3.get("_objSnackbarService").showSnackbarMessage(_this3.get("_objPolicyStringsService").getString("failurePolicy"));
                }), objBody);
            }
        }
    });
});