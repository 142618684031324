define("a24-ember-staffshift-core/components/chatter-right-container", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/chatter-right-container"], function (exports, _didRenderChangesMixin, _chatterRightContainer) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _chatterRightContainer.default,
        classNames: ["a24-chatter-right-container"],
        objData: null,
        _momentHelper: null
    });
});