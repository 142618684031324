define("a24-calendar/components/calendar-navigation", ["exports", "a24-calendar/templates/components/calendar-navigation", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _calendarNavigation, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        /*
         * Default values
        */
        layout: _calendarNavigation.default,
        classNames: ["calendar-navigation"],

        /*
         * @property bTextDisabled
         * True when the text button is disabled
        */
        bTextDisabled: false,

        /*
         * @property bBackDisabled
         * True when the back icon is disabled
        */
        bBackDisabled: false,

        /*
         * @property bForwardDisabled
         * True when the forward icon is disabled
        */
        bForwardDisabled: false,

        /*
         * @property bTextHidden
         * True when the text button is hidden
        */
        bTextHidden: false,

        /*
         * @property bText
         * Sets the text button content
        */
        bText: "TODAY",

        actions: {
            /*
             * @action btnClick
             * Dispatches clicked button if not disabled
            */
            btnClick: function btnClick() {
                if (!this.get("bTextDisabled")) {
                    if (!a24Core.isEmpty(this.get("navBtnClick"))) {
                        this.get("navBtnClick")();
                    }
                }
            },


            /*
             * @action goBack
             * Dispatches clicked back icon if not disabled
            */
            goBack: function goBack() {
                if (!this.get("bBackDisabled")) {
                    if (!a24Core.isEmpty(this.get("navGoBack"))) {
                        this.get("navGoBack")();
                    }
                }
            },


            /*
             * @action goForward
             * Dispatches clicked forward icon if not disabled
            */
            goForward: function goForward() {
                if (!this.get("bForwardDisabled")) {
                    if (!a24Core.isEmpty(this.get("navGoForward"))) {
                        this.get("navGoForward")();
                    }
                }
            }
        }
    });
});