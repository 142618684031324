define("a24-ember-candidate-profile/services/card-datagrid-custom-filter-candidate-external-portfolios", ["exports", "a24-ember-lib/services/card-datagrid-filter-custom-base"], function (exports, _cardDatagridFilterCustomBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _cardDatagridFilterCustomBase.default.extend({
        _objStringsService: Ember.inject.service("a24-strings"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objLookupService: null,

        funcFilterCustomPrimaryQuery: function funcFilterCustomPrimaryQuery(objHeaderItem /* , objHeaderSecondaries */) {
            var objQuery = null;

            if (objHeaderItem._bEmptyEntriesOnly) {
                objQuery = {
                    bEncode: true,
                    sFilterValue: "empty",
                    mValue: "null"
                };
            } else {
                objQuery = {
                    bEncode: true,
                    bAny: a24Core.isEmpty(objHeaderItem._objPortfolio),
                    arSec: []
                };

                if (!a24Core.isEmpty(objHeaderItem._objPortfolio)) {
                    objQuery.sFilterValue = objHeaderItem._objPortfolio.name;
                    objQuery.mValue = objHeaderItem._objPortfolio._id;
                }
                if (!a24Core.isEmpty(objHeaderItem._objConsultantType)) {

                    objQuery.arSec.push({
                        "sProp": "consultant_type_id",
                        "sFilterValue": objHeaderItem._objConsultantType.name,
                        "bEncode": true,
                        "mValue": objHeaderItem._objConsultantType._id
                    });
                }

                if (!a24Core.isEmpty(objHeaderItem._objConsultant)) {
                    objQuery.arSec.push({
                        "sProp": "consultant_id",
                        "sFilterValue": objHeaderItem._objConsultant.name,
                        "bEncode": true,
                        "mValue": objHeaderItem._objConsultant._id
                    });
                }

                if (!a24Core.isEmpty(objHeaderItem._objPlayedKeyword)) {
                    objQuery.arSec.push({
                        "sProp": "played_keywords",
                        "sFilterValue": objHeaderItem._objPlayedKeyword.sLabel,
                        "bEncode": true,
                        "mValue": objHeaderItem._objPlayedKeyword.sValue === this.get("_objCandidateProfileEnumsService.playedKeywords.PLAYED.backend")
                    });
                }
            }

            return objQuery;
        },

        funcFilterCustomTag: function funcFilterCustomTag(objHeaderItem, objQuery, objHeadersSecondaries, bSkipTitle) {
            if (objQuery.sFilterValue === "empty") {
                if (bSkipTitle) {
                    return {
                        sTag: this.get("_objStringsService").getString("isEmpty")
                    };
                } else {
                    return {
                        sTag: this.get("_objCandidateProfileStringsService").getString("externalPortfolioTagEmpty")
                    };
                }
            } else {
                var sExternalPortfolioTagKey = bSkipTitle ? "externalPortfolioTagNoTitle" : "externalPortfolioTag";
                var sPortfolioName = null;
                var sIs = this.get("_objStringsService").getString("is").toLowerCase();

                if (!a24Core.isEmpty(objQuery.sFilterValue)) {
                    sPortfolioName = objQuery.sFilterValue;
                } else {
                    sPortfolioName = this.get("_objStringsService").getString("any");
                }

                var objConsultantTypeQuery = objQuery.arSec.findBy("sProp", "consultant_type_id");

                if (!a24Core.isEmpty(objConsultantTypeQuery)) {
                    var sConsultantType = this.get("_objCandidateProfileStringsService").getString("consultantType");
                    sPortfolioName = sPortfolioName + ", " + sConsultantType + " " + sIs + " " + objConsultantTypeQuery.sFilterValue;
                }

                var objConsultantQuery = objQuery.arSec.findBy("sProp", "consultant_id");

                if (!a24Core.isEmpty(objConsultantQuery)) {
                    var sConsultant = this.get("_objCandidateProfileStringsService").getString("consultant");
                    sPortfolioName = sPortfolioName + ", " + sConsultant + " " + sIs + " " + objConsultantQuery.sFilterValue;
                }

                var objPlayedKeywordQuery = objQuery.arSec.findBy("sProp", "played_keywords");

                if (!a24Core.isEmpty(objPlayedKeywordQuery)) {
                    var sPlayedKeyword = this.get("_objCandidateProfileStringsService").getString("keyword");
                    sPortfolioName = sPortfolioName + ", " + sPlayedKeyword + " " + sIs + " " + objPlayedKeywordQuery.sFilterValue;
                }

                return {
                    sTag: this.get("_objCandidateProfileStringsService").getTokenString(sExternalPortfolioTagKey, sPortfolioName)
                };
            }
        },

        funcFilterCustomPrimaryReset: function funcFilterCustomPrimaryReset(objHeaderItem) {
            //Reset the state of the custom filter input
            Ember.set(objHeaderItem, "_bEmptyEntriesOnly", false);
            Ember.set(objHeaderItem, "_objPortfolio", null);
            Ember.set(objHeaderItem, "_objConsultantType", null);
            Ember.set(objHeaderItem, "_objConsultant", null);
            Ember.set(objHeaderItem, "_objPlayedKeyword", null);
        },

        funcFilterCustomFixupQuery: function funcFilterCustomFixupQuery() /* objHeader, objHeaderSecondaries */{},
        funcFilterCustomSetEditState: function funcFilterCustomSetEditState(objHeaderItem, iIndex) {
            var objTag = objHeaderItem.mFilterValue[iIndex];

            Ember.set(objHeaderItem, "_bEmptyEntriesOnly", objTag.sFilterValue === "empty");

            if (objTag.sFilterValue !== "empty") {
                if (!a24Core.isEmpty(objTag.mValue) && !a24Core.isEmpty(objTag.sFilterValue)) {
                    var objPortfolio = {
                        name: objTag.sFilterValue,
                        _id: objTag.mValue
                    };

                    Ember.set(objHeaderItem, "_objPortfolio", objPortfolio);
                }

                if (!a24Core.isEmpty(objTag.arSec)) {
                    for (var i = 0; i < objTag.arSec.length; i++) {
                        var objSecondary = objTag.arSec[i];

                        if (objSecondary.sProp === "consultant_type_id") {
                            var objConsultantType = {
                                name: objSecondary.sFilterValue,
                                _id: objSecondary.mValue
                            };

                            Ember.set(objHeaderItem, "_objConsultantType", objConsultantType);
                        } else if (objSecondary.sProp === "consultant_id") {
                            var objConsultant = {
                                name: objSecondary.sFilterValue,
                                _id: objSecondary.mValue
                            };

                            Ember.set(objHeaderItem, "_objConsultant", objConsultant);
                        } else if (objSecondary.sProp === "played_keywords") {
                            var objPlayedKeyword = {
                                sLabel: objSecondary.sFilterValue
                            };

                            if (objSecondary.mValue === true) {
                                objPlayedKeyword.sValue = this.get("_objCandidateProfileEnumsService.playedKeywords.PLAYED.backend");
                            } else {
                                objPlayedKeyword.sValue = this.get("_objCandidateProfileEnumsService.playedKeywords.PAUSED.backend");
                            }

                            Ember.set(objHeaderItem, "_objPlayedKeyword", objPlayedKeyword);
                        }
                    }
                }
            }
        }
    });
});