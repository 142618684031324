define("a24-ember-staffshift-core/services/rest-country", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        _sTriageBaseRest: Ember.computed.readOnly("env.sTriageBaseRest"),
        _objNavigation: Ember.inject.service("navigation"),
        _objUserSession: Ember.inject.service("user-session"),

        /**
         * Service method to retrieve the country name and code based on the id
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The property used for the rest service
         * @param sCountryId - The country id
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object to execute when the service call is not successful
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  12 July 2018
         */
        getCountryById: function getCountryById(objCaller, sProperty, sCountryId, funcSuccess, objFailure) {

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sTriageBaseRest") + "/rest/application/country/" + sCountryId, funcSuccess, objFailure));
        }
    });
});