define("a24-ember-lookup/helpers/lookup-constant", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Helper.extend({
        _objA24Constants: Ember.inject.service("a24-constants"),

        compute: function compute(arrParams) {
            var sKey = arrParams[0];
            var objService = arrParams[1] || this.get("_objA24Constants");

            return objService[sKey];
        }
    });
});