define("a24-ember-staffshift-candidate-availability/services/rest-staffing-shift-details", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _sTriageBaseRest: Ember.computed.readOnly("_ENV.sTriageBaseRest"),

        _ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * Route for getting details of shifts
         *
         * @param objCaller - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param arrOrderIds - The array of order ids
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  04 June 2020
         */
        getShiftDetails: function getShiftDetails(objContext, sProperty, arrOrderIds, funcSuccess, objFailure) {
            var sOrderIds = arrOrderIds.join("|");

            a24RestUrlConstruct.setBaseUrl("/rest/staffing/shift?sStaffingOrderId=in::" + sOrderIds);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sTriageBaseRest") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, null, null, false, true));
        }
    });
});